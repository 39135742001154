import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


//all generated scheme User Action
// export const fetchGeneratedScheme = createAsyncThunk("fetchSchemeList", async (params) => {
//     let res = await fetch(`${baseUrl}/api/SchemeGenerate/GetSchemeGenerate`,{
//       method: "Post",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(params),
//     })
//   const result = await res.json();
//   console
//   });

const getGeneratedScheme = createSlice({
  name: "generatedScheme",
  initialState: { 
      data: [],
  },
  reducers: {
    generatedScheme: (state, action)=>{
        state.data = action.payload;
    }
  }

});
export const { generatedScheme } = getGeneratedScheme.actions;
export const getAllGeneratedScheme = (state)=> state.generatedScheme.data;
export default getGeneratedScheme.reducer;