import { Button, Table, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import {
  columnWidth,
  inputColumnWidth,
  columnWidth150,
  smallColumnWidth,
  amountCheckIN,
  checkDisableText,
  TableSummaryInput,
  DummyTableCollectionSummary,
  columnWidth120,
  columnWidth220,
} from "../widthTable.jsx";
import { decryptData } from "../../utils/localStorageEncodeDecode.jsx";

export const actionColumnBtn = (handleBtn) => [
  {
    title: "Action",
    fixed: "right",
    dataIndex: "action",
    key: "action",
    ...columnWidth150,
    align: "center",
    ellipsis: {
      showTitle: false,
    },
    // render: (currentRecord, showRecord) => (
    //   <ShowButton
    //     styleClass={""}
    //     actionBtn={() => handleBtn(showRecord)}
    //     disableBtn={false}
    //     label={"Action"}
    //     icon={null}
    //   />
    // ),
  },
];
const { userRole, userId, userName } =
  decryptData(sessionStorage.getItem("userInfo")) || {};
export const actionMergeColumnBtn = (text, handleBtn) => [
  {
    title: "Action",
    fixed: "right",
    dataIndex: "action",
    key: "action",
    ...columnWidth150,
    align: "center",
    ellipsis: {
      showTitle: false,
    },
    render: (currentRecord, showRecord) => {
      const userData1 = decryptData(sessionStorage.getItem("userInfo")) || {};

      if (text == "Unhold") {
        return (
          <>
            {showRecord?.holdByRole == userData1?.userRole && (
              <Button
                type="primary"
                onClick={() => handleBtn(showRecord, text)}
              >
                {text ? text : "Hold Scheme"}
              </Button>
            )}
          </>
        );
      } else {
        return (
          <>
            <Button type="primary" onClick={() => handleBtn(showRecord)}>
              {text ? text : "Hold Scheme"}
            </Button>
          </>
        );
      }
    },
  },
];

export const deviationlogsColumns = [
  {
    title: "REVISE TARGET",

    dataIndex: "deviationQuantity",
    key: "deviationQuantity",
    ...columnWidth150,
    align: "center",
    ellipsis: {
      showTitle: false,
    },
    render: (data) => <Tooltip title={data}>{data || 0}</Tooltip>,
  },
  {
    title: "REVISE PAYOUT",

    dataIndex: "deviationAmount",
    key: "deviationAmount",
    ...columnWidth150,
    align: "center",
    ellipsis: {
      showTitle: false,
    },
    render: (data) => <Tooltip title={data}>{data || 0}</Tooltip>,
  },
  {
    title: "DEVIATION REMARKS",

    dataIndex: "deviationRemarks",
    key: "deviationRemarks",
    ...columnWidth150,
    align: "center",
    ellipsis: {
      showTitle: false,
    },
    render: (data) => <Tooltip title={data}>{data}</Tooltip>,
  },
];
export const summaryCollectionViewOnly = (tableData, pageType) => {
  const {
    delayPlan,
    billing,
    installation,
    collection,
    t1,
    t2,
    t2_T1,
    t3,
    t3_T2,
    t4,
    t4_T3,
    t5,
    t5_T4,
    t6,
    t6_T5,
    r1,
    r2,
    r3,
    r4,
    r5,
    r6,
    p1,
    p2,
    p3,
    p4,
    p5,
    p6,
    volume,
    perTr,
    amount,
    deviationQuantity,
    deviationAmount,
  } = tableData.reduce(
    (
      sum,
      {
        delayPlan,
        billing,
        topTarget,
        installation,
        collection,
        t1,
        t2,
        t2_T1,
        t3,
        t3_T2,
        t4,
        t4_T3,
        t5,
        t5_T4,
        t6,
        t6_T5,
        r1,
        r2,
        r3,
        r4,
        r5,
        r6,
        p1,
        p2,
        p3,
        p4,
        p5,
        p6,
        volume,
        perTr,
        amount,
        deviationAmount,
        deviationQuantity,
      }
    ) => ({
      delayPlan: sum.delayPlan + (delayPlan ? parseInt(delayPlan) : 0),
      billing: sum.billing + (billing ? parseInt(billing) : 0),
      installation:
        sum.installation + (installation ? parseInt(installation) : 0),
      collection: sum.collection + (collection ? parseInt(collection) : 0),

      t1: sum.t1 + (t1 && topTarget?.includes("t1") ? parseInt(t1) : 0),
      t2: sum.t2 + (t2 && topTarget?.includes("t2") ? parseInt(t2) : 0),
      t3: sum.t3 + (t3 && topTarget?.includes("t3") ? parseInt(t3) : 0),
      t4: sum.t4 + (t4 && topTarget?.includes("t4") ? parseInt(t4) : 0),
      t5: sum.t5 + (t5 && topTarget?.includes("t5") ? parseInt(t5) : 0),
      t6: sum.t6 + (t6 && topTarget?.includes("t6") ? parseInt(t6) : 0),

      t2_T1:
        sum.t2_T1 + (t2_T1 && topTarget?.includes("t2") ? parseInt(t2_T1) : 0),
      t3_T2:
        sum.t3_T2 + (t3_T2 && topTarget?.includes("t3") ? parseInt(t3_T2) : 0),
      t4_T3:
        sum.t4_T3 + (t4_T3 && topTarget?.includes("t4") ? parseInt(t4_T3) : 0),
      t5_T4:
        sum.t5_T4 + (t5_T4 && topTarget?.includes("t5") ? parseInt(t5_T4) : 0),
      t6_T5:
        sum.t6_T5 + (t6_T5 && topTarget?.includes("t6") ? parseInt(t6_T5) : 0),

      r1: sum.r1 + (r1 && topTarget?.includes("t1") ? parseInt(r1) : 0),
      r2: sum.r2 + (r2 && topTarget?.includes("t2") ? parseInt(r2) : 0),
      r3: sum.r3 + (r3 && topTarget?.includes("t3") ? parseInt(r3) : 0),
      r4: sum.r4 + (r4 && topTarget?.includes("t4") ? parseInt(r4) : 0),
      r5: sum.r5 + (r5 && topTarget?.includes("t5") ? parseInt(r5) : 0),
      r6: sum.r6 + (r6 && topTarget?.includes("t6") ? parseInt(r6) : 0),
      p1: sum.p1 + (p1 && topTarget?.includes("t1") ? parseInt(p1) : 0),
      p2: sum.p2 + (p2 && topTarget?.includes("t2") ? parseInt(p2) : 0),
      p3: sum.p3 + (p3 && topTarget?.includes("t3") ? parseInt(p3) : 0),
      p4: sum.p4 + (p4 && topTarget?.includes("t4") ? parseInt(p4) : 0),
      p5: sum.p5 + (p5 && topTarget?.includes("t5") ? parseInt(p5) : 0),
      p6: sum.p6 + (p6 && topTarget?.includes("t6") ? parseInt(p6) : 0),
      volume: sum.volume + (volume ? parseInt(volume) : 0),
      perTr: sum.perTr + (perTr ? parseInt(perTr) : 0),
      amount: sum.amount + (amount ? parseInt(amount) : 0),
      deviationQuantity:
        sum.deviationQuantity +
        (deviationQuantity ? parseInt(deviationQuantity) : 0),
      deviationAmount:
        sum.deviationAmount + (deviationAmount ? parseInt(deviationAmount) : 0),
    }),
    {
      delayPlan: 0,
      billing: 0,
      installation: 0,
      collection: 0,
      t1: 0,
      t2: 0,
      t3: 0,
      t4: 0,
      t5: 0,
      t6: 0,
      t2_T1: 0,
      t3_T2: 0,
      t4_T3: 0,
      t5_T4: 0,
      t6_T5: 0,
      r1: 0,
      r2: 0,
      r3: 0,
      r4: 0,
      r5: 0,
      r6: 0,
      p1: 0,
      p2: 0,
      p3: 0,
      p4: 0,
      p5: 0,
      p6: 0,
      volume: 0,
      perTr: 0,
      amount: 0,
      deviationQuantity: 0,
      deviationAmount: 0,
    }
  );
  const deviationSummary = () => {
    return pageType == "deviation log" ? (
      <>
        <TableSummaryInput index={51} data={deviationAmount} />
        <TableSummaryInput index={52} data={deviationQuantity} />
        <TableSummaryInput index={53} data={""} />
        <TableSummaryInput index={54} data={""} />
      </>
    ) : pageType == "deviation request" ? (
      <>
        <TableSummaryInput index={51} data={""} />
        <TableSummaryInput index={52} data={""} />
      </>
    ) : (
      <>
        <Table.Summary.Cell index={51}></Table.Summary.Cell>
      </>
    );
  };
  return (
    <Table.Summary.Row>
      <DummyTableCollectionSummary />
      <TableSummaryInput index={19} data={""} />
      <TableSummaryInput index={20} data={delayPlan} />
      <TableSummaryInput index={21} data={billing} />
      <TableSummaryInput index={22} data={installation} />
      <TableSummaryInput index={23} data={collection} />
      <TableSummaryInput index={24} data={""} />
      <TableSummaryInput index={25} data={""} />
      <TableSummaryInput index={26} data={t1 || ""} />
      <TableSummaryInput index={27} data={t2 || ""} />
      <TableSummaryInput index={28} data={t2_T1 || ""} />
      <TableSummaryInput index={29} data={t3 || ""} />
      <TableSummaryInput index={30} data={t3_T2 || ""} />
      <TableSummaryInput index={31} data={t4 || ""} />
      <TableSummaryInput index={32} data={t4_T3 || ""} />
      <TableSummaryInput index={33} data={t5 || ""} />
      <TableSummaryInput index={34} data={t5_T4 || ""} />
      <TableSummaryInput index={35} data={t6 || ""} />
      <TableSummaryInput index={36} data={t6_T5 || ""} />
      <TableSummaryInput index={37} data={r1 || ""} />
      <TableSummaryInput index={38} data={r2 || ""} />
      <TableSummaryInput index={39} data={r3 || ""} />
      <TableSummaryInput index={40} data={r4 || ""} />
      <TableSummaryInput index={41} data={r5 || ""} />
      <TableSummaryInput index={42} data={r6 || ""} />
      <TableSummaryInput index={43} data={p1 || ""} />
      <TableSummaryInput index={44} data={p2 || ""} />
      <TableSummaryInput index={45} data={p3 || ""} />
      <TableSummaryInput index={46} data={p4 || ""} />
      <TableSummaryInput index={47} data={p5 || ""} />
      <TableSummaryInput index={48} data={p6 || ""} />
      <TableSummaryInput index={49} data={""} />
      <TableSummaryInput index={50} data={volume} />
      <TableSummaryInput index={51} data={perTr} />
      <TableSummaryInput index={52} data={amount} />
      {deviationSummary()}
    </Table.Summary.Row>
  );
};
//t1,modifiedT1,historyT1

// status:1?"t1:modifiedT1"
//if hold and approved historyT1:t1
//if rejected t1:historyT1

const dataType = (data) => {
  data?.status == 1 ? (
    <>
      <div>{data?.t1}</div>
      <div>{data?.modifiedT1}</div>
    </>
  ) : data?.status == 9 && data?.status == 2 ? (
    <>
      <div>{data?.historyT1}</div>
      <div>{data?.t1}</div>
    </>
  ) : data?.status == 5 ? (
    <>
      <div>{data?.t1}</div>
      <div>{data?.historyT1}</div>
    </>
  ) : (
    <div>{data?.t1}</div>
  );
};
const TableCollectionViewOnly = (props) => {
  const {
    showCreatedBy,
    showRemark,
    showCreatedDate,
    disableSort,
    showHoldComment,
  } = props;

  const getCreatedName = () =>
    showCreatedBy
      ? [
          {
            title: (
              <div>
                CREATED <br /> BY
              </div>
            ),
            dataIndex: "createdBy",
            ...columnWidth150,
            ellipsis: false,
          },
        ]
      : [];

  const getRemarks = () =>
    showHoldComment
      ? [
          {
            title: "COMMENTS",
            children: [
              {
                title: "REMARKS",
                align: "center",
                dataIndex: "reason",
                ellipsis: {
                  showTitle: false,
                },
                shouldCellUpdate: (record, prevRecord) =>
                  record.reason !== prevRecord.reason,
                render: (data) => <Tooltip title={data}>{data}</Tooltip>,
                ...columnWidth,
              },
              {
                title: "HOLD REASON",
                dataIndex: "modifiedReason",
                key: "modifiedReason",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                shouldCellUpdate: (record, prevRecord) =>
                  record.modifiedReason !== prevRecord.modifiedReason,
                render: (data) => <Tooltip title={data}>{data || ""}</Tooltip>,
                ...columnWidth,
              },
            ],
          },
        ]
      : showRemark
      ? [
          {
            title: "REMARKS",
            align: "center",
            dataIndex: "reason",
            ellipsis: {
              showTitle: false,
            },
            shouldCellUpdate: (record, prevRecord) =>
              record.reason !== prevRecord.reason,
            render: (data) => <Tooltip title={data}>{data}</Tooltip>,
            ...columnWidth,
          },
        ]
      : [];

  const getCreatedDate = () =>
    showCreatedDate
      ? [
          {
            title: (
              <div>
                Created <br />
                Date
              </div>
            ),
            dataIndex: "createdDate",
            ...columnWidth150,
            sorter: disableSort ? false : true,
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "createdDate",
            shouldCellUpdate: (record, prevRecord) =>
              record.createdDate !== prevRecord.createdDate,
            render: (data) => <Tooltip title={data}>{data}</Tooltip>,
          },
        ]
      : [];

  return [
    {
      title: (
        <div>
          SCHEME <br /> NO
        </div>
      ),
      dataIndex: "schemeNo",
      fixed: "left",
      sorter: disableSort ? false : true,
      ...columnWidth,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "descend",
      key: "SchemeNo",
      // shouldCellUpdate: (record, prevRecord) =>
      //   record.schemeNo !== prevRecord.schemeNo,
    },
    {
      title: (
        <div className="pl-4">
          DEALER <br /> CODE
        </div>
      ),
      dataIndex: "dealerCode",
      fixed: "left",
      sorter: disableSort ? false : true,
      ...columnWidth150,
      align: "center",
      defaultSortOrder: "descend",
      key: "DealerCode",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      // shouldCellUpdate: (record, prevRecord) =>
      //   record.DealerCode !== prevRecord.DealerCode,
    },
    {
      title: (
        <div className="pl-4">
          DEALER <br /> LOCATION
        </div>
      ),
      dataIndex: "dealerLocation",
      ...columnWidth150,
      fixed: "left",
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "descend",
      key: "DealerLocation",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      // shouldCellUpdate: (record, prevRecord) =>
      //   record.DealerLocation !== prevRecord.DealerLocation,
    },
    {
      title: (
        <div className="pl-4">
          DEALER <br /> NAME
        </div>
      ),
      dataIndex: "dealerName",
      ...columnWidth150,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "DealerName",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      // shouldCellUpdate: (record, prevRecord) =>
      //   record.DealerName !== prevRecord.DealerName,
    },
    {
      title: "STATE",
      dataIndex: "stateName",
      ...columnWidth150,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "StateName",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      shouldCellUpdate: (record, prevRecord) =>
        record.stateName !== prevRecord.stateName,
    },
    ...getCreatedDate(),
    {
      title: (
        <div>
          TERRITORY <br /> MANAGER
        </div>
      ),
      dataIndex: "territoryManager",
      ...columnWidth150,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "TerritoryManager",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      shouldCellUpdate: (record, prevRecord) =>
        record.territoryManager !== prevRecord.territoryManager,
    },
    {
      title: (
        <div>
          TOP <br /> SLAB
        </div>
      ),
      dataIndex: "topTarget",
      ...columnWidth150,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "topTarget",
      shouldCellUpdate: (record, prevRecord) =>
        record.topTarget !== prevRecord.topTarget,
      render: (data) => {
        const selectedTopSlab =
          data?.split(",")[data?.split(",")?.length - 1].toUpperCase() ||
          "t1".toUpperCase();
        return (
          <Tooltip
            title={<div className="uppercase"> {selectedTopSlab}</div>}
            className="uppercase"
          >
            {selectedTopSlab}
          </Tooltip>
        );
      },
    },
    ...getCreatedName(),
    {
      title: (
        <div>
          START <br /> MONTH
        </div>
      ),
      dataIndex: "startMonth",
      key: "startMonth",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      ...columnWidth150,
      shouldCellUpdate: (record, prevRecord) =>
        record.startMonth !== prevRecord.startMonth,
      render: (data) => dayjs(data).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      key: "endMonth",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.endMonth !== prevRecord.endMonth,
      ...columnWidth150,
      render: (data) => dayjs(data).format("MMM'YY"),
    },
    {
      title: "INDUSTRY",
      dataIndex: "industry",
      key: "industry",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.industry !== prevRecord.industry,
      ...smallColumnWidth,
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: "B",
      dataIndex: "oldBillingQty",
      key: "oldBillingQty",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.oldBillingQty !== prevRecord.oldBillingQty,
      ...inputColumnWidth,
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: "I",
      dataIndex: "oldInstallation",
      key: "oldInstallation",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.oldInstallation !== prevRecord.oldInstallation,
      ...inputColumnWidth,
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: "C",
      dataIndex: "oldCollection",
      key: "oldCollection",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.oldCollection !== prevRecord.oldCollection,
      ...inputColumnWidth,
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: ` OPENING STOCK`,
      dataIndex: "stock",

      children: [
        {
          title: "Nos",
          dataIndex: "oldOpeningStock",
          key: "oldOpeningStock",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          ...columnWidth150,
          shouldCellUpdate: (record, prevRecord) =>
            record.oldOpeningStock !== prevRecord.oldOpeningStock,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
        {
          title: "Days",
          dataIndex: "openingStockDays",
          key: "openingStockDays",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.openingStockDays !== prevRecord.openingStockDays,
          ...columnWidth150,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
      ],
    },
    {
      title: "CLOSING OS. (IN LACS)",
      dataIndex: "stock",

      textAlign: "center",

      children: [
        {
          title: (
            <span>
              Last{" "}
              <span className="ordinal-title">
                3<sup className="ordinal-suffix">rd</sup>
              </span>{" "}
              Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_3",
          key: "closingOutStandingMonth_3",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.closingOutStandingMonth_3 !==
            prevRecord.closingOutStandingMonth_3,
          ...columnWidth150,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
        {
          title: (
            <span>
              Last{" "}
              <span className="ordinal-title">
                2<sup className="ordinal-suffix">nd</sup>
              </span>{" "}
              Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_2",
          key: "closingOutStandingMonth_2",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          ...columnWidth150,
          shouldCellUpdate: (record, prevRecord) =>
            record.closingOutStandingMonth_2 !==
            prevRecord.closingOutStandingMonth_2,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
        {
          title: (
            <span>
              Last Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_1",
          key: "closingOutStandingMonth_1",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.closingOutStandingMonth_1 !==
            prevRecord.closingOutStandingMonth_1,
          ...columnWidth150,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
        // {
        //   title: (
        //     <span>
        //       Last{" "}
        //       <span className="ordinal-title">
        //         1<sup className="ordinal-suffix">st</sup>
        //       </span>{" "}
        //       Month Days
        //     </span>
        //   ),
        //   dataIndex: "outStandingDays",
        //   key: "outStandingDays",
        //   align: "center",
        //   ellipsis: {
        //     showTitle: false,
        //   },
        //   shouldCellUpdate: (record, prevRecord) =>
        //     record.outStandingDays !== prevRecord.outStandingDays,
        //   ...columnWidth150,
        //   render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        // },
      ],
    },
    {
      title: "Closing OS Days",
      dataIndex: "outStandingDays",
      children: [
        {
          title: "OS Last Month",
          dataIndex: "outStandingDays",
          key: "outStandingDays",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.outStandingDays !== prevRecord.outStandingDays,
          ...columnWidth150,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
      ],
    },
    {
      title: "BG",
      dataIndex: "bg",
      children: [
        {
          title: "Lacs",
          dataIndex: "bGLacsCurrentYear",
          key: "bGLacsCurrentYear",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.bGLacsCurrentYear !== prevRecord.bGLacsCurrentYear,
          ...columnWidth150,
          render: (data) => <Tooltip title={data}>{data || 0}</Tooltip>,
        },
      ],
    },
    {
      title: "INSTALLATION PLAN",
      children: [
        {
          title: "NOS",
          dataIndex: "delayPlan",
          key: "delayPlan",
          ...columnWidth150,
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.delayPlan !== prevRecord.delayPlan,
          render: (data) => amountCheckIN(data),
        },
      ],
    },
    {
      title: <div>BILLING DETAILS</div>,
      children: [
        {
          title: "B",
          dataIndex: "billing",
          key: "billing",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.billing !== prevRecord.billing,
          render: (data) => amountCheckIN(data),
          ...columnWidth150,
        },
        {
          title: "I",
          dataIndex: "installation",
          key: "installation",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.installation !== prevRecord.installation,
          render: (data) => amountCheckIN(data),
          ...columnWidth150,
        },
        {
          title: "C",
          dataIndex: "collection",
          key: "collection",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.collection !== prevRecord.collection,
          render: (data) => amountCheckIN(data),
          ...columnWidth150,
        },
      ],
    },
    {
      title: "Closing Stock",
      dataIndex: "closingStock",
      key: "closingStock",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.closingStock !== prevRecord.closingStock,
      render: (value) => value,
      ...columnWidth150,
    },

    {
      title: "Closing OutStanding (In Lacs)",
      dataIndex: "closingOutStanding",
      key: "closingOutStanding",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.closingOutStanding !== prevRecord.closingOutStanding,
      render: (value) => value,
      ...columnWidth220,
    },
    {
      title: "BOOKS COLLECTION TARGETS (NO. OF TRS.)",
      children: [
        {
          title: "T1",
          dataIndex: "t1",
          key: "t1",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.t1 !== prevRecord.t1,
          render: (data) => amountCheckIN(data),
          ...columnWidth150,
        },
        {
          title: "T2",
          dataIndex: "t2",
          key: "t2",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.t2 !== prevRecord.t2,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t2")
            ),
          ...columnWidth150,
        },
        {
          title: "T2-T1",
          dataIndex: "t2_T1",
          key: "t2_T1",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.t2_T1 !== prevRecord.t2_T1,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t2")
            ),
          ...columnWidth150,
        },

        {
          title: "T3",
          dataIndex: "t3",
          key: "t3",
          align: "center",
          shouldCellUpdate: (record, prevRecord) => record.t3 !== prevRecord.t3,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t3")
            ),
          ...columnWidth150,
        },
        {
          title: "T3-T2",
          dataIndex: "t3_T2",
          key: "t3_T2",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.t3_T2 !== prevRecord.t3_T2,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t3")
            ),
          ...columnWidth150,
        },
        {
          title: "T4",
          dataIndex: "t4",
          key: "t4",
          align: "center",
          shouldCellUpdate: (record, prevRecord) => record.t4 !== prevRecord.t4,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t4")
            ),
          ...columnWidth150,
        },
        {
          title: "T4-T3",
          dataIndex: "t4_T3",
          key: "t4_T3",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.t4_T3 !== prevRecord.t4_T3,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t4")
            ),
          ...columnWidth150,
        },
        {
          title: "T5",
          dataIndex: "t5",
          key: "t5",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.t5 !== prevRecord.t5,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t5")
            ),
          ...columnWidth150,
        },
        {
          title: "T5-T4",
          dataIndex: "t5_T4",
          key: "t5_T4",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.t5_T4 !== prevRecord.t5_T4,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t5")
            ),
          ...columnWidth150,
        },

        {
          title: "T6",
          dataIndex: "t6",
          key: "t6",
          align: "center",
          shouldCellUpdate: (record, prevRecord) => record.t6 !== prevRecord.t6,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t6")
            ),
          ...columnWidth150,
        },
        {
          title: "T6-T5",
          dataIndex: "t6_T5",
          key: "t6_T5",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.t6_T5 !== prevRecord.t6_T5,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t6")
            ),
          ...columnWidth150,
        },
      ],
    },
    {
      title: "PAYOUT RATE",
      children: [
        {
          title: "R1",
          dataIndex: "r1",
          key: "r1",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.r1 !== prevRecord.r1,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t1")
            ),
          ...columnWidth150,
        },
        {
          title: "R2",
          dataIndex: "r2",
          key: "r2",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.r2 !== prevRecord.r2,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t2")
            ),
          ...columnWidth150,
        },
        {
          title: "R3",
          dataIndex: "r3",
          key: "r3",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.r3 !== prevRecord.r3,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t3")
            ),
          ...columnWidth150,
        },
        {
          title: "R4",
          dataIndex: "r4",
          key: "r4",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.r4 !== prevRecord.r4,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t4")
            ),
          ...columnWidth150,
        },
        {
          title: "R5",
          dataIndex: "r5",
          key: "r5",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.r5 !== prevRecord.r5,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t5")
            ),
          ...columnWidth150,
        },
        {
          title: "R6",
          dataIndex: "r6",
          key: "r6",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.r6 !== prevRecord.r6,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t6")
            ),
          ...columnWidth150,
        },
      ],
    },

    {
      title: "PAYOUT RATE",
      children: [
        {
          title: "P1",
          dataIndex: "p1",
          key: "p1",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.p1 !== prevRecord.p1,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t1")
            ),
          ...columnWidth150,
        },
        {
          title: "P2",
          dataIndex: "p2",
          key: "p2",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.p2 !== prevRecord.p2,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t2")
            ),
          ...columnWidth150,
        },
        {
          title: "P3",
          dataIndex: "p3",
          key: "p3",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.p3 !== prevRecord.p3,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t3")
            ),
          ...columnWidth150,
        },
        {
          title: "P4",
          dataIndex: "p4",
          key: "p4",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.p4 !== prevRecord.p4,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t4")
            ),
          ...columnWidth150,
        },
        {
          title: "P5",
          dataIndex: "p5",
          key: "p5",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.p5 !== prevRecord.p5,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t5")
            ),
          ...columnWidth150,
        },
        {
          title: "P6",
          dataIndex: "p6",
          key: "p6",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) => record.p6 !== prevRecord.p6,
          render: (value, data) =>
            checkDisableText(
              value,
              data?.topTarget?.split(",")?.includes("t6")
            ),
          ...columnWidth150,
        },
      ],
    },
    ...getRemarks(),
    {
      title: "TOTAL EXPECTED PAYOUT",
      children: [
        {
          title: "Expected Volume",
          dataIndex: "volume",
          key: "volume",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.volume !== prevRecord.volume,
          render: (data) => amountCheckIN(data),
          ...columnWidth150,
        },
        {
          title: "PER TR.",
          dataIndex: "perTr",
          key: "perTr",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.perTr !== prevRecord.perTr,
          render: (data) => amountCheckIN(data),
          ...columnWidth150,
        },
        {
          title: "AMOUNT",
          dataIndex: "amount",
          key: "amount",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          shouldCellUpdate: (record, prevRecord) =>
            record.amount !== prevRecord.amount,
          render: (data) => amountCheckIN(data),
          ...columnWidth150,
        },
      ],
    },
  ];
};

export default TableCollectionViewOnly;
