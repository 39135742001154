
import React, { useEffect, useState } from 'react';
import { Table, Button, message } from "antd";
import { getDealerUnMergeAsync, getUmMergeDealerListByStateIdAsync, getDealerListByStateIdAsync } from '../../redux/mergeUnmerge/mergeUmergeSlice';
import { useDispatch } from 'react-redux';
import { decryptData } from '../../utils/localStorageEncodeDecode';

const UnMergeTable = ({ dataSource,
  selectedStateId,
  payloads, amId,
  handleChange }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSourceArr, setDataSourceArr] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState([]);
  const dispatch = useDispatch()
  const userInfo = decryptData(sessionStorage.getItem("userInfo"));
  const userName = userInfo.userName;

  useEffect(() => {
    if (dataSource && dataSource?.length != 0) {
      setDataSourceArr(dataSource?.map((x, index) => ({ ...x, key: index })))
    } else {
      setDataSourceArr([])
    }
  }, [dataSource])



  const columns = [
    {
      title: "Delear Code",
      dataIndex: "dealerCode",
      fixed: "left",
      width: "150px",
    },
    { title: "Delear Location", dataIndex: "dealerLocation", width: "150px" },

    {
      title: "Delear Name",
      dataIndex: "dealerName",
      fixed: "left",
      width: "150px",
    },
    {
      title: "State",
      dataIndex: "stateName",
      width: "150px"
    }



  ];
  const onSelectChange = (newSelectedRowKeys, y) => {
    setSelectedDealer(y)
    setSelectedRowKeys(newSelectedRowKeys);

  };



  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };
  const handleUnMerge = () => {
    const payload = {
      stateId: selectedStateId,
      userName: userName,
      areaManagerId: amId,
      mergedDealerId: selectedDealer?.map((x) => x?.mergedDealerId)
    }
    try {
      if (selectedRowKeys.length === 0) {
        return message.warning("Select Schemes to be UnMerged")
      }
      if (selectedRowKeys.length > 0) {
        dispatch(getDealerUnMergeAsync(payload)).then((x) => {
          if (x.payload.response === 1) {
            message.success("Schemes  UnMerged Successfully")
            dispatch(getUmMergeDealerListByStateIdAsync(payloads))
            dispatch(getDealerListByStateIdAsync(payloads))

          }
          setSelectedRowKeys([])
          setSelectedDealer([])
        })
      }
    } catch (e) {
      message.warning(e.message)
    }
  }
  return (
    <div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSourceArr}
        onChange={handleChange}
        pagination={false}
        bordered
        scroll={{
          x: 1000,
          y: 200,
        }}


      />
      <div style={{
        display: "flex",
        justifyContent: "right"
      }}>
        <Button
          className="schemi-btn-all"
          style={{
            margin: "10px",
            background: "#FCE838",
            fontWeight: "700",
            textTransform: "uppercase",
            border: "none",
            color: "#fff !important",

          }}
          onClick={handleUnMerge}
        //  icon={<MergeIcon style={{color:'#fff'}} />}
        >
          <span style={{ color: '#fff' }}>UnMerge</span></Button>
      </div>
    </div>

  )
}

export default UnMergeTable