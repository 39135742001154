import React from "react";
import { Col,Row } from 'antd';
import './index.css'
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const Position = () => {
 const {userRole,userName} = decryptData(sessionStorage.getItem("userInfo"))||{};
    return(
        <>
        <Row className="nameTagWrap">
            <Col className="nameHead">
            {userRole=== "AMGR"? userName
            :userRole==="SADM" ? userName
            :userRole==="NSH" ? userName
            :userRole==="PMIS" ? userName
            :userRole==="Finance" ? userName
            :userRole==="COSM" ? userName
            :userRole==="ZADM"? userName:null}
            </Col>
            <Col className="nameTitle" >
            {userRole=== "AMGR"? "Area Manager"
            :userRole==="SADM" ? "State Head"
            :userRole==="PMIS" ? "PMIS"
            :userRole==="Finance" ? "Finance"
            :userRole==="COSM" ? "COSM"
            :userRole==="NSH" ? "National State Head"
            :userRole==="ZADM"? "Zonal Head":null}
            </Col>
        </Row>
        </>
    )
}

export default Position;