import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Table,
  Divider,
  Col,
  Row,
  Form,
  Input,
  Alert,
  Dropdown,
  Menu,
  Space,
  message,
  Select,
  Tooltip,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { getLockedZonalHeadApiAsync } from "../../../redux/stateData/stateDataSlice";
import CreateSchemeTable from "../createSchemes/createSchemeTable";
import { useFormik } from "formik";
import XLSX from "xlsx-js-style";
import {
  schemeDraftPostApi,
  schemeGeneratePostApi,
} from "../../../redux/schemaGenerate/schemaGenerateSlice";
import SMDialog from "../../../components/modal/alertModal/CommonModal";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import {
  getAwaitingLockSchemeAsync,
  getLockedSchemeStateApiAsync,
  getLockedSchemeTypeAsync,
} from "../../../redux/stateData/stateDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import axiosAPI from "../../../services/axiosApi";
import dayjs from "dayjs";
import ValidationDifferentSchemeError from "../../../components/modal/alertModal/ValidationDifferentSchemeError";
import StatusModal from "../../../components/modal/alertModal/StatusModal";
import { render } from "@testing-library/react";
import { encryptPayload } from "../../../services/encryption";
import { postUpdateHoldScheme } from "../../../redux/hold/holdDataSlice";
import ToastifyShow from "../../../components/ToastifyShow";
import { isValidContentType, isValidFileHeader } from "../../../config";
import { ModificationCollectionSchemeDownload } from "../modificationApproval/ModificationCollectionSchemeDownload";
import { parseFiveDigitNumber } from "../../../components/download/collectionSchemeUpload";
import moment from "moment";
import { uniqueByKey } from "../../../utils/formatter";
const CollectionDraftEditScheme = () => {
  const { lockedStates } = useSelector((state) => state?.stateData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state, pathname } = useLocation();
  const currentFiscalYear = getCurrentFiscalYear();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [payloadData, setPayloadData] = useState([]);
  const [order, setOrder] = useState("");
  const [toggleSubmitBtn, setToggleSubmitBtn] = useState("submit");
  const [orderBy, setOrderBy] = useState("");
  const [schemeLockedStatus, setSchemeLockedStatus] = useState({
    t1: false,
    t2: false,
    t3: false,
    t4: false,
    t5: false,
    t6: false,
    stateId: 0,
  });
  const [checkCalculate, setCheckCalculate] = useState(false);
  const [checkCalculateArr, setCheckCalculateArr] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState({
    open: false,
    errorsFields: {},
  });
  const [codeError, setCodeError] = useState([]);
  const { userId, userRole } = decryptData(sessionStorage.getItem("userInfo"));

  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });
  const showErrorDialog = (errFields) => {
    setOpenErrorDialog((openErrorDialog) => ({
      open: true,
      errorsFields: errFields ? errFields : {},
    }));
  };

  const topTargetArr = [
    {
      name: "T1",
      value: "t1",
      disabled: !schemeLockedStatus?.t1 ? true : false,
    },
    {
      name: "T2",
      value: "t2",
      disabled: !schemeLockedStatus?.t2 ? true : false,
    },
    {
      name: "T3",
      value: "t3",
      disabled: !schemeLockedStatus?.t3 ? true : false,
    },
    {
      name: "T4",
      value: "t4",
      disabled: !schemeLockedStatus?.t4 ? true : false,
    },
    {
      name: "T5",
      value: "t5",
      disabled: !schemeLockedStatus?.t5 ? true : false,
    },
    {
      name: "T6",
      value: "t6",
      disabled: !schemeLockedStatus?.t6 ? true : false,
    },
  ];
  const topTargetArr1 = (x) => [
    {
      name: "T1",
      value: "t1",
      disabled: false,
    },
    {
      name: "T2",
      value: "t2",
      disabled: false,
    },
    {
      name: "T3",
      value: "t3",
      disabled: false,
    },
    {
      name: "T4",
      value: "t4",
      disabled: !x?.t4 ? true : false,
    },
    {
      name: "T5",
      value: "t5",
      disabled: !x?.t5 ? true : false,
    },
    {
      name: "T6",
      value: "t6",
      disabled: !x?.t6 ? true : false,
    },
  ];
  const formik = useFormik({
    initialValues: [],
  });


  const uniqueData = useMemo(() => uniqueByKey(state?.map((data)=>({...data,stateId:data?.scheme?.stateId})), 'stateId'), [state])
  
  useEffect(() => {

    if (state?.length != 0) {

      dispatch(getLockedSchemeStateApiAsync({ data: encryptPayload(uniqueData?.toString()) })).then((data) => {
      formik?.setValues(state?.map((x) => ({
        ...x?.scheme, dropdownscheme: x?.topTarget?.split(","),

        BooksCollectionTargetT6T5: x?.BooksCollectionTargetT6T5,
        BooksCollectionTargetT6: x?.BooksCollectionTargetT6,
        BooksCollectionTargetT5T4: x?.BooksCollectionTargetT5T4,
        BooksCollectionTargetT5: x?.BooksCollectionTargetT5,
        BooksCollectionTargetT4T3: x?.BooksCollectionTargetT4T3,
        BooksCollectionTargetT4: x?.BooksCollectionTargetT4,
        BooksCollectionTargetT3T2: x?.BooksCollectionTargetT3T2,
        BooksCollectionTargetT3: x?.BooksCollectionTargetT3,
        BooksCollectionTargetT2T1: x?.BooksCollectionTargetT2T1,
        BooksCollectionTargetT2: x?.BooksCollectionTargetT2,
        BooksCollectionTargetT1: x?.BooksCollectionTargetT1,
        PayoutRatep6: x?.PayoutRatep6, payoutR6: x?.payoutR6,
        PayoutRatep5: x?.PayoutRatep5, payoutR5: x?.payoutR5,
        PayoutRatep4: x?.PayoutRatep4, payoutR4: x?.payoutR4,
        PayoutRatep3: x?.PayoutRatep3, payoutR3: x?.payoutR3,
        PayoutRatep2: x?.PayoutRatep2, payoutR2: x?.payoutR2,
        PayoutRatep1: x?.PayoutRatep1, payoutR1: x?.payoutR1,
        TEPvol: x?.TEPvol, slab: x?.slab,
        TEPpertr: x?.TEPpertr,
        code: x?.scheme?.dealerId,
        TEPamount: x?.TEPamount,
        dealerCode: x?.scheme?.dealerCode,
        stateName: x?.scheme?.stateName,
        billingFactorCOS:x?.scheme?.billingFactorCOS,
        closingStock: x?.closingStock,
        closingOutStanding: x?.closingOutStanding,
        topTargetArrState: (Array?.isArray(data?.payload?.data)) ? data?.payload?.data?.filter((y) => y?.stateId == x?.scheme?.stateId)?.[0] || [{
          t1: false,
          t2: false,
          t3: false,
          t4: false,
          t5: false,
          t6: false
        }] : [{
          t1: false,
          t2: false,
          t3: false,
          t4: false,
          t5: false,
          t6: false
        }],
        ...(pathname == "/collection/allSchemes/edit") && { "status": 1 }
      })))
    })}
  }, [state, schemeLockedStatus]);

  //pathname!="/collection/allSchemes/edit"
  // for draft status 0
  // all Schemes edit work in progress status 1
  //if approved status 2
console.log("formik?.values",formik?.values)
  const saveDraft = (route) => {
    let payload = [];
    if (formik?.values.length != 0) {
      formik?.values.map((currentRecord) => {
        const userData = decryptData(sessionStorage.getItem("userInfo"));
        const userId = userData.userId;
        const userName = userData.userName;
        const formattedPayout = {
          dealerCode: currentRecord.dealerCode,
          dealerId: +currentRecord.dealerId,
          // Manager: currentRecord.Manager,
          schemeId: currentRecord.schemeId,

          stateName: currentRecord.stateName,
          startMonth: currentRecord.startMonth
            ? currentRecord.startMonth
            : currentRecord.scheme.startMonth,
          endMonth: currentRecord.endMonth
            ? currentRecord.endMonth
            : currentRecord.scheme.endMonth,
          reason: currentRecord.reason,
          delayPlan: currentRecord.delayPlan,
          billing: currentRecord.billing,
          installation: currentRecord.installation,
          collection: Math.round(currentRecord.collection),
          openingStockDays: currentRecord.openingStockDays,
          outStandingDays: currentRecord.outStandingDays,
          status: 0,
          isSAP: true,
          isActive: true,
          t1: parseInt(currentRecord.BooksCollectionTargetT1) || 0,
          t2: parseInt(currentRecord.BooksCollectionTargetT2) || 0,
          t3: parseInt(currentRecord.BooksCollectionTargetT3) || 0,
          t4: parseInt(currentRecord.BooksCollectionTargetT4)
            ? parseInt(currentRecord.BooksCollectionTargetT4)
            : 0,
          t5: parseInt(currentRecord.BooksCollectionTargetT5)
            ? parseInt(currentRecord.BooksCollectionTargetT5)
            : 0,
          t6: parseInt(currentRecord.BooksCollectionTargetT6)
            ? parseInt(currentRecord.BooksCollectionTargetT6)
            : 0,
          t2_T1: parseInt(currentRecord.BooksCollectionTargetT2T1) || 0,
          t3_T2: parseInt(currentRecord.BooksCollectionTargetT3T2) || 0,
          t4_T3: parseInt(currentRecord.BooksCollectionTargetT4T3) || 0,
          t5_T4: parseInt(currentRecord.BooksCollectionTargetT5T4) || 0,
          t6_T5: parseInt(currentRecord.BooksCollectionTargetT6T5) || 0,
          p1: parseInt(currentRecord.payoutR1) || 0,
          p2: parseInt(currentRecord.payoutR2) || 0,
          p3: parseInt(currentRecord.payoutR3) || 0,
          p4: parseInt(currentRecord.payoutR4)
            ? parseInt(currentRecord.payoutR4)
            : 0,
          p5: parseInt(currentRecord.payoutR5)
            ? parseInt(currentRecord.payoutR5)
            : 0,
          p6: parseInt(currentRecord.payoutR6)
            ? parseInt(currentRecord.payoutR6)
            : 0,
          r1: +currentRecord.PayoutRatep1 || 0,
          r2: +currentRecord.PayoutRatep2 || 0,
          r3: +currentRecord.PayoutRatep3 || 0,
          r4: currentRecord.PayoutRatep4 ? +currentRecord.PayoutRatep4 : 0,
          r5: currentRecord.PayoutRatep5 ? currentRecord.PayoutRatep5 : 0,
          r6: currentRecord.PayoutRatep5 ? currentRecord.PayoutRatep5 : 0,
          volume: parseInt(currentRecord.TEPvol),
          perTr: parseInt(currentRecord.TEPpertr),
          amount: parseInt(currentRecord.TEPamount),
          remarks: currentRecord.remarks,
          stateName: currentRecord?.stateName,
          lastMonthSlab: currentRecord.lastMonthSlab,
          lastMonthColQty: currentRecord.lastMonthColQty,
          lastTwoMonthSlab: currentRecord.lastTwoMonthSlab,
          lastTwoMonthColQty: currentRecord.lastTwoMonthColQty,
          fyear: currentFiscalYear,
          userName: userName,
          userId: userId,
          industry: currentRecord.industry
            ? parseInt(currentRecord.industry)
            : 0,
          TopTarget: currentRecord?.dropdownscheme?.toString(),
          closingStock: currentRecord?.closingStock,
          closingOutStanding: currentRecord?.closingOutStanding,
        };
        payload.push(formattedPayout);
      });
    }

    let extraValidation = {};

    formik?.values?.map((x, index) => {
      let topTargetDetails = x?.dropdownscheme;
      let errors = [];
      if (topTargetDetails?.length == 0) {
        errors.push("Please select top target");
      }
      if (!x.industry) {
        errors.push("Please enter industry");
      }
      // if (!x.openingStockDays) {
      //   errors.push("Please enter open stock days");
      // }
      // if (!x.outStandingDays) {
      //   errors.push("Please enter outstanding days");
      // }
      if (!x.billing) {
        errors.push("Please enter billing");
      }
      if (!x.installation) {
        errors.push("Please enter installation");
      }
      if (!x.collection) {
        errors.push("Please enter collection");
      }
      if (!x.delayPlan && x?.schemeTypeId == 1) {
        errors.push("Please enter Installtion Plan Nos");
      }
      if (!x.TEPvol) {
        errors.push("Please enter volume");
      }
      //TEPvol
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(x?.BooksCollectionTargetT6 || 0) <=
          parseInt(x?.BooksCollectionTargetT5 || 0)
      ) {
        errors.push("T6 should be greater than T5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(x?.BooksCollectionTargetT5 || 0) <=
          parseInt(x?.BooksCollectionTargetT4 || 0)
      ) {
        errors.push("T5 should be greater than T4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(x?.BooksCollectionTargetT4 || 0) <=
          parseInt(x?.BooksCollectionTargetT3 || 0)
      ) {
        errors.push("T4 should be greater than T3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(x?.BooksCollectionTargetT3 || 0) <=
          parseInt(x?.BooksCollectionTargetT2 || 0)
      ) {
        errors.push("T3 should be greater than T2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(x?.BooksCollectionTargetT2 || 0) <=
          parseInt(x?.BooksCollectionTargetT1 || 0)
      ) {
        errors.push("T2 should be greater than T1");
      }
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(x?.PayoutRatep6 || 0) <= parseInt(x?.PayoutRatep5 || 0)
      ) {
        errors.push("R6 should be greater than R5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(x?.PayoutRatep5 || 0) <= parseInt(x?.PayoutRatep4 || 0)
      ) {
        errors.push("R5 should be greater than R4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(x?.PayoutRatep4 || 0) <= parseInt(x?.PayoutRatep3 || 0)
      ) {
        errors.push("R4 should be greater than R3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(x?.PayoutRatep3 || 0) <= parseInt(x?.PayoutRatep2 || 0)
      ) {
        errors.push("R3 should be greater than R2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(x?.PayoutRatep2 || 0) <= parseInt(x?.PayoutRatep1 || 0)
      ) {
        errors.push("R2 should be greater than R1");
      }
      if (!x.PayoutRatep1) {
        errors.push("Please enter R1");
      }
      if (!x.PayoutRatep2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter R2");
      }
      if (!x.PayoutRatep3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter R3");
      }
      if (!x.PayoutRatep4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter R4");
      }
      if (!x.PayoutRatep5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter R5");
      }
      if (!x.PayoutRatep6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter R6");
      }
      if (!x.BooksCollectionTargetT1) {
        errors.push("Please enter T1");
      }
      if (!x.BooksCollectionTargetT2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter T2");
      }
      if (!x.BooksCollectionTargetT3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter T3");
      }
      if (!x.BooksCollectionTargetT4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter T4");
      }
      if (!x.BooksCollectionTargetT5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter T5");
      }
      if (!x.BooksCollectionTargetT6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter T6");
      }
      if (errors.length > 0) {
        extraValidation[x?.dealerCode] = errors;
      }
    });

    setCodeError(Object?.keys(extraValidation || {}));
    if (Object?.keys?.(extraValidation)?.length != 0) {
      showErrorDialog(extraValidation);
    } else if (Object?.keys?.(extraValidation)?.length == 0) {
      setShowSubmitModal(true);
      setPayloadData(payload);
      setToggleSubmitBtn("draft");
    }
  };

  const closeErrorDialog = () => {
    setOpenErrorDialog({ open: false, errorsFields: [] });
  };

  const handleSaveSubmit = async () => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const userRole = userData.userRole;

    const handleDropDownSchemeCheckIndex = (x) => {
      let { t1, t2, t3, t4, t5, t6 } = x.reduce(
        (a, v) => ({ ...a, [v]: v }),
        {}
      );

      let data =
        t1 && !t2 && !t3 && !t4 && !t5 && !t6
          ? "t1"
          : t1 && t2 && !t3 && !t4 && !t5 && !t6
          ? "t2"
          : t1 && t2 && t3 && !t4 && !t5 && !t6
          ? "t3"
          : t1 && t2 && t3 && t4 && !t5 && !t6
          ? "t4"
          : t1 && t2 && t3 && t4 && t5 && !t6
          ? "t5"
          : t1 && t2 && t3 && t4 && t5 && t6
          ? "t6"
          : "";

      return data;
    };
    let data = formik?.values?.map((x) => ({
      dealerCode: x.dealerCode,
      dealerName: x.dealerName,
      dealerLocation: x.dealerLocation,
      dealerId: +x.dealerId,
      StartMonth: x?.startMonth
        ? dayjs(x?.startMonth).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        : x?.startMonth,
      EndMonth: x?.endMonth
        ? dayjs(x?.endMonth).endOf("month").format("YYYY-MM-DD HH:mm:ss")
        : x?.endMonth,

      status: x?.status,
      state: x.stateId,
  

      isSAP: true,
      isActive: true,
      schemePlanStartMonth: x?.startMonth,
      schemePlanEndMonth: x?.endMonth,
      schemeTypeId: x?.schemeTypeId,
      openingStockDays: x.openingStockDays,
      schemeId: x?.schemeId,
      holdStatus: x?.holdStatus,
      outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
      // ...(formik?.values?.[0]?.holdStatus==9&&{holdReason:x?.holdReason||""}),
      [formik?.values?.[0]?.holdStatus==9?"holdReason":"reason"]: (x.reason||x?.holdReason),
      ...(handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
        ? {
             [formik?.values?.[0]?.holdStatus==9?"T1":"t1"]: +x.BooksCollectionTargetT1 || 0,
             [formik?.values?.[0]?.holdStatus==9?"T2":"t2"]: 0,
             [formik?.values?.[0]?.holdStatus==9?"T3":"t3"]: 0,
             [formik?.values?.[0]?.holdStatus==9?"T4":"t4"]: 0,
             [formik?.values?.[0]?.holdStatus==9?"T5":"t5"]: 0,
             [formik?.values?.[0]?.holdStatus==9?"T6":"t6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T2t1":"t2_T1"]: +x.BooksCollectionTargetT2T1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T3t2":"t3_T2"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T4t3":"t4_T3"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T5t4":"t5_T4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T6t5":"t6_T5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P1":"p1"]: +x.payoutR1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P2":"p2"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P3":"p3"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P4":"p4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P5":"p5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P6":"p6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R1":"r1"]: +x?.PayoutRatep1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R2":"r2"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R3":"r3"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R4":"r4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R5":"r5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R6":"r6"]: 0,
          }
        : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
        ? {
          [formik?.values?.[0]?.holdStatus==9?"T1":"t1"]: +x.BooksCollectionTargetT1 || 0,
          [formik?.values?.[0]?.holdStatus==9?"T2":"t2"]: +x.BooksCollectionTargetT2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T3":"t3"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T4":"t4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T5":"t5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T6":"t6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T2t1":"t2_T1"]:  +x.BooksCollectionTargetT2T1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T3t2":"t3_T2"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T4t3":"t4_T3"]:  0,
            [formik?.values?.[0]?.holdStatus==9?"T5t4":"t5_T4"]:  0,
            [formik?.values?.[0]?.holdStatus==9?"T6t5":"t6_T5"]:  0,
            [formik?.values?.[0]?.holdStatus==9?"P1":"p1"]: +x.payoutR1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P2":"p2"]: +x.payoutR2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P3":"p3"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P4":"p4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P5":"p5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P6":"p6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R1":"r1"]: +x?.PayoutRatep1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R2":"r2"]: +x?.PayoutRatep2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R3":"r3"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R4":"r4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R5":"r5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R6":"r6"]: 0,
          }
        : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
        ? {
          [formik?.values?.[0]?.holdStatus==9?"T1":"t1"]: +x.BooksCollectionTargetT1 || 0,
          [formik?.values?.[0]?.holdStatus==9?"T2":"t2"]: +x.BooksCollectionTargetT2 || 0,
          [formik?.values?.[0]?.holdStatus==9?"T3":"t3"]: +x.BooksCollectionTargetT3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T4":"t4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T5":"t5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T6":"t6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T2t1":"t2_T1"]:  +x.BooksCollectionTargetT2T1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T3t2":"t3_T2"]:  +x.BooksCollectionTargetT3T2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T4t3":"t4_T3"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T5t4":"t5_T4"]:  0,
            [formik?.values?.[0]?.holdStatus==9?"T6t5":"t6_T5"]:  0,
            [formik?.values?.[0]?.holdStatus==9?"P1":"p1"]: +x.payoutR1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P2":"p2"]: +x.payoutR2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P3":"p3"]: +x.payoutR3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P4":"p4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P5":"p5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P6":"p6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R1":"r1"]: +x?.PayoutRatep1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R2":"r2"]: +x?.PayoutRatep2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R3":"r3"]: +x?.PayoutRatep3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R4":"r4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R5":"r5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R6":"r6"]: 0,
          }
        : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t4"
        ? {
          [formik?.values?.[0]?.holdStatus==9?"T1":"t1"]: +x.BooksCollectionTargetT1 || 0,
          [formik?.values?.[0]?.holdStatus==9?"T2":"t2"]: +x.BooksCollectionTargetT2 || 0,
          [formik?.values?.[0]?.holdStatus==9?"T3":"t3"]: +x.BooksCollectionTargetT3 || 0,
          [formik?.values?.[0]?.holdStatus==9?"T4":"t4"]: +x.BooksCollectionTargetT4 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T5":"t5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T6":"t6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T2t1":"t2_T1"]:  +x.BooksCollectionTargetT2T1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T3t2":"t3_T2"]:  +x.BooksCollectionTargetT3T2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T4t3":"t4_T3"]:  +x.BooksCollectionTargetT4T3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T5t4":"t5_T4"]:   0,
            [formik?.values?.[0]?.holdStatus==9?"T6t5":"t6_T5"]:  0,
            [formik?.values?.[0]?.holdStatus==9?"P1":"p1"]: +x.payoutR1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P2":"p2"]: +x.payoutR2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P3":"p3"]: +x.payoutR3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P4":"p4"]: +x.payoutR4 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P5":"p5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P6":"p6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R1":"r1"]: +x?.PayoutRatep1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R2":"r2"]: +x?.PayoutRatep2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R3":"r3"]: +x?.PayoutRatep3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R4":"r4"]: +x?.PayoutRatep4 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R5":"r5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R6":"r6"]: 0,
          }
        : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t5"
        ? {
            [formik?.values?.[0]?.holdStatus==9?"T1":"t1"]: +x.BooksCollectionTargetT1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T2":"t2"]: +x.BooksCollectionTargetT2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T3":"t3"]: +x.BooksCollectionTargetT3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T4":"t4"]: +x.BooksCollectionTargetT4 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T5":"t5"]: +x.BooksCollectionTargetT5 ? +x.BooksCollectionTargetT5 : 0,
            [formik?.values?.[0]?.holdStatus==9?"T6":"t6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T2t1":"t2_T1"]:  +x.BooksCollectionTargetT2T1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T3t2":"t3_T2"]:  +x.BooksCollectionTargetT3T2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T4t3":"t4_T3"]:  +x.BooksCollectionTargetT4T3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T5t4":"t5_T4"]:  +x.BooksCollectionTargetT5T4 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T6t5":"t6_T5"]:  0,
            [formik?.values?.[0]?.holdStatus==9?"P1":"p1"]: +x.payoutR1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P2":"p2"]: +x.payoutR2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P3":"p3"]: +x.payoutR3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P4":"p4"]: +x.payoutR4 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P5":"p5"]: +x.payoutR5 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P6":"p6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R1":"r1"]: +x?.PayoutRatep1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R2":"r2"]: +x?.PayoutRatep2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R3":"r3"]: +x?.PayoutRatep3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R4":"r4"]: +x?.PayoutRatep4 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R5":"r5"]: +x?.PayoutRatep5 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R6":"r6"]: 0,
          }
        : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t6"
        ? {
            [formik?.values?.[0]?.holdStatus==9?"T1":"t1"]: +x.BooksCollectionTargetT1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T2":"t2"]: +x.BooksCollectionTargetT2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T3":"t3"]: +x.BooksCollectionTargetT3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T4":"t4"]: +x.BooksCollectionTargetT4 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T5":"t5"]: +x.BooksCollectionTargetT5 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T6":"t6"]: +x?.BooksCollectionTargetT6 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T2t1":"t2_T1"]: +x.BooksCollectionTargetT2T1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T3t2":"t3_T2"]: +x.BooksCollectionTargetT3T2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T4t3":"t4_T3"]: +x.BooksCollectionTargetT4T3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T5t4":"t5_T4"]: +x.BooksCollectionTargetT5T4 || 0,
            [formik?.values?.[0]?.holdStatus==9?"T6t5":"t6_T5"]: +x.BooksCollectionTargetT6T5 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P1":"p1"]: +x.payoutR1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P2":"p2"]: +x.payoutR2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P3":"p3"]: +x.payoutR3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P4":"p4"]: +x.payoutR4 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P5":"p5"]: +x.payoutR5 || 0,
            [formik?.values?.[0]?.holdStatus==9?"P6":"p6"]: +x.payoutR6 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R1":"r1"]: +x?.PayoutRatep1 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R2":"r2"]: +x?.PayoutRatep2 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R3":"r3"]: +x?.PayoutRatep3 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R4":"r4"]: +x?.PayoutRatep4 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R5":"r5"]: +x?.PayoutRatep5 || 0,
            [formik?.values?.[0]?.holdStatus==9?"R6":"r6"]: +x?.PayoutRatep6 || 0,
          }
        : {
            [formik?.values?.[0]?.holdStatus==9?"T1":"t1"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T2":"t2"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T3":"t3"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T4":"t4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T5":"t5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T6":"t6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T2t1":"t2_T1"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T3t2":"t3_T2"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T4t3":"t4_T3"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T5t4":"t5_T4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"T6t5":"t6_T5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P1":"p1"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P2":"p2"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P3":"p3"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P4":"p4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P5":"p5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"P6":"p6"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R1":"r1"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R2":"r2"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R3":"r3"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R4":"r4"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R5":"r5"]: 0,
            [formik?.values?.[0]?.holdStatus==9?"R6":"r6"]: 0,
          }),
      ...(formik?.values?.[0]?.holdStatus==9&&{Volume:formik?.values?.[0]?.holdStatus==9?x.TEPvol||x?.volume : ""}),
      ...(formik?.values?.[0]?.holdStatus!=9&&{volume:formik?.values?.[0]?.holdStatus!=9?x.TEPvol||x?.volume : ""}),
      [formik?.values?.[0]?.holdStatus==9?"DelayPlan":"delayPlan"]: x.delayPlan,
      [formik?.values?.[0]?.holdStatus==9?"Amount":"amount"]: x?.TEPamount||x.amount,
      [formik?.values?.[0]?.holdStatus==9?"Industry":"industry"]: x.industry ? parseInt(x.industry) : 0,
      [formik?.values?.[0]?.holdStatus==9?"Billing":"billing"]: x.billing ? +x.billing : 0,
      [formik?.values?.[0]?.holdStatus==9?"Installation":"installation"]: x.installation ? +x.installation : 0,
      [formik?.values?.[0]?.holdStatus==9?"Collection":"collection"]: Math.round(x.collection ? +x.collection : 0),
      [formik?.values?.[0]?.holdStatus==9?"PerTr":"perTr"]:Math.round(x?.TEPpertr||x.perTr),
      // 
//       parseInt(currentRecord.TEPpertr),
// parseInt(currentRecord.TEPamount),
      userrole: userRole,
      fyear: x?.fyear,
      userid: +userId,
      username: userName,
      oldBillingQty: x.oldBillingQty,
      oldInstallation: x.oldInstallation,
      oldCollection: Math.round(x.oldCollection),
      oldOpeningStock: x.oldOpeningStock,
      closingOutStandingMonth_1: x.closingOutStandingMonth_1,
      closingOutStandingMonth_2: x.closingOutStandingMonth_2,
      closingOutStandingMonth_3: x.closingOutStandingMonth_3,
      bgLacsCurrentYear: x.bGLacsCurrentYear,
      SlabAchieved: x.slab,
      TopTarget: x?.dropdownscheme?.toString(),
      stateName: x?.stateName,
      closingStock: x?.closingStock,
      closingOutStanding: x?.closingOutStanding,
      billingFactorCOS: x?.billingFactorCOS,
    }));

    let extraValidation = {};
    formik?.values?.map((x, index) => {
      let topTargetDetails = x?.dropdownscheme;
      let errors = [];
      if (topTargetDetails?.length == 0) {
        errors.push("Please select top target");
      }
      if (!x.industry) {
        errors.push("Please enter industry");
      }
      // if (!x.openingStockDays) {
      //   errors.push("Please enter open stock days");
      // }
      // if (!x.outStandingDays) {
      //   errors.push("Please enter outstanding days");
      // }
      if (!x.billing) {
        errors.push("Please enter billing");
      }
      if (!x.installation) {
        errors.push("Please enter installation");
      }
      if (!x.collection) {
        errors.push("Please enter collection");
      }
      if (!x.delayPlan && x?.schemeTypeId == 1) {
        errors.push("Please enter Installtion Plan Nos");
      }
      if (!x.TEPvol) {
        errors.push("Please enter volume");
      }
      //TEPvol
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(x?.BooksCollectionTargetT6 || 0) <=
          parseInt(x?.BooksCollectionTargetT5 || 0)
      ) {
        errors.push("T6 should be greater than T5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(x?.BooksCollectionTargetT5 || 0) <=
          parseInt(x?.BooksCollectionTargetT4 || 0)
      ) {
        errors.push("T5 should be greater than T4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(x?.BooksCollectionTargetT4 || 0) <=
          parseInt(x?.BooksCollectionTargetT3 || 0)
      ) {
        errors.push("T4 should be greater than T3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(x?.BooksCollectionTargetT3 || 0) <=
          parseInt(x?.BooksCollectionTargetT2 || 0)
      ) {
        errors.push("T3 should be greater than T2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(x?.BooksCollectionTargetT2 || 0) <=
          parseInt(x?.BooksCollectionTargetT1 || 0)
      ) {
        errors.push("T2 should be greater than T1");
      }
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(x?.PayoutRatep6 || 0) <= parseInt(x?.PayoutRatep5 || 0)
      ) {
        errors.push("R6 should be greater than R5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(x?.PayoutRatep5 || 0) <= parseInt(x?.PayoutRatep4 || 0)
      ) {
        errors.push("R5 should be greater than R4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(x?.PayoutRatep4 || 0) <= parseInt(x?.PayoutRatep3 || 0)
      ) {
        errors.push("R4 should be greater than R3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(x?.PayoutRatep3 || 0) <= parseInt(x?.PayoutRatep2 || 0)
      ) {
        errors.push("R3 should be greater than R2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(x?.PayoutRatep2 || 0) <= parseInt(x?.PayoutRatep1 || 0)
      ) {
        errors.push("R2 should be greater than R1");
      }
      if (!x.PayoutRatep1) {
        errors.push("Please enter R1");
      }
      if (!x.PayoutRatep2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter R2");
      }
      if (!x.PayoutRatep3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter R3");
      }
      if (!x.PayoutRatep4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter R4");
      }
      if (!x.PayoutRatep5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter R5");
      }
      if (!x.PayoutRatep6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter R6");
      }
      if (!x.BooksCollectionTargetT1) {
        errors.push("Please enter T1");
      }
      if (!x.BooksCollectionTargetT2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter T2");
      }
      if (!x.BooksCollectionTargetT3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter T3");
      }
      if (!x.BooksCollectionTargetT4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter T4");
      }
      if (!x.BooksCollectionTargetT5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter T5");
      }
      if (!x.BooksCollectionTargetT6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter T6");
      }
      if (errors.length > 0) {
        extraValidation[x?.dealerCode] = errors;
      }
    });

    setCodeError(Object?.keys(extraValidation || {}));
    if (Object?.keys?.(extraValidation)?.length != 0) {
      showErrorDialog(extraValidation);
    } else if (Object?.keys?.(extraValidation)?.length == 0) {
      setShowSubmitModal(true);
      setPayloadData(data);
      setToggleSubmitBtn("submit");
      // await axiosAPI
      // .post(`UpdateScheme/DraftToSchemeGenerate`, data)
      // .then((result) => {
      //   message.success({
      //     content: "Scheme successfully generated",
      //     className: "custom-message",
      //   });
      //   const userData = decryptData(sessionStorage.getItem("userInfo"));
      //   const userId = userData.userId;
      //   const demoParams = {
      //     fyear: currentFiscalYear,
      //     schemeType: 6,
      //     userid: userId,
      //   };          navigate("/collection/allSchemes");

      // });
    }
   
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    if (order == "") {
      setOrder("desc");
      setOrderBy(property);
      return;
    }
    if (order == "desc") {
      setOrder("asc");
      setOrderBy(property);
      return;
    }
    if (order == "asc") {
      setOrder("desc");
      setOrderBy(property);
      return;
    }
  };
  const handleErrorModal = (firstItem, secondItem, opt = false) => {
    setOpenErrorModal(opt ? opt : !openErrorModal);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };

  const useCreateSchemeColumns = (formik) => {

    const getDropdown = () => [
      {
        title: "Top Slab",
        width: "150px",
        render: (x, y, index) => {
          const handleChange = (value) => {

            let checkValue1 = value?.includes("t1");
            let checkValue2 = value?.includes("t2");
            let checkValue3 = value?.includes("t3");
            let checkValue4 = value?.includes("t4");
            let checkValue5 = value?.includes("t5");
            let checkValue6 = value?.includes("t6");
            const handleDropDownScheme = () => {
              return checkValue6
                ? ["t1", "t2", "t3", "t4", "t5", "t6"]
                : checkValue5
                ? ["t1", "t2", "t3", "t4", "t5"]
                : checkValue4
                ? ["t1", "t2", "t3", "t4"]
                : checkValue3
                ? ["t1", "t2", "t3"]
                : checkValue2
                ? ["t1", "t2"]
                : checkValue1
                ? ["t1"]
                : [];
            };
            const handleDropdownSchemes = handleDropDownScheme();

            formik.setFieldValue(index, {
              ...x,
              dropdownscheme: handleDropDownScheme() || null,
              ...(handleDropdownSchemes.includes("t6")
              ? {
              }:handleDropdownSchemes.includes("t5")
                ? {
                    BooksCollectionTargetT6T5: null,
                    PayoutRatep6: null,
                    BooksCollectionTargetT6: null,
                  }
                : handleDropdownSchemes.includes("t4")
                ? {
                    PayoutRatep5: null,
                    BooksCollectionTargetT5: null,
                    PayoutRatep6: null,
                    BooksCollectionTargetT6: null,
                    BooksCollectionTargetT5T4: 0,
                  }
                : handleDropdownSchemes.includes("t3")
                ? {
                    PayoutRatep4: null,
                    BooksCollectionTargetT4: null,
                    PayoutRatep5: null,
                    BooksCollectionTargetT5: null,
                    PayoutRatep6: null,
                    BooksCollectionTargetT6: null,
                    BooksCollectionTargetT4T3: 0,
                  }
                : handleDropdownSchemes.includes("t2")
                ? {
                    PayoutRatep3: null,
                    BooksCollectionTargetT3: null,
                    PayoutRatep4: null,
                    BooksCollectionTargetT4: null,
                    PayoutRatep5: null,
                    BooksCollectionTargetT5: null,
                    PayoutRatep6: null,
                    BooksCollectionTargetT6: null,
                    BooksCollectionTargetT3T2: 0,
                  }
                : handleDropdownSchemes.includes("t1") && {
                    PayoutRatep2: null,
                    BooksCollectionTargetT2: null,
                    PayoutRatep3: null,
                    BooksCollectionTargetT3: null,
                    PayoutRatep4: null,
                    BooksCollectionTargetT4: null,
                    PayoutRatep5: null,
                    BooksCollectionTargetT5: null,
                    PayoutRatep6: null,
                    BooksCollectionTargetT6: null,
                    BooksCollectionTargetT2T1: 0,
                  }),

              BooksCollectionTargetT6T5: null,
            });
          };

          let arr1 = topTargetArr1(y?.topTargetArrState)
            

          return (
            <Select
              onChange={handleChange}
              value={y?.dropdownscheme}
              mode="multiple"
              autoClearSearchValue="multiple"
              className="w-100"
              allowClear
              onDeselect={false}
              showArrow={false}
              removeIcon={false}
            >
              {arr1?.map((x) => (
                <Select.Option value={x?.value} disabled={x?.disabled}>
                  {x?.name}
                </Select.Option>
              ))}

              {/* {topTargetArr
                  ?.filter((x) => !x?.disabled)
                  ?.map((x) => (
                    <Select.Option value={x?.value}>
                      {x?.name}
                    </Select.Option>
                  ))} */}
            </Select>
          );
        },
      },
    ];

    const getTargetT1 = () =>[
            {
              title: "T1",
              dataIndex: "BooksCollectionTargetT1",
              editable: true,
              width: "100px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.BooksCollectionTargetT1}
                  value={formik?.values?.[index]?.BooksCollectionTargetT1 || ""}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      BooksCollectionTargetT1: value,
                    });
                  }}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                      ? true
                      : false
                  }
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
          ]
    const getTargetT2 = () => [
            {
              title: "T2",
              dataIndex: "BooksCollectionTargetT2",
              editable: true,
              width: "100px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.BooksCollectionTargetT2}
                  value={formik?.values?.[index]?.BooksCollectionTargetT2 || ""}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      BooksCollectionTargetT2: value,
                    });
                  }}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                      ? true
                      : false
                  }
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
            {
              title: "T2-T1",
              dataIndex: "BooksCollectionTargetT2T1",
              width: "100px",
              render: (x, y, index) => {
                return formik?.values?.[index]?.dropdownscheme?.includes(
                  "t2"
                ) && formik?.values?.[index]?.dropdownscheme?.includes("t1") ? (
                  <div>{x}</div>
                ) : (
                  <div style={{ height: "26px" }} className={"input-disabled1"}>
                    &nbsp;
                  </div>
                );
              },
            },
          ]
    const getTargetT3 = () => [
            {
              title: "T3",
              dataIndex: "BooksCollectionTargetT3",
              editable: true,
              width: "100px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.BooksCollectionTargetT3}
                  value={formik?.values?.[index]?.BooksCollectionTargetT3 || ""}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      BooksCollectionTargetT3: value,
                    });
                  }}
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
            {
              title: "T3-T2",
              dataIndex: "BooksCollectionTargetT3T2",
              width: "100px",
              render: (x, y, index) => {
                return formik?.values?.[index]?.dropdownscheme?.includes(
                  "t3"
                ) && formik?.values?.[index]?.dropdownscheme?.includes("t2") ? (
                  <div>{x}</div>
                ) : (
                  <div style={{ height: "26px" }} className={"input-disabled1"}>
                    &nbsp;
                  </div>
                );
              },
            },
          ]
    const getTargetT4 = () =>[
            {
              title: "T4",
              dataIndex: "BooksCollectionTargetT1",
              editable: true,
              width: "100px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.BooksCollectionTargetT4}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                      ? true
                      : false
                  }
                  value={formik?.values?.[index]?.BooksCollectionTargetT4 || ""}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      BooksCollectionTargetT4: value,
                    });
                  }}
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
            {
              title: "T4-T3",
              dataIndex: "BooksCollectionTargetT4T3",
              width: "100px",
              render: (x, y, index) => {
                return formik?.values?.[index]?.dropdownscheme?.includes(
                  "t4"
                ) && formik?.values?.[index]?.dropdownscheme?.includes("t3") ? (
                  <div>{x}</div>
                ) : (
                  <div style={{ height: "26px" }} className={"input-disabled1"}>
                    &nbsp;
                  </div>
                );
              },
            },
          ]
    const getTargetT5 = () => [
            {
              title: "T5",
              dataIndex: "BooksCollectionTargetT5",
              editable: true,
              width: "100px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.BooksCollectionTargetT5}
                  value={formik?.values?.[index]?.BooksCollectionTargetT5 || ""}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                      ? true
                      : false
                  }
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      BooksCollectionTargetT5: value,
                    });
                  }}
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
            {
              title: "T5-T4",
              dataIndex: "BooksCollectionTargetT5T4",
              width: "100px",
              render: (x, y, index) => {
                return formik?.values?.[index]?.dropdownscheme?.includes(
                  "t5"
                ) && formik?.values?.[index]?.dropdownscheme?.includes("t4") ? (
                  <div>{x}</div>
                ) : (
                  <div style={{ height: "26px" }} className={"input-disabled1"}>
                    &nbsp;
                  </div>
                );
              },
            },
          ]
    const getTargetT6 = () => [
            {
              title: "T6",
              dataIndex: "BooksCollectionTargetT6",
              editable: true,
              width: "100px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.BooksCollectionTargetT6}
                  value={formik?.values?.[index]?.BooksCollectionTargetT6 || ""}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                      ? true
                      : false
                  }
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      BooksCollectionTargetT6: value,
                    });
                  }}
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
            {
              title: "T6-T5",
              dataIndex: "BooksCollectionTargetT6T5",
              width: "100px",
              render: (x, y, index) => {
                return formik?.values?.[index]?.dropdownscheme?.includes(
                  "t6"
                ) && formik?.values?.[index]?.dropdownscheme?.includes("t5") ? (
                  <div>{x}</div>
                ) : (
                  <div style={{ height: "26px" }} className={"input-disabled1"}>
                    &nbsp;
                  </div>
                );
              },
            },
          ]
    const getPayoutRateR1 = () =>[
            {
              title: "R1",
              dataIndex: "PayoutRatep1",
              editable: false,
              width: "120px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.PayoutRatep1}
                  value={formik?.values?.[index]?.PayoutRatep1 || ""}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                    if (value.length > 5) {
                      value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                    }
                    formik.setFieldValue(index, {
                      ...y,
                      PayoutRatep1: value,
                    });
                  }}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                      ? true
                      : false
                  }
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
          ]
    const getPayoutRateR2 = () =>[
            {
              title: "R2",
              dataIndex: "PayoutRatep2",
              editable: false,
              width: "120px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.PayoutRatep2}
                  value={formik?.values?.[index]?.PayoutRatep2 || ""}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                    if (value.length > 5) {
                      value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                    }
                    formik.setFieldValue(index, {
                      ...y,
                      PayoutRatep2: value,
                    });
                  }}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                      ? true
                      : false
                  }
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
          ]
    const getPayoutRateR3 = () =>[
            {
              title: "R3",
              dataIndex: "PayoutRatep3",
              editable: true,
              width: "120px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.PayoutRatep3}
                  value={formik?.values?.[index]?.PayoutRatep3 || ""}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                    if (value.length > 5) {
                      value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                    }
                    formik.setFieldValue(index, {
                      ...y,
                      PayoutRatep3: value,
                    });
                  }}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                      ? true
                      : false
                  }
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
          ]
    const getPayoutRateR4 = () =>[
            {
              title: "R4",
              dataIndex: "PayoutRatep4",
              editable: true,
              width: "120px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.PayoutRatep4}
                  value={formik?.values?.[index]?.PayoutRatep4 || ""}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                      ? true
                      : false
                  }
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                    if (value.length > 5) {
                      value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                    }
                    formik.setFieldValue(index, {
                      ...y,
                      PayoutRatep4: value,
                    });
                  }}
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
          ]
    const getPayoutRateR5 = () =>[
            {
              title: "R5",
              dataIndex: "PayoutRatep5",
              editable: true,
              width: "120px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.PayoutRatep5}
                  value={formik?.values?.[index]?.PayoutRatep5 || ""}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                      ? true
                      : false
                  }
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                    if (value.length > 5) {
                      value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                    }
                    formik.setFieldValue(index, {
                      ...y,
                      PayoutRatep5: value,
                    });
                  }}
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
          ]
    const getPayoutRateR6 = () =>[
            {
              title: "R6",
              dataIndex: "PayoutRatep3",
              editable: true,
              width: "120px",
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.PayoutRatep6}
                  value={formik?.values?.[index]?.PayoutRatep6 || ""}
                  disabled={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                      ? true
                      : false
                  }
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                    if (value.length > 5) {
                      value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                    }
                    formik.setFieldValue(index, {
                      ...y,
                      PayoutRatep6: value,
                    });
                  }}
                  placeholder={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                      ? ""
                      : "Type here"
                  }
                  className={
                    !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                      ? "input-disabled"
                      : "input1"
                  }
                  style={{ width: "90%", padding: "5px" }}
                />
              ),
            },
          ]
    const getPayoutP1 = () =>[
            {
              title: "P1",
              dataIndex: "payoutR1",
              width: "120px",
              render: (x, y, index) =>
                formik?.values?.[index]?.dropdownscheme?.includes("t1") ? (
                  <div>{x}</div>
                ) : (
                  <div className="h-24 input-disabled"></div>
                ),
            },
          ]
    const getPayoutP2 = () =>[
            {
              title: "P2",
              dataIndex: "payoutR2",
              width: "120px",
              render: (x, y, index) =>
                formik?.values?.[index]?.dropdownscheme?.includes("t2") ? (
                  <div>{x}</div>
                ) : (
                  <div className="h-24 input-disabled"></div>
                ),
            },
          ]
    const getPayoutP3 = () =>[
            {
              title: "P3",
              dataIndex: "payoutR3",
              width: "120px",
              render: (x, y, index) =>
                formik?.values?.[index]?.dropdownscheme?.includes("t3") ? (
                  <div>{x}</div>
                ) : (
                  <div className="h-24 input-disabled"></div>
                ),
            },
          ]
    const getPayoutP4 = () =>[
            {
              title: "P4",
              dataIndex: "payoutR4",
              width: "120px",
              render: (x, y, index) =>
                formik?.values?.[index]?.dropdownscheme?.includes("t4") ? (
                  <div>{x}</div>
                ) : (
                  <div className="h-24 input-disabled"></div>
                ),
            },
          ]
    const getPayoutP5 = () =>[
            {
              title: "P5",
              dataIndex: "payoutR5",
              width: "120px",
              render: (x, y, index) =>
                formik?.values?.[index]?.dropdownscheme?.includes("t5") ? (
                  <div>{x}</div>
                ) : (
                  <div className="h-24 input-disabled"></div>
                ),
            },
          ]
    const getPayoutP6 = () => [
            {
              title: "P6",
              dataIndex: "payoutR6",
              width: "120px",
              render: (x, y, index) =>
                formik?.values?.[index]?.dropdownscheme?.includes("t6") ? (
                  <div>{x}</div>
                ) : (
                  <div className="h-24 input-disabled"></div>
                ),
            },
          ]
    return [
      {
        title: (
          <div onClick={() => handleRequestSort("code")}>
            Dealer <br /> Code
          </div>
        ),
        // (<div className="text-center" >"Dealer" < br />Code </div >),
        dataIndex: "dealerCode",
        fixed: "left",
        width: "160px",
        sorter: true,
        defaultSortOrder: "ascend",
        key: "DealerCode",
        // filters:
        //   listData?.length &&
        //   listData?.map((item) => ({
        //     text: item.code,
        //     value: item.code,
        //   })),
        // onFilter: (value, record) => record.code.indexOf(value) === 0,
      },
      {
        title: (
          <div>
            Dealer <br /> Location
          </div>
        ),
        dataIndex: "dealerLocation",
        width: "190px",
        fixed: "left",
        sorter: true,
        defaultSortOrder: "ascend",
        key: "DealerLocation",
        // filters: listData.map((item) => ({
        //   text: item.Location,
        //   value: item.Location,
        // })),
        // onFilter: (value, record) => record.Location.indexOf(value) === 0,
      },
      {
        title: (
          <div>
            Dealer <br /> Name
          </div>
        ),
        dataIndex: "dealerName",
        // fixed: "left",
        width: "190px",
        sorter: true,
        defaultSortOrder: "ascend",
        key: "DealerName",
        // defaultSortOrder: "descend",
        // sorter: (a, b) => a.name && b.name && a.name.length - b.name.length,
        // filters: listData.map((item) => ({
        //   text: item.name,
        //   value: item.name,
        // })),
        // onFilter: (value, record) => record.name.indexOf(value) === 0,
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: "130px",
        sorter: true,
        defaultSortOrder: "ascend",
        key: "StateName",
        // filters: listData.map((item) => ({
        //   text: item.state,
        //   value: item.state,
        // })),

        // onFilter: (value, record) => record.state.indexOf(value) === 0,
      },
      {
        title: (
          <div>
            Territory <br /> Manager
          </div>
        ),
        dataIndex: "territoryManager",
        width: "210px",
        sorter: true,
        defaultSortOrder: "ascend",
        key: "TerritoryManager",
        // filters: listData.map((item) => ({
        //   text: item.Manager,
        //   value: item.Manager,
        // })),

        // onFilter: (value, record) => record.Manager.indexOf(value) === 0,
      },
      ...getDropdown(),
      {
        title: (
          <div>
            Start <br /> Month
          </div>
        ),
        dataIndex: "startMonth",
        width: "80px",
        render: (x) => dayjs(x).format("D-MMM"),
      },
      {
        title: (
          <div>
            End <br /> Month
          </div>
        ),
        dataIndex: "endMonth",
        width: "80px",
        render: (x) => dayjs(x).format("D-MMM"),
      },
      {
        title: "Industry",
        dataIndex: "industry",
        width: "90px",
        editable: true,
        render: (x, y, index) => (
          <input
            name={formik?.values?.[index]?.industry}
            placeholder="Type here"
            value={formik?.values?.[index]?.industry}
            // onBlur={()=>{
            //   calculateFormula(index)
            // }}
            onChange={(e) => {
              let value = e.target?.value.replace(/[^\d]/g, "");
              formik.setFieldValue(index, {
                ...y,
                industry: value,
              });
            }}
            className="input1"
            style={{ width: "90%", padding: "5px" }}
          />
        ),
      },
      {
        title: "B",
        dataIndex: "oldBillingQty",
        width: "50px",
      },
      {
        title: "I",
        dataIndex: "oldInstallation",
        width: "50px",
      },
      {
        title: "C",
        dataIndex: "oldCollection",
        width: "50px",
      },
      {
        title: `Opening Stock`,
        dataIndex: "stock",
        editable: false,
        children: [
          {
            title: "Nos",
            dataIndex: "oldOpeningStock",
            width: "80px",
          },
          {
            title: "Days",
            dataIndex: "openingStockDays",
            width: "100px",
          },
        ],
      },

      {
        title: "Closing Os. (In Lacs)",
        dataIndex: "stock",
        editable: false,
        textAlign: "center",

        children: [
          {
  title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_3",
            width: "80px",
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_2",
            width: "80px",
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_1",
            width: "80px",
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
              </span>
            ),
            dataIndex: "outStandingDays",
            width: "110px",
            //  editable: false,
            // render: (x, y, index) => (
            //   <input
            //     name={formik?.values?.[index]?.outStandingDays}
            //     placeholder="Type here"
            //     value={formik?.values?.[index]?.outStandingDays}
            //     // onBlur={()=>{
            //     //   calculateFormula(index)
            //     // }}
            //     onChange={(e) => {
            //       let value = e.target?.value.replace(/[^\d]/g, "");
            //       formik.setFieldValue(index, {
            //         ...y,
            //         outStandingDays: value,
            //       });
            //     }}
            //     className="input1"
            //     style={{ width: "90%", padding: "5px" }}
            //   />
            // ),
          },
        ],
      },
      {
        title: "BG",
        dataIndex: "bg",
        children: [
          {
            title: "Lacs",
            dataIndex: "bgLacsCurrentYear",
            width: "120px",
            render: (x, y) => x || 0,
          },
        ],
      },

      {
        title: `BILLING DETAILS`,
        dataIndex: "plan",
        editable: false,
        children: [
          {
            title: "B",
            dataIndex: "billing",
            editable: true,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.billing}
                placeholder="Type here"
                value={formik?.values?.[index]?.billing}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    billing: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "I",
            dataIndex: "installation",
            editable: false,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.installation}
                placeholder="Type here"
                value={formik?.values?.[index]?.installation}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    installation: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "C",
            dataIndex: "collection",
            editable: false,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.collection}
                placeholder="Type here"
                value={formik?.values?.[index]?.collection}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    collection: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ],
      },
      {
        title: "Installation plan",
        dataIndex: "instaPlan",
        editable: false,
        children: [
          {
            title: "Nos",
            dataIndex: "delayPlan",
            width: "200px",
            editable: false,
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.delayPlan}
                placeholder="Type here"
                value={formik?.values?.[index]?.delayPlan}
                // onBlur={()=>{
                //   calculateFormula(index)
                // }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  // if (schemeType == 1) {
                  value = value.replace(/^0+/, "");
                  // }
                  formik.setFieldValue(index, {
                    ...y,
                    delayPlan: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ],
      },
      {
        title: "Closing Stock",
        dataIndex: "closingStock",
        width: "120px",
        render: (x, y, index) => <div>{x}</div>,
      },
      {
        title: "Closing OutStanding (In Lacs)",
        dataIndex: "closingOutStanding",
        width: "120px",
        render: (x, y, index) => <div>{x}</div>,
      },
      {
        title: "Books Collection Targets (No. of Trs.)",
        dataIndex: "BooksCollectionTarget",
        editable: false,
        children: [
          ...getTargetT1(),
          ...getTargetT2(),
          ...getTargetT3(),
          ...getTargetT4(),
          ...getTargetT5(),
          ...getTargetT6(),
        ],
      },
      {
        title: "Payout Rate",
        dataIndex: "pr",
        editable: false,

        children: [
          ...getPayoutRateR1(),
          ...getPayoutRateR2(),
          ...getPayoutRateR3(),
          ...getPayoutRateR4(),
          ...getPayoutRateR5(),
          ...getPayoutRateR6(),
        ],
      },
      {
        title: "Payout",
        dataIndex: "payout",
        editable: false,

        children: [
          ...getPayoutP1(),
          ...getPayoutP2(),
          ...getPayoutP3(),
          ...getPayoutP4(),
          ...getPayoutP5(),
          ...getPayoutP6(),
        ],
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        editable: true,
        width: "180px",
        render: (x, y, index) => (
          <input
            name={formik?.values?.[index]?.reason}
            placeholder="Type here"
            value={formik?.values?.[index]?.reason}
            onChange={(e) => {
              let value = e.target?.value;
              const sanitizedValue = value
                ?.replace("<script>", "")
                ?.replace("</script>", "")
              //  .replace(/[^a-zA-Z0-9\s]/g, "");
              formik.setFieldValue(index, {
                ...y,
                reason: sanitizedValue,
              });
            }}
            className="input1"
            style={{ width: "90%", padding: "5px" }}
          />
        ),
      },
      {
        title: "Total Expected Payout",
        dataIndex: "TEP",
        editable: true,
        children: [
          {
            title: "Expected Volume",
            dataIndex: "TEPvol",
            editable: true,
            width: "150px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.TEPvol}
                placeholder="Type here"
                value={formik?.values?.[index]?.TEPvol||""}
                onBlur={() => {
                  calculateFormula(index);
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    TEPvol: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "Per Tr.",
            dataIndex: "TEPpertr",
            width: "150px",
            render: (x) => x || 0,
          },
          {
            title: "Amount",
            dataIndex: "TEPamount",
            width: "150px",
          },
        ],
      },
      {
        title: "Slab achieve",
        dataIndex: "slab",
        width: "100px",
        render: (x) => {
          return x != 0 ? x : "NO SLAB ACHIEVED";
        },
      },
    ];
  };

  // const selectedFirstMonth = new Date(startDate);
  // const selectedSecondMonth = new Date(endDate);
  const createColumns = useCreateSchemeColumns(
    formik,
    formik?.values
    // selectedFirstMonth,
    // selectedSecondMonth,
  );

  const calculateFormula = (index) => {
    let y = formik?.values[index];
    let {
      BooksCollectionTargetT2,
      BooksCollectionTargetT1,
      BooksCollectionTargetT3,
      BooksCollectionTargetT4,
      BooksCollectionTargetT5,
      BooksCollectionTargetT6,
      PayoutRatep1,
      PayoutRatep2,
      PayoutRatep3,
      PayoutRatep4,
      PayoutRatep5,
      PayoutRatep6,
      TEPamount,
      TEPvol,
      TEPpertr,
      dropdownscheme,
      oldOpeningStock,
      billing,
      installation,
      collection,
      closingOutStandingMonth_1,
      billingFactorCOS,
    } = formik?.values[index];

    const TEPvol1 = TEPvol ? parseInt(TEPvol) : 0;

    const handleDropDownSchemeCheckIndex = () => {
      let { t1, t2, t3, t4, t5, t6 } = dropdownscheme?.reduce(
        (a, v) => ({ ...a, [v]: v }),
        {}
      );
      return t1 && !t2 && !t3 && !t4 && !t5 && !t6
        ? "t1"
        : t1 && t2 && !t3 && !t4 && !t5 && !t6
        ? "t2"
        : t1 && t2 && t3 && !t4 && !t5 && !t6
        ? "t3"
        : t1 && t2 && t3 && t4 && !t5 && !t6
        ? "t4"
        : t1 && t2 && t3 && t4 && t5 && !t6
        ? "t5"
        : t1 && t2 && t3 && t4 && t5 && t6
        ? "t6"
        : "";
    };
    let validationSoftTarget = handleDropDownSchemeCheckIndex();

    // let calculateTepAMount =
    //   (TEPvol1 >= BooksCollectionTargetT1 && TEPvol1 < BooksCollectionTargetT2) ? (parseInt(TEPvol1) * parseInt(PayoutRatep1))
    //     : (TEPvol1 < BooksCollectionTargetT1 || TEPvol == 0) ? 0
    //       : TEPvol1 >= BooksCollectionTargetT2 && TEPvol1 < BooksCollectionTargetT3 ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
    //         : parseInt(TEPvol1) * parseInt(PayoutRatep3)

    // let calculateTepAMount =
    // TEPvol1 >= BooksCollectionTargetT1 && TEPvol1 < BooksCollectionTargetT2
    //   ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
    //   : TEPvol1 < BooksCollectionTargetT1 || TEPvol == 0
    //     ? 0
    //     : TEPvol1 >= BooksCollectionTargetT2 &&
    //       TEPvol1 < BooksCollectionTargetT3
    //       ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
    //       : parseInt(TEPvol1) * parseInt(PayoutRatep3);

    let calculateTepAMount1 = () => {
      // eslint-disable-next-line default-case
      switch (validationSoftTarget) {
        case "t1":
          return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
          ? 0
          : parseInt(TEPvol1) 
            ? parseInt(TEPvol1) * parseInt(PayoutRatep1):0;          
        case "t2":
          return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
          ? 0
          : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT1))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT2))
            ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
            : parseInt(TEPvol1)
              ? parseInt(TEPvol1) * parseInt(PayoutRatep2):0;
        case "t3":
          return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
          ? 0
          : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT1))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT2))
            ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
            : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT2))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT3))
              ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
              : parseInt(TEPvol1)
                ? parseInt(TEPvol1) * parseInt(PayoutRatep3):0;
        case "t4":

        return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
        ? 0
        : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT1))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT2))
          ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
          : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT2))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT3))
            ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
            : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT3))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT4))
              ? parseInt(TEPvol1) * parseInt(PayoutRatep3)
              : parseInt(TEPvol1)
                ? parseInt(TEPvol1) * parseInt(PayoutRatep4):0;

        case "t5":
          return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
          ? 0
          : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT1))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT2))
            ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
            : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT2))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT3))
              ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
              : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT3))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT4))
                ? parseInt(TEPvol1) * parseInt(PayoutRatep3)
                : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT4))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT5))
                  ? parseInt(TEPvol1) * parseInt(PayoutRatep4)
                  : parseInt(TEPvol1)
                    ? parseInt(TEPvol1) * parseInt(PayoutRatep5):0;
        case "t6":
          return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
          ? 0
          : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT1))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT2))
            ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
            : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT2))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT3))
              ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
              : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT3))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT4))
                ? parseInt(TEPvol1) * parseInt(PayoutRatep3)
                : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT4))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT5))
                  ? parseInt(TEPvol1) * parseInt(PayoutRatep4)
                  : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT5))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT6))
                    ? parseInt(TEPvol1) * parseInt(PayoutRatep5)
                    : parseInt(TEPvol1)
                      ? parseInt(TEPvol1) * parseInt(PayoutRatep6)
                      : 0;

            
      }
    };
    let calculateTepAMount = calculateTepAMount1();

    let TEPpertr1Calculate = calculateTepAMount / TEPvol1;
    // console.log(validationSoftTarget,"validationSoftTarget",calculateTepAMount,TEPvol1,"TEPvol1 < BooksCollectionTargetT1",
    //   TEPvol1 < BooksCollectionTargetT1,"BooksCollectionTargetT1 && TEPvol1 < BooksCollectionTargetT2",BooksCollectionTargetT1 && TEPvol1 < BooksCollectionTargetT2,
    //   "BooksCollectionTargetT2 && TEPvol1 < BooksCollectionTargetT3",BooksCollectionTargetT2 && TEPvol1 < BooksCollectionTargetT3
    // )
    //Calculate ClosingStock
    const calculateClosingStock = () => {
      return oldOpeningStock + parseInt(billing) - installation;
    };
    const closingStock = calculateClosingStock();

    //Calculate Closing OutStanding
    const calculateClosingOutStanding = () => {
      const closingOutstanding = ((parseFloat(closingOutStandingMonth_1 * 100000))  
        + (parseInt(billing) * parseFloat(billingFactorCOS))) 
        - (parseInt(collection) * parseFloat(billingFactorCOS));
      
      return closingOutstanding / 100000;
    };

    const closingOutStanding = calculateClosingOutStanding().toFixed(2);

    let slabAchieve;

    if (validationSoftTarget == "t4") {
      slabAchieve =
        TEPvol1 >= BooksCollectionTargetT1 && TEPvol1 < BooksCollectionTargetT2
          ? "T1"
          : TEPvol1 < BooksCollectionTargetT1 || TEPvol == 0
          ? 0
          : TEPvol1 >= BooksCollectionTargetT2 &&
            TEPvol1 < BooksCollectionTargetT3
          ? "T2"
          : TEPvol1 >= BooksCollectionTargetT3 &&
            TEPvol1 < BooksCollectionTargetT4
          ? "T3"
          : "T4";
    } else if (validationSoftTarget == "t5") {
      slabAchieve =
        TEPvol1 >= BooksCollectionTargetT1 && TEPvol1 < BooksCollectionTargetT2
          ? "T1"
          : TEPvol1 < BooksCollectionTargetT1 || TEPvol == 0
          ? 0
          : TEPvol1 >= BooksCollectionTargetT2 &&
            TEPvol1 < BooksCollectionTargetT3
          ? "T2"
          : TEPvol1 >= BooksCollectionTargetT3 &&
            TEPvol1 < BooksCollectionTargetT4
          ? "T3"
          : TEPvol1 >= BooksCollectionTargetT4 &&
            TEPvol1 < BooksCollectionTargetT5
          ? "T4"
          : "T5";
    } else if (validationSoftTarget == "t6") {
      slabAchieve =
        TEPvol1 >= BooksCollectionTargetT1 && TEPvol1 < BooksCollectionTargetT2
          ? "T1"
          : TEPvol1 < BooksCollectionTargetT1 || TEPvol == 0
          ? 0
          : TEPvol1 >= BooksCollectionTargetT2 &&
            TEPvol1 < BooksCollectionTargetT3
          ? "T2"
          : TEPvol1 >= BooksCollectionTargetT3 &&
            TEPvol1 < BooksCollectionTargetT4
          ? "T3"
          : TEPvol1 >= BooksCollectionTargetT4 &&
            TEPvol1 < BooksCollectionTargetT5
          ? "T4"
          : TEPvol1 >= BooksCollectionTargetT5 &&
            TEPvol1 < BooksCollectionTargetT6
          ? "T5"
          : "T6";
    } else {
      slabAchieve =
        TEPvol1 >= BooksCollectionTargetT1 && TEPvol1 < BooksCollectionTargetT2
          ? "T1"
          : TEPvol1 < BooksCollectionTargetT1 || TEPvol == 0
          ? 0
          : TEPvol1 >= BooksCollectionTargetT2 &&
            TEPvol1 < BooksCollectionTargetT3
          ? "T2"
          : "T3";
    }

    formik?.setFieldValue(index, {
      ...y,
      BooksCollectionTargetT2T1:
        +(
          BooksCollectionTargetT2 &&
          BooksCollectionTargetT1 &&
          parseInt(BooksCollectionTargetT2 || 0) -
            parseInt(BooksCollectionTargetT1 || 0)
        ) || 0,
      BooksCollectionTargetT3T2: +(
        (BooksCollectionTargetT3 &&
          BooksCollectionTargetT2 &&
          parseInt(BooksCollectionTargetT3 || 0) -
            parseInt(BooksCollectionTargetT2 || 0)) ||
        0
      ),
      BooksCollectionTargetT4T3:
        +(
          BooksCollectionTargetT4 &&
          BooksCollectionTargetT3 &&
          parseInt(BooksCollectionTargetT4 || 0) -
            parseInt(BooksCollectionTargetT3 || 0)
        ) || 0,
      BooksCollectionTargetT5T4:
        +(
          BooksCollectionTargetT5 &&
          BooksCollectionTargetT4 &&
          parseInt(BooksCollectionTargetT5 || 0) -
            parseInt(BooksCollectionTargetT4 || 0)
        ) || 0,
      BooksCollectionTargetT6T5:
        +(
          BooksCollectionTargetT6 &&
          BooksCollectionTargetT5 &&
          parseInt(BooksCollectionTargetT6 || 0) -
            parseInt(BooksCollectionTargetT5 || 0)
        ) || 0,
      payoutR1:
        BooksCollectionTargetT1 && PayoutRatep1
          ? parseInt(BooksCollectionTargetT1 || 0) * parseInt(PayoutRatep1 || 0)
          : 0,
      payoutR2:
        BooksCollectionTargetT2 && PayoutRatep2
          ? parseInt(BooksCollectionTargetT2 || 0) * parseInt(PayoutRatep2 || 0)
          : 0,
      payoutR3:
        BooksCollectionTargetT3 && PayoutRatep3
          ? parseInt(BooksCollectionTargetT3 || 0) * parseInt(PayoutRatep3 || 0)
          : "",
      payoutR4:
        BooksCollectionTargetT4 && PayoutRatep4
          ? parseInt(BooksCollectionTargetT4 || 0) * parseInt(PayoutRatep4 || 0)
          : "",
      payoutR5:
        BooksCollectionTargetT5 && PayoutRatep5
          ? parseInt(BooksCollectionTargetT5 || 0) * parseInt(PayoutRatep5 || 0)
          : "",
      payoutR6:
        BooksCollectionTargetT6 && PayoutRatep6
          ? parseInt(BooksCollectionTargetT6 || 0) * parseInt(PayoutRatep6 || 0)
          : "",

      TEPpertr: Math.round(TEPpertr1Calculate),

      TEPamount: Math.round(calculateTepAMount),
      slab: slabAchieve,
      closingStock: closingStock,
      closingOutStanding: closingOutStanding,
    });

    if (
      BooksCollectionTargetT1 != null &&
      BooksCollectionTargetT2 != null &&
      +BooksCollectionTargetT2 <= +BooksCollectionTargetT1 &&
      (validationSoftTarget == "t2" ||
        validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("T2", "T1", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   BooksCollectionTargetT2: "",
      // });
    }

    if (
      BooksCollectionTargetT2 != null &&
      BooksCollectionTargetT3 != null &&
      +BooksCollectionTargetT3 <= +BooksCollectionTargetT2 &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("T3", "T2", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   BooksCollectionTargetT3: "",
      // });
    }

    if (
      BooksCollectionTargetT3 != null &&
      BooksCollectionTargetT4 != null &&
      +BooksCollectionTargetT4 <= +BooksCollectionTargetT3 &&
      (validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("T4", "T3", true);
      return false;
    }

    if (
      BooksCollectionTargetT4 != null &&
      BooksCollectionTargetT5 != null &&
      +BooksCollectionTargetT5 <= +BooksCollectionTargetT4 &&
      (validationSoftTarget == "t5" || validationSoftTarget == "t6")
    ) {
      handleErrorModal("T5", "T4", true);
      return false;
    }
    if (
      BooksCollectionTargetT5 != null &&
      BooksCollectionTargetT6 != null &&
      +BooksCollectionTargetT6 <= +BooksCollectionTargetT5 &&
      validationSoftTarget == "t6"
    ) {
      handleErrorModal("T6", "T5", true);
      return false;
    }
    if (
      PayoutRatep2 != null &&
      PayoutRatep1 != null &&
      +PayoutRatep2 <= +PayoutRatep1 &&
      (validationSoftTarget == "t2" ||
        validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("R2", "R1", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   PayoutRatep2: "",
      // });
    }

    if (
      PayoutRatep3 != null &&
      PayoutRatep2 != null &&
      +PayoutRatep3 <= +PayoutRatep2 &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("R3", "R2", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   PayoutRatep3: "",
      // });
    }

    if (
      PayoutRatep4 != null &&
      PayoutRatep3 != null &&
      +PayoutRatep4 <= +PayoutRatep3 &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("R4", "R3", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   PayoutRatep3: "",
      // });
    }

    if (
      PayoutRatep5 != null &&
      PayoutRatep4 != null &&
      +PayoutRatep5 <= +PayoutRatep4 &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("R5", "R4", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   PayoutRatep3: "",
      // });
    }

    if (
      PayoutRatep6 != null &&
      PayoutRatep5 != null &&
      +PayoutRatep6 <= +PayoutRatep5 &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("R6", "R5", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   PayoutRatep3: "",
      // });
    }
  };
  const handleRemoveFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };
  

    const handleSampleFile = () => {

      const headerStyle = {
        fill: {
          fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
        },
        font: {
          color: { rgb: "212121" }, // Black font color
          bold: true,
          sz: "12",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
          diagonal: { diagonalUp: true, diagonalDown: true },
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };
  
      const headerStyle1 = {
     
        font: {
          color: { rgb: "212121" }, // Black font color
          bold: true,
          sz: "12",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
          diagonal: { diagonalUp: true, diagonalDown: true },
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };
   if (formik?.values?.length == 0) {
          ToastifyShow("Something went wrong", "error")
          return false
        }

        let tab_text =ModificationCollectionSchemeDownload({
          collectionLock:"t6"
        })
        formik?.values?.length != 0 &&
        formik?.values.forEach((header) => {
          tab_text += `<tr ><td>${header?.dealerCode}</td>
    <td>${header?.dealerLocation}</td>
    <td>${header?.dealerName || ""}</td>
    <td>${header?.stateName}</td>
    <td>${header?.territoryManager}</td>
    <td>${dayjs(header?.startMonth).format("D-MMM")|| ""}</td>
    <td>${dayjs(header?.endMonth).format("D-MMM") || ""}</td>
    <td>${header?.dropdownscheme?.includes("t6")
              ? "T6"
              : header?.dropdownscheme?.includes("t5")
                ? "T5"
                : header?.dropdownscheme?.includes("t4")
                  ? "T4"
                  : header?.dropdownscheme?.includes("t3")
                    ? "T3"
                    : header?.dropdownscheme?.includes("t2")
                      ? "T2"
                      : header?.dropdownscheme?.includes("t1")
                        ? "T1"
                        : ""
            }
    </td>
    <td>${(header?.industry||"")}</td>
    <td>${header?.oldBillingQty}</td>
    <td>${header?.oldInstallation}</td>
    <td>${header?.oldCollection}</td>
    <td>${header?.oldOpeningStock || 0}</td>
    <td>${header?.openingStockDays || 0}</td>
      <td>${header?.closingOutStandingMonth_3}</td>
      <td>${header?.closingOutStandingMonth_2}</td>
      <td>${header?.closingOutStandingMonth_1}</td>
      <td>${header?.outStandingDays || 0}</td>
      <td>${header?.bGLacsCurrentYear}</td>
      <td>${header?.billing}</td>
      <td>${header?.installation}</td>
      <td>${header?.collection}</td>
      <td>${header?.delayPlan || 0}</td>
      <td>${(header?.t1||"")}</td>
      <td>${(header?.t2||"")}</td>
      <td>${(header?.t3||"")}</td>
      <td>${(header?.t4||"")}</td>
      <td>${(header?.t5||"")}</td>
      <td>${(header?.t6||"")}</td>
      <td>${(header?.r1||"")}</td>
      <td>${(header?.r2||"")}</td>
      <td>${(header?.r3||"")}</td>
      <td>${(header?.r4||"")}</td>
      <td>${(header?.r5||"")}</td>
      <td>${(header?.r6||"")}</td>
      <td>${ header?.reason||""}</td>
      <td>${header?.volume||""}</td>
      
    </tr>`;
        });
  
        tab_text += "</table>";
  
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
  
      // Apply styles to the table using a class
  
  
      // generate workbook with XLSXStyle
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      
  
  
  
        function xSeparateKeys(obj) {
          return Object.keys(obj).filter(key => 
            ((key.startsWith('X') && key !== 'X2'&&key!=='X1')|| 
            (key.startsWith('Z') && key !== 'Z2') ||
             (key.startsWith('Y') && key !== 'Y2') ||
             (key.startsWith('ADD') && key !== 'ADD1' && key !== 'ADD2')||
             (key.startsWith('AA') && key !== 'AA2')||
             (key.startsWith('AB') && key !== 'AB2')||
             (key.startsWith('AC') && key !== 'AC2')||
             (key.startsWith('AD') && key !== 'AD2'&&key!=='AD1')||
             (key.startsWith('AE') && key !== 'AE2')||
             (key.startsWith('AF') && key !== 'AF2')||
             (key.startsWith('AG') && key !== 'AG2')||
             (key.startsWith('AH') && key !== 'AH2')||
             (key.startsWith('AI') && key !== 'AI2')||
             (key.startsWith('AJ') && key !== 'AJ1')||
             (key.startsWith('AK') && key !== 'AK1')||
             (key.startsWith('H') && key !== 'H1')||
             (key.startsWith('W')&&key!=='W1'&&key!=='W2')||
             (key.startsWith('T')&&key!=='T1'&&key!=='T2')||
             (key.startsWith('U')&&key!=='U1'&&key!=='U2')||
             (key.startsWith('V')&&key!=='V1'&&key!=='V2')||
             (key.startsWith('I') && key !== 'I1')
            ))
        }
        const xKeys= xSeparateKeys(wb?.Sheets?.Sheet1);
  
  
      Object?.keys(wb?.Sheets?.Sheet1 || {})
        ?.filter(
          (x) =>
            x != "!rows" &&
            x != "!cols" &&
            x != "!merges" &&
            x != "!ref" &&
            x != "!fullref" &&
            !xKeys?.includes(x)
        )
        ?.forEach((x) => {
          wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle;
        });
  
        Object?.keys(wb?.Sheets?.Sheet1 || {})
        ?.filter(
          (x) =>xKeys?.includes(x)
        )
        ?.forEach((x) => {
          wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle1;
        });
      // Dynamically set column widths
  
      wb.Sheets[wb.SheetNames[0]]["!cols"] = [
        { wch: 15 },
        { wch: 20 },
        { wch: 30 },
        { wch: 10 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
  
      // Dynamically set row heights
      wb.Sheets[wb.SheetNames[0]]["!rows"] = [
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
  
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        // Add more row heights as needed
      ];
  
      // remove element
      document.body.removeChild(elt);
  
      XLSX.writeFile(
        wb,
        (formik?.values?.[0]?.type == "1" || formik?.values?.[0]?.type == "")
          ? "With_Delivery_Plan.xlsx"
          : "Without_Delivery_Plan.xlsx"
      );
      }

  useEffect(() => {
    if (checkCalculate) {
      checkCalculateArr?.forEach((x) => calculateFormula(x));
      setCheckCalculate(false);
    }
  }, [checkCalculate]);
  const handleFileUpload = (e) => {
    const maxFileSize = 5 * 1024 * 1024; // Set maximum file size to 5MB
    const files = e.target.files;

    // Check if any file exceeds the maximum file size limit
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxFileSize) {
        ToastifyShow(`File ${files[i].name} is too large. Maximum file size is 5MB.`, "error");
        return;
      }
  
      // Check file headers for XLSX files
      if (!isValidFileHeader(files[i])) {
        ToastifyShow(`Invalid file header for ${files[i].name}.`, "error");
        return;
      }
  
      // Check content type for XLSX files
      if (!isValidContentType(files[i])) {
        ToastifyShow(`Invalid content type for ${files[i].name}.`, "error");
        return;
      }
  
    }

    if (files[0].name.split('.')?.[1] !== 'xlsx') {
      ToastifyShow("Please upload an XLSX file only.", "error");
      return;
    }
  
    const reader = new FileReader();
    reader.readAsBinaryString(files[0]);
  
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  
    reader.onload = async(e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      let calArr = [];
      // schemeLockedStatus?.t1 || userRole == "ZADM"
      let dataItem= formik?.values?.map((y, index) => {

        if (parsedData.find((x) => x["DEALER CODE"] === y?.dealerCode)) {
    
          //TOP SLAB
          let z = parsedData.find((x) => x["DEALER CODE"] === y?.dealerCode);

          let dropdownscheme= z?.["TOP SLAB"]?.toUpperCase()=="T6"&&y?.topTargetArrState?.t6?["t1","t2","t3","t4","t5","t6"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T5"&&y?.topTargetArrState?.t5?["t1","t2","t3","t4","t5"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T4"&&y?.topTargetArrState?.t4?["t1","t2","t3","t4"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T3"?["t1","t2","t3"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T2"?["t1","t2"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T1"?["t1"]:["t1","t2"]

           let paget1=dropdownscheme.includes("t1"||"t2"||"t3"||"t4"||"t5"||"t6") 
           let paget2=dropdownscheme.includes("t2"||"t3"||"t4"||"t5"||"t6") 
           let paget3=dropdownscheme.includes("t3"||"t4"||"t5"||"t6") 
           let paget4=dropdownscheme.includes("t4"||"t5"||"t6") 
           let paget5=dropdownscheme.includes("t5"||"t6") 
           let paget6=dropdownscheme.includes("t6") 

            let updatedR1 = paget1?parseFiveDigitNumber(z?.["PAYOUT RATE"]) : 0; //1200
            let updatedR2 = paget2?parseFiveDigitNumber(z?.["__EMPTY_11"]):0; //1300
            let updatedR3 = paget3?parseFiveDigitNumber(z?.["__EMPTY_12"]) :0; //1400
            let updatedR4 = paget4?parseFiveDigitNumber(z?.["__EMPTY_13"]) : 0; //1500
            let updatedR5 = paget5?parseFiveDigitNumber(z?.["__EMPTY_14"]) : 0; //1600
            let updatedR6 = paget6?parseFiveDigitNumber(z?.["__EMPTY_15"]) : 0; //1700
      
            let updateT1 = paget1?parseInt(z?.["BOOKS COLLECTION TARGETS (NO. OF TRS.)"]): 0; //60
            let updateT2 = paget2?parseInt(z?.["__EMPTY_6"]) : 0; //700
            let updateT3 = paget3?parseInt(z?.["__EMPTY_7"]) : 0; //800
            let updateT4 = paget4?parseInt(z?.["__EMPTY_8"]) : 0; //900
            let updateT5 = paget5?parseInt(z?.["__EMPTY_9"]) : 0; //1000
            let updateT6 = paget6?parseInt(z?.["__EMPTY_10"])  : 0; //1100


            calArr.push(index);

          return {
            ...y,
            dropdownscheme:dropdownscheme,
            [`industry`]:parseInt(z?.INDUSTRY) || 0, //100
            [`billing`]:parseInt(z?.["BILLING DETAILS"]) || 0, //200
            [`installation`]:parseInt(z?.__EMPTY_4) || 0, //300
            [`collection`]:parseInt(z?.__EMPTY_5) || 0,
            [`delayPlan` ]: parseInt(z?.[`Installation Plan`]) || 0, //500
            [`BooksCollectionTargetT1`]: updateT1 || 0, //600
            [`BooksCollectionTargetT2`]: updateT2||0,//700
            [`BooksCollectionTargetT3`]: updateT3||0,//800
            [`BooksCollectionTargetT4`]: updateT4||0,//900
            [`BooksCollectionTargetT5`]: updateT5||0,//1000
            [`BooksCollectionTargetT6`]: updateT6||0,//1100
            [`PayoutRatep1`]: updatedR1||0, //1200
            [`PayoutRatep2`]: updatedR2||0, //1300
            [`PayoutRatep3`]: updatedR3||0, //1400
            [`PayoutRatep4`]: updatedR4||0, //1500
            [`PayoutRatep5`]: updatedR5||0, //1600
            [`PayoutRatep6`]: updatedR6||0, //1700
            [`reason`]:z?.REMARKS || "", //1700,
            [`TEPvol`]: parseInt(z?.[`Expected Volume`]) || 0,

          };
        } else {
          return y;
        }
      });
      await setCheckCalculate(true);
      await setCheckCalculateArr(calArr);
      await formik?.setValues(dataItem);
      ToastifyShow("File Uploaded successfully","success")
    };
  };
  const menu = (
    <Menu>
      <Menu.Item>
        {selectedFiles?.length == 0 && (
          <div className="custom-file-input">
            <input type="file" accept=".xlsx" onChange={handleFileUpload} />
            <label htmlFor="fileInput">Choose File</label>
          </div>
        )}
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <Button onClick={() => handleRemoveFile(index)} className="mx-1">
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </Menu.Item>
      <Menu.Item onClick={handleSampleFile}>Download Sample File</Menu.Item>
    </Menu>
  );
  return (
    <div className="create_scheme_wrap approval-schme-row-cdo">

<Col
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px 0px",
              width:"100%",
              justifyContent:"space-between"
            }}
          >


<div></div>

            <Space
              style={{
                padding: "0",
                paddingTop: "14px",
              }}
              direction="vertical"
            >
              <Tooltip title={"Import/Export to Excel"}>
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button className="h-2">Import/Export to Excel</Button>
              </Dropdown>
              </Tooltip> 
            </Space>

          </Col>
      <Form>
        <Table
          id="myTable1"
          showSorterTooltip={false}
          className="header"
          summary={(pageData) => {
            const fieldSum = {
              b: 0,
              i: 0,
              c: 0,
              totalR1: 0,
              totalR2: 0,
              totalR3: 0,
              totalR4: 0,
              totalR5: 0,
              totalR6: 0,
              t1: 0,
              t2: 0,
              t3: 0,
              t4: 0,
              t5: 0,
              t6: 0,
              T2_T1: 0,
              T3_T2: 0,
              T4_T3: 0,
              T5_T4: 0,
              T6_T5: 0,
              vol: 0,
              perTr: 0,
              totalAmount: 0,
            };
            pageData.forEach(
              ({
                payoutR1,
                payoutR2,
                payoutR3,
                payoutR4,
                payoutR5,
                payoutR6,
                TEPamount,
                BooksCollectionTargetT1,
                BooksCollectionTargetT2,
                BooksCollectionTargetT3,
                BooksCollectionTargetT4,
                BooksCollectionTargetT5,
                BooksCollectionTargetT6,
                BooksCollectionTargetT2T1,
                BooksCollectionTargetT3T2,
                BooksCollectionTargetT4T3,
                BooksCollectionTargetT5T4,
                BooksCollectionTargetT6T5,
                TEPvol,
                TEPpertr,
                dropdownscheme,
              }) => {
                fieldSum.totalR1 +=
                  payoutR1 && dropdownscheme?.includes("t1")
                    ? parseInt(payoutR1 || 0)
                    : 0;
                fieldSum.totalR2 +=
                  payoutR2 && dropdownscheme?.includes("t2")
                    ? parseInt(payoutR2 || 0)
                    : 0;
                fieldSum.totalR3 +=
                  payoutR3 && dropdownscheme?.includes("t3")
                    ? parseInt(payoutR3 || 0)
                    : 0;
                fieldSum.totalR4 +=
                  payoutR4 && dropdownscheme?.includes("t4")
                    ? parseInt(payoutR4 || 0)
                    : 0;
                fieldSum.totalR5 +=
                  payoutR5 && dropdownscheme?.includes("t5")
                    ? parseInt(payoutR5 || 0)
                    : 0;
                fieldSum.totalR6 +=
                  payoutR6 && dropdownscheme?.includes("t6")
                    ? parseInt(payoutR6 || 0)
                    : 0;
                fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
                fieldSum.t1 +=
                  BooksCollectionTargetT1 && dropdownscheme?.includes("t1")
                    ? parseInt(BooksCollectionTargetT1)
                    : 0;
                fieldSum.t2 +=
                  BooksCollectionTargetT2 && dropdownscheme?.includes("t2")
                    ? parseInt(BooksCollectionTargetT2)
                    : 0;
                fieldSum.t3 +=
                  BooksCollectionTargetT3 && dropdownscheme?.includes("t3")
                    ? parseInt(BooksCollectionTargetT3)
                    : 0;
                fieldSum.t4 +=
                  BooksCollectionTargetT4 && dropdownscheme?.includes("t4")
                    ? parseInt(BooksCollectionTargetT4)
                    : 0;
                fieldSum.t5 +=
                  BooksCollectionTargetT5 && dropdownscheme?.includes("t5")
                    ? parseInt(BooksCollectionTargetT5)
                    : 0;
                fieldSum.t6 +=
                  BooksCollectionTargetT6 && dropdownscheme?.includes("t6")
                    ? parseInt(BooksCollectionTargetT6)
                    : 0;
                fieldSum.T2_T1 +=
                  BooksCollectionTargetT2T1 && dropdownscheme?.includes("t2")
                    ? parseInt(BooksCollectionTargetT2T1)
                    : 0;
                fieldSum.T3_T2 +=
                  BooksCollectionTargetT3T2 && dropdownscheme?.includes("t3")
                    ? parseInt(BooksCollectionTargetT3T2)
                    : 0;
                fieldSum.T4_T3 +=
                  BooksCollectionTargetT4T3 && dropdownscheme?.includes("t4")
                    ? parseInt(BooksCollectionTargetT4T3)
                    : 0;
                fieldSum.T5_T4 +=
                  BooksCollectionTargetT5T4 && dropdownscheme?.includes("t5")
                    ? parseInt(BooksCollectionTargetT5T4)
                    : 0;
                fieldSum.T6_T5 +=
                  BooksCollectionTargetT6T5 && dropdownscheme?.includes("t6")
                    ? parseInt(BooksCollectionTargetT6T5)
                    : 0;
                fieldSum.vol += TEPvol ? parseInt(TEPvol) : 0;
                fieldSum.perTr = parseInt(fieldSum.totalAmount / fieldSum.vol);
              }
            );
            return (
              <Table.Summary.Row
                style={{ fontWeight: "500", fontSize: "18px" }}
              >
                <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10}></Table.Summary.Cell>
                <Table.Summary.Cell index={11}></Table.Summary.Cell>
                <Table.Summary.Cell index={12}></Table.Summary.Cell>
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
                <Table.Summary.Cell index={15}></Table.Summary.Cell>
                <Table.Summary.Cell index={16}></Table.Summary.Cell>
                <Table.Summary.Cell index={17}></Table.Summary.Cell>
                <Table.Summary.Cell index={18}></Table.Summary.Cell>
                <Table.Summary.Cell index={19}></Table.Summary.Cell>
                <Table.Summary.Cell index={20}></Table.Summary.Cell>
                <Table.Summary.Cell index={21}></Table.Summary.Cell>
                <Table.Summary.Cell index={22}></Table.Summary.Cell>
                <Table.Summary.Cell index={23}></Table.Summary.Cell>
                <Table.Summary.Cell index={24}></Table.Summary.Cell>
                <Table.Summary.Cell index={25}></Table.Summary.Cell>
                <Table.Summary.Cell index={26}>
                  {fieldSum.t1 ? fieldSum.t1 : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={27}>
                  {fieldSum.t2 ? fieldSum.t2 : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={28}>
                  {fieldSum.T2_T1 ? fieldSum.T2_T1 : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={29}>
                  {fieldSum.t3 ? fieldSum.t3 : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={30}>
                  {fieldSum.T3_T2 ? fieldSum.T3_T2 : ""}
                </Table.Summary.Cell>
                
                  <Table.Summary.Cell index={31}>
                    {fieldSum.t4 ? fieldSum.t4 : ""}
                  </Table.Summary.Cell>
              
               
                  <Table.Summary.Cell index={32}>
                    {fieldSum.T4_T3 ? fieldSum.T4_T3 : ""}
                  </Table.Summary.Cell>
            

           
                  <Table.Summary.Cell index={33}>
                    {fieldSum.t5 ? fieldSum.t5 : ""}
                  </Table.Summary.Cell>
               
              
                  <Table.Summary.Cell index={34}>
                    {fieldSum.T5_T4 ? fieldSum.T5_T4 : ""}
                  </Table.Summary.Cell>
          

               
                  <Table.Summary.Cell index={35}>
                    {fieldSum.t6 ? fieldSum.t6 : ""}
                  </Table.Summary.Cell>
               
                  <Table.Summary.Cell index={36}>
                    {fieldSum.T6_T5 ? fieldSum.T6_T5 : ""}
                  </Table.Summary.Cell>
             

                <Table.Summary.Cell index={37}>
                  {fieldSum.t1 && fieldSum.totalR1
                    ? Math.round(fieldSum.totalR1 / fieldSum.t1)
                    : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={38}>
                  {fieldSum.t2 && fieldSum.totalR2
                    ? Math.round(fieldSum.totalR2 / fieldSum.t2)
                    : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={39}>
                  {fieldSum.t3 && fieldSum.totalR3
                    ? Math.round(fieldSum.totalR3 / fieldSum.t3)
                    : ""}
                </Table.Summary.Cell>

                
                  <Table.Summary.Cell index={40}>
                    {fieldSum.t4 && fieldSum.totalR4
                      ? Math.round(fieldSum.totalR4 / fieldSum.t4)
                      : ""}
                  </Table.Summary.Cell>
 
              
                  <Table.Summary.Cell index={41}>
                    {fieldSum.t5 && fieldSum.totalR5
                      ? Math.round(fieldSum.totalR5 / fieldSum.t5)
                      : ""}
                  </Table.Summary.Cell>
           
               
                  <Table.Summary.Cell index={42}>
                    {fieldSum.t6 && fieldSum.totalR6
                      ? Math.round(fieldSum.totalR6 / fieldSum.t6)
                      : ""}
                  </Table.Summary.Cell>
              
                <Table.Summary.Cell index={43}>
                  {" "}
                  {fieldSum.totalR1 ? fieldSum.totalR1 : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={44}>
                  {fieldSum.totalR2 ? fieldSum.totalR2 : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={45}>
                  {fieldSum.totalR3 ? fieldSum.totalR3 : ""}
                </Table.Summary.Cell>
              
                  <Table.Summary.Cell index={46}>
                    {fieldSum.totalR4 ? fieldSum.totalR4 : ""}
                  </Table.Summary.Cell>
                
                  <Table.Summary.Cell index={47}>
                    {fieldSum.totalR5 ? fieldSum.totalR5 : ""}
                  </Table.Summary.Cell>
               
                  <Table.Summary.Cell index={48}>
                    {fieldSum.totalR6 ? fieldSum.totalR6 : ""}
                  </Table.Summary.Cell>
                
                <Table.Summary.Cell index={49}></Table.Summary.Cell>
                <Table.Summary.Cell index={50}>
                  {fieldSum.vol ? fieldSum.vol : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={51}>
                  {fieldSum.perTr ? fieldSum.perTr : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={52}>
                  {fieldSum.totalAmount ? fieldSum.totalAmount : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={53}></Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
          rowClassName={(record, index) =>
            codeError?.includes(record?.code) ? "data-row active-row" : ""
          }
          rowSelection={() => {}}
          columns={createColumns}
          dataSource={formik?.values}
          pagination={false}
          bordered
          scroll={{
            x: 3900,
            y: 800,
          }}
        />
      </Form>
      <Row
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {pathname != "/collection/allSchemes/edit" && (
          <Col>
            <Button
              type="default"
              shape="round"
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",
                padding: "20px",
              }}
              size="large"
              onClick={saveDraft}
            >
              Save as draft
            </Button>
          </Col>
        )}

        <Col>
          <Button
            type="default"
            shape="round"
            className="schemi-btn-all"
            style={{
              margin: "10px",
              background: "#FCE838",
              fontWeight: "700",
              textTransform: "uppercase",
              border: "none",
            }}
            size="large"
            onClick={handleSaveSubmit}
          >
            Save & Submit
          </Button>
        </Col>
      </Row>
      <SMDialog
        open={openSelectModal}
        onCancel={() => setOpenSelectModal(!openSelectModal)}
      >
        <p className="custom-warning">Warning !</p>
        <p>Please Select The Dealer</p>
      </SMDialog>
      {/* <ValidationErrorsModal
          open={openErrorDialog.open}
          onClose={closeErrorDialog}
          errorsFields={openErrorDialog?.errorsFields}
          SchemeID={codeError}
        /> */}
      <ValidationDifferentSchemeError
        open={openErrorDialog.open}
        onClose={closeErrorDialog}
        errorsFields={openErrorDialog?.errorsFields}
        // SchemeID={codeError}
      />
      <StatusModal
        open={showSubmitModal}
        onOk={() => {
          if (
            toggleSubmitBtn == "submit" &&
            payloadData?.[0]?.holdStatus == 9
          ) {
            dispatch(postUpdateHoldScheme(payloadData)).then((data) => {
              navigate(-1);
            });
          } else if (
            toggleSubmitBtn == "submit" &&
            pathname != "/collection/allSchemes/edit"
          ) {
            let apiCall = async () => {
              await axiosAPI
                .post(`UpdateScheme/DraftToSchemeGenerate`, payloadData)
                .then((result) => {
                  message.success({
                    content: "Scheme successfully generated",
                    className: "custom-message",
                  });
                  const userData = decryptData(
                    sessionStorage.getItem("userInfo")
                  );
                  const userId = userData.userId;
                  const demoParams = {
                    fyear: currentFiscalYear,
                    schemeType: 6,
                    userid: userId,
                  };
                  navigate(-1);
                });
            };
            apiCall();
          } else {
            let apiCall = async () => {
              await axiosAPI
                .post(`UpdateScheme/UpdateSchemeGenerate`, payloadData)
                .then((result) => {
                  message.success({
                    content: "Scheme successfully generated",
                    className: "custom-message",
                  });

                  navigate(-1);
                });
              setShowSubmitModal(false);
            };
            apiCall();
          }
        }}
        onCancel={() => {
          setShowSubmitModal(false);
        }}
      >
        <p>
          {toggleSubmitBtn == "submit"
            ? "Are you sure you want to submit this scheme?"
            : "Are you sure you want to save this scheme as Draft?"}
        </p>
      </StatusModal>
    </div>
  );
};
export default CollectionDraftEditScheme;
