import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSchemeList} from "./schemaTableService";

export const getSchemeListApi = createAsyncThunk(
  "getSchemeListApi",
  async (data, thunkAPI) => {
    try {
      const response = await getSchemeList(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const schemaTableSlice = createSlice({
  name: "schemaTableSlice",
  initialState: {
    schemeListData: {},
    loadingSchemeData: "idle",
  },
  reducers: {
     reset: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getSchemeListApi.pending, (state) => {
      state.loadingSchemeData = "pending";
    });
    builder.addCase(getSchemeListApi.fulfilled, (state, action) => {
      state.loadingSchemeData = "succeeded";
      state.schemeListData = action.payload;
      state.error = false;
    });
    builder.addCase(getSchemeListApi.rejected, (state) => {
      state.schemeListData = {};
      state.loadingSchemeData = "succeeded";
      state.error = true;
    });

    
  },
});
export const { reset } = schemaTableSlice.actions;

export default schemaTableSlice.reducer;
