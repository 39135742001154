
import React, { useEffect, useState } from 'react';
import { Table, Divider, Col, Row, Form } from "antd";
import ThemeButton from "../../../components/button/buttons";
import axiosAPI from '../../../services/axiosApi';
import { getCurrentFiscalYear, getCurrentFiscalYear1, getCurrentFiscalYearShow } from '../../../utils/dateFormatter';
import dayjs from 'dayjs';
import { decryptData } from '../../../utils/localStorageEncodeDecode';




const InitCollectionData = {
  currentYear: dayjs().year(),
  // collectionType: 'Collection',
  schemeType: "",
  dealerCode: [],
  startDate: "",
  endDate: "",
};

const StateReportScheme = () => {
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRow, setEditingRow] = useState(null)
  const [dataSource, setDataSource] = useState([]);


  const {startDate, endDate } = collectionData;
  const currentFiscalYear = getCurrentFiscalYear1(collectionData?.startDate||new Date());
  const selectedFirstMonth = new Date(startDate);
  const selectedFirstMonthString = selectedFirstMonth.toLocaleString("en-IN", {
    year: "2-digit",
    month: "short",
  });

  // Columns for table

  const columns = [
    {
      "title": "State",
      "dataIndex": "stateName",
      fixed: 'left',
      "width": "100px"
    },
    {
      title: `${selectedFirstMonthString == 'Invalid Date'? "Month" : selectedFirstMonthString}`,
      dataIndex: "",
      children: [
        {
          title: "Qty",
          dataIndex: "",
          children: [
            {
              title: "Total",
              dataIndex: "totalQuantity",
              "width": "100px"
            },
            {
              title: "Institutional Qty",
              dataIndex: "institutionalQty",
              "width": "150px"
            },
            {
              title: "Net of Institutional Qty",
              dataIndex: "netInstitutionalQty",
              "width": "250px"
            }
          ]
        },
        {
          title: "Amt",
          dataIndex: "",
          children: [
            {
              title: "Coll Incentive",
              dataIndex: "collIncentiveAmt",
              "width": "150px"
            },
            {
              title: "Installation",
              dataIndex: "installationAmt",
              "width": "150px"
            },
            {
              title: "Price support",
              dataIndex: "priceSupportAmt",
              "width": "150px"
            },
            {
              title: "BTL",
              dataIndex: "btlAmt",
              "width": "100px"
            },
            {
              title: "Total",
              dataIndex: "totalAmt",
              "width": "100px"
            }
          ]
        },
        {
          title: "Amt Per Tractor",
          dataIndex: "",
          children: [
            {
              title: "Coll Incentive",
              dataIndex: "collIncentiveAmtPerTr",
              "width": "150px"
            },
            {
              title: "Installation",
              dataIndex: "installationAmtPerTr",
              "width": "150px"
            },
            {
              title: "Price support",
              dataIndex: "priceSupportAmtPerTr",
              "width": "150px"
            },
            {
              title: "BTL",
              dataIndex: "btlAmtPerTr",
              "width": "100px"
            },
            {
              title: "Total",
              dataIndex: "totalAmtPerTr",
              "width": "100px"
            }
          ]
        },
      ]
    },
    {
      title: `April ${getCurrentFiscalYearShow(new Date())?.split("FY")[1]} - ${selectedFirstMonthString == 'Invalid Date'? "Month" : selectedFirstMonthString}`,
      dataIndex: "",
      children: [
        {
          title: "Qty",
          dataIndex: "",
          children: [
            {
              title: "Total",
              dataIndex: "ytdTotalQuantity",
              "width": "100px"
            },
            {
              title: "Institutional Qty",
              dataIndex: "ytdInstitutionalQty",
              "width": "150px"
            },
            {
              title: "Net of Institutional Qty",
              dataIndex: "ytdNetInstitutionalQty",
              "width": "250px"
            }
          ]
        },
        {
          title: "Amt",
          dataIndex: "",
          children: [
            {
              title: "Coll Incentive",
              dataIndex: "ytdCollIncentiveAmt",
              "width": "150px"
            },
            {
              title: "Installation",
              dataIndex: "ytdInstallationAmt",
              "width": "100px"
            },
            {
              title: "Price support",
              dataIndex: "ytdPriceSupportAmt",
              "width": "150px"
            },
            {
              title: "BTL",
              dataIndex: "ytdbtlAmt",
              "width": "100px"
            },
            {
              title: "Total",
              dataIndex: "ytdTotalAmt",
              "width": "100px"
            }
          ]
        },
        {
          title: "Amt Per Tractor",
          dataIndex: "",
          children: [
            {
              title: "Coll Incentive",
              dataIndex: "ytdCollIncentiveAmtPerTr",
              "width": "150px"
            },
            {
              title: "Installation",
              dataIndex: "ytdInstallationAmtPerTr",
              "width": "150px"
            },
            {
              title: "Price support",
              dataIndex: "ytdPriceSupportAmtPerTr",
              "width": "150px"
            },
            {
              title: "BTL",
              dataIndex: "ytdbtlAmtPerTr",
              "width": "100px"
            },
            {
              title: "Total",
              dataIndex: "ytdTotalAmtPerTr",
              "width": "100px"
            }
          ]
        },
      ]
    },
  ]

  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userId = userData.userId;

  useEffect(() => {
    if (!collectionData.startDate) {
      return;
    }
    const payload = {
      fyear: currentFiscalYear,
      userid: userId,
      month: new Date(collectionData.startDate).getMonth() + 1,
      monthEnd: new Date(collectionData.startDate).getMonth() + 1
    }
    axiosAPI.post(`FinanceDashboard/GetFinanceStateReport`, payload)
      .then((result) => {
        setDataSource(result.data.data.result.financelist)
      });
  }, [collectionData]);

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className="table_scheme_wrap onemoth-date-selector">
      <Divider />
      <grid>
        <Row className='finacial-account'>
          <Col flex="auto">
            <h2 loading={loading} style={{ fontSize: "18px", padding: "10px" }}>
              State Wise Spend Report
            </h2>
          </Col>
          <Col style={{ display: "flex", alignItems: 'center' }}>
            <ThemeButton
              showDealer={false}
              showSchemeType={false}
              showStartMonth={true}
              showEndMonth={false}
              collectionData={collectionData}
              listData={[]}
              onSelectSchemeType={(schemeType) =>
                setCollectionData({ ...collectionData, schemeType })
              }
              handleStartChange={(date) => {
                setCollectionData({
                  ...collectionData,
                  startDate: date == null ? "" : date.format(),
                });
              }}
              handleEndChange={(date) =>
                setCollectionData({
                  ...collectionData,
                  endDate: date == null ? "" : date.format(),
                })
              }
              onSelectDealer={(dealerCode, currentSelected) => { }}
              onGetDealerDropdownData={() => { }}
            />
          </Col>
        </Row>

        <span>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </grid>

      <Form>
        <Table
          rowClassName={() => 'editable-row'}
          columns={columns}
          dataSource={dataSource}
          bordered
          scroll={{
            x: 5000,
            y: 600,
          }}
          
        />
      </Form>
    </div>
  );
};
export default StateReportScheme;
