import React from "react";
import  CreateSchemeTable from "../createSchemes/createSchemeTable"


const CollectionEditScheme = () => {
    return(
        <div className="create_scheme_wrap editscheme-page approval-schme-row-cdo">
            <CreateSchemeTable type="edit" />
        </div>
    )
}
export default CollectionEditScheme;
