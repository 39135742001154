import ToastifyShow from "../../components/ToastifyShow";
import axiosAPI from "../../services/axiosApi"
export const trackGetAreaManager = async (data) => {
    try {

      const response=axiosAPI.post(`SchemeGenerate/GetAreaManager`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const trackGetStateHeadbyAMId = async (data) => {
    try {

      const response=axiosAPI.post(`SchemeGenerate/GetStateHeadbyAMId`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const trackGetZonalHeadbyAMId = async (data) => {
    try {

      const response=axiosAPI.post(`SchemeGenerate/GetZonalHeadbyAMId`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };



  export const trackZonalHeadbySHId = async (data) => {
    try {

      const response=axiosAPI.post(`SchemeGenerate/GetZonalHeadbySHId`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
  ///api/controller/getCollectionDealersList

  export const getCollectionDealersList = async (data) => {
    try {

      const response=axiosAPI.post(`Collection/getCollectionDealersList`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };