import axiosAPI from '../../services/axiosApi';


const unMergeScheme = async (schemeIdNo, userName, userId) => {
    const payload = schemeIdNo.map(item => ({
        "schemeId": item.scheme.schemeId,
        userName,
        "schemeNo": item.scheme.schemeNo
      }))

      const payload2 = schemeIdNo.map(item => ({
        "schemeId": item.scheme.schemeId,
        userId,
        userName,
        "status": item.scheme.schemeNo ? 1: 0
        // status:2
      }))

      await axiosAPI
          .post(`UpdateScheme/UnMergeSchemeGenerate`, payload2[0])
          .then((result) => {
        axiosAPI
            .post(`UpdateScheme/UpdateInActiveScheme`, payload)
            .then((result) => {
            window.location.reload();
        });
    });
};

export default unMergeScheme