import React from 'react';
import { Modal } from 'antd';
import { ReactComponent as SmileIcon } from "../../../assets/vector/smile.svg";
import Icon from "@ant-design/icons";
import "./index.css";

const AlertModal = ({openModal,handleModal}) => {

  return (
   <>
    <Modal 
        className='alertModal'
        open={openModal}
        onCancel={handleModal}
        closable={false}
        cancelText="Ok"
        okButtonProps={{ style: { display: "none" } }}
        maskClosable={false}
        destroyOnClose={true}
        >
        {/* <Icon className='custom-icon' component={SmileIcon}/> */}
        <p className='custom-warning'>Warning !</p>
        <p> Zero or decimal is not a valid. Please enter other valid value</p>
      </Modal>
   </>
  )
}

export default AlertModal
