import React, { useEffect, useState } from "react";
import CommonCard from "../../../components/commonCard/CommonCard";
import { useDispatch, useSelector } from "react-redux";
import "./ZonalTable.css";
import ZonalHeadTable from "./ZonalTable";
import FinancialYearButton from "../../../components/financialYearButton/FinancialYearButton";
import { Select } from "antd";
import { STATE_WISE_DASHBOARD_LABEL } from "../../../config";
import SubmittedCard from "../../../assets/images/schemeSubmitted.png";
import ApprovedCard from "../../../assets/images/schemeApproved.png";
import PendingCard from "../../../assets/images/schemePending.png";
import RejectedCard from "../../../assets/images/rejected.png";
import { GetStatesbyZonalHeadIdApi } from "../../../redux/stateData/stateDataSlice";
import OneMonthScheme from "../pmisDashboard/OneMonth";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import dayjs from "dayjs";
//import { dashboardDetails } from "../../redux/authUser/authSlice";

const ZonalHead = ({
  type = "hideFYSelect",
  role = "Finance",
  dashboardType,
  stateId = 0,
  SetTotalCountShow,
  totalCountShow,
  dateShowValue
}) => {
  const { Option } = Select;
  const dispatch = useDispatch();

  const { stateList, ZonalHeadStateList, loadingState } = useSelector((state) => state.stateData);

  const [mngrdata, setMngrdata] = useState({
    pendingCount: 0,
    approvedCount: 0,
    submittedCount: 0,
    totalApprovedCount: 0,
    totalPendingCount: 0,
    totalSubmittedCount: 0,
    rejectedCount: 0,
    autoRejectCount: 0,
    totalRejectedCount: 0
  });

  const [areaManager, setAreaManager] = useState([]);
  const [ZonalHeadStateListArr, setZonalHeadStateListArr] = useState();
  const [selectedStateId, setSelectedStateId] = useState(0);
  const {userRole,UserID} = decryptData(sessionStorage.getItem("userInfo"));
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const financialYearStartMonth = 3; // April (0-indexed)
  const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  const financialYear = financialYearStartYear + 1;
    const [dateValue, setDateValue] = useState(financialYear);
  
    useEffect(() => {
      setDateValue(financialYear);
    }, [financialYear]);
  const [typeOfScheme, setTypeOfScheme] = useState(1);


  const handleTypeOfScheme = (data) => {
    setTypeOfScheme(data);
  };

  //schemeListApprove
  useEffect(() => {
    if (stateId || stateId == 0) {
      setSelectedStateId(stateId);
    }
  }, [stateId]);

  useEffect(() => {
    let payload = {
      fyear: dateValue,
      userid: UserID ? UserID : 0
    }
    dispatch(GetStatesbyZonalHeadIdApi(payload))
  }, [])

  useEffect(() => {
    if (Array?.isArray(ZonalHeadStateList?.data) && ZonalHeadStateList?.data?.length != 0) {
      setZonalHeadStateListArr(ZonalHeadStateList?.data);
    } else {
      setZonalHeadStateListArr([]);
    }
  }, [ZonalHeadStateList]);

  const handleSelect = (value, option) => {

    const id = option.key;
    setSelectedStateId(parseInt(id));
  };



  return (
    <>
    <div className="d-flex w-100 bg-white">
      <div className="d-flex  ml-2 pt-2 ">
      {type == "hideFYSelect" ? null : <FinancialYearButton 
       handleDate={(x)=>setDateValue(x)}
        />}
      </div>
      {userRole == "ZADM" && (
        <div className={"d-flex  ml-2 pt-2 h-100 calendar_zonalHead "}>
          <Select
            showSearch
            placeholder="Select State"
            optionFilterProp="children"
            onSelect={handleSelect}
            defaultValue={0}
            className="h-100"
            style={{height:"42px !important"}}
          >
            <Option key={0} value={0}>
              All States
            </Option>
            {ZonalHeadStateListArr?.map((option) => (
              <Option key={option.stateID}
                value={option.stateName}>
                {option.stateName}
              </Option>
            ))}
          </Select>
        </div>
      )}
      </div>
      {(userRole == "Finance" ||
        dashboardType === STATE_WISE_DASHBOARD_LABEL) ? null : (
        <div className="cards_container pending-summary-wrap">
          <CommonCard
            submittedNumber={totalCountShow ? mngrdata.submittedCount : !totalCountShow ? mngrdata.totalSubmittedCount : 0}
            schemeState="Schemes Submitted"
            colorCode="black"
            backgroundImage={`url(${SubmittedCard})`}
            backgroundSize="cover"
            typeOfScheme={typeOfScheme}
          />
          <CommonCard
            submittedNumber={totalCountShow ? mngrdata.approvedCount : !totalCountShow ? mngrdata.totalApprovedCount : 0}
            schemeState="Schemes Approved"
            colorCode="white"
            backgroundImage={`url(${ApprovedCard})`}
            backgroundSize="cover"
            typeOfScheme={typeOfScheme}
          />
          <CommonCard
            submittedNumber={totalCountShow ? mngrdata.pendingCount : !totalCountShow ? mngrdata.totalPendingCount : 0}
            schemeState="Schemes Pending"
            colorCode="black"
            backgroundImage={`url(${PendingCard})`}
            backgroundSize="cover"
            typeOfScheme={typeOfScheme}
          />
          <CommonCard
            submittedNumber={totalCountShow ? mngrdata.rejectedCount : !totalCountShow ? mngrdata.totalRejectedCount : 0}
            schemeState="Schemes Rejected"
            colorCode="black"
            backgroundImage={`url(${RejectedCard})`}
            backgroundSize="cover"
            typeOfScheme={typeOfScheme}
          />
        </div>
      )}
      <div className="">
        
        {/* {[1,2].includes(userRole) && <ActionButton userRole={userRole}/>} */}
        <ZonalHeadTable
          areaManager={areaManager}
          onGetData={() => { }}
          onSetCardData={(data) => {
            setMngrdata(data);
          }}
           SetTotalCountShow={(data) => SetTotalCountShow(data)}
          selectedStateId={selectedStateId}
          dashboardType={dashboardType}
          dateValue={ dateShowValue || dateValue}
          // dateValue={dateValue}
          setDateValue={setDateValue}
          sendDataToParent={handleTypeOfScheme}
          fyear={dateValue}
        />
      </div>


      <div>
        
        {(dashboardType != STATE_WISE_DASHBOARD_LABEL &&
          <OneMonthScheme dateValue={dateValue}/>
        )}
      </div>
    </>
  );
};

export default ZonalHead;
