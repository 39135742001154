import { Button, Modal } from "antd";
import React, { useState } from "react";
import CollapseCheckboxModal from "./CollapseCheckboxModal";
import { SmileOutlined } from '@ant-design/icons';
import Congratulation from "../congratulation";
import "./rolemodal.css";
import { Space } from 'antd';
const { confirm } = Modal;

const App = ( {openModal,handleModal,handleOk}) => {

  // const [test,setTest] = useState(false);

  // const handleOk = ()=>{
  // setTest(!test)
  // }
  // const showPropsConfirm = () => {

  //   confirm({
  //     icon: <SmileOutlined />,
  //     title: 'Congratulation',
  //     content: 'Communicated successfully.',
  //     cancelText: 'close',
  //     onOk:{handleOk},
  //     open:{test},
  //     okButtonProps: {
  //       disabled: true,
  //       style: { display: 'none' },
  //     },
  //   });
  // };  

  return (
    <div>
    {/* {
      test && showPropsConfirm()
    } */}
    
    <Modal
        className="test"
        title="Select Role"
        centered
        open={openModal}
        onCancel={handleModal}
        closable={false}
        onOk={handleOk}
        okText="Yes" 
        maskClosable={false}
        destroyOnClose={true}
         >
        <CollapseCheckboxModal />
      </Modal>
   
    </div>
  );
};

export default App;
