import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { ALLOWED_PATHS } from "../config";
import { decryptData } from "../utils/localStorageEncodeDecode";

const AuthenticatedRoute = ({ children }) => {
  const location = useLocation();
  const { userRole, token } = decryptData(sessionStorage.getItem("userInfo"))||{};
  const currentAllowedPaths = ALLOWED_PATHS[userRole] || [];

  let isAuthenticated = (token && userRole) ? true : false;
  if (!isAuthenticated || !currentAllowedPaths.includes(location.pathname)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};
export default AuthenticatedRoute;
