import React from 'react'
import { Modal } from 'antd';

const StatusModal = ({children,open,onCancel,onOk}) => {
  return (
    <div>
     <Modal 
        className='alertModal'
        open={open}
        onOk={onCancel}
        onCancel={onOk}
        closable={false}
        cancelText="Yes"
        okText="No"
        maskClosable={false}
        destroyOnClose={true}
        // okButtonProps={{ style: { display: "none" } }}
        >
        {/* <Icon className='custom-icon' component={SmileIcon}/> */}
        {children}
       
      </Modal>
      
    </div>
  )
}

export default StatusModal
