import React, { useMemo } from "react";
import { decryptData } from "../../../utils/localStorageEncodeDecode";

// Memoize the ProfileImage component to prevent unnecessary re-renders
const ProfileImage = React.memo(() => {
  const colorMap = [
    "#f56565",
    "#48bb78",
    "#ecc94b",
  ];

  // Use useMemo to memoize the calculation of colors and randomColorClass
  const randomColor = useMemo(() => {
    const colors = Object.keys(colorMap);
    const randomColorClass = colors[Math.floor(Math.random() * colors.length)];
    return colorMap[randomColorClass];
  }, [colorMap]);

  // Memoize the decryption and initialization of user data
  const { userRole, userName } = useMemo(() => {
    return decryptData(sessionStorage.getItem("userInfo")) || {};
  }, []);

  // Memoize the calculation of initials
  const initials = useMemo(() => {
    if (userName) {
      const nameParts = userName.split(" ");
      const firstNameInitial = nameParts[0]?.[0]?.toUpperCase() || "";
      const lastNameInitial = nameParts[1]?.[0]?.toUpperCase() || "";
      return firstNameInitial + lastNameInitial;
    }
    return "";
  }, [userName]);

  // Memoize the styles
  const avatarStyle = useMemo(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '3rem',
    width: '3rem',
    borderRadius: '50%',
    color: 'white',
    backgroundColor: randomColor,
    fontWeight: 'bold'
  }), [randomColor]);

  const containerStyle = useMemo(() => ({
    display: 'flex',
    marginRight: '1.25rem',
    alignItems: 'center',
  }), []);

  return (
    <div style={{ display: 'flex' }}>
      <div style={containerStyle}>
        <div style={avatarStyle}>
          <span draggable={false}>
            {initials}
          </span>
        </div>
      </div>
    </div>
  );
});

export default ProfileImage;
