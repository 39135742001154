import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getNotificationSadmApi} from './notificationService'
export const getNotificationSadm = createAsyncThunk(
    "getNotificationSadmApi",
    async (data, thunkAPI) => {
      try {
        const response = await getNotificationSadmApi(data);
  
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.string();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  const notificationDataSlice = createSlice({
    name: "notificationDataSlice",
    initialState: {
      sadmDataList: {},
      saveHoldDataList: {},
      unHoldDataList: {},
      unholdSchemePost: {},
      loadingUnholdList: "idle",
      loadingGetnotificationList: "idle",
      loadingSaveHoldList: "idle",
    },
    reducers: {
      // resetState: (state, action) => {},
    },
    extraReducers: (builder) => {
      builder.addCase(getNotificationSadm.pending, (state) => {
        state.loadingGetnotificationList = "pending";
      });
      builder.addCase(getNotificationSadm.fulfilled, (state, action) => {
        state.loadingGetnotificationList = "succeeded";
        state.sadmDataList = action.payload.data;
        state.error = false;
      });
      builder.addCase(getNotificationSadm.rejected, (state) => {
        state.sadmDataList = {};
        state.loadingGetnotificationList = "failed";
        state.error = true;
      });
  
      
      //postUnHoldSchemeApi
      //getUnholdScheme
    },
  });
  export const { reset } = notificationDataSlice.actions;
  
  export default notificationDataSlice.reducer;