import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSchemeIdDetails, getSchemesForWithdrawnApi, getWithdrawnSchemesListApi, postSaveWithdrawanSchemeApi } from "./withdrawalService";


export const getSchemesForWithdrawn = createAsyncThunk(
  "getSchemesForWithdrawnApi",
  async (data, thunkAPI) => {
    try {
      const response = await getSchemesForWithdrawnApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postSaveWithdrawanScheme = createAsyncThunk(
  "postSaveWithdrawanSchemeApi",
  async (data, thunkAPI) => {
    try {
      const response = await postSaveWithdrawanSchemeApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSchemeIdDetailsApi = createAsyncThunk(
  "getSchemeIdDetailsApi",
  async (data, thunkAPI) => {
    try {
      const response = await getSchemeIdDetails(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getWithdrawnSchemesList = createAsyncThunk(
    "getWithdrawnSchemesListApi",
    async (data, thunkAPI) => {
      try {
        const response = await getWithdrawnSchemesListApi(data);
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.string();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
const withdrawalSlice = createSlice({
  name: "withdrawalSlice",
  initialState: {
    dealersList:{},
    loadingScheme: "idle",
    loadingSchemeDetails:"idle",
    trackInstallationList: {},
    schemeIdList:{}
  },
  reducers: {
    // resetState: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getSchemesForWithdrawn.pending, (state) => {
      state.loadingSchemeDetails = "pending";
    });
    builder.addCase(getSchemesForWithdrawn.fulfilled, (state, action) => {
      state.loadingSchemeDetails = "succeeded";
      state.schemesForWithdrawn = action.payload;
      state.error = false;
    });
    builder.addCase(getSchemesForWithdrawn.rejected, (state) => {
      state.schemesForWithdrawn = {};
      state.loadingSchemeDetails = "succeeded";
      state.error = true;
    });
    builder.addCase(postSaveWithdrawanScheme.pending, (state) => {
      state.loadingScheme = "pending";
    });
    builder.addCase(postSaveWithdrawanScheme.fulfilled, (state, action) => {
      state.loadingScheme = "succeeded";
      state.saveWithdrawanScheme = action.payload;
      state.error = false;
    });
    builder.addCase(postSaveWithdrawanScheme.rejected, (state) => {
      state.saveWithdrawanScheme = {};
      state.loadingScheme = "succeeded";
      state.error = true;
    });
    builder.addCase(getWithdrawnSchemesList.pending, (state) => {
        state.loadingScheme = "pending";
      });
      builder.addCase(getWithdrawnSchemesList.fulfilled, (state, action) => {
        state.loadingScheme = "succeeded";
        state.withdrawnSchemesList = action.payload;
        state.error = false;
      });
      builder.addCase(getWithdrawnSchemesList.rejected, (state) => {
        state.withdrawnSchemesList = {};
        state.loadingScheme = "succeeded";
        state.error = true;
      });
      builder.addCase(getSchemeIdDetailsApi.pending, (state) => {
        state.loadingScheme = "pending";
      });
      builder.addCase(getSchemeIdDetailsApi.fulfilled, (state, action) => {
        state.loadingScheme = "succeeded";
        state.schemeIdList = action.payload;
        state.error = false;
      });
      builder.addCase(getSchemeIdDetailsApi.rejected, (state) => {
        state.schemeIdList = {};
        state.loadingScheme = "succeeded";
        state.error = true;
      });

      
  },
});
export const { reset } = withdrawalSlice.actions;

export default withdrawalSlice.reducer;
