
import React, { useEffect, useState, dispatch } from 'react';
import { Table, Button, message } from "antd";
import { getDealerListByStateIdAsync, getDealerMergeAsync, getUmMergeDealerListByStateIdAsync, } from '../../redux/mergeUnmerge/mergeUmergeSlice';
import { useDispatch } from 'react-redux';
import "./index.css"
import { decryptData } from '../../utils/localStorageEncodeDecode';

const MergeTable = ({ dataSource,
  selectedStateId,
  setSelectedRowKeys,
  selectedRowKeys,
  payloads, amId,
  handleChange }) => {
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSourceArr, setDataSourceArr] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState([]);
  const [messageApi, contextHolder] = message.useMessage()
  const dispatch = useDispatch()
  const userInfo = decryptData(sessionStorage.getItem("userInfo"));
  const userName = userInfo.userName;

  useEffect(() => {
    if (dataSource && dataSource?.length != 0) {
      setDataSourceArr(dataSource?.map((x, index) => ({ ...x, key: x.dealerCode })))
    } else {
      setDataSourceArr([])
    }
  }, [dataSource])

  const columns = [
    {
      title: "Delear Code",
      dataIndex: "dealerCode",
      fixed: "left",
      width: "100px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerCode",
    },
    {
      title: "Delear Location",
      dataIndex: "dealerLocation",
      width: "100px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
    },

    {
      title: "Delear Name",
      dataIndex: "dealerName",
      fixed: "left",
      width: "100px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerName",
    },
    {
      title: "State",
      dataIndex: "stateName",
      width: "100px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "StateName",
    }
  ];

  const onSelectChange = (newSelectedRowKeys, y) => {
    let data = y
    setSelectedDealer(y)
    setSelectedRowKeys(newSelectedRowKeys);

  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const handleMerge = async (e, allData) => {

    const payload = {
      "userName": userName,
      "stateId": selectedStateId,
      "dealersToMerged": selectedDealer,
      "areaManagerId": amId,
    }
    try {
      if (selectedRowKeys.length === 0) {
        return message.warning("Select Schemes to be merged")
      }
      if (selectedRowKeys.length < 2) {
        return message.warning("Select Atleast two  Schemes  for merged")
      }
      if (selectedRowKeys.length <= 4) {
        dispatch(getDealerMergeAsync(payload)).then(async(x) => {
          if (x.payload.response === 1) {
             message.success("Schemes  Merged Successfully")
            await dispatch(getDealerListByStateIdAsync(payloads))
            await dispatch(getUmMergeDealerListByStateIdAsync(payloads))
          }
          setSelectedRowKeys([])
          setSelectedDealer([])
        })

      }
      else {
        message.warning("More than 4 Schemes can't be merged")
      }
    } catch (e) {
      message.warning(e.message)
    }
  }

  useEffect(() => {
    if(amId){
    dispatch(getUmMergeDealerListByStateIdAsync(payloads))
    }
  }, [amId])

  return (
    <div>

      <Table
        rowSelection={
          rowSelection
        }
        // className="fixed-height-table-container"
        columns={columns}
        dataSource={dataSourceArr}
        onChange={handleChange}
        pagination={false}
        bordered
        scroll={{
          x: 500,
          y: 200,
        }}
      />

      <div style={{
        display: "flex",
        justifyContent: "right"
      }}>
        <Button
          className="schemi-btn-all"
          style={{
            margin: "10px",
            background: "#FCE838",
            fontWeight: "700",
            textTransform: "uppercase",
            border: "none",
            color: "#fff !important",

          }}
          onClick={(e) => handleMerge(e)}
        //  icon={<MergeIcon style={{color:'#fff'}} />}
        >
          <span style={{ color: '#fff' }}>Merge</span></Button>
      </div>
    </div>
  )
}

export default MergeTable;