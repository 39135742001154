import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDashboardCOSMStateWiseReport, getDashboardDetails, getNHSDashboardDetails, getNHSDashboardDetailsForInstallation, getNHSDashboardDetailsForZadm, getNHSDashboardDetailsForInstallationForZadm, getNHSDashboardMultiMonthDetails, getDashboardCOSMMultipleStateWiseReport, getNHSDashboardDetailsForCollection} from "./dashboardDataService";

export const getNHSDashboardDetailsApi = createAsyncThunk(
  "getNHSDashboardDetailsApi",
  async (data, thunkAPI) => {
    try {
      const response = await getNHSDashboardDetails(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// API for MultipleMonthSchemes - Collection
export const getNHSDashboardDetailsForCollectionApi = createAsyncThunk(
  "getNHSDashboardDetailsForCollectionApi",
  async (data, thunkAPI) => {
    try {
      const response = await getNHSDashboardDetailsForCollection(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//API for OneMonthSchemes - Installation
export const getNHSDashboardDetailsForInstallationApi = createAsyncThunk(
  "getNHSDashboardDetailsForInstallationApi",
  async (data, thunkAPI) => {
    try {
      const response = await getNHSDashboardDetailsForInstallation(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getNHSDashboardDetailsApiForZadm = createAsyncThunk(
  "getNHSDashboardDetailsApiForZadm",
  async (data, thunkAPI) => {
    try {
      const response = await getNHSDashboardDetailsForZadm(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getNHSDashboardDetailsApiForInstallationForZadm = createAsyncThunk(
  "getNHSDashboardDetailsApiForZadm",
  async (data, thunkAPI) => {
    try {
      const response = await getNHSDashboardDetailsForInstallationForZadm(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getNHSDashboardMultiMonthDetailsApi = createAsyncThunk(
  "getNHSDashboardMultiMonthDetailsApi",
  async (data, thunkAPI) => {
    try {
      const response = await getNHSDashboardMultiMonthDetails(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardCOSMStateWiseReportApi = createAsyncThunk(
  "getDashboardCOSMStateWiseReportApi",
  async (data, thunkAPI) => {
    try {
      const response = await getDashboardCOSMStateWiseReport(data);
   
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardCOSMMultipleStateWiseReportApi = createAsyncThunk(
  "getDashboardCOSMMultipleStateWiseReportApi",
  async (data, thunkAPI) => {
    try {
      const response = await getDashboardCOSMMultipleStateWiseReport(data);
   
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDashboardDetailsApi = createAsyncThunk(
  "getDashboardDetailsApi",
  async (data, thunkAPI) => {
    try {
      const response = await getDashboardDetails(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const dashboardDataSlice = createSlice({
  name: "dashboardDataSlice",
  initialState: {
    nhsDashboardDetails: {},
    nhsDashboardDetailsForZadm: {},
    nhsDashboardDetailsMultiMonth:{},
    dashboardCOSMStateWiseReport:{},
    dashboardDetails:{},
    dashboardCOSMMultipleStateWiseReport:{},
    loadingNhs: "idle",
    loadingdashboard:"idle",
    loadingMultiMonthNhs: "idle",
    loadingCosmstateWise:"idle",
  },
  reducers: {
    // resetState: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getNHSDashboardDetailsApi.pending, (state) => {
      state.loadingNhs = "pending";
    });
    builder.addCase(getNHSDashboardDetailsApi.fulfilled, (state, action) => {
      state.loadingNhs = "succeeded";
      state.nhsDashboardDetails = action.payload.data;
      state.error = false;
    });
    builder.addCase(getNHSDashboardDetailsApi.rejected, (state) => {
      state.nhsDashboardDetails = {};
      state.loadingNhs = "succeeded";
      state.error = true;
    });

    builder.addCase(getNHSDashboardDetailsForInstallationApi.pending, (state) => {
      state.loadingNhs = "pending";
    });
    builder.addCase(getNHSDashboardDetailsForInstallationApi.fulfilled, (state, action) => {
      state.loadingNhs = "succeeded";
      state.nhsDashboardDetailsForInstallation = action.payload.data;
      state.error = false;
    });
    builder.addCase(getNHSDashboardDetailsForInstallationApi.rejected, (state) => {
      state.nhsDashboardDetailsForInstallation = {};
      state.loadingNhs = "succeeded";
      state.error = true;
    });


    builder.addCase(getNHSDashboardDetailsForCollectionApi.pending, (state) => {
      state.loadingNhs = "pending";
    });
    builder.addCase(getNHSDashboardDetailsForCollectionApi.fulfilled, (state, action) => {
      state.loadingNhs = "succeeded";
      state.nhsDashboardDetailsMultiMonth = action.payload.data;
      state.error = false;
    });
    builder.addCase(getNHSDashboardDetailsForCollectionApi.rejected, (state) => {
      state.nhsDashboardDetailsMultiMonth = {};
      state.loadingNhs = "succeeded";
      state.error = true;
    });



    builder.addCase(getNHSDashboardDetailsApiForZadm.pending, (state) => {
      state.loadingNhs = "pending";
    });
    builder.addCase(getNHSDashboardDetailsApiForZadm.fulfilled, (state, action) => {
      state.loadingNhs = "succeeded";
      state.nhsDashboardDetailsForZadm = action.payload.data;
      state.error = false;
    });
    builder.addCase(getNHSDashboardDetailsApiForZadm.rejected, (state) => {
      state.nhsDashboardDetailsForZadm = {};
      state.loadingNhs = "succeeded";
      state.error = true;
    });

    // builder.addCase(getNHSDashboardDetailsApiForInstallationForZadm.pending, (state) => {
    //   state.loadingNhs = "pending";
    // });
    // builder.addCase(getNHSDashboardDetailsApiForInstallationForZadm.fulfilled, (state, action) => {
    //   state.loadingNhs = "succeeded";
    //   state.nhsDashboardDetailsForInstallationForZadm = action.payload.data;
    //   state.error = false;
    // });
    // builder.addCase(getNHSDashboardDetailsApiForInstallationForZadm.rejected, (state) => {
    //   state.nhsDashboardDetailsForInstallationForZadm = {};
    //   state.loadingNhs = "succeeded";
    //   state.error = true;
    // });

    builder.addCase(getNHSDashboardMultiMonthDetailsApi.pending, (state) => {
      state.loadingMultiMonthNhs = "pending";
    });
    builder.addCase(getNHSDashboardMultiMonthDetailsApi.fulfilled, (state, action) => {
      state.loadingMultiMonthNhs = "succeeded";
      state.nhsDashboardDetailsMultiMonth = action.payload;
      state.error = false;
    });
    builder.addCase(getNHSDashboardMultiMonthDetailsApi.rejected, (state) => {
      state.nhsDashboardDetails = {};
      state.nhsDashboardDetailsMultiMonth = "succeeded";
      state.error = true;
    });
    builder.addCase(getDashboardCOSMStateWiseReportApi.pending, (state) => {
      state.loadingCosmstateWise = "pending";
    });
    builder.addCase(getDashboardCOSMStateWiseReportApi.fulfilled, (state, action) => {
      state.loadingCosmstateWise = "succeeded";
      state.dashboardCOSMStateWiseReport = action.payload.data;
      state.error = false;
    });
    builder.addCase(getDashboardCOSMStateWiseReportApi.rejected, (state) => {
      state.dashboardCOSMStateWiseReport = {};
      state.loadingCosmstateWise = "succeeded";
      state.error = true;
    });
    builder.addCase(getDashboardCOSMMultipleStateWiseReportApi.pending, (state) => {
      state.loadingCosmstateWise = "pending";
    });
    builder.addCase(getDashboardCOSMMultipleStateWiseReportApi.fulfilled, (state, action) => {
      state.loadingCosmstateWise = "succeeded";
      state.dashboardCOSMMultipleStateWiseReport = action.payload.data;
      state.error = false;
    });
    builder.addCase(getDashboardCOSMMultipleStateWiseReportApi.rejected, (state) => {
      state.dashboardCOSMMultipleStateWiseReport = {};
      state.loadingCosmstateWise = "succeeded";
      state.error = true;
    });
    builder.addCase(getDashboardDetailsApi.pending, (state) => {
      state.loadingdashboard = "pending";
    });
    builder.addCase(getDashboardDetailsApi.fulfilled, (state, action) => {
      state.loadingdashboard = "succeeded";
      state.dashboardDetails = action.payload;
      state.error = false;
    });
    builder.addCase(getDashboardDetailsApi.rejected, (state) => {
      state.dashboardDetails = {};
      state.loadingdashboard = "succeeded";
      state.error = true;
    });

  },
});
export const { reset } = dashboardDataSlice.actions;

export default dashboardDataSlice.reducer;
