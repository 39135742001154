import { useEffect, useState } from "react";
import { Card, Row, Button, Typography } from "antd";
import SummaryModal from "../modal/summaryModal/index";
import { modalUpdated } from "../../redux/slice/schemeSubmitted/SchemeSubmitted";
import { useDispatch, useSelector } from "react-redux";
import Dropdowns from "../modal/summaryModal/Dropdowns";
import { schemeStatus } from "../../redux/statusTable/SchemeStatusTable";
import { useNavigate } from "react-router-dom";
import axiosAPI from "../../services/axiosApi";
import { getCurrentFiscalYear } from "../../utils/dateFormatter";
import { decryptData } from "../../utils/localStorageEncodeDecode";

const currentFiscalYear = getCurrentFiscalYear();

const InitSummaryModalValue = {
  currentYear: currentFiscalYear,
  start_Month: { key: "", val: "" },
  end_Month: { key: "", val: "" },
  pageSize: 150,
  pageNumber: 1,
};
const CommonCard = ({
  submittedNumber,
  colorCode,
  schemeState,
  modalTitle,
  backgroundImage,
  backgroundSize,
  typeOfScheme
}) => {
  const dispatch = useDispatch();
  const [modalFormData, setModalFormData] = useState(InitSummaryModalValue);
  const [type, setType] = useState(1)
  const { Title } = Typography;
  // modal code
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setMessage("");
  }, [openModal]);
  const handleModal = () => {
    setType(typeOfScheme)
    setOpenModal(!openModal);
    dispatch(modalUpdated({ schemeState, modalTitle }));
  };


  const startMonth = useSelector((state) => state.monthPicker?.startMonth);
  const endMonth = useSelector((state) => state.monthPicker?.endMonth);
  const {financialYear} = useSelector((state) => state.monthPicker);

  const handleSubmit = () => {
    const {userRole,userId} = decryptData(sessionStorage.getItem("userInfo"));

    if (!type && !startMonth && !endMonth) {
      setMessage("Please select required fields!");
    } else if (!type) {
      setMessage("Please select scheme type!");
    } else if (!startMonth || !endMonth) {
      setMessage("Please select StartMonth and EndMonth!");
    }

    let payload = {
      fyear: financialYear,
      schemeType: type,
      month: startMonth ? startMonth : null,
      monthEnd: endMonth ? endMonth : null,
      userid: userId,
      state: 0,
      userRole: userRole,
      pageNumber: modalFormData.pageNumber || 1,
      pageSize: modalFormData.pageSize || 150
    };

    navigate(
      `/collection/schemeStatus?schemeType=${type}`,
      {state:{"payload":payload,schemeState:schemeState}}
    );
    // if (userRole == "AMGR") {
    //   if (schemeState == "Schemes Submitted") {
    //     axiosAPI
    //       .post(`Summary/SummaryDetailsSubmitted`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //                   `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`
    //         );
    //       });
    //   }
    //   if (schemeState == "Schemes Approved") {
    //     axiosAPI
    //       .post(`Summary/ApprovedSummaryDetails`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //           `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`
    //         );
    //       });
    //   }
    //   if (schemeState == "Schemes Pending") {
    //     axiosAPI
    //       .post(`Summary/PendingSummaryDetails`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //           `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`
    //         );
    //       });
    //   }
    //   if (schemeState == "Schemes Rejected") {
    //     axiosAPI
    //       .post(`Summary/SummaryDetailsRejected`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //           `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`
    //         );
    //       });
    //   }
    // }
    // if (userRole == "SADM") {
    //   if (schemeState == "Schemes Submitted") {
    //     axiosAPI
    //       .post(`Summary/SummaryDetailsSubmitted`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //           `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`
    //         );
    //       });
    //   }
    //   if (schemeState == "Schemes Approved") {
    //     axiosAPI
    //       .post(`Summary/ApprovedSummaryDetails`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //           `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`
    //         );
    //       });
    //   }
    //   if (schemeState == "Schemes Pending") {
    //     axiosAPI
    //       .post(`Summary/PendingSummaryDetails`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //           `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`
    //         );
    //       });
    //   }
    //   if (schemeState == "Schemes Rejected") {
    //     axiosAPI
    //       .post(`Summary/SummaryDetailsRejected`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //           `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`,
    //           {state:payload,schemeState:schemeState}
    //         );
    //       });
    //   }
    // }
    // if (userRole == "ZADM") {
    //   if (schemeState == "Schemes Submitted") {
    //     axiosAPI
    //       .post(`Summary/SummaryDetailsSubmitted`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //           `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`
    //         );
    //       });
    //   }
    //   if (schemeState == "Schemes Approved") {
    //     axiosAPI
    //       .post(`Summary/ApprovedSummaryDetails`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //           `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`
    //         );
    //       });
    //   }
    //   if (schemeState == "Schemes Pending") {
    //     axiosAPI
    //       .post(`Summary/PendingSummaryDetails`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //           `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`
    //         );
    //       });
    //   }
    //   if (schemeState == "Schemes Rejected") {
    //     axiosAPI
    //       .post(`Summary/SummaryDetailsRejected`, payload)
    //       .then((result) => {
    //         let response = result.data.data;
    //         dispatch(schemeStatus(response));
    //         navigate(
    //           `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`
    //         );
    //       });
    //   }
    // }
  };
  return (
    <>
      <Card
        style={{
          cursor: "default",
          backgroundColor: "transparent",
          // borderBottom: `3px solid ${colorCode}`,
          backgroundImage: `${backgroundImage}`,
          backgroundSize: `${backgroundSize}`,
        }}
      >
        <Row className="dash_card">
          <Row className="dash_card_left" span={10}>
            <Title
              className="dash_card_left_title"
              level={1}
              style={{ color: `${colorCode}` }}
            >
              {submittedNumber}
            </Title>
            <Typography
              className="dash_card_left_state"
              level={6}
              style={{ color: `${colorCode}` }}
            >
              {schemeState}
            </Typography>
          </Row>
          <Button className="dash_card_button" onClick={() => handleModal()}>
            View Summary
          </Button>
        </Row>
      </Card>

      {openModal && (
        <SummaryModal
          onSave={handleSubmit}
          openModal={openModal}
          handleModal={handleModal}
          modalTitle={schemeState}
          type={type} 
        
         
        >
          <Dropdowns
            modalFormData={modalFormData}
            onChange={(key, val) =>
              setModalFormData({ ...modalFormData, [key]: val })
            }
            type={type} 
            errorMessage={message}
          />
        </SummaryModal>
      )}
    </>
  );
};
export default CommonCard;
