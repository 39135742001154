import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Select, Col, Pagination } from "antd";
import { getStateListApi } from "../../redux/stateData/stateDataSlice"
import { getDealerListByStateIdAsync, getUmMergeDealerListByStateIdAsync, getAMListByStateIdAsync } from "../../redux/mergeUnmerge/mergeUmergeSlice";
import { ReactComponent as MergeIcon } from "../../assets/vector/merge.svg";
import MergeTable from './MergeTable';
import UnMergeTable from './UnMergeTable';
import { Option } from 'antd/es/mentions';




const InitCollectionData = {
  pageSize: 150,
  pageNumber: 1,
  sortingOrder: "ascend",
  sortingType: "DealerCode"
};
const Merge = () => {

  const [stateListArr, setStateListArr] = useState();
  const [amListArr, setAmListArr] = useState();
  const [amId, setAmId] = useState();
  const [selectedStateId, setSelectedStateId] = useState(0);
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const [collectionData1, setCollectionData1] = useState(InitCollectionData);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [payloadForMerge, setPayloadsForMerge] = useState({})
  const [payloadForUnmerge, setPayloadsForUnmerge] = useState({})
  const [data, setData] = useState([])
  const { stateList } = useSelector((state) => state.stateData);
  const { dealerList, amList } = useSelector((state) => state.dealerList);
  const { unMergeData } = useSelector((state) => state.dealerList);


  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getStateListApi())
  }, [])

  const handleSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));
    setData([]);

  };
  const handleAmSelect = (value, option) => {
    const id = option.key;
    setAmId(parseInt(id));
  };


  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      return setStateListArr(stateList?.data);
    } else {
      return setStateListArr([]);
    }
  }, [stateList]);

  useEffect(() => {
    if (Array?.isArray(amList?.data?.result) && amList?.data?.result.length != 0) {
      return setAmListArr(amList?.data?.result);
    } else {
      return setAmListArr([]);
    }
  }, [amList]);

  useEffect(() => {
    if (Array?.isArray(dealerList.data?.result.res) && dealerList.data?.result.res.length != 0) {
      return setData(dealerList.data?.result.res);
    } else {
      return setData([]);
    }
  }, [dealerList]);



  const payload = {
    "stateId": selectedStateId,
  }
  useEffect(() => {
    dispatch(getAMListByStateIdAsync(payload))
  }, [selectedStateId])


  const payloads = {
    "stateId": selectedStateId,
    "areaManagerId": amId,
    "pageSize": collectionData?.pageSize,
    "pageNumber": collectionData?.pageNumber,
    sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
    sortingType: collectionData.sortingType || "DealerCode"
  }

  const payloads1 = {
    "stateId": selectedStateId,
    "areaManagerId": amId,
    "pageSize": collectionData1?.pageSize,
    "pageNumber": collectionData1?.pageNumber,
    sortingOrder: collectionData1.sortingOrder == "ascend" ? "ASC" : "DESC",
    sortingType: collectionData1.sortingType || "DealerCode"
  }
  useEffect(() => {
    dispatch(getDealerListByStateIdAsync(payloads))
    dispatch(getUmMergeDealerListByStateIdAsync(payloads1))
    const updatedPayloads = { ...payloads, pageNumber: 1 };
    const updatedPayloads1 = { ...payloads1, pageNumber: 1 };

    setPayloadsForMerge(updatedPayloads);
    setPayloadsForUnmerge(updatedPayloads1);

  }, [collectionData, amId,selectedStateId,collectionData1]);



 const handlePaginationChange = (pageNumber, pageSize) => {
    setCollectionData({ ...collectionData, pageNumber, pageSize });
 }

 const handleSortingChange = (pagination, filters, sorter) => {
  setCollectionData({ ...collectionData, sortingOrder: sorter?.order, sortingType: sorter?.columnKey });
};

const handlePaginationChange1 = (pageNumber, pageSize) => {
  setCollectionData1({ ...collectionData1, pageNumber, pageSize });
}

const handleSortingChange1 = (pagination, filters, sorter) => {
setCollectionData1({ ...collectionData1, sortingOrder: sorter?.order, sortingType: sorter?.columnKey });
};
  return (
    <div>
      <Select
        showSearch
        placeholder="Select State"
        optionFilterProp="children"
        onSelect={handleSelect}
      // defaultValue={0}
      >
        {/* <Option key={0} value={0}>
        Select State
                </Option> */}

        {stateListArr?.map((option) => (
          <Option key={option.stateID} value={option.stateName}>
            {option.stateName}
          </Option>
        ))}
      </Select>
   
      {/*<Select
        showSearch
        placeholder="Area Manager"
        optionFilterProp="children"
        onSelect={handleAmSelect}

      >
        {amListArr?.map((option) => (
          <Option key={option.areaManagerId} value={option.areaManagerName}>
            {option.areaManagerName}
          </Option>
        ))}
      </Select> */}

      <Select
      className=''
        showSearch
        placeholder="Area Manager"
        optionFilterProp="children"
        onSelect={handleAmSelect}
        value={amListArr?.filter((x)=>x?.areaManagerId==amId)?.[0]?.areaManagerName||"Area Manager"}
      // defaultValue={0}
      >


        {amListArr?.map((option) => (
          <Option key={option.areaManagerId} value={option.areaManagerName}>
            {option.areaManagerName}
          </Option>
        ))}
      </Select>

      <MergeTable
        dataSource={data}
        selectedStateId={selectedStateId}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        payloads={payloadForMerge}

        handleChange={handleSortingChange}
        amId={amId}
      />
      <Pagination
        defaultCurrent={1}
        className='paginationDesign'
        current={collectionData.pageNumber}
        onChange={handlePaginationChange}
        total={dealerList?.data?.result.total}
        showSizeChanger
        pageSize={collectionData.pageSize}
        pageSizeOptions={['150', '200', '250']}
      />

      <Col className="head_title">
        <span>UnMerge</span>
      </Col>
      <UnMergeTable
        dataSource={unMergeData.data?.result.res}
        payloads={payloadForUnmerge}
        selectedStateId={selectedStateId}
        amId={amId}
        handleChange={handleSortingChange1}
      />
      <Pagination
        defaultCurrent={1}
        className='paginationDesign'
        current={collectionData1.pageNumber}
        onChange={handlePaginationChange1 }
        total={unMergeData.data?.result.total}
        showSizeChanger
        pageSize={collectionData1.pageSize}
        pageSizeOptions={['150', '200', '250']}
      />


    </div>
  )
}

export default Merge;