export const LegendHold = () => {
  let colorArray = [
    {
      name: "Updated Value",
      color: "green",
    },
  ];

  return (
    <div className="flex pt-3 flex-wrap bg-white">
      {colorArray?.map((x) => (
        <div
          className="items-center d-flex pr-4 "
          style={{ alignItems: "center" }}
        >
          <div className="Schemes_status">
            <div
              className="Status_color"
              style={{ backgroundColor: x?.color }}
            ></div>
            <span className=" pl-2"> {x?.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};
