import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const getSADMSchemeList = createSlice({
  name: "stateHeadList",
  initialState: { 
      data: [],
  },
  reducers: {
    stateheadData: (state, action)=>{
        state.data = action.payload;
    }
  }
  
});
export const { stateheadData } = getSADMSchemeList.actions;
export const stateHeadList = (state)=> state.stateHeadList.data;
export default getSADMSchemeList.reducer;