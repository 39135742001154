import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const ExpiredWindowShow=()=>{
const navigate=useNavigate()
    return(
        <div>
  <Result
          status="500"
          title="500"
          subTitle="Scheme creation window is expired now"
          extra={
            <Button
              type="primary"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Back Home
            </Button>}
            />
        </div>
    )
}
export default ExpiredWindowShow