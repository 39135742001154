import React, { useEffect, useState, useContext, useRef } from "react";
// import "./createScheme.css";
import { Button, Table, Divider, Col, Row, Form, Input, Alert , Modal, message} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as HideIcon } from "../../../../assets/vector/hide.svg";
import { ReactComponent as UnHideIcon } from "../../../../assets/vector/unHide.svg";
import { ReactComponent as ChangeScheme } from "../../../../assets/vector/changeScheme.svg";
import { useDispatch, useSelector } from "react-redux";
import { schemeList } from "../../../../redux/collection/SchemeList";
import { add, fetchgenerateScheme } from "../../../../redux/collection/PostScheme";
import AlertModal from "../../../../components/modal/alertModal/AlertModal";
import ChangeSchemeModal from "../../../../components/changeSchemeModal";
import dayjs from "dayjs";
import SMDialog from "../../../../components/modal/alertModal/CommonModal";
import { useNavigate } from "react-router-dom";
import { draftScheme } from "../../../../redux/installation/AllDraftScheme";
import ConfirmModal from "../../../../components/modal/alertModal/ConfirmModal";
import axiosAPI from '../../../../services/axiosApi';
import { getCurrentFiscalYear } from "../../../../utils/dateFormatter";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";


const currentFiscalYear = getCurrentFiscalYear()
const InitCollectionData = {
  currentYear: "2024",
  schemeType: "",
  dealerCode: [],
  startDate: "",
  endDate: "",
};

const SingleTargetWithUpperCapEdit = ({ type = "edit", editType = "create" }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const EditableContext = React.createContext(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [changeSchemeDate, setchangeSchemeDate] = useState([]);
  const [editdataSource, setEditDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editRow, setEditingRow] = useState(null);
  const [showHide, setShowUnhide] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [openSchemeModal, setOpenSchemeModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false)
  const [load, setLoad] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const draftListData = useSelector((state) => state.draftScheme.data);
  const [handledata, setHandledata] = useState({});
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });

  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const { schemeType, startDate, endDate, dealerCode } = collectionData;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen(false);
  };


  const generatedSchemeData = useSelector(
    (state) => state.generatedScheme.data
  );

  const selectedRow = JSON.parse(sessionStorage.getItem('rowData'))

  const schemeIds = selectedRow?.map(schemeKey => schemeKey.scheme.schemeId)
  const draftSchemeData = useSelector((state) => state.draftScheme.data);
  const approvalSchemeData = useSelector((state) => state.approvalScheme.data);

  const getGeneratedData = () => {
    if (type !== "edit") return;
    if (editType === "create") return generatedSchemeData;
    else if (editType === "draft") return draftSchemeData;
    else if (editType === "schemeProgress") return approvalSchemeData;
  };

  const generatedData = getGeneratedData();

  useEffect(() => {
    let finalData = [];

    if (editType === "create" && type === "edit") {
      // const schemeId = location?.state?.schemeId;
      if (schemeIds) {
        finalData = generatedSchemeData.data?.data.filter((data) =>
          schemeIds.includes(data.schemeId)
        );
      }
    }
    else if (editType === "draft" && type === "edit") {
      // const schemeId= location?.state?.schemeId

      if (schemeIds) {
        finalData = draftSchemeData.data?.data.filter((data) =>
          schemeIds.includes(data.schemeId)
        );


      }
    }
    else if (editType === "schemeProgress" && type === "edit") {

      if (schemeIds) {
        finalData = approvalSchemeData.filter((data) =>
          schemeIds.includes(data.schemeId)
        );

      }
    }
    else finalData = generatedData;

    /* DISCLAIMER: REMOVE IF THERE IS ANY ISSUE WHILE CHECKBOX SELECTION IN EDIT */
    if (type === "edit" && schemeIds?.length) {
      setSelectedRowKeys(schemeIds);
    }


    if (finalData?.length > 0) {
      const newData = finalData.map((item, index) => {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");

        return {
          status: item.status,
          key: item.schemeId,
          scheme: item,
          code: item.dealerCode,
          name: item.dealerName,
          Location: item.dealerLocation,
          state: item.stateName,
          // stateId: item.stateId,
          Manager: item.territoryManager,
          StartMonth: formattedStartDate,
          EndMonth: formattedEndDate,
          Target: item.installationTarget,
          incPerTarget: item.incPerTarget,
          UC: item.upperCap,
          p1: item.p1,
          reason: item.reason,
          actual_Installation_Last_Year_Month:item.actual_Installation_Last_Year_Month,
        };
      });
      dispatch(schemeList(newData));
    }
  }, [generatedData]);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleSaveModal = () => {
    setOpenSaveModal(!openSaveModal);
  };

  const handleSuccessModal = () => {
    setOpenSuccessModal(!openSuccessModal);
  };

  const handleErrorModal = (firstItem, secondItem) => {
    setOpenErrorModal(!openErrorModal);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };

  const handleSelectModal = () => {
    setOpenSelectModal(!openSelectModal);
  };

  const handleDraftModal = () => {
    setOpenDraftModal(!openDraftModal);
  };

  const listData = useSelector((state) => state.schemeList.data);
  let schemeTypeId
  listData && listData.forEach(element => {

    schemeTypeId = element.scheme.schemeTypeId
  });

  const defaultColumns = [
    {
      title: "Dealer Code",
      dataIndex: "code",
      fixed: "left",
      width: "120px",
      filters: listData.map((item) => ({
        text: item.code,
        value: item.code,
      })),
      sorter: true,
      key:"DealerCode",
      onFilter: (value, record) => record.code.indexOf(value) === 0,
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      fixed: "left",
      width: "150px",
      sorter: true,
      key:"DealerName"
    //  defaultSortOrder: "descend",
    //  sorter: (a, b) => a.name && b.name && a.name.length - b.name.length,
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "120px",
      fixed: "left",
      filters: listData.map((item) => ({
        text: item.Location,
        value: item.Location,
      })),
      sorter: true,
      key:"DealerLocation",
      onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },
    {
      title: "State",
      dataIndex: "state",
      // sorter: true,
      width: "100px",
      filters: listData.map((item) => ({
        text: item.state,
        value: item.state,
      })),
      sorter: true,
      key:"StateName",
      onFilter: (value, record) => record.state.indexOf(value) === 0,
    },
    {
      title: "Territory Manager",
      dataIndex: "Manager",
      // sorter: true,
      width: "120px",
      filters: listData.map((item) => ({
        text: item.Manager,
        value: item.Manager,
      })),
      sorter: true,
      key:"TerritoryManager",
      onFilter: (value, record) => record.Manager.indexOf(value) === 0,
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      width: "100px",
    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      width: "100px",
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      width: '170px',
      // sorter: true,
    },
    {
      title: "Installation Target",
      dataIndex: "Target",
      width: "120px",
      editable: true
    },
    {
      title: "Upper Cap",
      dataIndex: "UC",
      width: '120px',
      editable: true
    },

    {
      title: "inc. Per Tractor",
      dataIndex: "incPerTarget",
      width: "120px",
      editable: true
    },
    {
      title: "Payout",
      dataIndex: "p1",
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      editable: true,
      width: "150px",
    },
  ]
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    if (
      showHide &&
      selectedRowKeys.length > 0 &&
      selectedRowKeys.includes({ ...props }["data-row-key"])
    )
      return;
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave(
          {
            ...record,
            ...values,
          },
          values
        );
      } catch (errInfo) { }
    };
    const handleInputChange = (e, dataIndex) => {
      const isInteger = /^[1-9]+$/;
      const { name, value } = e.target;
      const newlist =  JSON.parse(JSON.stringify(listData));
      if (name === "Remarks") {
        form.setFieldsValue({
          [dataIndex]: e.target.value,
        });

        if (type === "edit") {
          // listData.find((data) => selectedRowKeys.includes(data.key))[
          // editdataSource.find((data) => selectedRowKeys.includes(data.key))[
          newlist.find((data) => selectedRowKeys.includes(data.key))["reason"] = value;
        }
        return;
      }

      if (
        (e.target.value !== "" && e.target.value == 0) ||
        e.target.value < 0 ||
        !Number.isInteger(+e.target.value)
      ) {
        handleModal();
        setDirty(true);
        form.resetFields();
        return;
      }

      if (
        e.target.value !== "" &&
        e.target.value !== 0 &&
        (isInteger.test(+e.target.value) || Number.isInteger(+e.target.value))
      ) {
        form.setFieldsValue({
          [dataIndex]: e.target.value,
        });
        if (type === "edit") {
          // listData.find((data) => selectedRowKeys.includes(data.key))[
          // editdataSource.find((data) => selectedRowKeys.includes(data.key))[

        newlist.find((data) => selectedRowKeys.includes(data.key))[
            dataIndex
          ] = parseInt(e.target.value);
        }
        return;
      }
    }
    let childNode = children;

    if (
      (editable &&
        record.status !== 1 &&
        record.status !== 2 &&
        editType === "create") ||
      (type === "edit" &&
        editable &&
        selectedRowKeys.length > 0 &&
        selectedRowKeys.includes(record.key))
    ) {
      // if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}


          rules={[
            dataIndex !== "reason" &&
            {
              required: true,
              message: "Required",
            },
          ]}

        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            type={dataIndex !== "reason" && "number"}
            name={title}
            onChange={(value) => {
              handleInputChange(value, dataIndex);
            }}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
            height: 20,
            display: "block",
            // background: 'red',
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const handleSave = (row, form) => {
    const newData = [...listData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = { ...newData[index], ...form };

    if (row.UC != null && row.Target != null && +row.UC <= +row.Target) {
      handleErrorModal('UpperCap', ' Installation Target')
      return;
    }

    if (row.incPerTarget) {
      row.p1 = (parseInt(row.UC) * parseInt(row.incPerTarget));
    }

    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    dispatch(schemeList(newData));
    // setDataSource(newData);
  };

  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }
    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };

  const columns = defaultColumns.map((col) => {
    return mapColumns(col);

  });


  const formatData = (result) => {
    const data = [];
    result.map((item, index) => {

      for (let i = 0; i < 1; i++) {

        let formattedStartDate = dayjs(startDate).format("MMM'YY");
        let formattedEndDate = dayjs(endDate).format("MMM'YY");

        data.push({
          status: item.status,
          key: item.admDealerId,
          code: item.dealerCode,
          name: item.dealerName,
          Location: item.cityName,
          state: item.stateName,
          Manager: item.territoryName,
          StartMonth: formattedStartDate,
          EndMonth: formattedEndDate,
          stateId: item.stateId,
          installationTarget: item.Target,
          incPerTarget: item.incPerTarget,
          BooksCollectionTargetT1: null,
          BooksCollectionTargetT2: null,
          BooksCollectionTargetT2T1: null,
          BooksCollectionTargetT3: null,
          BooksCollectionTargetT3T2: null,
          PayoutRatep1: null,
          PayoutRatep2: null,
          PayoutRatep3: null,
          payoutR1: null,
          payoutR2: null,
          payoutR3: null,
          TEPvol: null,
          TEPpertr: null,
          TEPamount: null,
          LMSA: null,
          LCQ: null,
          LMSA1: null,
          LCQ1: null,
        });
      }
    });

    return data;
  };

  const handleChangeScheme = (data) => {
    setOpenSchemeModal((openSchemeModal) => !openSchemeModal);

    if (data?.startDate) {
      setchangeSchemeDate(data);

      const currentRecord = listData.find((data) =>
        selectedRowKeys.includes(data.key)
      );

      const payload = {
        startMonth: data.startDate,
        endMonth: data.endDate,
        schemeId: currentRecord.key,
      };

      axiosAPI
        .post(
          `UpdateScheme/UpdateChangeScheme`,
          payload
        )
        .then((result) => {
          currentRecord.StartMonth = dayjs(data.startDate).format("MMM'YY");
          currentRecord.EndMonth = dayjs(data.endDate).format("MMM'YY");
          handleSuccessModal();
        });
      // API CALL FOR UPDATE
      // SELECTED RECORD UPDATE
    }
  };



  const tableHeader = () => {
    const handleHide = () => {
      if (selectedRowKeys.length == 0) {
        handleSelectModal()
        return;
      }
      setShowUnhide(!showHide);
    };

    return (
      <Row className="table_sub_buttons">
        <Col>
          <Button
            icon={showHide === true ? <UnHideIcon /> : <HideIcon />}
            type="text"
            style={{
              margin: "10px",
              color: showHide === true ? "#FF3232" : "",
            }}
            onClick={handleHide}
          >
            {showHide === true ? "Show" : "Hide"}
          </Button>
        </Col>

        {!["draft", "schemeProgress"].includes(editType) && (
          <Col>
            <Button
              onClick={handleChangeScheme}
              type="text"
              style={{ margin: "10px" }}
            >
              <ChangeScheme />
              Change Scheme
            </Button>
          </Col>
        )}
      </Row>
    );
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  //   const handlePayloadValidation = (payload) => {
  //     if (!payload.reason || !payload.reason) {
  //       return;
  //     }
  //   };

  const draftData = () => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const userRole = userData.userRole;
    const finalData = [];
    if (selectedRowKeys.length === 0) {
      handleSelectModal();
      return;
    }
    selectedRowKeys.forEach((item) => {

      for (let dataItem of listData) {
        if (dataItem.key === item) {
          const payload = {
            dealerCode: dataItem.code,
            state: dataItem.stateId,
            dealerId: +dataItem.key,
            StartMonth: startDate,
            EndMonth: endDate,
            reason: dataItem.reason,
            billing: +dataItem.feb22b,
            installation: +dataItem.feb22i,
            collection: +dataItem.feb22c,
            isSAP: true,
            isActive: true,
            installationTarget: dataItem.Target ? dataItem.Target : 0,
            incPerTarget: dataItem.incPerTarget ? dataItem.incPerTarget : 0,
            schemePlanStartMonth: startDate,
            schemePlanEndMonth: endDate,
            schemeTypeId: schemeType,
            t1: +dataItem.BooksCollectionTargetT1,
            t2: +dataItem.BooksCollectionTargetT2,
            t3: +dataItem.BooksCollectionTargetT3,
            t2_T1: dataItem.BooksCollectionTargetT2T1,
            t3_T2: dataItem.BooksCollectionTargetT3T2,
            p1: dataItem.payoutR1,
            p2: dataItem.payoutR2,
            p3: dataItem.payoutR3,
            r1: +dataItem.PayoutRatep1,
            r2: +dataItem.PayoutRatep2,
            r3: +dataItem.PayoutRatep3,
            volume: +dataItem.TEPvol,
            perTr: dataItem.TEPpertr,
            amount: dataItem.TEPamount,
            fyear: currentFiscalYear,
            upperCap: dataItem.UC ? dataItem.UC : 0,
            userid: +userId,
            username: userName,
            userrole: userRole,
          };
          finalData.push(payload);
          break;
        }
      }
    });
    dispatch(fetchgenerateScheme(finalData));
    dispatch(add(finalData));
    handleDraftModal();
  };

  const handleCancel = () => {
    setOpenSubmitModal(!openSubmitModal)
  }
  const handleUpdateScheme = () => {
    setOpenSubmitModal(!openSubmitModal)
    if (selectedRowKeys.length === 0) {
      handleSelectModal();
      return;
    }
    let payload = [];
    let status = 0
    if (editType === "schemeProgress" && type === "edit") {
      status = 1
    }
    else if (editType === "draft" && type === "edit") {
      status = 0
    }
    listData.length > 0 &&
      listData.map((currentRecord) => {

        const userData = decryptData(sessionStorage.getItem("userInfo"));
        const userId = userData.userId;
        const userName = userData.userName;
        payload.push({
          dealerCode: currentRecord.code,
          dealerId: +currentRecord.key,
          Manager: currentRecord.Manager,
          schemeId: currentRecord.key,
          stateName: currentRecord.state,
          startMonth: currentRecord.scheme.startMonth,
          endMonth: currentRecord.scheme.endMonth,
          reason: currentRecord.reason,
          delayPlan: currentRecord.scheme.delayPlan,
          status: ((editType === "draft"||editType === "create") && type === "edit")?1:status,
          billing: currentRecord.scheme.billing,
          installation: currentRecord.scheme.installation,
          installationTarget: currentRecord.Target ? parseInt(currentRecord.Target) : 0,
          incPerTarget: currentRecord.incPerTarget ? parseInt(currentRecord.incPerTarget) : 0,
          collection: currentRecord.scheme.collection,
          openingStockDays: currentRecord.scheme.openingStockDays,
          outStandingDays: currentRecord.scheme.outStandingDays,
          isSAP: true,
          isActive: true,
          p1: parseInt(currentRecord.p1),
          remarks: currentRecord.scheme.remarks,
          lastMonthSlab: currentRecord.scheme.lastMonthSlab,
          lastMonthColQty: currentRecord.scheme.lastMonthColQty,
          lastTwoMonthSlab: currentRecord.scheme.lastTwoMonthSlab,
          lastTwoMonthColQty: currentRecord.scheme.lastTwoMonthColQty,
          fyear: currentFiscalYear,
          upperCap: currentRecord.UC ? parseInt(currentRecord.UC) : 0,
          userName: userName,
          userId: userId
        });
      });

    axiosAPI
      .post(
        `UpdateScheme/UpdateSchemeGenerate`,
        payload
      )
      .then((result) => {
        // handleSuccessModal();
        if (type === "edit" && editType === "schemeProgress") {
          navigate('/schemeProgress')
        }
        else if (type === "edit" && editType === "draft") {
          navigate('/installation/draftSchemes')
        }
        else {
          navigate('/installation/allSchemes')
        }

      });
  };


  const submitData = (e, allData) => {
    setOpenSubmitModal(!openSubmitModal)
  }


  const handleSaveScheme = (e) => {
    setOpenSubmitModal(!openSubmitModal)
    e.preventDefault();

    if ((type === "new" && editType === "create") || (type === "edit" && editType === "draft")) {
      const userData = decryptData(sessionStorage.getItem("userInfo"));
      const userId = userData.userId;
      const userName = userData.userName;
      const finalData = [];


      if (selectedRowKeys.length == 0) {
        // alert("Please select a dealer.");
        handleSelectModal();
        return;
      }
      selectedRowKeys.forEach((item) => {
        for (let dataItem of listData) {
          if (dataItem.key === item) {
            const payload = {
              dealerCode: dataItem.code,
              dealerId: +dataItem.key,
              Manager: dataItem.Manager,
              StartMonth: startDate,
              EndMonth: endDate,
              reason: dataItem.reason,
              status: 1,
              state: dataItem.stateId,
              billing: +dataItem.feb22b,
              installation: +dataItem.feb22i,
              collection: +dataItem.feb22c,
              isSAP: true,
              isActive: true,
              installationTarget: dataItem.Target ? dataItem.Target : 0,
              incPerTarget: dataItem.incPerTarget ? dataItem.incPerTarget : 0,
              schemePlanStartMonth: startDate,
              schemePlanEndMonth: endDate,
              schemeTypeId: schemeType,
              t1: +dataItem.BooksCollectionTargetT1,
              t2: +dataItem.BooksCollectionTargetT2,
              t3: +dataItem.BooksCollectionTargetT3,
              t2_T1: dataItem.BooksCollectionTargetT2T1,
              t3_T2: 5,
              p1: dataItem.payoutR1,
              p2: dataItem.payoutR2,
              p3: dataItem.payoutR3,
              r1: +dataItem.PayoutRatep1,
              r2: +dataItem.PayoutRatep2,
              r3: +dataItem.PayoutRatep3,
              volume: +dataItem.TEPvol,
              perTr: Math.round(dataItem.TEPpertr),
              amount: dataItem.TEPamount,
              isSubmitted: 1,
              fyear: currentFiscalYear,
              upperCap: dataItem.UC ? dataItem.UC : 0,
              userid: +userId,
              username: userName,
            };

  // handleSuccessModal();
  finalData.push(payload);
  break;
          }
        }
      });
      if (type === "edit" && editType === "draft") {
        // handleUpdateScheme()
        const status = (editType === "schemeProgress" && type === "edit") ? 1 : 0;
        let payload = [];
        const fetchGeneratedScheme = async (demoParams) => {
          let result = await axiosAPI.post(`SchemeGenerate/GetDraftedScheme`,demoParams)
          dispatch(draftScheme(result.data.data));
          navigate('/installation/allSchemes');
        };
        /* const payload = draftListData.filter((data) => schemeIds.includes(data.schemeId))
          .map((obj) => {
            const { status, ...rest } = obj;
            return rest;
          }); */

          
        listData.length > 0 && listData.map((currentRecord) => {

          const userData = decryptData(sessionStorage.getItem("userInfo"));
          const userId = userData.userId;
          const userName = userData.userName;
          payload.push({
            dealerCode: currentRecord.code,
            dealerId: +currentRecord.key,
            Manager: currentRecord.Manager,
            schemeId: currentRecord.key,
            stateName: currentRecord.state,
            startMonth: currentRecord.scheme.startMonth,
            endMonth: currentRecord.scheme.endMonth,
            reason: currentRecord.reason,
            delayPlan: currentRecord.scheme.delayPlan,
            status: status,
            billing: currentRecord.scheme.billing,
            installation: currentRecord.scheme.installation,
            installationTarget: currentRecord.Target ? parseInt(currentRecord.Target) : 0,
            incPerTarget: currentRecord.incPerTarget ? parseInt(currentRecord.incPerTarget) : 0,
            collection: currentRecord.scheme.collection,
            openingStockDays: currentRecord.scheme.openingStockDays,
            outStandingDays: currentRecord.scheme.outStandingDays,
            isSAP: true,
            isActive: true,
            p1: parseInt(currentRecord.p1),
            remarks: currentRecord.scheme.remarks,
            lastMonthSlab: currentRecord.scheme.lastMonthSlab,
            lastMonthColQty: currentRecord.scheme.lastMonthColQty,
            lastTwoMonthSlab: currentRecord.scheme.lastTwoMonthSlab,
            lastTwoMonthColQty: currentRecord.scheme.lastTwoMonthColQty,
            fyear: currentFiscalYear,
            upperCap: currentRecord.UC ? parseInt(currentRecord.UC) : 0,
            userName: userName,
            userId: userId
          });
        });

        axiosAPI
        .post(`UpdateScheme/DraftToSchemeGenerate`, payload)
        .then((result) => {
          message.success({
            content: "Scheme Generated Successfully",
            className: "custom-message",
          });
          // setOpenSuccessModal(!openSuccessModal);
          const userData = decryptData(sessionStorage.getItem("userInfo"));
          const userId = userData.userId;
          const demoParams = {
            fyear: currentFiscalYear,
            schemeType: 7,
            userid: userId,
          };
          fetchGeneratedScheme(demoParams);
        });
    }
      else{
        // dispatch(fetchgenerateScheme(finalData));
        dispatch(add(finalData));
      }

    } else if (type === "edit") handleUpdateScheme();
  }


  const hasSelected = selectedRowKeys?.length > 0;


  const getTitle = () => {
    switch (editType) {
      case "draft":
        return "Edit Draft";
      case "schemeProgress":
        return "Edit Scheme Progress";
      case "create":
        return "Edit";
      default:
        return "";
    }
  };

  const getBackPath = () => {
    if (editType === "draft") return "/installation/draftSchemes";
    else if (editType === "schemeProgress")
      return "/schemeProgress";
    else return "/installation/allSchemes";
  };


  return (
    <div className="table_scheme_wrap">
      <Divider style={{ marginBottom: "10px" }} />
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "25px",
        }}
      >
        <Col style={{ display: "flex", alignItems: "center" }}>
          {type === "edit" && (
            <Link to={getBackPath()} style={{ color: "#000" }}>
              <LeftOutlined style={{ fontSize: "14px" }} />
            </Link>
          )}

          <h2 style={{ fontSize: "18px" }}>
            {`${type === "new" ? "Create" : getTitle()} Scheme`}
          </h2>
        </Col>

      </Row>
      <span>
        {hasSelected ? `Selected ${selectedRowKeys?.length} items` : ""}
      </span>

      {type === "edit" && listData?.length > 0 && (
        <Form>
          <Table
            title={tableHeader}
            components={components}
            rowSelection={{
              selectedRowKeys,
              onChange: (newSelectedRowKeys) => {
                setSelectedRowKeys(newSelectedRowKeys);
              },
              getCheckboxProps: (record) => ({
                // disabled: record.status === 1 || record.status === 2,
                // Column configuration not to be checked
                name: record.name,
              }),
            }}
            rowClassName={() => "editable-row"}
            columns={columns}
            dataSource={listData}
            bordered
            scroll={{
              x: 1800,
              y: 500,
            }}
     
          />
        </Form>
      )}

      {
        //   (isAnyEmpty(collectionData) && [2, 1].includes(schemeType)) ||
        type === "edit" && listData?.length > 0 ? (
          <Row
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >


            {/* {!["draft", "awaitingApproval", "create"].includes(editType) && ( */}
            {type === "new" && (
              <Col>
                <Button
                  type="default"
                  shape="round"
                  style={{
                    margin: "10px",
                    background: "#FCE838",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    border: "none",
                    padding: "20px",
                  }}
                  size="large"
                   // onClick={showModal}
                  onClick={draftData}
                  
                >
                  save as draft
                </Button>
              </Col>
            )}
            {(type === "edit" && editType === "draft") && (
              <Col>
                <Button
                  type="default"
                  shape="round"
                  style={{
                    margin: "10px",
                    background: "#FCE838",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    border: "none",
                    padding: "20px",
                  }}
                  size="large"
                  // onClick={handleUpdateScheme}
                  onClick={showModal}
                >
                  save as draft
                </Button>
              </Col>
            )}
            <Col>
              <Button
                type="default"
                shape="round"
                className='schemi-btn-all'
                style={{
                  margin: "10px",
                  background: "#FCE838",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  border: "none",

                }}
                size="large"
                onClick={(e) => submitData(e)}
              // disabled={listData.every((item) => item.nos == "0")}
              >
                Save & Submit
              </Button>
            </Col>
          </Row>
        ) : null}
      <ChangeSchemeModal
        openModal={openSchemeModal}
        onModalClose={handleChangeScheme}
      />


      {dirty && <AlertModal         maskClosable={false}
        destroyOnClose={true} openModal={openModal} handleModal={handleModal} />}
      <Modal open={isModalOpen} onOk={() => {handleUpdateScheme()
        handleOk()}} onCancel={handleCancel1}>
       <h2>Are you sure want to submit?</h2>
      </Modal>      
      
      <SMDialog open={openSaveModal} onCancel={handleSaveModal}>
        <p className="custom-warning">Warning !</p>
        <p>Please fill the required values.</p>
      </SMDialog>

      {/* <SMDialog open={openSuccessModal} onCancel={handleSuccessModal}>
        <p className="custom-warning">Success</p>
        <p>Are you sure you want to {type === "edit" ? "Updated" : "submit"} </p>
      </SMDialog> */}

      <SMDialog open={openDraftModal} onCancel={handleDraftModal}>
        <p className="custom-warning">Success</p>
        <p> Scheme Saved Successfully</p>
      </SMDialog>


      <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>

        <p> {errorItem.firstItem} should be greater then  {errorItem.secondItem}</p>

      </SMDialog>
      <SMDialog open={openSelectModal} onCancel={handleSelectModal}>
        <p className="custom-warning">Warning !</p>
        <p>Please Select The Dealer</p>
      </SMDialog>
      <ConfirmModal
        open={openSubmitModal}
        handleCancel={handleCancel}
        handleOk={handleSaveScheme}
        confirmLoading={load}
        modalText={'Are you sure want to submit?'}
      />
    </div>
  );


}
export default SingleTargetWithUpperCapEdit
