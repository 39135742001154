import React, { useState, useEffect } from "react";
import { Button, Table, Divider, Col, Row, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SlabBasedScheme from "./SlabBasedSchemeTable";
import SingleTarget from "./SingleTargetTable";
import SingleTargetWithUpperCap from "./SingleTargetWithUpperCapTable";
import UnconditionalInstallation from "./UnconditionalInstallationScheme";
import ThemeButton from "../../../components/button/buttons";
import { schemeList } from "../../../redux/collection/SchemeList";
import axiosAPI from '../../../services/axiosApi';
import dayjs from "dayjs";

const InitCollectionData = {
  currentYear: dayjs().year(),
  // collectionType: 'Collection',
  schemeType: "",
  dealerCode: [],
  startDate: "",
  endDate: "",
};

const InstallationEditScheme = () => {
  const [selectedTable, setSelectedTable] = useState({ value: "", key: 0 });
  // const [startDate, setStartDate] = useState(dayjs());
  const [listData, setListData] = useState([]);

  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const { schemeType, startDate, endDate, dealerCode } = collectionData;
  const dispatch = useDispatch();
  // const listData = useSelector((state) => state.schemeList.data);
  const handleSchemeTableChange = (value, key) => {
    setSelectedTable({ value, key });
  };

  const changeStartDate = (date) => {
    setCollectionData.startDate(date.format());
  };
  const changeEndDate = (date) => {
    setCollectionData.startDate(date.format());
  };

  const isAnyEmpty = collectionData => Object.values(collectionData).every(value => {
    if (value == '' || value == []) {
      return false;
    }
    return true;
  });

  const getDealerDropdownData = (userId) => {
    axiosAPI
      .post(`SchemeGenerate/GetSchemeList`, { userId })
      .then((result) => {
        setListData(result.data.data.map(item => ({...item, code: item.dealerCode})))
      });
  }

  useEffect(() => {
    // const userData = decryptData(sessionStorage.getItem("userInfo"));
    // const userId = userData.userId;
    // const userRole = userData.userRole;
    // const staticAmId = 2;
    // if (userRole === "AMGR") {
    //   axiosAPI
    //     .post(
    //       `https://marketing-webapp.azurewebsites.net/mclaim/api/SchemeGenerate/GetSchemeList`,
    //       { userId }
    //     )
    //     .then((result) => {
    //       setListData(result.data.data.map(item => ({...item, code: item.dealerCode})))
    //       // const data = formatData(result.data.data);
    //       // dispatch(schemeList(data));
    //     });
    // }
  }, []);

  const renderSchemeTable = () => {
    switch (schemeType) {
      case "slabBased":
        return <SlabBasedScheme startDate={startDate} endDate={endDate} dealerCode={dealerCode} listData={listData}  />;
      case "singleTarget":
        return <SingleTarget startDate={startDate} endDate={endDate} dealerCode={dealerCode} listData={listData} />;
      case "singleTargetWithUpperCap":
        return (
          <SingleTargetWithUpperCap startDate={startDate} endDate={endDate} dealerCode={dealerCode} listData={listData} />
        );
      case "unconditionalInstallation":
        return (
          <UnconditionalInstallation startDate={startDate} endDate={endDate} dealerCode={dealerCode} listData={listData} />
        );
      default:
        return null;
    }
  };

  const handleDealerCode = (dlrCode, currentSelected) => {
    if (dealerCode.includes("allDealer") && !dlrCode.includes("allDealer")) {
      setCollectionData({ ...collectionData, dealerCode: [] });
      return;
    }
    if (dealerCode.includes("allDealer") && dlrCode.includes("allDealer")) {
      setCollectionData({
        ...collectionData,
        dealerCode: dlrCode.filter((item) => item != "allDealer"),
      });
      return;
    }
    if (dlrCode.includes("allDealer")) {
      setCollectionData({
        ...collectionData,
        dealerCode: [...[...listData].map((item) => item.code), "allDealer"],
      });
      return;
    }
    setCollectionData({ ...collectionData, dealerCode: dlrCode });
  };

  return (
    <>
      <Divider orientation="center" style={{ marginTop: 30 }} />
      <Row>
        <Col flex="auto">
          <h2
            style={{ fontSize: "18px", padding: "20px", paddingLeft: "60px" }}
          >
            Create Scheme
          </h2>
        </Col>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: "40px",
          }}
        >
          <ThemeButton
            collectionData={collectionData}
            listData={listData}
            onSchemeChange={handleSchemeTableChange}
            handleStartChange={(date) => {
              setCollectionData({
                ...collectionData,
                startDate: date == null ? "" : date.format(),
              });
            }}
            handleEndChange={(date) =>
              setCollectionData({
                ...collectionData,
                endDate: date == null ? "" : date.format(),
              })
            }
            onSelectSchemeType={(schemeType) =>
              setCollectionData({ ...collectionData, schemeType })
            }
            onSelectDealer={(dealerCode, currentSelected) =>
              handleDealerCode(dealerCode, currentSelected)
            }
            onGetDealerDropdownData={getDealerDropdownData}
          />
        </Col>
      </Row>
      <div>
        <Row>
          <Col>{isAnyEmpty(collectionData) ? renderSchemeTable() : null}</Col>
        </Row>
      </div>
    </>
  );
};
export default InstallationEditScheme;
