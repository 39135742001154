import React, { useState } from "react";
import { Divider, Input } from "antd";

import CollapseTab from "../allSchemes/collapseTab";
import AllSchemeHeader from "./AllSchemeHeader";
import "./allScheme.css";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import dayjs from "dayjs";

const currentFiscalYear = getCurrentFiscalYear();


const initPayloadData = {
  fyear: currentFiscalYear,
  schemeType: 1,
  // state: 1,
  // userid: 0,
  month: new Date().getMonth() + 1,
  monthEnd: new Date().getMonth() + 1,
  enforcedMonth: 0,
};

const CollectionAllSchemes = () => {
  const [payload, setPayload] = useState(initPayloadData);
  const [startMonth, setStartMonth] = useState(dayjs());
  const [dealerDetails, setDealerDetails] = useState({
    code: "",
    location: "",
  });
  return (
    <>
      <div className="create_scheme_wrap collection-schme-box creating-schema-box">
        <Divider />
       
          <div className="d-flex align-items-center flex-end pb-3">
         
            <AllSchemeHeader
              onChangeFilter={(key, val) =>
                setPayload({ ...payload, [key]: val }
                )
              }
              startMonth={startMonth} 
              setStartMonth={setStartMonth}
              payload={payload}
              showEnforcedMonth={true}
              setDealerDetails={setDealerDetails}
              dealerDetails={dealerDetails}
              showFilter={true}
            /> 
       
          </div>

      
        <CollapseTab payload={payload} startMonth={startMonth} dealerDetails={dealerDetails}/>
      </div>
      <div className ="All_scheme_status" style={{marginRight: '220px', marginTop: '20px'}} >
                <div className ="Schemes_status">
                    <div className="Status_color" style={{backgroundColor: "green" }}></div>
                    Approved
                </div>
                <div className ="Schemes_status">
                    <div  className="Status_color"  style={{ backgroundColor: "red" }}></div>
                    Rejected
                </div>
                <div className ="Schemes_status">
                    <div  className="Status_color"  style={{ backgroundColor: "yellow" }}></div>
                    Work in Progress
                </div>
            <div className ="Schemes_status">
                <div   className="Status_color"           style={{ backgroundColor: "rgb(255, 0, 255)" }}></div>
                Auto Rejected
            </div>
            <div className ="Schemes_status">
                <div   className="Status_color"  style={{ backgroundColor:"#BCB38D" }}></div>
                Deviation
            </div>
            <div className ="Schemes_status">
                <div   className="Status_color"  style={{ backgroundColor: "#9999ff" }}></div>
                Hold
            </div>
      </div>
    </>
  );
};
export default CollectionAllSchemes;
