import { Table, Col, Row, Button } from "antd";
import React, { useState, useEffect } from "react";
import { getCurrentFiscalYear } from "../../../../utils/dateFormatter";
import { useLocation } from "react-router-dom";
import tableData from "./tableData";
import axiosAPI from "../../../../services/axiosApi";
import ToastifyShow from "../../../../components/ToastifyShow";
import FullScreenLoader from "../../../../components/loader/FullScreenLoader";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
const currentFiscalYear = getCurrentFiscalYear();
const SLAB_BASED = 3;
const SINGLE_TARGET = 8;
const SINGLE_TARGET_WITH_UPPER_CAP = 4;
const UNCONDITIONAL_STATEMENT = 5;

const TrackCollectionTable = ({ selectedSchemeType, 
  loading, 
  dataSource, 
  grandTotal, 
  onSendEmail, 
  onSelectedRows,
  handleChange }) => {
  const location = useLocation();
  const userInfo = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userInfo.userRole;

  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [selectedRows, setSelectedRows] = useState([])
  const [fullPageLoader, setFullPageLoader] = useState(false);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (order == '') {
      setOrder('desc');
      setOrderBy(property);
      return;
    }
    if (order == 'desc') {
      setOrder('asc');
      setOrderBy(property);
      return;
    }
    if (order == 'asc') {
      setOrder('');
      setOrderBy('');
      return;
    }
  };

  const visibleRows = stableSort(dataSource, getComparator(order, orderBy))

  const a = React.useMemo(
    () =>
      stableSort(dataSource, getComparator(order, orderBy))
      // .slice(
      //   page * rowsPerPage,
      //   page * rowsPerPage + rowsPerPage,
      // ),
      [order, orderBy], //, page, rowsPerPage
  );

  const columns = tableData("Dealer wise Dashboard", userRole, "dashboard", handleRequestSort, selectedSchemeType, onSendEmail);

  const getSum = grandTotal && Object.values(grandTotal).reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  const getValue = (field) => getSum == 0 ? '' : field

  const colsToBeadded = visibleRows.map(item => ({
    p1: item?.p1, 
    p2: item?.p2,
    p3: item?.p3,
    t1: item?.t1, 
    t2: item?.t2,
    t3: item?.t3,
    r1: item?.r1, 
    r2: item?.r2,
    r3: item?.r3,
    actual_Installation_Last_Year_Month: item?.actual_Installation_Last_Year_Month,
    installationTarget: item?.installationTarget,
    incPerTractor: item?.incPerTarget,
    payout: item?.p1,
    upperCap: item?.upperCap,
  }))
 

  const pageTotal = colsToBeadded.reduce((acc, obj) => {
    for (const key in obj) {
      if (acc.hasOwnProperty(key)) {
        acc[key] += obj[key];
      } else {
        acc[key] = obj[key];
      }
    }
    return acc;
  }, {});

  return (
    <>
      {fullPageLoader?<FullScreenLoader/>:""}
      <Table
        loading={loading ? true : false}
        total={dataSource?.length || 1}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
                <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={39}></Table.Summary.Cell>
                { selectedSchemeType == SLAB_BASED &&<Table.Summary.Cell index={139}></Table.Summary.Cell>}
                <Table.Summary.Cell index={9}>{getValue(pageTotal?.actual_Installation_Last_Year_Month)}</Table.Summary.Cell>
                {
                  selectedSchemeType == SLAB_BASED &&
                  <>
                    <Table.Summary.Cell index={24}>{getValue(pageTotal?.t1)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{getValue(pageTotal?.t2)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={26}>{getValue(pageTotal?.t3)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={27}>{(Math.round(getValue(pageTotal?.p1) / getValue(pageTotal?.t1)))||''}</Table.Summary.Cell>
                    <Table.Summary.Cell index={28}>{(Math.round(getValue(pageTotal?.p2) / getValue(pageTotal?.t2)))||""}</Table.Summary.Cell>
                    <Table.Summary.Cell index={29}>{(Math.round(getValue(pageTotal?.p3) / getValue(pageTotal?.t3)))||""}</Table.Summary.Cell>
                    <Table.Summary.Cell index={30}>{getValue(pageTotal?.p1)} </Table.Summary.Cell>
                    <Table.Summary.Cell index={31}>{getValue(pageTotal?.p2)} </Table.Summary.Cell>
                    <Table.Summary.Cell index={32}>{getValue(pageTotal?.p3)} </Table.Summary.Cell>
                  </>
                }
  
                {
                  selectedSchemeType == SINGLE_TARGET &&
                  <>
                    <Table.Summary.Cell index={24}>{getValue(pageTotal?.installationTarget)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{Math.round(getValue(pageTotal?.payout) / getValue(pageTotal?.installationTarget))|| ""} </Table.Summary.Cell>
                    <Table.Summary.Cell index={29}>{getValue(pageTotal?.payout)}</Table.Summary.Cell>
                  </>
                }
  
                {
                  selectedSchemeType == SINGLE_TARGET_WITH_UPPER_CAP &&
                  <>
                    <Table.Summary.Cell index={24}>{getValue(pageTotal?.installationTarget)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{getValue(pageTotal?.upperCap)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{Math.round(getValue(pageTotal?.payout) / getValue(pageTotal?.installationTarget)) || ""}</Table.Summary.Cell>
                    <Table.Summary.Cell index={29}>{getValue(pageTotal?.payout)}</Table.Summary.Cell>
                  </>
                }
  
                {
                  selectedSchemeType == UNCONDITIONAL_STATEMENT &&
                  <>
                    <Table.Summary.Cell index={24}>{getValue(pageTotal?.installationTarget)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{Math.round(getValue(pageTotal?.p1) / getValue(pageTotal?.installationTarget))||""}</Table.Summary.Cell>
                    <Table.Summary.Cell index={29}>{getValue(pageTotal?.p1)}</Table.Summary.Cell>
                  </>
                }
  
                <Table.Summary.Cell index={38}></Table.Summary.Cell>
                <Table.Summary.Cell index={38}></Table.Summary.Cell>
                { userRole == 'SADM' && <Table.Summary.Cell index={40} /> }
              </Table.Summary.Row>
  
              <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
                <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Grand Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={39}></Table.Summary.Cell>
                { selectedSchemeType == SLAB_BASED &&<Table.Summary.Cell index={139}></Table.Summary.Cell>}
                <Table.Summary.Cell index={9}>{getValue(grandTotal?.acutalInstallationLastYearMonth)}</Table.Summary.Cell>
                {
                  selectedSchemeType == SLAB_BASED &&
                  <>
                    <Table.Summary.Cell index={24}>{getValue(grandTotal?.t1)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{getValue(grandTotal?.t2)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={26}>{getValue(grandTotal?.t3)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={27}>{getValue(grandTotal?.r1)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={28}>{getValue(grandTotal?.r2)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={29}>{getValue(grandTotal?.r3)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={30}>{getValue(grandTotal?.p1)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={31}>{getValue(grandTotal?.p2)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={32}>{getValue(grandTotal?.p3)}</Table.Summary.Cell>
                  </>
                }
  
                {
                  selectedSchemeType == SINGLE_TARGET &&
                  <>
                    <Table.Summary.Cell index={24}>{getValue(grandTotal?.installationTarget)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{getValue(grandTotal?.incPerTractor)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={29}>{getValue(grandTotal?.payout)}</Table.Summary.Cell>
                  </>
                }
  
                {
                  selectedSchemeType == SINGLE_TARGET_WITH_UPPER_CAP &&
                  <>
                    <Table.Summary.Cell index={24}>{getValue(grandTotal?.installationTarget)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{getValue(grandTotal?.upperCap)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{getValue(grandTotal?.incPerTractor)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={29}>{getValue(grandTotal?.payout)}</Table.Summary.Cell>
                  </>
                }
  
                {
                  selectedSchemeType == UNCONDITIONAL_STATEMENT &&
                  <>
                    <Table.Summary.Cell index={24}>{getValue(grandTotal?.installationTarget)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{getValue(grandTotal?.incPerTractor)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={29}>{getValue(grandTotal?.payout)}</Table.Summary.Cell>
                  </>
                }
  
                <Table.Summary.Cell index={38}></Table.Summary.Cell>
                <Table.Summary.Cell index={38}></Table.Summary.Cell>
                { userRole == 'SADM' && <Table.Summary.Cell index={40} /> }
              </Table.Summary.Row>
            </>
          );
        }}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys, selectedRows) => {
            onSelectedRows(selectedRows)
          },
          getCheckboxProps: (record) => ({ disabled: !record.isCommunication })
        }}
        columns={columns}
        onChange={handleChange}
        pagination={false}
        dataSource={visibleRows.map((item, ind) => ({key: ind, ...item}))}
        scroll={{
          x: 2000,
          y: 1000,
        }}
      />
    </>
  );
};

export default TrackCollectionTable;
