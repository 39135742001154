import dayjs from "dayjs";

export const formatDate = (date, format) => {
	if (!date || date === '') { return date };
	format = format || 'YYYY-MM-DD hh:mm:ss A';
	return dayjs(date).format(format);
};

/**
 * Get current fiscal year as per API requirement
 * 
 * @param {String} prefix 
 * @returns {String}
 */
export const getCurrentFiscalYear = (currentDate1,prefix = 'FY') => {
  const currentDate = currentDate1||dayjs();
  const currentMonth = currentDate.month() + 1;
  const financialYear = currentMonth > 3 ? currentDate.year() + 1 : currentDate.year();

  return prefix + financialYear.toString().substring(2);
};

export const getCurrentFiscalYear1 = (currentDate1,prefix = 'FY') => {

  const currentDate = dayjs(currentDate1) ||dayjs();
  const currentMonth = dayjs(currentDate1)?.month() + 1;
  const financialYear = currentMonth > 3 ? currentDate?.year() + 1 : currentDate?.year();

  return prefix + financialYear?.toString().substring(2);
};

export const getCurrentFiscalYearShow = (currentDate1,prefix = 'FY') => {

  const currentDate = dayjs(currentDate1) ||dayjs();
  const currentMonth = dayjs(currentDate1)?.month() + 1;
  const financialYear = currentDate?.year();

  return prefix + financialYear?.toString().substring(2);
};