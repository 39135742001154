import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './redux/store'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from './components/ErrorBoundary';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
           <ErrorBoundary>
{/* {"homepage": "https://marketing-webapp.azurewebsites.net",} */}
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename="/">
        <App />
        <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
      </BrowserRouter>
    </PersistGate>
    </ErrorBoundary>
  </Provider>
  // </React.StrictMode>
);
