import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



const getDraftScheme = createSlice({
  name: "draftScheme",
  initialState: { 
      data: [],
  },
  reducers: {
    draftScheme: (state, action)=>{
        state.data = action.payload;
    }
  }

});
export const { draftScheme } = getDraftScheme.actions;
export const getAllDraftScheme = (state)=> state.draftScheme.data;
export default getDraftScheme.reducer;