import axios from "axios";
import { BASE_API_PATH } from "../config";
import { decryptData } from "../utils/localStorageEncodeDecode";
import CryptoJS from "crypto-js";
import { xUseValue, velocityX } from "./encryption";
// const requestMap = new Map();
// const { CancelToken } = axios;

const API = axios.create({
  baseURL: BASE_API_PATH,
  headers: { "Content-Type": "application/json" },
});

API.interceptors.request.use(
  (config) => {
//
  //   // Generate a unique key for the request based on its data
  //   const requestKey = JSON.stringify({
  //     method: config.method,
  //     url: config.url,
  //     data: config.data,
  //     params: config.params,
  // });

  // // If there's an ongoing request with the same data, cancel it
  // if (requestMap.has(requestKey)) {
  //     const cancelToken = requestMap.get(requestKey);
  //     cancelToken.cancel("Request canceled due to new request");
  // }

  // // Create a new cancellation token for the current request
  // const source = CancelToken.source();
  // config.cancelToken = source.token;

  // // Store the cancellation token with the request key
  // requestMap.set(requestKey, source);

    const encryptedBytesVector = CryptoJS.enc.Utf8.parse(velocityX);
    const encryptionKeyBytes = CryptoJS.enc.Utf8.parse(xUseValue);
    const { token } = sessionStorage.getItem("userInfo")
      ? decryptData(sessionStorage.getItem("userInfo"))
      : {};
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (config.data) {
      const encryptedPayload = CryptoJS.AES.encrypt(
        JSON.stringify(config.data),
        encryptionKeyBytes,
        {
          iv: encryptedBytesVector,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
      config.data = encryptedPayload;
      
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    if (typeof response.data == "string") {
      const encryptedBytesVector = CryptoJS.enc.Utf8.parse(velocityX);
      const encryptionKeyBytes = CryptoJS.enc.Utf8.parse(xUseValue);
      const decryptedPayload = CryptoJS.AES.decrypt(
        response.data,
        encryptionKeyBytes,
        {
          iv: encryptedBytesVector,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      function convertKeysToCamelCase(obj) {
        if (obj === null || typeof obj !== "object") {
          return obj;
        }

        if (Array.isArray(obj)) {
          return obj.map((item) => convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
          const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
          acc[camelCaseKey] = convertKeysToCamelCase(obj[key]);
          return acc;
        }, {});
      }
      response.data = convertKeysToCamelCase(
        JSON?.parse(decryptedPayload.toString(CryptoJS.enc.Utf8))
      );
    } else if (typeof response.data.data == "string") {
      const encryptedBytesVector = CryptoJS.enc.Utf8.parse(velocityX);
      const encryptionKeyBytes = CryptoJS.enc.Utf8.parse(xUseValue);
      const decryptedPayload = CryptoJS.AES.decrypt(
        response.data.data,
        encryptionKeyBytes,
        {
          iv: encryptedBytesVector,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      function convertKeysToCamelCase(obj) {
        if (obj === null || typeof obj !== "object") {
          return obj;
        }

        if (Array.isArray(obj)) {
          return obj.map((item) => convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
          const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
          acc[camelCaseKey] = convertKeysToCamelCase(obj[key]);
          return acc;
        }, {});
      }
      let data = JSON.parse(decryptedPayload.toString(CryptoJS.enc.Utf8));
      response.data.data = convertKeysToCamelCase(data);
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;
