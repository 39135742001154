import { MoreOutlined } from '@ant-design/icons';
import InstallationAllSchemeTable from './InstallationDraftSchemeTable';
import './InstallationDraftScheme.css';
import { Collapse, Space, Pagination, Dropdown ,message, Button} from "antd";
import { useEffect, useState } from 'react';
import { Link,useNavigate } from "react-router-dom";
import { ReactComponent as Download } from "../../../assets/vector/download.svg";
import { ReactComponent as Copy } from "../../../assets/vector/copy.svg";
import { ReactComponent as Edit } from "../../../assets/vector/edit.svg";
import SingleTargetDraft from "../draftSchemes/draftTables/singleTargetDraft";
import SingleTargetWithUpperCapDraft from '../draftSchemes/draftTables/singleTargetWithUpperCap'
import UnconditionalInstallationDraft from "../draftSchemes/draftTables/unconditionalInstallationDraft";
import SlabBasedDraft from "../draftSchemes/draftTables/slabBasedDraft";
import InstallationFilter from '../../../components/installation/installationFilter';
import axiosAPI from '../../../services/axiosApi';
import { getCurrentFiscalYear } from '../../../utils/dateFormatter';



const { Panel } = Collapse;
const currentFiscalYear = getCurrentFiscalYear()

const DraftCollapseTab = ({ payload}) => {
  const getSelectedRowKeys = JSON.parse(sessionStorage.getItem('rowData')) 
  const navigate = useNavigate();
  const [selectedSchemeType, setSelectedSchemeType] = useState(3)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const items = [
    {
      label: (
        <Link onClick={(e) => handleEditClick(e)}>Edit</Link>
      ),
      key: "1",
    },
  ];
  
  const handleEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const record = "";
    const selectedRow = JSON.parse(sessionStorage.getItem('rowData'))
    const recordExist = selectedRow ;

if (recordExist!= null) {
  navigate("/installation/draftSchemes/edit", {state:selectedRow});
} else {
  messageApi.open({
    type: 'warning',
    content: 'Please select the checkbox !',
    duration: 2
  });
  // alert("please select checkbox");
}
  }

  const collapseProps = {
    items,
    trigger: ["click"],
  };
  

  const fetchGeneratedScheme = (demoParams) => {
    // 
    setSelectedSchemeType(demoParams.schemeType)
  };

  const defaultActiveKey = ["1", "2"];

  const genExtra = () => (
    <Dropdown menu={collapseProps}>
      <MoreOutlined />
    </Dropdown>

    //  <MoreOutlined
    //     onClick={(event) => {
    //       // If you don't want click extra trigger collapse, you can prevent this:
    //       event.stopPropagation();
    //     }}
    //   />
  );
 
  return (
    <>
    <Space
    className='instalttion-collapse-items collapse-box-cell-table d-flex'
     direction="vertical"
    size="large"
    >
        <Collapse className="all_scheme_draft"  defaultActiveKey={defaultActiveKey}>
            <Panel size = "large" header="Incentive Installation " key="1" extra={genExtra()}>
            <InstallationFilter onGetData={fetchGeneratedScheme}  />
              {/* </div> */}
                {selectedSchemeType == 3 && <SlabBasedDraft payload={payload} selectedSchemeType={selectedSchemeType} getSelectedRowKeys={getSelectedRowKeys}/>}
                {selectedSchemeType == 8 && <SingleTargetDraft payload={payload} selectedSchemeType={selectedSchemeType} getSelectedRowKeys={getSelectedRowKeys}/>}
                {selectedSchemeType == 4 && <SingleTargetWithUpperCapDraft payload={payload} selectedSchemeType={selectedSchemeType}  getSelectedRowKeys={getSelectedRowKeys}/>}
                {selectedSchemeType == 5 && <UnconditionalInstallationDraft  payload={payload} selectedSchemeType={selectedSchemeType} getSelectedRowKeys={getSelectedRowKeys}/>}

              {/* <InstallationAllSchemeTable
                getSelectedRowKeys={getSelectedRowKeys}
                fetchGeneratedScheme={fetchGeneratedScheme}
                /> */}
            </Panel>

            {/* <Panel header="Incentive Collection FY 2022 -November " key="2" extra={genExtra()}>
            <InstallationAllSchemeTable/>
            </Panel>

            <Panel header="Incentive Collection FY 2022 - October" key="3" extra={genExtra()}>
            <InstallationAllSchemeTable/>
            </Panel>

            <Panel header="Incentive Collection FY 2022 -September" key="4" extra={genExtra()}>
            <InstallationAllSchemeTable/>
            </Panel>

            <Panel header="Incentive Collection FY 2022 -August" key="5" extra={genExtra()}>
            <InstallationAllSchemeTable/>
            </Panel> */}
        </Collapse>
        {/* <Pagination defaultCurrent={1} total={30} className="draft-scheme-pagination" /> */}
    </Space>
    {contextHolder}
    </>
  );
};
export default DraftCollapseTab;
