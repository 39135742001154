import React, { useEffect, useState } from "react";
import OneMonthScheme from "./OneMonth";
import MultipleMonthScheme from "./MultipleMonth";
import StateWiseScheme from "./StateWise";
import StateReport from "./StateReport";
import "./style.css";
import Schemes from "./Schemes";
import { Select, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import FinancialYearButton from "../../../components/financialYearButton/FinancialYearButton";
import { Link, useNavigate } from "react-router-dom";
import ZonalHead from "../zonalHead";
// import StateHead from "../stateHead";
import axiosAPI from "../../../services/axiosApi";
import StateHead from "../stateHead";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import { getStateListApi } from "../../../redux/stateData/stateDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import dayjs from "dayjs";
import { getNotificationSadm } from "../../../redux/notification/notificationSlice";
import StateWiseMultiple from "./StateWiseMultiple";

const currentFiscalYear = getCurrentFiscalYear();

const PmisDashboard = () => {
  const { Option } = Select;

  const [stateHeadName, setStateHeadName] = useState();
  const [sHNameSelect, setSHNameSelect] = useState();
  const [sHStateSelect, setSHStateSelect] = useState({
    stateID: "",
    stateName: "",
  });
  const [dashboardType, setDashboardType] = useState();
  const dispatch = useDispatch();
  const [toggleDashboard, setToggleDashboard] = useState(false);
  const [open, setOpen] = useState(false);
  const [stateListArr, setStateListArr] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState(0);
  const { stateList, loadingState } = useSelector((state) => state.stateData);
  const {userRole,userId} = decryptData(sessionStorage.getItem("userInfo"));
  const [dateValue, setDateValue] = useState(dayjs());
  useEffect(() => {
    axiosAPI.post(`SchemeGenerate/GetStateHeadName`).then((result) => {
      const data = result.data.data;
      setStateHeadName(data);
    });
  }, []);

  const payload = {
    fyear: currentFiscalYear,
    userid: sHNameSelect ? +sHNameSelect : 0,

  };
  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      setStateListArr(stateList?.data);
    } else {
      setStateListArr([]);
    }
  }, [stateList]);
  useEffect(() => {
    axiosAPI
      .post(`SchemeGenerate/GetStatebyStateHeadId`, payload)
      .then((result) => {
        const data = result?.data?.data;
        setSHStateSelect({
          stateID: data ? data?.stateID : 0,
          stateName: data ? data?.stateName : "stateName",
        });
      });
  }, [sHNameSelect]);

  const handleSelectDropdown = (value, option) => {
    setDashboardType(value);
  };
  const handleStateSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));
  };
  const items = [
    {
      label: "State wise Dashboard",
      value: "0",
    },
    {
      label: "Dealer wise Dashboard",
      value: "1",
    },
  ];
  useEffect(() => {
    if (toggleDashboard) {
      dispatch(getStateListApi());
    }
  }, [toggleDashboard]);
  useEffect(()=>{
    dispatch(getStateListApi())
    },[])
  
  
    const getnotificationApi = async()=>{
      let lastTwoDigits = dateValue % 100;
      const demoParams = {
        fYear: "FY" + lastTwoDigits,
        userid: userId,
        userRole: userRole,
      };
  await dispatch(getNotificationSadm(demoParams));
    }
    useEffect(() => {
      getnotificationApi()
    }, [dateValue])

    

  return (
    <div className="pmis_wrapper">
      <div className="main-div-select amgr_year">
        <FinancialYearButton handleDate={(x)=>setDateValue(x)}/>
        <Select
          className="select_state stateWise"
          placeholder={dashboardType ? dashboardType : "State wise Dashboard"}
          onSelect={handleSelectDropdown}
          trigger={["click"]}
          open={open}

          onClick={() => {
            setOpen(!open);
          }}
          defaultValue={ !toggleDashboard?{ label: "State wise Dashboard",
            value: "0" }:{
              label: "Dealer wise Dashboard",
              value: "1",
            }}
          onChange={(event) =>
            event == "State wise Dashboard"
              ? setToggleDashboard(false)
              : setToggleDashboard(true)
          }
        >
          {items?.map((option) => (
            <Option key={option.value} value={option.label} >
              {option.label}
            </Option>
          ))}
        </Select>
      
        {((toggleDashboard) && (userRole == "PMIS" || userRole == "Finance"||userRole == "NSH"|| userRole ==  "COSM")) && (
          <Select
            showSearch
            className="select_state"
            placeholder="Select State"
            optionFilterProp="children"
            onSelect={handleStateSelect}
            defaultValue={0}
          >
            <Option key={0} value={0}>
              All States
            </Option>
            {stateListArr?.map((option) => (
              <Option key={option.stateID} value={option.stateName}>
                {option.stateName}
              </Option>
            ))}
          </Select>
        )}
      </div>
      
      {toggleDashboard ? (
        <div className="pt-4">
        <ZonalHead
          type="hideFYSelect"
          dashboardType={dashboardType}
          stateId={selectedStateId}
          dateShowValue={dateValue}
        />
        </div>
        
      ) : (
        <div className="px-2">
               
          <OneMonthScheme dateValue={dateValue}/>
          <MultipleMonthScheme dateValue={dateValue}/>
          
          {["PMIS"].includes(userRole) && <Schemes />}
          {/* {["PMIS", "COSM","Finance"].includes(userRole) && <StateWiseScheme dateValue={dateValue}/>} */}
          {["PMIS", "COSM","Finance"].includes(userRole) && <><StateWiseScheme dateValue={dateValue}/><StateWiseMultiple dateValue={dateValue}/></>}
          {/* StateWiseMultiple */}
        </div>
      )}
    </div>
  );
};

export default PmisDashboard;
