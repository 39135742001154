import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import {
  Button,
  Table,
  Divider,
  Col,
  Row,
  Form,
  Input,
  message,
  Modal,
  Space,
  Tooltip,
  Dropdown,
  Menu
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import axiosAPI from "../../../services/axiosApi";
import ToastifyShow from "../../../components/ToastifyShow";
import { ArrowBack, ArrowBackRounded } from "@mui/icons-material";
import XLSX from "xlsx-js-style";

import {
  getSaveModifiedDashboardApi,
  resetCollection,
} from "../../../redux/modification/modificationSlice";
import { Select } from "antd";
import SMDialog from "../../../components/modal/alertModal/CommonModal";
import ValidationErrorsModal from "../../../components/modal/alertModal/ValidationErrorsModal";
import {
  schemeGeneratePostApi,
  updateSchemeGenerateApiAsync,
} from "../../../redux/schemaGenerate/schemaGenerateSlice";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import dayjs from "dayjs";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import StatusModal from "../../../components/modal/alertModal/StatusModal";
import {
  getLockedSchemeStateApiAsync,
  getLockedSchemeTypeAsync,
} from "../../../redux/stateData/stateDataSlice";
import { encryptPayload } from "../../../services/encryption";
import ValidationDifferentSchemeError from "../../../components/modal/alertModal/ValidationDifferentSchemeError";
import { parseFiveDigitNumber } from "../../../components/download/collectionSchemeUpload";
import { getLockedZonalHeadApiAsync } from "../../../redux/stateData/stateDataSlice";
import { postUpdateHoldSchemeApi } from "../../../redux/hold/holdDataService";
import { postUpdateHoldScheme } from "../../../redux/hold/holdDataSlice";
import { isValidContentType, isValidFileHeader } from "../../../config";
import { ModificationInstallationDownload } from "../../collection/modificationApproval/ModificationInstallationDownload";
import moment from "moment";
const currentFiscalYear = getCurrentFiscalYear();

const DraftInstallationEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });
  const [dataHandle, setDataHandle] = useState([]);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [checkCalculate, setCheckCalculate] = useState(false);
  const [checkCalculateArr, setCheckCalculateArr] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);


  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );
  const [openErrorDialog, setOpenErrorDialog] = useState({
    open: false,
    errorsFields: [],
  });
  const { state, pathname } = useLocation();
  const schemeTypeId = Array?.isArray(state)
    ? state[0].scheme?.schemeTypeId
    : state?.schemeTypeId;
  let selectedSchemeType = schemeTypeId;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [schemeLockedStatus, setSchemeLockedStatus] = useState({});
  const { editDashboard, loadingState } = useSelector(
    (state) => state.modifyCollection
  );
  const editData = editDashboard?.data?.result;
  const formik = useFormik({
    initialValues: [],
  });

  useEffect(() => {
    if (Array?.isArray(state) && state?.length != 0) {
      let data1 = state?.map((x) => ({
        ...x?.scheme,
        dealerCode: x?.scheme?.dealerCode,
        dealerId: x?.scheme?.dealerId,
        dealerName: x?.scheme?.dealerName,
        dealerLocation: x?.scheme?.dealerLocation,
        stateName: x?.scheme?.stateName,
        teritoryManager: x?.scheme?.territoryManager || "",
        topTarget: x?.scheme?.topTarget,
        installationTarget: parseInt(x?.scheme?.installationTarget) || 0,
        incPerTarget: parseInt(x?.scheme?.incPerTarget) || 0,
        actual_Installation_Last_Year_Month:
          x?.scheme?.actual_Installation_Last_Year_Month,
        startMonth: x?.scheme?.startMonth,
        endMonth: x?.scheme?.endMonth,
        reason: x?.scheme?.reason,
        payout: x?.payout,
        t1: +x?.scheme?.t1,
        t2: +x?.scheme?.t2,
        t3: +x?.scheme?.t3,
        p1: x?.scheme?.p1,
        p2: x?.scheme?.p2,
        p3: x?.scheme?.p3,
        r1: +x?.scheme?.r1,
        r2: +x?.scheme?.r2,
        r3: +x?.scheme?.r3,
        volume: x?.scheme?.volume,
        perTr: Math.round(x?.scheme?.perTr),
        amount: x?.scheme?.amount,
        dropdownscheme: x?.scheme?.topTarget?.split(","),
        ...(userRole == "ZADM" && {
          topTargetArrState:
            userRole == "ZADM" && Array?.isArray(schemeLockedStatus)
              ? schemeLockedStatus?.filter(
                  (y) =>
                    y?.stateId == x?.scheme?.stateId || y?.stateId == x?.stateId
                ) || [
                  {
                    t1: true,
                    t2: true,
                    t3: false,
                    stateId: 0,
                  },
                ]
              : [
                  {
                    t1: true,
                    t2: true,
                    t3: false,
                    stateId: 0,
                  },
                ],
        }),
        ...(pathname == "installation/allSchemes/edit" && { status: 1 }),
      }));
      formik?.setValues(data1);
    } else if (Object?.keys(state)?.length != 0) {
      let data1 = [state]?.map((x) => ({
        ...x,
        dealerCode: x.dealerCode,
        dealerId: x.dealerId,
        dealerName: x.dealerName,
        dealerLocation: x.dealerLocation,
        stateName: x.stateName,
        teritoryManager: x.territoryManager || "",
        topTarget: x.topTarget,
        installationTarget: parseInt(x?.installationTarget) || 0,
        incPerTarget: parseInt(x?.incPerTarget) || 0,
        actual_Installation_Last_Year_Month:
          x?.actual_Installation_Last_Year_Month,
        startMonth: x.startMonth,
        endMonth: x.endMonth,
        reason: x.reason,
        payout: x?.payout,
        t1: +x.t1,
        t2: +x.t2,
        t3: +x.t3,
        p1: x.p1,
        p2: x.p2,
        p3: x.p3,
        r1: +x.r1,
        r2: +x.r2,
        r3: +x.r3,
        volume: x.volume,
        perTr: Math.round(x.perTr),
        amount: x.amount,
        dropdownscheme: x?.topTarget?.split(","),
        ...(userRole == "ZADM" && {
          topTargetArrState:
            userRole == "ZADM" && Array?.isArray(schemeLockedStatus)
              ? schemeLockedStatus?.filter((y) => y?.stateId == x?.stateId) || [
                  {
                    t1: true,
                    t2: true,
                    t3: false,
                    stateId: 0,
                  },
                ]
              : [
                  {
                    t1: true,
                    t2: true,
                    t3: false,
                    stateId: 0,
                  },
                ],
        }),
        ...(pathname == "installation/allSchemes/edit" && { status: 1 }),
      }));
      formik?.setValues(data1);
    } else {
      navigate("/installation/draftSchemes");
    }
  }, [state, schemeLockedStatus]);

  const handleErrorModal = (firstItem, secondItem, opt = false) => {
    setOpenErrorModal(opt ? opt : !openErrorModal);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };
  const showErrorDialog = (errFields) => {
    setOpenErrorDialog((openErrorDialog) => ({
      open: true,
      errorsFields: errFields ? errFields : [],
    }));
  };


  useEffect(() => {
    if (userRole != "ZADM") {
      dispatch(getLockedSchemeTypeAsync(""));
    }
  }, []);
  useEffect(() => {
    if (userRole == "ZADM") {
      dispatch(
        getLockedZonalHeadApiAsync({ fyear: "FY24", userid: userId })
      ).then((x) => {
        if (x?.payload?.data?.[0]) {
          setSchemeLockedStatus(x?.payload?.data);
        }
      });
    } else {
      dispatch(getLockedSchemeTypeAsync(userId)).then((x) => {
        if (x?.payload?.[0]) {
          setSchemeLockedStatus(
            x?.payload?.[0]
              ? {
                  t1: true,
                  t2: true,
                  t3: x?.payload?.[0]?.t3I ? true : false,
                }
              : {
                  t1: true,
                  t2: true,
                  t3: false,
                }
          );
        }
      });
    }
  }, []);

  const topTargetArr = [
    {
      name: "T1",
      value: "t1",
      disabled: !schemeLockedStatus?.t1 ? true : false,
    },
    {
      name: "T2",
      value: "t2",
      disabled: !schemeLockedStatus?.t2 ? true : false,
    },
    {
      name: "T3",
      value: "t3",
      disabled: !schemeLockedStatus?.t3 ? true : false,
    },
  ];

  const topTargetArr1 = (x) => [
    {
      name: "T1",
      value: "t1",
      disabled: false,
    },
    {
      name: "T2",
      value: "t2",
      disabled: false,
    },
    {
      name: "T3",
      value: "t3",
      disabled: x?.[0]?.t3I ? false : true,
    },
  ];
  const getDropdown = () =>
    schemeTypeId == 3 || userRole == "ZADM"
      ? [
          {
            title: "Top Slab",
            width: "150px",
            render: (x, y, index) => {
              const handleChange = (value) => {
                let dropdownValues = formik?.values?.[index]?.dropdownscheme;
                let checkValue1 = value?.includes("t1");
                let checkValue2 = value?.includes("t2");
                let checkValue3 = value?.includes("t3");
                const handleDropDownScheme = () => {
                  return checkValue3
                    ? ["t1", "t2", "t3"]
                    : checkValue2
                    ? ["t1", "t2"]
                    : checkValue1
                    ? ["t1"]
                    : [];
                };
                const handleDropdownSchemes = handleDropDownScheme();
                formik.setFieldValue(index, {
                  ...x,
                  dropdownscheme: handleDropDownScheme() || null,
                  ...(handleDropdownSchemes.includes("t3")
                  ? {
                  }:handleDropdownSchemes.includes("t2")
                    ? {
                        BooksCollectionTargetT3T2: null,
                        r3: null,
                        t3: null,
                      }
                    : handleDropdownSchemes.includes("t1")
                    ? {
                        r2: null,
                        r3: null,
                        t2: null,
                        t3: null,
                        BooksCollectionTargetT2T1: null,
                      }
                    : {}),
                  BooksCollectionTargetT3T2: null,
                });
              };

              let arr1 =
                userRole == "ZADM"
                  ? topTargetArr1(y?.topTargetArrState)
                  : topTargetArr?.filter((x, index) => !x?.disabled);
              return (
                <Select
                  key={index}
                  onChange={handleChange}
                  value={y?.dropdownscheme}
                  mode="multiple"
                  autoClearSearchValue="multiple"
                  className="w-100"
                  allowClear
                  onDeselect={false}
                  showArrow={false}
                  removeIcon={false}
                >
                  {" "}
                  {arr1?.map((x) => (
                    <Select.Option value={x?.value} disabled={x?.disabled}>
                      {x?.name}
                    </Select.Option>
                  ))}
                </Select>
              );
            },
          },
        ]
      : [];

  const getTargetT1 = () =>
    schemeLockedStatus?.t1 || userRole == "ZADM"
      ? [
          {
            title: "T1",
            dataIndex: "t1",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t1}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? ""
                    : "Type here"
                }
                value={formik?.values?.[index]?.t1 || ""}
                onBlur={() => calculateFormula(index)}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    t1: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? true
                    : false
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getTargetT2 = () =>
    schemeLockedStatus?.t2 || userRole == "ZADM"
      ? [
          {
            title: "T2",
            dataIndex: "t2",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t2}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? ""
                    : "Type here"
                }
                value={formik?.values?.[index]?.t2 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");

                  formik.setFieldValue(index, {
                    ...y,
                    t2: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? true
                    : false
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getTargetT3 = () =>
    schemeLockedStatus?.t3 || userRole == "ZADM"
      ? [
          {
            title: "T3",
            dataIndex: "t3",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t3}
                placeholder="Type here"
                value={formik?.values?.[index]?.t3 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    t3: value,
                  });
                }}
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? "input-disabled"
                    : "input1"
                }
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? true
                    : false
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getPayoutRateR1 = () =>
    schemeLockedStatus?.t1 || userRole == "ZADM"
      ? [
          {
            title: "R1",
            dataIndex: "r1",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r1}
                value={formik?.values?.[index]?.r1 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r1: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getPayoutRateR2 = () =>
    schemeLockedStatus?.t2 || userRole == "ZADM"
      ? [
          {
            title: "R2",
            dataIndex: "r2",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r2}
                value={formik?.values?.[index]?.r2 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r2: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getPayoutRateR3 = () =>
    schemeLockedStatus?.t3 || userRole == "ZADM"
      ? [
          {
            title: "R3",
            dataIndex: "r3",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r3}
                value={formik?.values?.[index]?.r3 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r3: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getPayoutP1 = () =>
    schemeLockedStatus?.t1 || userRole == "ZADM"
      ? [
          {
            title: "P1",
            dataIndex: "p1",
            width: "90px",
          },
        ]
      : [];
  const getPayoutP2 = () =>
    schemeLockedStatus?.t2 || userRole == "ZADM"
      ? [
          {
            title: "P2",
            dataIndex: "p2",
            width: "90px",
          },
        ]
      : [];
  const getPayoutP3 = () =>
    schemeLockedStatus?.t3 || userRole == "ZADM"
      ? [
          {
            title: "P3",
            dataIndex: "p3",
            width: "90px",
          },
        ]
      : [];

  const handleSaveModal = () => {
    setOpenSaveModal(!openSaveModal);
  };
  const handleSuccessModal = () => {
    setOpenSuccessModal(!openSuccessModal);
  };
  const handleSelectModal = () => {
    setOpenSelectModal(!openSelectModal);
  };
  const handleDraftModal = () => {
    setOpenDraftModal(!openDraftModal);
  };
  const handleSaveScheme = async () => {
    if (dataHandle?.[0]?.holdStatus == 9) {

      const result = dataHandle.map(
        ({ StartMonth, EndMonth, dropdownscheme, incPerTarget,upperCap,installationTarget,p1,payout, ...rest }) => ({
          ...rest,
          InsPerTarget: incPerTarget,
          UpperCap:upperCap,
          InsTarget:installationTarget,
          P1:p1||payout
          
        })
      );
// T1:t1,T2:t1,T3:t3,P1:p1,P2:p2,P3:p3,R1:r1,R2:r2,R3:r3,TopTarget: item?.dropdownscheme?.toString(),
      dispatch(postUpdateHoldScheme(result)).then((data) => {
        navigate(-1);
      });
    } else {

      const result = dataHandle.map(
        ({ StartMonth, EndMonth, dropdownscheme, ...rest }) => ({ ...rest })
      );
      await axiosAPI
        .post(`UpdateScheme/DraftToSchemeGenerate`, result)
        .then((result) => {
          navigate("/installation/allSchemes");
          handleSuccessModal();
          message.success({
            content: "Scheme Generated Successfully",
            className: "custom-message",
          });
        });
    }
  };

  const handleDraftScheme = async () => {
    const result = dataHandle.map(
      ({ StartMonth, EndMonth, dropdownscheme, ...rest }) => ({ ...rest })
    );

    dispatch(updateSchemeGenerateApiAsync(dataHandle)).then((x) => {
      if (x.payload.errorMessage == "Scheme updated successfully!!") {
        message.success({
          content: "Scheme Draft Successfully",
          className: "custom-message",
        });

        navigate("/installation/draftSchemes");
      } else {
        message.error({
          content: "Something Went Wrong",
          className: "custom-mesSMDialogsage",
        });
      }
    });
  };
  const draftData = (e) => {
    e.preventDefault();

    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;

    const handleDropDownSchemeCheckIndex = (x) => {
      let { t1, t2, t3 } = x.reduce((a, v) => ({ ...a, [v]: v }), {});

      let data =
        t1 && !t2 && !t3
          ? "t1"
          : t1 && t2 && !t3
          ? "t2"
          : t1 && t2 && t3
          ? "t3"
          : "";
      return data;
    };

    let checkValidation =
      selectedSchemeType == 3
        ? formik?.values?.filter((x) =>
            handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
              ? !x.t1 || !x.r1
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
              ? !x.t1 ||
                !x.t2 ||
                !x.r1 ||
                !x.r2 ||
                parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||
                !x.TEPvol
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
              ? !x.t1 ||
                !x.t2 ||
                !x.t3 ||
                !x.r1 ||
                !x.r2 ||
                !x.r3 ||
                parseInt(x?.t3 || 0) <= parseInt(x?.t2 || 0) ||
                parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                parseInt(x?.r3 || 0) <= parseInt(x?.r2 || 0) ||
                parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||
                !x.TEPvol
              : !x.t1 || !x.t2 || !x.t3 || !x.r1 || !x.r2 || !x.r3
          )
        : selectedSchemeType == 4
        ? formik?.values?.filter(
            (x) => !x?.installationTarget || !x.upperCap || !x.incPerTarget
          )
        : formik?.values?.filter(
            (x) => !x?.installationTarget || !x.incPerTarget
          );

    let errorDetails =
      selectedSchemeType == 3
        ? Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter((x) =>
              checkValidation?.[0]?.dropdownscheme?.includes("t3")
                ? !x[1] &&
                  (x[0] == "t1" ||
                    x[0] == "t2" ||
                    x[0] == "t3" ||
                    x[0] == "r1" ||
                    x[0] == "r2" ||
                    x[0] == "r3")
                : checkValidation?.[0]?.dropdownscheme?.includes("t2")
                ? !x[1] &&
                  (x[0] == "t1" || x[0] == "t2" || x[0] == "r1" || x[0] == "r2")
                : checkValidation?.[0]?.dropdownscheme?.includes("t1")
                ? !x[1] && (x[0] == "t1" || x[0] == "r1")
                : !x[0]
            )
            .map((x) =>
              x[0] == "t1"
                ? "T1"
                : x[0] == "t2"
                ? "T2"
                : x[0] == "t3"
                ? "T3"
                : x[0] == "r1"
                ? "R1"
                : x[0] == "r2"
                ? "R2"
                : x[0] == "r3"
                ? "R3"
                : x[0]
            )
        : selectedSchemeType == 4
        ? Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter(
              (x) =>
                (!x[1] && x[0] == "installationTarget" && x[1] == 0) ||
                (x[0] == "incPerTarget" && x[1] == 0) ||
                (x[0] == "upperCap" && x[1] == 0)
            )
            .map((x) =>
              x[0] == "installationTarget"
                ? "Installation Target"
                : x[0] == "upperCap"
                ? "Upper Cap"
                : x[0] == "incPerTarget" && "INC. PER TRACTOR"
            )
        : Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter(
              (x) =>
                (!x[1] && x[0] == "installationTarget" && x[1] == 0) ||
                (x[0] == "incPerTarget" && x[1] == 0)
            )
            .map((x) =>
              x[0] == "installationTarget"
                ? "Installation Expected"
                : x[0] == "incPerTarget" && "INC. PER TRACTOR"
            );

    let data = [];
    formik?.values.forEach((item, index) => {
      data.push({
        ...formik?.values[index],
        dealerCode: item.dealerCode,
        dealerId: +item.dealerId,
        StartMonth: item?.StartMonth
          ? dayjs(item?.StartMonth)
              .startOf("month")
              .format("YYYY-MM-DD HH:mm:ss")
          : item?.StartMonth,
        EndMonth: item?.EndMonth
          ? dayjs(item?.EndMonth).endOf("month").format("YYYY-MM-DD HH:mm:ss")
          : item?.EndMonth,
        isSAP: item?.isSAP,
        isActive: item?.isActive,
        state: item.stateId,
        status: 0,
        actual_Installation_Last_Year_Month:
          item?.actual_Installation_Last_Year_Month,
        fyear: item?.fyear,
        schemeType: item?.schemeType,
        schemeTypeId: item?.schemeTypeId,
        userid: +userId,
        username: userName,
        t1: +item.t1 || 0,
        t2: +item.t2 || 0,
        t3: +item.t3 || 0,
        r1: +item.r1 || 0,
        r2: +item.r2 || 0,
        r3: +item.r3 || 0,
        p1: +item.p1 || 0,
        p2: +item.p2 || 0,
        p3: +item.p3 || 0,

        installationTarget: item?.installationTarget,
        incPerTarget: item?.incPerTarget,
        upperCap: item?.upperCap,
        reason: item.reason,
        topTarget: item?.dropdownscheme?.toString(),
      });
    });
    let extraValidation = {};


    if (selectedSchemeType == 3) {
      formik?.values?.map((x, index) => {
        let topTargetDetails = x?.dropdownscheme;
        let errors = [];
        let t3Details = x?.t3 != "" ? x?.t3 || 0 : 0;
        let t2Details = x?.t2 != "" ? x?.t2 || 0 : 0;
        let t1Details = x?.t1 != "" ? x?.t1 || 0 : 0;

        let r3Details = x?.r3 != "" ? x?.r3 || 0 : 0;
        let r2Details = x?.r2 != "" ? x?.r2 || 0 : 0;
        let r1Details = x?.r1 != "" ? x?.r1 || 0 : 0;

        if (
          topTargetDetails?.includes("t3") &&
          parseInt(t3Details) < parseInt(t2Details)
        ) {
          errors.push("T3 should be greater than T2");
        }
        if (
          topTargetDetails?.includes("t2") &&
          parseInt(t2Details) < parseInt(t1Details)
        ) {
          errors.push("T2 should be greater than T1");
        }
        if (
          topTargetDetails?.includes("t3") &&
          parseInt(r3Details) < parseInt(r2Details)
        ) {
          errors.push("R3 should be greater than R2");
        }
        if (
          topTargetDetails?.includes("t2") &&
          parseInt(r2Details) < parseInt(r1Details)
        ) {
          errors.push("R2 should be greater than R1");
        }
        if (errors.length > 0) {
          extraValidation[x?.dealerCode] = errors;
        }
      });
    }

    if (Object?.keys?.(extraValidation)?.length != 0) {
      showErrorDialog(extraValidation);
    } else {
      setDataHandle(data);
      handleDraftModal();
    }
  };
  const closeErrorDialog = () => {
    setOpenErrorDialog({ open: false, errorsFields: [] });
  };

  const submitData = (e) => {
    e.preventDefault();

    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const userRole = userData.userRole;
    const finalData = [];
    const handleDropDownSchemeCheckIndex = (x) => {
      let { t1, t2, t3 } = x.reduce((a, v) => ({ ...a, [v]: v }), {});

      let data =
        t1 && !t2 && !t3
          ? "t1"
          : t1 && t2 && !t3
          ? "t2"
          : t1 && t2 && t3
          ? "t3"
          : "";
      return data;
    };

    let checkValidation =
      selectedSchemeType == 3
        ? formik?.values?.filter((x) =>
            handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
              ? !x.t1 || !x.r1
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
              ? !x.t1 ||
                !x.t2 ||
                !x.r1 ||
                !x.r2 ||
                parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||
                !x.TEPvol
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
              ? !x.t1 ||
                !x.t2 ||
                !x.t3 ||
                !x.r1 ||
                !x.r2 ||
                !x.r3 ||
                parseInt(x?.t3 || 0) <= parseInt(x?.t2 || 0) ||
                parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                parseInt(x?.r3 || 0) <= parseInt(x?.r2 || 0) ||
                parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||
                !x.TEPvol
              : !x.t1 || !x.t2 || !x.t3 || !x.r1 || !x.r2 || !x.r3
          )
        : selectedSchemeType == 4
        ? formik?.values?.filter(
            (x) => !x?.installationTarget || !x.upperCap || !x.incPerTarget
          )
        : formik?.values?.filter(
            (x) => !x?.installationTarget || !x.incPerTarget
          );

    let errorDetails =
      selectedSchemeType == 3
        ? Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter((x) =>
              checkValidation?.[0]?.dropdownscheme?.includes("t3")
                ? !x[1] &&
                  (x[0] == "t1" ||
                    x[0] == "t2" ||
                    x[0] == "t3" ||
                    x[0] == "r1" ||
                    x[0] == "r2" ||
                    x[0] == "r3")
                : checkValidation?.[0]?.dropdownscheme?.includes("t2")
                ? !x[1] &&
                  (x[0] == "t1" || x[0] == "t2" || x[0] == "r1" || x[0] == "r2")
                : checkValidation?.[0]?.dropdownscheme?.includes("t1")
                ? !x[1] && (x[0] == "t1" || x[0] == "r1")
                : !x[0]
            )
            .map((x) =>
              x[0] == "t1"
                ? "T1"
                : x[0] == "t2"
                ? "T2"
                : x[0] == "t3"
                ? "T3"
                : x[0] == "r1"
                ? "R1"
                : x[0] == "r2"
                ? "R2"
                : x[0] == "r3"
                ? "R3"
                : x[0]
            )
        : selectedSchemeType == 4
        ? Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter(
              (x) =>
                (!x[1] && x[0] == "installationTarget" && x[1] == 0) ||
                (x[0] == "incPerTarget" && x[1] == 0) ||
                (x[0] == "upperCap" && x[1] == 0)
            )
            .map((x) =>
              x[0] == "installationTarget"
                ? "Installation Target"
                : x[0] == "upperCap"
                ? "Upper Cap"
                : x[0] == "incPerTarget" && "INC. PER TRACTOR"
            )
        : Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter(
              (x) =>
                (!x[1] && x[0] == "installationTarget" && x[1] == 0) ||
                (x[0] == "incPerTarget" && x[1] == 0)
            )
            .map((x) =>
              x[0] == "installationTarget"
                ? "Installation Expected"
                : x[0] == "incPerTarget" && "INC. PER TRACTOR"
            );

    let data = [];
    formik?.values.forEach((item, index) => {
      data.push({
        ...formik?.values[index],
        // ...(formik?.values?.[0]?.holdStatus==9&&{holdReason:item?.holdReason||""}),
        dealerCode: item.dealerCode,
        dealerId: +item.dealerId,
        StartMonth: item?.StartMonth
          ? dayjs(item?.StartMonth)
              .startOf("month")
              .format("YYYY-MM-DD HH:mm:ss")
          : item?.StartMonth,
        EndMonth: item?.EndMonth
          ? dayjs(item?.EndMonth).endOf("month").format("YYYY-MM-DD HH:mm:ss")
          : item?.EndMonth,
        isSAP: item?.isSAP,
        isActive: item?.isActive,
        state: item.stateId,
        status: 1,
        isSubmitted: 1,
        actual_Installation_Last_Year_Month:
          item?.actual_Installation_Last_Year_Month,
        fyear: item?.fyear,
        schemeType: item?.schemeType,
        schemeTypeId: item?.schemeTypeId,
        userid: +userId,
        userRole: userRole,
        username: userName,
        t1: +item.t1 || 0,
        t2: +item.t2 || 0,
        t3: +item.t3 || 0,
        r1: +item.r1 || 0,
        r2: +item.r2 || 0,
        r3: +item.r3 || 0,
        p1: +item.p1 || 0,
        p2: +item.p2 || 0,
        p3: +item.p3 || 0,
        installationTarget: item?.installationTarget,
        incPerTarget: item?.incPerTarget,
        upperCap: item?.upperCap,
        reason: item.reason,
        topTarget: item?.dropdownscheme?.toString(),
        HoldReason:item.reason
      });
    });
    if (formik?.values?.[0]?.holdStatus==9){
      data = data.map(d => {
        delete d.reason;
        return d;
      });
    }else {
      data = data.map(d => {
        delete d.HoldReason;
        return d;
      });
    }
   
    let extraValidation = [];
    //postUpdateHoldScheme

    if (selectedSchemeType == 3) {
      formik?.values?.map((x, index) => {
        // if (checkValidation?.[0]?.code) {
        //   formik?.values?.filter((x) => x?.code == checkValidation?.[0]?.code).map((x, index) => {
        let topTargetDetails = x?.dropdownscheme;
        let errors = [];
        if (topTargetDetails?.length == 0) {
          errors.push("Please select Top Target");
        }

        if (
          topTargetDetails?.includes("t3") &&
          parseInt(x?.t3) <= parseInt(x?.t2)
        ) {
          errors.push("T3 should be greater than T2");
        }
        if (
          topTargetDetails?.includes("t2") &&
          parseInt(x?.t2) <= parseInt(x?.t1)
        ) {
          errors.push("T2 should be greater than T1");
        }
        if (
          topTargetDetails?.includes("t3") &&
          parseInt(x?.r3) <= parseInt(x?.r2)
        ) {
          errors.push("R3 should be greater than R2");
        }
        if (
          topTargetDetails?.includes("t2") &&
          parseInt(x?.r2) <= parseInt(x?.r1)
        ) {
          errors.push("R2 should be greater than R1");
        }
        if (!x.r1) {
          errors.push("Please enter R1");
        }
        if (!x.r2 && topTargetDetails?.includes("t2")) {
          errors.push("Please enter R2");
        }
        if (!x.r3 && topTargetDetails?.includes("t3")) {
          errors.push("Please enter R3");
        }
        if (!x.t1) {
          errors.push("Please enter T1");
        }
        if (!x.t2 && topTargetDetails?.includes("t2")) {
          errors.push("Please enter T2");
        }
        if (!x.t3 && topTargetDetails?.includes("t3")) {
          errors.push("Please enter T3");
        }

        if (errors.length > 0) {
          extraValidation[x?.code || x?.dealerCode] = errors;
        }
      });

      if (Object?.keys?.(extraValidation)?.length != 0) {
        showErrorDialog(extraValidation);
      } else {
        handleSuccessModal();
        setDataHandle(data);
      }

    }else if(selectedSchemeType == 8){

         formik?.values?.map((x, index) => {

        let errors = [];

        if (
          (!parseInt(x?.incPerTarget)>0)||!x?.incPerTarget
        ) {
          errors.push("Installation per target is required");
        }
        if (

          (!parseInt(x?.installationTarget)>0)||!x?.installationTarget
        ) {
          errors.push("Installation target is required");
        }
        if (errors.length > 0) {
          extraValidation[x?.code || x?.dealerCode] = errors;
        }
      })


      if (Object?.keys?.(extraValidation)?.length != 0) {
        showErrorDialog(extraValidation);
      } else {
        handleSuccessModal();
        setDataHandle(data);
      }

    }else if(selectedSchemeType == 4){

      formik?.values?.map((x, index) => {

        let errors = [];
    
        if (
          (!parseInt(x?.incPerTarget)>0)||!x?.incPerTarget
        ) {
          errors.push("Installation per target is required");
        }


        if (
          (!parseInt(x?.upperCap)>0)||!x?.upperCap
        ) {
          errors.push("Installation per target is required");
        }
        if (

          (!parseInt(x?.installationTarget)>0)||!x?.installationTarget
        ) {
          errors.push("Installation target is required");
        }
        if (errors.length > 0) {
          extraValidation[x?.code || x?.dealerCode] = errors;
        }
      })
       
      if (Object?.keys?.(extraValidation)?.length != 0) {
        showErrorDialog(extraValidation);
      } else {
        handleSuccessModal();
        setDataHandle(data);
      }


    }else if(selectedSchemeType == 5){

      formik?.values?.map((x, index) => {

        let errors = [];
    
        if (
          (!parseInt(x?.incPerTarget)>0)||!x?.incPerTarget
        ) {
          errors.push("Installation per target is required");
        }


        if (

          (!parseInt(x?.installationTarget)>0)||!x?.installationTarget
        ) {
          errors.push("Installation target is required");
        }
        if (errors.length > 0) {
          extraValidation[x?.code || x?.dealerCode] = errors;
        }
      })
       
      if (Object?.keys?.(extraValidation)?.length != 0) {
        showErrorDialog(extraValidation);
      } else {
        handleSuccessModal();
        setDataHandle(data);
      }

      
    }
  };

  const calculateFormula = (index) => {
    let y = formik?.values[index];

    if (selectedSchemeType == 3) {
      let { t2, t1, t3, r1, r2, r3 } = y;

      let dropdownShow = y?.dropdownscheme;

      formik?.setFieldValue(index, {
        ...y,
        p1: parseInt(t1) * parseInt(r1) || 0,
        p2: parseInt(t2) * parseInt(r2) || 0,
        p3: parseInt(t3) * parseInt(r3) || 0,
      });
      if (
        (t2 == "" ||
          t2 == null ||
          t1 == "" ||
          t1 == null ||
          parseInt(t2) < parseInt(t1)) &&
        dropdownShow?.includes("t2")
      ) {
        handleErrorModal("T2", "T1", true);
      }
      if (
        (t3 == "" ||
          t3 == null ||
          t2 == "" ||
          t2 == null ||
          parseInt(t3) < parseInt(t2)) &&
        dropdownShow?.includes("t3")
      ) {
        handleErrorModal("T3", "T2", true);
      }
      if (
        (r2 == "" ||
          r2 == null ||
          r1 == "" ||
          r1 == null ||
          parseInt(r2) < parseInt(r1)) &&
        dropdownShow?.includes("t2")
      ) {
        handleErrorModal("R2", "R1", true);
      }
      if (
        (r3 == "" ||
          r3 == null ||
          r2 == "" ||
          r2 == null ||
          parseInt(r3) < parseInt(r2)) &&
        dropdownShow?.includes("t3")
      ) {
        handleErrorModal("R3", "R2", true);
      }
    } else if (selectedSchemeType == 4) {
      let { upperCap, incPerTarget, installationTarget } =
        formik?.values[index];
      if (
        upperCap == "" ||
        upperCap == null ||
        incPerTarget == "" ||
        incPerTarget == null ||
        parseInt(incPerTarget) <= parseInt(upperCap)
      ) {
        handleErrorModal("Inc Per Target", "Upper Cap", true);
      }
      formik?.setFieldValue(index, {
        ...y,
        installationTarget: parseInt(installationTarget) || 0,
        incPerTarget: parseInt(incPerTarget) || 0,
        upperCap: parseInt(upperCap) || 0,

        p1: parseInt(upperCap) * parseInt(incPerTarget) || 0,
      });
    } else if (selectedSchemeType == 5) {
      let { installationTarget, incPerTarget } = formik?.values[index];

      if (
        installationTarget == null &&
        incPerTarget == null &&
        parseInt(incPerTarget) <= parseInt(installationTarget)
      ) {
        handleErrorModal("incPerTarget", "installationTarget", true);
      }

      formik?.setFieldValue(index, {
        ...y,
        installationTarget: parseInt(installationTarget) || 0,
        incPerTarget: parseInt(incPerTarget) || 0,
        p1: parseInt(installationTarget) * parseInt(incPerTarget) || 0,
      });
    } else {
      let { installationTarget, incPerTarget } = formik?.values[index];
      if (
        installationTarget == null &&
        incPerTarget == null &&
        parseInt(incPerTarget) <= parseInt(installationTarget)
      ) {
        handleErrorModal("incPerTarget", "installationTarget", true);
      }

      formik?.setFieldValue(index, {
        ...y,
        installationTarget: parseInt(installationTarget) || 0,
        incPerTarget: parseInt(incPerTarget) || 0,
        p1: parseInt(installationTarget) * parseInt(incPerTarget) || 0,
      });
    }
  };

  const getStateHead = () => {
    if (userRole != "SADM") {
      return [
        {
          title: <div>State Head</div>,
          dataIndex: "stateHeadName",
          width: "180px",
        },
      ];
    } else {
      return [];
    }
  };
  const getCols = (schemeType) => {
    if (schemeType == 3) {
      return [
        {
          title: "Targets",
          dataIndex: "Target",
          width: "180px",
          children: [...getTargetT1(), ...getTargetT2(), ...getTargetT3()],
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "IPT",
          width: "180px",
          children: [
            ...getPayoutRateR1(),
            ...getPayoutRateR2(),
            ...getPayoutRateR3(),
          ],
        },
        {
          title: "Payout",
          dataIndex: "Payout",
          width: "180px",
          children: [...getPayoutP1(), ...getPayoutP2(), ...getPayoutP3()],
        },
      ];
    }
    if (schemeType == 4) {
      return [
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.installationTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.installationTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  installationTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "190px",
        },
        {
          title: "Upper Cap",
          dataIndex: "upperCap",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.upperCap}
              placeholder="Type here"
              value={formik?.values?.[index]?.upperCap}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  upperCap: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "120px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.incPerTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.incPerTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  incPerTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "190px",
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
        },
      ];
    }
    if (schemeType == 8) {
      return [
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.installationTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.installationTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  installationTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "190px",
        },

        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.incPerTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.incPerTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  incPerTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "190px",
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
        },
      ];
    }
    if (schemeType == 5) {
      return [
        {
          title: "Installation Expected",
          dataIndex: "installationTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.installationTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.installationTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  installationTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),

          width: "200px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.incPerTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.incPerTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  incPerTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "190px",
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
        },
      ];
    }
    return [];
  };
  const defaultColumns = useMemo(
    () => [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        fixed: "left",
        width: "120px",
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        fixed: "left",
        width: "180px",
      },
      {
        title: "Dealer Location",
        dataIndex: "dealerLocation",
        width: "120px",
        fixed: "left",
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: "120px",
      },
      //  ...getStateHead(),
      {
        title: "Territory Manager",
        dataIndex: "teritoryManager",
        width: "100px",
      },
      ...getDropdown(),
      {
        title: "Start Month",
        dataIndex: "startMonth",
        width: "120px",
        render: (x) => dayjs(x).format("MMM'YY"),
      },
      {
        title: "End Month",
        dataIndex: "endMonth",
        width: "120px",
        render: (x) => dayjs(x).format("MMM'YY"),
      },
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        width: "250px",
      },
      ...getCols(selectedSchemeType),

      {
        title: "Remarks",
        dataIndex: "reason",
        render: (x, y, index) => (
          <input
            name={formik?.values?.[index]?.reason}
            placeholder="Type here"
            value={formik?.values?.[index]?.reason}
            onBlur={() => {
              calculateFormula(index);
            }}
            onChange={(e) => {
              let value = e.target?.value;
              const sanitizedValue = value
                ?.replace("<script>", "")
                ?.replace("</script>", "")
              //  .replace(/[^a-zA-Z0-9\s]/g, "");
              formik.setFieldValue(index, {
                ...y,
                reason: sanitizedValue,
              });
            }}
            className="input1"
            style={{ width: "90%", padding: "5px" }}
          />
        ),
        width: "150px",
      },
    ],
    [formik?.values, schemeLockedStatus]
  );

  const handleRemoveFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };
  
  const handleSampleFile = (e) => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };
    if (formik?.values?.length == 0) {
      ToastifyShow("Something went wrong", "error");
      return false;
    }

    if (selectedSchemeType == 5) {
      let tab_text = `<table class="custom-table">
        <thead>
        <tr>
            <th >SCHEME NO</th>
            <th >DEALER CODE</th>
            <th >DEALER NAME</th>
            <th >STATE</th>
            <th >DEALER LOCATION</th>
            <th >TERRITORY MANAGER</th>
            <th >START MONTH</th>
            <th >END MONTH</th>
            <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
            <th >INSTALLATION EXPECTED</th>
            <th >INC. PER TRACTOR</th>
            <th >PAYOUT</th>
            <th >REMARKS</th>
            </tr>
        </thead>`;

      formik?.values?.forEach((x) => {
        tab_text += `<tr >

        <td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
        <td>${x?.installationTarget}</td>
        <td>${x?.perTr}</td>
        <td>${x?.payout}</td>
        <td>${x?.reason}</td>
      </tr>`;
      });

      let totals = {
        installationTarget: 0,
        incPerTarget: 0,
        p1: 0,
        reason: ""

      };


      formik?.values.forEach((item) => {
        for (let key in totals) {
          totals[key] += parseInt(item[key]) || 0;
        }
      });
      totals.incPerTarget = parseInt(totals.p1 / totals.installationTarget);

      let totalsRow = "<tr><td colspan='9' >Total</td>";
      for (let key in totals) {
        if (key != 'reason') {
          if (key.startsWith('incPer')) {
            totalsRow += `<td>${totals[key]}</td>`;
          } else {
            totalsRow += `<td>${totals[key]}</td>`;
          }
        } else {
          totalsRow += `<td></td>`;
        }
      }

      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      const allColumns = formik?.values.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);




      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(formik?.values.length + 2).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

      // generate file
      XLSX.writeFile(
        wb,
        `Unconditional_Installation_${new Date().toJSON().slice(0, 10)}.xlsx`
      );
    } else if (selectedSchemeType == 4) {
      let tab_text = `<table>
      <thead>
      <tr>
          <th >SCHEME NO</th>
          <th >DEALER CODE</th>
          <th >DEALER NAME</th>
          <th >STATE</th>
          <th >DEALER LOCATION</th>
          <th >TERRITORY MANAGER</th>
          <th >START MONTH</th>
          <th >END MONTH</th>
          <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th >INSTALLATION TARGET</th>
          <th>UPPER CAP</th>
          <th >INC. PER TRACTOR</th>
          <th >PAYOUT</th>
          <th >REMARKS</th>
          </tr>
      </thead>`;

      formik?.values?.forEach((x) => {
        tab_text += `<tr >
        <td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
        <td>${x?.installationTarget}</td>
        <td>${x?.upperCap}</td>
        <td>${x?.perTr}</td>
        <td>${x?.payout}</td>
        <td>${x?.reason}</td>
    </tr>`;
      });

      let totals = {
        installationTarget: 0,
        upperCap: 0,
        incPerTarget: 0,
        p1: 0,
        reason: ""

      };

      formik?.values.forEach((item) => {
        for (let key in totals) {
          totals[key] += parseInt(item[key]) || 0;
        }
      });
      totals.incPerTarget = parseInt(totals.p1 / totals.installationTarget);

      let totalsRow = "<tr><td colspan='9' >Total</td>";
      for (let key in totals) {
        if (key != 'reason') {
          if (key.startsWith('incPer')) {
            totalsRow += `<td>${totals[key]}</td>`;
          } else {
            totalsRow += `<td>${totals[key]}</td>`;
          }
        } else {
          totalsRow += `<td></td>`;
        }
      }

      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      const allColumns = formik?.values.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);




      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(formik?.values.length + 2).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });


      // generate file
      XLSX.writeFile(
        wb,
        `Installation_Single_Target_With_Upper_Cap${new Date()
          .toJSON()
          .slice(0, 10)}.xlsx`
      );
    } else if (selectedSchemeType == 8) {
      let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        </tr>
    </thead>`;

      formik?.values?.forEach((x) => {
        tab_text += `<tr >
        <td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
        <td>${x?.installationTarget}</td>
        <td>${x?.perTr}</td>
        <td>${x?.payout}</td>
        <td>${x?.reason}</td>
  </tr>`;
      });

      let totals = {
        installationTarget: 0,
        incPerTarget: 0,
        p1: 0,
        reason: ""

      };

      formik?.values.forEach((item) => {
        for (let key in totals) {
          totals[key] += parseInt(item[key]) || 0;
        }
      });
      totals.incPerTarget = parseInt(totals.p1 / totals.installationTarget);

      let totalsRow = "<tr><td colspan='9' >Total</td>";
      for (let key in totals) {
        if (key != 'reason') {
          if (key.startsWith('incPer')) {
            totalsRow += `<td>${totals[key]}</td>`;
          } else {
            totalsRow += `<td>${totals[key]}</td>`;
          }
        } else {
          totalsRow += `<td></td>`;
        }
      }


      totalsRow += "</tr>";
      tab_text += totalsRow;

      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      const allColumns = formik?.values.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);




      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(formik?.values.length + 2).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });


      // generate file
      XLSX.writeFile(
        wb,
        `Download_Installation_Single_Target_${new Date()
          .toJSON()
          .slice(0, 10)}.xlsx`
      );
    } else if (selectedSchemeType == 3) {
      let tab_text = `<table>
      <thead>
      <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">TOP SLAB</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th colspan="3">TARGETS</th>
          <th colspan="3">INC. PER TRACTOR</th>
          <th colspan="3">PAYOUT</th>
          <th rowspan="2">REMARKS</th>
          </tr>
      <tr>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>P1</th>
         <th>P2</th>
         <th>P3</th>
        </tr>
      </thead>`;

      formik?.values?.forEach((header) => {
        tab_text += `<tr >
  <td>${header?.schemeNo}</td>
  <td>${header?.dealerCode}</td>
  <td>${header?.dealerName}</td>
  <td>${header?.stateName}</td>
  <td>${header?.dealerLocation}</td>
  <td>${header?.territoryManager}</td>
  <td>${header?.topTarget}</td>
  <td>${moment(header?.startMonth).format("MMM-YY") || ""}</td>
  <td>${moment(header?.endMonth).format("MMM-YY") || ""}</td>
  <td>${header?.actual_Installation_Last_Year_Month}</td>
  <td>${header?.t1}</td>
  <td>${header?.t2}</td>
  <td>${header?.t3}</td>
  <td>${header?.r1}</td>
  <td>${header?.r2}</td>
  <td>${header?.r3}</td>
  <td>${header?.p1}</td>
  <td>${header?.p2}</td>
  <td>${header?.p3}</td>
  <td>${header?.reason}</td>
  </tr>`;
      });
      let totals = {
        t1: 0,
        t2: 0,
        t3: 0,
        r1: 0,
        r2: 0,
        r3: 0,
        p1: 0,
        p2: 0,
        p3: 0,
        reason: ""

      };


      formik?.values.forEach((item) => {
        for (let key in totals) {
          totals[key] += parseInt(item[key]) || 0;
        }
      });

      totals.r1 = parseInt(totals.p1 / totals.t1);
      totals.r2 = parseInt(totals.p2 / totals.t2);
      totals.r3 = parseInt(totals.p3 / totals.t3);
      let totalsRow = "<tr><td colspan='10' >Total</td>";
      for (let key in totals) {
        if (key != 'reason') {
          if (key.startsWith('r')) {
            totalsRow += `<td>${totals[key]}</td>`;
          } else {
            totalsRow += `<td>${totals[key]}</td>`;
          }
        } else {
          totalsRow += `<td></td>`;
        }
      }

      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      // Add style to Roral row
      const allColumns = formik?.values.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }

      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(formik?.values.length + 3).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });


      // generate file
      XLSX.writeFile(
        wb,
        `Slab_based_scheme_${new Date().toJSON().slice(0, 10)}.xlsx`
      );
    }
  };
const handleFileUpload=(e)=>{
  const maxFileSize = 5 * 1024 * 1024; // Set maximum file size to 5MB
  const files = e.target.files;

  // Check if any file exceeds the maximum file size limit
  for (let i = 0; i < files.length; i++) {
    if (files[i].size > maxFileSize) {
      ToastifyShow(`File ${files[i].name} is too large. Maximum file size is 5MB.`, "error");
      return;
    }

    // Check file headers for XLSX files
    if (!isValidFileHeader(files[i])) {
      ToastifyShow(`Invalid file header for ${files[i].name}.`, "error");
      return;
    }

    // Check content type for XLSX files
    if (!isValidContentType(files[i])) {
      ToastifyShow(`Invalid content type for ${files[i].name}.`, "error");
      return;
    }

  }

  if (files[0].name.split('.')?.[1] !== 'xlsx') {
    ToastifyShow("Please upload an XLSX file only.", "error");
    return;
  }

  const reader = new FileReader();
  reader.readAsBinaryString(files[0]);

  setSelectedFiles((prevFiles) => [...prevFiles, ...files]);

  reader.onload = async(e) => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];  
    const parsedData = XLSX.utils.sheet_to_json(sheet);
    let schemeType=formik?.values?.[0]?.schemeTypeId||3
    let calArr = [];
    let dataArr = formik?.values?.map((y, index) => {
      if (
        parsedData.find(
          (x) =>
            x[schemeType == "3" || schemeType == "" ? "-" : "DEALER CODE"] ===
            y?.dealerCode
        )
      ) {
        let z = parsedData.find(
          (x) =>
            x[schemeType == "3" || schemeType == "" ? "-" : "DEALER CODE"] ===
            y?.dealerCode
        );

        if (schemeType == "3" || schemeType == "") {
          let dropdownscheme = z?.["__EMPTY_4"]?.toUpperCase() == "T3"&&y?.topTargetArrState?.t3I ? ["t1", "t2", "t3"] :
            z?.["__EMPTY_4"]?.toUpperCase() == "T2" ? ["t1", "t2"] :
              z?.["__EMPTY_4"]?.toUpperCase() == "T1" ? ["t1"] : []
          let paget1 = dropdownscheme.includes("t1" || "t2" || "t3")
          let paget2 = dropdownscheme.includes("t2" || "t3")
          let paget3 = dropdownscheme.includes("t3")
          calArr.push(index);
          return {
            ...y,
            dropdownscheme: dropdownscheme,
            t1: paget1 ? z?.Targets : "",
            t2: paget2 ? z?.["__EMPTY_8"] : "",
            t3: paget3 ? z?.["__EMPTY_9"] : "",
            r1: paget1 ? parseFiveDigitNumber(z?.["INC.PER TRACTOR"]) : "",
            r2: paget2 ? parseFiveDigitNumber(z?.["__EMPTY_10"]) : "",
            r3: paget3 ? parseFiveDigitNumber(z?.["__EMPTY_11"]) : "",
            p1: (paget1) ? (parseInt(z?.Targets || 0) * parseInt(z?.["INC.PER TRACTOR"] || 0)) : 0,
            p2: (paget2) ? (parseInt(z?.__EMPTY_8 || 0) * parseInt(z?.["__EMPTY_10"] || 0)) : 0,
            p3: (paget3) ? (parseInt(z?.__EMPTY_9 || 0) * parseInt(z?.__EMPTY_11 || 0)) : 0,
            reason: z?.["-_1"] || "",
          };
        } else if (schemeType == "8") {
          return {
            ...y,
            installationTarget: z?.["INSTALLATION TARGET"] || "",
            incPerTarget: z?.["INC. PER TRACTOR"] || "",
            reason: z?.["REMARKS"] || "",
          };
        } else if (schemeType == "4") {
          return {
            ...y,
            installationTarget: z?.["INSTALLATION TARGET"] || "",
            upperCap: z?.["UPPER CAP"] || "",
            incPerTarget: z?.["INC. PER TRACTOR"] || "",
            reason: z?.["REMARKS"] || "",
          };
        } else {
          return {
            ...y,
            installationTarget: z?.["INSTALLATION EXPECTED"] || "",
            incPerTarget: z?.["INC. PER TRACTOR"] || "",
            reason: z?.["REMARKS"] || "",
          };
        }
      } else {
        return y;
      }
    });
    await setCheckCalculate(true);
    await setCheckCalculateArr(calArr);
    await formik?.setValues(dataArr);
    ToastifyShow("File uploaded successfully", "success");

  };
}


  const menu = (
    <Menu>
      <Menu.Item>
        {selectedFiles?.length == 0 && (
          <div className="custom-file-input">
            <input type="file" accept=".xlsx" onChange={handleFileUpload} />
            <label htmlFor="fileInput">Choose File</label>
          </div>
        )}
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <Button onClick={() => handleRemoveFile(index)} className="mx-1">
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </Menu.Item>
      <Menu.Item onClick={handleSampleFile}>Download Sample File</Menu.Item>
    </Menu>
  );
  return (
    <div className="create_scheme_wrap general-bscheme">
      <Button
        type="primary"
        icon={<ArrowBackRounded />}
        size="middle"
        onClick={() => {
          navigate(-1);
          // navigate("/installation/draftSchemes")
        }}
      />
      {/* <Col
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px 0px",
              width:"100%",
              justifyContent:"space-between"
            }}
          >
<div></div>
<Space
              style={{
                padding: "0",
                paddingTop: "14px",
              }}
              direction="vertical"
            >
              <Tooltip title={"Import/Export to Excel"}>
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button className="h-2">Import/Export to Excel</Button>
              </Dropdown>
              </Tooltip> 
            </Space>

          </Col> */}
      <div className="table_scheme_wrap generate-finace-report">
        <Form>
          <Table
            className="finance"
            loading={loadingState == "pending" ? true : false}
            columns={defaultColumns}
            dataSource={formik?.values}
            bordered
            pagination={false}
            scroll={{
              x: 1000,
              y: 300,
            }}
            size="middle"
          />
        </Form>
        <div className="pt-3 d-flex f-reverse">
          {pathname != "/installation/allSchemes/edit" && (
            <Col>
              <Button
                className="draft-btn"
                type="default"
                shape="round"
                style={{
                  margin: "10px",
                  background: "#FCE838",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  border: "none",
                  padding: "20px",
                }}
                size="large"
                onClick={draftData}
              >
                save as draft
              </Button>
            </Col>
          )}
          <Col>
            <Button
              type="default"
              shape="round"
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",
                padding: "20px",
              }}
              size="large"
              onClick={submitData}
            >
              Save & Submit
            </Button>
          </Col>
        </div>
      </div>

      <ValidationDifferentSchemeError
        open={openErrorDialog.open}
        onClose={closeErrorDialog}
        errorsFields={openErrorDialog?.errorsFields}
        // SchemeID={codeError}
      />
      {/* <ValidationErrorsModal
        open={openErrorDialog.open}
        onClose={() => {
          setOpenErrorDialog({ open: false, errorsFields: [] });
        }}
        errorsFields={openErrorDialog?.errorsFields}
      /> */}
      {/* <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>
        <p>
          {errorItem.firstItem} should be greater then {errorItem.secondItem}
        </p>
      </SMDialog> */}

      <StatusModal
        open={openDraftModal}
        onOk={() => {
          handleDraftScheme();
          handleDraftModal();
        }}
        onCancel={handleDraftModal}
      >
        <p>Are you sure to save as Draft?</p>
      </StatusModal>
      <StatusModal
        open={openSuccessModal}
        onOk={() => {
          handleSaveScheme();
        }}
        onCancel={handleSuccessModal}
      >
        <p>Are you sure you want to Save</p>
      </StatusModal>
    </div>
  );
};
export default DraftInstallationEdit;
