import React, { useEffect, useState } from "react";
import { Dropdown, Space, Row, Col, Button,DatePicker,Alert,Popconfirm } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { monthPicker } from "../../../redux/summaryMonthPicker/Monthpicker";
import FinancialYearButton from "../../financialYearButton/FinancialYearButton";
import dayjs from "dayjs";
import FinancialButtonNew from "../../financialYearButton/FinancialButtonNew";
import { getCurrentFiscalYear1, getCurrentFiscalYearShow } from "../../../utils/dateFormatter";

const Dropdowns = ({modalFormData, onChange, errorMessage, type }) => {
const { RangePicker } = DatePicker;
const { scheme_Type, start_Month, end_Month } = modalFormData;
const currentDate = dayjs();
const currentYear = dayjs().year();
const currentMonth = currentDate.month();
const financialYearStartMonth = 3; // April (0-indexed)
const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
const financialYear = financialYearStartYear + 1;
  const [dateValue, setDateValue] = useState(financialYear);
  const [dateDetails,setDateDetails]=useState({      month: 0,
    monthEnd: 0})

  // const [modalFormData, setModalFormData] = useState(InitSummaryModalValue);

  const [selectedMonth, setSelectedMonth] = useState(null)
  const dispatch = useDispatch()
  const getSchemeType = (type) => {
    if (type === 1) {
      return 'With Delivery Plan';
    }
    if (type === 2) {
      return 'Without Delivery Plan';
    }
    if (type === 3) {
      return 'Slab Based';
    }
    if (type === 8) {
      return 'Single Target';
    }
    if (type === 4) {
      return 'Single Target With Upper Cap';
    }
    if (type === 5) {
      return 'Unconditional Installation';
    }
    return '';
  }

  const schemeType = getSchemeType(type);

  const disabledDate = (current, selectedMonth) => {
    const today = dayjs().year(dateValue - 1);
    const currentYear = today.year();

    // Calculate the start date of the current financial year (April of the current year)
    const startFinancialYear = new Date(currentYear, 3, 1); // Months are zero-based, so April is 3

    // Calculate the end date of the current financial year (March of the next year)
    const endFinancialYear = new Date(currentYear + 1, 2, 31); // March is 2

    // Convert current date to Date object if it's not already
    const currentDate = current instanceof Date ? current : new Date(current);

    // Disable dates that are outside the current financial year
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };

  const handlePanelChange = (value) => {
    setSelectedMonth(value);
  };


  const handleRangePicker = (date) => {
    const startDate = date && date[0] ? date[0].format() : '';
    const endDate = date && date[1] ? date[1].format() : '';
    const startMonth= new Date (startDate)
    const endMonth =  new Date (endDate);
    const start_Month = startMonth.getMonth()+1;
    const end_Month = endMonth.getMonth()+1;
    const start_yr = startMonth.getFullYear();
    const end_yr = endMonth.getFullYear();
    const financialYear=getCurrentFiscalYearShow(dayjs().year(dateValue)||dayjs());
    const month = {start_Month,end_Month,start_yr,end_yr,financialYear}

    dispatch(monthPicker(month));
  }

  return (
    <div>
      <Row className="summary_card">
        {/* <Col>
          <Button disabled>
            <Space>2023</Space>
          </Button>
        </Col> */}
        <Col className="d-flex align-items-center">
          {/* <Dropdown
          // menu={{ items: schemeType }}
          trigger={['click']}
          > */}
            <Button>
           
                {/* { type == " " ? "Scheme Type" :  getSchemeType()} */}
               { schemeType}
              
             
            </Button>
          {/* </Dropdown> */}
          <FinancialButtonNew  
       handleDate={(x)=>{
        const startDate = dateDetails?.month ? dateDetails?.month : '';
        const endDate = dateDetails?.monthEnd ? dateDetails?.monthEnd : '';
        const startMonth= new Date (startDate)
        const endMonth =  new Date (endDate);
        const start_Month = startMonth.getMonth()+1||0;
        const end_Month = endMonth.getMonth()+1||0;
        const start_yr = startMonth.getFullYear()||0;
        const end_yr = endMonth.getFullYear()||0;
        const financialYear=getCurrentFiscalYearShow(x||dayjs());
        const month = {start_Month,end_Month,start_yr,end_yr,financialYear}
        dispatch(monthPicker(month));
        setDateValue(dayjs(x)?.year())}}
        />
          {errorMessage && <Alert message={errorMessage} type="error" />}
        </Col>
        <RangePicker
          mode={["month", "month"]}
          picker="month"
          onChange={(date, formatDate) => {
            handleRangePicker(date)
            setDateDetails({
              month: date && date?.[0] ? date?.[0].format() : 0,
              monthEnd: date && date?.[1] ? date?.[1].format() : 0,
            })
          }}
          disabledDate={(current) => disabledDate(current, selectedMonth)}
          onPanelChange={handlePanelChange}
        />
      </Row>
    </div>
  );
};

export default Dropdowns;
