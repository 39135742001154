import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from 'antd';
import axiosAPI from "../../services/axiosApi";


//Login User Action
export const fetchgenerateScheme = createAsyncThunk(
  "fetchSchemeList",
  async (payload,cb) => {
    let result = await axiosAPI.post(`SchemeGenerate/SchemeGenerate`,payload);

    if(result.data.response === 1){

        message.success({
          content: 'Scheme successfully generated',
          className: 'custom-message', 
        })
        .then(()=>  window.location.replace('/collection/allSchemes'))


    } else if(result.data.response === 14){

        message.warning("Scheme already exit with this dealer")


    } else if(result.data.response === 2){

        message.success({
          content: 'Scheme successfully generated',
          className: 'custom-message', 
        })
        .then(()=>  window.location.replace('/collection/draftSchemes'))

    } else if(result.data.response === 46){
      message.error(result.data.errorMessage || 'Locked for current area')
    }
  }
);

const generateScheme = createSlice({
  name: "generateScheme",
  initialState: {
    data: [{"hey":"ankit"}],
    // error:null
  },
  reducers: {
    add: (state, action) => {
      state.data = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //   .addCase(fetchgenerateScheme.rejected, (state, action) => {
  //   state.error = action.error.message;
  //   });
  //   },
});
export const { add } = generateScheme.actions;
export default generateScheme.reducer;
