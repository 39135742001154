export const SchemeStatus = () => {
    let colorArray = [{
        "name": "Approved", color: "green"
    }, {
        "name": "Rejected", color: "red"
    }, {
        "name": "Work in Progress", color: "yellow"
    }, {
        "name": "Auto Rejected", color: "pink"
    }, {
        "name": "Deviation", color: "#BCB38D"
    }, {
        "name": "Hold", color: "#9999ff"
    }]

    return (
        <div className="flex pt-3 flex-wrap bg-white py-2 pl-2" >
            {colorArray?.map((x) => (<div className="items-center flex pr-4 ">
                <div className={`!color-[#464646] h-3.5 rounded-full w-3.5 !bg-[${x?.color}]`} style={{ backgroundColor: x?.color }}></div>
                <span className="pl-3 text-base font-bold">{x?.name}</span>
            </div>))
            }
        </div >
    )
}