import React, { useEffect, useState } from "react";
import CommonCard from "../../../components/commonCard/CommonCard";
import StateHeadTable from "./StateHeadTable";
import FinancialYearButton from "../../../components/financialYearButton/FinancialYearButton";
import SubmittedCard from "../../../assets/images/schemeSubmitted.png";
import ApprovedCard from '../../../assets/images/schemeApproved.png';
import PendingCard from '../../../assets/images/schemePending.png';
import RejectedCard from '../../../assets/images/rejected.png';
import dayjs from "dayjs";


const StateHead = ({SetTotalCountShow,totalCountShow}) => {
  const [stateheaddata, setStateHeadData] = useState({
    pendingCount: 0,
    approvedCount: 0,
    submittedCount: 0,
    totalApprovedCount:0,
    totalPendingCount:0,
    totalSubmittedCount:0,
    rejectedCount:0,
    autoRejectCount:0,
    totalRejectedCount: 0
  });
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const financialYearStartMonth = 3; // April (0-indexed)
  const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  const financialYear = financialYearStartYear + 1;
    const [dateValue, setDateValue] = useState(financialYear);
  
    useEffect(() => {
      setDateValue(financialYear);
    }, [financialYear]);
  
  const [typeOfScheme, setTypeOfScheme] = useState(1);

  
  const handleTypeOfScheme = (data) => {
   
    setTypeOfScheme(data);
  };

  return (
    <>
      <div className="amgr_year">
        <FinancialYearButton        handleDate={(x)=>setDateValue(x)}/>
      </div>
      <div className="cards_container">
        <CommonCard
          submittedNumber={totalCountShow?stateheaddata.submittedCount:!totalCountShow?stateheaddata.totalSubmittedCount: 0}
          schemeState="Schemes Submitted"
          colorCode="black"
          backgroundImage= {`url(${SubmittedCard})`}
          backgroundSize = 'cover'
          typeOfScheme={typeOfScheme}
        />
        <CommonCard
          submittedNumber={totalCountShow?stateheaddata.approvedCount:!totalCountShow?stateheaddata.totalApprovedCount :0}
          schemeState="Schemes Approved"
          colorCode="white"
          backgroundImage= {`url(${ApprovedCard})`}
          backgroundSize = 'cover'
          typeOfScheme={typeOfScheme}
        />
        <CommonCard
          submittedNumber={totalCountShow?stateheaddata.pendingCount:!totalCountShow?stateheaddata.totalPendingCount :0}
          schemeState="Schemes Pending"
          colorCode="black"
          backgroundImage= {`url(${PendingCard})`}
          backgroundSize = 'cover'
          typeOfScheme={typeOfScheme}
        />
         <CommonCard
           submittedNumber={totalCountShow?stateheaddata.rejectedCount:!totalCountShow?stateheaddata.totalRejectedCount :0}
          schemeState="Schemes Rejected"
          colorCode="black"
          backgroundImage= {`url(${RejectedCard})`}
          backgroundSize = 'cover'
          typeOfScheme={typeOfScheme}
        />
      </div>
      <div>
        
        <StateHeadTable 
        onSetCardData={data => setStateHeadData(data)} 
        SetTotalCountShow={(data)=>SetTotalCountShow(data)}
        sendDataToParent={handleTypeOfScheme}
        fyear={dateValue}
        />
      </div>{" "}
    </>
  );
};
export default StateHead;
