export const ModificationCollectionSchemeDownload = (props) => {
  let { collectionLock } = props;

  let tab_text =
    collectionLock == "t3"
      ? `<table class="custom-table">
  <thead >
  <tr >
    <th rowspan="2">DEALER CODE</th>
    <th rowspan="2">DEALER LOCATION</th>
    <th rowspan="2">DEALER NAME</th>
    <th rowspan="2">STATE</th>
    <th rowspan="2">TERRITORY MANAGER</th>
    <th rowspan="2">INDUSTRY</th>
    <th rowspan="2">B</th>
    <th rowspan="2">I</th>
    <th rowspan="2">C</th>
    <th rowspan="2">START MONTH</th>
    <th rowspan="2">END MONTH</th>
    <th rowspan="2">TOP SLAB</th>
    <th rowspan="2">INDUSTRY</th>
    
    <th colspan="2" rowspan="1">OPENING STOCK</th>
    <th colspan="4" rowspan="1">CLOSING OS.(IN LACS)</th>
    <th colspan="1" rowspan="1">BG</th>
    <th colspan="3" rowspan="1">BILLING DETAILS</th>
    <th colspan="1" rowspan="1">Installation Plan</th>
    <th colspan="3" rowspan="1">BOOKS COLLECTION TARGETS (NO. OF TRS.)</th>
    <th colspan="3" rowspan="1">PAYOUT RATE</th>
    <th colspan="1" rowspan="2">REMARKS</th>
    <th colspan="1" rowspan="2">Expected Volume</th>
  </tr>
  <tr>

   <th>NOS</th>
   <th>DAYS</th>
   <th>Last 3rd Month</th>
      <th>Last 2nd Month</th>
     <th>Last 1st Month</th>
         <th>LAST 1st MONTH DAYS</th>
   <th>LACS</th>
   <th>B</th>
   <th>I</th>
   <th>C</th>
   <th>NOS</th>
   <th>T1</th>
   <th>T2</th>
   <th>T3</th>
  
   <th>R1</th>
   <th>R2</th>
   <th>R3</th>
  
  </tr>
  </thead>`
      : collectionLock == "t4"
      ? `<table class="custom-table">
      <thead >
      <tr >
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">TERRITORY MANAGER</th>
  
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">TOP SLAB</th>
       <th rowspan="2">INDUSTRY</th>
         <th rowspan="2">B</th>
         <th rowspan="2">I</th>
         <th rowspan="2">C</th>
          <th colspan="2" rowspan="1">OPENING STOCK</th>
          <th colspan="4" rowspan="1">CLOSING OS.(IN LACS)</th>
          <th colspan="1" rowspan="1">BG</th>
          <th colspan="3" rowspan="1">BILLING DETAILS</th>
          <th colspan="1" rowspan="1">Installation Plan</th>
          <th colspan="4" rowspan="1">BOOKS COLLECTION TARGETS (NO. OF TRS.)</th>
          <th colspan="4" rowspan="1">PAYOUT RATE</th>
          <th colspan="1" rowspan="2">REMARKS</th>
          <th colspan="1" rowspan="2">Expected Volume</th>
      </tr>
      <tr>
  
         <th>NOS</th>
         <th>DAYS</th>
         <th>LAST 3rd MONTH</th>
         <th>LAST 2nd MONTH</th>
         <th>LAST 1st MONTH</th>
         <th>LAST 1st MONTH DAYS</th>
         <th>LACS</th>
         <th>B</th>
         <th>I</th>
         <th>C</th>
         <th>NOS</th>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>T4</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>R4</th>
        </tr>
      </thead>`
      : collectionLock == "t5"
      ? `<table class="custom-table">
      <thead >
      <tr >
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">TOP SLAB</th>
       <th rowspan="2">INDUSTRY</th>
         <th rowspan="2">B</th>
         <th rowspan="2">I</th>
         <th rowspan="2">C</th>
          <th colspan="2" rowspan="1">OPENING STOCK</th>
          <th colspan="4" rowspan="1">CLOSING OS.(IN LACS)</th>
          <th colspan="1" rowspan="1">BG</th>
          <th colspan="3" rowspan="1">BILLING DETAILS</th>
          <th colspan="1" rowspan="1">Installation Plan</th>
          <th colspan="5" rowspan="1">BOOKS COLLECTION TARGETS (NO. OF TRS.)</th>
          <th colspan="5" rowspan="1">PAYOUT RATE</th>
          <th colspan="1" rowspan="2">REMARKS</th>
          <th colspan="1" rowspan="2">Expected Volume</th>
      </tr>
      <tr>

         <th>NOS</th>
         <th>DAYS</th>
         <th>LAST 3rd MONTH</th>
         <th>LAST 2nd MONTH</th>
         <th>LAST 1st MONTH</th>
         <th>LAST 1st MONTH DAYS</th>
         <th>LACS</th>
         <th>B</th>
         <th>I</th>
         <th>C</th>
         <th>NOS</th>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>T4</th>
         <th>T5</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>R4</th>
         <th>R5</th>
        </tr>
      </thead>`
      : collectionLock == "t6"
      ? `<table class="custom-table">
      <thead >
      <tr >
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">TERRITORY MANAGER</th>
  
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">TOP SLAB</th>
       <th rowspan="2">INDUSTRY</th>
         <th rowspan="2">B</th>
         <th rowspan="2">I</th>
         <th rowspan="2">C</th>
          <th colspan="2" rowspan="1">OPENING STOCK</th>
          <th colspan="4" rowspan="1">CLOSING OS.(IN LACS)</th>
          <th colspan="1" rowspan="1">BG</th>
          <th colspan="3" rowspan="1">BILLING DETAILS</th>
          <th colspan="1" rowspan="1">Installation Plan</th>
          <th colspan="6" rowspan="1">BOOKS COLLECTION TARGETS (NO. OF TRS.)</th>
          <th colspan="6" rowspan="1">PAYOUT RATE</th>
          <th colspan="1" rowspan="2">REMARKS</th>
          <th colspan="1" rowspan="2">Expected Volume</th>
      </tr>
      <tr>

         <th>NOS</th>
         <th>DAYS</th>
         <th>LAST 3rd MONTH</th>
         <th>LAST 2nd MONTH</th>
          <th>LAST 1st MONTH</th>
         <th>LAST 1st MONTH DAYS</th>
         <th>LACS</th>
         <th>B</th>
         <th>I</th>
         <th>C</th>
         <th>NOS</th>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>T4</th>
         <th>T5</th>
         <th>T6</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>R4</th>
         <th>R5</th>
         <th>R6</th>
        </tr>
      </thead>`
      : `<table class="custom-table">
      <thead >
      <tr >
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">TERRITORY MANAGER</th>
      
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">TOP SLAB</th>
       <th rowspan="2">INDUSTRY</th>
         <th rowspan="2">B</th>
         <th rowspan="2">I</th>
         <th rowspan="2">C</th>
          <th colspan="2" rowspan="1">OPENING STOCK</th>
          <th colspan="4" rowspan="1">CLOSING OS.(IN LACS)</th>
          <th colspan="1" rowspan="1">BG</th>
          <th colspan="3" rowspan="1">BILLING DETAILS</th>
          <th colspan="1" rowspan="1">Installation Plan</th>
  
          <th colspan="1" rowspan="2">REMARKS</th>
          <th colspan="1" rowspan="2">Expected Volume</th>
      </tr>
      <tr>

         <th>NOS</th>
         <th>DAYS</th>
         <th>LAST 3rd MONTH</th>
         <th>LAST 2nd MONTH</th>
         <th>LAST 1st MONTH</th>
         <th>LAST 1st MONTH DAYS</th>
         <th>LACS</th>
         <th>B</th>
         <th>I</th>
         <th>C</th>
         <th>NOS</th>   
        </tr>
      </thead>`;
  return tab_text;
};
