import { Divider } from 'antd';
import React from 'react';
import SchemeStatusTable from './SchemeStatusTable';

const SchemeStatus = () => {
  return (
    <div className='scheme_status'>
    <Divider/>
     <SchemeStatusTable/>
    </div>
  )
}

export default SchemeStatus;
