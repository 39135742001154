import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Modal, Input } from 'antd';

const { TextArea } = Input;

const HoldModal = React.memo(({
  confirmationModal,
  setConfirmationModal,
  handleConformationModal,
  editIndex,
  selectedHold,
  modalType,
  selectedRowKeys,
  selectedId,
  formik,
  setSelectedId,
  handleCancel,
}) => {
const [message,setMessage]=useState("")
  const handleChange = (e) => {
      setMessage(e.target.value);
    }

  const titleText = useMemo(
    () =>
      selectedHold
        ? "Do you want to hold the selected scheme? Please enter the Remarks for the selected Scheme"
        : "Do you want to hold this scheme? Please enter the Remarks",
    [selectedHold, modalType]
  );

  const titleTextUnhold = useMemo(
    () =>
      selectedHold
        ? "Do you want to Unhold the selected scheme?"
        : "Do you want to Unhold this scheme?",
    [selectedHold, modalType]
  );
  
  useEffect(()=>{
    if(formik?.values.records[editIndex||0]){
      setMessage(formik?.values.records[editIndex||0]?.comments||"")
    }
  },[formik?.values.records[editIndex||0]])

  return (
    <Modal
      title="Confirmation"
      open={confirmationModal}
      onCancel={()=>handleConformationModal(message)}
      onOk={handleCancel}
       cancelText="Yes"
        okText="No"        maskClosable={false}
        destroyOnClose={true}
    >
      <p>{modalType === "hold" ? titleText : titleTextUnhold}</p>
      {modalType === "hold" && (
        <TextArea
          rows={4}
          placeholder="Type here"
          maxLength={100}
          onChange={handleChange}
          value={message||""}
        />
      )}
    </Modal>
  );
})

export default HoldModal;
