import React, { useEffect, useState } from "react";
import {Button, DatePicker, Modal } from "antd";
import dayjs from 'dayjs';
import './changeSchemeModal.css';
import { ReactComponent as WarningIcon } from "../../assets/vector/warningSign.svg";


const ChangeSchemeModal = ({openModal, onModalClose}) => {
  const [showSchemeForm, setShowSchemeForm] = useState(false);
  const [selectedStart, setSelectedStart] = useState(dayjs());
  const [selectedEnd, setSelectedEnd] =  useState(dayjs());
  const currentYear = dayjs(selectedStart).year()
  const futureYear = currentYear + 1

  useEffect(() => {
    setShowSchemeForm(false);
  }, [openModal]);

  const handleChangeButton = () => {
    const isSchemeForm = showSchemeForm;
    setShowSchemeForm(true);

    if (isSchemeForm) {
      onModalClose({ startDate: selectedStart.format(), endDate: selectedEnd.format() });
    }
  };

  const handleDateChange = (date, dateString, type) => {
    if (type === 'startDate') {
      setSelectedStart(date);
    }
    else {
      setSelectedEnd(date);
    }
  };

  return (
      <Modal
        className="ChangeSchemeModal"
        centered={true}
        open={openModal}
        okText="Change"
        onOk={handleChangeButton}
        onCancel={() => onModalClose()}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        // okButtonProps={{ style: { display: "none" } }}
      >
        {showSchemeForm ? (
          <div>
            <h3 className="ChangeSchemeText">Change Scheme</h3>
            <h3 className="ChangeSchemeTextLine">Select the start and end month to create a new scheme</h3>
            <Button className="ChangeSchemeButton yearly-basis-box" disabled>2023</Button>
            <div className='ChangeSchemeDate'>
              <DatePicker
                className="startDate"
                placeholder='Start month'
                picker ="month"
                onChange={(date, dateString) => handleDateChange(date, dateString, 'startDate')}
                value={selectedStart}
                disabledDate={(current) => {
                  return current && current < dayjs(selectedEnd);
                }}
              />
              <DatePicker
                className="endDate"
                placeholder='End month'
                picker ="month"
                onChange={(date, dateString) => handleDateChange(date, dateString, 'endDate')}
                value={selectedEnd}
                disabledDate={(current) => {
                  // return current && current < dayjs(selectedStart);
                  return current.year() < currentYear || (current.year() === currentYear && current.month() < 3) ||
                  current.year() > futureYear || (current.year() === futureYear && current.month() > 2) 
                }}
              />
            </div>
          </div>
        ) : (

         <div>
            <WarningIcon className="WarningIcon"/>
            <h4 className="WarningText">Warning!</h4>
            <h6 className="Text">Creating new scheme shall withdraw the previous scheme.</h6>
          </div>
        )}

      </Modal>
  );
};

export default ChangeSchemeModal;
