import { createSlice } from '@reduxjs/toolkit'


export const headerSlice = createSlice({
  name: 'heading',
  initialState: {
    heading: ''
  },
  reducers: {
    setHeading: (state, action) => {
      state.heading = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setHeading } = headerSlice.actions

export default headerSlice.reducer