import ToastifyShow from "../../components/ToastifyShow";
import axiosAPI from "../../services/axiosApi"

  export const trackCollectionDealersList = async (data) => {
    try {
      const response=axiosAPI.post(`controller/getCollectionDealersList`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const trackInstallationDealersList = async (data) => {
    try {
      const response=axiosAPI.post(`Installation/getInstallationSchemesList`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };