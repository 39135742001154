import React, { Table, Tag, Space, Tooltip } from "antd";

import dayjs from "dayjs";

import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const AwaitingApprovalTable = ({
 
  selectedSchemeType,
  dataSource,
  selectedRowKeys,
  setSelectedRowKeys,
  selectedLabel,
  setEditSchemes,
  setSchemeIdNo,
  setSchemeNo,
  setSchemeId,
  schemeNo,
  handleRejectModal1,
  handleAcceptModal1,
  loading,
  setModificationId
}) => {
  const handleSingleEdit = (record) => {
    setSchemeIdNo([record])
    setEditSchemes(true)
  };



  const { userRole, userId, userName } = decryptData(sessionStorage.getItem("userInfo"));
  const getCols = (schemeType) => {
    if (schemeType == 1 || schemeType == 2) {
      return [
        {
          title: "Industry",
          dataIndex: "industry",
          render: (x, y, index) => (
            (y.holdStatus === 9  ) ?
            <div>
              {(y.industry || y.industry == 0 ) && <Tag>{y.industry}</Tag>}
              {(y.holdIndustry || y.holdIndustry ==0) && (
                <Tag
                  color={
                    y.holdIndustry !== y.industry &&
                    "red"
                  }
                >
                  {y.holdIndustry}
                </Tag>
              )}
            </div>:
            <div>
              {(y.industry || y.industry == 0) && <Tag>{y.industry}</Tag>}
              {(y.modifiedIndustry || y.modifiedIndustry==0) && (
                <Tag
                  color={
                    y.modifiedIndustry !== y.industry &&
                    "red"
                  }
                >
                  {y.modifiedIndustry}
                </Tag>
              )}
            </div>
          ),
          width: "100px",
        },
        {
          title: "B",
          dataIndex: "oldBillingQty",
          width: "100px",
        },
        {
          title: "I",
          dataIndex: "oldInstallation",
          width: "100px",
        },
        {
          title: "C",
          dataIndex: "oldCollection",
          width: "100px",
        },
        {
          title: (
            <div>
              Opening <br /> Stock
            </div>
          ),
          dataIndex: "stock",
          width: "100px",
          children: [
            {
              title: "NOS",
              width: "100px",
              dataIndex: "oldOpeningStock",
            },
            {
              title: "Days",
              dataIndex: "openingStockDays",
              // render: (x, y, index) => (
              //   <div >
              //     {y.openingStockDays && <Tag>{y.openingStockDays}</Tag>}
              //     {y.modifiedOpeningStockDays && (
              //       <Tag
              //         color={
              //           y.modifiedOpeningStockDays !== y.openingStockDays &&
              //           "red"
              //         }
              //       >
              //         {y.modifiedOpeningStockDays}
              //       </Tag>
              //     )}
              //   </div>
              // ),
              width: "100px",
            },
          ],
        },
        {
          title: "Closing Os. (In Lacs)",
          dataIndex: "stock",
          defaultSortOrder: "descend",

          children: [
            {
    title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
              dataIndex: "closingOutStandingMonth_3",
              defaultSortOrder: "descend",
              width: "150px",
            },
            {
              title: (
                <span>
                  Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
                </span>
              ),
              dataIndex: "closingOutStandingMonth_2",
              defaultSortOrder: "descend",
              width: "150px",
            },
            {
              title: (
                <span>
                  Last Month
                </span>
              ),
              dataIndex: "closingOutStandingMonth_1",
              defaultSortOrder: "descend",
              width: "150px",
            },

            // {
            //   title: (
            //     <span>
            //       Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
            //     </span>
            //   ),
            //   dataIndex: "outStandingDays",
            //   width: "90px",
            // },
          ],
        },
        {
          title: "Closing OS Days",
          dataIndex: "outStandingDays",
          children: [
            {
              title: "OS Last Month",
              dataIndex: "outStandingDays",
              width: "90px",
            },
          ],
        },
        {
          title: "BG",
          dataIndex: "outStandingDays",
          children: [
            {
              title: "Lacs",
              dataIndex: "bGLacsCurrentYear",
              width: "100px",
            },
          ],
        },
        {
          title: (
            <div>
              Installation <br /> Plan
            </div>
          ),
          dataIndex: "IP",
          children: [
            {
              title: "NOS",
              dataIndex: "delayPlan",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.delayPlan || y.delayPlan == 0 ) && <Tag>{y.delayPlan}</Tag>}
                  {(y.holdDelayPlan || y.holdDelayPlan ==0) && (
                    <Tag
                      color={
                        y.holdDelayPlan !== y.delayPlan &&
                        "red"
                      }
                    >
                      {y.holdDelayPlan}
                    </Tag>
                  )}
                </div>:
                <div>
                  {(y.delayPlan || y.delayPlan ==0) && <Tag>{y.delayPlan}</Tag>}
                  {(y.modifiedDelayPlan || y.modifiedDelayPlan ==0) && (
                    <Tag color={y.modifiedDelayPlan !== y.delayPlan && "red"}>
                      {y.modifiedDelayPlan}
                    </Tag>
                  )}
                </div>
              ),
              width: "100px",
            },
          ],
        },
        {
          title: (
            <div>
              Billing <br /> Details
            </div>
          ),
          dataIndex: "plan",
          children: [
            {
              title: "B",
              dataIndex: "billing",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.billing || y.billing == 0 ) && <Tag>{y.billing}</Tag>}
                  {(y.holdBilling || y.holdBilling ==0) && (
                    <Tag
                      color={
                        y.holdBilling !== y.billing &&
                        "red"
                      }
                    >
                      {y.holdBilling}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.billing || y.billing ==0 ) && <Tag>{y.billing}</Tag>}
                  {(y.modifiedBilling || y.modifiedBilling ==0)&& (
                    <Tag color={y.modifiedBilling !== y.billing && "red"}>
                      {y.modifiedBilling}
                    </Tag>
                  )}
                </div>
              ),
              width: "100px",
            },
            {
              title: "I",
              dataIndex: "installation",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.installation || y.installation == 0 ) && <Tag>{y.installation}</Tag>}
                  {(y.holdInstallation || y.holdInstallation ==0) && (
                    <Tag
                      color={
                        y.holdInstallation !== y.installation &&
                        "red"
                      }
                    >
                      {y.holdInstallation}
                    </Tag>
                  )}
                </div>: 
                <div >
                  {(y.installation || y.installation==0) && <Tag>{y.installation}</Tag>}
                  {(y.modifiedInstallation || y.modifiedInstallation== 0) && (
                    <Tag
                      color={y.modifiedInstallation !== y.installation && "red"}
                    >
                      {y.modifiedInstallation}
                    </Tag>
                  )}
                </div>
              ),
              width: "100px",
            },
            {
              title: "C",
              dataIndex: "collection",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.collection || y.collection == 0 ) && <Tag>{y.collection}</Tag>}
                  {(y.holdCollection || y.holdCollection ==0) && (
                    <Tag
                      color={
                        y.holdCollection !== y.collection &&
                        "red"
                      }
                    >
                      {y.holdCollection}
                    </Tag>
                  )}
                </div>: 
                <div >
                  {(y.collection || y.collection ==0)&& <Tag>{y.collection}</Tag>}
                  {(y.modifiedCollection || y.modifiedCollection==0) && (
                    <Tag color={y.modifiedCollection !== y.collection && "red"}>
                      {y.modifiedCollection}
                    </Tag>
                  )}
                </div>
              ),
              width: "100px",
            },
          ],
        },
        {
          title: (
            <div>
              Closing <br /> Stock
            </div>
          ),
          dataIndex: "closingStock",
          width: "80px",
          render: (x, y, index) => (
            (y.holdStatus === 9  ) ?
                <div>
                  {(y.closingStock || y.closingStock == 0 ) && <Tag>{y.closingStock}</Tag>}
                  {(y.holdclosingStock || y.holdclosingStock ==0) && (
                    <Tag
                      color={
                        y.holdclosingStock !== y.closingStock &&
                        "red"
                      }
                    >
                      {y.holdclosingStock}
                    </Tag>
                  )}
                </div>: 
            <div >
              {(y.closingStock || y.closingStock==0) && <Tag>{y.closingStock}</Tag>}
              {(y.modifiedclosingStock|| y.modifiedclosingStock==0) && (
                <Tag color={y.modifiedclosingStock !== y.closingStock && "red"}>
                  {y.modifiedclosingStock}
                </Tag>
              )}
            </div>
          ),
        },
        {
          title: (
            <div>
              Closing <br /> OutStanding (In Lacs)
            </div>
          ),
          dataIndex: "closingOutStanding",
          width: "110px",
          render: (x, y, index) => (
            (y.holdStatus === 9  ) ?
            <div>
              {(y.closingOutStanding || y.closingOutStanding == 0 ) && <Tag>{y.closingOutStanding}</Tag>}
              {(y.holdclosingOutStanding || y.holdclosingOutStanding ==0) && (
                <Tag
                  color={
                    y.holdclosingOutStanding !== y.closingOutStanding &&
                    "red"
                  }
                >
                  {y.holdclosingOutStanding}
                </Tag>
              )}
            </div>:
            <div >
              {(y.closingOutStanding || y.closingOutStanding==0) && <Tag>{y.closingOutStanding}</Tag>}
              {(y.modifiedclosingOutStanding || y.modifiedclosingOutStanding==0) && (
                <Tag color={y.modifiedclosingOutStanding !== y.closingOutStanding && "red"}>
                  {y.modifiedclosingOutStanding}
                </Tag>
              )}
            </div>
          ),
        },
        {
          title: "Books Collection Target (No. of Ts.)",
          dataIndex: "BooksCollectionTarget",
          children: [
            {
              title: "T1",
              dataIndex: "t1",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
            <div>
              {(y.t1 || y.t1 == 0 ) && <Tag>{y.t1}</Tag>}
              {(y.holdT1 || y.holdT1 ==0) && (
                <Tag
                  color={
                    y.holdT1 !== y.t1 &&
                    "red"
                  }
                >
                  {y.holdT1}
                </Tag>
              )}
            </div>:
                <div >
                  {(y.t1  || y.t1 == 0)&& <Tag>{y.t1}</Tag>}
                  {(y.modifiedT1 || y.modifiedT1==0) && (
                    <Tag color={y.modifiedT1 !== y.t1 && "red"}>
                      {y.modifiedT1}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "T2",
              dataIndex: "t2",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
            <div>
              {(y.t2 || y.t2 == 0 ) && <Tag>{y.t2}</Tag>}
              {(y.holdT2 || y.holdT2 ==0) && (
                <Tag
                  color={
                    y.holdT2 !== y.t2 &&
                    "red"
                  }
                >
                  {y.holdT2}
                </Tag>
              )}
            </div>:
                <div >
                  {(y.t2 || y.t2== 0) && <Tag>{y.t2}</Tag>}
                  {(y.modifiedT2 ||  y.modifiedT2==0) && (
                    <Tag color={y.modifiedT2 !== y.t2 && "red"}>
                      {y.modifiedT2}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "T2-T1",
              dataIndex: "t2_T1",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
            <div>
              {(y.t2_T1 || y.t2_T1 == 0 ) && <Tag>{y.t2_T1}</Tag>}
              {(y.holdT2_T1 || y.holdT2_T1 ==0) && (
                <Tag
                  color={
                    y.holdT2_T1 !== y.t2_T1 &&
                    "red"
                  }
                >
                  {y.holdT2_T1}
                </Tag>
              )}
            </div>:
                <div >
                  {(y.t2_T1 || y.t2_T1==0 )&& <Tag>{y.t2_T1}</Tag>}
                  {(y.modifiedT2_T1|| y.modifiedT2_T1 == 0) && (
                    <Tag color={y.modifiedT2_T1 !== y.t2_T1 && "red"}>
                      {y.modifiedT2_T1}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "T3",
              dataIndex: "t3",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
            <div>
              {(y.t3 || y.t3 == 0 ) && <Tag>{y.t3}</Tag>}
              {(y.holdT3 || y.holdT3 ==0) && (
                <Tag
                  color={
                    y.holdT3 !== y.t3 &&
                    "red"
                  }
                >
                  {y.holdT3}
                </Tag>
              )}
            </div>:
                <div>
                  {(y.t3 || y.t3 ==0)&& <Tag>{y.t3}</Tag>}
                  {(y.modifiedT3 || y.modifiedT3==0) && (
                    <Tag color={y.modifiedT3 !== y.t3 && "red"}>
                      {y.modifiedT3}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "T3-T2",
              dataIndex: "t3_T2",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
            <div>
              {(y.t3_T2 || y.t3_T2 == 0 ) && <Tag>{y.t3_T2}</Tag>}
              {(y.holdT3_T2 || y.holdT3_T2 ==0) && (
                <Tag
                  color={
                    y.holdT3_T2 !== y.t3_T2 &&
                    "red"
                  }
                >
                  {y.holdT3_T2}
                </Tag>
              )}
            </div>:
                <div >
                  {(y.t3_T2 || y.t3_T2== 0) && <Tag>{y.t3_T2}</Tag>}
                  {(y.modifiedT3_T2 || y.modifiedT3_T2==0) && (
                    <Tag color={y.modifiedT3_T2 !== y.t3_T2 && "red"}>
                      {y.modifiedT3_T2}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },


            {
              title: "T4",
              dataIndex: "t4",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
            <div>
              {(y.t4 || y.t4 == 0 ) && <Tag>{y.t4}</Tag>}
              {(y.holdT4 || y.holdT4 ==0) && (
                <Tag
                  color={
                    y.holdT4 !== y.t4 &&
                    "red"
                  }
                >
                  {y.holdT4}
                </Tag>
              )}
            </div>:
                <div>
                  {(y.t4 || y.t4==0) && <Tag>{y.t4}</Tag>}
                  {(y.modifiedT4 || y.modifiedT4==0) && (
                    <Tag color={y.modifiedT4 !== y.t4 && "red"}>
                      {y.modifiedT4}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "T4-T3",
              dataIndex: "t4_T3",
              
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
            <div>
              {(y.t4_T3 || y.t4_T3 == 0 ) && <Tag>{y.t4_T3}</Tag>}
              {(y.holdT4_T3 || y.holdT4_T3 ==0) && (
                <Tag
                  color={
                    y.holdT4_T3 !== y.t4_T3 &&
                    "red"
                  }
                >
                  {y.holdT4_T3}
                </Tag>
              )}
            </div>:
                <div >
                  {(y.t4_T3 || y.t4_T3==0) && <Tag>{y.t4_T3}</Tag>}
                  {(y.modifiedt4_T3|| y.modifiedt4_T3==0) && (
                    <Tag color={y.modifiedt4_T3 !== y.t4_T3 && "red"}>
                      {y.modifiedt4_T3}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },

            {
              title: "T5",
              dataIndex: "t5",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
            <div>
              {(y.t5 || y.t5 == 0 ) && <Tag>{y.t5}</Tag>}
              {(y.holdT5 || y.holdT5 ==0) && (
                <Tag
                  color={
                    y.holdT5 !== y.t5 &&
                    "red"
                  }
                >
                  {y.holdT5}
                </Tag>
              )}
            </div>:
                <div>
                  {(y.t5 || y.t5 == 0) && <Tag>{y.t5}</Tag>}
                  {(y.modifiedT5 || y.modifiedT5==0) && (
                    <Tag color={y.modifiedT5 !== y.t5 && "red"}>
                      {y.modifiedT5}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "T5-T4",
              dataIndex: "t5_T4",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
            <div>
              {(y.t5_T4 || y.t5_T4 == 0 ) && <Tag>{y.t5_T4}</Tag>}
              {(y.holdT5_T4 || y.holdT5_T4 ==0) && (
                <Tag
                  color={
                    y.holdT5_T4 !== y.t5_T4 &&
                    "red"
                  }
                >
                  {y.holdT5_T4}
                </Tag>
              )}
            </div>:
                <div >
                  {(y.t5_T4 || y.t5_T4==0) && <Tag>{y.t5_T4}</Tag>}
                  {(y.modifiedT5_T4|| y.modifiedT5_T4==0) && (
                    <Tag color={y.modifiedT5_T4 !== y.t5_T4 && "red"}>
                      {y.modifiedT5_T4}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "T6",
              dataIndex: "t6",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
            <div>
              {(y.t6 || y.t6 == 0 ) && <Tag>{y.t6}</Tag>}
              {(y.holdT6 || y.holdT6 ==0) && (
                <Tag
                  color={
                    y.holdT6 !== y.t6 &&
                    "red"
                  }
                >
                  {y.holdT6}
                </Tag>
              )}
            </div>:
                <div>
                  {(y.t6 || y.t6==0) && <Tag>{y.t6}</Tag>}
                  {(y.modifiedT6 || y.modifiedT6==0) && (
                    <Tag color={y.modifiedT6 !== y.t6 && "red"}>
                      {y.modifiedT6}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "T6-T5",
              dataIndex: "t6_T5",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
            <div>
              {(y.t6_T5 || y.t6_T5 == 0 ) && <Tag>{y.t6_T5}</Tag>}
              {(y.holdT6_T5 || y.holdT6_T5 ==0) && (
                <Tag
                  color={
                    y.holdT6_T5 !== y.t6_T5 &&
                    "red"
                  }
                >
                  {y.holdT6_T5}
                </Tag>
              )}
            </div>:
                <div >
                  {(y.t6_T5 || y.t6_T5 ==0)&& <Tag>{y.t6_T5}</Tag>}
                  {(y.modifiedT6_T5 || y.modifiedT6_T5== 0) && (
                    <Tag color={y.modifiedT6_T5 !== y.t6_T5 && "red"}>
                      {y.modifiedT6_T5}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },

          ],
        },
        {
          title: (
            <div>
              Payout <br /> Rate
            </div>
          ),
          dataIndex: "pr",

          children: [
            {
              title: "R1",
              dataIndex: "r1",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.r1 || y.r1 == 0 ) && <Tag>{y.r1}</Tag>}
                  {(y.holdR1 || y.holdR1 ==0) && (
                    <Tag
                      color={
                        y.holdR1 !== y.r1 &&
                        "red"
                      }
                    >
                      {y.holdR1}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.r1 ||  y.r1==0) && <Tag>{y.r1}</Tag>}
                  {(y.modifiedR1 || y.modifiedR1==0) && (
                    <Tag color={y.modifiedR1 !== y.r1 && "red"}>
                      {y.modifiedR1}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "R2",
              dataIndex: "r2",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.r2 || y.r2 == 0 ) && <Tag>{y.r2}</Tag>}
                  {(y.holdR2 || y.holdR2 ==0) && (
                    <Tag
                      color={
                        y.holdR2 !== y.r2 &&
                        "red"
                      }
                    >
                      {y.holdR2}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.r2 || y.r2==0) && <Tag>{y.r2}</Tag>}
                  {(y.modifiedR2|| y.modifiedR2==0) && (
                    <Tag color={y.modifiedR2 !== y.r2 && "red"}>
                      {y.modifiedR2}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "R3",
              dataIndex: "r3",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.r3 || y.r3 == 0 ) && <Tag>{y.r3}</Tag>}
                  {(y.holdR3 || y.holdR3 ==0) && (
                    <Tag
                      color={
                        y.holdR3 !== y.r3 &&
                        "red"
                      }
                    >
                      {y.holdR3}
                    </Tag>
                  )}
                </div>:
                <div>
                  {(y.r3|| y.r3==0) && <Tag>{y.r3}</Tag>}
                  {(y.modifiedR3 || y.modifiedR3==0) && (
                    <Tag color={y.modifiedR3 !== y.r3 && "red"}>
                      {y.modifiedR3}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },

            {
              title: "R4",
              dataIndex: "r4",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.r4 || y.r4 == 0 ) && <Tag>{y.r4}</Tag>}
                  {(y.holdR4 || y.holdR4 ==0) && (
                    <Tag
                      color={
                        y.holdR4 !== y.r4 &&
                        "red"
                      }
                    >
                      {y.holdR4}
                    </Tag>
                  )}
                </div>:
                <div>
                  {(y.r4 || y.r4==0) && <Tag>{y.r4}</Tag>}
                  {(y.modifiedR4 || y.modifiedR4 ==0)&& (
                    <Tag color={y.modifiedR4 !== y.r4 && "red"}>
                      {y.modifiedR4}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },   {
              title: "R5",
              dataIndex: "r5",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.r5 || y.r5 == 0 ) && <Tag>{y.r5}</Tag>}
                  {(y.holdR5 || y.holdR5 ==0) && (
                    <Tag
                      color={
                        y.holdR5 !== y.r5 &&
                        "red"
                      }
                    >
                      {y.holdR5}
                    </Tag>
                  )}
                </div>:
                <div>
                  {(y.r5|| y.r5==0) && <Tag>{y.r5}</Tag>}
                  {(y.modifiedR5|| y.modifiedR5==0) && (
                    <Tag color={y.modifiedR5 !== y.r5 && "red"}>
                      {y.modifiedR5}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },   {
              title: "R6",
              dataIndex: "r6",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.r6 || y.r6 == 0 ) && <Tag>{y.r6}</Tag>}
                  {(y.holdR6 || y.holdR6 ==0) && (
                    <Tag
                      color={
                        y.holdR6 !== y.r6 &&
                        "red"
                      }
                    >
                      {y.holdR6}
                    </Tag>
                  )}
                </div>:
                <div>
                  {(y.r6|| y.r6==0) && <Tag>{y.r6}</Tag>}
                  {(y.modifiedR6 || y.modifiedR6==0) && (
                    <Tag color={y.modifiedR6 !== y.r6 && "red"}>
                      {y.modifiedR6}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
          ],
        },

        {
          title: "Payout",
          dataIndex: "payout",

          children: [
            {
              title: "P1",
              dataIndex: "p1",
              width: "150px",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.p1 || y.p1 == 0 ) && <Tag>{y.p1}</Tag>}
                  {(y.holdP1 || y.holdP1 ==0) && (
                    <Tag
                      color={
                        y.holdP1 !== y.p1 &&
                        "red"
                      }
                    >
                      {y.holdP1}
                    </Tag>
                  )}
                </div>:
                <div >
                  <Tag>{y.p1}</Tag>
                  <Tag color={y?.modifiedT1 * y?.modifiedR1 !== y.p1 && "red"}>
                    {(y?.modifiedT1 * y?.modifiedR1) || 0}
                  </Tag>
                </div>
              ),
            },
            {
              title: "P2",
              dataIndex: "p2",
              width: "150px",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.p2 || y.p2 == 0 ) && <Tag>{y.p2}</Tag>}
                  {(y.holdP2 || y.holdP2 ==0) && (
                    <Tag
                      color={
                        y.holdP2 !== y.p2 &&
                        "red"
                      }
                    >
                      {y.holdP2}
                    </Tag>
                  )}
                </div>:
                <div >
                  <Tag>{y.p2}</Tag>
                  <Tag color={y?.modifiedT2 * y?.modifiedR2 !== y.p2 && "red"}>
                    {(y?.modifiedT2 * y?.modifiedR2) || 0}
                  </Tag>
                </div>
              ),
            },
            {
              title: "P3",
              dataIndex: "p3",
              width: "150px",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.p3 || y.p3 == 0 ) && <Tag>{y.p3}</Tag>}
                  {(y.holdP3 || y.holdP3 ==0) && (
                    <Tag
                      color={
                        y.holdP3 !== y.p3 &&
                        "red"
                      }
                    >
                      {y.holdP3}
                    </Tag>
                  )}
                </div>:
                <div >
                  <Tag>{y.p3}</Tag>
                  <Tag color={y?.modifiedT3 * y?.modifiedR3 !== y.p3 && "red"}>
                    {(y?.modifiedT3 * y?.modifiedR3) || 0}
                  </Tag>
                </div>
              ),
            },

            {
              title: "P4",
              dataIndex: "p4",
              width: "150px",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.p4 || y.p4 == 0 ) && <Tag>{y.p4}</Tag>}
                  {(y.holdP4 || y.holdP4 ==0) && (
                    <Tag
                      color={
                        y.holdP4 !== y.p4 &&
                        "red"
                      }
                    >
                      {y.holdP4}
                    </Tag>
                  )}
                </div>:
                   <div >
                   <Tag>{y.p4}</Tag>
                   <Tag color={y?.modifiedT4 * y?.modifiedR4 !== y.p4 && "red"}>
                     {(y?.modifiedT4 * y?.modifiedR4) || 0}
                   </Tag>
                 </div>
              ),
            },  {
              title: "P5",
              dataIndex: "p5",
              width: "150px",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.p5 || y.p5 == 0 ) && <Tag>{y.p5}</Tag>}
                  {(y.holdP5 || y.holdP5 ==0) && (
                    <Tag
                      color={
                        y.holdP5 !== y.p5 &&
                        "red"
                      }
                    >
                      {y.holdP5}
                    </Tag>
                  )}
                </div>:
                <div >
                  <Tag>{y.p5}</Tag>
                  <Tag color={y?.modifiedT5 * y?.modifiedR5 !== y.p5 && "red"}>
                    {(y?.modifiedT5 * y?.modifiedR5) || 0}
                  </Tag>
                </div>
              ),
            },  {
              title: "P6",
              dataIndex: "p6",
              width: "150px",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.p6 || y.p6 == 0 ) && <Tag>{y.p6}</Tag>}
                  {(y.holdP6 || y.holdP6 ==0) && (
                    <Tag
                      color={
                        y.holdP6 !== y.p6 &&
                        "red"
                      }
                    >
                      {y.holdP6}
                    </Tag>
                  )}
                </div>:
                <div >
                  <Tag>{y.p6}</Tag>
                  <Tag color={y?.modifiedT6 * y?.modifiedR6 !== y.p6 && "red"}>
                    {(y?.modifiedT6 * y?.modifiedR6) || 0}
                  </Tag>
                </div>
              ),
            },
          ],
        },
        {
          title: "Total Expected Payout",
          dataIndex: "TEP",
          children: [
            {
              title: "Expected Volume",
              dataIndex: "volume",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y.volume || y.volume == 0 ) && <Tag>{y.volume}</Tag>}
                  {(y.holdVolume || y.holdVolume ==0) && (
                    <Tag
                      color={
                        y.holdVolume !== y.volume &&
                        "red"
                      }
                    >
                      {y.holdVolume}
                    </Tag>
                  )}
                </div>:
                <div >
                  {y.volume && <Tag>{y.volume}</Tag>}
                  {y.modifiedVolume && (
                    <Tag color={y.modifiedVolume !== y.volume && "red"}>
                      {y.modifiedVolume}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "Per Tr.",
              dataIndex: "perTr",
              width: "150px",
              render: (x, y, index) => (
                (y.holdStatus === 9 ) ?
                <div>
                  {(y?.perTr || y?.perTr == 0 ) && <Tag>{y?.perTr}</Tag>}
                  {(y.holdPerTr || y.holdPerTr ==0) && (
                    <Tag
                      color={
                        y.holdPerTr !== y?.perTr &&
                        "red"
                      }
                    >
                      {y.holdPerTr}
                    </Tag>
                  )}
                </div>:
                <div >
                  {y.perTr && <Tag>{y.perTr}</Tag>}
                  {y.modifiedPerTr && (
                    <Tag color={y.modifiedPerTr !== y.perTr && "red"}>
                      {y.modifiedPerTr}
                    </Tag>
                  )}
                </div>
              ),
            },
            {
              title: "Amount",
              dataIndex: "amount",
              width: "150px",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y?.amount || y?.amount == 0 ) && <Tag>{y?.amount}</Tag>}
                  {(y.holdAmount || y.holdAmount ==0) && (
                    <Tag
                      color={
                        y.holdAmount !== y?.amount &&
                        "red"
                      }
                    >
                      {y.holdAmount}
                    </Tag>
                  )}
                </div>:
                <div >
                  {y.amount && <Tag >{y.amount}</Tag>}
                  {y.modifiedAmount && (
                    <Tag color={y.modifiedAmount !== y.amount && "red"}>
                      {y.modifiedAmount}
                    </Tag>
                  )}
                </div>
              ),
            },
          ],
        },
      ];
    }
    if (schemeType == 3) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          // sorter: true,
          width: "200px",
        },
        {
          title: "Targets",
          dataIndex: "Target",
          children: [
            {
              title: "T1",
              dataIndex: "t1",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y?.t1 || y?.t1 == 0 ) && <Tag>{y?.t1}</Tag>}
                  {(y.holdT1 || y.holdT1 ==0) && (
                    <Tag
                      color={
                        y.holdT1 !== y?.t1 &&
                        "red"
                      }
                    >
                      {y.holdT1}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.t1 || y.t1==0) && <Tag>{y.t1}</Tag>}
                  {(y.modifiedT1 || y.modifiedT1==0) && (
                    <Tag color={y.modifiedT1 !== y.t1 && "red"}>
                      {y.modifiedT1}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "T2",
              dataIndex: "t2",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y?.t2 || y?.t2 == 0 ) && <Tag>{y?.t2}</Tag>}
                  {(y.holdT2 || y.holdT2 ==0) && (
                    <Tag
                      color={
                        y.holdT2 !== y?.t2 &&
                        "red"
                      }
                    >
                      {y.holdT2}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.t2 || y.t2==0) && <Tag>{y.t2}</Tag>}
                  {(y.modifiedT2 || y.modifiedT2==0) && (
                    <Tag color={y.modifiedT2 !== y.t2 && "red"}>
                      {y.modifiedT2}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },

            {
              title: "T3",
              dataIndex: "t3",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y?.t3 || y?.t3 == 0 ) && <Tag>{y?.t3}</Tag>}
                  {(y.holdT3 || y.holdT3 ==0) && (
                    <Tag
                      color={
                        y.holdT3 !== y?.t3 &&
                        "red"
                      }
                    >
                      {y.holdT3}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.t3 || y.t3==0) && <Tag>{y.t3}</Tag>}
                  {(y.modifiedT3 || y.modifiedT3==0) && (
                    <Tag color={y.modifiedT3 !== y.t3 && "red"}>
                      {y.modifiedT3}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
          ],
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "IPT",
          // width: '180px',
          children: [
            {
              title: "R1",
              dataIndex: "r1",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y?.r1 || y?.r1 == 0 ) && <Tag>{y?.r1}</Tag>}
                  {(y.holdR1 || y.holdR1 ==0) && (
                    <Tag
                      color={
                        y.holdR1 !== y?.r1 &&
                        "red"
                      }
                    >
                      {y.holdR1}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.r1 || y.r1==0) && <Tag>{y.r1}</Tag>}
                  {(y.modifiedR1  || y.modifiedR1 ==0)&& (
                    <Tag color={y.modifiedR1 !== y.r1 && "red"}>
                      {y.modifiedR1}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "R2",
              dataIndex: "r2",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y?.r2 || y?.r2 == 0 ) && <Tag>{y?.r2}</Tag>}
                  {(y.holdR2 || y.holdR2 ==0) && (
                    <Tag
                      color={
                        y.holdR2 !== y?.r2 &&
                        "red"
                      }
                    >
                      {y.holdR2}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.r2 || y.r2==0) && <Tag>{y.r2}</Tag>}
                  {(y.modifiedR2 || y.modifiedR2==0) && (
                    <Tag color={y.modifiedR2 !== y.r2 && "red"}>
                      {y.modifiedR2}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
            {
              title: "R3",
              dataIndex: "r3",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y?.r3 || y?.r3 == 0 ) && <Tag>{y?.r3}</Tag>}
                  {(y.holdR3 || y.holdR3 ==0) && (
                    <Tag
                      color={
                        y.holdR3 !== y?.r3 &&
                        "red"
                      }
                    >
                      {y.holdR3}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.r3|| y.r3==0) && <Tag>{y.r3}</Tag>}
                  {(y.modifiedR3|| y.modifiedR3==0) && (
                    <Tag color={y.modifiedR3 !== y.r3 && "red"}>
                      {y.modifiedR3}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },
          ],
        },
        {
          title: "Payout",
          dataIndex: "Payout",
          // width: '180px',
          children: [
            {
              title: "P1",
              dataIndex: "p1",
              render: (x, y, index) => (
                (y.holdStatus === 9  &&  y.status === 1) ?
                <div>
                  {(y?.p1 || y?.p1 == 0 ) && <Tag>{y?.p1}</Tag>}
                  {(y.holdP1 || y.holdP1 ==0) && (
                    <Tag
                      color={
                        y.holdP1 !== y?.p1 &&
                        "red"
                      }
                    >
                      {y.holdP1}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.p1 || y.p1==0) && <Tag>{y.p1}</Tag>}
                  {(y.modifiedP1 || y.modifiedP1==0) && (
                    <Tag color={y.modifiedP1 !== y.p1 && "red"}>
                      {y.modifiedP1}
                    </Tag>
                  )}
                </div>
              ),
              width: "150px",
            },

            {
              title: "P2",
              dataIndex: "p2",
              width: "150px",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y?.p2 || y?.p2 == 0 ) && <Tag>{y?.p2}</Tag>}
                  {(y.holdP2 || y.holdP2 ==0) && (
                    <Tag
                      color={
                        y.holdP2 !== y?.p2 &&
                        "red"
                      }
                    >
                      {y.holdP2}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.p2 ||y.p2==0) && <Tag>{y.p2}</Tag>}
                  {(y.modifiedP2|| y.modifiedP2==0) && (
                    <Tag color={y.modifiedP2 !== y.p2 && "red"}>
                      {y.modifiedP2}
                    </Tag>
                  )}
                </div>
              ),
            },

            {
              title: "P3",
              dataIndex: "p3",
              width: "150px",
              render: (x, y, index) => (
                (y.holdStatus === 9  ) ?
                <div>
                  {(y?.p3 || y?.p3 == 0 ) && <Tag>{y?.p3}</Tag>}
                  {(y.holdP3 || y.holdP3 ==0) && (
                    <Tag
                      color={
                        y.holdP3 !== y?.p3 &&
                        "red"
                      }
                    >
                      {y.holdP3}
                    </Tag>
                  )}
                </div>:
                <div >
                  {(y.p3 || y.p3==0 )&& <Tag>{y.p3}</Tag>}
                  {(y.modifiedP3 || y.modifiedP3==0) && (
                    <Tag color={y.modifiedP3 !== y.p3 && "red"}>
                      {y.modifiedP3}
                    </Tag>
                  )}
                </div>
              ),
            },
          ],
        },
      ];
    }
    if (schemeType == 4) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          //   sorter: true,
          width: "250px",
        },
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          width: "190px",
          render: (x, y) => (
            (y.holdStatus === 9 ) ?
              <div>
                {(y?.installationTarget || y?.installationTarget == 0 ) && <Tag>{y?.installationTarget}</Tag>}
                {(y.holdInstallationTarget || y.holdInstallationTarget ==0) && (
                  <Tag
                    color={
                      y.holdInstallationTarget !== y?.installationTarget &&
                      "red"
                    }
                  >
                    {y.holdInstallationTarget}
                  </Tag>
                )}
              </div>:
            <div style={{ display: "flex" }}>
              {(y?.installationTarget || y?.installationTarget==0) &&<Tag>{y?.installationTarget}</Tag>}
              {(y?.modifiedInstallationTarget|| y?.modifiedInstallationTarget==0) &&<Tag color={y?.modifiedInstallationTarget !== y?.installationTarget && "red"}>
                {y?.modifiedInstallationTarget}
              </Tag>}

            </div>
          )
        },
        {
          title: "Upper Cap",
          dataIndex: "upperCap",
          width: "120px",
          render: (x, y) => (
            (y.holdStatus === 9 ) ?
            <div>
              {(y?.upperCap || y?.upperCap == 0 ) && <Tag>{y?.upperCap}</Tag>}
              {(y.holdUpperCap || y.holdUpperCap ==0) && (
                <Tag
                  color={
                    y.holdUpperCap !== y?.upperCap &&
                    "red"
                  }
                >
                  {y.holdUpperCap}
                </Tag>
              )}
            </div>:
            <div style={{ display: "flex" }}>
            { (y?.upperCap || y?.upperCap==0) &&<Tag>{y?.upperCap}</Tag>}

             { (y?.modifiedUpperCap || y?.modifiedUpperCap==0) &&<Tag color={y?.modifiedUpperCap !== y?.upperCap && "red"}>
                {y?.modifiedUpperCap}
              </Tag>}

            </div>
          )
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          width: "190px",
          render: (x, y) => (
            (y.holdStatus === 9  ) ?
            <div>
              {(y?.incPerTarget || y?.incPerTarget == 0 ) && <Tag>{y?.incPerTarget}</Tag>}
              {(y.holdIncPerTarget || y.holdIncPerTarget ==0) && (
                <Tag
                  color={
                    y.holdIncPerTarget !== y?.incPerTarget &&
                    "red"
                  }
                >
                  {y.holdIncPerTarget}
                </Tag>
              )}
            </div>:
            <div style={{ display: "flex" }}>
              {(y?.incPerTarget || y?.incPerTarget==0) &&<Tag>{y?.incPerTarget}</Tag>}

              {(y?.modifiedIncPerTarget || y?.modifiedIncPerTarget==0) &&<Tag color={y?.modifiedIncPerTarget !== y?.incPerTarget && "red"}>
                {y?.modifiedIncPerTarget}
              </Tag>}

            </div>
          )
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
          render: (x, y, index) => (
            (y.holdStatus === 9  ) ?
            <div>
              {(y?.p1 || y?.p1 == 0 ) && <Tag>{y?.p1}</Tag>}
              {(y.holdP1 || y.holdP1 ==0) && (
                <Tag
                  color={
                    y.holdP1 !== y?.p1 &&
                    "red"
                  }
                >
                  {y.holdP1}
                </Tag>
              )}
            </div>:
            <div >
              {(y.p1 || y.p1==0) && <Tag>{y.p1}</Tag>}
              {(y.modifiedP1  || y.modifiedP1  ==0)&& (
                <Tag color={y.modifiedP1 !== y.p1 && "red"}>
                  {y.modifiedP1}
                </Tag>
              )}
            </div>
          ),
          
        },
      ];
    }
    if (schemeType == 8) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          //   sorter: true,
          width: "250px",
        },
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          width: "190px",
          render: (x, y) => (
            (y.holdStatus === 9  && y.status ===1) ?
            <div>
              {(y?.installationTarget || y?.installationTarget == 0 ) && <Tag>{y?.installationTarget}</Tag>}
              {(y.holdInstallationTarget || y.holdInstallationTarget ==0) && (
                <Tag
                  color={
                    y.holdInstallationTarget !== y?.installationTarget &&
                    "red"
                  }
                >
                  {y.holdInstallationTarget}
                </Tag>
              )}
            </div>:
            <div style={{ display: "flex" }}>
              {(y?.installationTarget || y?.installationTarget==0)&&<Tag>{y?.installationTarget}</Tag>}

             {(y?.modifiedInstallationTarget || y?.modifiedInstallationTarget==0) &&<Tag color={y?.modifiedInstallationTarget !== y?.installationTarget && "red"}>
                {y?.modifiedInstallationTarget}
              </Tag>}

            </div>
          )
        },

        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          width: "190px",
          render: (x, y) => (
            (y.holdStatus === 9  ) ?
            <div>
              {(y?.incPerTarget || y?.incPerTarget == 0 ) && <Tag>{y?.incPerTarget}</Tag>}
              {(y.holdIncPerTarget || y.holdIncPerTarget ==0) && (
                <Tag
                  color={
                    y.holdIncPerTarget !== y?.incPerTarget &&
                    "red"
                  }
                >
                  {y.holdIncPerTarget}
                </Tag>
              )}
            </div>:
            <div style={{ display: "flex" }}>
              { (y?.incPerTarget || y?.incPerTarget==0) &&<Tag>{y?.incPerTarget}</Tag>}

             { (y?.modifiedIncPerTarget || y?.modifiedIncPerTarget==0) &&<Tag color={y?.modifiedIncPerTarget !== y?.incPerTarget && "red"}>
                {y?.modifiedIncPerTarget}
              </Tag>}

            </div>
          )
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "190px",
          render: (x, y, index) => (
            (y.holdStatus === 9  ) ?
            <div>
              {(y?.p1 || y?.p1 == 0 ) && <Tag>{y?.p1}</Tag>}
              {(y.holdP1 || y.holdP1 ==0) && (
                <Tag
                  color={
                    y.holdP1 !== y?.p1 &&
                    "red"
                  }
                >
                  {y.holdP1}
                </Tag>
              )}
            </div>:
            <div >
              {(y.p1 || y.p1==0) && <Tag>{y.p1}</Tag>}
              {(y.modifiedP1 || y.modifiedP1==0) && (
                <Tag color={y.modifiedP1 !== y.p1 && "red"}>
                  {y.modifiedP1}
                </Tag>
              )}
            </div>
          ),
        },
      ];
    }
    if (schemeType == 5) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          //   sorter: true,
          width: "250px",
        },
        {
          title: "Installation Expected",
          dataIndex: "modifiedInstallationTarget",
          //modifiedInstallationTarget
          width: "200px",

          render: (x, y) => (
            (y.holdStatus === 9 ) ?
            <div>
              {(y?.installationTarget || y?.installationTarget == 0 ) && <Tag>{y?.installationTarget}</Tag>}
              {(y.holdInstallationTarget || y.holdInstallationTarget ==0) && (
                <Tag
                  color={
                    y.holdInstallationTarget !== y?.installationTarget &&
                    "red"
                  }
                >
                  {y.holdInstallationTarget}
                </Tag>
              )}
            </div>:
            <div style={{ display: "flex" }}>
              {(y?.modifiedInstallationTarget || y?.modifiedInstallationTarget==0) && <Tag color={y?.modifiedInstallationTarget !== y?.installationTarget && "red"}>{y?.installationTarget}</Tag>}
              {(y?.modifiedInstallationTarget  || y?.modifiedInstallationTarget ==0)&& (
                <Tag color={y?.modifiedInstallationTarget !== y?.installationTarget && "red"}>
                  {y?.modifiedInstallationTarget}
                </Tag>
              )}
            </div>
          )
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "modifiedIncPerTarget",

          width: "190px",
          render: (x, y) => (
            (y.holdStatus === 9  ) ?
            <div>
              {(y?.incPerTarget || y?.incPerTarget == 0 ) && <Tag>{y?.incPerTarget}</Tag>}
              {(y.holdIncPerTarget || y.holdIncPerTarget ==0) && (
                <Tag
                  color={
                    y.holdIncPerTarget !== y?.incPerTarget &&
                    "red"
                  }
                >
                  {y.holdIncPerTarget}
                </Tag>
              )}
            </div>:
            <div style={{ display: "flex" }}>
              { (y?.incPerTarget || y?.incPerTarget==0 ) &&<Tag color={y?.modifiedIncPerTarget !== y?.incPerTarget && "red"}>{y?.incPerTarget}</Tag>}         
                {(y?.modifiedIncPerTarget || y?.modifiedIncPerTarget==0) && (
                <Tag color={y?.modifiedIncPerTarget !== y?.incPerTarget && "red"}>
                  {y?.modifiedIncPerTarget}
                </Tag>
              )}
            </div>
          )
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
          render: (x, y, index) => (
            (y.holdStatus === 9  ) ?
            <div>
              {(y?.p1 || y?.p1 == 0 ) && <Tag>{y?.p1}</Tag>}
              {(y.holdP1 || y.holdP1 ==0) && (
                <Tag
                  color={
                    y.holdP1 !== y?.p1 &&
                    "red"
                  }
                >
                  {y.holdP1}
                </Tag>
              )}
            </div>:
            <div >
              {(y.p1 || y.p1 ==0) && <Tag>{y.p1}</Tag>}
              {(y.modifiedP1 || y.modifiedP1==0 ) && (
                <Tag color={y.modifiedP1 !== y.p1 && "red"}>
                  {y.modifiedP1}
                </Tag>
              )}
            </div>
          ),
        },
      ];
    }
    return [];
  };
  const getStateHead = () => userRole != "SADM" ? [
    {
      title: (
        <div>
          State <br /> Head
        </div>
      ),
      dataIndex: "stateHeadName",
      width: "180px",
    },
  ] : []

  const getHoldRemarks =(selectedLabel)=> (selectedLabel === 'Hold Schemes')
  ?
  [
    {
      title: "Hold Remarks",
      dataIndex: "holdReason",
      width: "120px",
    }

  ]:[]

  const topTargetShow=(x)=>
(x==1||x==2||x==3)?    [{
      title: (
        <div>
          TOP SLAB
        </div>
      ),
      render: (x, y, index) => (
        (y.holdStatus === 9  ) ?
        <div>
          {(y?.topTarget || y?.topTarget == 0 ) && <Tag>{y?.topTarget}</Tag>}
          {(y.holdTopTarget || y.holdTopTarget ==0) && (
            <Tag
              color={
                y.holdTopTarget !== y?.topTarget &&
                "red"
              }
            >
              {y.holdTopTarget}
            </Tag>
          )}
        </div>:
        <div >
          <Tag>{y.topTarget}</Tag>
          <Tag color={y.modifiedTopTarget !== y.topTarget && "red"}>
            
            {(y?.modifiedTopTarget * y?.modifiedTopTarget) || y?.modifiedTopTarget}
          </Tag>
        </div>
      ),
      dataIndex: "topTarget",
      width: "180px",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"topTarget",
    }]:[]
  const commonColumns = [
    {
      title: (
        <div>
          Scheme <br /> No
        </div>
      ),
      dataIndex: "scheme",
      width: "145px",
      fixed: "left",
      sorter: true,
      key: "SchemeNo",
      render: (_, item) => {

        let color = " ";
        const status = item?.holdStatus==9?item.isModified?1:0:item?.modifiedStatus;
        switch (status) {
          case 1:
            color = "#ffff00";
            break;
          case 2:
            color = "#51DE4E";
            break;
          // case 5:
          //   color = "#FF3232";
          //   break;
          default:
            color = "#51DE4E";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item?.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "dealerCode",
      width: "140px",
      sorter: true,
      defaultSortOrder: "ascend",
      fixed: "left",
      key: "DealerCode",
    },
    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),
      fixed: "left",
      dataIndex: "dealerLocation",
      width: "150px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
      // onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "dealerName",
      sorter: true,
      defaultSortOrder: "ascend",
      width: "160px",
      key: "DealerName",
      // fixed: "left",
    },
    {
      title: "State",
      dataIndex: "stateName",
      sorter: true,
      defaultSortOrder: "ascend",
      width: "100px",
      key: "StateName",
      // fixed: "left",
    },
    ...getStateHead(),
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "territoryManager",
      sorter: true,
      defaultSortOrder: "ascend",
      width: "180px",
      key: "TerritoryManager",
    },   ...topTargetShow(selectedSchemeType),
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "startMonth",
      // sorter: true,
      width: "140px",
      render: (_, item) => dayjs(item.startMonth).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      // sorter: true,
      width: "140px",
      render: (_, item) => dayjs(item.endMonth).format("MMM'YY"),
    },
    ...getCols(selectedSchemeType),
    ...getHoldRemarks(selectedLabel),
    {
      title: "Remarks",
      dataIndex: "reason",
      render: (x, y, index) => (
        (y.holdStatus === 9  ) ?
        <div>
          {(y.reason || y.reason == 0 ) && <Tag>{y.reason}</Tag>}
          {(y.holdRemarks || y.holdRemarks ==0) && (
            <Tag
              color={
                y.holdRemarks !== y.reason &&
                "red"
              }
            >
              <Tooltip title ={y.holdRemarks}>
              {y.holdRemarks}
              </Tooltip>
            </Tag>
          )}
        </div>:
        <div >

          {y?.modifiedReason?.toLowerCase() == y?.reason?.toLowerCase() ? <Tag>
            <Tooltip title={y.reason}>{y.reason}</Tooltip>
          </Tag> : <Tooltip title={y.modifiedReason} >
            <Tag >
              {y.modifiedReason}
            </Tag>
          </Tooltip>}

        </div>
      ),
      width: "300px",
      // ellipsis: true,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "150px",

      render: (x, record) => (
        <Space size="small">
          <Tooltip title="Edit" placement="top" className="infoIcon">
            <EditOutlined
              // type="primary" // Disable the button based on the condition
              onClick={() => handleSingleEdit(x)}
              style={{ fontSize: "20px" }}
            />
          </Tooltip>
          <Tooltip title="Accept" placement="top" className="infoIcon">
            <CheckOutlined
              style={{ color: "#51DE4E", fontSize: "20px" }}
              onClick={() => {
                setModificationId(record?.modifiedId)
                setSchemeId(record?.schemeId);
                handleAcceptModal1()
              }}
            />
          </Tooltip>
          <Tooltip title="Reject" placement="top" className="infoIcon">
            <CloseOutlined
              style={{ color: "#FF3232", fontSize: "20px" }}
              onClick={() => {
                setSchemeNo([...schemeNo, record?.key]);
                handleRejectModal1();
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];






  const scroll =
    selectedSchemeType == 1 || selectedSchemeType == 2
      ? {
        x: 4200,
        y: 1000,
      }
      : {
        x: 2000,
        y: 300,
      };


  return (
    <>
      <div>
        <Table
          style={{
            cursor: "default",
            marginTop: "9px",
          }}


          columns={commonColumns}
          dataSource={dataSource}
          loading={loading}
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys, selectedRow) => {
              setSelectedRowKeys(newSelectedRowKeys);
              setSchemeIdNo(selectedRow);
            },
            getCheckboxProps: (record) => ({
              // Column configuration not to be checked
              name: record.name,
            }),
          }}
          scroll={scroll}
          pagination={false}
        />
      </div>

    </>
  );
};
export default AwaitingApprovalTable;
