import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router";
import { decryptData } from "../utils/localStorageEncodeDecode";

const UnauthenticatedRoute = ({ children }) => {
    const location = useLocation();
    const {userRole,token} = decryptData(sessionStorage.getItem("userInfo")||"")||{};
    // const { isAuthenticated } = useSelector(state => state.user);
    let isAuthenticated=(token&&userRole)?true:false
    if (isAuthenticated) {
        return <Navigate to="/dashboard" state={{ from: location }} replace />
    }
    return children;
}
export default UnauthenticatedRoute;
