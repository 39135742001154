import dayjs from "dayjs";
import { decryptData } from "../../utils/localStorageEncodeDecode";
import { ENV_TIME, ENV_TYPE } from "../../config";


const lastDateOfCurrentMonth = dayjs().endOf('month').format('DD');
// export const expiredSADMTime = 30
export const expiredSADMDate=25
export const expiredAMGRDate=12
export const expiredZADMDate=28
export const expiredTime = dayjs().minute();
export const expiredHrTime = dayjs().hour();

const currentDate = dayjs().date();

// AM-11-12
// SH-12-1
// ZH-3-4
export const getExpiredStatusAMGR = () => {
  const dayjs = require("dayjs");
  const currentTime = dayjs().format("HH:mm:ss");
  const currentHour = parseInt(currentTime.split(":")[0]);

  if (ENV_TIME == "true") {
    if (currentHour == 11) {
      return false;
    } else {
      return ENV_TYPE == "true" ? true : false;
    }
  } else {
    if (currentDate >= 1 && currentDate <= 20) {
      return false;
    } else {
      return ENV_TYPE == "true" ? true : false;
    }
  }
};

export const getExpiredStatusSADM = () => {
  const dayjs = require("dayjs");
  const currentTime = dayjs().format("HH:mm:ss");
  const currentHour = parseInt(currentTime.split(":")[0]);
  let shDate=lastDateOfCurrentMonth-6
  if (ENV_TIME == "true") {
    if (currentHour == 12) {
      return false;
    } else {
      return ENV_TYPE == "true" ? true : false;
    }
  } else {
    //shDate
    if (currentDate >= 11 && currentDate <= shDate) {
      return false;
    } else {
      return ENV_TYPE == "true" ? true : false;
    }
  }
};

export const getExpiredStatusZADM = () => {
  const dayjs = require("dayjs");
  const currentTime = dayjs().format("HH:mm:ss");
  const currentHour = parseInt(currentTime.split(":")[0]);
  let zhDate=lastDateOfCurrentMonth-2

  // ZH E-5 - E-2
  if (ENV_TIME == "true") {
    if (currentHour == 15) {
      return false;
    } else {
      return ENV_TYPE == "true" ? true : false;
    }
  } else {
    if (currentDate >= 20 && currentDate <= 28) {
      return false;
    } else {
      return ENV_TYPE == "true" ? true : false;
    }
  }
};
export const getExpiredStatusHoldZADM = () => {
  const dayjs = require("dayjs");
  const currentTime = dayjs().format("HH:mm:ss");
  const currentHour = parseInt(currentTime.split(":")[0]);
  const currentDate = dayjs().format("D");
  let zhDate=lastDateOfCurrentMonth-6

  // ZH E-5 - E-2
  //zhDate-6
  if (ENV_TIME == "true") {
    if (currentHour == 15) {
      return false;
    } else {
      return ENV_TYPE == "true" ? true : false;
    }
  } else {
    if (zhDate>parseInt(currentDate)) {
      return false;
    } else {
      return ENV_TYPE == "true" ? true : false;
    }
  }
};

export const getExpiredStatusHoldNSH = () => {
  const dayjs = require("dayjs");
  const currentTime = dayjs().format("HH:mm:ss");
  const currentHour = parseInt(currentTime.split(":")[0]);
  const currentDate = dayjs().format("D");
  let zhDate=lastDateOfCurrentMonth-2

  // ZH E-5 - E-2
   //zhDate-2
  if (ENV_TIME == "true") {
    if (currentHour == 15) {
      return false;
    } else {
      return ENV_TYPE == "true" ? true : false;
    }
  } else {
    if (zhDate>parseInt(currentDate)) {
      return false;
    } else {
      return ENV_TYPE == "true" ? true : false;
    }
  }
};