import React, { useEffect, useState, useCallback, memo } from "react";
import { Button, Pagination, Table } from "antd";
import "antd/dist/reset.css";
import { SchemeStatus } from "../../atoms/schemeStatus.jsx";
import FullScreenLoader from "../loader/FullScreenLoader.js";
import "bootstrap/dist/css/bootstrap.min.css";
import ToastifyShow from "../ToastifyShow.js";

const AntdTable = ({
  columns,
  dataSource,
  summary,
  showPagination,
  currentPage,
  handlePaginationChange,
  tableSortingChange,
  totalPaginationPage,
  pageSize = 100,
  showSchemeStatus,
  loading,
  footerVisible,
  scrollDetails,
  headerText,
  selectedRowKeys,
  setSelectedHold,
  tableStyle = "",
  unhold,
  schemeType,
  hideBtn,
  ...rest
}) => {
  const [scrollY, setScrollY] = useState(240);

  const handleResize = useCallback(() => {
    const screenHeight = window.innerHeight;
    const newY = screenHeight * 0.5;
    setScrollY(newY);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <>
      <div
        className={tableStyle ? tableStyle : "p-3 bg-white h-100 shadow-lg"}
        id="hold"
      >
        {headerText}
        <Table
          columns={columns}
          dataSource={dataSource}
          summary={summary}
          loading={{
            spinning: loading,
            indicator: <FullScreenLoader />,
          }}
          className="holdTable"
          pagination={false}
          // className="rounded-0 antdTable"
          size="small"
          onChange={tableSortingChange}
          scroll={{
            x:
              schemeType == 8 || schemeType == 4
                ? 2300
                : schemeType == 5 || schemeType == 3
                ? 2700
                : 3900,
            y: 800,
          }}
          {...rest}
        />
        {!hideBtn && (
          <div className="d-flex flex-row-reverse mt-2 ">
            <Button
              type="primary"
              className="w-auto"
              onClick={() => {
                if (selectedRowKeys?.length == 0 || !selectedRowKeys) {
                  ToastifyShow("Please check the checkbox", "error");
                  return;
                } else {
                  selectedRowKeys?.length != 0 && setSelectedHold(true);
                }
              }}
              // disabled={selectedRowKeys?.length != 0 ? false : true}
            >
              {selectedRowKeys?.length != 0
                ? `${
                    unhold ? "Unhold Selected Scheme" : "Hold Selected Scheme"
                  }`
                : `${unhold ? "Unhold All Scheme" : "Hold All Scheme"}`}
            </Button>
          </div>
        )}
        {showPagination && (
          <div className="pt-3">
            <Pagination
              responsive
              showQuickJumper
              className="d-flex justify-content-end bg-white"
              current={currentPage}
              onChange={handlePaginationChange}
              total={totalPaginationPage}
              showSizeChanger
              showTotal={(total) => `Total ${total} items`}
              pageSize={pageSize}
              defaultPageSize={150}
              pageSizeOptions={["150", "250", "350"]}
            />
          </div>
        )}
        {showSchemeStatus && <SchemeStatus />}
      </div>
    </>
  );
};

export default AntdTable;
