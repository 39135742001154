import ToastifyShow from "../../components/ToastifyShow";
import axiosAPI from "../../services/axiosApi"
import { decryptData } from "../../utils/localStorageEncodeDecode";

export const getModificationCollectionApi = async (data) => {
    try {
     
      const response=axiosAPI.post(`SchemeGenerate/GetAMsApprovedScheme`,data)
        .then((data) => {
         
          return data?.data;
        })
        .catch((error) => {
       
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const getSaveModifiedDashboard = async (data) => {
    try {
      const response=axiosAPI.post(`Dashboard/SaveModifiedDashboardDetails`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  
  export const getUpdateModifiedDataDetails = async (data) => {
    try {
      const response=axiosAPI.post(`UpdateScheme/UpdateModifiedDataDetails`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const getEditDashboardDetail = async (data) => {
    try {
      const response=axiosAPI.post(`Dashboard/EditDashboardDetail`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const getModificationApi = async (data) => {
    try {
      const {userRole}=decryptData(sessionStorage.getItem("userInfo"));
      
let urlView=(userRole=="NSH")?"SchemeGenerate/GetApprovedModifiedSchemesAwaitingApprovalforNSH":"SchemeGenerate/GetApprovedModifiedSchemes"
      const response=axiosAPI.post(urlView,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };



  export const getModificationHoldApi = async (data) => {
    try {
      const {userRole}=decryptData(sessionStorage.getItem("userInfo"));
      let urlView = (userRole === "SADM") 
      ? "HoldScheme/GetAMsApprovedAndHoldSchemeForSH" 
      : "HoldScheme/GetAMsApprovedAndHoldSchemeForZH";
      

      const response=axiosAPI.post(urlView,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const getModificationAcceptApi = async (data) => {
    try {
      const response=axiosAPI.post(`Dashboard/SaveHistoryDataDetails`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
  
  export const getModificationRejectHoldApi = async (data) => {
    try {
      const response=axiosAPI.post(`HoldScheme/RejectHoldScheme`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
  export const getModificationRejectApi = async (data) => {
    try {
      const response=axiosAPI.post(`UpdateScheme/RejectModifiedSchemes`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const getModificationTrackApiSadm = async (data) => {
    try {
      const response=axiosAPI.post(`Modifications/GetTrackModifiedSADM`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
  export const getModificationTrackApiZadm = async (data) => {
    try {
      const response=axiosAPI.post(`Modifications/GetTrackModifiedZADM`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const getModificationTrackApi = async (data) => {
    try {
      const response=axiosAPI.post(`Modifications/GetTrackModifiedNSH`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
  export const getModificationHoldTrackApi = async (data) => {
    try {
      const {userRole}=decryptData(sessionStorage.getItem("userInfo"));
      let urlView = (userRole === "SADM")
      ? "HoldScheme/GetTrackHoldSchemeSADM"
      : (userRole === "ZADM")
      ? "HoldScheme/GetTrackHoldSchemeZADM"
      : "HoldScheme/GetTrackHoldSchemeNSH";
  
      

      const response=axiosAPI.post(urlView,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const getModificationAwaitingHoldApi = async (data) => {
    try {
      const {userRole}=decryptData(sessionStorage.getItem("userInfo"));
     
      let urlView = (userRole === "NSH") 
      ? "HoldScheme/GetApprovedModifiedSchemeByZHAwaitingForNSH" 
      : "HoldScheme/GetApprovedModifiedSchemesBySH_AwaitingForZH";

      const response=axiosAPI.post(urlView,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
