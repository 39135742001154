import React ,{useState,useEffect} from 'react'
import { DownOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  message,
  Space,
  DatePicker,
  Tooltip,

} from "antd";
import { Link } from 'react-router-dom';
import "./awaitingLog.css";
import dayjs from "dayjs";
import { CREATE_SCHEME_ROLES } from "../../../config";
import { decryptData } from '../../../utils/localStorageEncodeDecode';



const AwaitingLogHeader = ({ onChangeFilter,startMonth,setStartMonth, showEnforcedMonth = false, }) => {
    const [endMonth, setEndMonth] = useState(dayjs());
    const [enforcedMonth, setEnforcedMonth] = useState();
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
      const userData = sessionStorage.getItem('userInfo') ? decryptData(sessionStorage.getItem('userInfo')) : {};
      setUserInfo(userData);
    }, []);


    const handleInputChange = (key, date) => {
      switch (key) {
        case 'month': setStartMonth(date); break;
        case 'monthEnd': setEndMonth(date); break;
        case 'enforcedMonth': setEnforcedMonth(date); break;
        default:
          break;
      };
      const month = date ? new Date(date).getMonth() + 1 : 0;
      onChangeFilter(key, month);
    }

    return (
      <div className="All_scheme_inner">
        <Space className="all_Scheme_header">


          {showEnforcedMonth ? (
                      <Tooltip title={"Running Month"}>
            <DatePicker
              onChange={(date) => handleInputChange('enforcedMonth', date)}
              className="start"
              placeholder="Running Month"
              picker="month"
              value={enforcedMonth}
              allowClear
            />
            </Tooltip>
          ) : null}
          <div className="month">
          <Tooltip title={"Start Month"}>
            <DatePicker
              onChange={(date) => handleInputChange('month', date)}
              className="start"
              placeholder="Start Month"
              picker="month"
              value={startMonth}
            //   disabledDate={(current) => {
            //   const endDate = dayjs(endMonth).format("YYYY-MM-DD");
            //   return current && current > dayjs(endDate, "YYYY-MM-DD");
            // }}
              
            />
            </Tooltip>
            <Tooltip title={"End Month"}>
            <DatePicker
              onChange={(date) => handleInputChange('monthEnd', date)}
              className="end"
              placeholder="End Month"
              picker="month"
              value={endMonth}
              disabledDate={(current) => {
              const startDate = dayjs(startMonth).format("YYYY-MM-DD");
              return current && current < dayjs(startDate, "YYYY-MM-DD");
            }}
            />
            </Tooltip>
          </div>
          {CREATE_SCHEME_ROLES.includes(userInfo?.userRole) ? (
            <div className='allscheme_btn_newscheme'>
              <Link to='/collection/createNewScheme'>
                <Button className="create btn-big" icon={<PlusOutlined style={{color:'white'}} />}><span style={{color:'#fff'}}>Create New Scheme</span></Button>
              </Link>
            </div>
          ) : null}

        </Space>
      </div>
    );
}

export default AwaitingLogHeader
