import ToastifyShow from "../../components/ToastifyShow";
import axiosAPI from "../../services/axiosApi";
import { decryptData } from "../../utils/localStorageEncodeDecode";
export const getApprovedSchemeApi = async (data) => {
  try {
    const response = axiosAPI
      .post(`HoldScheme/GetApprovedScheme`, data)
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
        ToastifyShow("Something went wrong", "error");
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};
export const postSaveHoldSchemeDataApi = async (data) => {
  try {
    const response = axiosAPI
      .post(`HoldScheme/SaveHoldSchemeData`, data)
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
        ToastifyShow("Something went wrong", "error");
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};

export const postUpdateHoldSchemeApi = async (data) => {
  try {
    const response = axiosAPI
      .post(`HoldScheme/UpdateHoldScheme`, data)
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
        ToastifyShow("Something went wrong", "error");
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};
export const postUnHoldScheme = async (data) => {
  try {
    const response = axiosAPI
      .post(`HoldScheme/UnHoldScheme`, data)
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
        ToastifyShow("Something went wrong", "error");
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getUnholdSchemeApi = async (data) => {
  const { userRole } = decryptData(sessionStorage.getItem("userInfo")) || {};
  try {
    const response = axiosAPI
      .post(
        userRole == "ZADM"
          ? `HoldScheme/GetholdModifiedSchemes`
          : "HoldScheme/GetholdSchemesforNSH",
        data
      )
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
        ToastifyShow("Something went wrong", "error");
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};
