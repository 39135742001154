import { Button, Table, Tag, Tooltip } from "antd";
import {
  amountCheckIN,
  checkDisableText,
  columnWidth,
  columnWidth120,
  columnWidth150,
  DummyTableInstallationSummary,
  columnWidth80,
  TableSummaryInput,
} from "../widthTable";

import dayjs from "dayjs";
import {
  TagUnholdData,
  validationStatus,
  validationStatus1,
  validationStatus2,
  validationStatus3,
  validationEdit
} from "./holdTableCollectionView";
export const HoldTableInstallationView = (props) => {
  const {
    showCreated,
    showCreatedBy,
    showCreatedDate,
    showRemark,
    selectedSchemeType,
    showTopSlab,
    type,
    handleBtn,
    disableSort,
    showHoldComment,
  } = props;

  const getCreatedName = () =>
    showCreatedBy
      ? [
          {
            title: (
              <div>
                CREATED <br /> BY
              </div>
            ),
            dataIndex: "createdBy",
            ...columnWidth150,
            defaultSortOrder: "ascend",
            key: "createdBy",
            ellipsis: false,
          },
        ]
      : [];
  const deviationAction = () =>
    type == "deviation request"
      ? [
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            ...columnWidth80,
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            render: (currentRecord, showRecord) => (
              <>
                <Button type="primary" onClick={() => handleBtn()}>
                  Hold Scheme
                </Button>
              </>
            ),
          },
        ]
      : [];
  const getCols = (selectedSchemeType) =>
    selectedSchemeType == 3
      ? [
          {
            title: "Targets",
            dataIndex: "TEP",
            textAlign: "center",
            children: [
              {
                title: "T1",
                dataIndex: "t1",

                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "t1",
                ...columnWidth150,
                render: (_, data) => {
                  const simple = data?.t1;
                  const modified = data?.modifiedT1;
                  const history = data?.historyT1;
                  const topSlabDetails = data?.topTarget
                    ?.split(",")
                    ?.includes("t1")
                    ? false
                    : true;
                  return validationStatus(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus3(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus1(data) ? (
                    TagUnholdData(history, simple, topSlabDetails)
                  ) : validationStatus2(data) ? (
                    TagUnholdData(simple, history, topSlabDetails)
                  ) : (
                    <div>{simple}</div>
                  );
                },
              },
              {
                title: "T2",
                dataIndex: "t2",
                ...columnWidth150,

                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "t2",
                render: (_, data) => {
                  const simple = data?.t2;
                  const modified = data?.modifiedT2;
                  const history = data?.historyT2;
                  const topSlabDetails = data?.topTarget
                    ?.split(",")
                    ?.includes("t2")
                    ? false
                    : true;
                  return validationStatus(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus3(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus1(data) ? (
                    TagUnholdData(history, simple, topSlabDetails)
                  ) : validationStatus2(data) ? (
                    TagUnholdData(simple, history, topSlabDetails)
                  ) : (
                    <div>{simple}</div>
                  );
                },
              },
              {
                title: "T3",
                dataIndex: "t3",
                ...columnWidth150,

                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "t3",
                render: (_, data) => {
                  const simple = data?.t3;
                  const modified = data?.modifiedT3;
                  const history = data?.historyT3;
                  const topSlabDetails = data?.topTarget
                    ?.split(",")
                    ?.includes("t3")
                    ? false
                    : true;
                  return validationStatus(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus3(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus1(data) ? (
                    TagUnholdData(history, simple, topSlabDetails)
                  ) : validationStatus2(data) ? (
                    TagUnholdData(simple, history, topSlabDetails)
                  ) : (
                    <div>{simple}</div>
                  );
                },
              },
            ],
          },
          {
            title: "INC. PER TRACTOR",
            children: [
              {
                title: "R1",
                dataIndex: "r1",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "r1",
                ...columnWidth150,
                render: (_, data) => {
                  const simple = data?.r1;
                  const modified = data?.modifiedR1;
                  const history = data?.historyR1;
                  const topSlabDetails = data?.topTarget
                    ?.split(",")
                    ?.includes("t1")
                    ? false
                    : true;
                  return validationStatus(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus3(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus1(data) ? (
                    TagUnholdData(history, simple, topSlabDetails)
                  ) : validationStatus2(data) ? (
                    TagUnholdData(simple, history, topSlabDetails)
                  ) : (
                    <div>{simple}</div>
                  );
                },
              },
              {
                title: "R2",
                dataIndex: "r2",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "r2",
                ...columnWidth150,
                render: (_, data) => {
                  const simple = data?.r2;
                  const modified = data?.modifiedR2;
                  const history = data?.historyR2;
                  const topSlabDetails = data?.topTarget
                    ?.split(",")
                    ?.includes("t2")
                    ? false
                    : true;
                  return validationStatus(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus3(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus1(data) ? (
                    TagUnholdData(history, simple, topSlabDetails)
                  ) : validationStatus2(data) ? (
                    TagUnholdData(simple, history, topSlabDetails)
                  ) : (
                    <div>{simple}</div>
                  );
                },
              },
              {
                title: "R3",
                dataIndex: "r3",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "r3",
                ...columnWidth150,
                render: (_, data) => {
                  const simple = data?.r3;
                  const modified = data?.modifiedR3;
                  const history = data?.historyR3;
                  const topSlabDetails = data?.topTarget
                    ?.split(",")
                    ?.includes("t3")
                    ? false
                    : true;
                  return validationStatus(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus3(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus1(data) ? (
                    TagUnholdData(history, simple, topSlabDetails)
                  ) : validationStatus2(data) ? (
                    TagUnholdData(simple, history, topSlabDetails)
                  ) : (
                    <div>{simple}</div>
                  );
                },
              },
            ],
          },
          {
            title: "PAYOUT RATE",
            children: [
              {
                title: "P1",
                dataIndex: "p1",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "p1",
                ...columnWidth150,
                render: (_, data) => {
                  const simple = data?.p1;
                  const modified = data?.modifiedP1;
                  const history = data?.historyP1;
                  const topSlabDetails = data?.topTarget
                    ?.split(",")
                    ?.includes("t1")
                    ? false
                    : true;
                  return validationStatus(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus3(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus1(data) ? (
                    TagUnholdData(history, simple, topSlabDetails)
                  ) : validationStatus2(data) ? (
                    TagUnholdData(simple, history, topSlabDetails)
                  ) : (
                    <div>{simple}</div>
                  );
                },
              },
              {
                title: "P2",
                dataIndex: "p2",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },

                key: "p2",
                ...columnWidth150,
                render: (_, data) => {
                  const simple = data?.p2;
                  const modified = data?.modifiedP2;
                  const history = data?.historyP2;
                  const topSlabDetails = data?.topTarget
                    ?.split(",")
                    ?.includes("t2")
                    ? false
                    : true;
                  return validationStatus(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus3(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus1(data) ? (
                    TagUnholdData(history, simple, topSlabDetails)
                  ) : validationStatus2(data) ? (
                    TagUnholdData(simple, history, topSlabDetails)
                  ) : (
                    <div>{simple}</div>
                  );
                },
              },
              {
                title: "P3",
                dataIndex: "p3",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },

                key: "p3",
                ...columnWidth150,
                render: (_, data) => {
                  const simple = data?.p3;
                  const modified = data?.modifiedP3;
                  const history = data?.historyP3;
                  const topSlabDetails = data?.topTarget
                    ?.split(",")
                    ?.includes("t3")
                    ? false
                    : true;
                  return validationStatus(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus3(data) ? (
                    TagUnholdData(simple, modified, topSlabDetails)
                  ) : validationStatus1(data) ? (
                    TagUnholdData(history, simple, topSlabDetails)
                  ) : validationStatus2(data) ? (
                    TagUnholdData(simple, history, topSlabDetails)
                  ) : (
                    <div>{simple}</div>
                  );
                },
              },
            ],
          },
        ]
      : selectedSchemeType == 8
      ? [
          {
            title: "INSTALLATION TARGET",
            dataIndex: "installationTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "installationTarget",
            ...columnWidth80,
            render: (_, data) => {
              const simple = data?.installationTarget;
              const modified = data?.modifiedInstallationTarget;
              const history = data?.historyInstallationTarget;

              return validationStatus(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus3(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus1(data) ? (
                TagUnholdData(history, simple)
              ) : validationStatus2(data) ? (
                TagUnholdData(simple, history)
              ) : (
                <div>{simple}</div>
              );
            },
          },
          {
            title: "INC. PER TRACTOR",
            dataIndex: "incPerTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "incPerTarget",
            ...columnWidth80,
            render: (_, data) => {
              const simple = data?.incPerTarget;
              const modified = data?.modifiedIncPerTarget;
              const history = data?.historyIncPerTarget;

              return validationStatus(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus3(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus1(data) ? (
                TagUnholdData(history, simple)
              ) : validationStatus2(data) ? (
                TagUnholdData(simple, history)
              ) : (
                <div>{simple}</div>
              );
            },
          },
          {
            title: "PAYOUT",
            dataIndex: "p1",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "p1",
            ...columnWidth80,
            render: (_, data) => {
              const simple = data?.p1;
              const modified = data?.modifiedP1;
              const history = data?.historyP1;

              return validationStatus(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus3(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus1(data) ? (
                TagUnholdData(history, simple)
              ) : validationStatus2(data) ? (
                TagUnholdData(simple, history)
              ) : (
                <div>{simple}</div>
              );
            },
          },
        ]
      : selectedSchemeType == 4
      ? [
          {
            title: "INSTALLATION TARGET",
            dataIndex: "installationTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "installationTarget",
            ...columnWidth80,
            render: (_, data) => {
              const simple = data?.installationTarget;
              const modified = data?.modifiedInstallationTarget;
              const history = data?.historyInstallationTarget;

              return validationStatus(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus3(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus1(data) ? (
                TagUnholdData(history, simple)
              ) : validationStatus2(data) ? (
                TagUnholdData(simple, history)
              ) : (
                <div>{simple}</div>
              );
            },
          },
          {
            title: "UPPER CAP",
            dataIndex: "upperCap",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "upperCap",
            ...columnWidth80,
            render: (_, data) => {
              const simple = data?.upperCap;
              const modified = data?.modifiedUpperCap;
              const history = data?.historyUpperCap;

              return validationStatus(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus3(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus1(data) ? (
                TagUnholdData(history, simple)
              ) : validationStatus2(data) ? (
                TagUnholdData(simple, history)
              ) : (
                <div>{simple}</div>
              );
            },
          },
          {
            title: "INC. PER TRACTOR",
            dataIndex: "incPerTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "incPerTarget",
            ...columnWidth80,
            render: (_, data) => {
              const simple = data?.incPerTarget;
              const modified = data?.modifiedIncPerTarget;
              const history = data?.historyIncPerTarget;

              return validationStatus(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus3(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus1(data) ? (
                TagUnholdData(history, simple)
              ) : validationStatus2(data) ? (
                TagUnholdData(simple, history)
              ) : (
                <div>{simple}</div>
              );
            },
          },
          {
            title: "PAYOUT",
            dataIndex: "p1",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "p1",
            ...columnWidth80,
            render: (_, data) => {
              const simple = data?.p1;
              const modified = data?.modifiedP1;
              const history = data?.historyP1;

              return validationStatus(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus3(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus1(data) ? (
                TagUnholdData(history, simple)
              ) : validationStatus2(data) ? (
                TagUnholdData(simple, history)
              ) : (
                <div>{simple}</div>
              );
            },
          },
        ]
      : selectedSchemeType == 5
      ? [
          {
            title: "INSTALLATION EXPECTED",
            dataIndex: "installationTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "installationTarget",
            ...columnWidth80,
            render: (_, data) => {
              const simple = data?.installationTarget;
              const modified = data?.modifiedInstallationTarget;
              const history = data?.historyInstallationTarget;

              return validationStatus(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus3(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus1(data) ? (
                TagUnholdData(history, simple)
              ) : validationStatus2(data) ? (
                TagUnholdData(simple, history)
              ) : (
                <div>{simple}</div>
              );
            },
          },
          {
            title: "INC. PER TRACTOR",
            dataIndex: "incPerTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "incPerTarget",
            ...columnWidth80,
            render: (_, data) => {
              const simple = data?.incPerTarget;
              const modified = data?.modifiedIncPerTarget;
              const history = data?.historyIncPerTarget;

              return validationStatus(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus3(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus1(data) ? (
                TagUnholdData(history, simple)
              ) : validationStatus2(data) ? (
                TagUnholdData(simple, history)
              ) : (
                <div>{simple}</div>
              );
            },
          },
          {
            title: "PAYOUT",
            dataIndex: "p1",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "p1",
            ...columnWidth80,
            render: (_, data) => {
              const simple = data?.p1;
              const modified = data?.modifiedP1;
              const history = data?.historyP1;

              return validationStatus(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus3(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus1(data) ? (
                TagUnholdData(history, simple)
              ) : validationStatus2(data) ? (
                TagUnholdData(simple, history)
              ) : (
                <div>{simple}</div>
              );
            },
          },
        ]
      : [];
  const getCreatedDate = () =>
    showCreatedDate
      ? [
          {
            title: (
              <div>
                Created <br />
                Date
              </div>
            ),
            dataIndex: "createdDate",
            ...columnWidth80,
            sorter: disableSort ? false : true,
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "createdDate",
            render: (data) => <Tooltip title={data}>{data}</Tooltip>,
          },
        ]
      : [];
  const getTopSlab = () =>
    showTopSlab || selectedSchemeType == 3
      ? [
          {
            title: (
              <div>
                TOP <br /> SLAB
              </div>
            ),
            dataIndex: "topTarget",
            ...columnWidth80,
            defaultSortOrder: "ascend",
            key: "topTarget",
            render: (_, data) => {
              const simple =
                data?.topTarget
                  ?.split(",")
                  [data?.topTarget?.split(",")?.length - 1].toUpperCase() ||
                "t1".toUpperCase();
              const modified =
                data?.modifiedTopTarget
                  ?.split(",")
                  [
                    data?.modifiedTopTarget?.split(",")?.length - 1
                  ].toUpperCase() || "t1".toUpperCase();
              const history =
                data?.historyTopTarget
                  ?.split(",")
                  [
                    data?.historyTopTarget?.split(",")?.length - 1
                  ].toUpperCase() || "t1".toUpperCase();
              return validationStatus(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus3(data) ? (
                TagUnholdData(simple, modified)
              ) : validationStatus1(data) ? (
                TagUnholdData(history, simple)
              ) : validationStatus2(data) ? (
                TagUnholdData(simple, history)
              ) : (
                <div>{simple}</div>
              );
            },
          },
        ]
      : [];
  const getRemarks = () =>
    showHoldComment
      ? [
          {
            title: "",
            children: [
              {
                title: "REMARKS",
                align: "center",
                dataIndex: "reason",
                ellipsis: {
                  showTitle: false,
                },
                // render: (data) => <Tooltip title={data}>{data}</Tooltip>,
                render: (_, data) => {
                  const simple = data?.reason;
                  const modified = data?.modifiedReason;
                  const history = data?.historyReason;
                  return validationEdit(data) ? (
                    <div>{history}</div>
                  ) :<div>{simple}</div>
                },
                ...columnWidth,
              },
              {
                title: "MODIFIED REMARKS",
                dataIndex: "modifiedRemarks",
                key: "modifiedRemarks",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                shouldCellUpdate: (record, prevRecord) =>
                  record.modifiedRemarks !== prevRecord.modifiedRemarks,
                render: (data) => <Tooltip title={data}>{data || ""}</Tooltip>,
                ...columnWidth,
              },
              {
                title: "HOLD REASON",
                dataIndex: "modifiedReason",
                key: "modifiedReason",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                shouldCellUpdate: (record, prevRecord) =>
                  record.modifiedReason !== prevRecord.modifiedReason,
                render: (data) => <Tooltip title={data}>{data || ""}</Tooltip>,
                ...columnWidth,
              },
            ],
          },
        ]
      : showRemark
      ? [
          {
            title: "REMARKS",
            align: "center",
            dataIndex: "reason",
            ellipsis: {
              showTitle: false,
            },
            // render: (data) => <Tooltip title={data}>{data}</Tooltip>,
            render: (_, data) => {
              const simple = data?.reason;
              const modified = data?.modifiedReason;
              const history = data?.historyReason;
              return validationEdit(data) ? (
                <div> <Tooltip title={history}> {history}</Tooltip></div>
              ) :<div>{simple}</div>
            },
            ...columnWidth,
          },
        ]
      : [];
  return [
    {
      title: (
        <div>
          SCHEME <br /> NO
        </div>
      ),
      dataIndex: "schemeNo",
      fixed: "left",
      sorter: disableSort ? false : true,
      ...columnWidth,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "descend",
      key: "SchemeNo",
      render: (item,record) => {

        let color = " ";
        if(record?.isEditonHold ==false ){
          color = "#9999ff"
        }else{
          switch (record.status) {
            case 0:
              color = "#FCE838"; //inProgress
              break;
            case 1:
              color = "#FCE838"; //inProgress
              break;
            case 2:
              color = "#51DE4E"; //Approved
              break;
            case 3:
              color = "#9999ff"; //Withdraw
              break;
            case 4:
              color = "#FF3232"; //Cancel
              break;
            case 5:
              color = "#FF3232"; //Reject
              break;
            case 7:
              color = "#bcb38d"; //Deviation
              break;
            case 8:
              color = "rgb(255, 0, 255)"; //Auto Reject
              break;
              case 9:
                color = "#9999ff"; //hold
                break;
            default:
              color = "#FCE838";
              break;
          }
        }
       
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item}
          </Tag>
        );
      },
    },
    {
      title: (
        <div>
          DEALER <br /> CODE
        </div>
      ),
      dataIndex: "dealerCode",
      fixed: "left",
      sorter: disableSort ? false : true,
      ...columnWidth80,
      align: "center",
      defaultSortOrder: "descend",
      key: "DealerCode",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: (
        <div>
          DEALER <br /> LOCATION
        </div>
      ),
      dataIndex: "dealerLocation",
      ...columnWidth80,
      fixed: "left",
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "descend",
      key: "DealerLocation",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: (
        <div>
          DEALER <br /> NAME
        </div>
      ),
      dataIndex: "dealerName",
      ...columnWidth80,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "DealerName",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: "STATE",
      dataIndex: "stateName",
      ...columnWidth80,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "StateName",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    ...getCreatedDate(),
    {
      title: (
        <div>
          TERRITORY <br /> MANAGER
        </div>
      ),
      dataIndex: "territoryManager",
      ...columnWidth80,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "TerritoryManager",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    ...getTopSlab(),
    ...getCreatedName(),
    {
      title: (
        <div>
          START <br /> MONTH
        </div>
      ),
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "schemePlanStartMonth",
      dataIndex: "schemePlanStartMonth",
      ...columnWidth80,
      render: (data) => dayjs(data).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "schemePlanEndMonth",
      dataIndex: "schemePlanEndMonth",
      ...columnWidth80,
      render: (data) => dayjs(data).format("MMM'YY"),
    },
    {
      title: (
        <div>
          Actual Installation
          <br />
          Last Year Month
        </div>
      ),
      dataIndex: "actual_Installation_Last_Year_Month",
      ...columnWidth80,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "actual_Installation_Last_Year_Month",

      render: (data) => amountCheckIN(data),
    },

    ...getCols(selectedSchemeType),
    ...getRemarks(),
    ...deviationAction(),
  ];
};
