import ToastifyShow from "../../components/ToastifyShow";
import axiosAPI from "../../services/axiosApi"

  export const getSchemesForWithdrawnApi = async (data) => {
    try {
      const response=axiosAPI.post(`WithdrawnScheme/GetSchemesforWithdrawn`,{})
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const postSaveWithdrawanSchemeApi = async (data) => {
    try {
      const response=axiosAPI.post(`withdrawnscheme/savewithdrawalscheme`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const getWithdrawnSchemesListApi = async (data) => {
    try {
      const response=axiosAPI.post(`WithdrawnScheme/Getwithdrawnschemeslist`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const getSchemeIdDetails = async (data) => {

    try {
      const response=axiosAPI.post(`WithdrawnScheme/GetSchemedatabySchemeId?EncryptedText=${data?.replaceAll('+', '-')?.replaceAll('_','/')}`,{})
        .then((data) => {

          return data?.data;
        })
        .catch((error) => {
          ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };