import React ,{useEffect,useState}from "react";
import "./rolemodal.css";
import { Collapse } from "antd";
import CheckBox from "./CheckBox";
import axiosAPI from '../../../services/axiosApi';
import { decryptData } from "../../../utils/localStorageEncodeDecode";


const { Panel } = Collapse;

const CollapseCheckboxModal = () => {
  const [stateAmgr, setStateAmgr] = useState([]);
  const [dealer, setDealer] = useState({});
  const {userId,userRole} = sessionStorage.getItem("userInfo") ? decryptData(sessionStorage.getItem("userInfo")) : {};

  const getDealerDropdownData = (userId) => {
    axiosAPI
      .post(
        `SchemeGenerate/GetSchemeList`,
        { userId:userId  }
      )
      .then((result) => {
        setDealer(dealer => ({
          ...dealer,
          [userId]: result.data.data
        }))
        // const data = formatData(result.data.data);
        // dispatch(schemeList(data));
      });
  };

  useEffect(() => {
    const getAmDropdownData=()=>{
      if(userRole == 'SADM'){
        axiosAPI
          .post(`SchemeGenerate/GetDropdownAM`, { userId:userId })
          .then((result) => {
            setStateAmgr(result.data.data)
          });
      }
    }
    getAmDropdownData();
  }, [])

  return (
    <div>
      {userRole == 'SADM' && stateAmgr?.map((item,key) => (
        <Collapse
          expandIconPosition="right"
          size="small"
          defaultActiveKey={["1"]}
          onChange={() => getDealerDropdownData(item.id)}
        >
          <Panel header={item.am} key={key}>
            <CheckBox dealer={dealer[item.id]} />
          </Panel>

        </Collapse>
      ))}
    </div>
  )
}

export default CollapseCheckboxModal
