import { Navigate } from 'react-router-dom';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const getSchemeSubmittedList = createSlice({
  name: "schemeSubmitted",
  initialState: {
    modalName:""
  },
  reducers: {
    schemeSubmitted: (state, action) => {
        state.data = action.payload;
    },
    modalUpdated: (state, action) => {
        state.modalName = action.payload;
    },
 
  },
});

export const { schemeSubmitted,modalUpdated} = getSchemeSubmittedList.actions;
export const schemeSubmittedList = (state)=> state.schemeSubmitted.data;

export default getSchemeSubmittedList.reducer;
