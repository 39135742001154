import React from 'react';
import CommonModal from "./CommonModal";
import { formatUnderscore } from "../../../utils/formatter";

const ValidationErrorsModal = ({ open, onClose, errorsFields,SchemeID }) => {

  return (
    <CommonModal open={open} onCancel={() => onClose()}>
      <p className="custom-warning">Warning !</p>
      {SchemeID?<p>Please fill the required fields in Dealer Code {SchemeID}</p>:
      <p>Please fill the following required fields.</p>}
      
      <p className="error-list-p">
        {errorsFields?.length ? (
          <ul>
            {errorsFields?.map((errFieldName, index) => (
              <li key={index}>{formatUnderscore(errFieldName)}</li>
            ))}
          </ul>
        ) : null}
      </p>
    </CommonModal>
  );
};

export default ValidationErrorsModal;