import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPage from "./../assets/ErrorIcon.svg"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  refreshPage = () => {
    window.location.reload();
  };
  loginPage = () => {
    localStorage.clear()
    window.location.reload();
  };
  render() {
    if (this.state.hasError) {
      return (
        <div className='w-100 vh-100 text-center d-flex flex-column justify-content-center align-items-center'>
       

          <div className='d-flex align-items-center'style={{
            justifyContent: "center"
          }}>
          <img src={ErrorPage} alt="err " height={"500px"} width={"500px"}/>
          </div>
          <div className='px-3'>
          <h2>Something went wrong.</h2>
          <p>Please refresh the page to try again.</p>
          </div>
          <div className='px-1'>
          <button onClick={this.refreshPage} className='btn my-1'>Refresh</button>
          <button onClick={this.loginPage} className='btn'>Go back to login Page</button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
