import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const getAMGRSchemeList = createSlice({
  name: "mngrSchemeList",
  initialState: {
    data: [],
  },
  reducers: {
    areaMngrData: (state, action) => {
      state.data = action.payload;
    },
  },
});
export const { areaMngrData } = getAMGRSchemeList.actions;
// export const areaManagerList = (state) => state.mngrSchemeList.data;
export default getAMGRSchemeList.reducer;
