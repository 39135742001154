import { ReactComponent as MergeIcon } from "../../../assets/vector/merge.svg";
import { ReactComponent as UnmergeIcon } from "../../../assets/vector/unmerge.svg";
import React, {
  Table,
  Tag,
  Space,
  Row,
  Col,
  Button,
  message,
  Pagination,
  Tooltip,
} from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generatedScheme } from "../../../redux/collection/AllGeneratedScheme";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import XLSX from "xlsx-js-style";
import timezone from "dayjs/plugin/timezone";
import MergeModal from "../../../components/modal/alertModal/ConfirmModal";
import "./allScheme.css";
import mergeScheme from "../../../utils/mergeUnmerge/mergeScheme";
import unMergeScheme from "../../../utils/mergeUnmerge/unMergeScheme";
import axiosAPI from "../../../services/axiosApi";
import {
  getCurrentFiscalYear,
  getCurrentFiscalYear1,
} from "../../../utils/dateFormatter";
import ToastifyShow, { TextLocalString } from "../../../components/ToastifyShow";
import { ReactComponent as Download } from "../../../assets/vector/download.svg";
import { ConstructionOutlined } from "@mui/icons-material";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import { formatNumberDecimal } from "../../../utils/formatter";
import { render } from "@testing-library/react";
import {
  getExpiredStatusAMGR,
  getExpiredStatusSADM,
  getExpiredStatusZADM,
} from "../../../components/header/schemeExpiredDetails";
import { columnWidth } from "../../../components/widthTable";

dayjs.extend(utc);
dayjs.extend(timezone);

const InitAllSchemeData = {
  currentYear: dayjs().year(),
  // collectionType: 'Collection',
  // schemeType: 1,
  // dealerCode: [],
  month: 0,
  monthEnd: 0,
  pageSize: 150,
  pageNumber: 1,
  sortingOrder: "ascend",
  sortingType: "DealerCode",
};
const currentFiscalYear = getCurrentFiscalYear();

const AllSchemeTable = ({
  payload,
  getSelectionCheck,
  selectedStatus,
  reference,
  setEnable,
  enable,
  selectedSchemeType,
  startMonth,
  dealerDetails,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [disable, setDisable] = useState(false);
  const { userRole, userId, userName } =
    decryptData(sessionStorage.getItem("userInfo")) || {};

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [schemeIdNo, setSchemeIdNo] = useState([]);
  const [openMergeModal, setOpenMergeModal] = useState(false);
  const [openUnMergeModal, setOpenUnMergeModal] = useState(false);
  const [openSchemeModal, setOpenSchemeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(true);
  const [collectionData, setCollectionData] = useState(InitAllSchemeData);
  const { schemeType, startDate, endDate, currentYear, pageNumber, pageSize } =
    collectionData;
  const [messageApi, contextHolder] = message.useMessage();

  const listData = useSelector((state) => state.generatedScheme.data);

  const handleChange = (pagination, filters, sorter) => {
    setCollectionData({
      ...collectionData,
      sortingOrder: sorter?.order,
      sortingType: sorter?.columnKey,
    });
  };
  const handleSingleEdit = (record) => {
    if (record) {
      navigate("/collection/allSchemes/edit", {
        state: [record],
        pageType: "All",
      });
    } else {
      messageApi.open({
        type: "warning",
        content: "Please select the checkbox !",
        duration: 2,
      });
    }
  };
  const convertUtcToLocal = (utcDate) => {
    return utcDate;
    // const localDate = new Date(utcDate);
    // return localDate.toLocaleString(""); // Convert to local date and time string
  };
  let dayExpired =
    userRole == "SADM"
      ? getExpiredStatusSADM()
      : userRole == "AMGR"
      ? getExpiredStatusAMGR()
      : getExpiredStatusZADM();

  const columns = [
    {
      title: (
        <div>
          Scheme <br /> No
        </div>
      ),
      dataIndex: "scheme",
      width: "160px",
      fixed: "left",
      key: "SchemeNo",
      sorter: true,
      defaultSortOrder: "ascend",
      //  sorter: (a, b) => a.name.length - b.name.length,
      render: (item) => {
        let color = " ",
          border = false;
        // if(item.dealerCode != null) {
        //   const dealreCode = item.dealerCode.split(',');
        //   const objCopy = {...item}; // 👈️ create copy
        //   if(dealreCode.length > 1) {
        //     objCopy.schemeType = 'colorCode';
        //   }

        //   if(objCopy.schemeType === 'colorCode'){
        //      border = true
        //  }
        // }
        // if(item.schemeType === 'colorCode'){
        // }
        const dataItem = item?.holdStatus == 9 ? item?.holdStatus : item.status;
        switch (dataItem) {
          case 0:
            color = "#FCE838"; //inProgress
            break;
          case 1:
            color = "#FCE838"; //inProgress
            break;
          case 2:
            color = "#51DE4E"; //Approved
            break;
          case 3:
            color = "#9999ff"; //Withdraw
            break;
          case 4:
            color = "#FF3232"; //Cancel
            break;
          case 5:
            color = "#FF3232"; //Reject
            break;
          case 7:
            color = "#bcb38d"; //Deviation
            break;
          case 8:
            color = "rgb(255, 0, 255)"; //Auto Reject
            break;
          case 9:
            color = "#9999ff"; //hold
            break;
          default:
            color = "#FCE838";
            break;
        }

        // switch (item.schemeType != null) {
        //   case 0:
        //     color = "#FFB6C1";
        //     break;
        // }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            bordered={border}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        );
      },
      // defaultSortOrder: "ascend",
      //   sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      key: "DealerCode",
      dataIndex: "code",
      fixed: "left",
      width: "160px",
      sorter: true,
      defaultSortOrder: "ascend",
    },
    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),
      key: "DealerLocation",
      dataIndex: "Location",
      fixed: "left",
      width: "190px",
      defaultSortOrder: "ascend",
      sorter: true,
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      key: "DealerName",
      dataIndex: "name",
      // fixed: "left",
      width: "160px",
      sorter: true,
      defaultSortOrder: "ascend",
    },
    {
      title: (
        <div>
          Created <br /> Date
        </div>
      ),
      // fixed: "left",
      dataIndex: "createdDate",
      defaultSortOrder: "ascend",
      width: "160px",
      //   sorter: (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
      sortDirections: ["descend", "ascend"],
      render: (createdDate) => convertUtcToLocal(createdDate),
    },
    {
      title: "State",
      dataIndex: "state",
      width: "110px",
      key: "StateName",
      sorter: true,
      defaultSortOrder: "ascend",
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      key: "TerritoryManager",
      dataIndex: "Manager",
      width: "190px",
      sorter: true,
      defaultSortOrder: "ascend",
    },
    {
      title: "Top Slab",
      width: "150px",
      render: (x, y, index) => y?.scheme?.topTarget?.toUpperCase(),
    },
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "StartMonth",
      width: "160px",
      // defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "EndMonth",
      width: "160px",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Industry",
      dataIndex: "industry",
      render:(x)=><div>{TextLocalString(x)|| 0}</div>
      // defaultSortOrder: "null",
      //   sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "B",
      dataIndex: "oldBillingQty",
      width: "110px",
      render:(x)=><div>{TextLocalString(x)|| 0}</div>
      // defaultSortOrder: "descend",
      //   sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "I",
      dataIndex: "oldInstallation",
      width: "110px",
      render:(x)=><div>{TextLocalString(x)|| 0}</div>
      // defaultSortOrder: "descend",
      //    sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "C",
      dataIndex: "oldCollection",
      width: "110px",
      render:(x)=><div>{TextLocalString(x)|| 0}</div>
      // defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: (
        <div>
          Opening <br /> Stock
        </div>
      ),
      dataIndex: "stock",
      children: [
        {
          title: "Nos",
          dataIndex: "oldOpeningStock",
          render:(x)=><div>{TextLocalString(x)|| 0}</div>
        },
        {
          title: "Days",
          dataIndex: "openingStockDays",
          render:(x)=><div>{TextLocalString(x)|| 0}</div>
        },
      ],
    },
    {
      title: "Closing Os. (In Lacs)",
      dataIndex: "stock",
      children: [
        {
title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
          dataIndex: "lastThirdMonth",
          // defaultSortOrder: "descend",
          width: "190px",
          render:(x)=><div>{TextLocalString(x)|| 0}</div>
          //   sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          title: (
            <span>
              Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
            </span>
          ),
          
          dataIndex: "lastTwoMonth",
          width: "190px",
          render:(x)=><div>{TextLocalString(x)|| 0}</div>
        },
        {
          title: (
            <span>
              Last Month
            </span>
          ),
          dataIndex: "lastOneMonth",
          width: "190px",
          render:(x)=><div>{TextLocalString(x)|| 0}</div>

          // defaultSortOrder: "descend",
          //   sorter: (a, b) => a.name.length - b.name.length,
        },
        // {
        //   title: "Lacs",
        //   dataIndex: "lac",
        //   // defaultSortOrder: "descend",
        //   sorter: (a, b) => a.name.length - b.name.length,
        // },
        // {
        //   title: (
        //     <span>
        //       Last <span>1<sup>st</sup></span> Month Days
        //     </span>
        //   ),
        //   dataIndex: "outStandingDays",
        //   width: "200px",
        //   // defaultSortOrder: "descend",
        //   //  sorter: (a, b) => a.name.length - b.name.length,
        // },
      ],
    },
    {
      title: "Closing OS Days",
      dataIndex: "outStandingDays",
      children: [
        {
          title: "OS Last Month",
          dataIndex: "outStandingDays",
        },
      ],
    },
    {
      title: "BG",
      dataIndex: "bg",
      // defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,
      children: [
        {
          title: "Lacs",
          dataIndex: "bgLacsCurrentYear",
          // defaultSortOrder: "descend",
          //  sorter: (a, b) => a.name.length - b.name.length,
        },
      ],
    },
    {
      title: (
        <div>
          Installation <br /> Plan
        </div>
      ),
      dataIndex: "IP",
      width: "190px",

      // defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,
      children: [
        {
          title: "Nos",
          dataIndex: "delayPlan",
          width: "200px",
          render:(x)=><div>{TextLocalString(x)|| 0}</div>
          // defaultSortOrder: "descend",
          //  sorter: (a, b) => a.name.length - b.name.length,
        },
      ],
    },
    {
      title: (
        <div>
          Billing <br /> Details
        </div>
      ),
      dataIndex: "plan",
      // defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,
      children: [
        {
          title: "B",
          dataIndex: "b",
          render:(x)=><div>{TextLocalString(x)|| 0}</div>
          // defaultSortOrder: "descend",
          //  sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          title: "I",
          dataIndex: "i",
          render:(x)=><div>{TextLocalString(x)}</div>
          // defaultSortOrder: "descend",
          //   sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          title: "C",
          dataIndex: "c",
          render:(x)=><div>{TextLocalString(x)}</div>
          // defaultSortOrder: "descend",
          // sorter: (a, b) => a.name.length - b.name.length,
        },
      ],
    },
    {
      title: (
        <div>
          Closing <br /> Stock
        </div>
      ),
      dataIndex: "closingStock",
      width: "80px",
      render:(x)=><div>{TextLocalString(x)}</div>
    },
    {
      title: (
        <div>
          Closing <br /> OutStanding (In Lacs)
        </div>
      ),
      dataIndex: "closingOutStanding",
      width: "110px",
      render:(x)=><div>{TextLocalString(x)}</div>
    },
    {
      title: "Books Collection Targets (No. of Trs.)",
      dataIndex: "BooksCollectionTarget",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.name.length - b.name.length,
      children: [
        {
          title: "T1",
          dataIndex: "BooksCollectionTargetT1",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t1") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
              <div>{x}</div>
            );
          },
          // defaultSortOrder: "descend",
          //  sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          title: "T2",
          dataIndex: "BooksCollectionTargetT2",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t2") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
              <div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "T2-T1",
          dataIndex: "BooksCollectionTargetT2T1",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t2") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
              <div>{TextLocalString(x)}</div>
            );
          },
          // defaultSortOrder: "descend",
          //  sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          title: "T3",
          dataIndex: "BooksCollectionTargetT3",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t3") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
              <div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "T3-T2",
          dataIndex: "BooksCollectionTargetT3T2",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t3") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
              <div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "T4",
          dataIndex: "BooksCollectionTargetT4",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t4") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
              <div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "T4-T3",
          dataIndex: "BooksCollectionTargetT4T3",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t4") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "T5",
          dataIndex: "BooksCollectionTargetT5",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t5") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "T5-T4",
          dataIndex: "BooksCollectionTargetT5T4",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t5") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "T6",
          dataIndex: "BooksCollectionTargetT6",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t6") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "T6-T5",
          dataIndex: "BooksCollectionTargetT6T5",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t6") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
      ],
    },
    {
      title: (
        <div>
          Payout <br /> Rate
        </div>
      ),
      dataIndex: "pr",
      // defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,

      children: [
        {
          title: "R1",
          dataIndex: "PayoutRatep1",    width: "100px",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t1") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "R2",
          dataIndex: "PayoutRatep2",     width: "100px",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t2") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "R3",
          dataIndex: "PayoutRatep3",    width: "100px",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t3") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
              <div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "R4",
          dataIndex: "PayoutRatep4",     width: "100px",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t4") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
              <div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "R5",
          dataIndex: "PayoutRatep5",     width: "100px",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t5") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
              <div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "R6",
          dataIndex: "PayoutRatep6",          width: "100px",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t6") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
      ],
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "300px",
      // defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "HOLD REASON",
      dataIndex: "holdReason",
      key: "holdReason",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.holdReason !== prevRecord.holdReason,
      render: (data, data1) => {

        return <Tooltip title={data}>{data || ""}</Tooltip>;
      },
      ...columnWidth,
    },
    {
      title: "Payout",
      dataIndex: "payout",
      // defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,
      children: [
        {
          title: "P1",
          dataIndex: "payoutR1",
          width: "120px",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t1") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "P2",
          dataIndex: "payoutR2",
          width: "120px",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t2") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "P3",
          width: "120px",
          dataIndex: "payoutR3",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t3") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "P4",
          dataIndex: "payoutR4",
          width: "120px",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t4") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "P5",
          dataIndex: "payoutR5",
          width: "120px",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t5") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
        {
          title: "P6",
          dataIndex: "payoutR6",
          width: "120px",
          render: (x, y) => {
            return !y?.scheme?.topTarget?.split(",")?.includes("t6") ? (
              <div className="h-24 input-disabled"></div>
            ) : (
<div>{TextLocalString(x)}</div>
            );
          },
        },
      ],
    },
    {
      title: "Total Expected Payout",
      dataIndex: "TEP",
      children: [
        {
          title: "Expected Vol",
          dataIndex: "TEPvol",
          width: "150px",
          render:(x)=><div>{TextLocalString(x)}</div>
        },
        {
          title: "Per Tr.",
          dataIndex: "TEPpertr",
          width: "150px",
          render:(x)=><div>{TextLocalString(x)}</div>
        },
        {
          title: "Amount",
          dataIndex: "TEPamount",
          width: "150px",
          render:(x)=><div>{TextLocalString(x)}</div>
        },
      ],
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",

      render: (_, record) =>
        !dayExpired && (
          <Button
            // type="primary"
            disabled={
              record.status == 1 ||
              record.status == 3 ||
              record.status == 0 ||
              schemeIdNo.length > 1 ||
              record.scheme.holdStatus == 10
            } // Disable the button based on the condition
            onClick={() => handleSingleEdit(record)}
          >
            {" "}
            Edit
          </Button>
        ),
    },
  ];

  const fetchGeneratedScheme = async (demoParams) => {
    const result = await axiosAPI.post(
      "SchemeGenerate/GetSchemeGenerate",
      demoParams
    );
    // dispatch(schemeList(res.data[0]))
    dispatch(generatedScheme(result.data));

    if (result?.data?.data?.data && result?.data?.data?.data.length === 0)
      setDataSource([]);
  };
  useEffect(() => {
    // const userId = userData.userId;

    const currentFiscalYear1 = getCurrentFiscalYear1(startMonth);

    const demoParams = {
      // state: 1,
      // userid: 2,
      userid: userId,
      status: selectedStatus,
      month: collectionData.month,
      // endMonth: 2
      pageSize: collectionData?.pageSize,
      pageNumber: collectionData?.pageNumber,
      ...payload,
      fyear:
        currentFiscalYear1 != "FYN" ? currentFiscalYear1 : currentFiscalYear,
      schemeType: selectedSchemeType,
      sortingOrder: collectionData.sortingOrder == "descend" ? "DESC" : "ASC",
      sortingType: collectionData.sortingType || "DealerCode",
      dealerLocation: dealerDetails?.location,
      dealerCode: dealerDetails?.code,
    };

    if (selectedStatus === "") {
      delete demoParams.status;
    }
    // fetchSchemeList(userId);
    const timer = setTimeout(() => {
      fetchGeneratedScheme(demoParams);
    }, 500);
    return () => clearTimeout(timer);
  }, [
    payload,
    selectedStatus,
    collectionData,
    selectedSchemeType,
    dealerDetails,
  ]);

  useEffect(() => {
    const data = [];
    if (listData?.data?.data?.length) {
      listData?.data?.data
        .filter((item) => item.schemeNo != null)
        .map((item, index) => {
          for (let i = 0; i < 1; i++) {
            if (item.dealerCode != null) {
              const dealreCode = item.dealerCode.split(",");

              const objCopy = { ...item }; // 👈️ create copy
              // if(dealreCode.length > 1) {
              //   objCopy.schemeType = 'colorCode';
              // }
              let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
              let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
              let createDate = dayjs(item.createdDate)
                .utc("z")
                .local()
                .tz(dayjs.tz.guess())
                .format("MMM D, YYYY");

              data.push({
                status: item.status,
                key: item.schemeId,
                scheme: item,
                code: item.dealerCode,
                name: item.dealerName,
                Location: item.dealerLocation,
                state: item.stateName,
                Manager: item.territoryManager,
                TopTarget: item.TopTarget,
                StartMonth:
                  formattedStartDate == "Invalid Date"
                    ? ""
                    : formattedStartDate,
                EndMonth:
                  formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
                industry: item.industry,
                oldBillingQty: item.oldBillingQty,
                oldCollection: Math.round(item.oldCollection),
                oldInstallation: item.oldInstallation,
                b: item.billing,
                i: item.installation,
                c: Math.round(item.collection),
                delayPlan: item.delayPlan,
                bgLacsCurrentYear:
                  item.bGLacsCurrentYear || item.bgLacsCurrentYear || 0,
                lastThirdMonth: item.closingOutStandingMonth_3,
                lastTwoMonth: item.closingOutStandingMonth_2,
                lastOneMonth: item.closingOutStandingMonth_1,
                outStandingDays: item.outStandingDays,
                openingStockDays: item.openingStockDays,
                oldOpeningStock: item.oldOpeningStock,
                target: "",
                // nos: 50,
                // days: 0,
                nov21: null,
                dec21: null,
                // lac: 66,
                schemeType:
                  dealreCode.length > 1
                    ? (objCopy.schemeType = "colorCode")
                    : null,
                BooksCollectionTargetT1: item.t1,
                BooksCollectionTargetT2: item.t2,
                BooksCollectionTargetT2T1: item.t2_T1,
                BooksCollectionTargetT3: item.t3,
                BooksCollectionTargetT3T2: item.t3_T2,
                BooksCollectionTargetT4: item.t4,
                BooksCollectionTargetT4T3: item.t4_T3,
                BooksCollectionTargetT5: item.t5,
                BooksCollectionTargetT5T4: item.t5_T4,
                BooksCollectionTargetT6: item.t6,
                BooksCollectionTargetT6T5: item.t6_T5,
                PayoutRatep1: item.r1,
                PayoutRatep2: item.r2,
                PayoutRatep3: item.r3,
                PayoutRatep4: item.r4,
                PayoutRatep5: item.r5,
                PayoutRatep6: item.r6,
                payoutR1: item.p1,
                payoutR2: item.p2,
                payoutR3: item.p3,
                payoutR4: item.p4,
                payoutR5: item.p5,
                payoutR6: item.p6,
                reason: item.reason,
                TEPvol: item.volume,
                TEPpertr: item.perTr,
                TEPamount: item.amount,
                editable: false,
                createdDate: createDate,
                topTarget: item.topTarget,
                closingStock: item.closingStock,
                closingOutStanding: item.closingOutStanding,
                holdReason: item.holdReason,
                // LMSA: 'T2',
                // LCQ: 55,
                // LMSA1: 'T1',
                // LCQ1: 18000
              });
            }
          }
          setDataSource(data);
        });
    }
  }, [listData]);

  const handleUnMergeModal = () => {
    if (schemeIdNo.length === 0) {
      return message.warning("Select the merged scheme first");
    } else {
      setOpenUnMergeModal(!openUnMergeModal);
    }
  };
  useEffect(() => {
    const value = schemeIdNo.map((item) => item.scheme.isMerge);
    const status = schemeIdNo.some(
      (item) => item.scheme.status === 2 || item.scheme.status === 3
    );
    const isEdit = schemeIdNo.map((item) => item.scheme.isEdit);
    value.includes(true) ? setEdit(false) : setEdit(edit);
    setDisable(
      status
        ? status
        : value.includes(true) && !value.includes(false)
        ? true
        : value.includes(true)
    );
    setEnable(
      status
        ? status
        : !value.includes(true) && value.includes(false)
        ? true
        : value.includes(false)
    );
  }, [schemeIdNo]);

  // useEffect(() => {
  //   const hasMerge = schemeIdNo.some(item => item.scheme.isMerge);
  //   const hasStatus1or3 = schemeIdNo.some(item => item.scheme.status === 1 || item.scheme.status === 3);

  //   setEdit(!hasMerge);

  //   if (hasStatus1or3) {
  //     setDisable(!disable);
  //     setEnable(!enable);
  //   } else {
  //     const hasFalse = schemeIdNo.some(item => item.scheme.isMerge === false);
  //     setDisable(hasMerge && !hasFalse);
  //     setEnable(!hasMerge && hasFalse);
  //   }
  // }, [schemeIdNo]);

  const handleOk = async () => {
    await mergeScheme(schemeIdNo, userName, userId);
    setLoading(false);
    setOpenMergeModal(false);
  };

  const checkDate = async () => {
    let startMonth = "";
    let endMonth = "";
    for (let i = 0; i <= schemeIdNo.length - 1; i++) {
      for (let j = i + 1; j <= schemeIdNo.length - 1; j++) {
        if (
          schemeIdNo[i].StartMonth === schemeIdNo[j].StartMonth &&
          schemeIdNo[i].EndMonth === schemeIdNo[j].EndMonth
        ) {
          const monthStart = schemeIdNo[i].StartMonth.split("'")[0];
          const startYear = schemeIdNo[i].StartMonth.split("'")[1];
          const monthEnd = schemeIdNo[i].EndMonth.split("'")[0];
          const endYear = schemeIdNo[i].EndMonth.split("'")[1];
          startMonth = dayjs(`${monthStart} 20${startYear}`).format();
          endMonth = dayjs(`${monthEnd} 20${endYear}`).format();
        }
      }
    }
    if (startMonth === "") {
      return {
        error: "Scheme can't be merged due to different start  and end month",
      };
      // return message.warning("Scheme can't be merged due to different start  and end month")
    }
    return {
      error: "",
    };
  };
  const handleMerge = async (e, allData) => {
    try {
      if (schemeIdNo.length === 0) {
        return message.warning("Select Schemes to be merged");
      }
      const errorMessage = await checkDate();
      if (!!errorMessage?.error) {
        throw new Error(errorMessage.error);
      }
      if (schemeIdNo.length <= 4) {
        setOpenMergeModal(!openMergeModal);
      } else {
        message.warning("More than 4 Schemes can't be merged");
      }
    } catch (e) {
      message.warning(e.message);
    }
  };
  const handleUnMerge = async (e, allData) => {
    await unMergeScheme(schemeIdNo, userName, userId);
    setLoading(false);
    setOpenUnMergeModal(false);
  };

  const captalizeObjKeys = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
    );

  const handleDownload = () => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };

    const data = dataSource
      .map((rec) => rec.scheme)
      .map((item) => {
        let formattedStartDate = dayjs(item?.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item?.endMonth).format("MMM'YY");
        let createdDate = dayjs(item?.createdDate).format("MMM D,YY");
        return captalizeObjKeys({
          Scheme_No: item.schemeNo,
          Dealer_Code: item.dealerCode,
          Dealer_Location: item.dealerLocation,
          Dealer_Name: item.dealerName,
          Created_Date: createdDate || "",
          State: item.stateName,
          Territory_Manager: item.territoryManager,
          Start_Month:
            formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
          End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
          Industry: item.industry,
          B: item.oldBillingQty ? item.oldBillingQty : 0,
          I: Math.round(item.oldInstallation),
          C: item.oldCollection ? item.oldCollection : 0,
          Opening_Stock_Nos: item.oldOpeningStock,
          Opening_Stock_Days: item.openingStockDays,
          Last_Third_Month: item.closingOutStandingMonth_3,
          Last_Two_Month: item.closingOutStandingMonth_2,
          Last_One_Month: item.closingOutStandingMonth_1,
          Lacs: "",
          Days: item.outStandingDays,
          BG_Lacs: item.bgLacsCurrentYear || item.bGLacsCurrentYear || 0,
          Installation_Plan_Nos: item.delayPlan,
          Billing_Details_B: item.billing,
          Billing_Details_i: item.installation,
          Billing_Details_c: Math.round(item.collection),
          CLOSING_STOCK: item.closingStock,
          CLOSING_OUTSTANDING: item?.closingOutStanding,
          Books_Collection_Target_T1: item.t1,
          Books_Collection_Target_T2: item.t2,
          Books_Collection_Target_T2T1: item.t2_T1,
          Books_Collection_Target_T3: item.t3,
          Books_Collection_Target_T3T2: item.t3_T2,
          Books_Collection_Target_T4: item.t4,
          Books_Collection_Target_T4T3: item.t4_T3,
          Books_Collection_Target_T5: item.t5,
          Books_Collection_Target_T5T4: item.t5_T4,
          Books_Collection_Target_T6: item.t6,
          Books_Collection_Target_T6T5: item.t6_T5,
          Payout_Rate_R1: item.r1,
          Payout_Rate_R2: item.r2,
          Payout_Rate_R3: item.r3,
          Payout_Rate_R4: item.r4,
          Payout_Rate_R5: item.r5,
          Payout_Rate_R6: item.r6,
          Remarks: item.reason,
          Payout_P1: item.p1,
          Payout_P2: item.p2,
          Payout_P3: item.p3,
          Payout_P4: item.p4,
          Payout_P5: item.p5,
          Payout_P6: item.p6,
          TEPvol: item.volume,
          TEPpertr: item.perTr,
          TEPamount: item.amount,
          TOP_TARGET: item?.topTarget || "",
          holdReason: item.holdReason || "",
        });
      });
    if (data?.length == 0) {
      ToastifyShow("Something went wrong", "error");
      return false;
    }
    let tab_text = `<table class="custom-table">
    <thead >
    <tr >
    <th rowspan="2">SCHEME CODE</th>
        <th rowspan="2">DEALER CODE</th>
        <th rowspan="2">DEALER LOCATION</th>
        <th rowspan="2">DEALER NAME</th>
        <th rowspan="2">CREATED DATE</th>
        <th rowspan="2">STATE</th>
        <th rowspan="2">TERRITORY MANAGER</th>
        <th rowspan="2">TOP SLAB</th>
        <th rowspan="2">START MONTH</th>
        <th rowspan="2">END MONTH</th>
        <th rowspan="2">INDUSTRY</th>
        <th rowspan="2">B</th>
        <th rowspan="2">I</th>
        <th rowspan="2">C</th>
        <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS. (IN LACS)</th>
      <th>BG</th>
      <th>INSTALLATION PLAN</th>
 <th colspan="3">BILLING DETAILS</th>
 <th rowspan="2">CLOSING STOCK</th>
 <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
 <th colspan="11">BOOKS COLLECTION TARGETS (NO. OF TRS.)</th>
 <th colspan="6">PAYOUT RATE</th>

 <th colspan="6">PAYOUT</th>
  <th rowspan="2">REMARKS</th>
    <th rowspan="2">HOLD REASON</th>
 <th colspan="3">TOTAL EXPECTED PAYOUT</th>
    </tr>
    <tr>
       <th>NOS</th>
       <th>DAYS</th>
       <th>LAST 3rd MONTH</th>
       <th>LAST 2nd MONTH</th>
       <th>LAST 1st MONTH</th>
       <th>LAST 1st MONTH DAYS</th>
       <th>LACS</th>
       <th>NOS</th>
       <th>B</th>
       <th>I</th>
       <th>C</th>
       <th>T1</th>
       <th>T2</th>
       <th>T2-T1</th>
       <th>T3</th>
       <th>T3-T2</th>
       <th>T4</th>
       <th>T4-T3</th>
       <th>T5</th>
       <th>T5-T4</th>
       <th>T6</th>
       <th>T6-T5</th>
       <th>R1</th>
       <th>R2</th>
       <th>R3</th>
       <th>R4</th>
       <th>R5</th>
       <th>R6</th>
       <th>P1</th>
       <th>P2</th>
       <th>P3</th>
       <th>P4</th>
       <th>P5</th>
       <th>P6</th>
       <th>Expected Volume</th>
       <th>PER TR.</th>
       <th>AMOUNT</th>
       </tr>
    </thead>`;

    data?.forEach((x) => {
      tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
<td>${x?.DEALER_CODE}</td>
<td>${x?.DEALER_LOCATION}</td>
<td>${x?.DEALER_NAME}</td>
<td>${x?.CREATED_DATE}</td>
<td>${x?.STATE}</td>
<td>${x?.TERRITORY_MANAGER}</td>
<td>${x?.TOP_TARGET?.toUpperCase()}</td>
<td>${x?.START_MONTH}</td>
<td>${x?.END_MONTH}</td>
<td>${x?.INDUSTRY}</td>
<td>${x?.B}</td>
<td>${x?.I}</td>
<td>${x?.C}</td>
<td>${x?.OPENING_STOCK_NOS}</td>
<td>${x?.OPENING_STOCK_DAYS}</td>
<td>${x?.LAST_THIRD_MONTH}</td>
<td>${x?.LAST_TWO_MONTH}</td>
<td>${x?.LAST_ONE_MONTH}</td>
<td>${x?.DAYS}</td>
<td>${x?.BG_LACS}</td>
<td>${x?.INSTALLATION_PLAN_NOS}</td>
<td>${x?.BILLING_DETAILS_B}</td>
<td>${x?.BILLING_DETAILS_I}</td>
<td>${x?.BILLING_DETAILS_C}</td>
<td>${x?.CLOSING_STOCK}</td>
<td>${x?.CLOSING_OUTSTANDING}</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T1}</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T2}</td>
<td>${
        x?.BOOKS_COLLECTION_TARGET_T2T1 && x?.TOP_TARGET?.includes("t2")
          ? x?.BOOKS_COLLECTION_TARGET_T2T1
          : 0
      }</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T3}</td>
<td>${
        x?.BOOKS_COLLECTION_TARGET_T3T2 && x?.TOP_TARGET?.includes("t3")
          ? x?.BOOKS_COLLECTION_TARGET_T3T2
          : 0
      }</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T4}</td>
<td>${
        x?.BOOKS_COLLECTION_TARGET_T4T3 && x?.TOP_TARGET?.includes("t4")
          ? x?.BOOKS_COLLECTION_TARGET_T4T3
          : 0
      }</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T5}</td>
<td>${
        x?.BOOKS_COLLECTION_TARGET_T5T4 && x?.TOP_TARGET?.includes("t5")
          ? x?.BOOKS_COLLECTION_TARGET_T5T4
          : 0
      }</td>
<td>${x?.BOOKS_COLLECTION_TARGET_T6}</td>
<td>${
        x?.BOOKS_COLLECTION_TARGET_T6T5 && x?.TOP_TARGET?.includes("t6")
          ? x?.BOOKS_COLLECTION_TARGET_T6T5
          : 0
      }</td>
<td>${x?.PAYOUT_RATE_R1}</td>
<td>${x?.PAYOUT_RATE_R2}</td>
<td>${x?.PAYOUT_RATE_R3}</td>
<td>${x?.PAYOUT_RATE_R4}</td>
<td>${x?.PAYOUT_RATE_R5}</td>
<td>${x?.PAYOUT_RATE_R6}</td>
<td>${x?.PAYOUT_P1}</td>
<td>${x?.PAYOUT_P2}</td>
<td>${x?.PAYOUT_P3}</td>
<td>${x?.PAYOUT_P4}</td>
<td>${x?.PAYOUT_P5}</td>
<td>${x?.PAYOUT_P6}</td>
<td>${x?.REMARKS}</td>
    <td>${x?.HOLDREASON}</td>
<td>${x?.TEPVOL}</td>
<td>${x?.TEPPERTR}</td>
<td>${x?.TEPAMOUNT}</td>
</tr>`;
    });

    let totals = {
      closingStock: 0,
      closingOutStanding: 0,
      t1: 0,
      t2: 0,
      t2_T1: 0,
      t3: 0,
      t3_T2: 0,
      t4: 0,
      t4_T3: 0,
      t5: 0,
      t5_T4: 0,
      t6: 0,
      t6_T5: 0,
      r1: 0,
      r2: 0,
      r3: 0,
      r4: 0,
      r5: 0,
      r6: 0,
      p1: 0,
      p2: 0,
      p3: 0,
      p4: 0,
      p5: 0,
      p6: 0,
      reason: "",
      holdReason: "",
      volume: 0,
      perTr: 0,
      amount: 0,
    };

    dataSource.forEach((item) => {
      for (let key in totals) {
        if (key === "t2_T1" && item.scheme.topTarget.includes("t2")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t3_T2" && item.scheme.topTarget.includes("t3")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t4_T3" && item.scheme.topTarget.includes("t4")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t5_T4" && item.scheme.topTarget.includes("t5")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t6_T5" && item.scheme.topTarget.includes("t6")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (
          !["t2_T1", "t3_T2", "t4_T3", "t5_T4", "t6_T5"].includes(key)
        ) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        }
      }
    });

    totals.r1 = totals.t1 !== 0 ? parseInt(totals.p1 / totals.t1) : 0;
    totals.r2 = totals.t2 !== 0 ? parseInt(totals.p2 / totals.t2) : 0;
    totals.r3 = totals.t3 !== 0 ? parseInt(totals.p3 / totals.t3) : 0;
    totals.r4 = totals.t4 !== 0 ? parseInt(totals.p4 / totals.t4) : 0;
    totals.r5 = totals.t5 !== 0 ? parseInt(totals.p5 / totals.t5) : 0;
    totals.r6 = totals.t6 !== 0 ? parseInt(totals.p6 / totals.t6) : 0;
    totals.perTr =
      totals.perTr !== 0 ? parseInt(totals.amount / totals.volume) : 0;

    let totalsRow = "<tr><td colspan='25' >Total</td>";
    for (let key in totals) {
      if (
        key != "reason" &&
        key != "holdReason" &&
        key != "closingStock" &&
        key != "closingOutStanding"
      ) {
        if (key.startsWith("r") || key === "perTr") {
          totalsRow += `<td>${totals[key]}</td>`;
        } else {
          totalsRow += `<td>${totals[key]}</td>`;
        }
      } else {
        totalsRow += `<td></td>`;
      }
    }

    totalsRow += "</tr>";
    tab_text += totalsRow;
    tab_text += "</table>";
    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);

    // Apply styles to the table using a class
    elt.getElementsByClassName("custom-table")[0].style.width = "100%";

    // generate workbook with XLSXStyle
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });

    // Add style to Roral row
    const allColumns = dataSource.reduce((columns, item) => {
      Object.keys(item).forEach((key) => {
        if (!columns.includes(key)) {
          columns.push(key);
        }
      });
      return columns;
    }, []);

    function getAlphabetEquivalent(number) {
      if (number < 1) {
        return "Invalid input";
      } else if (number <= 26) {
        return String.fromCharCode(number + 64);
      } else {
        let firstChar = String.fromCharCode((number - 1) / 26 + 64);
        let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
        return firstChar + secondChar;
      }
    }

    let excelArr = [];
    for (let index = 0; index < allColumns.length; index++) {
      let ele = `${getAlphabetEquivalent(index + 1)}${(
        dataSource.length + 3
      ).toString()}`;
      excelArr.push(ele);
    }
    Object.keys(wb.Sheets[wb.SheetNames[0]])
      .filter((cell) => excelArr.includes(cell))
      .forEach((cell) => {
        wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
      });
    // Dynamically set column widths
    wb.Sheets[wb.SheetNames[0]]["!cols"] = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    // remove element
    document.body.removeChild(elt);
    XLSX.writeFile(
      wb,
      `all_scheme(with delivery plan and without delivery plan)_${new Date()
        .toJSON()
        .slice(0, 10)}.xlsx`
    );
  };
  const tableHeader = (d) => {
    return (
      <Row className="table_sub_buttons">
        <Col>
          {/* <Button
            disabled={disable}
            icon={<MergeIcon />}
            type="text"
            onClick={(e) => handleMerge(e, d)}
          >
            <span>Merge</span>
          </Button> */}
        </Col>
        <Col>
          {/* <Button
            disabled={enable}
            icon={<UnmergeIcon />}
            type="text"
            onClick={(e) => handleUnMergeModal()}
          >
            Unmerge
          </Button> */}
        </Col>
        <Col className="d-none">
          <Button
            // disabled={enable}
            icon={<Download />}
            type="text"
            ref={reference}
            onClick={handleDownload}
          >
            Download
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div>
        <Table
          title={tableHeader}
          className="all_schemeTable_pagination"
          // rowClassName={(record, index) => record.scheme.isMerge == true ? 'table-row-dark' :  'table-row-light'}
          style={{
            cursor: "default",
          }}
          summary={(pageData) => {
            const fieldSum = {
              b: 0,
              i: 0,
              c: 0,
              totalR1: 0,
              totalR2: 0,
              totalR3: 0,
              totalR4: 0,
              totalR5: 0,
              totalR6: 0,
              t1: 0,
              t2: 0,
              t3: 0,
              t4: 0,
              t5: 0,
              t6: 0,
              T2_T1: 0,
              T3_T2: 0,
              T4_T3: 0,
              T5_T4: 0,
              T6_T5: 0,
              vol: 0,
              perTr: 0,
              totalAmount: 0,
              industry:0,
              billing:0,
              installation:0,
              collection:0,
              delayPlan:0
            };
            pageData.forEach(
              ({
                payoutR1,
                payoutR2,
                payoutR3,
                payoutR4,
                payoutR5,
                payoutR6,
                TEPamount,
                BooksCollectionTargetT1,
                BooksCollectionTargetT2,
                BooksCollectionTargetT3,
                BooksCollectionTargetT4,
                BooksCollectionTargetT5,
                BooksCollectionTargetT6,
                BooksCollectionTargetT2T1,
                BooksCollectionTargetT3T2,
                BooksCollectionTargetT4T3,
                BooksCollectionTargetT5T4,
                BooksCollectionTargetT6T5,
                TEPvol,
                TEPpertr,
                scheme,
                industry,
                billing,
                installation,
                collection,
                delayPlan
              }) => {
                fieldSum.totalR1 +=
                  payoutR1 && scheme?.topTarget?.includes("t1")
                    ? parseInt(payoutR1 || 0)
                    : 0;
                fieldSum.totalR2 +=
                  payoutR2 && scheme?.topTarget?.includes("t2")
                    ? parseInt(payoutR2 || 0)
                    : 0;
                fieldSum.totalR3 +=
                  payoutR3 && scheme?.topTarget?.includes("t3")
                    ? parseInt(payoutR3 || 0)
                    : 0;
                fieldSum.totalR4 +=
                  payoutR4 && scheme?.topTarget?.includes("t4")
                    ? parseInt(payoutR4 || 0)
                    : 0;
                fieldSum.totalR5 +=
                  payoutR5 && scheme?.topTarget?.includes("t5")
                    ? parseInt(payoutR5 || 0)
                    : 0;
                fieldSum.totalR6 +=
                  payoutR6 && scheme?.topTarget?.includes("t6")
                    ? parseInt(payoutR6 || 0)
                    : 0;
                fieldSum.t1 +=
                  BooksCollectionTargetT1 && scheme?.topTarget?.includes("t1")
                    ? parseInt(BooksCollectionTargetT1 || 0)
                    : 0;
                fieldSum.t2 +=
                  BooksCollectionTargetT2 && scheme?.topTarget?.includes("t2")
                    ? parseInt(BooksCollectionTargetT2 || 0)
                    : 0;
                fieldSum.t3 +=
                  BooksCollectionTargetT3 && scheme?.topTarget?.includes("t3")
                    ? parseInt(BooksCollectionTargetT3 || 0)
                    : 0;
                fieldSum.t4 +=
                  BooksCollectionTargetT4 && scheme?.topTarget?.includes("t4")
                    ? parseInt(BooksCollectionTargetT4 || 0)
                    : 0;
                fieldSum.t5 +=
                  BooksCollectionTargetT5 && scheme?.topTarget?.includes("t5")
                    ? parseInt(BooksCollectionTargetT5 || 0)
                    : 0;
                fieldSum.t6 +=
                  BooksCollectionTargetT6 && scheme?.topTarget?.includes("t6")
                    ? parseInt(BooksCollectionTargetT6 || 0)
                    : 0;
                fieldSum.T2_T1 +=
                  BooksCollectionTargetT2T1 && scheme?.topTarget?.includes("t2")
                    ? parseInt(BooksCollectionTargetT2T1 || 0)
                    : 0;
                fieldSum.T3_T2 +=
                  BooksCollectionTargetT3T2 && scheme?.topTarget?.includes("t3")
                    ? parseInt(BooksCollectionTargetT3T2 || 0)
                    : 0;
                fieldSum.T4_T3 +=
                  BooksCollectionTargetT4T3 && scheme?.topTarget?.includes("t4")
                    ? parseInt(BooksCollectionTargetT4T3 || 0)
                    : 0;
                fieldSum.T5_T4 +=
                  BooksCollectionTargetT5T4 && scheme?.topTarget?.includes("t5")
                    ? parseInt(BooksCollectionTargetT5T4 || 0)
                    : 0;
                fieldSum.T6_T5 +=
                  BooksCollectionTargetT6T5 && scheme?.topTarget?.includes("t6")
                    ? parseInt(BooksCollectionTargetT6T5 || 0)
                    : 0;
                fieldSum.vol += TEPvol ? parseInt(TEPvol) : 10;
                fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
                fieldSum.perTr = parseInt(fieldSum.totalAmount / fieldSum.vol);
                fieldSum.billing += billing ? parseInt(billing) : 10;
                fieldSum.installation += installation ? parseInt(installation) : 10;
                fieldSum.collection += collection ? parseInt(collection) : 10;
                fieldSum.delayPlan += delayPlan ? parseInt(delayPlan) : 10;
              }
            );
            return (
              <Table.Summary.Row
                style={{ fontWeight: "500", fontSize: "18px" }}
              >
                <Table.Summary.Cell fixed index={0}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell fixed index={1}></Table.Summary.Cell>
                <Table.Summary.Cell fixed index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10}>{TextLocalString(fieldSum.industry,true)||""}</Table.Summary.Cell>
                <Table.Summary.Cell index={11}></Table.Summary.Cell>
                <Table.Summary.Cell index={12}></Table.Summary.Cell>
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
                <Table.Summary.Cell index={15}></Table.Summary.Cell>
                <Table.Summary.Cell index={16}></Table.Summary.Cell>
                <Table.Summary.Cell index={17}></Table.Summary.Cell>
                <Table.Summary.Cell index={18}></Table.Summary.Cell>
                <Table.Summary.Cell index={19}></Table.Summary.Cell>
                <Table.Summary.Cell index={20}></Table.Summary.Cell>
                <Table.Summary.Cell index={21}></Table.Summary.Cell>
                <Table.Summary.Cell index={22}>{TextLocalString(fieldSum.billing,true)||""}</Table.Summary.Cell>
                <Table.Summary.Cell index={23}>{TextLocalString(fieldSum.installation,true)||""}</Table.Summary.Cell>
                <Table.Summary.Cell index={24}>{TextLocalString(fieldSum.collection,true)||""}</Table.Summary.Cell>
                <Table.Summary.Cell index={25}></Table.Summary.Cell>
                <Table.Summary.Cell index={26}></Table.Summary.Cell>

                <Table.Summary.Cell index={27}>
                  {fieldSum.t1 ? TextLocalString(fieldSum.t1,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={28}>
                  {fieldSum.t2 ? TextLocalString(fieldSum.t2,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={29}>
                  {fieldSum.T2_T1 ? TextLocalString(fieldSum.T2_T1,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={30}>
                  {fieldSum.t3 ? TextLocalString(fieldSum.t3,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={31}>
                  {fieldSum.T3_T2 ? TextLocalString(fieldSum.T3_T2,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={32}>
                  {fieldSum.t4 ? TextLocalString(fieldSum.t4,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={33}>
                  {fieldSum.T4_T3 ? TextLocalString(fieldSum.T4_T3,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={34}>
                  {fieldSum.t5 ? TextLocalString(fieldSum.t5,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={35}>
                  {fieldSum.T5_T4 ? TextLocalString(fieldSum.T5_T4,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={36}>
                  {fieldSum.t6 ? TextLocalString(fieldSum.t6,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={37}>
                  {" "}
                  {fieldSum.T6_T5 ? TextLocalString(fieldSum.T6_T5,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={38}>
                  {fieldSum.t1 && fieldSum.totalR1
                    ? TextLocalString(Math.round(fieldSum.totalR1 / fieldSum.t1),true)
                    : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={39}>
                  {fieldSum.t2 && fieldSum.totalR2
                    ? TextLocalString(Math.round(fieldSum.totalR2 / fieldSum.t2),true)
                    : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={40}>
                  {fieldSum.t3 && fieldSum.totalR3
                    ? TextLocalString(Math.round(fieldSum.totalR3 / fieldSum.t3),true)
                    : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={41}>
                  {fieldSum.t4 && fieldSum.totalR4
                    ? TextLocalString(Math.round(fieldSum.totalR4 / fieldSum.t4),true)
                    : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={42}>
                  {fieldSum.t5 && fieldSum.totalR5
                    ? TextLocalString(Math.round(fieldSum.totalR5 / fieldSum.t5),true)
                    : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={43}>
                  {fieldSum.t6 && fieldSum.totalR6
                    ? TextLocalString(Math.round(fieldSum.totalR6 / fieldSum.t6),true)
                    : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={44}></Table.Summary.Cell>
                <Table.Summary.Cell index={45}></Table.Summary.Cell>
                <Table.Summary.Cell index={46}>
                  {fieldSum.totalR1 ? TextLocalString(fieldSum.totalR1,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={47}>
                  {fieldSum.totalR2 ? TextLocalString(fieldSum.totalR2,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={48}>
                  {fieldSum.totalR3 ? TextLocalString(fieldSum.totalR3,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={49}>
                  {fieldSum.totalR4 ? TextLocalString(fieldSum.totalR4,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={50}>
                  {fieldSum.totalR5 ? TextLocalString(fieldSum.totalR5,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={51}>
                  {fieldSum.totalR6 ? TextLocalString(fieldSum.totalR6,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={52}>
                  {fieldSum.vol ? TextLocalString(fieldSum.vol,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={53}>
                  {fieldSum.perTr ? TextLocalString(fieldSum.perTr,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={54}>
                  {fieldSum.totalAmount ? TextLocalString(fieldSum.totalAmount,true) : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={55}></Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
          // rowSelection={{
          //   selectedRowKeys,
          //   onChange: (newSelectedRowKeys, selectedRow) => {

          //     setSelectedRowKeys(newSelectedRowKeys);
          //     setSchemeIdNo(selectedRow)
          //     getSelectionCheck(newSelectedRowKeys, dataSource);
          //     // setEdit(!selectedRow.scheme.isMerge)
          //     sessionStorage.setItem('rowData', JSON.stringify(selectedRow))

          //   },
          //   getCheckboxProps: (record) => ({ disabled: ((record.status == 2 && record.ismerged == "false") || record.status == 0 || record.status == 3 || record.status == 7 || record.status == 8|| record.status == 1) || record.scheme.holdStatus==10 })
          // }}
          onChange={handleChange}
          columns={columns}
          pagination={false}
          dataSource={dataSource}
          bordered
          scroll={{
            x: 7000,
            y: 1800,
          }}
        />

        <Pagination
          className="paginationDesign"
          defaultCurrent={1}
          current={collectionData.pageNumber}
          onChange={(pageNumber, pageSize) =>
            setCollectionData({ ...collectionData, pageSize, pageNumber })
          }
          total={listData?.data?.totalCountScheme}
          showSizeChanger
          pageSize={collectionData.pageSize}
          pageSizeOptions={['150', '250', '350']}
        />

        <MergeModal
          open={openMergeModal}
          handleCancel={handleMerge}
          handleOk={handleOk}
          confirmLoading={loading}
          modalText={"Do you want to merge?"}
        />
        <MergeModal
          open={openUnMergeModal}
          handleCancel={handleUnMergeModal}
          handleOk={handleUnMerge}
          confirmLoading={loading}
          modalText={"Do you want to Unmerge?"}
        />
      </div>
      {contextHolder}
    </>
  );
};
export default AllSchemeTable;
