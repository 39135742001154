import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAllSchemeAwaitingCountData, GetHoldSchemesCountData, schemeDraftPost, schemeGenerateApi, getHoldSchemes, schemeGeneratePost, schemeListApprove, updateApprovalScheme, updateApprovedHoldScheme, updateRejectedHoldScheme, updateSchemeGenerateApi } from "./schemaGenerateService";

export const schemeGeneratePostApi = createAsyncThunk(
  "schemeGeneratePostApi",
  async (data, thunkAPI) => {
    try {
      const response = await schemeGeneratePost(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const schemeDraftPostApi = createAsyncThunk(
  "schemeDraftPostApi",
  async (data, thunkAPI) => {
    try {
      const response = await schemeDraftPost(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//schemeListApprove

export const schemeListApproveApi = createAsyncThunk(
  "schemeListApproveApi",
  async (data, thunkAPI) => {
    try {
      const response = await schemeListApprove(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//schemeGenerateApi

export const schemeGenerateListApi = createAsyncThunk(
  "schemeGenerateListApi",
  async (data, thunkAPI) => {
    try {
      const response = await schemeGenerateApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getHoldSchemesApi = createAsyncThunk(
  "getHoldSchemesApi",
  async (data, thunkAPI) => {
    try {
      const response = await getHoldSchemes(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//updateApprovalSchemeApi

export const updateApprovalSchemeApi = createAsyncThunk(
  "updateApprovalSchemeApi",
  async (data, thunkAPI) => {
    try {
      const response = await updateApprovalScheme(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//For Approve Click of Hold Schemes on Awaiting Screen
export const updateApprovedHoldSchemeApi = createAsyncThunk(
  "updateApprovedHoldSchemeApi",
  async (data, thunkAPI) => {
    try {
      const response = await updateApprovedHoldScheme(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//For Reject Click of Hold Schemes on Awaiting Screen
export const updateRejectedHoldSchemeApi = createAsyncThunk(
  "updateRejectedHoldSchemeApi",
  async (data, thunkAPI) => {
    try {
      const response = await updateRejectedHoldScheme(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//updateSchemeGenerateApi

export const updateSchemeGenerateApiAsync = createAsyncThunk(
  "updateSchemeGenerateApiAsync",
  async (data, thunkAPI) => {
    try {
      const response = await updateSchemeGenerateApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetAllSchemeAwaitingCountDataApiAsync = createAsyncThunk(
  "GetAllSchemeAwaitingCountDataApiAsync",
  async (data, thunkAPI) => {
    try {
      const response = await GetAllSchemeAwaitingCountData(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetHoldSchemesCountDataApiAsync = createAsyncThunk(
  "GetHoldSchemesCountDataApiAsync",
  async (data, thunkAPI) => {
    try {
      const response = await GetHoldSchemesCountData(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const schemeGenerateSlice = createSlice({
  name: "schemeGenerateSlice",
  initialState: {
    schemeGenerateDetails: {},
    schemeDraftDetails:{},
    schemeApprove:{},
    schemeAwaitingCount:{},
    schemeHoldCount:{},
    schemeUpdateApproval:{},
    schemeUpdateApprovedHold:{},
    updateSchemeGenerate:{},
    holdSchemeAwaiting:{},
    loadingScheme: "idle",
    loadingHoldSchemeAwaiting:"idle"
  },
  reducers: {
    // resetState: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(schemeGeneratePostApi.pending, (state) => {
      state.loadingScheme = "pending";
    });
    builder.addCase(schemeGeneratePostApi.fulfilled, (state, action) => {
      state.loadingScheme = "succeeded";
      state.schemeGenerateDetails = action.payload;
      state.error = false;
    });
    builder.addCase(schemeGeneratePostApi.rejected, (state) => {
      state.schemeGenerateDetails = {};
      state.loadingScheme = "succeeded";
      state.error = true;
    });
    builder.addCase(schemeDraftPostApi.pending, (state) => {
      state.loadingScheme = "pending";
    });
    builder.addCase(schemeDraftPostApi.fulfilled, (state, action) => {
      state.loadingScheme = "succeeded";
      state.schemeDraftDetails = action.payload;
      state.error = false;
    });
    builder.addCase(schemeDraftPostApi.rejected, (state) => {
      state.schemeDraftDetails = {};
      state.loadingScheme = "succeeded";
      state.error = true;
    });
    builder.addCase(schemeListApproveApi.pending, (state) => {
      state.loadingScheme = "pending";
    });
    builder.addCase(schemeListApproveApi.fulfilled, (state, action) => {
      state.loadingScheme = "succeeded";
      state.schemeApprove = action.payload;
      state.error = false;
    });
    builder.addCase(schemeListApproveApi.rejected, (state) => {
      state.schemeApprove = {};
      state.loadingScheme = "succeeded";
      state.error = true;
    });
    builder.addCase(schemeGenerateListApi.pending, (state) => {
      state.loadingScheme = "pending";
    });
    builder.addCase(schemeGenerateListApi.fulfilled, (state, action) => {
      state.loadingScheme = "succeeded";
      state.schemeGenerateList = action.payload;
      state.error = false;
    });
    builder.addCase(schemeGenerateListApi.rejected, (state) => {
      state.schemeGenerateList = {};
      state.loadingScheme = "succeeded";
      state.error = true;
    });

    builder.addCase(updateApprovalSchemeApi.pending, (state) => {
      state.loadingScheme = "pending";
    });
    builder.addCase(updateApprovalSchemeApi.fulfilled, (state, action) => {
      state.loadingScheme = "succeeded";
      state.schemeUpdateApproval = action.payload;
      state.error = false;
    });
    builder.addCase(updateApprovalSchemeApi.rejected, (state) => {
      state.schemeUpdateApproval = {};
      state.loadingScheme = "succeeded";
      state.error = true;
    });

    builder.addCase(updateApprovedHoldSchemeApi.pending, (state) => {
      state.loadingScheme = "pending";
    });
    builder.addCase(updateApprovedHoldSchemeApi.fulfilled, (state, action) => {
      state.loadingScheme = "succeeded";
      state.schemeUpdateApprovedHold = action.payload;
      state.error = false;
    });
    builder.addCase(updateApprovedHoldSchemeApi.rejected, (state) => {
      state.schemeUpdateApprovedHold = {};
      state.loadingScheme = "succeeded";
      state.error = true;
    });

    builder.addCase(updateSchemeGenerateApiAsync.pending, (state) => {
      state.loadingScheme = "pending";
    });
    builder.addCase(updateSchemeGenerateApiAsync.fulfilled, (state, action) => {
      state.loadingScheme = "succeeded";
      state.updateSchemeGenerate = action.payload;
      state.error = false;
    });
    builder.addCase(updateSchemeGenerateApiAsync.rejected, (state) => {
      state.updateSchemeGenerate = {};
      state.loadingScheme = "succeeded";
      state.error = true;
    });
    builder.addCase(GetAllSchemeAwaitingCountDataApiAsync.pending, (state) => {
      state.loadingCount = "pending";
    });
    builder.addCase(GetAllSchemeAwaitingCountDataApiAsync.fulfilled, (state, action) => {
      state.loadingCount = "succeeded";
      state.schemeAwaitingCount = action.payload;
      state.error = false;
    });
    builder.addCase(GetAllSchemeAwaitingCountDataApiAsync.rejected, (state) => {
      state.schemeAwaitingCount = {};
      state.loadingCount = "succeeded";
      state.error = true;
    });

    builder.addCase(GetHoldSchemesCountDataApiAsync.pending, (state) => {
      state.loadingCount = "pending";
    });
    builder.addCase(GetHoldSchemesCountDataApiAsync.fulfilled, (state, action) => {
      state.loadingCount = "succeeded";
      state.schemeHoldCount = action.payload;
      state.error = false;
    });
    builder.addCase(GetHoldSchemesCountDataApiAsync.rejected, (state) => {
      state.schemeHoldCount = {};
      state.loadingCount = "succeeded";
      state.error = true;
    });

    builder.addCase(getHoldSchemesApi.pending, (state) => {
      state.loadingHoldSchemeAwaiting = "pending";
    });
    builder.addCase(getHoldSchemesApi.fulfilled, (state, action) => {
      state.loadingHoldSchemeAwaiting = "succeeded";
      state.holdSchemeAwaiting = action.payload;
      state.error = false;
    });
    builder.addCase(getHoldSchemesApi.rejected, (state) => {
      state.holdSchemeAwaiting = {};
      state.loadingHoldSchemeAwaiting = "succeeded";
      state.error = true;
    });


    
  },
});
export const { reset } = schemeGenerateSlice.actions;

export default schemeGenerateSlice.reducer;
