import { Button, Table, Row, Col, Tag, Pagination } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import StateHead from "../../pages/dashboard/stateHead";
import ActionButton from "../actionButton/actionButton";
// import AMGRSchemeList from "../../AMdashboard/AMGRSchemeList.js"
import { areaMngrData } from "../../redux/areaManager/DashboardList";
import dayjs from "dayjs";
import axiosAPI from "../../services/axiosApi";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../utils/dateFormatter";
import { STATE_WISE_DASHBOARD_LABEL } from "../../config";
import { getStateWiseDataApi } from "../../redux/stateData/stateDataSlice";
import { schemeListApproveApi } from "../../redux/schemaGenerate/schemaGenerateSlice";
import ToastifyShow, { TextLocalString } from "../ToastifyShow";
import TableColorDetails from "./TableColorDetails";
import { decryptData } from "../../utils/localStorageEncodeDecode";
import FullScreenLoader from "../loader/FullScreenLoader";
import { render } from "@testing-library/react";





const SlabBased = ({ selectedSchemeType, onSetCardData, selectedStateId, selectedStatus, dashboardType, monthlyFilters,
    setDataSource,
    dataSource,
    yearShow,startMonth }) => {
    const dispatch = useDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const { stateWiseData, loadingState } = useSelector((state) => state.stateData)
    const { loadingScheme, schemeApprove } = useSelector(
        (state) => state.schemagenerate
    );

    const [isLoading,setIsLoading]=useState(false)
    const InitCollectionData = {
        currentYear: yearShow,
        // collectionType: 'Collection',
        pageSize: 150,
        pageNumber: 1,
        sortingOrder:"ascend",
        sortingType:"DealerCode"
    };
    const {userRole,userId} = decryptData(sessionStorage.getItem("userInfo"));
    // const [selectedSchemeType, setSelectedSchemeType] = useState(3)
    const [loading, setLoading] = useState(false);
    // const listData = useSelector((state)=> state.generatedScheme.data)
    const [mngrdata, setMngrdata] = useState([]);
    const [collectionData, setCollectionData] = useState(InitCollectionData);
    const listAreaMngrData = useSelector(
        (state) => state.areaMngrSchemeList.data
    );

    const columns = [
        {
            title: "Scheme No.",
            dataIndex: "scheme",
            width: "180px",
            fixed: "left",
            defaultSortOrder: "ascend",
            sorter: true,
            key:"SchemeNo",
            render: (item) => {
                let color = " ";
                const dataItem=item?.holdStatus==9?item?.holdStatus:item.status
                switch (dataItem) {
                    case 0:
                        color = "#FCE838"; //inProgress
                        break;
                    case 1:
                        color = "#FCE838"; //inProgress
                        break;
                    case 2:
                        color = "#51DE4E"; //Approved
                        break;
                    case 3:
                        color = "#9999ff"; //Withdraw
                        break;
                    case 4:
                        color = "#FF3232"; //Cancel
                        break;
                    case 5:
                        color = "#FF3232"; //Reject
                        break;
                    case 7:
                        color = "#bcb38d"; //Deviation
                        break;
                    case 8:
                        color = "rgb(255, 0, 255)"; //Auto Reject
                        break;
                        case 9:
              color = "#9999ff"; //hold
              break;
                    default:
                        color = "#FCE838";
                        break;
                }
                return (
                    <Tag
                        // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
                        style={{ color: "black", fontSize: "13px" }}
                        color={color}
                    >
                        {item.schemeNo}
                    </Tag>
                );
            },
            key:"SchemeNo",
        },
        {
            title: "Dealer Code",
            dataIndex: "code",
            fixed: 'left',
            width: "180px",
            sorter: true,
            defaultSortOrder: "ascend",
            key:"DealerCode",
        },
        {
            title: "Dealer Name",
            dataIndex: "name",
            fixed: 'left',
            width: "180px",
            sorter: true,
            defaultSortOrder: "ascend",
            key:"DealerName",
        },
        {
            title: "State",
            dataIndex: "state",
            width: '120px',
            sorter: true,
            defaultSortOrder: "ascend",
            key:"StateName",
    
        },
        {
            title: "Dealer Location",
            dataIndex: "Location",
            width: "190px",
            onFilter: (value, record) => record?.Location.indexOf(value) === 0,
            sorter: true,
            defaultSortOrder: "ascend",
            key:"DealerLocation",
        },
    
        {
            title: "Territory Manager",
            dataIndex: "Manager",
            width: '190px',
            sorter: true,
            defaultSortOrder: "ascend",
            key:"TerritoryManager",
        }, {
            title: "TOP SLAB",
            dataIndex: "topTarget",
            width: '190px',
            sorter: true,
            render:(x,y)=>y?.scheme?.topTarget?.toUpperCase()
        },
        {
            title: "Start Month",
            dataIndex: "StartMonth",
            // sorter: true,
            width: '150px'
    
        },
        {
            title: "End Month",
            dataIndex: "EndMonth",
            // sorter: true,
            width: '150px'
    
        },
        {
            title: "Actual Installation Last Year Month",
            dataIndex: "actual_Installation_Last_Year_Month",
            // sorter: true,
            width: '250px'
    
        },
        {
            title: "Targets",
            dataIndex: "Target",
            width: '180px',
            children: [
                {
                    title: "T1",
                    dataIndex: "t1",
                    width: '90px',
                    render:(x)=>TextLocalString(x)
                },
                {
                    title: "T2",
                    dataIndex: "t2",
                    width: '90px',
                    render:(x)=>TextLocalString(x)
                },
                {
                    title: "T3",
                    dataIndex: "t3",
                    width: '90px',
                    render:(x)=>TextLocalString(x)
                }
            ]
        },
        {
            title: "inc. Per Tractor",
            dataIndex: "IPT",
            width: '180px',
            children: [
                {
                    title: "R1",
                    dataIndex: "r1",
                    width: '90px',
                    render:(x)=>TextLocalString(x)
                },
                {
                    title: "R2",
                    dataIndex: "r2",
                    width: '90px',
                    render:(x)=>TextLocalString(x)
                },
                {
                    title: "R3",
                    dataIndex: "r3",
                    width: '90px',
                    render:(x)=>TextLocalString(x)
                }
            ]
        },
        {
            title: "Payout",
            dataIndex: "Payout",
            width: '180px',
            children: [
                {
                    title: "P1",
                    dataIndex: "p1",
                    width: '90px',
                    render:(x)=>TextLocalString(x)
                },
                {
                    title: "P2",
                    dataIndex: "p2",
                    width: '90px',
                    render:(x)=>TextLocalString(x)
                },
                {
                    title: "P3",
                    dataIndex: "p3",
                    width: '90px',
                    render:(x)=>TextLocalString(x)
                }
            ]
        },
        {
            title: "Remarks",
            dataIndex: "reason",
            width: '120px'
    
        },
        {
            title: "Hold Remarks",
            dataIndex: "holdReason",
            width: "120px",
          },
    ];
    const stateWiseColumns = [
        {
            title: "Actual Installation",
            dataIndex: "actualInstallation",
            render: (x) => Math?.round(x) || "0",
            width: "100px",
        },
        
       
        {
            title: "Achieved Slab",
            dataIndex: "achievedSlab",
            width: "100px",
        },
        {
            title: "Payout 1",
            dataIndex: "payout",
            width: "100px",
            render:(x)=>TextLocalString(x)
        },
        {
            title: "Payout 2",
            dataIndex: "payout2",
            width: "100px",
            render:(x)=>TextLocalString(x)
        },
        {
            title: "Payout 3",
            dataIndex: "payout3",
            width: "100px",
            render:(x)=>TextLocalString(x)
        },
        {
            title: "Payout 4",
            dataIndex: "payout4",
            width: "100px",
            render:(x)=>TextLocalString(x)
        }
    ];
    
  
    if (dashboardType === STATE_WISE_DASHBOARD_LABEL) {
        columns.push(...stateWiseColumns);
    }
    const currentYear = dayjs().year()
    const fetchGeneratedScheme = async (demoParams) => {      setIsLoading(true)
        await axiosAPI.post(`Dashboard/DashboardDetail`, demoParams).then((x)=>{
            setMngrdata(x?.data?.data||{});
            setIsLoading(false) 
          }).catch((err)=>{
            setIsLoading(false) 
          })
        // setMngrdata(result?.data?.data);
        // dispatch(areaMngrData(result.data));
    };
    useEffect(() => {
        if (schemeApprove?.data?.data?.length != 0) {
            setMngrdata(schemeApprove?.data?.data);
          }
    }, [schemeApprove])
    useEffect(() => {

        if (Array?.isArray(stateWiseData?.lisitems) && stateWiseData?.lisitems?.length != 0) {
            setMngrdata(stateWiseData?.lisitems)
            
        } else {
            setIsLoading(false);
            setMngrdata([])
        }
    }, [stateWiseData])
    useEffect(() => {
        // const userId = userData.userId;

        const currentFiscalYear = getCurrentFiscalYear1(dayjs(startMonth).format());
        const demoParams = {
            fyear: currentFiscalYear,
            schemeType: selectedSchemeType,
            state: selectedStateId,
            status: selectedStatus,
            userid: userId,
            userRole: userRole,
            enforcedMonth: monthlyFilters?.enforcedMonth,
            month: monthlyFilters?.month||4,
            endMonth: monthlyFilters?.monthEnd||3,
            pageNumber: collectionData.pageNumber || 1,
            pageSize: collectionData.pageSize || 150,
            sortingOrder:collectionData.sortingOrder=="ascend"?"ASC":"DESC",
            sortingType:collectionData.sortingType||"DealerCode"
        };
        if (selectedStatus === "") {
            delete demoParams.status
        }
        if (dashboardType === STATE_WISE_DASHBOARD_LABEL) {
            const fetchStateWiseReport = async (demoParams) => {
                demoParams = {
                    ...demoParams,
                    fYear: currentFiscalYear,
                    stateId: selectedStateId,
                    year:(typeof monthlyFilters?.year=="string"&&monthlyFilters?.year)?monthlyFilters?.year-1:yearShow?yearShow-1:dayjs().year()-1,
                    enforcedMonth: monthlyFilters?.enforcedMonth,
                    month: monthlyFilters?.month||4,
                    endMonth: monthlyFilters?.monthEnd||3,
                    userid: userId,
                    userRole: userRole,
                    pageNumber: collectionData.pageNumber || 1,
                    pageSize: collectionData.pageSize || 150,
                    sortingOrder:collectionData.sortingOrder=="ascend"?"ASC":"DESC",
                    sortingType:collectionData.sortingType||"DealerCode"
                };
                delete demoParams.fyear;
                delete demoParams.state;
                // setSelectedSchemeType(demoParams.schemeType)
                await dispatch(getStateWiseDataApi(demoParams))
                // const result = await axiosAPI.post(`Dashboard/StateWiseReports`,demoParams);
                // setMngrdata(result?.data?.data)
                // dispatch(areaMngrData(result?.data?.data));
            };
            fetchStateWiseReport(demoParams);
        } else {
            // if (userRole == "ZADM") {
            //     let apiData = {
            //         fYear: currentFiscalYear,
            //         stateId: selectedStateId,
            //         year: currentYear,
            //         schemeType: selectedSchemeType,
            //         userid: userId,
            //         userRole: userData?.userRole,
            //         pageNumber: collectionData.pageNumber || 1,
            //         pageSize: collectionData.pageSize || 10
            //     };
            //     dispatch(schemeListApproveApi(apiData))
            //     let fetchDashboardDetails = async () => {
            //         let result = await axiosAPI.post(`Dashboard/DashboardDetail`, demoParams);

            //         if (result?.data) {

            //             let { submittedCount, pendingCount, approvedCount } = result?.data?.data
            //             userRole != "ZADM" && onSetCardData({
            //                 pendingCount: pendingCount || 0,
            //                 approvedCount: approvedCount || 0,
            //                 submittedCount: submittedCount || 0
            //             });
            //         }
            //     }
            //     fetchDashboardDetails()
            // } else 
            // {
                fetchGeneratedScheme(demoParams);
            // }
        }
        
    }, [selectedSchemeType, selectedStateId, selectedStatus, dashboardType, monthlyFilters, collectionData,startMonth]);

    // useEffect(() => {
    //     setMngrdata({
    //         ...mngrdata,
    //         schemelist: listAreaMngrData.schemelist,
    //     });
    // }, [listAreaMngrData]);


    let schemeList=mngrdata?.schemelist||[]
    useEffect(() => {

        const data = [];
        const schemeListData = 
        userRole == "ZADM" 
            ? schemeList
            : dashboardType === STATE_WISE_DASHBOARD_LABEL 
            ? mngrdata 
            : schemeList;
            onSetCardData({
                pendingCount: mngrdata?.pendingCount||0,
                approvedCount: mngrdata?.approvedCount||0,
                submittedCount: mngrdata?.submittedCount||0,
                rejectedCount: mngrdata?.rejectedCount||0,
                totalApprovedCount:mngrdata?.totalApprovedCount||0,
                totalPendingCount:mngrdata?.totalPendingCount||0,
                totalSubmittedCount:mngrdata?.totalSubmittedCount||0,
                totalRejectedCount: mngrdata?.totalRejectedCount||0,
                autoRejectCount : mngrdata?.totalAutoRejectCount||0,
              });
            if (schemeListData?.length == 0) {
            setDataSource([])
            // userRole != "ZADM" && 
            // onSetCardData({
            //     pendingCount: 0,
            //     approvedCount: 0,
            //     submittedCount: 0,
            //     totalApprovedCount:0,
            //     totalPendingCount:0,
            //     totalSubmittedCount:0
            // });
            return;
        }
        schemeListData?.filter(item => item.schemeNo != null).map((item, index) => {

            for (let i = 0; i < 1; i++) {
                let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
                let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
                data.push({
                    status: item.status,
                    key: item.schemeId,
                    scheme: item,
                    code: item.dealerCode,
                    name: item.dealerName,
                    Location: item.dealerLocation,
                    state: item.stateName,
                    Manager: item.territoryManager,
                    StartMonth:
                        formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
                    EndMonth:
                        formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
                    // industry: 102,
                    b: item.billing,
                    c: item.collection,
                    i: item.installation,
                    target: "",
                    t1: item.t1,
                    t2: item.t2,
                    t3: item.t3,
                    r1: item.r1,
                    r2: item.r2,
                    r3: item.r3,
                    p1: item.p1,
                    p2: item.p2,
                    p3: item.p3,
                    // nos: 50,
                    // days: 0,
                    // nov21: null,
                    // dec21: null,
                    // lac: 66,
                    BooksCollectionTargetT1: item.t1,
                    BooksCollectionTargetT2: item.t2,
                    BooksCollectionTargetT2T1: item.t2_T1,
                    BooksCollectionTargetT3: item.t3,
                    BooksCollectionTargetT3T2: item.t3_T2,
                    BooksCollectionTargetT4: item.t4,
                    BooksCollectionTargetT4T3: item.t4_T3,
                    PayoutRatep1: item.r1,
                    PayoutRatep2: item.r2,
                    PayoutRatep3: item.r3,
                    payoutR1: item.p1,
                    payoutR2: item.p2,
                    payoutR3: item.p3,
                    reason: item.reason,
                    holdReason:item.holdReason,
                    TEPvol: item.volume,
                    TEPpertr: item.perTr,
                    TEPamount: item.amount,
                    actual_Installation_Last_Year_Month:item?.actual_Installation_Last_Year_Month,
                    actualInstallation:item.actualInstallation,
                    payout:item.payout,
                    payout2:item.payout2,
                    payout3:item.payout3,
                    payout4:item.payout4,
                    achievedSlab:item.achievedSlab || "No Slab",
                    holdStatus:item?.holdStatus
                    // LMSA: 'T2',
                    // LCQ: 55,
                    // LMSA1: 'T1',
                    // LCQ1: 18000
                });
            }
            setDataSource(data);
        });
        // userRole != "ZADM" && 
        // onSetCardData({
        //     pendingCount: mngrdata?.pendingCount||0,
        //     approvedCount: mngrdata?.approvedCount||0,
        //     submittedCount: mngrdata?.submittedCount||0,
        //     totalApprovedCount:mngrdata?.totalApprovedCount||0,
        //     totalPendingCount:mngrdata?.totalPendingCount||0,
        //     totalSubmittedCount:mngrdata?.totalSchemeSubmitted||0,
        // });
    }, [mngrdata, dashboardType]);



    // const userInfo = decryptData(sessionStorage.getItem("userInfo"));
    // const userRole = userInfo.userRole;

    // const captalizeObjKeys = obj => Object.fromEntries(
    //     Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
    // );

    // const handleDownload = () => {
     
    //   }
    const handleChange = (pagination, filters, sorter) => {
        setCollectionData({...collectionData,"sortingOrder":sorter?.order,"sortingType":sorter?.columnKey})
        // getApiData()
      }
    
    return (
        <>
            {/* <button className="action_button" style={{color: 'white'}} disabled={dataSource?.length == 0} onClick={handleDownload}>download</button> */}
            <Table
                // size="small"
                loading={(loadingScheme == "pending" || loadingState == "pending") ? true : false}
                summary={(pageData) => {
                    const fieldSum = {
                        T1: 0,
                        T2: 0,
                        T3: 0,
                        R1: 0,
                        R2: 0,
                        R3: 0,
                        P1: 0,
                        P2: 0,
                        P3: 0,
                        payout:0,
                        payout2:0,
                        payout3:0,
                        payout4:0,
                        actualInstallation:0,
                        totalAmount: 0,
                    }
                    pageData.forEach(({
                        t1,
                        t2,
                        t3,
                        r1,
                        r2,
                        r3,
                        p1,
                        p2,
                        p3,
                        payout,
                        payout2,
                        payout3,
                        payout4,
                        actualInstallation,
                        scheme,
                     
                    }) => {
                        fieldSum.T1 += (t1 && scheme?.topTarget?.includes("t1")) ? parseInt(t1 || 0) : 0;
                        fieldSum.T2 += (t2 && scheme?.topTarget?.includes("t2")) ? parseInt(t2 || 0) : 0;
                        fieldSum.T3 += (t3 && scheme?.topTarget?.includes("t3")) ? parseInt(t3 || 0) : 0;
                        fieldSum.R1 += (r1 && scheme?.topTarget?.includes("t1")) ? parseInt(r1 || 0) : 0;
                        fieldSum.R2 = (r2 && scheme?.topTarget?.includes("t2")) ? parseInt(r2 || 0) : 0;
                        fieldSum.R3 = (r3 && scheme?.topTarget?.includes("t3")) ? parseInt(r3 || 0) : 0;
                        // fieldSum.R1 += r1 ? parseInt(r1) : 0;
                        // fieldSum.R2 += r2 ? parseInt(r2) : 0;
                        fieldSum.P1 += (p1 && scheme?.topTarget?.includes("t1")) ? parseInt(p1 || 0) : 0;
                        fieldSum.P2 += (p2 && scheme?.topTarget?.includes("t2")) ? parseInt(p2 || 0) : 0;
                        fieldSum.P3 += (p3 && scheme?.topTarget?.includes("t3")) ? parseInt(p3 || 0) : 0;
                        fieldSum.actualInstallation += actualInstallation ? parseInt(actualInstallation) : 0;
                        fieldSum.payout += payout ? parseInt(payout) : 0;
                        fieldSum.payout2 += payout2 ? parseInt(payout2) : 0;
                        fieldSum.payout3 += payout3 ? parseInt(payout3) : 0;
                        fieldSum.payout4 += payout4 ? parseInt(payout4) : 0;
                        

                    });
                    return (
                        <Table.Summary.Row style={{ fontWeight: '600', fontSize: '18px' }}>
                            <Table.Summary.Cell fixed index={0}>Total</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}></Table.Summary.Cell>
                            <Table.Summary.Cell index={3}></Table.Summary.Cell>
                            <Table.Summary.Cell index={4}></Table.Summary.Cell>
                            <Table.Summary.Cell index={5}></Table.Summary.Cell>
                            <Table.Summary.Cell index={6}></Table.Summary.Cell>
                            <Table.Summary.Cell index={7}></Table.Summary.Cell>
                            <Table.Summary.Cell index={8}></Table.Summary.Cell>
                            <Table.Summary.Cell index={9}></Table.Summary.Cell>
                            <Table.Summary.Cell index={10}>
                                {fieldSum.T1 ? fieldSum.T1 : ''}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={11}>
                                {fieldSum.T2 ? fieldSum.T2 : ''}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={12}>
                                {fieldSum.T3 ? fieldSum.T3 : ''}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={13}>
                                {fieldSum.T1 && fieldSum.R1 ? Math.round( fieldSum.P1 / fieldSum.T1) : ""}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={14}>
                            {fieldSum.T2 && fieldSum.R2 ? Math.round( fieldSum.P2 / fieldSum.T2) : ""}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={15}>
                            {fieldSum.T3 && fieldSum.R3 ? Math.round( fieldSum.P3 / fieldSum.T3) : ""}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={16}>
                                {fieldSum.P1 ? fieldSum.P1 : ''}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={17}>
                                {fieldSum.P2 ? fieldSum.P2 : ''}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={18}>
                                {fieldSum.P3 ? fieldSum.P3 : ''}
                            </Table.Summary.Cell>
                  <Table.Summary.Cell index={56}></Table.Summary.Cell>
                  <Table.Summary.Cell index={56}></Table.Summary.Cell>

                            {dashboardType === STATE_WISE_DASHBOARD_LABEL && (
                <>
                  <Table.Summary.Cell index={51}>
                    {fieldSum.actualInstallation
                      ? fieldSum.actualInstallation
                      : 0}
                  </Table.Summary.Cell>
    
                  <Table.Summary.Cell index={56}></Table.Summary.Cell>
                  <Table.Summary.Cell index={57}>
                    {fieldSum?.payout || 0}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={58}>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={59}>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={60}>
                  </Table.Summary.Cell>
                </>
              )}
                        </Table.Summary.Row>
                    )
                }}
                columns={columns}
                dataSource={dataSource}
                onChange={handleChange}
                pagination={false}
                scroll={{
                    x: 2000,
                    y: 500,
                }}
                bordered
            />
       <div className="d-flex flex-column">
            <TableColorDetails />

            
            <Pagination defaultCurrent={1}
                showSizeChanger
                className="paginationDesign"
                // style={{ marginTop: "-43px", position:"absolute", right: "14px" }}
                current={collectionData.pageNumber}
                onChange={(pageNumber, pageSize) =>
                    setCollectionData({ ...collectionData, pageNumber, pageSize })
                }
                total={stateWiseData?.totalcount ||schemeApprove?.data?.totalCountScheme}
                pageSize={collectionData.pageSize}
                pageSizeOptions={['150', '250', '350']}
            />
        </div>      {isLoading&&<FullScreenLoader/>}
        </>

    );
};
export default SlabBased;
