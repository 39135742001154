import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const getSchemeStatus = createSlice({
  name: "schemeStatus",
  initialState: {
      data: [],
  },
  reducers: {
    schemeStatus: (state, action)=>{
        state.data = action.payload;
    }
  }

});
export const { schemeStatus } = getSchemeStatus.actions;
export const getAllSchemeStatus = (state)=> state.schemeStatus.data;
export default getSchemeStatus.reducer;
