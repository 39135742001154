import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import "../../createSchemes/createScheme.css";
import { Button, Table, Form, message, Modal, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";

import { getCurrentFiscalYear } from "../../../../utils/dateFormatter";
import moment from "moment";
import { ArrowBack, ArrowBackRounded } from "@mui/icons-material";
import {
  getSaveModifiedDashboardApi,
  resetCollection,
} from "../../../../redux/modification/modificationSlice";
import SMDialog from "../../../../components/modal/alertModal/CommonModal";
import ValidationErrorsModal from "../../../../components/modal/alertModal/ValidationErrorsModal";
import { updateSchemeGenerateApiAsync } from "../../../../redux/schemaGenerate/schemaGenerateSlice";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
import dayjs from "dayjs";
import { Select } from "antd";
import {
  getAwaitingLockSchemeAsync,
  getLockedSchemeStateApiAsync,
  getLockedSchemeTypeAsync,
} from "../../../../redux/stateData/stateDataSlice";
import { encryptPayload } from "../../../../services/encryption";
import ValidationDifferentSchemeError from "../../../../components/modal/alertModal/ValidationDifferentSchemeError";

import { postUpdateHoldScheme } from "../../../../redux/hold/holdDataSlice";
const currentFiscalYear = getCurrentFiscalYear();


const AwaitingInstallationEdit = ({
  backBtn,
  schemeDetails,
  selectedSchemeType,
  viewType
}) => {
  const dispatch = useDispatch();
  const [dataHandle, setDataHandle] = useState([]);
  const [schemeLockedStatus, setSchemeLockedStatus] = useState({});
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [codeError, setCodeError] = useState([]);
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });


  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );
  const [openErrorDialog, setOpenErrorDialog] = useState({
    open: false,
    errorsFields: [],
  });

  const formik = useFormik({
    initialValues: [],
  });

  const { lockedStates } = useSelector((state) => state?.stateData);

  // useEffect(() => {
  //   if ((userRole == "ZADM"||userRole == "NSH") && formik?.values?.[0]?.stateId) {
  //     dispatch(getLockedSchemeStateApiAsync({ data: encryptPayload(formik?.values?.[0]?.stateId || 0) })).then((x) => {
  //       if (x?.payload?.data?.[0]) {

  //         setSchemeLockedStatus(x?.payload?.data?.[0]
  //           ? {
  //             t1: true,
  //             t2: true,
  //             t3: x?.payload?.data?.[0]?.t3I ? true : false,
  //           }
  //           : {
  //             t1: true,
  //             t2: true,
  //             t3: false
  //           }
  //         )
  //       }
  //     })
  //   } else {

  //     setSchemeLockedStatus(lockedStates?.[0]
  //       ? {
  //         t1: true,
  //         t2: true,
  //         t3: lockedStates?.[0]?.t3I ? true : false,
  //       }
  //       : {
  //         t1: true,
  //         t2: true,
  //         t3: true
  //       })
  //   }
  // }, [formik?.values?.[0]?.stateId, lockedStates])

  const topTargetArr = (x) => [
    {
      name: "T1",
      value: "t1",

    },
    {
      name: "T2",
      value: "t2",

    },
    {
      name: "T3",
      value: "t3",
      disabled: !x?.x?.t3I || !x?.t3 ? true : false,
    },
  ];

  const topTargetArr1 = (x) => [
    {
      name: "T1",
      value: "t1",

    },
    {
      name: "T2",
      value: "t2",

    },
    {
      name: "T3",
      value: "t3",
      disabled: !x?.t3I || x?.t3 ? true : false,
    },
  ]
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { editDashboard, loadingState } = useSelector(
    (state) => state.modifyCollection
  );
  const uniqueByKey = (array, key) => {
    const seen = new Set();
    return array.reduce((acc, current) => {
      const value = current[key];
      if (!seen.has(value)) {
        seen.add(value);
        acc.push(value);
      }
      return acc;
    }, []);
  };
  const uniqueData = useMemo(
    () => uniqueByKey(schemeDetails, "stateId"),
    [schemeDetails]
  );
  useEffect(() => {
    if (schemeDetails) {
      dispatch(
        getLockedSchemeStateApiAsync({
          data: encryptPayload(uniqueData?.toString()),
        })
      ).then((data) => {
        if (data?.payload?.data?.[0]) {

          let data1 = schemeDetails.map((x) => ({
            ...x,
            dealerCode: x.dealerCode,
            dealerId: x.dealerId,
            dealerName: x.dealerName,
            dealerLocation: x.dealerLocation,
            stateName: x.stateName,
            teritoryManager: x.territoryManager || "",
            topTarget: x.topTarget,
            installationTarget: parseInt(x?.installationTarget) || 0,
            incPerTarget: parseInt(x?.incPerTarget) || 0,
            actual_Installation_Last_Year_Month:
              x?.actual_Installation_Last_Year_Month,
            startMonth: x.startMonth,
            endMonth: x.endMonth,
            reason: x.reason,
            payout: x.p1 || x?.payout,
            t1: +x.t1,
            t2: +x.t2,
            t3: +x.t3,
            p1: x.p1,
            p2: x.p2,
            p3: x.p3,
            r1: +x.r1,
            r2: +x.r2,
            r3: +x.r3,
            volume: x.volume,
            perTr: Math.round(x.perTr),
            amount: x.amount,
            dropdownscheme: x?.topTarget?.split(","),
            holdDrodownScheme: x?.holdTopTarget?.split(","),
            topTargetArrState: Array?.isArray(data?.payload?.data)
              ? data?.payload?.data?.filter(
                (y) => y?.stateId == x?.stateId
              )?.[0] || [
                {
                  t1: true,
                  t2: true,
                  t3: false,
                },
              ]
              : [
                {
                  t1: true,
                  t2: true,
                  t3: false,
                },
              ],
          }));
          formik?.setValues(data1);
        }
      });
    }
  }, [schemeDetails]);

  const submitData = (e) => {
    e.preventDefault();

    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const finalData = [];

    let checkValidation =
      selectedSchemeType == 3
        ? formik?.values?.filter((x) =>
          x?.dropdownscheme?.includes("t3")
            ? !x.t1 || !x.t2 || !x.t3 || !x.r1 || !x.r2 || !x.r3
            : x?.dropdownscheme?.includes("t2")
              ? !x.t1 || !x.t2 || !x.r1 || !x.r2
              : !x.t1 || !x.r1
        )
        : selectedSchemeType == 4
          ? formik?.values?.filter(
            (x) => !x?.installationTarget || !x.upperCap || !x.incPerTarget
          )
          : formik?.values?.filter(
            (x) => !x?.installationTarget || !x.incPerTarget
          );
    let errorDetails =
      selectedSchemeType == 3
        ? Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
          ?.filter((x) =>
            checkValidation?.[0]?.dropdownscheme?.includes("t3")
              ? !x[1] &&
              (x[0] == "t1" ||
                x[0] == "t2" ||
                x[0] == "t3" ||
                x[0] == "r1" ||
                x[0] == "r2" ||
                x[0] == "r3")
              : checkValidation?.[0]?.dropdownscheme?.includes("t2")
                ? !x[1] &&
                (x[0] == "t1" || x[0] == "t2" || x[0] == "r1" || x[0] == "r2")
                : checkValidation?.[0]?.dropdownscheme?.includes("t1")
                  ? !x[1] && (x[0] == "t1" || x[0] == "r1")
                  : !x[0]
          )
          .map((x) =>
            x[0] == "t1"
              ? "T1"
              : x[0] == "t2"
                ? "T2"
                : x[0] == "t3"
                  ? "T3"
                  : x[0] == "r1"
                    ? "R1"
                    : x[0] == "r2"
                      ? "R2"
                      : x[0] == "r3"
                        ? "R3"
                        : x[0]
          )
        : selectedSchemeType == 4
          ? Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter(
              (x) =>
                (!x[1] && x[0] == "installationTarget" && x[1] == 0) ||
                (x[0] == "incPerTarget" && x[1] == 0) ||
                (x[0] == "upperCap" && x[1] == 0)
            )
            .map((x) =>
              x[0] == "installationTarget"
                ? "Installation Target"
                : x[0] == "upperCap"
                  ? "Upper Cap"
                  : x[0] == "incPerTarget" && "INC. PER TRACTOR"
            )
          : Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter(
              (x) =>
                (!x[1] && x[0] == "installationTarget" && x[1] == 0) ||
                (x[0] == "incPerTarget" && x[1] == 0)
            )
            .map((x) =>
              x[0] == "installationTarget"
                ? "Installation Expected"
                : x[0] == "incPerTarget" && "INC. PER TRACTOR"
            );

    let data = [];
    formik?.values.forEach((item, index) => {
      data.push({
        ...formik?.values[index],
        dealerCode: item.dealerCode,
        dealerId: +item.dealerId,
        StartMonth: item?.StartMonth
          ? dayjs(item?.StartMonth)
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss")
          : item?.StartMonth,
        EndMonth: item?.EndMonth
          ? dayjs(item?.EndMonth).endOf("month").format("YYYY-MM-DD HH:mm:ss")
          : item?.EndMonth,
        isSAP: item?.isSAP,
        isActive: item?.isActive,
        state: item.stateId,
        status: 1,
        isSubmitted: 1,
        actual_Installation_Last_Year_Month:
          item?.actual_Installation_Last_Year_Month,
        fyear: item?.fyear,
        schemeType: item?.schemeType,
        schemeTypeId: item?.schemeTypeId,
        userid: +userId,
        username: userName,
        t1: +item.t1 || 0,
        t2: +item.t2 || 0,
        t3: +item.t3 || 0,
        r1: +item.r1 || 0,
        r2: +item.r2 || 0,
        r3: +item.r3 || 0,
        p1: +item.p1 || 0,
        p2: +item.p2 || 0,
        p3: +item.p3 || 0,
        installationTarget: item?.installationTarget,
        incPerTarget: item?.incPerTarget,
        upperCap: item?.upperCap,
        reason: item.reason,
        topTarget: item?.dropdownscheme?.toString(),
      });
    });

    if (Object.keys(errorDetails ? errorDetails : {})?.length != 0) {
      showErrorDialog(errorDetails);
    } else {
      showModal();
      setDataHandle(data);
    }
  };
  const handleErrorModal = (firstItem, secondItem, opt = false) => {
    setOpenErrorModal(opt ? opt : !openErrorModal);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };

  const showErrorDialog = (errFields) => {
    setOpenErrorDialog((openErrorDialog) => ({
      open: true,
      errorsFields: errFields ? errFields : [],
    }));
  };

  const calculateFormula = (index) => {
    let y = formik?.values[index];

    if (selectedSchemeType == 3) {
      let { t2, t1, t3, r1, r2, r3, holdT1, holdT2, holdT3, holdR1, holdR2, holdR3 } = y;

      let dropdownShow = y?.dropdownscheme;

      formik?.setFieldValue(index, {
        ...y,
        [viewType == "hold" ? "holdP1" : "p1"]: parseInt(viewType == "hold" ? holdT1 : t1) * parseInt(viewType == "hold" ? holdR1 : r1) || 0,
        [viewType == "hold" ? "holdP2" : "p2"]: parseInt(viewType == "hold" ? holdT2 : t2) * parseInt(viewType == "hold" ? holdR2 : r2) || 0,
        [viewType == "hold" ? "holdP3" : "p3"]: parseInt(viewType == "hold" ? holdT3 : t3) * parseInt(viewType == "hold" ? holdR3 : r3) || 0,
      });
      if (viewType == "hold") {
        if (
          (holdT2 == "" ||
            holdT2 == null ||
            holdT1 == "" ||
            holdT1 == null ||
            parseInt(holdT2) < parseInt(holdT1)) &&
          dropdownShow?.includes("t2")
        ) {
          handleErrorModal("T2", "T1", true);
        }
        if (
          (holdT3 == "" ||
            holdT3 == null ||
            holdT2 == "" ||
            holdT2 == null ||
            parseInt(holdT3) < parseInt(holdT2)) &&
          dropdownShow?.includes("t3")
        ) {
          handleErrorModal("T3", "T2", true);
        }
        if (
          (holdR2 == "" ||
            holdR2 == null ||
            holdR1 == "" ||
            holdR1 == null ||
            parseInt(holdR2) < parseInt(holdR1)) &&
          dropdownShow?.includes("t2")
        ) {
          handleErrorModal("R2", "R1", true);
        }
        if (
          (holdR3 == "" ||
            holdR3 == null ||
            holdR2 == "" ||
            holdR2 == null ||
            parseInt(holdR3) < parseInt(holdR2)) &&
          dropdownShow?.includes("t3")
        ) {
          handleErrorModal("R3", "R2", true);
        }
      } else {

        if (
          (t2 == "" ||
            t2 == null ||
            t1 == "" ||
            t1 == null ||
            parseInt(t2) < parseInt(t1)) &&
          dropdownShow?.includes("t2")
        ) {
          handleErrorModal("T2", "T1", true);
        }
        if (
          (t3 == "" ||
            t3 == null ||
            t2 == "" ||
            t2 == null ||
            parseInt(t3) < parseInt(t2)) &&
          dropdownShow?.includes("t3")
        ) {
          handleErrorModal("T3", "T2", true);
        }
        if (
          (r2 == "" ||
            r2 == null ||
            r1 == "" ||
            r1 == null ||
            parseInt(r2) < parseInt(r1)) &&
          dropdownShow?.includes("t2")
        ) {
          handleErrorModal("R2", "R1", true);
        }
        if (
          (r3 == "" ||
            r3 == null ||
            r2 == "" ||
            r2 == null ||
            parseInt(r3) < parseInt(r2)) &&
          dropdownShow?.includes("t3")
        ) {
          handleErrorModal("R3", "R2", true);
        }



      }
    } else if (selectedSchemeType == 4) {
      const { upperCap, incPerTarget, installationTarget, holdUpperCap, holdInsPerTarget, holdInsTarget, InsPerTarget, InsTarget } =
        formik?.values[index];
      if (viewType == "hold") {
        if (
          holdUpperCap == "" ||
          holdUpperCap == null ||
          holdInsPerTarget == "" ||
          holdInsPerTarget == null ||
          parseInt(holdInsPerTarget) <= parseInt(holdUpperCap)
        ) {
          handleErrorModal("Inc Per Target", "Upper Cap", true);
        }
        formik?.setFieldValue(index, {
          ...y,
          holdInsTarget: parseInt(holdInsTarget) || 0,
          holdInsPerTarget: parseInt(holdInsPerTarget) || 0,
          UpperCap: parseInt(holdUpperCap) || 0,
          holdP1: parseInt(holdUpperCap) * parseInt(holdInsTarget) || 0,
        });

      } else {
        if (
          upperCap == "" ||
          upperCap == null ||
          incPerTarget == "" ||
          incPerTarget == null ||
          parseInt(incPerTarget) <= parseInt(upperCap)
        ) {
          handleErrorModal("Inc Per Target", "Upper Cap", true);
        }
        formik?.setFieldValue(index, {
          ...y,
          installationTarget: parseInt(installationTarget) || 0,
          incPerTarget: parseInt(incPerTarget) || 0,
          upperCap: parseInt(upperCap) || 0,

          holdP1: parseInt(upperCap) * parseInt(incPerTarget) || 0,
        });
      }
    } else if (selectedSchemeType == 5) {
      const { installationTarget, incPerTarget, InsTarget, InsPerTarget, holdInsTarget, holdInsPerTarget } = formik?.values[index];
      if (viewType == "hold") {

        if (
          holdInsTarget == null &&
          holdInsTarget == null &&
          parseInt(InsPerTarget) <= parseInt(holdInsTarget)
        ) {
          handleErrorModal("incPerTarget", "installationTarget", true);
        }

        formik?.setFieldValue(index, {
          ...y,
          holdInsTarget: parseInt(holdInsTarget) || 0,
          holdInsPerTarget: parseInt(holdInsPerTarget) || 0,
          holdP1: parseInt(holdInsTarget) * parseInt(holdInsPerTarget) || 0,
        });
      } else {
        if (
          installationTarget == null &&
          incPerTarget == null &&
          parseInt(incPerTarget) <= parseInt(installationTarget)
        ) {
          handleErrorModal("incPerTarget", "installationTarget", true);
        }

        formik?.setFieldValue(index, {
          ...y,
          installationTarget: parseInt(installationTarget) || 0,
          incPerTarget: parseInt(incPerTarget) || 0,
          holdP1: parseInt(installationTarget) * parseInt(incPerTarget) || 0,
        });
      }
    } else {
      let { installationTarget, incPerTarget, InsTarget, holdInsTarget, InsPerTarget, holdInsPerTarget } = formik?.values[index];
      if (viewType == "hold") {
        if (
          holdInsTarget == null &&
          holdInsPerTarget == null &&
          parseInt(holdInsPerTarget) <= parseInt(holdInsTarget)
        ) {
          handleErrorModal("incPerTarget", "installationTarget", true);
        }

        formik?.setFieldValue(index, {
          ...y,
          holdInsTarget: parseInt(holdInsTarget) || 0,
          holdInsPerTarget: parseInt(holdInsPerTarget) || 0,
          holdP1: parseInt(holdInsTarget) * parseInt(holdInsPerTarget) || 0,
        });
      } else {
        if (
          installationTarget == null &&
          incPerTarget == null &&
          parseInt(incPerTarget) <= parseInt(installationTarget)
        ) {
          handleErrorModal("incPerTarget", "installationTarget", true);
        }

        formik?.setFieldValue(index, {
          ...y,
          installationTarget: parseInt(installationTarget) || 0,
          incPerTarget: parseInt(incPerTarget) || 0,
          p1: parseInt(installationTarget) * parseInt(incPerTarget) || 0,
        });
      }
    }
  };

  const getStateHead = () => {
    if (userRole == "ZADM") {
      return [
        {
          title: <div>State Head</div>,
          dataIndex: "stateHeadName",
          width: "180px",
        },
      ];
    } else {
      return [];
    }
  };

  const getCols = (schemeType) => {
    if (schemeType == 3) {
      return [
        {
          title: "Targets",
          dataIndex: "Target",
          width: "180px",
          children: [...getTargetT1(), ...getTargetT2(), ...getTargetT3()],
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "IPT",
          width: "180px",
          children: [
            ...getPayoutRateR1(),
            ...getPayoutRateR2(),
            ...getPayoutRateR3(),
          ],
        },
        {
          title: "Payout",
          dataIndex: "Payout",
          width: "180px",
          children: [...getPayoutP1(), ...getPayoutP2(), ...getPayoutP3()],
        },
      ];
    }
    if (schemeType == 4) {
      return [
        {
          title: "Installation Target",
          dataIndex: viewType == "hold" ? "holdInsTarget" : "installationTarget",

          render: (x, y, index) => {
            if (viewType == "hold") {
              return (
                <input
                  name={formik?.values?.[index]?.holdInsTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.holdInsTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      holdInsTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)

            } else {
              return (
                <input
                  name={formik?.values?.[index]?.installationTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.installationTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      installationTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)
            }
          },

          width: "190px",
        },
        {
          title: "Upper Cap",
          dataIndex: viewType == "hold" ? "holdUpperCap" : "upperCap",
          render: (x, y, index) => {
            if (viewType == "hold") {
              return (
                <input
                  name={formik?.values?.[index]?.holdUpperCap}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.holdUpperCap}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      holdUpperCap: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)

            } else {
              return (
                <input
                  name={formik?.values?.[index]?.upperCap}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.upperCap}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      upperCap: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)
            }
          },

          width: "120px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: viewType == "hold" ? "holdInsPerTarget" : "incPerTarget",
          render: (x, y, index) => {
            if (viewType == "hold") {
              return (
                <input
                  name={formik?.values?.[index]?.holdInsPerTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.holdInsPerTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      holdInsPerTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)

            } else {
              return (
                <input
                  name={formik?.values?.[index]?.incPerTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.incPerTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      incPerTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)
            }
          },

          width: "190px",
        },
        {
          title: "Payout",
          dataIndex: viewType == "hold" ? "holdP1" : "p1",
          width: "120px",
        },
      ];
    }
    if (schemeType == 8) {
      return [
        {
          title: "Installation Target",
          dataIndex: viewType == "hold" ? "holdInsTarget" : "installationTarget",

          render: (x, y, index) => {
            if (viewType == "hold") {
              return (
                <input
                  name={formik?.values?.[index]?.holdInsTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.holdInsTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      holdInsTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)

            } else {
              return (
                <input
                  name={formik?.values?.[index]?.installationTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.installationTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      installationTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)
            }
          },
          width: "190px",
        },

        {
          title: "inc. Per Tractor",
          dataIndex: viewType == "hold" ? "holdInsPerTarget" : "incPerTarget",
          render: (x, y, index) => {
            if (viewType == "hold") {
              return (
                <input
                  name={formik?.values?.[index]?.holdInsPerTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.holdInsPerTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      holdInsPerTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)

            } else {
              return (
                <input
                  name={formik?.values?.[index]?.incPerTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.incPerTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      incPerTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)
            }
          },
          width: "190px",
        },
        {
          title: "Payout",
          dataIndex: viewType == "hold" ? "holdP1" : "p1",
          width: "120px",
        },
      ];
    }
    if (schemeType == 5) {
      return [
        {
          title: "Installation Expected",
          dataIndex: viewType == "hold" ? "holdInsTarget" : "installationTarget",
          render: (x, y, index) => {
            if (viewType == "hold") {
              return (
                <input
                  name={formik?.values?.[index]?.holdInsTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.holdInsTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      holdInsTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)

            } else {
              return (
                <input
                  name={formik?.values?.[index]?.installationTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.installationTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      installationTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)
            }
          },

          width: "200px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: viewType == "hold" ? "holdInsPerTarget" : "incPerTarget",
          width: "190px",
          render: (x, y, index) => {
            if (viewType == "hold") {
              return (
                <input
                  name={formik?.values?.[index]?.holdInsPerTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.holdInsPerTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      holdInsPerTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)

            } else {
              return (
                <input
                  name={formik?.values?.[index]?.incPerTarget}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.incPerTarget}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      incPerTarget: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "90%", padding: "5px" }}
                />)
            }
          }

        },
        {
          title: "Payout",
          dataIndex: viewType == "hold" ? "holdP1" : "p1",
          width: "120px",
        },
      ];
    }
    return [];
  };

  const getDropdown = () =>
    selectedSchemeType == 3
      ? [
        {
          title: "Top Slab",
          width: "150px",
          render: (x, y, index) => {

            const arr1 = userRole == "ZADM" || userRole == "NSH" || userRole == "SADM" ? topTargetArr1(Array?.isArray(y?.topTargetArrState) ? y?.topTargetArrState[0] : y?.topTargetArrState)?.filter((x) => !x?.disabled) : topTargetArr
              ?.filter((x) => !x?.disabled)

            const handleChange = (value) => {
              let dropdownValues = formik?.values?.[index]?.dropdownscheme;
              let checkValue1 = value?.includes("t1");
              let checkValue2 = value?.includes("t2");
              let checkValue3 = value?.includes("t3");
              const handleDropDownScheme = () => {
                return checkValue3
                  ? ["t1", "t2", "t3"]
                  : checkValue2
                    ? ["t1", "t2"]
                    : checkValue1
                      ? ["t1"]
                      : [];
              };
              const handleDropdownSchemes = handleDropDownScheme();
              if (viewType == "hold") {

                formik.setFieldValue(index, {
                  ...x,
                  holdDrodownScheme: handleDropDownScheme() || null,
                  ...(handleDropdownSchemes.includes("t3")
                    ? {
                    } : handleDropdownSchemes.includes("t2")
                      ? {
                        BooksCollectionTargetT3T2: null,
                        holdR3: null,
                        holdT3: null,
                      }
                      : handleDropdownSchemes.includes("t1")
                        ? {
                          holdR2: null,
                          holdR3: null,
                          holdT2: null,
                          holdT3: null,
                          BooksCollectionTargetT2T1: null,
                        }
                        : {}),
                  BooksCollectionTargetT3T2: null,
                });

              } else {
                formik.setFieldValue(index, {
                  ...x,
                  dropdownscheme: handleDropDownScheme() || null,
                  ...(handleDropdownSchemes.includes("t3")
                    ? {
                    } : handleDropdownSchemes.includes("t2")
                      ? {
                        BooksCollectionTargetT3T2: null,
                        r3: null,
                        t3: null,
                      }
                      : handleDropdownSchemes.includes("t1")
                        ? {
                          r2: null,
                          r3: null,
                          t2: null,
                          t3: null,
                          BooksCollectionTargetT2T1: null,
                        }
                        : {}),
                  BooksCollectionTargetT3T2: null,
                });
              }
            }
            return (
              <Select
                onChange={handleChange}
                value={viewType == "hold" ? y?.holdDrodownScheme : y?.dropdownscheme}
                mode="multiple"
                autoClearSearchValue="multiple"
                className="w-100"
                allowClear
                onDeselect={false}
                showArrow={false}
                removeIcon={false}
              >
                {arr1?.map((x) => (
                  <Select.Option value={x?.value}>{x?.name}</Select.Option>
                ))}
              </Select>
            );
          },
        }
      ]
      : [];
  const getTargetT1 = () => [
    {
      title: "T1",
      dataIndex: viewType == "hold" ? "holdT1" : "t1",
      editable: true,
      width: "90px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdT1}
              value={formik?.values?.[index]?.holdT1 || ""}
              onBlur={() => {
                calculateFormula(index)
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  holdT1: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.t1}
              value={formik?.values?.[index]?.t1 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t1: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },

    },
  ]
  const getTargetT2 = () => [
    {
      title: "T2",
      dataIndex: viewType == "hold" ? "holdT2" : "t2",
      editable: true,
      width: "90px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdT2}
              value={formik?.values?.[index]?.holdT2 || ""}
              onBlur={() => {
                calculateFormula(index)
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  holdT2: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.t2}
              value={formik?.values?.[index]?.t2 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t2: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
  ]
  const getTargetT3 = () => [
    {
      title: "T3",
      dataIndex: viewType == "hold" ? "holdT3" : "t3",
      editable: true,
      width: "90px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdT3}
              value={formik?.values?.[index]?.holdT3 || ""}
              onBlur={() => {
                calculateFormula(index)
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  holdT3: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.t3}
              value={formik?.values?.[index]?.t3 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t3: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
  ]
  const getPayoutRateR1 = () => [
    {
      title: "R1",
      dataIndex: viewType == "hold" ? "holdR1" : "r1",
      editable: true,
      width: "90px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdR1}
              value={formik?.values?.[index]?.holdR1 || ""}
              onBlur={() => {
                calculateFormula(index)
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  holdR1: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.r1}
              value={formik?.values?.[index]?.r1 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r1: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
  ]
  const getPayoutRateR2 = () => [
    {
      title: "R2",
      dataIndex: viewType == "hold" ? "holdR2" : "r2",
      editable: true,
      width: "90px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdR2}
              value={formik?.values?.[index]?.holdR2 || ""}
              onBlur={() => {
                calculateFormula(index)
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  holdR2: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.r2}
              value={formik?.values?.[index]?.r2 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r2: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
  ]
  const getPayoutRateR3 = () => [
    {
      title: "R3",
      dataIndex: viewType == "hold" ? "holdR3" : "r3",
      editable: true,
      width: "90px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdR3}
              value={formik?.values?.[index]?.holdR3 || ""}
              onBlur={() => {
                calculateFormula(index)
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  holdR3: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.r3}
              value={formik?.values?.[index]?.r3 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r3: parseInt(value),
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
  ]
  const getPayoutP1 = () => [
    {
      title: "P1",
      dataIndex: viewType == "hold" ? "holdP1" : "p1",
      width: "90px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t1") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>)
        } else {
          return (
            formik?.values?.[index]?.dropdownscheme?.includes("t1") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          )
        }
      }

    },
  ]
  const getPayoutP2 = () => [
    {
      title: "P2",
      dataIndex: viewType == "hold" ? "holdP2" : "p2",
      width: "90px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t2") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>)
        } else {
          return (
            formik?.values?.[index]?.dropdownscheme?.includes("t2") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          )
        }
      }
    },
  ]
  const getPayoutP3 = () => [
    {
      title: "P3",
      dataIndex: viewType == "hold" ? "holdP3" : "p3",
      width: "90px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t3") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>)
        } else {
          return (
            formik?.values?.[index]?.dropdownscheme?.includes("t3") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          )
        }
      }
    },
  ]
  const defaultColumns = useMemo(
    () => [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        fixed: "left",
        width: "120px",
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        fixed: "left",
        width: "180px",
      },
      {
        title: "Dealer Location",
        dataIndex: "dealerLocation",
        width: "120px",
        fixed: "left",
      },
      ...getDropdown(),
      {
        title: "State",
        dataIndex: "stateName",
        width: "120px",
      },
      ...getStateHead(),
      {
        title: "Territory Manager",
        dataIndex: "teritoryManager",
        width: "100px",
      },

      {
        title: "Start Month",
        dataIndex: "startMonth",
        render: (x) => dayjs(x).format("MMM'YY"),
        width: "120px",
      },
      {
        title: "End Month",
        dataIndex: "endMonth",
        render: (x) => dayjs(x).format("MMM'YY"),
        width: "120px",
      },
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        width: "250px",
      },
      ...getCols(selectedSchemeType),

      {
        title: "Remarks",
        dataIndex: "reason",
        dataIndex: viewType == "hold" ? "holdRemarks" : "reason",
        render: (x, y, index) => {
          if (viewType == "hold") {
            return (
              <input
                name={formik?.values?.[index]?.holdRemarks}
                placeholder="Type here"
                value={formik?.values?.[index]?.holdRemarks}
                onChange={(e) => {
                  let value = e.target?.value;
                  const sanitizedValue = value
                    ?.replace("<script>", "")
                    ?.replace("</script>", "")
                 //   .replace(/[^a-zA-Z0-9\s]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    holdRemarks: sanitizedValue,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />)
          } else {
            return (
              <input
                name={formik?.values?.[index]?.reason}
                placeholder="Type here"
                value={formik?.values?.[index]?.reason}
                onChange={(e) => {
                  let value = e.target?.value;
                  const sanitizedValue = value
                    ?.replace("<script>", "")
                    ?.replace("</script>", "")
                  //  .replace(/[^a-zA-Z0-9\s]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    reason: sanitizedValue,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />)
          }
        },
        width: "150px",
      },
    ],

    [formik?.values, schemeLockedStatus, viewType]
  );

  const checkValidationFun = () => {
    let extraValidation = {};
    formik?.values?.map((x, index) => {
      let topTargetDetails = x?.dropdownscheme;
      let errors = [];
      if (selectedSchemeType == 3) {

        if (viewType == "hold") {
          if (x?.holdDrodownScheme?.length == 0) {
            errors.push("Please select top target");
          }
          if (
            x?.holdDrodownScheme?.includes("t3") &&
            parseInt(x?.holdT3 || 0) <= parseInt(x?.holdT2 || 0)
          ) {
            errors.push("T3 should be greater than T2");
          }
          if (
            x?.holdDrodownScheme?.includes("t2") &&
            parseInt(x?.holdT2 || 0) <= parseInt(x?.holdT1 || 0)
          ) {
            errors.push("T2 should be greater than T1");
          }
          if (
            x?.holdDrodownScheme?.includes("t3") &&
            parseInt(x?.holdR3 || 0) <= parseInt(x?.holdR2 || 0)
          ) {
            errors.push("R3 should be greater than R2");
          }
          if (
            x?.holdDrodownScheme?.includes("t2") &&
            parseInt(x?.holdR2 || 0) <= parseInt(x?.holdR1 || 0)
          ) {
            errors.push("R2 should be greater than R1");
          }
          if (!x.holdR1) {
            errors.push("Please enter R1");
          }
          if (!x.holdR2 && x?.holdDrodownScheme?.includes("t2")) {
            errors.push("Please enter R2");
          }
          if (!x.holdR3 && x?.holdDrodownScheme?.includes("t3")) {
            errors.push("Please enter R3");
          }
          if (!x.holdT1) {
            errors.push("Please enter T1");
          }
          if (!x.holdT2 && x?.holdDrodownScheme?.includes("t2")) {
            errors.push("Please enter T2");
          }
          if (!x.holdT3 && x?.holdDrodownScheme?.includes("t3")) {
            errors.push("Please enter T3");
          }
        } else {
          if (topTargetDetails?.length == 0) {
            errors.push("Please select top target");
          }
          if (
            topTargetDetails?.includes("t3") &&
            parseInt(x?.t3 || 0) <= parseInt(x?.t2 || 0)
          ) {
            errors.push("T3 should be greater than T2");
          }
          if (
            topTargetDetails?.includes("t2") &&
            parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0)
          ) {
            errors.push("T2 should be greater than T1");
          }
          if (
            topTargetDetails?.includes("t3") &&
            parseInt(x?.r3 || 0) <= parseInt(x?.r2 || 0)
          ) {
            errors.push("R3 should be greater than R2");
          }
          if (
            topTargetDetails?.includes("t2") &&
            parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0)
          ) {
            errors.push("R2 should be greater than R1");
          }
          if (!x.r1) {
            errors.push("Please enter R1");
          }
          if (!x.r2 && topTargetDetails?.includes("t2")) {
            errors.push("Please enter R2");
          }
          if (!x.r3 && topTargetDetails?.includes("t3")) {
            errors.push("Please enter R3");
          }
          if (!x.t1) {
            errors.push("Please enter T1");
          }
          if (!x.t2 && topTargetDetails?.includes("t2")) {
            errors.push("Please enter T2");
          }
          if (!x.t3 && topTargetDetails?.includes("t3")) {
            errors.push("Please enter T3");
          }
        }
      }
      if (selectedSchemeType == 4) {
        if (viewType == "hold") {

          if (!x.holdInsTarget) {
            errors.push("Please enter InstallationTarget");
          }
          if (!x.holdUpperCap) {
            errors.push("Please enter upperCap");
          }
          if (!x.holdInsPerTarget) {
            errors.push("Please enter incPerTarget");
          }
        } else {
          if (!x.installationTarget) {
            errors.push("Please enter InstallationTarget");
          }
          if (!x.upperCap) {
            errors.push("Please enter upperCap");
          }
          if (!x.incPerTarget) {
            errors.push("Please enter incPerTarget");
          }
        }
      }
      if (selectedSchemeType == 5) {

        if (viewType == "hold") {
          if (!x.holdInsTarget) {
            errors.push("Please enter InstallationTarget");
          }

          if (!x.holdInsPerTarget) {
            errors.push("Please enter incPerTarget");
          }
        } else {
          if (!x.installationTarget) {
            errors.push("Please enter InstallationTarget");
          }
          if (!x.incPerTarget) {
            errors.push("Please enter incPerTarget");
          }
        }
      }
      //Single Target
      if (selectedSchemeType == 8) {

        if (viewType == "hold") {
          if (!x.holdInsTarget) {
            errors.push("Please enter InstallationTarget");
          }

          if (!x.holdInsPerTarget) {
            errors.push("Please enter incPerTarget");
          }
        } else {
          if (!x.installationTarget) {
            errors.push("Please enter InstallationTarget");
          }
          if (!x.incPerTarget) {
            errors.push("Please enter incPerTarget");
          }
        }
      }
      if (errors.length > 0) {
        extraValidation[x?.dealerCode] = errors;
      }
    });

    setCodeError(Object?.keys(extraValidation || {}));

    if (Object?.keys?.(extraValidation)?.length != 0) {
      showErrorDialog(extraValidation);
    } else if (Object?.keys?.(extraValidation)?.length == 0) {
      showModal();
    }
  };

  const handleSubmit = (e) => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const userRole = userData.userRole;
    const handleDropDownSchemeCheckIndex = (x) => {
      let { t1, t2, t3 } = x.reduce((a, v) => ({ ...a, [v]: v }), {});

      let data =
        t1 && !t2 && !t3
          ? "t1"
          : t1 && t2 && !t3
            ? "t2"
            : t1 && t2 && t3
              ? "t3"
              : "";
      return data;
    };
    if (selectedSchemeType == 3) {
      let data = [];
      formik?.values.forEach((x) => {
        data.push({
          ...x,
          dealerCode: x.dealerCode,
          dealerId: +x.dealerId,
          startMonth: x.startMonth,
          endMonth: x.endMonth,
          isSAP: true,
          isActive: true,
          state: x.stateId,
          status: 1,
          isSubmitted: 1,
          holdStatus: x?.holdStatus,
          fyear: currentFiscalYear,
          schemeType: "Slabbased",
          incPerTarget: parseInt(x.incPerTarget) || 0,
          installationTarget: parseInt(x?.installationTarget) || 0,
          // schemeTypeId: schemeType,
          userid: +userId,
          username: userName,
          userrole: userRole,
          topTarget: x?.dropdownscheme?.toString(),
          t1: +x.t1 || 0,
          t2: +x.t2 || 0,
          t3: +x.t3 || 0,
          r1: +x.r1 || 0,
          r2: +x.r2 || 0,
          r3: +x.r3 || 0,
          p1: +x.p1 || x?.payout || 0,
          p2: +x.p2 || 0,
          p3: +x.p3 || 0,
          reason: x.reason,
          HoldReason:x.holdRemarks
        });
      });
      if (formik?.values?.[0]?.holdStatus==9){
      
        data = data.map(d => {
          delete d.reason;
          return d;
        });
      }else {
        data = data.map(d => {
          delete d.HoldReason;
          return d;
        });
      }

      let checkValidation =
        selectedSchemeType == 3
          ? formik?.values?.filter((x) =>
            handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
              ? !x.t1 || !x.r1
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
                ? !x.t1 ||
                !x.t2 ||
                !x.r1 ||
                !x.r2 ||
                parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||
                !x.TEPvol
                : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
                  ? !x.t1 ||
                  !x.t2 ||
                  !x.t3 ||
                  !x.r1 ||
                  !x.r2 ||
                  !x.r3 ||
                  parseInt(x?.t3 || 0) <= parseInt(x?.t2 || 0) ||
                  parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                  parseInt(x?.r3 || 0) <= parseInt(x?.r2 || 0) ||
                  parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||
                  !x.TEPvol
                  : !x.t1 || !x.t2 || !x.t3 || !x.r1 || !x.r2 || !x.r3
          )
          : selectedSchemeType == 4
            ? formik?.values?.filter(
              (x) => !x?.installationTarget || !x.upperCap || !x.incPerTarget
            )
            : formik?.values?.filter(
              (x) => !x?.installationTarget || !x.incPerTarget
            );
      let errorDetails = Object.entries(
        checkValidation?.[0] ? checkValidation?.[0] : {}
      )
        ?.filter((x) =>
          checkValidation?.[0]?.dropdownscheme?.includes("t3")
            ? !x[1] &&
            (x[0] == "t1" ||
              x[0] == "t2" ||
              x[0] == "t3" ||
              x[0] == "r1" ||
              x[0] == "r2" ||
              x[0] == "r3")
            : checkValidation?.[0]?.dropdownscheme?.includes("t2")
              ? !x[1] &&
              (x[0] == "t1" || x[0] == "t2" || x[0] == "r1" || x[0] == "r2")
              : checkValidation?.[0]?.dropdownscheme?.includes("t1")
                ? !x[1] && (x[0] == "t1" || x[0] == "r1")
                : !x[0]
        )
        .map((x) =>
          x[0] == "t1"
            ? "T1"
            : x[0] == "t2"
              ? "T2"
              : x[0] == "t3"
                ? "T3"
                : x[0] == "r1"
                  ? "R1"
                  : x[0] == "r2"
                    ? "R2"
                    : x[0] == "r3"
                      ? "R3"
                      : x[0]
        );
      let extraValidation = [];

      if (selectedSchemeType == 3) {
        if (checkValidation?.[0]?.dealerCode) {
          formik?.values
            ?.filter((x) => x?.dealerCode == checkValidation?.[0]?.dealerCode)
            .map((x, index) => {
              let topTargetDetails = x?.dropdownscheme;

              let t3Details = x?.t3 != "" ? parseInt(x?.t3 || 0) : 0;
              let t2Details = x?.t2 != "" ? parseInt(x?.t2 || 0) : 0;
              let t1Details = x?.t1 != "" ? parseInt(x?.t1 || 0) : 0;

              let r3Details = x?.r3 != "" ? parseInt(x?.r3 || 0) : 0;
              let r2Details = x?.r2 != "" ? parseInt(x?.r2 || 0) : 0;
              let r1Details = x?.r1 != "" ? parseInt(x?.r1 || 0) : 0;
              if (topTargetDetails?.length == 0) {
                extraValidation.push("Please select Top Target");
              }

              if (
                topTargetDetails?.includes("t3") &&
                parseInt(t3Details) <= parseInt(t2Details)
              ) {
                extraValidation.push("T3 should be greater than T2");
              }
              if (
                topTargetDetails?.includes("t2") &&
                parseInt(t2Details) <= parseInt(t1Details)
              ) {
                extraValidation.push("T2 should be greater than T1");
              }
              if (
                topTargetDetails?.includes("t3") &&
                parseInt(r3Details) <= parseInt(r2Details)
              ) {
                extraValidation.push("R3 should be greater than R2");
              }
              if (
                topTargetDetails?.includes("t2") &&
                parseInt(r2Details) <= parseInt(r1Details)
              ) {
                extraValidation.push("R2 should be greater than R1");
              }
            });
        }
      }

      let errorShow = errorDetails?.concat(extraValidation);
      if (errorShow?.length != 0) {
        showErrorDialog(errorDetails);
      } else {
        if (data?.[0]?.holdStatus == 9) {

          const result = data.map(
            ({ StartMonth, EndMonth, holdDrodownScheme, holdT1, holdT2, holdT3, holdP1, holdP2, holdP3, holdR1, holdR2, holdR3, ...rest }) => 
              ({ ...rest, T1: holdT1, T2: holdT2, T3: holdT3, P1: holdP1, P2: holdP2, P3: holdP3, R1: holdR1, R2: holdR2, R3: holdR3, TopTarget: holdDrodownScheme?.toString() })
          );

          dispatch(postUpdateHoldScheme(result)).then((data) => {
            backBtn();
          });
        } else {
          dispatch(updateSchemeGenerateApiAsync(data)).then((result) => {

            if (
              result?.payload?.errorMessage == "Scheme updated successfully!!"
            ) {
              message.success({
                content: "Scheme updated successfully!!",
                className: "custom-message",
              });
              backBtn();
            }
          });
        }
      }
    } else if (selectedSchemeType == 4) {
      let data = [];
      formik?.values.forEach((x) => {
        data.push({
          ...x,
          dealerCode: x.dealerCode,
          dealerId: +x.dealerId,
          startMonth: x.startMonth,
          endMonth: x.endMonth,
          isSAP: true,
          isActive: true,
          state: x.stateId,
          status: 1,
          isSubmitted: 1,
          fyear: currentFiscalYear,
          userid: +userId,
          username: userName,
          userrole: userRole,
          payout: x.payout ? parseInt(x.payout) : 0,
          incPerTarget: parseInt(x.incPerTarget) || 0,
          p1: +x.p1 || x?.payout || 0,
          reason: x.reason,
          upperCap: +x.upperCap,
          holdStatus: x?.holdStatus,
          installationTarget: parseInt(x?.installationTarget) || 0,
          HoldReason:x.holdRemarks
        });
      });
      if (formik?.values?.[0]?.holdStatus==9){
       
        data = data.map(d => {
          delete d.reason;
          return d;
        });
      }else {
        data = data.map(d => {
          delete d.HoldReason;
          return d;
        });
      }

      if (data?.[0]?.holdStatus == 9) {
        const result = data.map(
          ({ StartMonth, EndMonth, dropdownscheme, incPerTarget, upperCap, installationTarget, holdP1, holdUpperCap, holdInsPerTarget, holdInsTarget, ...rest }) => ({
            ...rest,
            InsPerTarget: holdInsPerTarget,
            UpperCap: holdUpperCap,
            InsTarget: holdInsTarget,
            P1: holdP1
          })
        );
       
        dispatch(postUpdateHoldScheme(result)).then((data) => {
          backBtn();
        });
      } else {
        dispatch(updateSchemeGenerateApiAsync(data)).then((result) => {
          if (result?.payload?.errorMessage == "Scheme updated successfully!!") {
            message.success({
              content: "Record Updated Successfully",
              className: "custom-message",
            });
            backBtn();
          }
        });
      }
    } else if (selectedSchemeType == 5) {
      let data = [];
      formik?.values.forEach((x) => {
        data.push({
          ...x,
          dealerCode: x.dealerCode,
          dealerId: +x.dealerId,
          startMonth: x.startMonth,
          endMonth: x.endMonth,
          isSAP: true,
          isActive: true,
          state: x.stateId,
          status: 1,
          isSubmitted: 1,
          fyear: currentFiscalYear,
          userid: +userId,
          username: userName,
          userrole: userRole,
          payout: x.payout ? parseInt(x.payout) : 0,
          incPerTarget: parseInt(x.incPerTarget) || 0,
          p1: +x.p1 || x?.payout || 0,
          reason: x.reason,
          holdStatus: x?.holdStatus,
          installationTarget: parseInt(x?.installationTarget) || 0,
          HoldReason:x.holdRemarks

        });
      });
      if (formik?.values?.[0]?.holdStatus==9){
        
        data = data.map(d => {
          delete d.reason;
          return d;
        });
      }else {
        data = data.map(d => {
          delete d.HoldReason;
          return d;
        });
      }

      if (data?.[0]?.holdStatus == 9) {

        const result = data.map(
          ({ StartMonth, EndMonth, dropdownscheme, incPerTarget, upperCap, holdInsPerTarget, holdUpperCap, holdInsTarget, installationTarget, holdP1, ...rest }) => ({
            ...rest,
            InsPerTarget: holdInsPerTarget,
            UpperCap: holdUpperCap,
            InsTarget: holdInsTarget,
            P1: holdP1,
          })
        );

        dispatch(postUpdateHoldScheme(result)).then((data) => {
          backBtn();
        });
      } else {
        dispatch(updateSchemeGenerateApiAsync(data)).then((result) => {
          if (result?.payload?.errorMessage == "Scheme updated successfully!!") {
            message.success({
              content: "Record Updated Successfully",
              className: "custom-message",
            });
            backBtn();
          }
        });
      }
    } else {
      let data = [];
      formik?.values.forEach((x) => {
        data.push({
          ...x,
          dealerCode: x.dealerCode,
          dealerId: +x.dealerId,
          startMonth: x.startMonth,
          endMonth: x.endMonth,
          isSAP: true,
          isActive: true,
          state: x.stateId,
          status: 1,
          isSubmitted: 1,
          fyear: currentFiscalYear,
          // schemeTypeId: schemeType,
          userid: +userId,
          username: userName,
          holdStatus: x?.holdStatus,
          payout: x.payout ? parseInt(x.payout) : 0,
          incPerTarget: parseInt(x.incPerTarget) || 0,
          installationTarget: +x.Target,
          p1: +x.p1 || x?.payout || 0,
          reason: x.reason,
          installationTarget: parseInt(x?.installationTarget) || 0,
          userrole: userRole,
          HoldReason:x.holdRemarks
        });
      });
      if (formik?.values?.[0]?.holdStatus==9){
       
        data = data.map(d => {
          delete d.reason;
          return d;
        });
      }else {
        data = data.map(d => {
          delete d.HoldReason;
          return d;
        });
      }
     
      if (data?.[0]?.holdStatus == 9) {

        const result = data.map(
          ({ StartMonth, EndMonth, dropdownscheme, incPerTarget, upperCap, installationTarget, holdP1, holdInsTarget, holdUpperCap, holdInsPerTarget, ...rest }) => ({
            ...rest,
            InsPerTarget: holdInsPerTarget,
            UpperCap: holdUpperCap,
            InsTarget: holdInsTarget,
            P1: holdP1
          })
        );

        dispatch(postUpdateHoldScheme(result)).then((data) => {
          backBtn();
        });
      } else {
        dispatch(updateSchemeGenerateApiAsync(data)).then((result) => {
          if (result?.payload?.errorMessage == "Scheme updated successfully!!") {
            message.success({
              content: "Record Updated Successfully",
              className: "custom-message",
            });
            backBtn();
          }
        });
      }
    }
  };

  return (
    <div className="create_scheme_wrap general-bscheme">
      <Button
        type="primary"
        icon={<ArrowBackRounded />}
        size="middle"
        onClick={() => {
          backBtn();
        }}
      />
      <div className="table_scheme_wrap generate-finace-report">
        <Form>
          <Table
            // className="finance"
            loading={loadingState == "pending" ? true : false}
            columns={defaultColumns}
            dataSource={formik?.values}
            bordered
            pagination={false}
            scroll={{ x: "max-content" }}
            size="middle"
          />
        </Form>
        <div className="pt-3 d-flex f-reverse">
          <Button
            type="default"
            shape="round"
            style={{
              margin: "10px",
              background: "#FCE838",
              fontWeight: "700",
              textTransform: "uppercase",
              border: "none",
              padding: "20px",
            }}
            size="large"
            onClick={checkValidationFun}
          >
            Update & Submits
          </Button>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onCancel={() => {
          handleSubmit();
          handleOk();
        }}
        onOK={handleCancel}
          cancelText="Yes"
        okText="No"
        maskClosable={false}
        destroyOnClose={true}
      >
        <h2>Are you sure you want to submit!</h2>
      </Modal>

      {/* <ValidationErrorsModal
        open={openErrorDialog.open}
        onClose={() => {
          setOpenErrorDialog({ open: false, errorsFields: [] });
        }}
        errorsFields={openErrorDialog?.errorsFields}
      /> */}
      <ValidationDifferentSchemeError
        open={openErrorDialog.open}
        onClose={() => {
          setOpenErrorDialog({ open: false, errorsFields: {} });
        }}
        errorsFields={openErrorDialog?.errorsFields}
      />
      <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>
        <p>
          {errorItem.firstItem} should be greater then {errorItem.secondItem}
        </p>
      </SMDialog>
    </div>
  );
};
export default AwaitingInstallationEdit;
