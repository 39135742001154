import ToastifyShow from "../../components/ToastifyShow";
import axiosAPI from "../../services/axiosApi";
import { decryptData } from "../../utils/localStorageEncodeDecode";

export const getNotificationSadmApi = async (data) => {
  const { userRole } = decryptData(sessionStorage.getItem("userInfo"))||{};
  try {
    const { userRole } = decryptData(sessionStorage.getItem("userInfo")) || {};
    const response = axiosAPI
    .post(
      userRole == "SADM"
      ? `Dashboard/GetAllNotificationCountforSADM`
      : userRole == "ZADM"
      ? "Dashboard/GetAllNotificationCountforZADM"
      : "Dashboard/GetAllNotificationCountforNSH",
      data
    )

    

      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
        ToastifyShow("Something went wrong", "error");
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};
