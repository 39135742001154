import React, { useEffect, useState } from "react";

import { Dropdown, Space, Col, Divider, Row, Button, Menu, Badge, Avatar, Statistic } from "antd";
// import Avatar from "../../assets/images/profileImg.jpg";
import ProfileImage from "./profileImage";
import TurnAroundTime from "./turnAroundTime";
import Position from "./position/index.js";
import "./index.css";
import { ReactComponent as Downwards } from "../../assets/vector/downwards.svg";
import { setHeading } from "../../redux/collection/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, logoutUser } from "../../redux/authUser/authSlice";
import dayjs from "dayjs";
import NotificationsIcon from '@mui/icons-material/Notifications';
import ToastifyShow from "../ToastifyShow";
import {
  expiredAMGRDate,
  expiredSADMDate,
  expiredZADMDate,
} from "./schemeExpiredDetails";
import FullScreenLoader from "../loader/FullScreenLoader";
import StatusModal from "../modal/alertModal/StatusModal";
import { decryptData } from "../../utils/localStorageEncodeDecode";
import axiosAPI from "../../services/axiosApi.js"
import { BASE_API_PATH } from "../../config.js";
import { BellOutlined } from "@ant-design/icons";
const items = [
  {
    key: "1",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        1st menu item
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aliyun.com"
      >
        2nd menu item (disabled)
      </a>
    ),
    disabled: true,
  },
  {
    key: "3",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        3rd menu item (disabled)
      </a>
    ),
    disabled: true,
  },
];

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);
  const heading = useSelector((state) => state.header.heading);
  const { loadingLogin } = useSelector((state) => state.user);
  // const [heartbeatResponse, setHeartbeatResponse] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    const pathName = window.location.pathname.split("/");
    dispatch(setHeading(pathName[1]));
  }, [window.location.pathname]);


  const {
    sadmDataList,
    
  } = useSelector((state) => state.notificationData);


  const regularCount = sadmDataList?.totalAwaitingScheme + sadmDataList?.totalHoldAwaitingScheme
  const modificationCount = sadmDataList?.totalHoldModicationawaitingScheme+ sadmDataList?.totalModificationScheme
  const holdCount = sadmDataList?.totalHoldScheme 
  const totalCount =regularCount + modificationCount +holdCount
  console.log(sadmDataList,regularCount,"sadmDataList")

  // useEffect(() => {
  //   const fetchHeartbeat = async () => {
  //     try {
  //       const response = await axiosAPI.get(`${BASE_API_PATH}/Login/HeartBeat`)
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch heartbeat data');
  //       }
  //       // const data = await response.json();
  //       // setHeartbeatResponse(data);
  //     } catch (error) {
  //       console.error('Error fetching heartbeat data:', error);
  //     }
  //   };
  //   fetchHeartbeat();
  //   const intervalId = setInterval(fetchHeartbeat, 30000);
  //   return () => clearInterval(intervalId);
  // }, []); 

  const Logout = async () => {
    setLogoutModal(true);
    // if (window.confirm('Are you sure you want to logout?') == true) {
    //   await dispatch(logoutUser())
    //   await dispatch(logout())
    //   window.location.reload();
    //   return;
    // }
    // return;
    // localStorage.clear();
    // await navigate("/login");
  };

  // useEffect(() => {
  //   if(visibleRows?.length > 0 || listData.length > 0){
  //     window.addEventListener('beforeunload', alertUser)
  //   }
  //   return () => {
  //       window.removeEventListener('beforeunload', alertUser)
  //   }
  // })

  const [schemeExpireDate, setSchemeExpireDate] = useState(0);
  const current_date = dayjs().get("date");
  const end_date_AM = 11;
  const end_date = dayjs().endOf("month").get("date");
  const scheme_end_date = end_date - 6;
  const {userRole} = decryptData(sessionStorage.getItem("userInfo"))||{};

  //

  // upto to 11th of the Month	Area Manager
  //closed on 12 morning
  // 11th - 25th [E-6 days]
  // January is the E = 31 - 6 = 25
  //---------------------------------->
  //22,23,24, last date 24
  //closed on 25 morning
  // January - March is the E = 31 - 6 = 25	State Head
  // 26th - 29th [E-2 days]
  // January is the E = 31 -2 = 29
  // January - March is the E = 31 - 2 = 29
  // --------------------->
  // 	Regional/Zonal Head
  //28 before 3 days
  //28 closed

  useEffect(() => {
    if (
      userRole === "AMGR" &&
      (current_date == 11 || current_date == 10 || current_date == 9)
    ) {
      setSchemeExpireDate(current_date - expiredAMGRDate);
    }
    if (
      userRole === "SADM" &&
      (current_date == 22 || current_date == 23 || current_date == 24)
    ) {
      setSchemeExpireDate(current_date - expiredSADMDate);
    }
    if (
      (userRole === "ZADM" && current_date == 25) ||
      current_date == 26 ||
      current_date == 27
    ) {
      setSchemeExpireDate(current_date - expiredZADMDate);
    }
    // else if(userRole === 'PMIS'){
    //   setSchemeExpireDate (end_date_AM - current_date)
    // }
    //   } else if(userRole === 'AMGR'){
    //     setSchemeExpireDate (end_date_AM - current_date)
    // }
  }, []);
  // PMIS date is not correct

  let dataHead = (x) => {
  
    switch (x) {
      case "WithdrawalScheme":
        return "Withdrawal Scheme";
      case "requestDeviation":
        return "REQUEST DEVIATION";
      case "requestDeviationLog":
          return "REQUEST DEVIATION LOG";
      case "installationModification":
        return "Installation Based ";
      case "modificationCollection":
        return "Collection Based";
      case "modificationAwatingApproval":
        return "Approve Modification";
      case "awatingLogsModification":
        return " Modification Awaiting ";
      case "trackModification":
        return "Track Modification";
      case "editApprovalModification":
        return "Edit Approval Modification";
      case "schemeProgress":
        return "For My Approval";
      case "approvedschemeslog":
        return "Approved by Me";
      case "track":
        return "Collection Based";
      case "trackinstallation":
        return "Installation Based";
      case "merge":
        return "Merge";
        case "holdApproval":
          return "Scheme Hold";
      default:
        return x;
    }
  };
// Scheme Progress
//Pending With Me


  const checkForInactivity = () => {
    const expireTime = sessionStorage.getItem("expireTime");
    if (expireTime < Date.now()) {
      Logout();
    }
  };
  const updateExpireTime = () => {
    const expireTime1 = Date.now() + 10000;
    localStorage.setItem("expireTime", expireTime1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 2 * 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpireTime();
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);
    return () => {
      window.addEventListener("click", updateExpireTime);
      window.addEventListener("keypress", updateExpireTime);
      window.addEventListener("scroll", updateExpireTime);
      window.addEventListener("mousemove", updateExpireTime);
    };
  }, []);
  const notifications = [
    {
      id: 1,
      title: 'New message from Biswajit',
      description: 'New scheme Added on approval awaiting.',
    },
    {
      id: 2,
      title: 'Server downtime',
      description: 'The server will be down for maintenance at 3 AM.',
    },
  ];
  const handleDropdownVisibleChange = (visible) => {
    setDropdownOpen(visible);
  };
   
const menu = (
  <Menu style={userRole === "SADM"?{ width: '200px' }:{ width: '470px' }}>

  
<Row
  gutter={[0, 0]}
  justify="space-between"
  align="middle"
  className="d-flex"
  style={{ border: '1px solid #d9d9d9', paddingTop: "20px", paddingBottom: "20px", margin:"20px" }} // Border color for Row
>
<Col span={userRole === "SADM" ? 24 :8} style={ userRole != "SADM"?{borderRight: '1px solid #d9d9d9' }:null}>
      <div style={{ textAlign: 'center', display: 'block' }}>
        <Statistic
          title="Awaiting Count"
          value={regularCount}
          valueStyle={{
            transition: 'transform 2s ease-in-out', // Slow in and out
            cursor: 'pointer',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.2)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
          }}
        />
      </div>
    </Col>
   { userRole != "SADM" && <Col span={userRole === "SADM" ?12 :8} style={{borderRight: '1px solid #d9d9d9' }}>
      <div style={{ textAlign: 'center', display: 'block' }}>
        <Statistic
          title="Modification Count"
          value={modificationCount}
          valueStyle={{
            transition: 'transform 2s ease-in-out', // Slow in and out
            cursor: 'pointer',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.2)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
          }}
        />
      </div>
    </Col>}
   { userRole != "SADM"  &&<Col span={8}>
      <div style={{ textAlign: 'center', display: 'block' }}>
        <Statistic
          title="Hold Count"
          value={holdCount}
          valueStyle={{
            transition: 'transform 2s ease-in-out', // Slow in and out
            cursor: 'pointer',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.2)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
          }}
        />
      </div>
    </Col>}
</Row>
    {/* <div   style={{  marginBottom:"20px" }}>
    {notifications.map((notification) => (
      <div key={notification.id} style={{marginLeft:"10px",marginRight:"10px"}}>
        <Menu.Item    style={{  padding:"15px" }}>
          <div>
            <strong>{notification.title}</strong>
            <div>{notification.description}</div>
          </div>
        </Menu.Item>
        <Divider  orientationMargin="0" style={{
        borderColor: '#d9d9d9',
        marginBottom: '0'
      }}/>
      </div>
    ))}</div> */}
  </Menu>
);

  
  return (
    <>
      <Row className="head_Wrapper p-relative">
        <Col className="head_title">
          <span>{dataHead(heading)}</span>
          {/* <span>{heading === 'requestDeviation' ? 'REQUEST DEVIATION' : heading ===  'installationModification' ? 'INSTALLATION MODIFICATION' : heading === 'modificationCollection' ? 'MODIFICATION COLLECTION' : heading}</span> */}
        </Col>
        <Row className="head_right_sec">
          {schemeExpireDate != 0 ? (
            <Col>
              {(userRole == "ZADM" &&
              userRole == "SADM" &&
              userRole == "AMGR") ? (
                <TurnAroundTime schemeExpireDate={schemeExpireDate} />
              ) : (
                ""
              )}
            </Col>
          ) : (
            <Col />
          )}
        { (!["AMGR", "PMIS", "COSM", "Finance"].includes(userRole) && (heading === "dashboard") )&&<Col>
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" style={{ cursor: 'pointer' }}  onVisibleChange={handleDropdownVisibleChange}>
      <Badge count={totalCount?totalCount:0} overflowCount={5}>
        <Avatar   shape="square"
          style={{
            color: dropdownOpen ? '#1890ff' : '#fff',
          }}
          icon={<BellOutlined />}/>
      </Badge>
    </Dropdown>
         
         </Col>}
          <Col>
            <ProfileImage />
          </Col>
          <Col>
            <Position />
          </Col>
          <Col>
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              dropdownRender={(menu) => (
                <div>
                  <Space>
                    <Button
                      className="logged-out-btn"
                      onClick={() => Logout()}
                      type="primary"
                    >
                      Logout!
                    </Button>
                  </Space>
                </div>
              )}
            >
              <Space style={{ cursor: "pointer", padding: 2 }}>
                <Downwards className="downwards" />
              </Space>
            </Dropdown>
          </Col>
        </Row>

        {loadingLogin == "pending" && <FullScreenLoader />}
      </Row>
      <StatusModal
        open={logoutModal}
        onOk={async () => {
          
          await dispatch(logoutUser());
          await dispatch(logout());
          // await window.location.reload();
          await setLogoutModal(false);
        }}
        onCancel={() => {
          setLogoutModal(false);
        }}
      >
        <p>Are you sure you want to Logout?</p>
      </StatusModal>
    </>
  );
};

export default Header;
