import React from 'react';
import './index.css';
import { SmileOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
const { confirm } = Modal;


const App = ({test,handleOk}) =>{
  const showPropsConfirm = () => {

    confirm({
      icon: <SmileOutlined />,
      title: 'Congratulation',
      content: 'Communicated successfully.',
      cancelText: 'close',
      onOk:{handleOk},
      open:{test},
      okButtonProps: {
        disabled: true,
        style: { display: 'none' },
      },
    });
  };  
  
  return(
    <>
    
    {/* <Button onClick={showPropsConfirm}>Communicated</Button>; */}
    </>
  )

}
export default App;
