import { toast } from "react-toastify";

export default function ToastifyShow(x, toastifyType = "info") {
  toast.success(x, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    type: toastifyType ? toastifyType : "info",
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: 0,
  });
}

export const TextLocalString=(data,hideZero)=>{
  if(hideZero){
    if(data){
        return data?.toLocaleString("en-IN")||""
    }else{
      return ""
    }
  }else{
  return data?.toLocaleString("en-IN")||""
  }
}