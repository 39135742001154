import { React, useEffect } from "react";
import { useNavigate, Outlet } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from "../../assets/vector/dashboard.svg";
import { ReactComponent as CollectionIcon } from "../../assets/vector/collection.svg";
import { ReactComponent as InstallationIcon } from "../../assets/vector/installation.svg";
import { ReactComponent as Deviation } from "../../assets/vector/deviation.svg";
import { ReactComponent as SwarajIcon } from "../../assets/vector/swarajIcon.svg";
import { ReactComponent as CollapseIcon } from "../../assets/vector/collapseIcon.svg";
import { ReactComponent as ExpandIcon } from "../../assets/vector/expandIcon.svg";
import { ReactComponent as AwaitingIcon } from "../../assets/vector/awating.svg";
import { ReactComponent as InstallationIcon1 } from "../../assets/vector/truck-moving-solid.svg";
import { ReactComponent as MergeIcon } from "../../assets/vector/merge.svg";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import {
    EditOutlined,
    LeftCircleTwoTone,
    RightCircleTwoTone,
} from '@ant-design/icons';
import Swaraj from "../../assets/images/swaraj.jpg";
import { Layout, Menu, Button, theme, Modal, Result, Tooltip } from 'antd';
import { useState } from 'react';
import { Col, Row } from 'antd';
import SchemeHeader from "../header/index.js";
import './index.css';
import dayjs from "dayjs";
import { expiredSADMDate, getExpiredStatusAMGR, getExpiredStatusSADM, getExpiredStatusZADM } from "../header/schemeExpiredDetails";
import { decryptData } from "../../utils/localStorageEncodeDecode";

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label:  <Tooltip placement="right" title={label} >{label}</Tooltip>,
        type,
    };
}

// submenu keys of first level
const rootSubmenuKeys = ['dashboard', 'collection', 'installation', 'table', 'modificationCollection', 'installationModification', 'trackModification', 'awatingLogsModification', 'merge'];

const { Header, Sider, Content } = Layout;


const Template = () => {
    const navigate = useNavigate();
    const [openKeys, setOpenKeys] = useState(['dashboard']);
    const [collapsed, setCollapsed] = useState(true);

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { userRole } = decryptData(sessionStorage.getItem("userInfo"));
    const items = [
        getItem('Dashboard', 'dashboard', <DashboardIcon />),
        !["NSH", "PMIS", "COSM", "Finance"].includes(userRole) && getItem('Collection', 'collection', <CollectionIcon />, [
            getItem('All Schemes', 'collection/allSchemes'),
            getItem('Create New Scheme', 'collection/createNewScheme'),
            getItem('Draft Schemes', 'collection/draftSchemes'),
        ]),

        !["NSH", "PMIS", "COSM", "Finance"].includes(userRole) && getItem('Installation', 'installation', <InstallationIcon />, [
            getItem('All Schemes', 'installation/allSchemes'),
            getItem('Create New Scheme', 'installation/createNewScheme'),
            getItem('Draft Schemes', 'installation/draftSchemes'),
        ]),
        !["AMGR", "COSM", "Finance", "PMIS"].includes(userRole) && getItem('Scheme Progress', '/schemeProgress', <AwaitingIcon />, [
            getItem('For My Approval', 'schemeProgress'),
            ["NSH", "ZADM", "PMIS", "SADM"].includes(userRole) && getItem('Approved by Me', '/approvedschemeslog'),
        ]),
        getItem('Track - All Schemes', 'Track', <InstallationIcon1 />, [
            getItem('Collection Based', 'track'),
            getItem('Installation Based', 'trackinstallation'),
        ]),

        ["NSH", "SADM", "ZADM", "COSM", "Finance", "PMIS"].includes(userRole) && getItem('Modify', 'modificationCollection', <EditOutlined />, [
            !["NSH", "COSM", "Finance", "PMIS"].includes(userRole) && getItem('Collection Based', 'modificationCollection'),
            !["NSH", "COSM", "Finance", "PMIS"].includes(userRole) && getItem('Installation Based', 'installationModification'),
            !["AMGR", "COSM", "Finance", "PMIS", "SADM"].includes(userRole) && getItem('Approve Modification', 'modificationAwatingApproval'),
            // ![ "AMGR", "COSM", "Finance","PMIS", "SADM"].includes(userRole) &&  getItem('Modification Awaiting  ', 'awatingLogsModification'),
            !["AMGR"].includes(userRole) && getItem('Track Modification', 'trackModification'),


        ]),
        // ["NSH","ZADM"].includes(userRole) && getItemP('Awaiting Logs Modification', 'awatingLogsModification', <EditOutlined />, [
        //     getItem('Track Modification', 'trackModification'),

        // ]),

        ["NSH","ZADM"].includes(userRole) && getItem('Hold Approval', 'holdApproval', <MoveUpIcon />),
        ["NSH", "ZADM", "PMIS", "COSM"].includes(userRole) && getItem('Deviation Request', 'requestDeviation', <Deviation />, [
            getItem('Deviation Request', 'requestDeviation'),
            ["PMIS", "ZADM", "COSM", "NSH"].includes(userRole) && getItem('Deviation Log', 'requestDeviationLog'),
        ]),

        ["Finance"].includes(userRole) && getItem('Input', 'generate-finance', <DashboardIcon />),
        ["PMIS"].includes(userRole) && getItem('Merge/Unmerge', 'merge', <MergeIcon />),
        ["PMIS"].includes(userRole) && getItem('Withdrawal', 'WithdrawalScheme', <PublishedWithChangesIcon />),
        
    ]

//  label: 'Option 1'
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    let dayExpired = userRole == "SADM" ? getExpiredStatusSADM() : userRole == "AMGR" ? getExpiredStatusAMGR() : getExpiredStatusZADM()
    return (
        <>
            <Row >
                <Col span={4}>
                    <Layout>
                        <Sider trigger={null} collapsible collapsed={collapsed} style={{ width: '250px', height: '100%' }}>
                            {/* <div className="nav_wrap"> */}
                            {!collapsed ? <div className="logo_sec" >
                                <Button
                                    type="text"
                                    icon={collapsed ? <CollapseIcon /> : <ExpandIcon />}
                                    style={{ position: 'relative' }}
                                    onClick={() => setCollapsed(!collapsed)}
                                />
                                <img src={Swaraj} alt="Swaraj Logo"/>

                                <span>Scheme management system</span>
                            </div> :
                                <div className="logo_sec" >
                                    <Button className="expand"
                                        type="text"
                                        icon={collapsed ?
                                            <CollapseIcon twotonecolor={'#808080'} style={{ fontSize: '20px', marginLeft: '30px' }} />
                                            : <ExpandIcon twotonecolor={'#808080'} style={{ fontSize: '20px', marginLeft: '30px' }}
                                            />}
                                        style={{ position: 'relative' }}
                                        onClick={() => setCollapsed(!collapsed)}
                                    />
                                    <SwarajIcon />
                                </div>}
                            {/* <div className="logo_title"><span>Scheme management system</span></div> */}
                            <Menu
                                className="navigation"
                                onClick={({ key }) => {
                                    if (key === "logOut") {
                                        //TO DO, logout feature
                                    } else if (dayExpired && (key == "installation/createNewScheme" || key == "collection/createNewScheme")) {
                                        setIsModalOpen(true)
                                    }
                                    else {
                                        navigate(key);
                                    }
                                }}
                                defaultSelectedKeys={["dashboard"]}
                                mode="inline"
                                openKeys={openKeys}
                                onOpenChange={onOpenChange}
                                items={items}
                            />
                            {/* </div> */}
                        </Sider>
                    </Layout>
                </Col>
                <Col span={20}>
                    <div className="dashboard-collapse">
                        <Layout>

                            <Content style={{ padding: 0, background: colorBgContainer, marginLeft: collapsed ? 70 : 245, transition: 'margin 0.2s' }} >
                                <SchemeHeader />
                                <Outlet />
                            </Content>
                        </Layout>
                    </div>
                </Col>
                {/* <Modal title="Scheme Creation Expired" open={isModalOpen} okText="Go Back"
                    onOk={() => { setIsModalOpen(false); navigate("/dashboard") }} onCancel={() => { setIsModalOpen(false); navigate("/dashboard") }}>
                    <Result
                        status="500"
                        title="Scheme creation window is expired now"
                    />
                </Modal> */}
                <Modal
                    title="Scheme Creation Expired"
                    open={isModalOpen}
                    okText="Go Back"
                    onOk={() => { setIsModalOpen(false); navigate("/dashboard") }}
                    okButtonProps={{style: {margin: '0px'}}}
                    cancelButtonProps={{ style: { display: 'none' } }} 
                    closable={false}
                    style={{padding : '0px', marginTop: '-70px', marginBottom: '0px'}}
                    maskClosable={false}
                    destroyOnClose={true}
                >
                    
                    <Result
                        status="500"
                        title="Scheme creation window is expired now"
                        style={{  height: '406px', padding: '0px' , marginTop: '-12px' , marginBottom : '-20px'}}
                    />
                </Modal>
            </Row>

        </>

    )
}

export default Template;
