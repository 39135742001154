import React from 'react';

const useTableSort = (tableData) => {

    const [order, setOrder] = React.useState('');
    const [orderBy, setOrderBy] = React.useState('');
  
    function descendingComparator(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
    
    function getComparator(order, orderBy) {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
  
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }
  
    const handleRequestSort = (property) => {
      const isAsc = orderBy === property && order === 'asc';
      if(order == ''){
        setOrder('desc');
        setOrderBy(property);
        return;
      }
      if(order == 'desc'){
        setOrder('asc');
        setOrderBy(property);
        return;
      }
      if(order == 'asc'){
        setOrder('desc');
        setOrderBy(property);
        return;
      }
    };

    const visibleRows = stableSort(tableData, getComparator(order, orderBy))

    return [visibleRows, handleRequestSort]
}

export default useTableSort;