import { Table, Col, Row, Button } from "antd";
import React, { useState, useEffect } from "react";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import { useLocation } from "react-router-dom";
import tableData from "./tableData";
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const currentFiscalYear = getCurrentFiscalYear();

const TrackCollectionTable = ({
  dashboardType,
  loading,
  dataSource,
  grandTotal,
  onSendEmail,
  onSelectedRows,
  handleChange,
}) => {
  const location = useLocation();
  const userInfo = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userInfo.userRole;

  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    if (order == "") {
      setOrder("desc");
      setOrderBy(property);
      return;
    }
    if (order == "desc") {
      setOrder("asc");
      setOrderBy(property);
      return;
    }
    if (order == "asc") {
      setOrder("");
      setOrderBy("");
      return;
    }
  };

  const visibleRows = stableSort(dataSource, getComparator(order, orderBy));

  // const a = React.useMemo(
  //   () =>
  //     stableSort(dataSource, getComparator(order, orderBy))
  //     // .slice(
  //     //   page * rowsPerPage,
  //     //   page * rowsPerPage + rowsPerPage,
  //     // ),
  //   [order, orderBy], //, page, rowsPerPage
  // );

  const columns = tableData(
    "Dealer wise Dashboard",
    userRole,
    "dashboard",
    handleRequestSort,
    onSendEmail
  );

  const getSum =
    grandTotal &&
    Object.values(grandTotal).reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

  return (
    <Table
      loading={loading ? true : false}
      total={dataSource?.length || 1}
      summary={(pageData) => {
        const fieldSum = {
          industry: 0,
          oldBillingQty: 0,
          oldInstallation: 0,
          oldCollection: 0,
          oldOpeningStock: 0,
          openingStockDays: 0,
          closingOutStandingMonth_3: 0,
          closingOutStandingMonth_2: 0,
          closingOutStandingMonth_1: 0,
          outStandingDays: 0,
          bg: 0,
          bgLacsCurrentYear: 0,
          delayPlan: 0,
          billing: 0,
          installation: 0,
          collection: 0,
          BooksCollectionTarget: 0,
          t1: 0,
          t2: 0,
          t2_T1: 0,
          t3: 0,
          t3_T2: 0,
          t4: 0,
          t4_T3: 0,
          t5: 0,
          t5_T4: 0,
          t6: 0,
          t6_T5: 0,
          r1: 0,
          r2: 0,
          r3: 0,
          r4: 0,
          r5: 0,
          r6: 0,
          payout: 0,
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          volume: 0,
          perTr: 0,
          amount: 0,
        };

        pageData.forEach(
          ({
            industry,
            oldBillingQty,
            oldInstallation,
            oldCollection,
            oldOpeningStock,
            openingStockDays,
            closingOutStandingMonth_3,
            closingOutStandingMonth_2,
            closingOutStandingMonth_1,
            outStandingDays,
            bg,
            bgLacsCurrentYear,
            delayPlan,
            billing,
            installation,
            collection,
            BooksCollectionTarget,
            t1,
            t2,
            t2_T1,
            t3,
            t3_T2,
            t4,
            t4_T3,
            t5,
            t5_T4,
            t6,
            t6_T5,
            r1,
            r2,
            r3,
            r4,
            r5,
            r6,
            payout,
            p1,
            p2,
            p3,
            p4,
            p5,
            p6,
            volume,
            perTr,
            amount,
            topTarget
          }) => {
            fieldSum.industry += industry ? parseInt(industry) : 0;
            fieldSum.oldBillingQty += oldBillingQty
              ? parseInt(oldBillingQty)
              : 0;
            fieldSum.oldInstallation += oldInstallation
              ? parseInt(oldInstallation)
              : 0;
            fieldSum.oldCollection += oldCollection
              ? parseInt(oldCollection)
              : 0;
            fieldSum.oldOpeningStock += oldOpeningStock
              ? parseInt(oldOpeningStock)
              : 0;
            fieldSum.openingStockDays += openingStockDays
              ? parseInt(openingStockDays)
              : 0;
            fieldSum.closingOutStandingMonth_3 += closingOutStandingMonth_3
              ? parseInt(closingOutStandingMonth_3)
              : 0;
            fieldSum.closingOutStandingMonth_2 += closingOutStandingMonth_2
              ? parseInt(closingOutStandingMonth_2)
              : 0;
            fieldSum.closingOutStandingMonth_1 += closingOutStandingMonth_1
              ? parseInt(closingOutStandingMonth_1)
              : 0;
            fieldSum.outStandingDays += outStandingDays
              ? parseInt(outStandingDays)
              : 0;
            fieldSum.bg += bg ? parseInt(bg) : 0;
            fieldSum.bgLacsCurrentYear += bgLacsCurrentYear
              ? parseInt(bgLacsCurrentYear)
              : 0;
            fieldSum.delayPlan += delayPlan ? parseInt(delayPlan) : 0;
            fieldSum.billing += billing ? parseInt(billing) : 0;
            fieldSum.installation += installation ? parseInt(installation) : 0;
            fieldSum.collection += collection ? parseInt(collection) : 0;
            fieldSum.BooksCollectionTarget += BooksCollectionTarget
              ? parseInt(BooksCollectionTarget)
              : 0;
            fieldSum.t1 += (t1 && topTarget?.includes("t1")) ? parseInt(t1 || 0) : 0;
            fieldSum.t2 += (t2 && topTarget?.includes("t2")) ? parseInt(t2 || 0) : 0;
            fieldSum.t2_T1 += (t2_T1 && topTarget?.includes("t2")) ? parseInt(t2_T1 || 0) : 0;
            fieldSum.t3 += (t3 && topTarget?.includes("t3")) ? parseInt(t3 || 0) : 0;
            fieldSum.t3_T2 += (t3_T2 && topTarget?.includes("t3")) ? parseInt(t3_T2 || 0) : 0;
            fieldSum.t4 += (t4 && topTarget?.includes("t4")) ? parseInt(t4 || 0) : 0;
            fieldSum.t4_T3 += (t4_T3 && topTarget?.includes("t4")) ? parseInt(t4_T3 || 0) : 0;
            fieldSum.t5 += (t5 && topTarget?.includes("t5")) ? parseInt(t5 || 0) : 0;
            fieldSum.t5_T4 += (t5_T4 && topTarget?.includes("t5")) ? parseInt(t5_T4 || 0) : 0;
            fieldSum.t6 += (t6 && topTarget?.includes("t6")) ? parseInt(t6 || 0) : 0;
            fieldSum.t6_T5 += (t6_T5 && topTarget?.includes("t6")) ? parseInt(t6_T5 || 0) : 0;
            fieldSum.r1 += (r1 && topTarget?.includes("t1")) ? parseInt(r1 || 0) : 0;
            fieldSum.r2 += (r2 && topTarget?.includes("t2")) ? parseInt(r2 || 0) : 0;
            fieldSum.r3 += (r3 && topTarget?.includes("t3")) ? parseInt(r3 || 0) : 0;
            fieldSum.r4 += (r4 && topTarget?.includes("t4")) ? parseInt(r4 || 0) : 0;
            fieldSum.r5 += (r5 && topTarget?.includes("t5")) ? parseInt(r5 || 0) : 0;
            fieldSum.r6 += (r6 && topTarget?.includes("t6")) ? parseInt(r6 || 0) : 0;
            fieldSum.payout += payout ? parseInt(payout) : 0;
            fieldSum.p1 += (p1 && topTarget?.includes("t1")) ? parseInt(p1 || 0) : 0;
            fieldSum.p2 += (p2 && topTarget?.includes("t2")) ? parseInt(p2 || 0) : 0;
            fieldSum.p3 += (p3 && topTarget?.includes("t3")) ? parseInt(p3 || 0) : 0;
            fieldSum.p4 += (p4 && topTarget?.includes("t4")) ? parseInt(p4 || 0) : 0;
            fieldSum.p5 += (p5 && topTarget?.includes("t5")) ? parseInt(p5 || 0) : 0;
            fieldSum.p6 += (p6 && topTarget?.includes("t6")) ? parseInt(p6 || 0) : 0;
            fieldSum.volume += volume ? parseInt(volume) : 0;
            fieldSum.perTr += perTr ? parseInt(perTr) : 0;
            fieldSum.amount += amount ? parseInt(amount) : 0;
          }
        );

        const fieldSum1 = {
          industry: 0,
          oldBillingQty: 0,
          oldInstallation: 0,
          oldCollection: 0,
          oldOpeningStock: 0,
          openingStockDays: 0,
          closingOutStandingMonth_3: 0,
          closingOutStandingMonth_2: 0,
          closingOutStandingMonth_1: 0,
          outStandingDays: 0,
          bg: 0,
          bgLacsCurrentYear: 0,
          delayPlan: 0,
          billing: 0,
          installation: 0,
          collection: 0,
          BooksCollectionTarget: 0,
          t1: 0,
          t2: 0,
          t2_T1: 0,
          t3: 0,
          t3_T2: 0,
          t4: 0,
          t4_T3: 0,
          t5: 0,
          t5_T4: 0,
          t6: 0,
          t6_T5: 0,
          r1: 0,
          r2: 0,
          r3: 0,
          r4: 0,
          r5: 0,
          r6: 0,
          payout: 0,
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          volume: 0,
          perTr: 0,
          amount: 0,
        };

        dataSource.forEach(
          ({
            industry,
            oldBillingQty,

            oldInstallation,

            oldCollection,

            oldOpeningStock,

            openingStockDays,

            closingOutStandingMonth_3,

            closingOutStandingMonth_2,

            closingOutStandingMonth_1,

            outStandingDays,

            bg,

            bgLacsCurrentYear,
            delayPlan,
            billing,
            installation,
            collection,
            BooksCollectionTarget,
            t1,
            t2,
            t2_T1,
            t3,
            t3_T2,
            t4,
            t4_T3,
            t5,
            t5_T4,
            t6,
            t6_T5,
            r1,
            r2,
            r3,
            r4,
            r5,
            r6,
            payout,
            p1,
            p2,
            p3,
            p4,
            p5,
            p6,
            volume,
            perTr,
            amount,
          }) => {
            fieldSum1.industry += industry ? parseInt(industry) : 0;

            fieldSum1.oldBillingQty += oldBillingQty
              ? parseInt(oldBillingQty)
              : 0;

            fieldSum1.oldInstallation += oldInstallation
              ? parseInt(oldInstallation)
              : 0;

            fieldSum1.oldCollection += oldCollection
              ? parseInt(oldCollection)
              : 0;

            fieldSum1.oldOpeningStock += oldOpeningStock
              ? parseInt(oldOpeningStock)
              : 0;

            fieldSum1.openingStockDays += openingStockDays
              ? parseInt(openingStockDays)
              : 0;

            fieldSum1.closingOutStandingMonth_3 += closingOutStandingMonth_3
              ? parseInt(closingOutStandingMonth_3)
              : 0;

            fieldSum1.closingOutStandingMonth_2 += closingOutStandingMonth_2
              ? parseInt(closingOutStandingMonth_2)
              : 0;

            fieldSum1.closingOutStandingMonth_1 += closingOutStandingMonth_1
              ? parseInt(closingOutStandingMonth_1)
              : 0;

            fieldSum1.outStandingDays += outStandingDays
              ? parseInt(outStandingDays)
              : 0;

            fieldSum1.bg += bg ? parseInt(bg) : 0;

            fieldSum1.bgLacsCurrentYear += bgLacsCurrentYear
              ? parseInt(bgLacsCurrentYear)
              : 0;

            fieldSum1.delayPlan += delayPlan ? parseInt(delayPlan) : 0;

            fieldSum1.billing += billing ? parseInt(billing) : 0;

            fieldSum1.installation += installation ? parseInt(installation) : 0;

            fieldSum1.collection += collection ? parseInt(collection) : 0;

            fieldSum1.BooksCollectionTarget += BooksCollectionTarget
              ? parseInt(BooksCollectionTarget)
              : 0;

            fieldSum1.t1 += t1 ? parseInt(t1) : 0;

            fieldSum1.t2 += t2 ? parseInt(t2) : 0;

            fieldSum1.t2_T1 += t2_T1 ? parseInt(t2_T1) : 0;

            fieldSum1.t3 += t3 ? parseInt(t3) : 0;

            fieldSum1.t3_T2 += t3_T2 ? parseInt(t3_T2) : 0;

            fieldSum1.t4 += t4 ? parseInt(t4) : 0;

            fieldSum1.t4_T3 += t4_T3 ? parseInt(t4_T3) : 0;

            fieldSum1.t5 += t5 ? parseInt(t5) : 0;

            fieldSum1.t5_T4 += t5_T4 ? parseInt(t5_T4) : 0;

            fieldSum1.t6 += t6 ? parseInt(t6) : 0;

            fieldSum1.t6_T5 += t6_T5 ? parseInt(t6_T5) : 0;

            fieldSum1.r1 += r1 ? parseInt(r1) : 0;

            fieldSum1.r2 += r2 ? parseInt(r2) : 0;

            fieldSum1.r3 += r3 ? parseInt(r3) : 0;

            fieldSum1.r4 += r4 ? parseInt(r4) : 0;

            fieldSum1.r5 += r5 ? parseInt(r5) : 0;

            fieldSum1.r6 += r6 ? parseInt(r6) : 0;

            fieldSum1.payout += payout ? parseInt(payout) : 0;

            fieldSum1.p1 += p1 ? parseInt(p1) : 0;

            fieldSum1.p2 += p2 ? parseInt(p2) : 0;

            fieldSum1.p3 += p3 ? parseInt(p3) : 0;

            fieldSum1.p4 += p4 ? parseInt(p4) : 0;

            fieldSum1.p5 += p5 ? parseInt(p5) : 0;

            fieldSum1.p6 += p6 ? parseInt(p6) : 0;

            fieldSum1.volume += volume ? parseInt(volume) : 0;

            fieldSum1.perTr += perTr ? parseInt(perTr) : 0;

            fieldSum1.amount += amount ? parseInt(amount) : 0;
          }
        );

        return (
          <>
            <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
              <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>

              <Table.Summary.Cell index={1}> Total</Table.Summary.Cell>

              <Table.Summary.Cell index={2}></Table.Summary.Cell>

              <Table.Summary.Cell index={3}></Table.Summary.Cell>

              <Table.Summary.Cell index={4}></Table.Summary.Cell>

              <Table.Summary.Cell index={5}></Table.Summary.Cell>

              <Table.Summary.Cell index={6}></Table.Summary.Cell>

              <Table.Summary.Cell index={7}></Table.Summary.Cell>

              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}></Table.Summary.Cell>
              <Table.Summary.Cell index={10}></Table.Summary.Cell>
              <Table.Summary.Cell index={11}>
                {fieldSum?.industry || "" }
              </Table.Summary.Cell>

              <Table.Summary.Cell index={12}>
                {fieldSum?.oldBillingQty || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={13}>
                {fieldSum?.oldInstallation || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={14}>
                {fieldSum?.oldCollection || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={15}>
                {fieldSum?.oldOpeningStock || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={16}>
                {fieldSum?.openingStockDays || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={17}>
                {fieldSum?.closingOutStandingMonth_3 || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={18}>
                {fieldSum?.closingOutStandingMonth_2 || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={19}>
                {fieldSum?.closingOutStandingMonth_1 || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={20}>
                {fieldSum?.outStandingDays || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={21}>
                {fieldSum?.bgLacsCurrentYear || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={22}>
                {fieldSum?.delayPlan || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={23}>
                {fieldSum?.billing || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={24}>
                {fieldSum?.installation || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={25}>
                {fieldSum?.collection ? fieldSum?.collection : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={26}></Table.Summary.Cell>
              <Table.Summary.Cell index={27}></Table.Summary.Cell>

              <Table.Summary.Cell index={28}>
                {fieldSum.t1 ? fieldSum.t1 : ""}

              </Table.Summary.Cell>

              <Table.Summary.Cell index={29}>
                {fieldSum.t2 ? fieldSum.t2 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={30}>
                {fieldSum.t2_T1 ? fieldSum.t2_T1 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={31}>
                {fieldSum.t3 ? fieldSum.t3 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={32}>
                {" "}
                {fieldSum.t3_T2 ? fieldSum.t3_T2 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={33}>
                {fieldSum.t4 ? fieldSum.t4 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={34}>
                {fieldSum.t4_T3 ? fieldSum.t4_T3 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={35}>
                {fieldSum.t5 ? fieldSum.t5 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={36}>
                {" "}
                {fieldSum.t5_T4 ? fieldSum.t5_T4 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={37}>
                {fieldSum.t6 ? fieldSum.t6 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={38}>
                {" "}
                {fieldSum.t6_T5 ? fieldSum.t6_T5 : ""}
    
              </Table.Summary.Cell>
              <Table.Summary.Cell index={39}>

                {(fieldSum.t1 && fieldSum.p1)
                  ? (Math.round(fieldSum.p1 / fieldSum.t1)||0)
                  : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={40}>
                {(fieldSum.t2 && fieldSum.p2)
                  ? (Math.round(fieldSum.p2 / fieldSum.t2)||0)
                  : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={41}>
                {(fieldSum.t3 && fieldSum.p3)
                  ? (Math.round(fieldSum.p3 / fieldSum.t3)||0)
                  : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={42}>
                {(fieldSum.t4 && fieldSum.p4)
                  ? (Math.round(fieldSum.p4 / fieldSum.t4)||0)
                  : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={43}>
                {(fieldSum.t5 && fieldSum.p5)
                  ? (Math.round(fieldSum.p5 / fieldSum.t5)||0)
                  : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={44}>
                {(fieldSum.t6 && fieldSum.p6)
                  ? (Math.round(fieldSum.p6 / fieldSum.t6)||0)
                  : ""}
              </Table.Summary.Cell> 

              <Table.Summary.Cell index={45}></Table.Summary.Cell>
                 <Table.Summary.Cell index={45}></Table.Summary.Cell>

              <Table.Summary.Cell index={46}>
                
                {fieldSum.p1 ? fieldSum.p1 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={47}>
                {fieldSum.p2 ? fieldSum.p2 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={48}>
                {fieldSum.p3 ? fieldSum.p3 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={49}>
                {fieldSum.p4 ? fieldSum.p4 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={50}>
                {fieldSum.p5 ? fieldSum.p5 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={51}>
                {fieldSum.p6 ? fieldSum.p6 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={52}>
                {fieldSum.volume ? fieldSum.volume : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={53}>
                {isNaN(Math.round(fieldSum.amount / fieldSum.volume))
                  ? ""
                  : Math.round(fieldSum.amount / fieldSum.volume)}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={54}>
                {fieldSum.amount ? fieldSum.amount : ""}
              </Table.Summary.Cell>
              {userRole == "SADM" && <Table.Summary.Cell index={55} />}
            </Table.Summary.Row>

            <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
              <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>

              <Table.Summary.Cell index={1}>Grand Total</Table.Summary.Cell>

              <Table.Summary.Cell index={2}></Table.Summary.Cell>

              <Table.Summary.Cell index={3}></Table.Summary.Cell>

              <Table.Summary.Cell index={4}></Table.Summary.Cell>

              <Table.Summary.Cell index={5}></Table.Summary.Cell>

              <Table.Summary.Cell index={6}></Table.Summary.Cell>

              <Table.Summary.Cell index={7}></Table.Summary.Cell>

              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}></Table.Summary.Cell>
              <Table.Summary.Cell index={10}></Table.Summary.Cell>

              <Table.Summary.Cell index={11}>
                {grandTotal?.industry || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={12}>
                {grandTotal?.industryB || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={13}>
                {grandTotal?.industryI || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={14}>
                {grandTotal?.industryC || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={15}>
                {grandTotal?.osNos || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={16}>
                {grandTotal?.osDays || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={17}>
                {grandTotal?.lastThirdMonth || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={18}>
                {grandTotal?.lastTwoMonth || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={19}>
                {grandTotal?.lastOneMonth || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={20}>
                {grandTotal?.days || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={21}>
                {grandTotal?.bgLacs || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={22}>
                {grandTotal?.iPNos || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={23}>
                {grandTotal?.bDB || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={24}>
                {grandTotal?.bDI || ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={25}>
                {grandTotal?.bDC ? grandTotal?.bDC : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={26}></Table.Summary.Cell>
              <Table.Summary.Cell index={27}></Table.Summary.Cell>

              <Table.Summary.Cell index={28}>
                {grandTotal?.t1 ? grandTotal?.t1 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={29}>
                {grandTotal?.t2 ? grandTotal?.t2 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={30}>
                {grandTotal?.t2_T1 ? grandTotal?.t2_T1 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={31}>
                {grandTotal?.t3 ? grandTotal?.t3 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={32}>
                {" "}
                {grandTotal?.t3T2 ? grandTotal?.t3T2 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={33}>
                {grandTotal?.t4 ? grandTotal?.t4 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={34}>
                {" "}
                {grandTotal?.t4_T3 ? grandTotal?.t4_T3 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={35}>
                {grandTotal?.t5 ? grandTotal?.t5 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={36}>
                {" "}
                {grandTotal?.t5_T4 ? grandTotal?.t5_T4 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={37}>
                {grandTotal?.t6 ? grandTotal?.t6 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={38}>
                {" "}
                {grandTotal?.t6_T5 ? grandTotal?.t6_T5 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={39}>
                {grandTotal?.r1 ? grandTotal?.r1 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={40}>
                {grandTotal?.r2 ? grandTotal?.r2 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={41}>
                {grandTotal?.r3 ? grandTotal?.r3 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={42}>
                {grandTotal?.r4 ? grandTotal?.r4 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={43}>
                {grandTotal?.r5 ? grandTotal?.r5 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={44}>
                {grandTotal?.r6 ? grandTotal?.r6 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={45}></Table.Summary.Cell>
              <Table.Summary.Cell index={45}></Table.Summary.Cell>

              <Table.Summary.Cell index={46}>
                {grandTotal?.p1 ? grandTotal?.p1 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={47}>
                {grandTotal?.p2 ? grandTotal?.p2 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={48}>
                {grandTotal?.p3 ? grandTotal?.p3 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={49}>
                {grandTotal?.p4 ? grandTotal?.p4 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={50}>
                {grandTotal?.p5 ? grandTotal?.p5 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={51}>
                {grandTotal?.p6 ? grandTotal?.p6 : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={52}>
                {grandTotal?.vol ? grandTotal?.vol : ""}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={53}>
                {getSum == 0 ? "" : grandTotal?.perTr}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={54}>
                {grandTotal?.amount ? grandTotal?.amount : ""}
              </Table.Summary.Cell>
              {userRole == "SADM" && <Table.Summary.Cell index={54} />}
            </Table.Summary.Row>
          </>
        );
      }}
      rowSelection={{
        type: "checkbox",
        onChange: (selectedRowKeys, selectedRows) => {
          onSelectedRows(selectedRows);
        },
        getCheckboxProps: (record) => ({ disabled: !record.isCommunication }),
      }}
      columns={columns}
      onChange={handleChange}
      pagination={false}
      dataSource={visibleRows.map((item, ind) => ({ key: ind, ...item }))}
      scroll={{
        x: 7000,
        y: 1000,
      }}
    />
  );
};

export default TrackCollectionTable;
