import { Tag, Space, Tooltip } from "antd";
import dayjs from "dayjs";
import { EditOutlined } from "@ant-design/icons";
import { STATE_WISE_DASHBOARD_LABEL } from "../../../config";
import { decryptData } from "../../../utils/localStorageEncodeDecode";


const tableData = ({ selectedSchemeType,handleModifyData ,selectedLabel}) => {
  const userInfo = decryptData(sessionStorage.getItem("userInfo"));
  let topSlabShow=(x)=>(x == 1||x == 2||x == 3)?[{
    title: (
      <div>
        TOP SLAB
      </div>
    ),
    dataIndex: "topTarget",
    width: "180px",
    sorter: true,
    defaultSortOrder: "ascend",
    key: "topTarget",
    render:(x)=>x?.toUpperCase()||""
  }]:[]
  const getCols = (schemeType) => {

    if (schemeType == 3) {
      return [

        {
          title: "Targets",
          dataIndex: "Target",
          width:"180px",
          children: [
            {
              title: "T1",
              dataIndex: "t1",
              width: "90px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t1") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "T2",
              dataIndex: "t2",
              width: "90px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t2") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "T3",
              dataIndex: "t3",
              width: "90px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t3") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
          ],
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "IPT",
          width: '180px',
          children: [
            {
              title: "R1",
              dataIndex: "r1",
              width: "90px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t1") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "R2",
              dataIndex: "r2",
              width: "90px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t2") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "R3",
              dataIndex: "r3",
              width: "90px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t3") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
          ],
        },
        {
          title: "Payout",
          dataIndex: "Payout",
          width: '180px',
          children: [
            {
              title: "P1",
              dataIndex: "p1",
              width: "90px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t1") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "P2",
              dataIndex: "p2",
              width: "90px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t2") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "P3",
              dataIndex: "p3",
              width: "90px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t3") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
          ],
        },
     
       
      ];
    }
    if (schemeType == 4) {
      return [

        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          width: "190px",
        },
        {
          title: "Upper Cap",
          dataIndex: "upperCap",
          width: "120px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          width: "190px",
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
        }
      ];
    }
    if (schemeType == 8) {
      return [

        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          width: "190px",
        },

        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          width: "190px",
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
        }  
      ];
    }
    if (schemeType == 5) {
      return [
      
        {
          title: "Installation Expected",
          dataIndex: "installationTarget",
          width: "200px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          width: "190px",
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
        }
      ];
    }
    return []

  }
  const getHoldRemarks =(selectedLabel)=> (selectedLabel === 'Hold Schemes')
  ?
  [
    {
      title: "Hold Remarks",
      dataIndex: "holdReason",
      width: "120px",
    }

  ]:[]
  let columnsList = [
    {
      title: (
        <div>
          Scheme <br /> No
        </div>
      ),
      dataIndex: "schemeNo",
      width: "145px",
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "SchemeNo",
      render: (_, item) => {
        let color = " ";
        const dataStatus=item?.holdStatus==9?item?.holdStatus:item.status
        switch (dataStatus) {
          case 0:
            color = "#FCE838"; //inProgress
            break;
          case 1:
            color = "#FCE838"; //inProgresss
            break;
          case 2:
            color = "#51DE4E"; //Approved
            break;
          case 3:
            color = "#9999ff"; //Withdraw
            break;
          case 4:
            color = "#FF3232"; //Cancel
            break;
          case 5:
            color = "#FF3232"; //Reject
            break;
          case 7:
            color = "#bcb38d"; //Deviation
            break;
          case 8:
            color = "#FFAE00"; //Auto Reject
            break;
            case 9:
              color = "#9999ff"; //hold
              break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={
             color
            }
          >
            {item?.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "dealerCode",
      width: "140px",
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"DealerCode",
    },
    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),
      fixed: "left",
      dataIndex: "dealerLocation",
      width: "150px",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"DealerLocation",
      // onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "dealerName",
      width: "160px",
      sorter: true,
      key: "dealerName",
      defaultSortOrder: "ascend",
    },
    {
      title: "State",
      dataIndex: "stateName",
      width: "100px",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"StateName",
    }, 
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "territoryManager",
      width: "180px",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"TerritoryManager",
    },...topSlabShow(selectedSchemeType),
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "startMonth",
      width: "140px",
      render: (_, item) => dayjs(item.startMonth).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      width: "140px",
      render: (_, item) => dayjs(item.endMonth).format("MMM'YY"),
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      width: "250px",
    },
    ...getCols(selectedSchemeType),
    ...getHoldRemarks(selectedLabel),
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "120px",
    },
    {
      title: "Modify",
      key: "action",
     fixed:"right",
      width: "70px",

      render: (_, record) =>

          <Space size="small">
            <Tooltip title="Modify" placement="top" className="infoIcon">
              <EditOutlined
                // type="primary"
                // className={schemeIdNo.length > 1 ? "disabled" : "enabled"} 
                // Disable the button based on the condition
                onClick={() => handleModifyData(record)}
                style={{ fontSize: "20px" }}
              />
            </Tooltip>
          </Space>
    }  
  ];


  let columnsList1 = [
    {
      title: (
        <div>
          Scheme <br /> No
        </div>
      ),
      dataIndex: "schemeNo",
      width: "145px",
      fixed: "left",
      render: (_, item) => {
        let color = " ";
        const dataStatus=item?.holdStatus==9?item?.holdStatus:item.status
        switch (dataStatus) {
          case 0:
            color = "#FCE838"; //inProgress
            break;
          case 1:
            color = "#FCE838"; //inProgresss
            break;
          case 2:
            color = "#51DE4E"; //Approved
            break;
          case 3:
            color = "#9999ff"; //Withdraw
            break;
          case 4:
            color = "#FF3232"; //Cancel
            break;
          case 5:
            color = "#FF3232"; //Reject
            break;
          case 7:
            color = "#bcb38d"; //Deviation
            break;
          case 8:
            color = "#FFAE00"; //Auto Reject
            break;
            case 9:
              color = "#9999ff"; //hold
              break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={
             color
            }
          >
            {item?.schemeNo}
          </Tag>      
        );
      },
    },
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "dealerCode",
      width: "140px",
      fixed: "left",
    },
    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),
      fixed: "left",
      dataIndex: "dealerLocation",
      width: "150px",

      // onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "dealerName",
      width: "160px",
    },
    {
      title: "State",
      dataIndex: "stateName",
      width: "100px",
    },   {
      title: (
        <div>
          State <br /> Head
        </div>
      ),
      dataIndex: "stateHeadName",
      width: "180px",
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "territoryManager",
      width: "180px",
    },
    ...topSlabShow(selectedSchemeType),
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "startMonth",
      width: "140px",
      render: (_, item) => dayjs(item.startMonth).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      width: "140px",
      render: (_, item) => dayjs(item.endMonth).format("MMM'YY"),
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      width: "250px",
    },
    ...getCols(selectedSchemeType),
    ...getHoldRemarks(selectedLabel),
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "120px",
    },
    {
      title: "Modify",
      key: "action",
     fixed:"right",
      width: "70px",

      render: (_, record) =>

          <Space size="small">
            <Tooltip title="Modify" placement="top" className="infoIcon">
              <EditOutlined
                // type="primary"
                // className={schemeIdNo.length > 1 ? "disabled" : "enabled"} 
                // Disable the button based on the condition
                onClick={() => handleModifyData(record)}
                style={{ fontSize: "20px" }}
              />
            </Tooltip>
          </Space>
    }  
  ];
  return userInfo?.userRole!="SADM"?columnsList1:columnsList;

};

export default tableData;