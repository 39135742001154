import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import "../../createSchemes/createScheme.css";
import { Button, Table, Col, Row, Form, message, Modal, Select, Space, Tooltip, Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import axiosAPI from "../../../../services/axiosApi";
import { useFormik } from "formik";
import { schemeList } from "../../../../redux/collection/SchemeList";
import { getCurrentFiscalYear } from "../../../../utils/dateFormatter";
import moment from "moment";
import { ArrowBack, ArrowBackRounded } from "@mui/icons-material";
import { getSaveModifiedDashboardApi, resetSaveModification } from "../../../../redux/modification/modificationSlice";
import SMDialog from "../../../../components/modal/alertModal/CommonModal";
import ValidationErrorsModal from "../../../../components/modal/alertModal/ValidationErrorsModal";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
import { getAwaitingLockSchemeAsync, getLockedSchemeTypeAsync, getLockedZonalHeadApiAsync } from "../../../../redux/stateData/stateDataSlice";
import ValidationDifferentSchemeError from "../../../../components/modal/alertModal/ValidationDifferentSchemeError";
import { postUpdateHoldScheme } from "../../../../redux/hold/holdDataSlice";
import ToastifyShow from "../../../../components/ToastifyShow";
import { isValidContentType, isValidFileHeader } from "../../../../config";
import XLSX from "xlsx-js-style";
import { ModificationCollectionSchemeDownload } from "../ModificationCollectionSchemeDownload";
import { parseFiveDigitNumber } from "../../../../components/download/collectionSchemeUpload";
const currentFiscalYear = getCurrentFiscalYear();

const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: "",
  dealerCode: [],
  startDate: "",
  endDate: "",
};

const ModificationEditAllSchemes = ({ backBtn, schemeDetails, selectedSchemeType, selectedLabel }) => {
  const dispatch = useDispatch();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [codeError, setCodeError] = useState([]);
  const [openErrorDialog, setOpenErrorDialog] = useState({
    open: false,
    errorsFields: {},
  });
  const [checkCalculate, setCheckCalculate] = useState(false);
  const [checkCalculateArr, setCheckCalculateArr] = useState([]);
  const { userId, userName, userRole } = decryptData(sessionStorage.getItem("userInfo"));
  const { editDashboard, loadingState } = useSelector((state) => state.modifyCollection);
  const editData = editDashboard?.data?.result;
  const [schemeLockedStatus, setSchemeLockedStatus] = useState({
    t1: false,
    t2: false,
    t3: false, t4: false, t5: false, t6: false
  })
  const [lockedStatus, setlockedStatus] = useState([])
  //   useEffect(()=>{
  //   dispatch(getAwaitingLockSchemeAsync()).then((x)=>{
  //     if(x?.payload){
  //       setlockedStatus(x?.payload)
  //     }
  //   })
  // },[])

  const formik = useFormik({
    initialValues: [],
  });
  useEffect(() => {

    if (userRole == "ZADM" || userRole == "NSH") {
      dispatch(getLockedZonalHeadApiAsync({ "fyear": "FY24", "userid": userId })).then((x) => {
        if (Array?.isArray(x?.payload?.data)) {

          setSchemeLockedStatus(x?.payload?.data)
        }
      })
    } else {
      dispatch(getLockedSchemeTypeAsync(userId)).then((x) => {
        if (x?.payload?.[0]) {
          setSchemeLockedStatus(x?.payload?.[0] ? {
            t1: true,
            t2: true,
            t3: true,
            t4: x?.payload?.[0]?.t4 ? true : false,
            t5: x?.payload?.[0]?.t5 ? true : false,
            t6: x?.payload?.[0]?.t6 ? true : false,
          } : {
            t1: false,
            t2: false,
            t3: false, t4: false, t5: false, t6: false
          })
        }
      })
    }
  }, [])



  const topTargetArr = [
    {
      name: "T1",
      value: "t1",
      disabled: !schemeLockedStatus?.t1 ? true : false,
    },
    {
      name: "T2",
      value: "t2",
      disabled: !schemeLockedStatus?.t2 ? true : false,
    },
    {
      name: "T3",
      value: "t3",
      disabled: !schemeLockedStatus?.t3 ? true : false,
    },
    {
      name: "T4",
      value: "t4",
      disabled: !schemeLockedStatus?.t4 ? true : false,
    },
    {
      name: "T5",
      value: "t5",
      disabled: !schemeLockedStatus?.t5 ? true : false,
    },
    {
      name: "T6",
      value: "t6",
      disabled: !schemeLockedStatus?.t6 ? true : false,
    },
  ];
  const topTargetArr1 = (x) => [
    {
      name: "T1",
      value: "t1",

    },
    {
      name: "T2",
      value: "t2",

    },
    {
      name: "T3",
      value: "t3",

    },
    {
      name: "T4",
      value: "t4",
      disabled: !x?.t4 ? true : false,
    },
    {
      name: "T5",
      value: "t5",
      disabled: !x?.t5 ? true : false,
    },
    {
      name: "T6",
      value: "t6",
      disabled: !x?.t6 ? true : false,
    },
  ]

  useEffect(() => {
    if (checkCalculate) {
      checkCalculateArr?.forEach((x) => calculateFormula(x));
      setCheckCalculate(false);
    }
  }, [checkCalculate]);

  const getDropdown = () =>
    [
      {
        title: "Top Slab",
        width: "150px",
        render: (x, y, index) => {
          const handleChange = (value) => {
            let dropdownValues = formik?.values?.[index]?.dropdownscheme;
            let checkValue1 = value?.includes("t1");
            let checkValue2 = value?.includes("t2");
            let checkValue3 = value?.includes("t3");
            let checkValue4 = value?.includes("t4");
            let checkValue5 = value?.includes("t5");
            let checkValue6 = value?.includes("t6");
            const handleDropDownScheme = () => {
              return checkValue6 ? ['t1', 't2', 't3', 't4', 't5', 't6']
                : checkValue5 ? ['t1', 't2', 't3', 't4', 't5'] :
                  checkValue4 ? ['t1', 't2', 't3', 't4']
                    : checkValue3 ? ['t1', 't2', 't3']
                      : checkValue2 ? ['t1', 't2']
                        : checkValue1 ? ['t1'] : [];
            };
            const handleDropdownSchemes = handleDropDownScheme()

            formik.setFieldValue(index, {
              ...x,
              dropdownscheme: handleDropDownScheme() || null,
              ... (handleDropdownSchemes.includes("t6")
                ? {
                } :
                handleDropdownSchemes.includes("t5") ? {
                  t6_T5: null,
                  r6: null,
                  t6: null
                } :
                  handleDropdownSchemes.includes("t4") ? {
                    r5: null, t5: null,
                    r6: null, t6: null,
                    t5_T4: 0
                  } : handleDropdownSchemes.includes("t3") ? {
                    r4: null, t4: null,
                    r5: null, t5: null,
                    r6: null, t6: null,
                    t4_T3: 0,
                  } : handleDropdownSchemes.includes("t2") ? {
                    r3: null, t3: null,
                    r4: null, t4: null,
                    r5: null, t5: null,
                    r6: null, t6: null,
                    t3_T2: 0,
                  } : handleDropdownSchemes.includes("t1") && {
                    r2: null, t2: null,
                    r3: null, t3: null,
                    r4: null, t4: null,
                    r5: null, t5: null,
                    r6: null, t6: null,
                    t2_T1: 0,

                  }),

              t6_T5: null,
            });
          };

          let arr1 = userRole == "ZADM" ? topTargetArr1(y?.topTargetArrState)?.filter((x) => !x?.disabled) : topTargetArr
            ?.filter((x) => !x?.disabled)
          return (
            <Select
              onChange={handleChange}
              value={y?.dropdownscheme}
              mode="multiple"
              autoClearSearchValue="multiple"
              className="w-100"
              allowClear
              onDeselect={false}
              showArrow={false}
              removeIcon={false}
            >
              {arr1?.map((x) => (
                <Select.Option value={x?.value}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
    ]

  const getTargetT1 = () =>
    (schemeLockedStatus?.t1 || userRole == "ZADM")
      ? [
        {
          title: "T1",
          dataIndex: "t1",
          editable: true,
          width: "100px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.t1}
              value={formik?.values?.[index]?.t1 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t1: value,
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
      ]
      : [];
  const getTargetT2 = () =>
    (schemeLockedStatus?.t2 || userRole == "ZADM")
      ? [
        {
          title: "T2",
          dataIndex: "t2",
          editable: true,
          width: "100px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.t2}
              value={formik?.values?.[index]?.t2 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t2: value,
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
        {
          title: "T2-T1",
          dataIndex: "t2_T1",
          width: "100px",
          render: (x, y, index) => {
            return (formik?.values?.[index]?.dropdownscheme?.includes("t2") && formik?.values?.[index]?.dropdownscheme?.includes("t1") ? <div>{x}</div> :
              <div
                style={{ height: "26px" }}
                className={"input-disabled1"
                }>&nbsp;</div>
            )
          }
        },
      ]
      : [];
  const getTargetT3 = () =>
    (schemeLockedStatus?.t3 || userRole == "ZADM")
      ? [
        {
          title: "T3",
          dataIndex: "t3",
          editable: true,
          width: "100px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.t3}
              value={formik?.values?.[index]?.t3 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? true
                  : false
              }
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t3: value,
                });
              }}
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
        {
          title: "T3-T2",
          dataIndex: "t3_T2",
          width: "100px",
          render: (x, y, index) => {
            return ((formik?.values?.[index]?.dropdownscheme?.includes("t3") && formik?.values?.[index]?.dropdownscheme?.includes("t2")) ? <div>{x}</div> :
              <div
                style={{ height: "26px" }}
                className={"input-disabled1"
                }>&nbsp;</div>
            )
          }
        },
      ]
      : [];
  const getTargetT4 = () =>
    (schemeLockedStatus?.t4 || userRole == "ZADM")
      ? [
        {
          title: "T4",
          dataIndex: "t4",
          editable: true,
          width: "100px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.t4}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? true
                  : false
              }
              value={formik?.values?.[index]?.t4 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t4: value,
                });
              }}
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
        {
          title: "T4-T3",
          dataIndex: "t4_T3",
          width: "100px",
          render: (x, y, index) => {
            return ((formik?.values?.[index]?.dropdownscheme?.includes("t4") && formik?.values?.[index]?.dropdownscheme?.includes("t3")) ? <div>{x}</div> :
              <div
                style={{ height: "26px" }}
                className={"input-disabled1"
                }>&nbsp;</div>
            )
          }
        },
      ]
      : [];
  const getTargetT5 = () =>
    (schemeLockedStatus?.t5 || userRole == "ZADM")
      ? [
        {
          title: "T5",
          dataIndex: "t5",
          editable: true,
          width: "100px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.t5}
              value={formik?.values?.[index]?.t5 || ""}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? true
                  : false
              }
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t5: value,
                });
              }}
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
        {
          title: "T5-T4",
          dataIndex: "t5_T4",
          width: "100px",
          render: (x, y, index) => {
            return ((formik?.values?.[index]?.dropdownscheme?.includes("t5") && formik?.values?.[index]?.dropdownscheme?.includes("t4")) ? <div>{x}</div> :
              <div
                style={{ height: "26px" }}
                className={"input-disabled1"
                }>&nbsp;</div>
            )
          }
        },
      ]
      : [];
  const getTargetT6 = () =>
    (schemeLockedStatus?.t6 || userRole == "ZADM")
      ? [
        {
          title: "T6",
          dataIndex: "t6",
          editable: true,
          width: "100px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.t6}
              value={formik?.values?.[index]?.t6 || ""}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? true
                  : false
              }
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t6: value,
                });
              }}
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
        {
          title: "T6-T5",
          dataIndex: "t6_T5",
          width: "100px",
          render: (x, y, index) => {
            return ((formik?.values?.[index]?.dropdownscheme?.includes("t6") && formik?.values?.[index]?.dropdownscheme?.includes("t5")) ? <div>{x}</div> :
              <div
                style={{ height: "26px" }}
                className={"input-disabled1"
                }>&nbsp;</div>
            )
          }
        },
      ]
      : [];
  const getPayoutRateR1 = () =>
    (schemeLockedStatus?.t1 || userRole == "ZADM")
      ? [
        {
          title: "R1",
          dataIndex: "r1",
          editable: false,
          width: "120px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.r1}
              value={formik?.values?.[index]?.r1 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r1: value,
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
      ]
      : [];
  const getPayoutRateR2 = () =>
    (schemeLockedStatus?.t2 || userRole == "ZADM")
      ? [
        {
          title: "R2",
          dataIndex: "r2",
          editable: false,
          width: "120px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.r2}
              value={formik?.values?.[index]?.r2 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r2: value,
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
      ]
      : [];
  const getPayoutRateR3 = () =>
    (schemeLockedStatus?.t3 || userRole == "ZADM")
      ? [
        {
          title: "R3",
          dataIndex: "r3",
          editable: true,
          width: "120px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.r3}
              value={formik?.values?.[index]?.r3 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r3: value,
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
      ]
      : [];
  const getPayoutRateR4 = () =>
    (schemeLockedStatus?.t4 || userRole == "ZADM")
      ? [
        {
          title: "R4",
          dataIndex: "r4",
          editable: true,
          width: "120px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.r4}
              value={formik?.values?.[index]?.r4 || ""}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? true
                  : false
              }
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r4: value,
                });
              }}
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
      ]
      : [];
  const getPayoutRateR5 = () =>
    (schemeLockedStatus?.t5 || userRole == "ZADM")
      ? [
        {
          title: "R5",
          dataIndex: "r5",
          editable: true,
          width: "120px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.r5}
              value={formik?.values?.[index]?.r5 || ""}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? true
                  : false
              }
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r5: value,
                });
              }}
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
      ]
      : [];
  const getPayoutRateR6 = () =>
    (schemeLockedStatus?.t6 || userRole == "ZADM")
      ? [
        {
          title: "R6",
          dataIndex: "r6",
          editable: true,
          width: "120px",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.r6}
              value={formik?.values?.[index]?.r6 || ""}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? true
                  : false
              }
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r6: value,
                });
              }}
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />
          ),
        },
      ]
      : [];
  const getPayoutP1 = () =>
    (schemeLockedStatus?.t1 || userRole == "ZADM")
      ? [
        {
          title: "P1",
          dataIndex: "p1",
          width: "120px",
          render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t1") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
        },
      ]
      : [{
        title: "P1",
        dataIndex: "p1",
        width: "120px",
        render: (x) => <div className="h-24 input-disabled"></div>
      }];
  const getPayoutP2 = () =>
    (schemeLockedStatus?.t2 || userRole == "ZADM")
      ? [
        {
          title: "P2",
          dataIndex: "p2",
          width: "120px",
          render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t2") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
        },
      ]
      : [[{
        title: "P2",
        dataIndex: "p2",
        width: "120px",
        render: (x) => <div className="h-24 input-disabled"></div>
      }]];
  const getPayoutP3 = () =>
    (schemeLockedStatus?.t3 || userRole == "ZADM")
      ? [
        {
          title: "P3",
          dataIndex: "p3",
          width: "120px",
          render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t3") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
        },
      ]
      : [{
        title: "P3",
        dataIndex: "p3",
        width: "120px",
        render: (x) => <div className="h-24 input-disabled"></div>
      }];
  const getPayoutP4 = () =>
    (schemeLockedStatus?.t4 || userRole == "ZADM")
      ? [
        {
          title: "P4",
          dataIndex: "p4",
          width: "120px",
          render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t4") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
        },
      ]
      : [];
  const getPayoutP5 = () =>
    (schemeLockedStatus?.t5 || userRole == "ZADM")
      ? [
        {
          title: "P5",
          dataIndex: "p5",
          width: "120px",
          render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t5") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
        },
      ]
      : [];
  const getPayoutP6 = () =>

    (schemeLockedStatus?.t6 || userRole == "ZADM")
      ? [
        {
          title: "P6",
          dataIndex: "p6",
          width: "120px",
          render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t6") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>

        },
      ]
      : [];



  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showErrorDialog = (errFields) => {
    setOpenErrorDialog((openErrorDialog) => ({
      open: true,
      errorsFields: errFields ? errFields : {},
    }));
  };
  useEffect(() => {
    if (schemeDetails) {
      let data = schemeDetails
      let data1 = data.map((x) => ({
        dealerCode: x.dealerCode,
        dealerId: x.dealerId,
        dealerName: x.dealerName,
        dealerLocation: x.dealerLocation,
        stateName: x.stateName,
        stateHeadName: x?.stateHeadName,
        territoryManager: x.territoryManager || "",
        topTarget: x.topTarget,
        startMonth: moment(x.startMonth).format("MMM YY"),
        endMonth: moment(x.endMonth).format("MMM YY"),
        reason: x.reason,
        billing: x.billing ? +x.billing : 0,
        installation: x.installation ? +x.installation : 0,
        collection: Math.round(x.collection ? +x.collection : 0),
        delayPlan: x.delayPlan,
        schemeTypeId: x.schemeTypeId,
        bookCollectionId: x.bookCollectionId,
        payoutId: x.payoutId,
        schemeId: x.schemeId,
        schemeNo: x.schemeNo,
        schemePlanId: x.schemePlanId,
        openingStockDays: x.openingStockDays,
        outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
        t1: +x.t1,
        t2: +x.t2,
        t3: +x.t3,
        t4: +x.t4,
        t5: +x.t5,
        t6: +x.t6,
        t2_T1: x.t2_T1,
        t3_T2: x.t3_T2,
        t4_T3: x.t4_T3,
        t5_T4: x.t5_T4,
        t6_T5: x.t6_T5,
        p1: x.p1,
        p2: x.p2,
        p3: x.p3,
        p4: x.p4,
        p5: x.p5,
        p6: x.p6,
        r1: +x.r1,
        r2: +x.r2,
        r3: +x.r3,
        r4: +x.r4,
        r5: +x.r5,
        r6: +x.r6,
        topTarget: +x?.topTarget,
        volume: x.volume,
        perTr: Math.round(x.perTr),
        amount: x.amount,
        // userrole: userRole,
        // ...(y != "draft" && { Manager: y.Manager }),
        // fyear: currentFiscalYear,
        // userid: +userId,
        // username: userName,
        industry: x.industry ? parseInt(x.industry) : 0,
        oldBillingQty: x.oldBillingQty,
        oldInstallation: x.oldInstallation,
        oldCollection: Math.round(x.oldCollection),
        oldOpeningStock: x.oldOpeningStock,
        closingOutStandingMonth_1: x.closingOutStandingMonth_1,
        closingOutStandingMonth_2: x.closingOutStandingMonth_2,
        closingOutStandingMonth_3: x.closingOutStandingMonth_3,
        bGLacsCurrentYear: x.bGLacsCurrentYear,
        holdStatus: x.holdStatus,
        dropdownscheme: x?.topTarget?.split(",") || ["t1", "t2", "t3"],
        TopTarget: x?.dropdownscheme?.toString() || "t1,t2,t3",
        closingStock: x.closingStock,
        closingOutStanding: x.closingOutStanding,
        billingFactorCOS: x.billingFactorCOS,
        ...(userRole == "ZADM") && {
          topTargetArrState: (userRole == "ZADM" && Array?.isArray(schemeLockedStatus)) ? schemeLockedStatus?.filter((y) => y?.stateId == x?.stateId)?.[0] || [{
            t1: false,
            t2: false,
            t3: false,
            t4: false,
            t5: false,
            t6: false
          }] : [{
            t1: false,
            t2: false,
            t3: false,
            t4: false,
            t5: false,
            t6: false
          }]
        },
      }));

      formik?.setValues(data1);
    }
  }, [schemeDetails, schemeLockedStatus]);

  const handleErrorModal = (firstItem, secondItem, opt = false) => {
    setOpenErrorModal(opt ? opt : !openErrorModal);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };
  const calculateFormula = (index) => {
    let y = formik?.values[index];
    let {
      t2,
      t1,
      t3,
      t4,
      t5,
      t6,
      r1,
      r2,
      r3,
      r4,
      r5,
      r6,
      volume,
      dropdownscheme,
      oldOpeningStock,
      billing,
      installation,
      collection,
      closingOutStandingMonth_1,
      billingFactorCOS
    } = formik?.values[index];

    const TEPvol1 = volume ? parseInt(volume) : 0;

    const handleDropDownSchemeCheckIndex = () => {
      let { t1, t2, t3, t4, t5, t6 } = dropdownscheme?.reduce((a, v) => ({ ...a, [v]: v }), {});
      return t1 && !t2 && !t3 && !t4 && !t5 && !t6
        ? "t1"
        : t1 && t2 && !t3 && !t4 && !t5 && !t6
          ? "t2"
          : t1 && t2 && t3 && !t4 && !t5 && !t6
            ? "t3"
            : t1 && t2 && t3 && t4 && !t5 && !t6
              ? "t4"
              : t1 && t2 && t3 && t4 && t5 && !t6
                ? "t5"
                : t1 && t2 && t3 && t4 && t5 && t6
                  ? "t6"
                  : "";
    };
    let validationSoftTarget = handleDropDownSchemeCheckIndex();


    let calculateTepAMount1 = () => {
      // eslint-disable-next-line default-case
      switch (validationSoftTarget) {
        case "t1":
          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : parseInt(TEPvol1)
              ? parseInt(TEPvol1) * parseInt(r1) : 0
        case "t2":
          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : (parseInt(TEPvol1) >= parseInt(t1)) && (parseInt(TEPvol1) < parseInt(t2))
              ? parseInt(TEPvol1) * parseInt(r1)
              : parseInt(TEPvol1)
                ? parseInt(TEPvol1) * parseInt(r2) : 0
        case "t3":
          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : (parseInt(TEPvol1) >= parseInt(t1)) && (parseInt(TEPvol1) < parseInt(t2))
              ? parseInt(TEPvol1) * parseInt(r1)
              : (parseInt(TEPvol1) >= parseInt(t2)) && (parseInt(TEPvol1) < parseInt(t3))
                ? parseInt(TEPvol1) * parseInt(r2)
                : parseInt(TEPvol1)
                  ? parseInt(TEPvol1) * parseInt(r3) : 0
        case "t4":
          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : (parseInt(TEPvol1) >= parseInt(t1)) && (parseInt(TEPvol1) < parseInt(t2))
              ? parseInt(TEPvol1) * parseInt(r1)
              : (parseInt(TEPvol1) >= parseInt(t2)) && (parseInt(TEPvol1) < parseInt(t3))
                ? parseInt(TEPvol1) * parseInt(r2)
                : (parseInt(TEPvol1) >= parseInt(t3)) && (parseInt(TEPvol1) < parseInt(t4))
                  ? parseInt(TEPvol1) * parseInt(r3)
                  : parseInt(TEPvol1)
                    ? parseInt(TEPvol1) * parseInt(r4) : 0

        case "t5":
          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : (parseInt(TEPvol1) >= parseInt(t1)) && (parseInt(TEPvol1) < parseInt(t2))
              ? parseInt(TEPvol1) * parseInt(r1)
              : (parseInt(TEPvol1) >= parseInt(t2)) && (parseInt(TEPvol1) < parseInt(t3))
                ? parseInt(TEPvol1) * parseInt(r2)
                : (parseInt(TEPvol1) >= parseInt(t3)) && (parseInt(TEPvol1) < parseInt(t4))
                  ? parseInt(TEPvol1) * parseInt(r3)
                  : (parseInt(TEPvol1) >= parseInt(t4)) && (parseInt(TEPvol1) < parseInt(t5))
                    ? parseInt(TEPvol1) * parseInt(r4)
                    : parseInt(TEPvol1)
                      ? parseInt(TEPvol1) * parseInt(r5) : 0;


        case "t6":

          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : (parseInt(TEPvol1) >= parseInt(t1)) && (parseInt(TEPvol1) < parseInt(t2))
              ? parseInt(TEPvol1) * parseInt(r1)
              : (parseInt(TEPvol1) >= parseInt(t2)) && (parseInt(TEPvol1) < parseInt(t3))
                ? parseInt(TEPvol1) * parseInt(r2)
                : (parseInt(TEPvol1) >= parseInt(t3)) && (parseInt(TEPvol1) < parseInt(t4))
                  ? parseInt(TEPvol1) * parseInt(r3)
                  : (parseInt(TEPvol1) >= parseInt(t4)) && (parseInt(TEPvol1) < parseInt(t5))
                    ? parseInt(TEPvol1) * parseInt(r4)
                    : (parseInt(TEPvol1) >= parseInt(t5)) && (parseInt(TEPvol1) < parseInt(t6))
                      ? parseInt(TEPvol1) * parseInt(r5)
                      : parseInt(TEPvol1)
                        ? parseInt(TEPvol1) * parseInt(r6)
                        : 0;

      }
    }
    let calculateTepAMount = calculateTepAMount1()
    let TEPpertr1Calculate = (calculateTepAMount / TEPvol1)

    //Calculate ClosingStock
    const calculateClosingStock = () => {
      return (oldOpeningStock + parseInt(billing)) - installation;
    };
    const closingStock = calculateClosingStock();

    //Calculate Closing OutStanding
    const calculateClosingOutStanding = () => {
      const closingOutstanding = ((parseFloat(closingOutStandingMonth_1 * 100000)) 
        + (parseInt(billing) * parseFloat(billingFactorCOS)))
        - (parseInt(collection) * parseFloat(billingFactorCOS));

      return closingOutstanding / 100000;
    };

    const closingOutStanding = calculateClosingOutStanding().toFixed(2);

    let slabAchieve;

    if (validationSoftTarget == "t4") {

      slabAchieve = ((TEPvol1 >= t1) && (TEPvol1 < t2))
        ? "T1"
        : (TEPvol1 < t1) || (TEPvol1 == 0)
          ? 0
          : TEPvol1 >= t2 &&
            TEPvol1 < t3
            ? "T2" :
            TEPvol1 >= t3 &&
              TEPvol1 < t4
              ? "T3"
              : "T4";
    } else if (validationSoftTarget == "t5") {
      slabAchieve = ((TEPvol1 >= t1) && (TEPvol1 < t2))
        ? "T1"
        : (TEPvol1 < t1) || (TEPvol1 == 0)
          ? 0
          : TEPvol1 >= t2 &&
            TEPvol1 < t3
            ? "T2" :
            TEPvol1 >= t3 &&
              TEPvol1 < t4
              ? "T3" :
              TEPvol1 >= t4 &&
                TEPvol1 < t5
                ? "T4"
                : "T5";

    } else if (validationSoftTarget == "t6") {
      slabAchieve = ((TEPvol1 >= t1) && (TEPvol1 < t2))
        ? "T1"
        : (TEPvol1 < t1) || (TEPvol1 == 0)
          ? 0
          : TEPvol1 >= t2 &&
            TEPvol1 < t3
            ? "T2" :
            TEPvol1 >= t3 &&
              TEPvol1 < t4
              ? "T3" :
              TEPvol1 >= t4 &&
                TEPvol1 < t5
                ? "T4" :
                TEPvol1 >= t5 &&
                  TEPvol1 < t6
                  ? "T5"
                  : "T6";
    } else {
      slabAchieve = ((TEPvol1 >= t1) && (TEPvol1 < t2))
        ? "T1"
        : TEPvol1 < t1 || TEPvol1 == 0
          ? 0
          : TEPvol1 >= t2 &&
            TEPvol1 < t3
            ? "T2"
            : "T3";
    }

    formik?.setFieldValue(index, {
      ...y,
      t2_T1: (+((t2 && t1) && (
        parseInt(t2 || 0) -
        parseInt(t1 || 0))) || 0
      ),
      t3_T2: (+((t3 && t2) && (
        parseInt(t3 || 0) -
        parseInt(t2 || 0)) || 0
      )
      ),
      t4_T3: (+((t4 && t3) &&
        (parseInt(t4 || 0) -
          parseInt(t3 || 0))) || 0
      ),
      t5_T4: (+((t5 && t4) &&
        (parseInt(t5 || 0) -
          parseInt(t4 || 0))) || 0
      ),
      t6_T5: (+((t6 && t5) &&
        (parseInt(t6 || 0) -
          parseInt(t5 || 0))) || 0
      ),
      p1:
        t1 && r1
          ? parseInt(t1 || 0) * parseInt(r1 || 0)
          : 0,
      p2:
        t2 && r2
          ? parseInt(t2 || 0) * parseInt(r2 || 0)
          : 0,
      p3:
        t3 && r3
          ? parseInt(t3 || 0) * parseInt(r3 || 0)
          : "",
      p4:
        t4 && r4
          ? parseInt(t4 || 0) * parseInt(r4 || 0)
          : "",
      p5:
        t5 && r5
          ? parseInt(t5 || 0) * parseInt(r5 || 0)
          : "",
      p6:
        t6 && r6
          ? parseInt(t6 || 0) * parseInt(r6 || 0)
          : "",

      perTr: Math.round(TEPpertr1Calculate),

      amount: Math.round(calculateTepAMount),
      slab: slabAchieve,
      closingStock: closingStock,
      closingOutStanding: closingOutStanding
    });


    if (
      t1 != null &&
      t2 != null &&
      +t2 <= +t1 &&
      (validationSoftTarget == "t2" || validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )

    ) {
      handleErrorModal("T2", "T1", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   t2: "",
      // });
    }

    if (
      t2 != null &&
      t3 != null &&
      +t3 <= +t2 &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T3", "T2", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   t3: "",
      // });
    }

    if (
      t3 != null &&
      t4 != null &&
      +t4 <= +t3 &&
      (validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T4", "T3", true);
      return false
    }

    if (
      t4 != null &&
      t5 != null &&
      +t5 <= +t4 &&
      (validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T5", "T4", true);
      return false
    }
    if (
      t5 != null &&
      t6 != null &&
      +t6 <= +t5 &&
      (validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T6", "T5", true);
      return false
    }
    if (
      r2 != null &&
      r1 != null &&
      +r2 <= +r1 &&
      (validationSoftTarget == "t2" || validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R2", "R1", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r2: "",
      // });
    }

    if (
      r3 != null &&
      r2 != null &&
      +r3 <= +r2
      &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R3", "R2", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r3: "",
      // });
    }


    if (
      r4 != null &&
      r3 != null &&
      +r4 <= +r3
      &&
      (
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R4", "R3", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r3: "",
      // });
    }

    if (
      r5 != null &&
      r4 != null &&
      +r5 <= +r4
      &&
      (validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R5", "R4", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r3: "",
      // });
    }

    if (
      r6 != null &&
      r5 != null &&
      +r6 <= +r5
      &&
      (validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R6", "R5", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r3: "",
      // });
    }

  };


  // const calculateFormula = (index) => {
  //   let y = formik?.values[index];
  //   let {
  //     t2,
  //     t1,
  //     t3,
  //     r1,
  //     r2,
  //     r3,
  //     amount,
  //     volume,
  //     TEPpertr,
  //   } = formik?.values[index];


  //   const TEPvol1 = volume ? parseInt(volume) : 0;

  //   let calculateTepAMount = (TEPvol1 >= t1 &&
  //     TEPvol1 < t2) ? (
  //     parseInt(TEPvol1) * parseInt(r1)
  //   ) : (TEPvol1 < t1 || volume == 0) ? 0 : TEPvol1 >= t2 && TEPvol1 < t3 ?
  //     parseInt(TEPvol1) * parseInt(r2) :
  //     parseInt(TEPvol1) * parseInt(r3)

  //   amount == "NaN" && (calculateTepAMount = volume)

  //   let TEPpertr1Calculate = TEPvol1 ? Math.round(
  //     parseInt(calculateTepAMount) / parseInt(TEPvol1)
  //   ) : ((parseInt(calculateTepAMount) / parseInt(TEPvol1)) == "NaN") ? volume : 0

  //   formik?.setFieldValue(index, {
  //     ...y,
  //     "t2_T1": +(
  //       parseInt(t2 || 0) -
  //       parseInt(t1 || 0)
  //     ),
  //     "t3_T2": +(
  //       parseInt(t3 || 0) -
  //       parseInt(t2 || 0)
  //     ),
  //     p1:
  //       (parseInt(t1 || 1) *
  //         parseInt(r1 || 1)) || "",
  //     p2:
  //       (parseInt(t2 || 1) *
  //         parseInt(r2 || 1)) || "",
  //     p3:
  //       (parseInt(t3 || 1) *
  //         parseInt(r3 || 1)) || "",
  //     r1: r1,
  //     r2: r2,
  //     r3: r3,
  //     perTr: Math.round(TEPpertr1Calculate),

  //     amount: Math.round(calculateTepAMount),
  //   });
  //   if (
  //     r2 != null &&
  //     r1 != null &&
  //     +r2 <= +r1
  //   ) {
  //     handleErrorModal("R2", "R1", true);

  //   }

  //   if (
  //     r3 != null &&
  //     r2 != null &&
  //     +r3 <= +r2
  //   ) {
  //     handleErrorModal("R3", "R2", true);

  //   }

  //   if (
  //     t1 != null &&
  //     t2 != null &&
  //     parseInt(t2) <= parseInt(t1)
  //   ) {

  //     handleErrorModal("T2", "T1", true);

  //   }

  //   if (
  //     t2 != null &&
  //     t3 != null &&
  //     +t3 <= +t2
  //   ) {
  //     handleErrorModal("T3", "T2", true);

  //   }
  // };

  const getStateHead = () => (userRole != "SADM") ? [
    {
      title: (
        <div>
          State <br /> Head
        </div>
      ),
      dataIndex: "stateHeadName",
      width: "180px",
      key: "stateHeadName",
    },
  ] : []
  const defaultColumns = useMemo(
    () => [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        fixed: "left",
        width: "120px",
        key: "DealerCode",
        sorter: true,
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        fixed: "left",
        width: "180px",
        sorter: true,
        key: "DealerName",
        //  defaultSortOrder: "descend",
        //  sorter: (a, b) => a.name && b.name && a.name.length - b.name.length,
      },
      {
        title: "Dealer Location",
        dataIndex: "dealerLocation",
        width: "120px",
        fixed: "left",
        sorter: true,
        key: "DealerLocation",
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: "120px",
        sorter: true,
        key: "StateName",
      }, ...getStateHead(),
      {
        title: "Territory Manager",
        dataIndex: "territoryManager",
        width: "100px",
        sorter: true,
        key: "TerritoryManager",
      }, ...getDropdown(),
      {
        title: "Start Month",
        dataIndex: "startMonth",
        width: "120px",
      },
      {
        title: "End Month",
        dataIndex: "endMonth",
        width: "120px",
      },
      {
        title: "Industry",
        dataIndex: "industry",
        width: "120px",
        render: (x, y, index) => (
          <input
            name={formik?.values?.[index]?.industry}
            placeholder="Type here"
            value={formik?.values?.[index]?.industry}
            onBlur={() => {
              calculateFormula(index);
            }}
            onChange={(e) => {
              let value = e.target?.value.replace(/[^\d]/g, "");
              formik.setFieldValue(index, {
                ...y,
                industry: value,
              });
            }}
            className="input1"
            style={{ width: "90%", padding: "5px" }}
          />
        ),
      },
      {
        title: "B",
        dataIndex: "oldBillingQty",
        width: "70px",
      },
      {
        title: "I",
        dataIndex: "oldInstallation",
        width: "70px",
      },
      {
        title: "C",
        dataIndex: "oldCollection",
        width: "70px",
      },
      {
        title: "Opening Stock",
        dataIndex: "stock",
        editable: false,
        children: [
          {
            title: "Nos",
            dataIndex: "oldOpeningStock",
            width: "100px",
          },
          {
            title: "Days",
            dataIndex: "openingStockDays",
            width: "100px",
            // render: (x, y, index) => (
            //   <input
            //     name={formik?.values?.[index]?.openingStockDays}
            //     placeholder="Type here"
            //     value={formik?.values?.[index]?.openingStockDays}
            //     onBlur={() => {
            //       calculateFormula(index);
            //     }}
            //     onChange={(e) => {
            //       let value = e.target?.value.replace(/[^\d]/g, "");
            //       formik.setFieldValue(index, {
            //         ...y,
            //         openingStockDays: value,
            //       });
            //     }}
            //     className="input1"
            //     style={{ width: "90%", padding: "5px" }}
            //   />
            // ),
          },
        ],
      },

      {
        title: "Closing Os. (In Lacs)",
        dataIndex: "stock",
        editable: true,
        children: [
          {
  title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_3",
            width: "100px",
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_2",
            width: "100px",
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_1",
            width: "100px",
          },

          {
            title: (
              <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
              </span>
            ),
            dataIndex: "outStandingDays",
            width: "100px",
            // editable: true,
          },
        ],
      },
      {
        title: "BG",
        dataIndex: "bg",
        children: [
          {
            title: "Lacs",
            dataIndex: "bGLacsCurrentYear",
            width: "100px",
          },
        ],
      },

      {
        title: "BILLING DETAILS",
        dataIndex: "plan",
        editable: true,
        children: [
          {
            title: "B",
            dataIndex: "billing",
            editable: true,
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.billing}
                placeholder="Type here"
                value={formik?.values?.[index]?.billing}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    billing: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "100px",
          },
          {
            title: "I",
            dataIndex: "installation",
            editable: true,
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.installation}
                placeholder="Type here"
                value={formik?.values?.[index]?.installation}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    installation: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "100px",
          },
          {
            title: "C",
            dataIndex: "collection",
            editable: true,
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.collection}
                placeholder="Type here"
                value={formik?.values?.[index]?.collection}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    collection: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "100px",
          },
        ],
      },
      {
        title: "Installation plan",
        dataIndex: "instaPlan",
        editable: true,
        width: "300px",
        children: [
          {
            title: "Nos",
            dataIndex: "delayPlan",
            width: "200px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.delayPlan}
                placeholder="Type here"
                value={formik?.values?.[index]?.delayPlan}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    delayPlan: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            editable: true,
          },
        ],
      },
      {
        title: "Closing Stock",
        dataIndex: "closingStock",
        width: "120px",
        render: (x, y, index) => <div>{x}</div>
      },
      {
        title: "Closing OutStanding (In Lacs)",
        dataIndex: "closingOutStanding",
        width: "120px",
        render: (x, y, index) => <div>{x}</div>
      },
      {
        title: "Books Collection Targets (No. of Trs.)",
        dataIndex: "BooksCollectionTarget",
        editable: true,
        children: [
          ...getTargetT1(),
          ...getTargetT2(),
          ...getTargetT3(),
          ...getTargetT4(),
          ...getTargetT5(),
          ...getTargetT6(),
        ],
      },
      {
        title: "Payout Rate",
        dataIndex: "pr",
        editable: true,

        children: [
          ...getPayoutRateR1(),
          ...getPayoutRateR2(),
          ...getPayoutRateR3(),
          ...getPayoutRateR4(),
          ...getPayoutRateR5(),
          ...getPayoutRateR6(),
        ],
      },
      {
        title: "Payout",
        dataIndex: "payout",
        editable: true,

        children: [
          ...getPayoutP1(),
          ...getPayoutP2(),
          ...getPayoutP3(),
          ...getPayoutP4(),
          ...getPayoutP5(),
          ...getPayoutP6(),
        ],
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        render: (x, y, index) => (
          <input
            name={formik?.values?.[index]?.reason}
            placeholder="Type here"
            value={formik?.values?.[index]?.reason}
            onChange={(e) => {
              let value = e.target?.value;
              const sanitizedValue = value
                ?.replace("<script>", "")
                ?.replace("</script>", "")
              //  .replace(/[^a-zA-Z0-9\s]/g, "");
              formik.setFieldValue(index, {
                ...y,
                reason: sanitizedValue,
              });
            }}
            className="input1"
            style={{ width: "90%", padding: "5px" }}
          />
        ),
        width: "150px",
      },


      {
        title: "Total Expected Payout",
        dataIndex: "TEP",
        editable: true,
        children: [
          {
            title: "Expected Volume",
            dataIndex: "volume",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.volume}
                placeholder="Type here"
                value={formik?.values?.[index]?.volume}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    volume: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
            width: "120px",
          },
          {
            title: "Per Tr.",
            dataIndex: "perTr",
            width: "120px",
            render: (x) => <div>{x || 0}</div>,
          },
          {
            title: "Amount",
            dataIndex: "amount",
            width: "120px",
            render: (x) => <div>{x || 0}</div>,
          },
        ],
      },
    ],
    [formik?.values, schemeLockedStatus]
  );
  const checkValidationFun = () => {

    let extraValidation = {};

    formik?.values?.map((x, index) => {
      let topTargetDetails = x?.dropdownscheme;
      let errors = [];
      if (topTargetDetails?.length == 0) {
        errors.push("Please select top target");
      }
      if (!x.industry) {
        errors.push("Please enter industry");
      }
      // if (!x.openingStockDays) {
      //   errors.push("Please enter open stock days");
      // }
      // if (!x.outStandingDays) {
      //   errors.push("Please enter outstanding days");
      // }
      if (!x.billing) {
        errors.push("Please enter billing");
      }
      if (!x.installation) {
        errors.push("Please enter installation");
      }
      if (!x.collection) {
        errors.push("Please enter collection");
      }
      if (!x.delayPlan && x?.schemeTypeId == 1) {
        errors.push("Please enter Installtion Plan Nos");
      }
      if (!x.volume) {
        errors.push("Please enter volume");
      }
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(x?.t6 || 0) <=
        parseInt(x?.t5 || 0)
      ) {
        errors.push("T6 should be greater than T5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(x?.t5 || 0) <=
        parseInt(x?.t4 || 0)
      ) {
        errors.push("T5 should be greater than T4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(x?.t4 || 0) <=
        parseInt(x?.t3 || 0)
      ) {
        errors.push("T4 should be greater than T3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(x?.t3 || 0) <=
        parseInt(x?.t2 || 0)
      ) {
        errors.push("T3 should be greater than T2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(x?.t2 || 0) <=
        parseInt(x?.t1 || 0)
      ) {
        errors.push("T2 should be greater than T1");
      }
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(x?.r6 || 0) <= parseInt(x?.r5 || 0)
      ) {
        errors.push("R6 should be greater than R5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(x?.r5 || 0) <= parseInt(x?.r4 || 0)
      ) {
        errors.push("R5 should be greater than R4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(x?.r4 || 0) <= parseInt(x?.r3 || 0)
      ) {
        errors.push("R4 should be greater than R3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(x?.r3 || 0) <= parseInt(x?.r2 || 0)
      ) {
        errors.push("R3 should be greater than R2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0)
      ) {
        errors.push("R2 should be greater than R1");
      }
      if (!x.r1) {
        errors.push("Please enter R1");
      }
      if (!x.r2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter R2");
      }
      if (!x.r3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter R3");
      }
      if (!x.r4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter R4");
      }
      if (!x.r5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter R5");
      }
      if (!x.r6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter R6");
      }
      if (!x.t1) {
        errors.push("Please enter T1");
      }
      if (!x.t2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter T2");
      }
      if (!x.t3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter T3");
      }
      if (!x.t4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter T4");
      }
      if (!x.t5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter T5");
      }
      if (!x.t6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter T6");
      }
      if (errors.length > 0) {
        extraValidation[x?.dealerCode] = errors;
      }
    });

    setCodeError(Object?.keys(extraValidation || {}));

    if (Object?.keys?.(extraValidation)?.length != 0) {
      showErrorDialog(extraValidation);
    } else if (Object?.keys?.(extraValidation)?.length == 0) {
      showModal()
    }

  }


  const handleSubmit = (e) => {
    const handleDropDownSchemeCheckIndex = (x) => {
      let { t1, t2, t3, t4, t5, t6 } = x.reduce(
        (a, v) => ({ ...a, [v]: v }),
        {}
      );

      let data =
        t1 && !t2 && !t3 && !t4 && !t5 && !t6
          ? "t1"
          : t1 && t2 && !t3 && !t4 && !t5 && !t6
            ? "t2"
            : t1 && t2 && t3 && !t4 && !t5 && !t6
              ? "t3"
              : t1 && t2 && t3 && t4 && !t5 && !t6
                ? "t4"
                : t1 && t2 && t3 && t4 && t5 && !t6
                  ? "t5"
                  : t1 && t2 && t3 && t4 && t5 && t6
                    ? "t6"
                    : "";

      return data;
    };
    let data = formik?.values?.map((x) => ({
      dealerCode: x.dealerCode,
      dealerId: x.dealerId,
      dealerName: x.dealerName,
      dealerLocation: x.dealerLocation,
      stateName: x.stateName,
      stateHeadName: x.stateHeadName,
      territoryManager: x.territoryManager || "",

      startMonth: moment(x.startMonth).format("MMM YY"),
      endMonth: moment(x.endMonth).format("MMM YY"),
      reason: x.reason,
      status: 1,
      // state: x.stateId,
      billing: x.billing ? +x.billing : 0,
      installation: x.installation ? +x.installation : 0,
      collection: Math.round(x.collection ? +x.collection : 0),
      delayPlan: parseInt(x.delayPlan),
      // isSAP: true,
      // isActive: true,
      // schemePlanStartMonth: startDate,
      // schemePlanEndMonth: endDate,

      schemeTypeId: x.schemeTypeId,
      bookCollectionId: x.bookCollectionId,
      payoutId: x.payoutId,
      schemeId: x.schemeId,
      schemeNo: x.schemeNo,
      schemePlanId: x.schemePlanId,
      openingStockDays: x.openingStockDays,
      outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
      ...(handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
        ? {
          t1: x?.t1 || 0,
          t2: 0,
          t3: 0,
          t4: 0,
          t5: 0,
          t6: 0,
          t2_T1: x?.t1 || 0,
          t3_T2: 0,
          t4_T3: 0,
          t5_T4: 0,
          t6_T5: 0,
          p1: x.r1 || 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          r1: x?.r2 || 0,
          r2: 0,
          r3: 0,
          r4: 0,
          r5: 0,
          r6: 0,
        }
        : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
          ? {
            t1: x?.t1 || 0,
            t2: x?.t2 || 0,
            t3: 0,
            t4: 0,
            t5: 0,
            t6: 0,
            t2_T1: x?.t2_T1 || 0,
            t3_T2: x?.t3_T2 || 0,
            t4_T3: 0,
            t5_T4: 0,
            t6_T5: 0,
            p1: x?.p1 || 0,
            p2: x?.p2 || 0,
            p3: 0,
            p4: 0,
            p5: 0,
            p6: 0,
            r1: x?.r1 || 0,
            r2: x?.r2 || 0,
            r3: 0,
            r4: 0,
            r5: 0,
            r6: 0,
          }
          : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
            ? {
              t1: x.t1 || 0,
              t2: x.t2 || 0,
              t3: x.t3 || 0,
              t4: 0,
              t5: 0,
              t6: 0,
              t2_T1: x?.t2_T1 || 0,
              t3_T2: x?.t3_T2 || 0,
              t4_T3: x?.t4_T3 || 0,
              t5_T4: 0,
              t6_T5: 0,
              p1: x.p1 || 0,
              p2: x.p2 || 0,
              p3: x.p3 || 0,
              p4: 0,
              p5: 0,
              p6: 0,
              r1: x?.r1 || 0,
              r2: x?.r2 || 0,
              r3: x?.r3 || 0,
              r4: 0,
              r5: 0,
              r6: 0,
            }
            : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t4"
              ? {
                t1: x?.t1 || 0,
                t2: x?.t2 || 0,
                t3: x?.t3 || 0,
                t4: x?.t4 || 0,
                t5: 0,
                t6: 0,
                t2_T1: x?.t2_T1 || 0,
                t3_T2: x?.t3_T2 || 0,
                t4_T3: x?.t4_T3 || 0,
                t5_T4: x?.t5_T4 || 0,
                t6_T5: 0,
                p1: x.p1 || 0,
                p2: x.p2 || 0,
                p3: x.p3 || 0,
                p4: x.p4 || 0,
                p5: 0,
                p6: 0,
                r1: x?.r1 || 0,
                r2: x?.r2 || 0,
                r3: x?.r3 || 0,
                r4: x?.r4 || 0,
                r5: 0,
                r6: 0,
              }
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t5"
                ? {
                  t1: x.t1 || 0,
                  t2: x.t2 || 0,
                  t3: x.t3 || 0,
                  t4: x.t4 || 0,
                  t5: x.t5 || 0,
                  t6: 0,
                  t2_T1: x?.t2_T1 || 0,
                  t3_T2: x?.t3_T2 || 0,
                  t4_T3: x?.t4_T3 || 0,
                  t5_T4: x?.t5_T4 || 0,
                  t6_T5: 0,
                  p1: x?.p1 || 0,
                  p2: x?.p2 || 0,
                  p3: x?.p3 || 0,
                  p4: x?.p4 || 0,
                  p5: x?.p5 || 0,
                  p6: 0,
                  r1: x?.r1 || 0,
                  r2: x?.r2 || 0,
                  r3: x?.r3 || 0,
                  r4: x?.r4 || 0,
                  r5: x?.r5 || 0,
                  r6: 0,
                }
                : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t6"
                  ? {
                    t1: x?.t1 || 0,
                    t2: x?.t2 || 0,
                    t3: x?.t3 || 0,
                    t4: x?.t4 || 0,
                    t5: x?.t5 || 0,
                    t6: x?.t6 || 0,
                    t2_T1: x?.t2_T1 || 0,
                    t3_T2: x?.t3_T2 || 0,
                    t4_T3: x?.t4_T3 || 0,
                    t5_T4: x?.t5_T4 || 0,
                    t6_T5: x?.t6_T5 || 0,
                    p1: x?.p1 || 0,
                    p2: x?.p2 || 0,
                    p3: x?.p3 || 0,
                    p4: x?.p4 || 0,
                    p5: x?.p5 || 0,
                    p6: x?.p6 || 0,
                    r1: x?.r1 || 0,
                    r2: x?.r2 || 0,
                    r3: x?.r3 || 0,
                    r4: x?.r4 || 0,
                    r5: x?.r5 || 0,
                    r6: x?.r6 || 0,
                  }
                  : {
                    t1: 0,
                    t2: 0,
                    t3: 0,
                    t4: 0,
                    t5: 0,
                    t6: 0,
                    t2_T1: 0,
                    t3_T2: 0,
                    t4_T3: 0,
                    t5_T4: 0,
                    t6_T5: 0,
                    p1: 0,
                    p2: 0,
                    p3: 0,
                    p4: 0,
                    p5: 0,
                    p6: 0,
                    r1: 0,
                    r2: 0,
                    r3: 0,
                    r4: 0,
                    r5: 0,
                    r6: 0,
                  }),
      topTarget: x.dropdownscheme?.toString(),
      volume: x.volume,
      perTr: Math.round(x.perTr),
      amount: x.amount,
      // ...(y != "draft" && { Manager: y.Manager }),
      // fyear: currentFiscalYear,
      userid: +userId,
      username: userName,
      userrole: userRole,
      industry: x.industry ? parseInt(x.industry) : 0,
      oldBillingQty: x.oldBillingQty,
      oldInstallation: x.oldInstallation,
      oldCollection: Math.round(x.oldCollection),
      oldOpeningStock: x.oldOpeningStock,
      closingOutStandingMonth_1: x.closingOutStandingMonth_1,
      closingOutStandingMonth_2: x.closingOutStandingMonth_2,
      closingOutStandingMonth_3: x.closingOutStandingMonth_3,
      bGLacsCurrentYear: x.bGLacsCurrentYear,
      holdStatus: x?.holdStatus,
      HoldReason: x.reason,
      IsModified: selectedLabel == "Hold Schemes" ? true : false
    }));
    if (selectedLabel === 'Regular Schemes' || selectedLabel === 'Schemes') {
      data = data.map(d => {
        delete d.HoldReason;
        return d;
      });
    } else if (selectedLabel === "Hold Schemes") {
      data = data.map(d => {
        delete d.reason;
        return d;
      });
    }
    if (data?.[0]?.holdStatus == 9) {
      dispatch(postUpdateHoldScheme(data)).then(async (data) => {
        if (data.payload?.errorMessage == "Hold Scheme Updated successfully!!") {
          message.success({
            content: "Record Updated Successfully",
            className: "custom-message",
          });
          await backBtn(false)
        }

      });
    } else {
      dispatch(getSaveModifiedDashboardApi(data))
        .then(async (result) => {
          if (result.payload?.errorMessage == "Data Saved successfully!!") {
            await dispatch(resetSaveModification())
            await message.success({
              content: "Record sent for approval successfully",
              className: "custom-message",
            });
            await backBtn(false)
          }
        })
    }

  };
  const handleRemoveFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };
  const handleFileUpload = (e) => {
    const maxFileSize = 5 * 1024 * 1024; // Set maximum file size to 5MB
    const files = e.target.files;

    // Check if any file exceeds the maximum file size limit
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxFileSize) {
        ToastifyShow(`File ${files[i].name} is too large. Maximum file size is 5MB.`, "error");
        return;
      }
  
      // Check file headers for XLSX files
      if (!isValidFileHeader(files[i])) {
        ToastifyShow(`Invalid file header for ${files[i].name}.`, "error");
        return;
      }
  
      // Check content type for XLSX files
      if (!isValidContentType(files[i])) {
        ToastifyShow(`Invalid content type for ${files[i].name}.`, "error");
        return;
      }
  
    }

    if (files[0].name.split('.')?.[1] !== 'xlsx') {
      ToastifyShow("Please upload an XLSX file only.", "error");
      return;
    }
  
    const reader = new FileReader();
    reader.readAsBinaryString(files[0]);
  
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  
    reader.onload = async(e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      let calArr = [];
      // schemeLockedStatus?.t1 || userRole == "ZADM"
      let dataItem= formik?.values?.map((y, index) => {
        if (parsedData.find((x) => x["DEALER CODE"] === y?.dealerCode)) {
    
          //TOP SLAB
          let z = parsedData.find((x) => x["DEALER CODE"] === y?.dealerCode);
          let dropdownscheme= z?.["TOP SLAB"]?.toUpperCase()=="T6"&&y?.topTargetArrState?.t6?["t1","t2","t3","t4","t5","t6"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T5"&&y?.topTargetArrState?.t5?["t1","t2","t3","t4","t5"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T4"&&y?.topTargetArrState?.t4?["t1","t2","t3","t4"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T3"?["t1","t2","t3"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T2"?["t1","t2"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T1"?["t1"]:["t1","t2"]

           let paget1=dropdownscheme.includes("t1"||"t2"||"t3"||"t4"||"t5"||"t6") 
           let paget2=dropdownscheme.includes("t2"||"t3"||"t4"||"t5"||"t6") 
           let paget3=dropdownscheme.includes("t3"||"t4"||"t5"||"t6") 
           let paget4=dropdownscheme.includes("t4"||"t5"||"t6") 
           let paget5=dropdownscheme.includes("t5"||"t6") 
           let paget6=dropdownscheme.includes("t6") 

            let updatedR1 = paget1?parseFiveDigitNumber(z?.["PAYOUT RATE"]) : 0; //1200
            let updatedR2 = paget2?parseFiveDigitNumber(z?.["__EMPTY_11"]):0; //1300
            let updatedR3 = paget3?parseFiveDigitNumber(z?.["__EMPTY_12"]) :0; //1400
            let updatedR4 = paget4?parseFiveDigitNumber(z?.["__EMPTY_13"]) : 0; //1500
            let updatedR5 = paget5?parseFiveDigitNumber(z?.["__EMPTY_14"]) : 0; //1600
            let updatedR6 = paget6?parseFiveDigitNumber(z?.["__EMPTY_15"]) : 0; //1700
      
            let updateT1 = paget1?parseInt(z?.["BOOKS COLLECTION TARGETS (NO. OF TRS.)"]): 0; //60
            let updateT2 = paget2?parseInt(z?.["__EMPTY_6"]) : 0; //700
            let updateT3 = paget3?parseInt(z?.["__EMPTY_7"]) : 0; //800
            let updateT4 = paget4?parseInt(z?.["__EMPTY_8"]) : 0; //900
            let updateT5 = paget5?parseInt(z?.["__EMPTY_9"]) : 0; //1000
            let updateT6 = paget6?parseInt(z?.["__EMPTY_10"])  : 0; //1100
        
            calArr.push(index);
          return {
            ...y,
            industry: parseInt(z?.INDUSTRY) || 0, //100
            dropdownscheme:dropdownscheme,
            billing: parseInt(z?.["BILLING DETAILS"]) || 0, //200
            installation: parseInt(z?.__EMPTY_4) || 0, //300
            collection: parseInt(z?.__EMPTY_5) || 0, //400
            delayPlan: parseInt(z?.[`Installation Plan`]) || 0, //500
            t1: updateT1 || 0, //600
            t2: updateT2||0,//700
            t3: updateT3||0,//800
            t4: updateT4||0,//900
            t5: updateT5||0,//1000
            t6: updateT6||0,//1100
            r1:  updatedR1||0, //1200
            r2: updatedR2||0, //1300
            r3: updatedR3||0, //1400
            r4: updatedR4||0, //1500
            r5: updatedR5||0, //1600
            r6: updatedR6||0, //1700
            reason: z?.REMARKS || "", //1700
            volume: parseInt(z?.[`Expected Volume`]) || 0, //1700
          };
        } else {
          return y;
        }
      });
      await setCheckCalculate(true);
      await setCheckCalculateArr(calArr);
      await formik?.setValues(dataItem);
      ToastifyShow("File Uploaded successfully","success")
    };
  };

  const handleSampleFile = () => {

    const headerStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
        diagonal: { diagonalUp: true, diagonalDown: true },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    const headerStyle1 = {
   
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
        diagonal: { diagonalUp: true, diagonalDown: true },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
 if (formik?.values?.length == 0) {
        ToastifyShow("Something went wrong", "error")
        return false
      }

      let tab_text =ModificationCollectionSchemeDownload({
        collectionLock:"t6"
      })

      formik?.values?.length != 0 &&
      formik?.values.forEach((header) => {
        tab_text += `<tr ><td>${header?.dealerCode}</td>
  <td>${header?.dealerLocation}</td>
  <td>${header?.dealerName || ""}</td>
  <td>${header?.stateName}</td>
  <td>${header?.territoryManager}</td>
  <td>${header?.startMonth|| ""}</td>
  <td>${header?.endMonth || ""}</td>
  <td>${header?.dropdownscheme?.includes("t6")
            ? "T6"
            : header?.dropdownscheme?.includes("t5")
              ? "T5"
              : header?.dropdownscheme?.includes("t4")
                ? "T4"
                : header?.dropdownscheme?.includes("t3")
                  ? "T3"
                  : header?.dropdownscheme?.includes("t2")
                    ? "T2"
                    : header?.dropdownscheme?.includes("t1")
                      ? "T1"
                      : ""
          }
  </td>
  <td>${header?.industry}</td>
  <td>${header?.oldBillingQty}</td>
  <td>${header?.oldInstallation}</td>
  <td>${header?.oldCollection}</td>
  <td>${header?.oldOpeningStock || 0}</td>
  <td>${header?.openingStockDays || 0}</td>
    <td>${header?.closingOutStandingMonth_3}</td>
    <td>${header?.closingOutStandingMonth_2}</td>
    <td>${header?.closingOutStandingMonth_1}</td>
    <td>${header?.outStandingDays || 0}</td>
    <td>${header?.bGLacsCurrentYear}</td>
    <td>${header?.billing}</td>
    <td>${header?.installation}</td>
    <td>${header?.collection}</td>
    <td>${header?.delayPlan}</td>
    <td>${header?.t1}</td>
    <td>${header?.t2}</td>
    <td>${header?.t3}</td>
    <td>${header?.t4}</td>
    <td>${header?.t5}</td>
    <td>${header?.t6}</td>
    <td>${header?.r1}</td>
    <td>${header?.r2}</td>
    <td>${header?.r3}</td>
    <td>${header?.r4}</td>
    <td>${header?.r5}</td>
    <td>${header?.r6}</td>
    <td>${header?.reason||""}</td>
    <td>${header?.volume}</td>
    
  </tr>`;
      });

      tab_text += "</table>";

    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);

    // Apply styles to the table using a class


    // generate workbook with XLSXStyle
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    



      function xSeparateKeys(obj) {
        return Object.keys(obj).filter(key => 
          ((key.startsWith('X') && key !== 'X2'&&key!=='X1')|| 
          (key.startsWith('Z') && key !== 'Z2') ||
           (key.startsWith('Y') && key !== 'Y2') ||
           (key.startsWith('ADD') && key !== 'ADD1' && key !== 'ADD2')||
           (key.startsWith('AA') && key !== 'AA2')||
           (key.startsWith('AB') && key !== 'AB2')||
           (key.startsWith('AC') && key !== 'AC2')||
           (key.startsWith('AD') && key !== 'AD2'&&key!=='AD1')||
           (key.startsWith('AE') && key !== 'AE2')||
           (key.startsWith('AF') && key !== 'AF2')||
           (key.startsWith('AG') && key !== 'AG2')||
           (key.startsWith('AH') && key !== 'AH2')||
           (key.startsWith('AI') && key !== 'AI2')||
           (key.startsWith('AJ') && key !== 'AJ1')||
           (key.startsWith('AK') && key !== 'AK1')||
           (key.startsWith('H') && key !== 'H1')||
           (key.startsWith('W')&&key!=='W1'&&key!=='W2')||
           (key.startsWith('T')&&key!=='T1'&&key!=='T2')||
           (key.startsWith('U')&&key!=='U1'&&key!=='U2')||
           (key.startsWith('V')&&key!=='V1'&&key!=='V2')||
           (key.startsWith('I') && key !== 'I1')
          ))
      }
      const xKeys= xSeparateKeys(wb?.Sheets?.Sheet1);


    Object?.keys(wb?.Sheets?.Sheet1 || {})
      ?.filter(
        (x) =>
          x != "!rows" &&
          x != "!cols" &&
          x != "!merges" &&
          x != "!ref" &&
          x != "!fullref" &&
          !xKeys?.includes(x)
      )
      ?.forEach((x) => {
        wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle;
      });

      Object?.keys(wb?.Sheets?.Sheet1 || {})
      ?.filter(
        (x) =>xKeys?.includes(x)
      )
      ?.forEach((x) => {
        wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle1;
      });
    // Dynamically set column widths

    wb.Sheets[wb.SheetNames[0]]["!cols"] = [
      { wch: 15 },
      { wch: 20 },
      { wch: 30 },
      { wch: 10 },
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    // Dynamically set row heights
    wb.Sheets[wb.SheetNames[0]]["!rows"] = [
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },

      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      // Add more row heights as needed
    ];

    // remove element
    document.body.removeChild(elt);

    XLSX.writeFile(
      wb,
      (formik?.values?.[0]?.type == "1" || formik?.values?.[0]?.type == "")
        ? "With_Delivery_Plan.xlsx"
        : "Without_Delivery_Plan.xlsx"
    );
    }

  
  const menu = (
    <Menu>
      <Menu.Item>
        {selectedFiles?.length == 0 && (
          <div className="custom-file-input">
            <input type="file" accept=".xlsx" onChange={handleFileUpload} />
            <label htmlFor="fileInput">Choose File</label>
          </div>
        )}
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <Button onClick={() => handleRemoveFile(index)} className="mx-1">
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </Menu.Item>
      <Menu.Item onClick={handleSampleFile}>Download Sample File</Menu.Item>
    </Menu>
  );
  return (
    <div className="create_scheme_wrap general-bscheme">
           <div className="d-flex justify-content-between">
      <Button type="primary" icon={<ArrowBackRounded />} size="middle" onClick={() => {
        backBtn(false)
      }} />
          <Space
              style={{
                padding: "0",
                paddingTop: "14px",
              }}
              direction="vertical"
            >
              <Tooltip title={"Import/Export to Excel"}>
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button className="h-2">Import/Export to Excel</Button>
              </Dropdown>
              </Tooltip> 
            </Space>
            </div>
      <div className="table_scheme_wrap generate-finace-report">
        <Form>
          <Table
            className="finance"

            loading={loadingState == "pending" ? true : false}
            columns={defaultColumns}
            dataSource={formik?.values}
            bordered
            pagination={false}
            scroll={{
              x: 1000,
              y: 300,
            }}
            rowClassName={(record, index) => codeError?.includes(record?.dealerCode) ? "data-row active-row" : ""}
          />
        </Form>
        <div className="pt-3 d-flex f-reverse">
          <Button
            type="default"
            shape="round"
            style={{
              margin: "10px",
              background: "#FCE838",
              fontWeight: "700",
              textTransform: "uppercase",
              border: "none",
              padding: "20px",
            }}
            size="large"
            onClick={checkValidationFun}
          >
            Update & Submit
          </Button>
        </div>
      </div>

      <Modal open={isModalOpen} onOk={() => {
        handleSubmit()
        handleOk()
      }} onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose={true}
      >
        <h2>Are you sure you want to submit</h2>
      </Modal>

      {/* <ValidationErrorsModal
        open={openErrorDialog.open}
        onClose={() => {
          setOpenErrorDialog({ open: false, errorsFields: [] });
        }}
        errorsFields={openErrorDialog?.errorsFields}
      /> */}
      <ValidationDifferentSchemeError

        open={openErrorDialog.open}
        onClose={() => {
          setOpenErrorDialog({ open: false, errorsFields: {} });
        }}
        errorsFields={openErrorDialog?.errorsFields}

      />
      <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>
        <p>
          {errorItem.firstItem} should be greater then {errorItem.secondItem}
        </p>
      </SMDialog>
    </div>
  );
};
export default ModificationEditAllSchemes;
