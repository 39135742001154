import { useState, useEffect, useMemo, useCallback, createRef } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Space, Dropdown, message } from "antd";
import "../statusBox/status.css";

import { getCurrentFiscalYear } from "../../utils/dateFormatter";
import { decryptData } from "../../utils/localStorageEncodeDecode";
const currentFiscalYear = getCurrentFiscalYear();

const CollectionFilter = ({onGetData}) => {
    const [items, setItems] = useState([]);
    const [selectedSchemeType, setSelectedSchemeType] = useState(1);

    const [openModal, setOpenModal] = useState(false);
    const [openDeviationModal, setOpenDeviationModal] = useState(false);

    const [test, setTest] = useState(false);
    // const [closeModal,setCloseModal] =useState(true);
    const {userRole,userId} = decryptData(sessionStorage.getItem("userInfo"));


    const handleSchemeDropdown = (schemeType) => {
        
        setSelectedSchemeType(schemeType);
        const demoParams = {
        fyear: currentFiscalYear,
        schemeType: schemeType,
        userid: userId,
        userRole: userRole,
        };
        onGetData(demoParams);
    };


    const schemeTypeData = [
        {
            key: "1",
            label: <a onClick={() => handleSchemeDropdown(1)}> Collection - With Delivery Plan</a>,
        },
        {
            key: "2",
            label: <a onClick={() => handleSchemeDropdown(2)}> Collection - Without  Delivery Plan</a>,
        }
    ];

    const getSchemeType = (schemeType) => {
        if (schemeType == 1) {
        return "Collection - With Delivery Plan";
        }
        if (schemeType == 2) {
        return "Collection - Without  Delivery Plan";
        }
      
    };

    return (
        <div 
        className="mx-1"
        >
        <Dropdown
        className="h-full"
            // className="action_button"
            menu={{ 
                items: schemeTypeData,
                defaultSelectedKeys:['1'],
                
            }}
            trigger={["click"]}
        >
         <Button  className="h-full"> 
            <Space
className="h-full"
            >
               
                <span className="h-full">
                {getSchemeType(selectedSchemeType)}
                  </span>
               
                <DownOutlined />
            </Space>
            </Button>
        
        </Dropdown>
        </div>
    );

}

export default CollectionFilter