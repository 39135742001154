import React, { Table, Tag, Space, Row, Col, Button, message,Pagination } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { draftScheme } from "../../../../redux/installation/AllDraftScheme";
import dayjs from "dayjs";
import ConfirmModal from "../../../../components/modal/alertModal/ConfirmModal";
import { useNavigate } from "react-router-dom";
import axiosAPI from '../../../../services/axiosApi';
import { getCurrentFiscalYear } from '../../../../utils/dateFormatter';
import { ReactComponent as MergeIcon } from "../../../../assets/vector/merge.svg";
import { ReactComponent as UnmergeIcon } from "../../../../assets/vector/unmerge.svg";
import unMergeScheme from '../../../../utils/mergeUnmerge/unMergeScheme';
import mergeScheme from '../../../../utils/mergeUnmerge/mergeScheme';
import MergeModal from "../../../../components/modal/alertModal/ConfirmModal";
import ToastifyShow from "../../../../components/ToastifyShow";
import { ReactComponent as Download } from "../../../../assets/vector/download.svg";
import XLSX from 'xlsx-js-style';
import { decryptData } from '../../../../utils/localStorageEncodeDecode';
//import type { ColumnsType } from 'antd/es/table'
const currentFiscalYear = getCurrentFiscalYear()

const InitCollectionData = {
  currentYear: "2024",
  pageSize: "150",
  pageNumber: 1,
  sortingOrder: "ascend",
  sortingType: "TerritoryManager"
};
const SingleTargetWithUpperCapDraftScheme = ({ getSelectedRowKeys, selectedSchemeType }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [schemeIdNo, setSchemeIdNo] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openSubmitModal, setOpenSubmitModal] = useState(false)
  const [keys, setKeys] = useState([]);
  const [messageApi, contextHolder] = message.useMessage()
  const [openMergeModal, setOpenMergeModal] = useState(false)
  const [openUnMergeModal, setOpenUnMergeModal] = useState(false)
  const [disable, setDisable] = useState(false)
  const [enable, setEnable] = useState(false)
  const [edit, setEdit] = useState(true)
  const [selectedRows, setSelectedRows] = useState([])
  const listData = useSelector((state) => state.draftScheme.data);
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userData.userRole;
  const userId = userData.userId;
  const userName = userData.userName;


  const handleSingleEdit = (record) => {
    const selectedRow = JSON.parse(sessionStorage.getItem('rowData'))
    const recordExist = selectedRow && selectedRow.find(item => item.key === record.key)

    if (recordExist) {
      navigate("/installation/draftSchemes/edit",{state:record?.scheme});
    } else {
      messageApi.open({
        type: 'warning',
        content: 'Please select the checkbox !',
        duration: 2
      });

    }

  };

  const columns = [
    // {
    //   title: 'Scheme No.',
    //   dataIndex: 'scheme',
    //   width: "20px",
    //   fixed: 'left',
    //   render: (item) => {
    //     let color = " "
    //       switch (item.status) {
    //         case 0:
    //           color = "#FCE838";
    //           break;
    //         case 1:
    //           color = "#FCE838";
    //           break;
    //         case 2:
    //           color = "#51DE4E";
    //           break;
    //         case 3:
    //           color = "#FFAE00";
    //           break;
    //         case 4:
    //           color = "#FF3232";
    //           break;
    //         case 5:
    //           color = "#FF3232";
    //           break;
    //         default:
    //           color = "pink";
    //           break;
    //       }
    //     return(
    //       <Tag
    //       // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
    //       style={{color: 'black', fontSize: '13px'}}
    //         color={color}
    //       >
    //        {item.schemeNo}
    //       </Tag>
    //     )
    //   },
    //   // defaultSortOrder: "descend",
    //   // sorter: (a, b) => a.name.length - b.name.length,
    // },
    {
      title: "Dealer Code",
      dataIndex: "code",
      width: "180px",
      fixed: "left",
      sorter: true,
      key:"DealerCode",
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      width: '180px',
      fixed: "left",
      sorter: true,
      key:"DealerName",
       defaultSortOrder: "ascend",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "190px",
      fixed: "left",
      sorter: true,
      key:"DealerLocation",
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.name.length - b.name.length,
      filters: [
        {
          text: "Amritsar",
          value: "Amritsar",
        },
        {
          text: "Zirakpur",
          value: "Zirakpur",
        },
      ],
      onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },
    {
      title: "State",
      dataIndex: "state",
      // fixed: "left",
      width: '120px',
      sorter: true,
      defaultSortOrder: "ascend",
      key:"StateName",
    },
    {
      title: "Territory Manager",
      dataIndex: "Manager",
      width: '200px',
      sorter: true,
      defaultSortOrder: "ascend",
      key:"TerritoryManager",
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      width: '160px',
      // sorter: true,
    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      width: '160px',
      // sorter: true,
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      //   sorter: true,
      width: '250px'

    },
    {
      title: "Installation Target",
      dataIndex: "Target",
      width: '190px'

    },
    {
      title: "Upper Cap",
      dataIndex: "UC",
      width: '120px'

    },
    {
      title: "inc. Per Tractor",
      dataIndex: "incPerTarget",
      width: '190px'

    },
    {
      title: "Payout",
      dataIndex: "p1",
      width: '120px'

    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: '120px'

    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: '100px',

      render: (_, record) => (
        <Space size="small">
          <Button
            disabled={schemeIdNo.length > 1}
            onClick={() => handleSingleEdit(record)}>Edit</Button>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData?.userId;
    const userRole = userData?.userRole;
    const demoParams = {
      fyear: currentFiscalYear,
      schemeType: selectedSchemeType,
      userId: userId,
      userRole,
      pageNumber : collectionData.pageNumber,
      pageSize :collectionData.pageSize,
      sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
      sortingType: collectionData.sortingType || "TerritoryManager"
    }
    const fetchGeneratedScheme = async() => {
      let result = await axiosAPI.post(`SchemeGenerate/GetDraftedScheme`,demoParams)
      dispatch(draftScheme(result.data));
    };
    fetchGeneratedScheme(demoParams)
  }, [collectionData])

  useEffect(() => {
    const data = [];
    listData.data?.data  &&  listData.data?.data.map((item, index) => {
      for (let i = 0; i < 1; i++) {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        data.push({
          status: item.status,
          key: item.schemeId,
          scheme: item,
          code: item.dealerCode,
          name: item.dealerName,
          Location: item.dealerLocation,
          state: item.stateName,
          Manager: item.territoryManager,
          StartMonth: formattedStartDate,
          EndMonth: formattedEndDate,
          Target: item?.installationTarget,
          UC: item?.upperCap,
          incPerTarget: item.incPerTarget,
          Payout: item.p1,
          // Payout: item.p1,
          // industry: 102,
          // b: 52,
          // i: 23,
          // c: 21,
          // target: '',
          // nos: 50,
          // days: 0,
          // nov21: null,
          // dec21: null,
          // lac: 66,
          t1: item?.t1,
          t2: item?.t2,
          // BooksCollectionTargetT2T1: item.t2_T1,
          // t3: item.t3,
          // BooksCollectionTargetT3T2: item.t3_T2,
          r1: item?.r1,
          r2: item?.r2,
          // PayoutRatep3: item.r3,
          p1: item?.p1,
          p2: item?.p2,
          // payoutR3: item.p3,
          reason: item.reason,
          actual_Installation_Last_Year_Month:item?.actual_Installation_Last_Year_Month
          // TEPvol: item.volume,
          // TEPpertr: item.perTr,
          // TEPamount: item.amount,
          // LMSA: 'T2',
          // LCQ: 55,
          // LMSA1: 'T1',
          // LCQ1: 18000

        })
      }
    })
    setDataSource(data);
  }, [listData])

  useEffect(() => {
    // const value = schemeIdNo.map(item => item.scheme.isMerge)
    const schemeId = schemeIdNo.map(id => id.scheme.schemeId)
    setKeys(schemeId)
    // const status = schemeIdNo.some(item => item.scheme.status === 3 )
    // setDisable(status ? status : value.includes(true) && !value.includes(false) ? true : value.includes(true))
    // setEnable(status ? status : !value.includes(true)  && value.includes(false)? true : value.includes(false))
  }, [schemeIdNo])

  const fetchGenerateScheme = async (demoParams) => {
    let result = await axiosAPI.post(`SchemeGenerate/GetDraftedScheme`,demoParams);
    dispatch(draftScheme(result.data));
  };

  const handleSaveScheme = async () => {
    // e.preventDefault()
    const payload = listData?.data?.data?.filter((data) => keys.includes(data.schemeId)).map((obj) => {
      const { status, ...rest } = obj;
      return rest;
    });
    await axiosAPI.post(
      `UpdateScheme/DraftToSchemeGenerate`,
      payload
      )
      
    .then((result) => {
      // setOpenSuccessModal(!openSuccessModal);
      const userData = decryptData(sessionStorage.getItem("userInfo"));
      const userId = userData.userId;
      const demoParams = {
        fyear: currentFiscalYear,
        schemeType: 6,
        userid: userId,
      };
      fetchGenerateScheme(demoParams);
    });
    setLoading(false)
    // setOpenUnMergeModal(false)
    navigate("/installation/allSchemes");
    // window.location.replace('/installation/allSchemes')
  }
  const handleCancel = () => {
    setOpenSubmitModal(!openSubmitModal)
  }

  const handleUnMergeModal = () => {
    if(schemeIdNo.length === 0){
      return message.warning("Select the merged scheme first")
    }else{
      setOpenUnMergeModal(!openUnMergeModal)
    }
  }
  useEffect(() => {
    const value = schemeIdNo.map(item => item.scheme.isMerge)
    const status = schemeIdNo.some(item => item.scheme.status === 3 || item.scheme.status === 1)

    value.includes(true) ? setEdit(false) : setEdit(edit)
    setDisable(status ? status : value.includes(true) && !value.includes(false) ? true : value.includes(true))
    setEnable(status ? status : !value.includes(true) && value.includes(false) ? true : value.includes(false))
  }, [schemeIdNo])

  const handleOk = async () => {
    await mergeScheme(schemeIdNo, userName, userId)
    setLoading(false)
    setOpenMergeModal(false)
  }

  const checkDate = async () => {
    let startMonth = ''
    let endMonth = ''
    for(let i=0; i<=schemeIdNo.length -1 ; i++){
      for(let j= i + 1; j <= schemeIdNo.length-1; j++){
        if(schemeIdNo[i].StartMonth === schemeIdNo[j].StartMonth && schemeIdNo[i].EndMonth === schemeIdNo[j].EndMonth ){
            const monthStart = schemeIdNo[i].StartMonth.split("'")[0]
            const startYear = schemeIdNo[i].StartMonth.split("'")[1]
            const monthEnd =  schemeIdNo[i].EndMonth.split("'")[0]
            const endYear = schemeIdNo[i].EndMonth.split("'")[1]
            startMonth = dayjs(`${monthStart} 20${startYear}`).format()
            endMonth = dayjs(`${monthEnd} 20${endYear}`).format()

        }
      }
    }
    if(startMonth === ''){
      return {
        error: "Scheme can't be merged due to different start and end month"
      }
      // return message.warning("Scheme can't be merged due to different start  and end month")
    }
    return {
      error: ''
    }
  }

  const handleMerge = async (e, allData) => {
   
    try{
      if (schemeIdNo.length === 0){
       return message.warning("Select Schemes to be merged")
      }
      const errorMessage =  await checkDate()
      if(!!errorMessage?.error) {
        throw new Error(errorMessage.error)
      }
      if ( schemeIdNo.length <= 4 ){
        setOpenMergeModal(!openMergeModal) }
      else {
        message.warning("More than 4 Schemes can't be merged")
      }
    }catch(e){
      message.warning(e.message)
    }


  }
  const handleUnMerge = async (e, allData) => {

    await unMergeScheme(schemeIdNo, userName, userId)
    setLoading(false)
    setOpenUnMergeModal(false)
  }
  const captalizeObjKeys = obj => Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
  );
  const handleDownload = () => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };

    const data = dataSource.map(rec => rec.scheme).filter(item => selectedRows.includes(item.schemeId)).map(item=> {
      let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
      let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
      let createDate = dayjs(item.createdDate).utc('z').local().tz(dayjs.tz.guess()).format("MMM D, YYYY");
      return captalizeObjKeys({
        // Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month:formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month:formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month||"",
        Installation_Target: item.installationTarget,
        Upper_Cap: item.upperCap,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason,
        // Created_Date: createDate,
      })
    })
    if(data?.length==0){
    ToastifyShow("Something went wrong","error")
    return false
    }
    let tab_text = `<table>
    <thead>
    <tr>
        <th>DEALER CODE</th>
        <th>DEALER NAME</th>
        <th>STATE</th>
        <th>DEALER LOCATION</th>
        <th>TERRITORY MANAGER</th>
        <th>START MONTH</th>
        <th>END MONTH</th>
        <th>ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th>INSTALLATION TARGET</th>
        <th>UPPER CAP</th>
        <th>INC. PER TRACTOR</th>
        <th>PAYOUT</th>
        <th>REMARKS</th>
        </tr>
    </thead>`;

      data?.forEach((x) => {
        tab_text += `<tr >
  <td>${x?.DEALER_CODE}</td>
  <td>${x?.DEALER_NAME}</td>
  <td>${x?.STATE}</td>
  <td>${x?.DEALER_LOCATION}</td>
  <td>${x?.TERRITORY_MANAGER}</td>
  <td>${x?.START_MONTH}</td>
  <td>${x?.END_MONTH}</td>
  <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${x?.INSTALLATION_TARGET}</td>
  <td>${x?.UPPER_CAP}</td>
  <td>${x?.INC_PER_TRACTOR}</td>
  <td>${x?.PAYOUT}</td>

  <td>${x?.REMARKS}</td>

  </tr>`;
      });
      let totals = {
        INSTALLATION_TARGET: 0,
        UPPER_CAP: 0,
        INC_PER_TRACTOR:0,
        PAYOUT: 0,
        REMARKS:" "
       
    };
    
    data.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item[key]) || 0 ;
        }
    });
    totals.INC_PER_TRACTOR =  totals.INSTALLATION_TARGET !== 0 ? parseInt(totals.PAYOUT / totals.INSTALLATION_TARGET) :0;

      let totalsRow = "<tr><td colspan='8' >Total</td>";
      for (let key in totals) {
        if(key != 'REMARKS'){
          if (key.startsWith('INC_PER') ) {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
   
      totalsRow += "</tr>";
      tab_text += totalsRow;
    tab_text += "</table>";
    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    document.body.removeChild(elt);
    // Add style to Total row color
    const allColumns = data.reduce((columns, item) => {
      Object.keys(item).forEach(key => {
        if (!columns.includes(key)) {

          columns.push(key);
        }
      });
      return columns;
    }, []);

    function getAlphabetEquivalent(number) {
      if (number < 1 || number > 26) { return "Invalid input"; }
      return String.fromCharCode(number + 64);
    }
    let excelArr = []
    for (let index = 0; index < allColumns.length; index++) {
      let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
      excelArr.push(ele);

    }

    Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
      excelArr
        .includes(cell)).forEach(cell => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });

    // generate file
    XLSX.writeFile(wb, `Singletarget_with_upper_scheme_Draft_${new Date().toJSON().slice(0, 10)}.xlsx`);  
  }
  const tableHeader = (d) => {

    return (
      <Row className="table_sub_buttons">
        {/* <Col>
          <Button
            disabled={disable}
            icon={<MergeIcon />}
            type="text"
            onClick={(e) => handleMerge(e, d)}
          >
            <span>Merge</span>
          </Button>
        </Col> */}
        <Col>
          {/* <Button
            disabled={enable}
            icon={<UnmergeIcon />}
            type="text"
            onClick={(e) => handleUnMergeModal()}
          >
            Unmerge
          </Button> */}
        </Col>
        <Col>
          <Button
            disabled={selectedRows?.length == 0}
            icon={<Download />}
            type="text"
            onClick={handleDownload}
          >
            Download
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div>
           <Table
          title={tableHeader}
          pagination = {false}
          showSorterTooltip={false}
          summary={(pageData) => {
            const fieldSum = {
              Target: 0,
              incPerTarget: 0,
              Payout: 0,
            }
            pageData.forEach(({
              Target,
              incPerTarget,
              Payout,
            }) => {
              fieldSum.Target += Target ? parseInt(Target) : 0;
              fieldSum.incPerTarget += incPerTarget ? parseInt(incPerTarget) : 0;
              fieldSum.Payout += Payout ? parseInt(Payout) : 0
            });
            return (
              <Table.Summary.Row style={{ fontWeight: '600', fontSize: '18px' }}>
                <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  {fieldSum.Target ? fieldSum.Target : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10}></Table.Summary.Cell>
                <Table.Summary.Cell index={11}>
                {fieldSum.Payout && fieldSum.Target ?
                    Math.round(fieldSum.Payout / fieldSum.Target) : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12}>
                  {fieldSum.Payout ? fieldSum.Payout : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
              </Table.Summary.Row>
            )
          }}
          // components={components}
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys, selectedRow) => {
              setSelectedRowKeys(newSelectedRowKeys);
              setSchemeIdNo(selectedRow)
              // getSelectedRowKeys(newSelectedRowKeys);
              sessionStorage.setItem('rowData', JSON.stringify(selectedRow))
              setSelectedRows(selectedRow.map(item => item.scheme.schemeId))
            },
          }}
          rowClassName={() => "editable-row"}
          columns={columns}
          dataSource={dataSource}
          scroll={{
            x: 1800,
            y: 500,
          }}
          bordered
          size="middle"
        />
        <Row
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Col>
            <Button
              type="default"
              shape="round"
              className='schemi-btn-all'
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",

              }}
              size="large"
              onClick={(e) => handleCancel()}
            // disabled={listData.every((item) => item.nos == "0")}
            >
              
              <span style={{color:'#fff'}}> Save & Submit</span>
            </Button>
          </Col>
        </Row>

        <Pagination
        // style={{ position: "absolute", right: "30px", marginTop:"-47px"}}
          defaultCurrent={1}
          className="paginationDesign"
          current={collectionData.pageNumber}
          onChange={(pageNumber, pageSize) => setCollectionData({ ...collectionData, pageSize, pageNumber })}
          total={listData?.data?.totalCountScheme}   
          showSizeChanger
          pageSize={collectionData.pageSize}
            pageSizeOptions={['150', '250', '350']}
        />
        <ConfirmModal
          open={openSubmitModal}
          handleCancel={handleCancel}
          handleOk={handleSaveScheme}
          confirmLoading={loading}
          modalText={'Are you sure want to submit?'}
        />
         <MergeModal
          open={openMergeModal}
          handleCancel={handleMerge}
          handleOk={handleOk}
          confirmLoading={loading}
          modalText={'Do you want to merge?'}
        />
        <MergeModal
          open={openUnMergeModal}
          handleCancel={handleUnMergeModal}
          handleOk={handleUnMerge}
          confirmLoading={loading}
          modalText={'Do you want to Unmerge?'}
        />
      </div>
      {contextHolder}
    </>
  );

};
export default SingleTargetWithUpperCapDraftScheme;

