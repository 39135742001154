import { render } from "@testing-library/react";

const useEditSchemeColumns = (listData, handleRequestSort) => {

  return [
    {
      title: (
        <div onClick={() => handleRequestSort('code')}>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "code",
      fixed: "left",
      width: "120px",
      sorter: true,
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      // sorter: true,
      fixed: "left",
      width: "180px",
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "120px",
      fixed: "left",
    },
    {
      title: "State",
      dataIndex: "state",
      // sorter: true,
      width: "120px",
    },
    {
      title: "Territory Manager",
      dataIndex: "Manager",
      // sorter: true,
      width: "100px",
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      width: "120px",
    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      width: "120px",
    },
    {
      title: "Industry",
      dataIndex: "industry",
      width: "120px",
      editable: true,
    },
    {
      title: "B",
      dataIndex: "oldBillingQty",
      width: "70px",
    },
    {
      title: "I",
      dataIndex: "oldInstallation",
      width: "70px",
    },
    {
      title: "C",
      dataIndex: "oldCollection",
      width: "70px",
    },
    {
      title: "Opening Stock",
      dataIndex: "stock",
      editable: false,
      children: [
        {
          title: "Nos",
          dataIndex: "oldOpeningStock",
          width: "100px",
        },
        {
          title: "Days",
          dataIndex: "openingStockDays",
       //   editable: true,
          width: "100px",
        },
      ],
    },

    {
      title: "Closing Os. (In Lacs)",
      dataIndex: "stock",
      editable: false,
      children: [
        {
          title: (
            <span>
              Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_3",
          width: "100px",
        },
        {
          title: (
            <span>
              Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_2",
          width: "100px",
        },
        {
          title: (
            <span>
              Last Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_1",
          width: "100px",
        },

        // {
        //   title: (
        //     <span>
        //       Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
        //     </span>
        //   ),
        //   dataIndex: "outStandingDays",
        //   width: "100px",
        //  // editable: true,
        // },
      ],
    },
    {
      title: "Closing OS Days",
      dataIndex: "outStandingDays",
      children: [
        {
          title: "OS Last Month",
          dataIndex: "outStandingDays",
          width: "100px",
        },
      ],
    },
    {
      title: "BG",
      dataIndex: "bg",
      children: [
        {
          title: "Lacs",
          dataIndex: "bgLacsCurrentYear",
          width: "100px",
        },
      ],
    },


    {
      title: 'BILLING DETAILS',
      dataIndex: "plan",
      editable: true,
      children: [
        {
          title: "B",
          dataIndex: "billing",
          editable: true,
          width: "100px",
        },
        {
          title: "I",
          dataIndex: "installation",
          editable: true,
          width: "100px",
        },
        {
          title: "C",
          dataIndex: "collection",
          editable: true,
          width: "100px",
        },
      ],
    },
    {
      title: "Installation plan",
      dataIndex: "instaPlan",
      editable: true,
      width: '300px',
      children: [
        {
          title: "Nos",
          dataIndex: "delayPlan",
          width: "200px",
          editable: true,
        },
      ],
    },
    {
      title: "Books Collection Targets (No. of Trs.)",
      dataIndex: "BooksCollectionTarget",
      editable: true,
      children: [
        {
          title: "T1",
          dataIndex: "BooksCollectionTargetT1",
          editable: true,
          width: "100px",


        },
        {
          title: "T2",
          dataIndex: "BooksCollectionTargetT2",
          editable: true,
          width: "100px",
        },
        {
          title: "T2-T1",
          dataIndex: "BooksCollectionTargetT2T1",
          width: "100px",
        },
        {
          title: "T3",
          dataIndex: "BooksCollectionTargetT3",
          editable: true,
          width: "100px",
        },
        {
          title: "T3-T2",
          dataIndex: "BooksCollectionTargetT3T2",
          width: "100px",
        },
        {
          title: "T4",
          dataIndex: "BooksCollectionTargetT4",
          editable: true,
          width: "100px",
        },
        {
          title: "T4-T3",
          dataIndex: "BooksCollectionTargetT4T3",
          width: "100px",
        },
        {
          title: "T5",
          dataIndex: "BooksCollectionTargetT5",
          editable: true,
          width: "100px",
        },
        {
          title: "T5-T4",
          dataIndex: "BooksCollectionTargetT5T4",
          width: "100px",
        },
        {
          title: "T6",
          dataIndex: "BooksCollectionTargetT6",
          editable: true,
          width: "100px",
        },
        {
          title: "T6-T5",
          dataIndex: "BooksCollectionTargetT6T5",
          width: "100px",
        },
      ],
    },
    {
      title: "Payout Rate",
      dataIndex: "pr",
      editable: true,

      children: [
        {
          title: "R1",
          dataIndex: "PayoutRatep1",
          editable: true,
          width: "120px",
        },
        {
          title: "R2",
          dataIndex: "PayoutRatep2",
          editable: true,
          width: "120px",
        },
        {
          title: "R3",
          dataIndex: "PayoutRatep3",
          editable: true,
          width: "120px",
        },
        {
          title: "R4",
          dataIndex: "PayoutRatep4",
          editable: true,
          width: "120px",
        },
        {
          title: "R5",
          dataIndex: "PayoutRatep5",
          editable: true,
          width: "120px",
        },
        {
          title: "R6",
          dataIndex: "PayoutRatep6",
          editable: true,
          width: "120px",
        },
      ],
    },
    {
      title: "Payout",
      dataIndex: "payout",
      editable: false,

      children: [
        {
          title: "P1",
          dataIndex: "payoutR1",
          width: "120px",
          editable: false,
        },
        {
          title: "P2",
          dataIndex: "payoutR2",
          width: "120px",
          editable: false,
        },
        {
          title: "P3",
          dataIndex: "payoutR3",
          width: "120px",
          editable: false,
        },
        {
          title: "P4",
          dataIndex: "payoutR4",
          width: "120px",
          editable: false,
        },
        {
          title: "P5",
          dataIndex: "payoutR5",
          width: "120px",
          editable: false,
        },
        {
          title: "P6",
          editable: false,
          dataIndex: "payoutR6",
          width: "120px",
        },
      ],
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      editable: true,
      width: "150px",
    },
    {
      title: "Total Expected Payout",
      dataIndex: "TEP",
      editable: true,
      children: [
        {
          title: "Expected Volume",
          dataIndex: "TEPvol",
          editable: true,
          width: "120px",
        },
        {
          title: "Per Tr.",
          dataIndex: "TEPpertr",
          width: "120px",
        },
        {
          title: "Amount",
          dataIndex: "TEPamount",
          width: "120px",
        },
      ],
    },

  ];
};

export default useEditSchemeColumns;