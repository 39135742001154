import React, {Table, Tag,Space,Row,Col,Button ,message} from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch ,useSelector} from "react-redux";
import {draftScheme} from "../../../redux/installation/AllDraftScheme";
import dayjs from "dayjs";
import ConfirmModal from "../../../components/modal/alertModal/ConfirmModal";
import { useNavigate } from "react-router-dom";
import axiosAPI from '../../../services/axiosApi';
import { getCurrentFiscalYear } from '../../../utils/dateFormatter';
import { decryptData } from '../../../utils/localStorageEncodeDecode';

//import type { ColumnsType } from 'antd/es/table'
const currentFiscalYear = getCurrentFiscalYear()
const InstallationDraftSchemeTable = ({ getSelectedRowKeys,getSelectionCheck}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const  [dataSource, setDataSource]= useState([]);
  const [loading, setLoading] = useState(false);
  const [schemeIdNo, setSchemeIdNo] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openSubmitModal, setOpenSubmitModal] = useState(false)
  const [keys, setKeys] = useState([]);
  const listData=  useSelector((state)=> state.draftScheme.data);
  const [messageApi, contextHolder] = message.useMessage();

  const handleSingleEdit = () => {
    const selectedRow = JSON.parse(sessionStorage.getItem('rowData'))
    const recordExist = selectedRow && selectedRow.find(item => item.key === selectedRow.key)

    if (recordExist) {
      navigate("/installation/draftSchemes/edit");
      // sessionStorage.removeItem('rowData');
    } else {
      
      messageApi.open({
        type: 'warning',
        content: 'Please select the checkbox !',
        duration: 2
      });

    }
  
  };

  const columns = [
    // {
    //   title: 'Scheme No.',
    //   dataIndex: 'scheme',
    //   width: "20px",
    //   fixed: 'left',
    //   render: (item) => {
    //     let color = " "
    //       switch (item.status) {
    //         case 0:
    //           color = "#FCE838";
    //           break;
    //         case 1:
    //           color = "#FCE838";
    //           break;
    //         case 2:
    //           color = "#51DE4E";
    //           break;
    //         case 3:
    //           color = "#FFAE00";
    //           break;
    //         case 4:
    //           color = "#FF3232";
    //           break;
    //         case 5:
    //           color = "#FF3232";
    //           break;
    //         default:
    //           color = "pink";
    //           break;
    //       }
    //     return(
    //       <Tag
    //       // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
    //       style={{color: 'black', fontSize: '13px'}}
    //         color={color}
    //       >
    //        {item.schemeNo}
    //       </Tag>
    //     )
    //   },
    //   // defaultSortOrder: "descend",
    //   // sorter: (a, b) => a.name.length - b.name.length,
    // },
    {
      title: "Dealer Code",
      dataIndex: "code",
      width: "180px",
      fixed: "left",
      sorter: true,
      key:"DealerCode",
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      width: '150px',
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"DealerName"
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "150px",
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"DealerLocation",
      // sorter: (a, b) => a.name.length - b.name.length,
      filters: [
        {
          text: "Amritsar",
          value: "Amritsar",
        },
        {
          text: "Zirakpur",
          value: "Zirakpur",
        },
      ],
      onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },
    {
      title: "State",
      dataIndex: "state",
      fixed: "left",
      width: '100px',
      sorter: true,
      defaultSortOrder: "ascend",
      key:"StateName",
    },
    {
      title: "Territory Manager",
      dataIndex: "Manager",
      // width: '30px',
      sorter: true,
      defaultSortOrder: "ascend",
      key:"TerritoryManager",
    },
    {
      title: "TOP SLAB",
      width: "150px",
      render: (x, y, index) => y?.topTarget?.toUpperCase(),
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      // width: '30px',
      // sorter: true,
    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      // width: '30px',
      // sorter: true,
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      // width: '50px',
      // sorter: true,

    },
    {
      title:"Targets",
      dataIndex:"Target",
      // width: '60px',
      children: [
        {
        title:"T1",
        dataIndex:"t1",
        // width: '30px',
      },
      {
        title:"T2",
        dataIndex:"t2",
        // width: '30px',
      },
      {
        title:"T3",
        dataIndex:"t3",
        // width: '30px',
      }
      ]
    },
    {
      title:"inc. Per Tractor",
      dataIndex:"IPT",
      // width: '60px',
      children: [
        {
        title:"R1",
        dataIndex:"r1",
        // width: '30px',
      },
      {
        title:"R2",
        dataIndex:"r2",
      },
      {
        title:"R3",
        dataIndex:"r3",
      }
      ]
    },
    {
      title:"Payout",
      dataIndex:"Payout",
      // width: '60px',
      children: [
        {
        title:"P1",
        dataIndex:"p1",
        // width: '30px',
      },
      {
        title:"P2",
        dataIndex:"p2",
      },
      {
        title:"P3",
        dataIndex:"p3",
      }
      ]
    },
    {
      title: "Installation Target",
      dataIndex: "Target",
      // width: "30px",
    },
    {
      title:"Upper Cap",
      dataIndex:"UC",
      // width: '30px'

    },
    {
      title:"Remarks",
      dataIndex:"reason",
      editable: true,
      // width: '50px'

    },
    {
      title: "Action",
      key: "action",
      fixed: "right",

      render: (_, record) => (
        <Space size="small">
          <a onClick={handleSingleEdit}>Edit</a>
        </Space>
      ),
    },
  ];
  useEffect(()=>{
    const userData = decryptData(sessionStorage.getItem('userInfo'));
    const userId = userData.userId;
    const userRole = userData.userRole;
    const demoParams = {
      fyear: currentFiscalYear,
       schemeType: 7,
       userId: userId,
       userRole,
    }
    const fetchGeneratedScheme = async() => {
      let result = await axiosAPI.post(`SchemeGenerate/GetDraftedScheme`,demoParams)
      dispatch(draftScheme(result.data.data))
    };
    fetchGeneratedScheme(demoParams)
  },[])

  useEffect(() => {
    const data = [];
    listData.map((item, index) => {
      for (let i = 0; i < 1; i++){
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        data.push({
          status: item.status,
          key: item.schemeId,
          scheme: item,
          code: item.dealerCode ,
          name: item.dealerName,
          Location: item.dealerLocation,
          state: item.stateName,
          Manager: item.territoryManager,
          topTarget: item.topTarget,
          StartMonth: formattedStartDate,
          EndMonth: formattedEndDate,
          Target:item?.installationTarget,
          UC: item?.upperCap,
          IPT:item.incPerTarget,
          Payout: item.p1,
          // Payout: item.p1,
          // industry: 102,
          // b: 52,
          // i: 23,
          // c: 21,
          // target: '',
          // nos: 50,
          // days: 0,
          // nov21: null,
          // dec21: null,
          // lac: 66,
          t1: item?.t1,
          t2: item?.t2,
          // BooksCollectionTargetT2T1: item.t2_T1,
          // t3: item.t3,
          // BooksCollectionTargetT3T2: item.t3_T2,
          r1: item?.r1,
          r2: item?.r2,
          // PayoutRatep3: item.r3,
          p1: item?.p1,
          p2: item?.p2,
          // payoutR3: item.p3,
          reason: item.reason,
          // TEPvol: item.volume,
          // TEPpertr: item.perTr,
          // TEPamount: item.amount,
          // LMSA: 'T2',
          // LCQ: 55,
          // LMSA1: 'T1',
          // LCQ1: 18000

        })
      }
      setDataSource(data)
    })
  },[listData])
  useEffect(() => {
    // const value = schemeIdNo.map(item => item.scheme.isMerge)
    const schemeId = schemeIdNo.map(id => id.scheme.schemeId)
    setKeys(schemeId)
    // const status = schemeIdNo.some(item => item.scheme.status === 3 )
    // setDisable(status ? status : value.includes(true) && !value.includes(false) ? true : value.includes(true))
    // setEnable(status ? status : !value.includes(true)  && value.includes(false)? true : value.includes(false))
  },[schemeIdNo])

  const fetchGenerateScheme = async (demoParams) => {
    let result = await axiosAPI.post(`SchemeGenerate/GetDraftedScheme`,demoParams);
    dispatch(draftScheme(result.data.data));
  };

  const handleSaveScheme = async () => {
    // e.preventDefault()

    const payload = listData
    .filter((data) => keys.includes(data.schemeId))
    .map((obj) => {
      const { status, ...rest } = obj;
      return rest;
    });

  await axiosAPI
    .post(
      `UpdateScheme/DraftToSchemeGenerate`,
      payload
    )
    .then((result) => {
      // setOpenSuccessModal(!openSuccessModal);
      const userData = decryptData(sessionStorage.getItem("userInfo"));
      const userId = userData.userId;
      const demoParams = {
        fyear: currentFiscalYear,
        schemeType: 6,
        userid: userId,
      };
      fetchGenerateScheme(demoParams);
    });
    setLoading(false)
    // setOpenUnMergeModal(false)
    navigate('/installation/allSchemes')
  }
  const handleCancel= () => {
    setOpenSubmitModal(!openSubmitModal)
  }
  return (
    <div>
      
       <Table size="medium"
        columns={columns}
        dataSource={dataSource}
        scroll={{
        x: 2500,
        y: 500,
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys,selectedRow) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSchemeIdNo(selectedRow)
            getSelectionCheck(newSelectedRowKeys, dataSource);
            // getSelectedRowKeys(newSelectedRowKeys);
            sessionStorage.setItem('rowData', JSON.stringify(selectedRow))
          },
        }}
      bordered/>
      <Row
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
        <Col>
            <Button
              type="default"
              shape="round"
              className='schemi-btn-all'
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",

              }}
              size="large"
              onClick={(e) => handleCancel()}
              // disabled={listData.every((item) => item.nos == "0")}
            >
              Save & Submit
            </Button>
          </Col>
        </Row>
        <ConfirmModal
        open={openSubmitModal}
        handleCancel={handleCancel}
        handleOk = {handleSaveScheme}
        confirmLoading={loading}
        modalText={'Are you sure want to submit?'}
      />
    </div>
  );

};
export default InstallationDraftSchemeTable ;

