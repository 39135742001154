import { Table, Row, Col, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { STATE_WISE_DASHBOARD_LABEL } from "../../../config";
import useColumns from "../../../components/table/columns";
import axiosAPI from "../../../services/axiosApi";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import { schemeListApproveApi } from "../../../redux/schemaGenerate/schemaGenerateSlice";
import { useLocation } from "react-router-dom";
import tableData from "./modificationCollectionTableData";
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const currentFiscalYear = getCurrentFiscalYear();

const ModificationCollectionTable = ({
  setToggleEdit,
  dashboardType,
  dataSource,
  loading,
  setSchemeIdNo,
  schemeIdNo,
  selectedLabel,
  setSelectedRowKeys,
  selectedRowKeys,
  handleChange
}) => {
  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );

  const columns = tableData(
    dashboardType,
    setToggleEdit,
    userRole,
    schemeIdNo,
    "dashboard",
    selectedLabel
  );

  const { loadingScheme, schemeApprove } = useSelector(
    (state) => state.schemagenerate
  );

  return (<>
    <Table
      size="small"
      loading={loading ? true : false}
      summary={(pageData) => {
        const fieldSum = {
          industry: 0,
          oldBillingQty: 0,
          oldInstallation: 0,
          oldCollection: 0,
          oldOpeningStock: 0,
          openingStockDays: 0,
          closingOutStandingMonth_3: 0,
          closingOutStandingMonth_2: 0,
          closingOutStandingMonth_1: 0,
          outStandingDays: 0,
          bg: 0,
          bGLacsCurrentYear: 0,
          delayPlan: 0,
          billing: 0,
          installation: 0,
          collection: 0,
          BooksCollectionTarget: 0,
          t1: 0,
          t2: 0,
          t2_T1: 0,
          t3: 0,
          t3_T2: 0,
          t4: 0,
          t4_T3: 0,
          t5: 0,
          t5_T4: 0,
          t6: 0,
          t6_T5: 0,
          r1: 0,
          r2: 0,
          r3: 0,
          r4: 0,
          r5: 0,
          r6: 0,
          payout: 0,
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          volume: 0,
          perTr: 0,
          amount: 0,
        };

        pageData.forEach(
          ({
            industry,
            oldBillingQty,
            oldInstallation,
            oldCollection,
            oldOpeningStock,
            openingStockDays,
            closingOutStandingMonth_3,
            closingOutStandingMonth_2,
            closingOutStandingMonth_1,
            outStandingDays,
            bg,
            bGLacsCurrentYear,
            delayPlan,
            billing,
            installation,
            collection,
            BooksCollectionTarget,
            t1,
            t2,
            t2_T1,
            t3,
            t3_T2,
            t4,
            t4_T3,
            t5,
            t5_T4,
            t6,
            t6_T5,
            r1,
            r2,
            r3,
            r4,
            r5,
            r6,
            payout,
            p1,
            p2,
            p3,
            p4,
            p5,
            p6,
            volume,
            perTr,
            amount,
            topTarget
          }) => {
            fieldSum.industry += industry ? parseInt(industry) : 0;
            fieldSum.oldBillingQty += oldBillingQty
              ? parseInt(oldBillingQty)
              : 0;
            fieldSum.oldInstallation += oldInstallation
              ? parseInt(oldInstallation)
              : 0;
            fieldSum.oldCollection += oldCollection
              ? parseInt(oldCollection)
              : 0;
            fieldSum.oldOpeningStock += oldOpeningStock
              ? parseInt(oldOpeningStock)
              : 0;
            fieldSum.openingStockDays += openingStockDays
              ? parseInt(openingStockDays)
              : 0;
            fieldSum.closingOutStandingMonth_3 += closingOutStandingMonth_3
              ? parseInt(closingOutStandingMonth_3)
              : 0;
            fieldSum.closingOutStandingMonth_2 += closingOutStandingMonth_2
              ? parseInt(closingOutStandingMonth_2)
              : 0;
            fieldSum.closingOutStandingMonth_1 += closingOutStandingMonth_1
              ? parseInt(closingOutStandingMonth_1)
              : 0;
            fieldSum.outStandingDays += outStandingDays
              ? parseInt(outStandingDays)
              : 0;
            fieldSum.bg += bg ? parseInt(bg) : 0;
            fieldSum.bGLacsCurrentYear += bGLacsCurrentYear
              ? parseInt(bGLacsCurrentYear)
              : 0;
            fieldSum.delayPlan += delayPlan ? parseInt(delayPlan) : 0;
            fieldSum.billing += billing ? parseInt(billing) : 0;
            fieldSum.installation += installation ? parseInt(installation) : 0;
            fieldSum.collection += collection ? parseInt(collection) : 0;
            fieldSum.BooksCollectionTarget += BooksCollectionTarget
              ? parseInt(BooksCollectionTarget)
              : 0;
            fieldSum.t1 += (t1 && topTarget?.includes("t1")) ? parseInt(t1) : 0;
            fieldSum.t2 += (t2 && topTarget?.includes("t2")) ? parseInt(t2) : 0;
            fieldSum.t2_T1 += (t2_T1 && topTarget?.includes("t2")) ? parseInt(t2_T1) : 0;
            fieldSum.t3 += ( t3 && topTarget?.includes("t3")) ? parseInt(t3) : 0;
            fieldSum.t3_T2 += (t3_T2 && topTarget?.includes("t3")) ? parseInt(t3_T2) : 0;
            fieldSum.t4 += (t4 && topTarget?.includes("t4")) ? parseInt(t4) : 0;
            fieldSum.t4_T3 += (t4_T3 && topTarget?.includes("t4")) ? parseInt(t4_T3) : 0;
            fieldSum.t5 += (t5 && topTarget?.includes("t5")) ? parseInt(t5) : 0;
            fieldSum.t5_T4 += (t5_T4 && topTarget?.includes("t5")) ? parseInt(t5_T4) : 0;
            fieldSum.t6 += (t6 && topTarget?.includes("t6")) ? parseInt(t6) : 0;
            fieldSum.t6_T5 += (t6_T5 && topTarget?.includes("t6")) ? parseInt(t6_T5) : 0;
            fieldSum.r1 += r1 ? parseInt(r1) : 0;
            fieldSum.r2 += r2 ? parseInt(r2) : 0;
            fieldSum.r3 += r3 ? parseInt(r3) : 0;
            fieldSum.r4 += r4 ? parseInt(r4) : 0;
            fieldSum.r5 += r5 ? parseInt(r5) : 0;
            fieldSum.r6 += r6 ? parseInt(r6) : 0;
            fieldSum.payout += payout ? parseInt(payout) : 0;
            fieldSum.p1 += (p1 && topTarget?.includes("t1")) ? parseInt(p1) : 0;
            fieldSum.p2 += (p2 && topTarget?.includes("t2")) ? parseInt(p2) : 0;
            fieldSum.p3 += (p3 && topTarget?.includes("t3")) ? parseInt(p3) : 0;
            fieldSum.p4 += (p4 && topTarget?.includes("t4")) ? parseInt(p4) : 0;
            fieldSum.p5 += (p5 && topTarget?.includes("t5")) ? parseInt(p5) : 0;
            fieldSum.p6 += (p6 && topTarget?.includes("t6")) ? parseInt(p6) : 0;
            fieldSum.volume += volume ? parseInt(volume) : 0;
            fieldSum.perTr += perTr ? parseInt(perTr) : 0;
            fieldSum.amount += amount ? parseInt(amount) : 0;
          }
        );

        const fieldSum1 = {
          industry: 0,
          oldBillingQty: 0,
          oldInstallation: 0,
          oldCollection: 0,
          oldOpeningStock: 0,
          openingStockDays: 0,
          closingOutStandingMonth_3: 0,
          closingOutStandingMonth_2: 0,
          closingOutStandingMonth_1: 0,
          outStandingDays: 0,
          bg: 0,
          bGLacsCurrentYear: 0,
          delayPlan: 0,
          billing: 0,
          installation: 0,
          collection: 0,
          BooksCollectionTarget: 0,
          t1: 0,
          t2: 0,
          t2_T1: 0,
          t3: 0,
          t3_T2: 0,
          t4: 0,
          t4_T3: 0,
          t5: 0,
          t5_T4: 0,
          t6: 0,
          t6_T5: 0,
          r1: 0,
          r2: 0,
          r3: 0,
          r4: 0,
          r5: 0,
          r6: 0,
          payout: 0,
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          volume: 0,
          perTr: 0,
          amount: 0,
        };

        dataSource?.forEach(
          ({
            industry,
            oldBillingQty,
            oldInstallation,
            oldCollection,
            oldOpeningStock,
            openingStockDays,
            closingOutStandingMonth_3,
            closingOutStandingMonth_2,
            closingOutStandingMonth_1,
            outStandingDays,
            bg,
            bGLacsCurrentYear,
            delayPlan,
            billing,
            installation,
            collection,
            BooksCollectionTarget,
            t1,
            t2,
            t2_T1,
            t3,
            t3_T2,
            t4,
            t4_T3,
            t5,
            t5_T4,
            t6,
            t6_T5,
            r1,
            r2,
            r3,
            r4,
            r5,
            r6,
            payout,
            p1,
            p2,
            p3,
            p4,
            p5,
            p6,
            volume,
            perTr,
            amount,
          }) => {
            fieldSum1.industry += industry ? parseInt(industry) : 0;
            fieldSum1.oldBillingQty += oldBillingQty
              ? parseInt(oldBillingQty)
              : 0;
            fieldSum1.oldInstallation += oldInstallation
              ? parseInt(oldInstallation)
              : 0;
            fieldSum1.oldCollection += oldCollection
              ? parseInt(oldCollection)
              : 0;
            fieldSum1.oldOpeningStock += oldOpeningStock
              ? parseInt(oldOpeningStock)
              : 0;
            fieldSum1.openingStockDays += openingStockDays
              ? parseInt(openingStockDays)
              : 0;
            fieldSum1.closingOutStandingMonth_3 += closingOutStandingMonth_3
              ? parseInt(closingOutStandingMonth_3)
              : 0;
            fieldSum1.closingOutStandingMonth_2 += closingOutStandingMonth_2
              ? parseInt(closingOutStandingMonth_2)
              : 0;
            fieldSum1.closingOutStandingMonth_1 += closingOutStandingMonth_1
              ? parseInt(closingOutStandingMonth_1)
              : 0;
            fieldSum1.outStandingDays += outStandingDays
              ? parseInt(outStandingDays)
              : 0;
            fieldSum1.bg += bg ? parseInt(bg) : 0;
            fieldSum1.bGLacsCurrentYear += bGLacsCurrentYear
              ? parseInt(bGLacsCurrentYear)
              : 0;
            fieldSum1.delayPlan += delayPlan ? parseInt(delayPlan) : 0;
            fieldSum1.billing += billing ? parseInt(billing) : 0;
            fieldSum1.installation += installation ? parseInt(installation) : 0;
            fieldSum1.collection += collection ? parseInt(collection) : 0;
            fieldSum1.BooksCollectionTarget += BooksCollectionTarget
              ? parseInt(BooksCollectionTarget)
              : 0;
            fieldSum1.t1 += t1 ? parseInt(t1) : 0;
            fieldSum1.t2 += t2 ? parseInt(t2) : 0;
            fieldSum1.t2_T1 += t2_T1 ? parseInt(t2_T1) : 0;
            fieldSum1.t3 += t3 ? parseInt(t3) : 0;
            fieldSum1.t3_T2 += t3_T2 ? parseInt(t3_T2) : 0;
            fieldSum1.t4 += t4 ? parseInt(t4) : 0;
            fieldSum1.t4_T3 += t4_T3 ? parseInt(t4_T3) : 0;
            fieldSum1.t5 += t5 ? parseInt(t5) : 0;
            fieldSum1.t5_T4 += t5_T4 ? parseInt(t5_T4) : 0;
            fieldSum1.t6 += t6 ? parseInt(t6) : 0;
            fieldSum1.t6_T5 += t6_T5 ? parseInt(t6_T5) : 0;
            fieldSum1.r1 += r1 ? parseInt(r1) : 0;
            fieldSum1.r2 += r2 ? parseInt(r2) : 0;
            fieldSum1.r3 += r3 ? parseInt(r3) : 0;
            fieldSum1.r4 += r4 ? parseInt(r4) : 0;
            fieldSum1.r5 += r5 ? parseInt(r5) : 0;
            fieldSum1.r6 += r6 ? parseInt(r6) : 0;
            fieldSum1.payout += payout ? parseInt(payout) : 0;
            fieldSum1.p1 += p1 ? parseInt(p1) : 0;
            fieldSum1.p2 += p2 ? parseInt(p2) : 0;
            fieldSum1.p3 += p3 ? parseInt(p3) : 0;
            fieldSum1.p4 += p4 ? parseInt(p4) : 0;
            fieldSum1.p5 += p5 ? parseInt(p5) : 0;
            fieldSum1.p6 += p6 ? parseInt(p6) : 0;
            fieldSum1.volume += volume ? parseInt(volume) : 0;
            fieldSum1.perTr += perTr ? parseInt(perTr) : 0;
            fieldSum1.amount += amount ? parseInt(amount) : 0;
          }
        );

        return (

          <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
            <Table.Summary.Cell index={0} fixed></Table.Summary.Cell>
            <Table.Summary.Cell fixed index={1}>
              Total
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} fixed></Table.Summary.Cell>
            <Table.Summary.Cell index={3} fixed></Table.Summary.Cell>
            <Table.Summary.Cell index={4}></Table.Summary.Cell>
            <Table.Summary.Cell index={5}></Table.Summary.Cell>
            <Table.Summary.Cell index={6}></Table.Summary.Cell>
            <Table.Summary.Cell index={7}></Table.Summary.Cell>
            <Table.Summary.Cell index={8}></Table.Summary.Cell>
            <Table.Summary.Cell index={9}></Table.Summary.Cell>
            {userRole != "SADM" && <Table.Summary.Cell index={99}></Table.Summary.Cell>}
            <Table.Summary.Cell index={10}>
              {fieldSum?.industry || "" || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={11}>
              {fieldSum?.oldBillingQty || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={12}>
              {fieldSum?.oldInstallation || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={13}>
              {fieldSum?.oldCollection || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={14}>
              {fieldSum?.oldOpeningStock || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={15}>
              {fieldSum?.openingStockDays || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={16}>
              {fieldSum?.closingOutStandingMonth_3 || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={17}>
              {fieldSum?.closingOutStandingMonth_2 || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={18}>
              {fieldSum?.closingOutStandingMonth_1 || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={19}>
              {fieldSum?.outStandingDays || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={20}>
              {fieldSum?.bGLacsCurrentYear || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={21}>
              {fieldSum?.delayPlan || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={22}>
              {fieldSum?.billing || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={23}>
              {fieldSum?.installation || ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={24}>
              {fieldSum?.collection ? fieldSum?.collection : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={25}></Table.Summary.Cell>
            <Table.Summary.Cell index={26}></Table.Summary.Cell>
            <Table.Summary.Cell index={27}>
              {fieldSum.t1 ? fieldSum.t1 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={28}>
              {fieldSum.t2 ? fieldSum.t2 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={29}>
              {fieldSum.t2_T1 ? fieldSum.t2_T1 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={30}>
              {fieldSum.t3 ? fieldSum.t3 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={31}>
              {fieldSum.t3_T2 ? fieldSum.t3_T2 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={32}>
              {fieldSum.t4 ? fieldSum.t4 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={33}>
              {fieldSum.t4_T3 ? fieldSum.t4_T3 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={34}>
              {fieldSum.t5 ? fieldSum.t5 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={35}>
              {fieldSum.t5_T4 ? fieldSum.t5_T4 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={36}>
              {fieldSum.t6 ? fieldSum.t6 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={37}>
              {fieldSum.t6_T5 ? fieldSum.t6_T5 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={38}>
              {/* {fieldSum.r1 ? fieldSum.r1 : ""} */}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={39}>
              {/* {fieldSum.r2 ? fieldSum.r2 : ""} */}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={40}>
              {/* {fieldSum.r3 ? fieldSum.r3 : ""} */}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={41}>
              {/* {fieldSum.r3 ? fieldSum.r3 : ""} */}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={42}>
              {/* {fieldSum.r3 ? fieldSum.r3 : ""} */}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={43}>
              {/* {fieldSum.r3 ? fieldSum.r3 : ""} */}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={44}>
              {fieldSum.p1 ? fieldSum.p1 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={45}>
              {fieldSum.p2 ? fieldSum.p2 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={46}>
              {fieldSum.p3 ? fieldSum.p3 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={47}>
              {fieldSum.p4 ? fieldSum.p4 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={48}>
              {fieldSum.p5 ? fieldSum.p5 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={49}>
              {fieldSum.p6 ? fieldSum.p6 : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={50}>
              {fieldSum.volume ? fieldSum.volume : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={51}>
              {isNaN(Math.round(fieldSum.amount / fieldSum.volume))
                ? ""
                : Math.round(fieldSum.amount / fieldSum.volume)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={52}>
              {fieldSum.amount ? fieldSum.amount : ""}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={53}>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={54}>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={55}>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={56}>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={57}>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={58}>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={59}>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={60}>
            </Table.Summary.Cell>
          </Table.Summary.Row>

        );
      }}
      columns={columns}
      pagination={false}
      onChange={handleChange}
      dataSource={dataSource?.map((item) => ({ ...item, key: item.schemeId }))}
      rowSelection={{
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRow) => {
          setSelectedRowKeys(newSelectedRowKeys);
          setSchemeIdNo(selectedRow);
        },
      }}
      scroll={{
        x: 7000,
        y: 100,
      }}
    />
    </>
  );
};
export default ModificationCollectionTable;
