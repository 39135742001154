import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from 'antd';
import axiosAPI from "../../services/axiosApi";


export const fetchgenerateScheme = createAsyncThunk("fetchSchemeList", async (payload) => {
    
    let result = await axiosAPI.post(`SchemeGenerate/SchemeGenerate`, payload)
    if(result.data.response === 1){
    
        setTimeout(
            message.success({
                
                content: 'Scheme successfully generated',
                className: 'custom-message', 
              })
            ,9000)
    } else if(result.data.response === 2){

            message.success({
                content: 'Scheme successfully generated',
                className: 'custom-message', 
              })
            .then(()=>  window.location.replace('/installation/draftSchemes'))

    } else if(result.data.response === 46){
        
        message.error(result.data.errorMessage || 'Locked for current area')
    }
});
const generateScheme = createSlice({
    name: "generateScheme",
    initialState: {
        data: [],
    },
    reducers: {
        add: (state, action) => {
            state.data = action.payload;
        }
    }
    
});
export const { add } = generateScheme.actions;
export default generateScheme.reducer;