import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Button, Space, Dropdown, message } from "antd";
// import { Link } from "react-router-dom";
// import { ReactComponent as Communicate } from "../../assets/vector/communicate.svg";
// import { ReactComponent as Download } from "../../assets/vector/download.svg";
// import { ReactComponent as Deviation } from "../../assets/vector/deviation.svg";
// import RoleModal from "../modal/roleModal/index";
// import { DeviationModal } from "../modal/deviationModal";
import "../statusBox/status.css";
import { getCurrentFiscalYear } from "../../utils/dateFormatter";
import { decryptData } from "../../utils/localStorageEncodeDecode";

const currentFiscalYear = getCurrentFiscalYear();


const InstallationFilter = ({
    onGetData
}) => {
    const location = useLocation();
    const [items, setItems] = useState([]);
    const [selectedSchemeType, setSelectedSchemeType] = useState(3);

    const [openModal, setOpenModal] = useState(false);
    const [openDeviationModal, setDeviationModal] = useState(false);

    const [test, setTest] = useState(false);
    // const [closeModal,setCloseModal] =useState(true);
    const {userRole,userId} = decryptData(sessionStorage.getItem("userInfo"));


    const handleSchemeDropdown = (schemeType) => {
        

        
        setSelectedSchemeType(schemeType);
        const demoParams = {
        fyear: currentFiscalYear,
        schemeType: schemeType,
        userid: userId,
        userRole: userRole,
        };
        onGetData(demoParams);
    };


    const schemeTypeData = [
        {
            key: "1",
            label: <a onClick={() => handleSchemeDropdown(3)}>Installation - Slab Based Scheme</a>,
        },
        {
            key: "2",
            label: <a onClick={() => handleSchemeDropdown(8)}>Installation - Single Target Scheme</a>,
        },
        {
            key: "3",
            label: <a onClick={() => handleSchemeDropdown(4)}>Installation - Single Target With Upper Cap Scheme</a>
        },
        {
            key: "4",
            label: <a onClick={() => handleSchemeDropdown(5)}>Installation - Unconditional Scheme</a>
        },
    ];

    const getSchemeType = (schemeType) => {
        if (schemeType == 3) {
        return "Installation - Slab Based Scheme";
        }
        if (schemeType == 8) {
        return "Installation - Single Target Scheme";
        }
        if (schemeType == 4) {
        return "Installation - Single Target With Upper Cap Scheme";
        }
        if (schemeType == 5) {
        return "Installation - Unconditional Scheme";
        }
    };

    return (
        <div 
        className="demo"
        >
        <Dropdown
            className="action_button"
            menu={{ 
                items: schemeTypeData,
                defaultSelectedKeys:['1'],
                
            }}
            trigger={["click"]}
        >
        <Button>
            <Space
             className="button_Type"
            >
                {getSchemeType(selectedSchemeType)}
                <DownOutlined />
            </Space>
        </Button>
        </Dropdown>
        </div>
    );
};

export default InstallationFilter;


