import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Divider,
  Col,
  Row,
  Form,
  Input,
  Space,
  Dropdown,
  Menu,
  Tooltip
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import SlabBasedScheme from "./SlabBasedSchemeTable";
import SingleTarget from "./SingleTargetTable";
import SingleTargetWithUpperCap from "./SingleTargetWithUpperCapTable";
import UnconditionalInstallation from "./UnconditionalInstallationScheme";
import ThemeButton from "../../../components/button/buttons";
import dayjs from "dayjs";
import { schemeList } from "../../../redux/collection/SchemeList";
import axiosAPI from "../../../services/axiosApi";
import { useNavigate } from "react-router-dom";
import XLSX from "xlsx-js-style";
import {
  getExpiredStatusAMGR,
  getExpiredStatusSADM,
  getExpiredStatusZADM,
} from "../../../components/header/schemeExpiredDetails";
import ExpiredWindow from "../../../components/expiredWindow/ExpiredWindowShow";
import { Formik, useFormik } from "formik";
import moment from "moment";
import ToastifyShow from "../../../components/ToastifyShow";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import { installationSchemeDownload } from "../../../components/download/installationSchemeDownload";
import { dealerTrackCounterApi, getLockedSchemeTypeAsync } from "../../../redux/stateData/stateDataSlice";
import SchemeWindowShow from "../../../components/expiredWindow/schemeWindowShow";
import FullScreenLoader from "../../../components/loader/FullScreenLoader";
import { ENV_TIME } from "../../../config";
import { parseFiveDigitNumber } from "../../../components/download/collectionSchemeUpload";

const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: "",
  dealerCode: [],
  startDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
  endDate:  dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
};

const InstallationCreateNewScheme = () => {
  const [selectedTable, setSelectedTable] = useState({ value: "", key: 0 });
  const navigate = useNavigate();
  const { userId, userRole } = decryptData(sessionStorage.getItem("userInfo"));
  const [listData, setListData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const { schemeType, startDate, endDate, dealerCode } = collectionData;
  const dispatch = useDispatch();

  const { lockedStates,loadingDealer } = useSelector((state) => state?.stateData);
  const [schemeData,setSchemeData]=useState([])
  useEffect(()=>{
    dispatch(dealerTrackCounterApi()).then(async(data)=>{
        // Parse the XML data
        const parser = await new DOMParser();
        const xmlDoc = await parser.parseFromString(data?.payload?.data, "application/xml");

        // Convert the XML data to an array format
        const rows =  await xmlDoc.getElementsByTagName('Row');
        const items = await Array.from(rows).map(row => {
          return {
            DealerCode: row.getElementsByTagName('DealerCode')[0]?.textContent,
            StateName: row.getElementsByTagName('StateName')[0]?.textContent,
            TMName: row.getElementsByTagName('TMName')[0]?.textContent,
            BillingCount: row.getElementsByTagName('BillingCount')[0]?.textContent,
            DeliveryCount: row.getElementsByTagName('DeliveryCount')[0]?.textContent,
            TractorInventory: row.getElementsByTagName('TractorInventory')[0]?.textContent,
            ClosuerDate: row.getElementsByTagName('ClosuerDate')[0]?.textContent,
          };
        });
        setSchemeData(items)
        // console.log(items,"itemsitems")
    })
  },[])
  const formik = useFormik({
    initialValues: [],
  });
  const schemeLockedStatus = lockedStates?.[0]
    ? {
      t3I:lockedStates?.[0]?.t3I ? true : false,
    }
    : { t3I: false };
  // const listData = useSelector((state) => state.schemeList.data);
  const handleSchemeTableChange = (value, key) => {
    setSelectedTable({ value, key });
  };

  const changeStartDate = (date) => {
    setCollectionData.startDate(date.format());
  };
  const changeEndDate = (date) => {
    setCollectionData.startDate(date.format());
  };

  const isAnyEmpty = (collectionData) =>
    Object.values(collectionData).every((value) => {
      if (value == "" || value == []) {
        return false;
      }
      return true;
    });

  const getDealerDropdownData = (x) => {
    let formattedStartDate = dayjs(collectionData?.startDate).month() + 1;
    let formattedEndDate = dayjs(collectionData?.endDate).month() + 1;
    const today = new Date();
    if(x){
    dispatch(getLockedSchemeTypeAsync(x)).then((x)=>{

    })
    }
    let data = {
      schemeType: schemeType || 3,
      year: new Date(collectionData?.startDate).getFullYear(),
      userId: x || userId,
      Month: formattedStartDate,
      MonthEnd: formattedEndDate,
    };

    axiosAPI.post(`SchemeGenerate/GetSchemeList`, data).then((result) => {
      setListData(
        result.data.data.map((item) => ({ ...item, code: item.dealerCode })) ||
          []
      );
    });
  };

  // useEffect(() => {
  //   if (schemeType) {
  //     handleRemoveFile()
  //   }
  // }, [schemeType])
  useEffect(() => {
    if (
      collectionData?.startDate &&
      collectionData?.endDate &&
      schemeType &&
      userRole == "AMGR"
    ) {
      getDealerDropdownData(userId);
    }
  }, [collectionData]);
  const renderSchemeTable = () => {
    switch (schemeType) {
      case 3:
        return (
          <SlabBasedScheme
            startDate={startDate}
            endDate={endDate}
            formik={formik}
            dealerCode={dealerCode}
            schemeType={schemeType}
            allListData={listData}
          />
        );
      case 8:
        return (
          <SingleTarget
            startDate={startDate}
            endDate={endDate}
            dealerCode={dealerCode}
            schemeType={schemeType}
            allListData={listData}
          />
        );
      case 4:
        return (
          <SingleTargetWithUpperCap
            startDate={startDate}
            endDate={endDate}
            dealerCode={dealerCode}
            schemeType={schemeType}
            allListData={listData}
          />
        );
      case 5:
        return (
          <UnconditionalInstallation
            startDate={startDate}
            endDate={endDate}
            dealerCode={dealerCode}
            schemeType={schemeType}
            allListData={listData}
          />
        );
      default:
        return null;
    }
  };

  const handleDealerCode = (dlrCode, currentSelected) => {
    if (dealerCode.includes("allDealer") && !dlrCode.includes("allDealer")) {
      setCollectionData({ ...collectionData, dealerCode: [] });
      return;
    }
    if (dealerCode.includes("allDealer") && dlrCode.includes("allDealer")) {
      setCollectionData({
        ...collectionData,
        dealerCode: dlrCode.filter((item) => item != "allDealer"),
      });
      return;
    }
    if (dlrCode.includes("allDealer")) {
      setCollectionData({
        ...collectionData,
        dealerCode: [...[...listData].map((item) => item.code), "allDealer"],
      });
      return;
    }
    setCollectionData({ ...collectionData, dealerCode: dlrCode });
  };
  const handleRemoveFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };
  const handleSampleFile = () => {
    const headerStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
        diagonal: { diagonalUp: true, diagonalDown: true },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    //schemeLockedStatus

    let tab_text = installationSchemeDownload({schemeType:schemeType,lockedScheme:schemeLockedStatus?.t3I})   
   {
    schemeType==3?formik?.values?.length != 0 &&
    formik?.values.forEach((header) => {
        tab_text += `<tr ><td>${header?.code}</td>
<td>${header?.name}</td>
<td>${header?.state}</td>
<td>${header?.Location}</td>
<td>${header?.Manager}</td>
<td>${header?.dropdownscheme?.includes("t3")?"t3":header?.dropdownscheme?.includes("t2")?"t2":header?.dropdownscheme?.includes("t1")?"t1":""}</td>
<td>${moment(startDate).format("MMM YY") || ""}</td>
<td>${moment(endDate).format("MMM YY") || ""}</td>
<td>${header?.actual_Installation_Last_Year_Month || 0}</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>      
</tr>`;
      }):  listData?.length != 0 &&
        listData
          ?.filter((x) => collectionData?.dealerCode?.includes(x?.dealerCode))
          ?.forEach((header) => {
            tab_text += `<tr ><td>${header?.dealerCode}</td>
    <td>${header?.dealerName}</td>
    <td>${header?.stateName}</td>
    <td>${header?.cityName}</td>
    <td>${header?.territoryName}</td>
    <td>${moment(startDate).format("MMM YY") || ""}</td>
    <td>${moment(endDate).format("MMM YY") || ""}</td>
    <td>${header?.actual_Installation_Last_Year_Month || 0}</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>      
    </tr>`;
          });
    }
    tab_text += "</table>";

    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);

    // Apply styles to the table using a class
    elt.getElementsByClassName("custom-table")[0].style.width = "100%";

    // generate workbook with XLSXStyle
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });

    Object?.keys(wb?.Sheets?.Sheet1 || {})
      ?.filter(
        (x) =>
          x != "!rows" &&
          x != "!cols" &&
          x != "!merges" &&
          x != "!ref" &&
          x != "!fullref"
      )
      ?.forEach((x) => {
        wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle;
      });

    // Dynamically set column widths
    wb.Sheets[wb.SheetNames[0]]["!cols"] = [
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    // Dynamically set row heights
    wb.Sheets[wb.SheetNames[0]]["!rows"] = [
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },

      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      // Add more row heights as needed
    ];

    // remove element
    document.body.removeChild(elt);

    // generate file
    XLSX.writeFile(
      wb,
      (schemeType == "3" || schemeType == "")
        ? "slab_based_scheme.xlsx"
        : schemeType == "8"
        ? "Single_target.xlsx"
        : schemeType == "4"
        ? "Single_target_Upper_Cap.xlsx"
        : "Unconditional_installation.xlsx"
    );
  };


// Check if file header is valid for XLSX files
const isValidFileHeader = (file) => {
  const validHeaders = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  return validHeaders.includes(file.type);
};

// Check if content type is valid for XLSX files
const isValidContentType = (file) => {
  const validContentTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  return validContentTypes.includes(file.type);
};

// Check if magic number is valid for XLSX files
const isValidMagicNumber = (file) => {
  // For XLSX files, we do not typically check for magic numbers.
  // Instead, we rely on file extension and content type validation.
  return true;
};
  const handleFileUpload = (e) => {
    const maxFileSize = 5 * 1024 * 1024; // Set maximum file size to 5MB
    const files = e.target.files;
  
    // Check if any file exceeds the maximum file size limit
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxFileSize) {
        ToastifyShow(`File ${files[i].name} is too large. Maximum file size is 5MB.`, "error");
        return;
      }
  
      // Check file headers for XLSX files
      if (!isValidFileHeader(files[i])) {
        ToastifyShow(`Invalid file header for ${files[i].name}.`, "error");
        return;
      }
  
      // Check content type for XLSX files
      if (!isValidContentType(files[i])) {
        ToastifyShow(`Invalid content type for ${files[i].name}.`, "error");
        return;
      }
  
      // Check magic numbers for XLSX files
      if (!isValidMagicNumber(files[i])) {
        ToastifyShow(`Invalid magic number for ${files[i].name}.`, "error");
        return;
      }
    }     // Validate file extension
    if (files[0].name.split('.')?.[1] !== 'xlsx') {
      ToastifyShow("Please upload an XLSX file only.", "error");
      return;
    }
  
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
  
      let dataArr = listData?.map((y, index) => {
        if (
          parsedData.find(
            (x) =>
              x[schemeType == "3" || schemeType == "" ? "-" : "DEALER CODE"] ===
              y?.dealerCode
          )
        ) {
          let z = parsedData.find(
            (x) =>
              x[schemeType == "3" || schemeType == "" ? "-" : "DEALER CODE"] ===
              y?.dealerCode
          );

          if ((schemeType == "3" || schemeType == "") && schemeLockedStatus?.t3I) {
            let dropdownscheme = z?.["__EMPTY_4"]?.toUpperCase() == "T3" ? ["t1", "t2", "t3"] :
              z?.["__EMPTY_4"]?.toUpperCase() == "T2" ? ["t1", "t2"] :
                z?.["__EMPTY_4"]?.toUpperCase() == "t1" ? ["t1"] : []
            let paget1 = dropdownscheme.includes("t1" || "t2" || "t3")
            let paget2 = dropdownscheme.includes("t2" || "t3")
            let paget3 = dropdownscheme.includes("t3")
            return {
              ...y,
              dropdownscheme: dropdownscheme,
              t1: paget1 ? z?.Targets : "",
              t2: paget2 ? z?.["__EMPTY_8"] : "",
              t3: paget3 ? z?.["__EMPTY_9"] : "",
              r1: paget1 ? parseFiveDigitNumber(z?.["INC.PER TRACTOR"]) : "",
              r2: paget2 ? parseFiveDigitNumber(z?.["__EMPTY_10"]) : "",
              r3: paget3 ? parseFiveDigitNumber(z?.["__EMPTY_11"]) : "",
              p1: (paget1) ? (parseInt(z?.Targets || 0) * parseInt(z?.["INC.PER TRACTOR"] || 0)) : 0,
              p2: (paget2) ? (parseInt(z?.__EMPTY_8 || 0) * parseInt(z?.["__EMPTY_10"] || 0)) : 0,
              p3: (paget3) ? (parseInt(z?.__EMPTY_9 || 0) * parseInt(z?.__EMPTY_11 || 0)) : 0,
              reason: z?.["-_1"] || "",
            };
          } else if ((schemeType == "3" || schemeType == "") && !schemeLockedStatus?.t3I) {
  
            let dropdownscheme = z?.["__EMPTY_4"]?.toUpperCase() == "T2" ? ["t1", "t2"] :
              z?.["__EMPTY_4"]?.toUpperCase() == "t1" ? ["t1"] : []
  
            let paget1 = dropdownscheme.includes("t1" || "t2")
            let paget2 = dropdownscheme.includes("t2" || "t3")
            let paget3 = dropdownscheme.includes("t3")

            return {
              ...y,
              dropdownscheme: dropdownscheme,
              t1: paget1 ? z?.Targets : "",
              t2: paget2 ? z?.["__EMPTY_8"] : "",
              t3: "",
              r1: paget1 ? parseFiveDigitNumber(z?.["INC.PER TRACTOR"]) : "",
              r2: paget2 ? parseFiveDigitNumber(z?.["__EMPTY_9"]) : "",
              r3: "",
              p1: (paget1) ? (parseInt(z?.Targets || 0) * parseInt(z?.["INC.PER TRACTOR"] || 0)) : 0,
              p2: (paget2) ? (parseInt(z?.__EMPTY_8 || 0) * parseInt(z?.["__EMPTY_9"] || 0)) : 0,
              p3: 0,
              reason: z?.["-_1"] || "",
            };
          } else if (schemeType == "8") {
            return {
              ...y,
              Target: z?.["INSTALLATION TARGET"] || "",
              IPT: z?.["INC. PER TRACTOR"] || "",
              reason: z?.["REMARKS"] || "",
            };
          } else if (schemeType == "4") {
            return {
              ...y,
              Target: z?.["INSTALLATION TARGET"] || "",
              UC: z?.["UPPER CAP"] || "",
              IPT: z?.["INC. PER TRACTOR"] || "",
              reason: z?.["REMARKS"] || "",
            };
          } else {
            return {
              ...y,
              Target: z?.["INSTALLATION EXPECTED"] || "",
              IPT: z?.["INC. PER TRACTOR"] || "",
              reason: z?.["REMARKS"] || "",
            };
          }
        } else {
          return y;
        }
      });
      setListData(dataArr);
  
      ToastifyShow("File uploaded successfully", "success");
    };
  };
  
  const dayExpired=userRole=="SADM"?getExpiredStatusSADM():userRole=="AMGR"?getExpiredStatusAMGR():getExpiredStatusZADM()
  const menu = (
    <Menu>
      <Menu.Item>
        {selectedFiles?.length == 0 && (
          <div className="custom-file-input">
            <input type="file" accept=".xlsx" onChange={handleFileUpload} />
            <label htmlFor="fileInput">Choose File</label>
          </div>
        )}
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <Button onClick={() => handleRemoveFile(index)} className="mx-1">
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </Menu.Item>
      <Menu.Item onClick={handleSampleFile}>Download Sample File</Menu.Item>
    </Menu>
  );

  return loadingDealer=="pending"?<FullScreenLoader />:!ENV_TIME&&(schemeData?.length==0||(schemeData?.[0]?.DealerCode=="null"&&schemeData?.[0]?.StateName=="null"&&schemeData?.[0]?.TMName=="null"))?<SchemeWindowShow />:dayExpired ? (
    <ExpiredWindow />
  ) : (
    <>
      <Divider orientation="center" />
      <Row>
        <Col flex="auto">
          <h2 className="unconditional" style={{ fontSize: "18px" }}>
            Create Scheme
          </h2>
        </Col>

        <Col
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: "40px",
          }}
        >
          <ThemeButton
            collectionData={collectionData}
            listData={listData}
            onSchemeChange={handleSchemeTableChange}
            handleStartChange={(date) => {
              setCollectionData({
                ...collectionData,
                startDate: date == null ? "" : date.format(),
              });
            }}
            handleEndChange={(date) =>
              setCollectionData({
                ...collectionData,
                endDate: date == null ? "" : date.format(),
              })
            }
            onSelectSchemeType={(schemeType) => {
              setCollectionData({ ...collectionData, schemeType });
              handleRemoveFile();
            }}
            onSelectDealer={(dealerCode, currentSelected) =>
              handleDealerCode(dealerCode, currentSelected)
            }
            onGetDealerDropdownData={getDealerDropdownData}
            handleDateRange={(date, formatDate) =>
              setCollectionData({
                ...collectionData,
                schemeType: schemeType,
                startDate: date && date[0] ? date[0].format() : "",
                endDate: date && date[1] ? date[1].format() : "",
                dealerCode: dealerCode,
              })
            }
            lastMonth={true}
          />
          <Space
            className="c-black1 "
            style={{
              padding: "0",
            }}
            direction="vertical"
          >
             <Tooltip title={"Import/Export to Excel"}> 
            <Dropdown overlay={menu} placement="bottomLeft" style={{}}>
              <Button
                className="h-2 c-black1 border-radius-0"
                style={{
                  borderRadius: "0 !important",
                }}
              >
                Import/Export to Excel
              </Button>
            </Dropdown>
            </Tooltip>
          </Space>
        </Col>
      </Row>

      <div>
        <Row>
          <Col>{isAnyEmpty(collectionData) ? renderSchemeTable() : null}</Col>
        </Row>
      </div>
    </>
  );
};
export default InstallationCreateNewScheme;
