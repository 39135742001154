import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import AuthSlice from "../authUser/authSlice";
import headerReducer from "../collection/Header";
import schemeListReducer from "../collection/SchemeList";
import generateScheme from "../collection/PostScheme";
import AllGeneratedScheme from "../collection/AllGeneratedScheme";
import AMGRSchemeList from "../areaManager/DashboardList";
import SADMSchemeList from "../stateHead/StateHeadDashboard";
import InstallationHeaderReducer from "../installation/Header";
import InstallationSchemeListReducer from "../installation/SchemeList";
import InstallationGenerateScheme from "../installation/PostScheme";
import InstallationAllGeneratedScheme from "../installation/AllGeneratedScheme";
import SchemeSubmitted from "../slice/schemeSubmitted/SchemeSubmitted";
import AllDraftScheme from "../collection/AllDraftScheme";
import ApprovalScheme from "../collection/ApprovalScheme";
import AllScheme from "../collection/AllScheme";
import SchemeStatus from "../statusTable/SchemeStatusTable";
import Monthpicker from "../summaryMonthPicker/Monthpicker";
import stateDataReducer from "../stateData/stateDataSlice";
import dashboardDataReducer from "../dashboard/dashboardDataSlice";
import schemeGenerateReducer from "../schemaGenerate/schemaGenerateSlice"
import schemeTrackReducer from "../schemetrack/schemaTrackSlice"
import schemaTableReducer from "../schemeTable/schemaTableSlice"
import trackCollectionReducer from "../trackCollection/trackCollectionSlice";
import modificationCollectionReducer from '../modification/modificationSlice';
import  mergeUnmergeReducer from "../mergeUnmerge/mergeUmergeSlice";
import withdrawalReducer from "../withdrawal/withdrawalSlice";
import holdDataReducer from "../hold/holdDataSlice"
import notificationReducer from "../notification/notificationSlice";

const persistConfig = {
  key: 'user',
  storage,
};

const persistedReducer = persistReducer(persistConfig, AuthSlice)



const store = configureStore({
  reducer: {
    header: headerReducer,
    user: persistedReducer,
    schemeList: schemeListReducer,
    postScheme: generateScheme,
    generatedScheme: AllGeneratedScheme,
    areaMngrSchemeList: AMGRSchemeList,
    stateHeadSchemeList: SADMSchemeList,
    installationHeader:InstallationHeaderReducer,
    installationSchemeList:InstallationSchemeListReducer,
    installationGenerateScheme:InstallationGenerateScheme,
    installationAllGeneratedScheme:InstallationAllGeneratedScheme,
    draftScheme:AllDraftScheme,
    approvalScheme:ApprovalScheme,
    schemeSubmitted:SchemeSubmitted,
    allScheme:AllScheme,
    schemeStatus:SchemeStatus,
    monthPicker:Monthpicker,
    stateData:stateDataReducer,
    dashboardDetails:dashboardDataReducer,
    schemagenerate:schemeGenerateReducer, 
    schemeTrack:schemeTrackReducer,
    schemeTableData:schemaTableReducer,
    trackCollection:trackCollectionReducer,
    modifyCollection:modificationCollectionReducer,
    dealerList:mergeUnmergeReducer,
    withdrawData:withdrawalReducer,
    holdData:holdDataReducer,
    notificationData:notificationReducer

   },
  // middleware,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});
const persistor = persistStore(store);

export { store, persistor }
