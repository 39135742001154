import { Space, Table, Tag, Tooltip, Row, Col, Button } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { getCurrentFiscalYear } from "../../../../utils/dateFormatter";
import XLSX from "xlsx-js-style";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { ReactComponent as Download } from "../../../../assets/vector/download.svg";
import ToastifyShow, { TextLocalString } from "../../../../components/ToastifyShow";
import moment from "moment";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
import { DisableShow } from "../../../../components/widthTable";

import { LegendHold } from "../../../../atoms/LegendHold";
const currentFiscalYear = getCurrentFiscalYear();

export const TagAwaitingHold = (x, y, data) => {
  return data ? (
    <DisableShow />
  ) : (
    <div className="d-flex justify-content-center">
      <Tag color="red" >{x || 0}</Tag>
      <Tag color={x != y ? "green" : "red"}>{y || 0}</Tag>
    </div>
  );
};
const AwaitingApprovalListCollectionDetails = ({
  setToggleEdit,
  dataSource,
  loading,
  setSchemeIdNo,
  setSelectedRowKeys,
  selectedRowKeys,
  setOpenRejectModal,
  setOpenAcceptModal,
  reference,
  schemeType,
  handleChange,
  viewType,
}) => {
  const getModifiedReason = () => viewType == "hold" ? [{
    title: "MODIFIED REMARKS",
    dataIndex: "holdRemarks",
    key: "holdRemarks",
    align: "center",
    ellipsis: {
      showTitle: false,
    },
    width: "180px",
    shouldCellUpdate: (record, prevRecord) =>
      record.holdRemarks !== prevRecord.holdRemarks,
    render: (data) => <Tooltip title={data}>{data || ""}</Tooltip>,
  }] : []
  const getRemarksHold = () => viewType == "hold" ? [{
    title: "HOLD REASON",
    dataIndex: "holdReason",
    key: "holdReason",
    align: "center",
    ellipsis: {
      showTitle: false,
    },
    width: "180px",
    shouldCellUpdate: (record, prevRecord) =>
      record.holdReason !== prevRecord.holdReason,
    render: (data) => <Tooltip title={data}>{data || ""}</Tooltip>,
  }] : []
  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSingleEdit = (record) => {

    setSchemeIdNo([record]);
    setToggleEdit(true);
  };

  const getStateHead = () => {
    if (userRole == "ZADM") {
      return [
        {
          title: <div>State Head</div>,
          dataIndex: "stateHeadName",
          width: "180px",
        },
      ];
    } else {
      return [];
    }
  };


  const columns = [
    {
      title: (
        <div>
          Scheme <br /> No
        </div>
      ),
      dataIndex: "schemeNo",
      width: "145px",
      fixed: "left",
      sorter: true,
      // sortDirections:['ascend', 'descend'],
      defaultSortOrder: "ascend",
      key: "SchemeNo",
      render: (_, item) => {
        let color = " ";
        const dataItem = item?.holdStatus == 9 ? item?.holdStatus : item.status;
        switch (dataItem) {
          case 0:
            color = "#FCE838";
            break;
          // #Inprogress
          case 1:
            color = "#FCE838";
            break;
          // #Approved
          case 2:
            color = "#51DE4E";
            break;
          // #Withdraw
          case 3:
            color = "#FFAE00";
            break;
          // #Cancel
          case 4:
            color = "#FF3232";
            break;
          // #Reject
          case 5:
            color = "#FF3232";
            break;
          //Deviation
          case 7:
            color = "#bcb38d";
            break;
          // #AutoRejected;
          case 8:
            color = "rgb(255, 0, 255)";
            break;
          case 9:
            color = "#9999ff"; //hold
            break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag style={{ color: "black", fontSize: "13px" }} color={color}>
            {item?.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "dealerCode",
      width: "140px",
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerCode",
    },
    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),
      dataIndex: "dealerLocation",
      fixed: "left",
      width: "150px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "dealerName",
      width: "160px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerName",
    },
    {
      title: "State",
      dataIndex: "stateName",
      width: "100px",
      key: "StateName",
      sorter: true,
      defaultSortOrder: "ascend",
    },
    ...getStateHead(),
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "territoryManager",
      width: "180px",
      key: "TerritoryManager",
      sorter: true,
      defaultSortOrder: "ascend",
    },
    {
      title: "TOP SLAB",
      width: "150px",
      dataIndex: "topTarget",
      // render: (x, y, index) => y?.topTarget?.toUpperCase(),
      render: (x, y) => {
        if (viewType == "hold") {
          const simple =
            y?.topTarget
              ?.split(",")
              ?.[y?.topTarget?.split(",")?.length - 1].toUpperCase() ||
            "t1".toUpperCase();
          const modified =
            y?.holdTopTarget
              ?.split(",")
              ?.[y?.holdTopTarget?.split(",")?.length - 1].toUpperCase() ||
            "t1".toUpperCase();
          return TagAwaitingHold(simple, modified);
        } else {
          return <div>{x}</div>;
        }
      },
    },
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "startMonth",
      width: "140px",
      render: (_, item) => dayjs(item.startMonth).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      width: "140px",
      render: (_, item) => dayjs(item.endMonth).format("MMM'YY"),
    },
    {
      title: "Industry",
      dataIndex: "industry",
      width: "160px",
      render: (x, y) => {
        if (viewType == "hold") {
          const simple = y?.industry;
          const modified = y?.holdIndustry;

          return TagAwaitingHold(simple, modified);
        } else {
          return <div>{x}</div>;
        }
      },
    },
    {
      title: "B",
      dataIndex: "oldBillingQty",
      width: "100px",
      render: (_, item) => item.oldBillingQty || 0,
    },
    {
      title: "I",
      dataIndex: "oldInstallation",
      width: "100px",
      render: (_, item) => item.oldInstallation || 0,
    },
    {
      title: "C",
      dataIndex: "oldCollection",
      width: "100px",
      render: (_, item) => item.oldCollection || 0,
    },
    {
      title: (
        <div>
          Opening <br /> Stock
        </div>
      ),
      dataIndex: "stock",
      width: "100px",
      children: [
        {
          title: "Nos",
          dataIndex: "oldOpeningStock",
          width: "100px",
          render: (x) => TextLocalString(x)
        },
        {
          title: "Days",
          dataIndex: "openingStockDays",
          width: "100px",
          render: (x) => TextLocalString(x)
        },
      ],
    },
    {
      title: "Closing Os. (In Lacs)",
      dataIndex: "stock",
      width: "160px",
      children: [
        {
          title: (
            <span>
              Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_3",
          width: "180px",
          render: (x) => <div>{TextLocalString(x)}</div>
        },
        {
          title: (
            <span>
              Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_2",
          width: "180px", render: (x) => <div>{TextLocalString(x)}</div>
        },
        {
          title: (
            <span>
              Last Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_1",
          width: "180px", render: (x) => <div>{TextLocalString(x)}</div>
        },
        // {
        //   title: (
        //     <span>
        //       Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
        //     </span>
        //   ),
        //   dataIndex: "outStandingDays",
        //   width: "160px", render: (x) => <div>{TextLocalString(x)}</div>
        // },
      ],
    },
    {
      title: "Closing OS Days",
      dataIndex: "outStandingDays",
      children: [
        {
          title: "OS Last Month",
          dataIndex: "outStandingDays",
          width: "160px",
          render: (x) => <div>{TextLocalString(x)}</div>
        },
      ],
    },
    {
      title: "BG",
      dataIndex: "bg",
      width: "160px",
      children: [
        {
          title: "Lacs",
          dataIndex: "bgLacsCurrentYear",
          width: "160px", render: (x) => <div>{TextLocalString(x)}</div>
        },
      ],
    },
    {
      title: (
        <div>
          Installation <br /> Plan
        </div>
      ),
      dataIndex: "IP",
      children: [
        {
          title: "Nos",
          dataIndex: "delayPlan",
          width: "160px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.delayPlan;
              const modified = y?.holdDelayPlan;

              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{TextLocalString(x)}</div>
            }
          },
        },
      ],
    },
    {
      title: (
        <div>
          Billing <br /> Details
        </div>
      ),
      dataIndex: "plan",
      children: [
        {
          title: "B",
          dataIndex: "billing",
          width: "160px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.billing;
              const modified = y?.holdBilling;

              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{TextLocalString(x)}</div>
            }
          },
        },
        {
          title: "I",
          dataIndex: "installation",
          width: "160px",

          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.installation;
              const modified = y?.holdInstallation;

              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{TextLocalString(x)}</div>
            }
          },
        },
        {
          title: "C",
          dataIndex: "collection",
          width: "160px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.collection;
              const modified = y?.holdCollection;

              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{TextLocalString(x)}</div>
            }
          },
        },
      ],
    },
    {
      title: (
        <div>
          Closing <br /> Stock
        </div>
      ),
      dataIndex: "closingStock",
      render: (x, y) => {
        if (viewType == "hold") {
          const simple = y?.closingStock;
          const modified = y?.holdClosingStock;

          return TagAwaitingHold(simple, modified);
        } else {
          return <div>{TextLocalString(x)}</div>
        }
      },
      width: "180px",
    },
    {
      title: (
        <div>
          Closing <br /> OutStanding (In Lacs)
        </div>
      ),
      dataIndex: "closingOutStanding",
      render: (x, y) => {
        if (viewType == "hold") {
          const simple = y?.closingOutStanding;
          const modified = y?.holdClosingOutstanding;

          return TagAwaitingHold(simple, modified);
        } else {
          return <div>{TextLocalString(x)}</div>
        }
      },
      width: "180px",
    },
    {
      title: "Books Collection Targets (No. of Trs.)",
      dataIndex: "BooksCollectionTarget",
      children: [
        {
          title: "T1",
          dataIndex: "t1",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.t1;
              const modified = y?.holdT1;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t1") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t1") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "T2",
          dataIndex: "t2",
          render: (x, y) => {
            if (viewType == "hold") {

              const simple = y?.t2;
              const modified = y?.holdT2;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t2") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t2") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },

          width: "160px",
        },
        {
          title: "T2-T1",
          dataIndex: "t2_T1",
          width: "160px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.t2_T1;
              const modified = y?.holdT2t1;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t2") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t2") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
        },
        {
          title: "T3",
          dataIndex: "t3",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.t3;
              const modified = y?.holdT3;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t3") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t3") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "T3-T2",
          dataIndex: "t3_T2",
          width: "160px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.t3_T2;
              const modified = y?.holdT3t2;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t3") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t3") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
        },
        {
          title: "T4",
          dataIndex: "t4",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.t4;
              const modified = y?.holdT4;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t4") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t4") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "T4-T3",
          dataIndex: "t4_T3",

          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.t4_T3;
              const modified = y?.holdT4t3;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t4") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t4") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "T5",
          dataIndex: "t5",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.t5;
              const modified = y?.holdT5;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t5") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t5") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "T5-T4",
          dataIndex: "t5_T4",
          width: "160px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.t5_T4;
              const modified = y?.holdT5t4;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t5") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t5") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
        },
        {
          title: "T6",
          dataIndex: "t6",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.t6;
              const modified = y?.holdT6;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t6") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t6") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "T6-T5",
          dataIndex: "t6_T5",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.t6_T5;
              const modified = y?.holdT6t5;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t6") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t6") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
        },
      ],
    },
    {
      title: (
        <div>
          Payout <br /> Rate
        </div>
      ),
      dataIndex: "pr",

      children: [
        {
          title: "R1",
          dataIndex: "r1",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.r1;
              const modified = y?.holdR1;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t1") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t1") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "R2",
          dataIndex: "r2",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.r2;
              const modified = y?.holdR2;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t2") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t2") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "R3",
          dataIndex: "r3",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.r3;
              const modified = y?.holdR3;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t3") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t3") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "R4",
          dataIndex: "r4",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.r4;
              const modified = y?.holdR4;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t4") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t4") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "R5",
          dataIndex: "r5",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.r5;
              const modified = y?.holdR5;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t5") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t5") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "R6",
          dataIndex: "r6",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.r6;
              const modified = y?.holdR6;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t6") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t6") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
      ],
    },

    {
      title: "Payout",
      dataIndex: "payout",
      children: [
        {
          title: "P1",
          dataIndex: "p1",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.p1;
              const modified = y?.holdP1;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t1") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t1") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "P2",
          dataIndex: "p2",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.p2;
              const modified = y?.holdP2;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t2") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t2") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "P3",
          dataIndex: "p3",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.p3;
              const modified = y?.holdP3;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t3") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t3") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "P4",
          dataIndex: "p4",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.p4;
              const modified = y?.holdP4;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t4") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t4") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "P5",
          dataIndex: "p5",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.p5;
              const modified = y?.holdP5;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t5") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t5") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
        {
          title: "P6",
          dataIndex: "p6",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.P6;
              const modified = y?.holdP6;
              const topSlabDetails =
                y?.holdTopTarget?.split(",")?.length != 0 ? y?.holdTopTarget?.split(",") : y?.topTarget?.split(",") ? y?.topTarget?.split(",") : ["t1"]
              return TagAwaitingHold(simple, modified, topSlabDetails?.includes("t6") ? false : true);
            } else {
              return !y?.topTarget?.split(",")?.includes("t6") ? (
                <div className="h-24 input-disabled"></div>
              ) : (
                <div>{TextLocalString(x)}</div>
              );
            }
          },
          width: "160px",
        },
      ],
    },
    {
      title: "Total Expected Payout",
      dataIndex: "TEP",
      children: [
        {
          title: "Expected Volume",
          dataIndex: "volume",
          width: "180px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.volume;
              const modified = y?.holdVolume;

              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{TextLocalString(x)}</div>;
            }
          },
        },
        {
          title: "Per Tr.",
          dataIndex: "perTr",
          width: "180px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.perTr;
              const modified = y?.holdPerTr;

              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{TextLocalString(x)}</div>;
            }
          },
        },
        {
          title: "Amount",
          dataIndex: "amount",
          width: "180px",
          render: (x, y) => {
            if (viewType == "hold") {
              const simple = y?.amount;
              const modified = y?.holdAmount;

              return TagAwaitingHold(simple, modified);
            } else {
              return <div>{TextLocalString(x)}</div>;
            }
          },
        },
      ],
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "180px",
    },
    ...getModifiedReason(),
    ...getRemarksHold(),

    {
      title: "Modify",
      key: "action",
      fixed: "right",
      width: "180px",

      render: (x, record, index) =>
        record?.status == 1 ? (
          <Space size="small">
            <Tooltip title="Edit" placement="top" className="infoIcon">
              <EditOutlined
                // type="primary" // Disable the button based on the condition
                onClick={() => handleSingleEdit(x)}
                style={{ fontSize: "20px" }}
              />
            </Tooltip>
            <Tooltip title="Accept" placement="top" className="infoIcon">
              <CheckOutlined
                style={{ color: "#51DE4E", fontSize: "20px" }}
                onClick={() => {
                  // setSchemeId(record.schemeId);
                  setSchemeIdNo([record]);
                  setOpenAcceptModal(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Reject" placement="top" className="infoIcon">
              <CloseOutlined
                style={{ color: "#FF3232", fontSize: "20px" }}
                onClick={() => {
                  // setSchemeNo([...schemeNo, record?.key]);
                  // handleRejectModal1();
                  setSchemeIdNo([record]);
                  setOpenRejectModal(true);
                }}
              />
            </Tooltip>
          </Space>
        ) : null,
    },
  ];

  const { loadingScheme, schemeApprove } = useSelector(
    (state) => state.schemagenerate
  );

  const captalizeObjKeys = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
    );

  const handleDownload = () => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };

    if (selectedRows?.length == 0) {
      ToastifyShow("Please select from checkbox", "error");
      return false;
    }

    const data = dataSource
      .filter((item) => selectedRows.includes(item.schemeId))
      .map((item) => {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");

        return captalizeObjKeys({
          Scheme_No: item.schemeNo,
          Dealer_Code: item.dealerCode,
          Dealer_Location: item.dealerLocation,
          Dealer_Name: item.dealerName,
          State: item.stateName,
          Territory_Manager: item.territoryManager,
          StateHeadName: item.stateHeadName,
          Start_Month:
            formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
          End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
          TOPTARGET: item.topTarget,
          Industry: item.industry,
          B: item.oldBillingQty ? item.oldBillingQty : 0,
          I: item.oldInstallation ? item.oldInstallation : 0,
          C: item.oldCollection ? item.oldCollection : 0,
          Opening_Stock_Nos: item.oldOpeningStock,
          Opening_Stock_Days: item.openingStockDays,
          Last_Third_Month: item.closingOutStandingMonth_3,
          Last_Two_Month: item.closingOutStandingMonth_2,
          Last_One_Month: item.closingOutStandingMonth_1,
          Days: item.outStandingDays,
          BG_Lacs: item.bgLacsCurrentYear,
          Installation_Plan_Nos: item.delayPlan,
          Billing_Details_B: item.billing,
          Billing_Details_i: item.installation,
          Billing_Details_c: Math.round(item.collection),
          Books_Collection_Target_T1: item.t1,
          Books_Collection_Target_T2: item.t2,
          Books_Collection_Target_T2T1: item.t2_T1,
          Books_Collection_Target_T3: item.t3,
          Books_Collection_Target_T3T2: item.t3_T2,
          Books_Collection_Target_T4: item.t4,
          Books_Collection_Target_T4T3: item.t4_T3,
          Books_Collection_Target_T5: item.t5,
          Books_Collection_Target_T5T4: item.t5_T4,
          Books_Collection_Target_T6: item.t6,
          Books_Collection_Target_T6T5: item.t6_T5,
          Payout_R1: item.r1,
          Payout_R2: item.r2,
          Payout_R3: item.r3,
          Payout_R4: item.r4,
          Payout_R5: item.r5,
          Payout_R6: item.r6,
          Payout_Rate_P1: item.p1,
          Payout_Rate_P2: item.p2,
          Payout_Rate_P3: item.p3,
          Payout_Rate_P4: item.p4,
          Payout_Rate_P5: item.p5,
          Payout_Rate_P6: item.p6,
          CLOSING_STOCK: item?.closingStock,
          CLOSING_OUTSTANDING: item?.closingOutStanding,
          TEPvol: item.volume,
          TEPpertr: item.perTr,
          TEPamount: item.amount,
          Remarks: item.reason,
        });
      });

    let tab_text = `<table>
    <thead>
    <tr>
        <th rowspan="2">SCHEME NO</th>
        <th rowspan="2">DEALER CODE</th>
        <th rowspan="2">DEALER LOCATION</th>
        <th rowspan="2">DEALER NAME</th>
        <th rowspan="2">STATE</th>
        <th rowspan="2">STATE HEAD</th>
        <th rowspan="2">TERRITORY MANAGER</th>
        <th rowspan="2">TOP SLAB</th>
        <th rowspan="2">START MONTH</th>
        <th rowspan="2">END MONTH</th>
        <th rowspan="2">Industry</th>
        <th rowspan="2">B</th>
        <th rowspan="2">I</th>
        <th rowspan="2">C</th>
        <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS. (IN LACS)</th>
        <th>BG</th>
        <th>INSTALLATION PLAN</th>
        <th colspan="3">BILLING DETAILS</th>
        <th rowspan="2">CLOSING STOCK</th>
        <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
        <th colspan="11">BOOKING COLLECTION TARGETS(NO. OF TRS)</th>
        <th colspan="6">PAYOUT RATE</th>

        <th colspan="6">PAYOUT</th>
        <th colspan="3">TOTAL EXPECTED PAYOUT</th>
        <th rowspan="2">REMARKS</th>
        </tr>
    <tr>
       <th>NOS</th>
       <th>Days</th>
       <th>LAST 3rd MONTH</th>
       <th>LAST 2nd MONTH</th>
       <th>LAST 1st MONTH</th>
       <th>LAST 1st MONTH DAYS</th>
       <th>LACS</th>
       <th>NOS</th>
       <th>B</th>
       <th>I</th>
       <th>C</th>
       <th>T1</th>f
       <th>T2</th>
       <th>T2-T1</th>
       <th>T3</th>
       <th>T3-T2</th>
       <th>T4</th>
       <th>T4-T3</th>
       <th>T5</th>
       <th>T5-T4</th>
       <th>T6</th>
       <th>T6-T5</th>
       <th>R1</th>
       <th>R2</th>
       <th>R3</th>
       <th>R4</th>
       <th>R5</th>
       <th>R6</th>
       <th>P1</th>
       <th>P2</th>
       <th>P3</th>  
       <th>P4</th>
       <th>P5</th>
       <th>P6</th>  
       <th>Expected Volume</th>
       <th>PER TR.</th>
       <th>AMOUNT</th>  
      </tr>
    </thead>`;
    data?.forEach((x) => {
      tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
  <td>${x?.DEALER_CODE}</td>
  <td>${x?.DEALER_LOCATION}</td>
  <td>${x?.DEALER_NAME}</td>
  <td>${x?.STATE}</td>
  <td>${userRole == "ZADM" ? x?.STATEHEADNAME : ""}</td>
  <td>${x?.TERRITORY_MANAGER}</td>
  <td>${x?.TOPTARGET || ""}</td>
  <td>${x?.START_MONTH || ""}</td>
  <td>${x?.END_MONTH || ""}</td>
  <td>${x?.INDUSTRY}</td>
  <td>${x?.B}</td>
  <td>${x?.I}</td>
  <td>${x?.C}</td>
  <td>${x?.OPENING_STOCK_NOS}</td>
  <td>${x?.OPENING_STOCK_DAYS}</td>
  <td>${x?.LAST_THIRD_MONTH}</td>
  <td>${x?.LAST_TWO_MONTH}</td>
  <td>${x?.LAST_ONE_MONTH}</td>
  <td>${x?.DAYS}</td>
  <td>${x?.BG_LACS}</td>
  <td>${x?.INSTALLATION_PLAN_NOS}</td>
  <td>${x?.BILLING_DETAILS_B}</td>
  <td>${x?.BILLING_DETAILS_I}</td>
  <td>${x?.BILLING_DETAILS_C}</td>
  <td>${x?.CLOSING_STOCK}</td>
  <td>${x?.CLOSING_OUTSTANDING}</td>
  <td>${x?.BOOKS_COLLECTION_TARGET_T1}</td>
  <td>${x?.BOOKS_COLLECTION_TARGET_T2}</td>
  <td>${x?.BOOKS_COLLECTION_TARGET_T2T1 && x?.TOPTARGET?.includes("t2")
          ? x?.BOOKS_COLLECTION_TARGET_T2T1
          : 0
        }</td>
  <td>${x?.BOOKS_COLLECTION_TARGET_T3}</td>
  <td>${x?.BOOKS_COLLECTION_TARGET_T3T2 && x?.TOPTARGET?.includes("t3")
          ? x?.BOOKS_COLLECTION_TARGET_T3T2
          : 0
        }</td>
  <td>${x?.BOOKS_COLLECTION_TARGET_T4}</td>
  <td>${x?.BOOKS_COLLECTION_TARGET_T4T3 && x?.TOPTARGET?.includes("t4")
          ? x?.BOOKS_COLLECTION_TARGET_T4T3
          : 0
        }</td>
  <td>${x?.BOOKS_COLLECTION_TARGET_T5}</td>
  <td>${x?.BOOKS_COLLECTION_TARGET_T5T4 && x?.TOPTARGET?.includes("t5")
          ? x?.BOOKS_COLLECTION_TARGET_T5T4
          : 0
        }</td>
  <td>${x?.BOOKS_COLLECTION_TARGET_T6}</td>
  <td>${x?.BOOKS_COLLECTION_TARGET_T6T5 && x?.TOPTARGET?.includes("t6")
          ? x?.BOOKS_COLLECTION_TARGET_T6T5
          : 0
        }</td>
  <td>${x?.PAYOUT_R1}</td>
  <td>${x?.PAYOUT_R2}</td>
  <td>${x?.PAYOUT_R3}</td>
  <td>${x?.PAYOUT_R4}</td>
  <td>${x?.PAYOUT_R5}</td>
  <td>${x?.PAYOUT_R6}</td>
  <td>${x?.PAYOUT_RATE_P1}</td>
  <td>${x?.PAYOUT_RATE_P2}</td>
  <td>${x?.PAYOUT_RATE_P3}</td>
  <td>${x?.PAYOUT_RATE_P4}</td>
  <td>${x?.PAYOUT_RATE_P5}</td>
  <td>${x?.PAYOUT_RATE_P6}</td>
  <td>${x?.TEPVOL}</td>
  <td>${x?.TEPPERTR}</td>
  <td>${x?.TEPAMOUNT}</td>
  <td>${x?.REMARKS}</td>
  </tr>`;
    });

    let totals = {
      INDUSTRY: 0,
      B: 0,
      I: 0,
      C: 0,
      OPENING_STOCK_NOS: 0,
      OPENING_STOCK_DAYS: 0,
      LAST_THIRD_MONTH: 0,
      LAST_TWO_MONTH: 0,
      LAST_ONE_MONTH: 0,
      DAYS: 0,
      BG_LACS: 0,
      INSTALLATION_PLAN_NOS: 0,
      BILLING_DETAILS_B: 0,
      BILLING_DETAILS_I: 0,
      Billing_Details_c: 0,
      CLOSING_STOCK: 0,
      CLOSING_OUTSTANDING: 0,
      BOOKS_COLLECTION_TARGET_T1: 0,
      BOOKS_COLLECTION_TARGET_T2: 0,
      BOOKS_COLLECTION_TARGET_T2T1: 0,
      BOOKS_COLLECTION_TARGET_T3: 0,
      BOOKS_COLLECTION_TARGET_T3T2: 0,
      BOOKS_COLLECTION_TARGET_T4: 0,
      BOOKS_COLLECTION_TARGET_T4T3: 0,
      BOOKS_COLLECTION_TARGET_T5: 0,
      BOOKS_COLLECTION_TARGET_T5T4: 0,
      BOOKS_COLLECTION_TARGET_T6: 0,
      BOOKS_COLLECTION_TARGET_T6T5: 0,
      PAYOUT_R1: 0,
      PAYOUT_R2: 0,
      PAYOUT_R3: 0,
      PAYOUT_R4: 0,
      PAYOUT_R5: 0,
      PAYOUT_R6: 0,
      PAYOUT_RATE_P1: 0,
      PAYOUT_RATE_P2: 0,
      PAYOUT_RATE_P3: 0,
      PAYOUT_RATE_P4: 0,
      PAYOUT_RATE_P5: 0,
      PAYOUT_RATE_P6: 0,
      TEPVOL: 0,
      TEPPERTR: 0,
      TEPAMOUNT: 0,
      REMARKS: "",
    };

    data.forEach((item) => {
      for (let key in totals) {
        if (
          key === "BOOKS_COLLECTION_TARGET_T2T1" &&
          item?.TOPTARGET.includes("t2")
        ) {
          totals[key] += parseInt(item[key]) || 0;
        } else if (
          key === "BOOKS_COLLECTION_TARGET_T3T2" &&
          item?.TOPTARGET.includes("t3")
        ) {
          totals[key] += parseInt(item[key]) || 0;
        } else if (
          key === "BOOKS_COLLECTION_TARGET_T4T3" &&
          item?.TOPTARGET.includes("t4")
        ) {
          totals[key] += parseInt(item[key]) || 0;
        } else if (
          key === "BOOKS_COLLECTION_TARGET_T5T4" &&
          item?.TOPTARGET.includes("t5")
        ) {
          totals[key] += parseInt(item[key]) || 0;
        } else if (
          key === "BOOKS_COLLECTION_TARGET_T6T5" &&
          item?.TOPTARGET.includes("t6")
        ) {
          totals[key] += parseInt(item[key]) || 0;
        } else if (
          ![
            "BOOKS_COLLECTION_TARGET_T2T1",
            "BOOKS_COLLECTION_TARGET_T3T2",
            "BOOKS_COLLECTION_TARGET_T4T3",
            "BOOKS_COLLECTION_TARGET_T5T4",
            "BOOKS_COLLECTION_TARGET_T6T5",
          ].includes(key)
        ) {
          totals[key] += parseInt(item[key]) || 0;
        }
      }
    });
    totals.PAYOUT_R1 =
      totals.BOOKS_COLLECTION_TARGET_T1 !== 0
        ? Math.round(totals.PAYOUT_RATE_P1 / totals.BOOKS_COLLECTION_TARGET_T1)
        : 0;
    totals.PAYOUT_R2 =
      totals.BOOKS_COLLECTION_TARGET_T2 !== 0
        ? parseInt(totals.PAYOUT_RATE_P2 / totals.BOOKS_COLLECTION_TARGET_T2)
        : 0;
    totals.PAYOUT_R3 =
      totals.BOOKS_COLLECTION_TARGET_T3 !== 0
        ? parseInt(totals.PAYOUT_RATE_P3 / totals.BOOKS_COLLECTION_TARGET_T3)
        : 0;
    totals.PAYOUT_R4 =
      totals.BOOKS_COLLECTION_TARGET_T4 !== 0
        ? parseInt(totals.PAYOUT_RATE_P4 / totals.BOOKS_COLLECTION_TARGET_T4)
        : 0;
    totals.PAYOUT_R5 =
      totals.BOOKS_COLLECTION_TARGET_T5 !== 0
        ? parseInt(totals.PAYOUT_RATE_P5 / totals.BOOKS_COLLECTION_TARGET_T5)
        : 0;
    totals.PAYOUT_R6 =
      totals.BOOKS_COLLECTION_TARGET_T6 !== 0
        ? parseInt(totals.PAYOUT_RATE_P6 / totals.BOOKS_COLLECTION_TARGET_T6)
        : 0;
    totals.TEPPERTR =
      totals.TEPVOL !== 0 ? parseInt(totals.TEPAMOUNT / totals.TEPVOL) : 0;

    let totalsRow = "<tr><td colspan='10' >Total</td>";
    for (let key in totals) {
      if (
        key != "REMARKS" &&
        key != "CLOSING_STOCK" &&
        key != "CLOSING_OUTSTANDING"
      ) {
        if (key.startsWith("PAYOUT_R") || key === "TEPPERTR") {
          totalsRow += `<td>${totals[key]}</td>`;
        } else {
          totalsRow += `<td>${totals[key]}</td>`;
        }
      } else {
        totalsRow += `<td></td>`;
      }
    }

    totalsRow += "</tr>";
    tab_text += totalsRow;
    tab_text += "</table>";
    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    document.body.removeChild(elt);

    // Add style to total row color
    const allColumns = data.reduce((columns, item) => {
      Object.keys(item).forEach((key) => {
        if (!columns.includes(key)) {
          columns.push(key);
        }
      });
      return columns;
    }, []);

    function getAlphabetEquivalent(number) {
      if (number < 1) {
        return "Invalid input";
      } else if (number <= 26) {
        return String.fromCharCode(number + 64);
      } else {
        let firstChar = String.fromCharCode((number - 1) / 26 + 64);
        let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
        return firstChar + secondChar;
      }
    }

    let excelArr = [];
    for (let index = 0; index < allColumns.length; index++) {
      let ele = `${getAlphabetEquivalent(index + 1)}${(
        data.length + 3
      ).toString()}`;
      excelArr.push(ele);
    }
    Object.keys(wb.Sheets[wb.SheetNames[0]])
      .filter((cell) => excelArr.includes(cell))
      .forEach((cell) => {
        wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
      });

    let nameFile =
      schemeType == 1
        ? "Awaiting_Approval_With_DeliveryPlan.xlsx"
        : "Awaiting_Approval_Without_Delivery_Plan.xlsx";
    // generate file
    XLSX.writeFile(wb, nameFile);
  };

  const tableHeader = (d) => {
    return (
      <Row className="d-none">
        <Col>
          <Button
            // disabled={selectedRows?.length == 0}
            icon={<Download />}
            type="text"
            onClick={handleDownload}
            ref={reference}
          >
            Downloads
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Table
        title={tableHeader}
        size="small"
        loading={loading ? true : false}
        summary={(pageData) => {
          const fieldSum = {
            industry: 0,
            oldBillingQty: 0,
            oldInstallation: 0,
            oldCollection: 0,
            oldOpeningStock: 0,
            openingStockDays: 0,
            closingOutStandingMonth_3: 0,
            closingOutStandingMonth_2: 0,
            closingOutStandingMonth_1: 0,
            outStandingDays: 0,
            bg: 0,
            bgLacsCurrentYear: 0,
            delayPlan: 0,
            billing: 0,
            installation: 0,
            collection: 0,
            BooksCollectionTarget: 0,
            t1: 0,
            t2: 0,
            t2_T1: 0,
            t3: 0,
            t3_T2: 0,
            t4: 0,
            t4_T3: 0,
            t5: 0,
            t5_T4: 0,
            t6: 0,
            t6_T5: 0,
            r1: 0,
            r2: 0,
            r3: 0,
            r4: 0,
            r5: 0,
            r6: 0,
            payout: 0,
            p1: 0,
            p2: 0,
            p3: 0,
            p4: 0,
            p5: 0,
            p6: 0,
            volume: 0,
            perTr: 0,
            amount: 0,
            holdT1: 0,
            holdT2: 0,
            holdT3: 0,
            holdT4: 0,
            holdT5: 0,
            holdT6: 0,
            holdR1: 0,
            holdR2: 0,
            holdR3: 0,
            holdR4: 0,
            holdR5: 0,
            holdR6: 0,
            holdP1: 0,
            holdP2: 0,
            holdP3: 0,
            holdP4: 0,
            holdP5: 0,
            holdP6: 0,
            holdT2t1: 0,
            holdT3t2: 0,
            holdT4t3: 0,
            holdT5t4: 0,
            holdT6t5: 0,
            holdAmount: 0,
            holdVolume: 0,
            holdPerTr: 0,
            holdDelayPlan: 0,
            holdBilling: 0,
            holdInstallation: 0,
            holdCollection: 0,
            holdIndustry: 0,
            holdClosingStock: 0,
            closingStock: 0,
            holdClosingOutstanding: 0,
            closingOutStanding: 0,
          };

          pageData.forEach(
            ({
              industry,
              oldBillingQty,
              oldInstallation,
              oldCollection,
              oldOpeningStock,
              openingStockDays,
              closingOutStandingMonth_3,
              closingOutStandingMonth_2,
              closingOutStandingMonth_1,
              outStandingDays,
              bg,
              bgLacsCurrentYear,
              delayPlan,
              billing,
              installation,
              collection,
              BooksCollectionTarget,
              t1,
              t2,
              t2_T1,
              t3,
              t3_T2,
              t4,
              t4_T3,
              t5,
              t5_T4,
              t6,
              t6_T5,
              r1,
              r2,
              r3,
              r4,
              r5,
              r6,
              payout,
              p1,
              p2,
              p3,
              p4,
              p5,
              p6,
              volume,
              perTr,
              amount,
              topTarget,
              holdTopTarget,
              holdP1,
              holdP2,
              holdP3,
              holdP4,
              holdP5,
              holdP6,
              holdR1,
              holdR2,
              holdR3,
              holdR4,
              holdR5,
              holdR6,
              holdT1,
              holdT2,
              holdT3,
              holdT4,
              holdT5,
              holdT6,
              holdT2t1,
              holdT3t2,
              holdT4t3,
              holdT5t4,
              holdT6t5,
              holdVolume,
              holdPerTr,
              holdAmount,
              holdDelayPlan,
              holdBilling,
              holdIndustry,
              holdInstallation,
              holdCollection,
              closingStock,
              holdClosingStock,
              holdClosingOutstanding,
              closingOutStanding,
            }) => {
              fieldSum.industry += industry ? parseInt(industry) : 0;
              fieldSum.oldBillingQty += oldBillingQty
                ? parseInt(oldBillingQty)
                : 0;
              fieldSum.oldInstallation += oldInstallation
                ? parseInt(oldInstallation)
                : 0;
              fieldSum.oldCollection += oldCollection
                ? parseInt(oldCollection)
                : 0;
              fieldSum.oldOpeningStock += oldOpeningStock
                ? parseInt(oldOpeningStock)
                : 0;
              fieldSum.openingStockDays += openingStockDays
                ? parseInt(openingStockDays)
                : 0;
              fieldSum.closingOutStandingMonth_3 += closingOutStandingMonth_3
                ? parseInt(closingOutStandingMonth_3)
                : 0;
              fieldSum.closingOutStandingMonth_2 += closingOutStandingMonth_2
                ? parseInt(closingOutStandingMonth_2)
                : 0;
              fieldSum.closingOutStandingMonth_1 += closingOutStandingMonth_1
                ? parseInt(closingOutStandingMonth_1)
                : 0;
              fieldSum.outStandingDays += outStandingDays
                ? parseInt(outStandingDays)
                : 0;
              fieldSum.bg += bg ? parseInt(bg) : 0;
              fieldSum.bgLacsCurrentYear += bgLacsCurrentYear
                ? parseInt(bgLacsCurrentYear)
                : 0;
              fieldSum.delayPlan += delayPlan ? parseInt(delayPlan) : 0;
              fieldSum.billing += billing ? parseInt(billing) : 0;
              fieldSum.installation += installation ? parseInt(installation) : 0;
              fieldSum.collection += collection ? parseInt(collection) : 0;
              fieldSum.holdIndustry += holdIndustry ? parseInt(holdIndustry) : 0;
              fieldSum.holdDelayPlan += holdDelayPlan ? parseInt(holdDelayPlan) : 0;
              fieldSum.holdBilling += holdBilling ? parseInt(holdBilling) : 0;
              fieldSum.holdInstallation += holdInstallation ? parseInt(holdInstallation) : 0;
              fieldSum.holdCollection += holdCollection ? parseInt(holdCollection) : 0;

              fieldSum.closingStock += closingStock ? parseInt(closingStock) : 0;
              fieldSum.holdClosingStock += holdClosingStock ? parseInt(holdClosingStock) : 0;

              fieldSum.closingOutStanding += closingOutStanding ? parseInt(closingOutStanding) : 0;
              fieldSum.holdClosingOutstanding += holdClosingOutstanding ? parseInt(holdClosingOutstanding) : 0;

              fieldSum.BooksCollectionTarget += BooksCollectionTarget
                ? parseInt(BooksCollectionTarget)
                : 0;
              //holdTopTarget,viewType
              if (viewType != "hold") {
                fieldSum.t1 +=
                  t1 && topTarget?.includes("t1") ? parseInt(t1 || 0) : 0;
                fieldSum.t2 +=
                  t2 && topTarget?.includes("t2") ? parseInt(t2 || 0) : 0;
                fieldSum.t2_T1 +=
                  t2_T1 && topTarget?.includes("t2") ? parseInt(t2_T1 || 0) : 0;
                fieldSum.t3 +=
                  t3 && topTarget?.includes("t3") ? parseInt(t3 || 0) : 0;
                fieldSum.t3_T2 +=
                  t3_T2 && topTarget?.includes("t3") ? parseInt(t3_T2 || 0) : 0;
                fieldSum.t4 +=
                  t4 && topTarget?.includes("t4") ? parseInt(t4 || 0) : 0;
                fieldSum.t4_T3 +=
                  t4_T3 && topTarget?.includes("t4") ? parseInt(t4_T3 || 0) : 0;
                fieldSum.t5 +=
                  t5 && topTarget?.includes("t5") ? parseInt(t5 || 0) : 0;
                fieldSum.t5_T4 +=
                  t5_T4 && topTarget?.includes("t5") ? parseInt(t5_T4 || 0) : 0;
                fieldSum.t6 +=
                  t6 && topTarget?.includes("t6") ? parseInt(t6 || 0) : 0;
                fieldSum.t6_T5 +=
                  t6_T5 && topTarget?.includes("t6") ? parseInt(t6_T5 || 0) : 0;
                fieldSum.r1 +=
                  r1 && topTarget?.includes("t1") ? parseInt(r1 || 0) : 0;
                fieldSum.r2 +=
                  r2 && topTarget?.includes("t2") ? parseInt(r2 || 0) : 0;
                fieldSum.r3 +=
                  r3 && topTarget?.includes("t3") ? parseInt(r3 || 0) : 0;
                fieldSum.r4 +=
                  r4 && topTarget?.includes("t4") ? parseInt(r4 || 0) : 0;
                fieldSum.r5 +=
                  r5 && topTarget?.includes("t5") ? parseInt(r5 || 0) : 0;
                fieldSum.r6 +=
                  r6 && topTarget?.includes("t6") ? parseInt(r6 || 0) : 0;
                fieldSum.payout += payout ? parseInt(payout) : 0;
                fieldSum.p1 +=
                  p1 && topTarget?.includes("t1") ? parseInt(p1 || 0) : 0;
                fieldSum.p2 +=
                  p2 && topTarget?.includes("t2") ? parseInt(p2 || 0) : 0;
                fieldSum.p3 +=
                  p3 && topTarget?.includes("t3") ? parseInt(p3 || 0) : 0;
                fieldSum.p4 +=
                  p4 && topTarget?.includes("t4") ? parseInt(p4 || 0) : 0;
                fieldSum.p5 +=
                  p5 && topTarget?.includes("t5") ? parseInt(p5 || 0) : 0;
                fieldSum.p6 +=
                  p6 && topTarget?.includes("t6") ? parseInt(p6 || 0) : 0;
              } else {
                fieldSum.t1 +=
                  t1 && topTarget?.includes("t1") ? parseInt(t1 || 0) : 0;
                fieldSum.t2 +=
                  t2 && topTarget?.includes("t2") ? parseInt(t2 || 0) : 0;
                fieldSum.t2_T1 +=
                  t2_T1 && topTarget?.includes("t2") ? parseInt(t2_T1 || 0) : 0;
                fieldSum.t3 +=
                  t3 && topTarget?.includes("t3") ? parseInt(t3 || 0) : 0;
                fieldSum.t3_T2 +=
                  t3_T2 && topTarget?.includes("t3") ? parseInt(t3_T2 || 0) : 0;
                fieldSum.t4 +=
                  t4 && topTarget?.includes("t4") ? parseInt(t4 || 0) : 0;
                fieldSum.t4_T3 +=
                  t4_T3 && topTarget?.includes("t4") ? parseInt(t4_T3 || 0) : 0;
                fieldSum.t5 +=
                  t5 && topTarget?.includes("t5") ? parseInt(t5 || 0) : 0;
                fieldSum.t5_T4 +=
                  t5_T4 && topTarget?.includes("t5") ? parseInt(t5_T4 || 0) : 0;
                fieldSum.t6 +=
                  t6 && topTarget?.includes("t6") ? parseInt(t6 || 0) : 0;
                fieldSum.t6_T5 +=
                  t6_T5 && topTarget?.includes("t6") ? parseInt(t6_T5 || 0) : 0;
                fieldSum.r1 +=
                  r1 && topTarget?.includes("t1") ? parseInt(r1 || 0) : 0;
                fieldSum.r2 +=
                  r2 && topTarget?.includes("t2") ? parseInt(r2 || 0) : 0;
                fieldSum.r3 +=
                  r3 && topTarget?.includes("t3") ? parseInt(r3 || 0) : 0;
                fieldSum.r4 +=
                  r4 && topTarget?.includes("t4") ? parseInt(r4 || 0) : 0;
                fieldSum.r5 +=
                  r5 && topTarget?.includes("t5") ? parseInt(r5 || 0) : 0;
                fieldSum.r6 +=
                  r6 && topTarget?.includes("t6") ? parseInt(r6 || 0) : 0;
                fieldSum.payout += payout ? parseInt(payout) : 0;
                fieldSum.p1 +=
                  p1 && topTarget?.includes("t1") ? parseInt(p1 || 0) : 0;
                fieldSum.p2 +=
                  p2 && topTarget?.includes("t2") ? parseInt(p2 || 0) : 0;
                fieldSum.p3 +=
                  p3 && topTarget?.includes("t3") ? parseInt(p3 || 0) : 0;
                fieldSum.p4 +=
                  p4 && topTarget?.includes("t4") ? parseInt(p4 || 0) : 0;
                fieldSum.p5 +=
                  p5 && topTarget?.includes("t5") ? parseInt(p5 || 0) : 0;
                fieldSum.p6 +=
                  p6 && topTarget?.includes("t6") ? parseInt(p6 || 0) : 0;
                fieldSum.holdT1 +=
                  holdTopTarget?.split(",")?.includes("t1") ? parseInt(holdT1 || 0) : 0;
                fieldSum.holdT2 +=
                  holdT2 && holdTopTarget?.split(",")?.includes("t2") ? parseInt(holdT2 || 0) : 0;
                fieldSum.holdT2t1 +=
                  holdT2t1 && holdTopTarget?.split(",")?.includes("t2") ? parseInt(holdT2t1 || 0) : 0;
                fieldSum.holdT3 +=
                  holdT3 && holdTopTarget?.split(",")?.includes("t3") ? parseInt(holdT3 || 0) : 0;
                fieldSum.holdT3t2 +=
                  holdT3t2 && holdTopTarget?.split(",")?.includes("t3") ? parseInt(holdT3t2 || 0) : 0;
                fieldSum.holdT4 +=
                  holdT4 && holdTopTarget?.split(",")?.includes("t4") ? parseInt(holdT4 || 0) : 0;
                fieldSum.holdT4t3 +=
                  holdT4t3 && holdTopTarget?.split(",")?.includes("t4") ? parseInt(holdT4t3 || 0) : 0;
                fieldSum.holdT5 +=
                  holdT5 && holdTopTarget?.split(",")?.includes("t5") ? parseInt(holdT5 || 0) : 0;
                fieldSum.holdT5t4 +=
                  holdT5t4 && holdTopTarget?.split(",")?.includes("t5") ? parseInt(holdT5t4 || 0) : 0;
                fieldSum.holdT6 +=
                  holdT6 && holdTopTarget?.split(",")?.includes("t6") ? parseInt(holdT6 || 0) : 0;
                fieldSum.holdT6t5 +=
                  holdT6t5 && holdTopTarget?.split(",")?.includes("t6") ? parseInt(holdT6t5 || 0) : 0;
                fieldSum.holdR1 +=
                  holdR1 && holdTopTarget?.split(",")?.includes("t1") ? parseInt(holdR1 || 0) : 0;
                fieldSum.holdR2 +=
                  holdR2 && holdTopTarget?.split(",")?.includes("t2") ? parseInt(holdR2 || 0) : 0;
                fieldSum.holdR3 +=
                  holdR3 && holdTopTarget?.split(",")?.includes("t3") ? parseInt(holdR3 || 0) : 0;
                fieldSum.holdR4 +=
                  holdR4 && holdTopTarget?.split(",")?.includes("t4") ? parseInt(holdR4 || 0) : 0;
                fieldSum.holdR5 +=
                  holdR5 && holdTopTarget?.split(",")?.includes("t5") ? parseInt(holdR5 || 0) : 0;
                fieldSum.holdR6 +=
                  holdR6 && holdTopTarget?.split(",")?.includes("t6") ? parseInt(holdR6 || 0) : 0;
                fieldSum.payout += payout ? parseInt(payout) : 0;
                fieldSum.holdP1 +=
                  holdP1 && holdTopTarget?.split(",")?.includes("t1") ? parseInt(holdP1 || 0) : 0;
                fieldSum.holdP2 +=
                  holdP2 && holdTopTarget?.split(",")?.includes("t2") ? parseInt(holdP2 || 0) : 0;
                fieldSum.holdP3 +=
                  holdP3 && holdTopTarget?.split(",")?.includes("t3") ? parseInt(holdP3 || 0) : 0;
                fieldSum.holdP4 +=
                  holdP4 && holdTopTarget?.split(",")?.includes("t4") ? parseInt(holdP4 || 0) : 0;
                fieldSum.holdP5 +=
                  holdP5 && holdTopTarget?.split(",")?.includes("t5") ? parseInt(holdP5 || 0) : 0;
                fieldSum.holdP6 +=
                  holdP6 && holdTopTarget?.split(",")?.includes("t6") ? parseInt(holdP6 || 0) : 0;
                fieldSum.holdVolume += holdVolume ? parseInt(holdVolume) : 0;
                fieldSum.holdPerTr += holdPerTr ? parseInt(holdPerTr) : 0;
                fieldSum.holdAmount += holdAmount ? parseInt(holdAmount) : 0;

              }

              fieldSum.volume += volume ? parseInt(volume) : 0;
              fieldSum.perTr += perTr ? parseInt(perTr) : 0;
              fieldSum.amount += amount ? parseInt(amount) : 0;
              // 
            }
          );

          const fieldSum1 = {
            industry: 0,
            oldBillingQty: 0,
            oldInstallation: 0,
            oldCollection: 0,
            oldOpeningStock: 0,
            openingStockDays: 0,
            closingOutStandingMonth_3: 0,
            closingOutStandingMonth_2: 0,
            closingOutStandingMonth_1: 0,
            outStandingDays: 0,
            bg: 0,
            bgLacsCurrentYear: 0,
            delayPlan: 0,
            billing: 0,
            installation: 0,
            collection: 0,
            BooksCollectionTarget: 0,
            t1: 0,
            t2: 0,
            t2_T1: 0,
            t3: 0,
            t3_T2: 0,
            t4: 0,
            t4_T3: 0,
            t5: 0,
            t5_T4: 0,
            t6: 0,
            t6_T5: 0,
            r1: 0,
            r2: 0,
            r3: 0,
            r4: 0,
            r5: 0,
            r6: 0,
            payout: 0,
            p1: 0,
            p2: 0,
            p3: 0,
            p4: 0,
            p5: 0,
            p6: 0,
            volume: 0,
            perTr: 0,
            amount: 0,
          };

          dataSource?.forEach(
            ({
              industry,
              oldBillingQty,
              oldInstallation,
              oldCollection,
              oldOpeningStock,
              openingStockDays,
              closingOutStandingMonth_3,
              closingOutStandingMonth_2,
              closingOutStandingMonth_1,
              outStandingDays,
              bg,
              bgLacsCurrentYear,
              delayPlan,
              billing,
              installation,
              collection,
              BooksCollectionTarget,
              t1,
              t2,
              t2_T1,
              t3,
              t3_T2,
              t4,
              t4_T3,
              t5,
              t5_T4,
              t6,
              t6_T5,
              r1,
              r2,
              r3,
              r4,
              r5,
              r6,
              payout,
              p1,
              p2,
              p3,
              p4,
              p5,
              p6,
              volume,
              perTr,
              amount,
            }) => {
              fieldSum1.industry += industry ? parseInt(industry) : 0;
              fieldSum1.oldBillingQty += oldBillingQty
                ? parseInt(oldBillingQty)
                : 0;
              fieldSum1.oldInstallation += oldInstallation
                ? parseInt(oldInstallation)
                : 0;
              fieldSum1.oldCollection += oldCollection
                ? parseInt(oldCollection)
                : 0;
              fieldSum1.oldOpeningStock += oldOpeningStock
                ? parseInt(oldOpeningStock)
                : 0;
              fieldSum1.openingStockDays += openingStockDays
                ? parseInt(openingStockDays)
                : 0;
              fieldSum1.closingOutStandingMonth_3 += closingOutStandingMonth_3
                ? closingOutStandingMonth_3
                : 0;
              fieldSum1.closingOutStandingMonth_2 += closingOutStandingMonth_2
                ? closingOutStandingMonth_2
                : 0;
              fieldSum1.closingOutStandingMonth_1 += closingOutStandingMonth_1
                ? closingOutStandingMonth_1
                : 0;
              fieldSum1.outStandingDays += outStandingDays
                ? parseInt(outStandingDays)
                : 0;
              fieldSum1.bg += bg ? parseInt(bg) : 0;
              fieldSum1.bgLacsCurrentYear += bgLacsCurrentYear
                ? parseInt(bgLacsCurrentYear)
                : 0;
              fieldSum1.delayPlan += delayPlan ? parseInt(delayPlan) : 0;
              fieldSum1.billing += billing ? parseInt(billing) : 0;
              fieldSum1.installation += installation ? parseInt(installation) : 0;
              fieldSum1.collection += collection ? parseInt(collection) : 0;
              fieldSum1.BooksCollectionTarget += BooksCollectionTarget
                ? parseInt(BooksCollectionTarget)
                : 0;
              fieldSum1.t1 += t1 ? parseInt(t1) : 0;
              fieldSum1.t2 += t2 ? parseInt(t2) : 0;
              fieldSum1.t2_T1 += t2_T1 ? parseInt(t2_T1) : 0;
              fieldSum1.t3 += t3 ? parseInt(t3) : 0;
              fieldSum1.t3_T2 += t3_T2 ? parseInt(t3_T2) : 0;
              fieldSum1.t4 += t4 ? parseInt(t4) : 0;
              fieldSum1.t4_T3 += t4_T3 ? parseInt(t4_T3) : 0;
              fieldSum1.t5 += t5 ? parseInt(t5) : 0;
              fieldSum1.t5_T4 += t5_T4 ? parseInt(t5_T4) : 0;
              fieldSum1.t6 += t6 ? parseInt(t6) : 0;
              fieldSum1.t6_T5 += t6_T5 ? parseInt(t6_T5) : 0;
              fieldSum1.r1 += r1 ? parseInt(r1) : 0;
              fieldSum1.r2 += r2 ? parseInt(r2) : 0;
              fieldSum1.r3 += r3 ? parseInt(r3) : 0;
              fieldSum1.r4 += r4 ? parseInt(r4) : 0;
              fieldSum1.r5 += r5 ? parseInt(r5) : 0;
              fieldSum1.r6 += r6 ? parseInt(r6) : 0;
              fieldSum1.payout += payout ? parseInt(payout) : 0;
              fieldSum1.p1 += p1 ? parseInt(p1) : 0;
              fieldSum1.p2 += p2 ? parseInt(p2) : 0;
              fieldSum1.p3 += p3 ? parseInt(p3) : 0;
              fieldSum1.p4 += p4 ? parseInt(p4) : 0;
              fieldSum1.p5 += p5 ? parseInt(p5) : 0;
              fieldSum1.p6 += p6 ? parseInt(p6) : 0;
              fieldSum1.volume += volume ? parseInt(volume) : 0;
              fieldSum1.perTr += perTr ? parseInt(perTr) : 0;
              fieldSum1.amount += amount ? parseInt(amount) : 0;
            }
          );
          return (
            <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
              <Table.Summary.Cell index={0} fixed>
                {" "}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} fixed>
                {" "}
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} fixed></Table.Summary.Cell>
              <Table.Summary.Cell index={3} fixed></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              {userRole != "SADM" && (
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
              )}
              {userRole != "NSH" ||
                userRole != "SADM" ||
                (userRole != "ZADM" && (
                  <Table.Summary.Cell index={99}></Table.Summary.Cell>
                ))}

              {userRole != "SADM" ||
                (userRole != "NSH" && (
                  <Table.Summary.Cell index={99}></Table.Summary.Cell>
                ))}

              {userRole === "ZADM" && (
                <Table.Summary.Cell index={99}></Table.Summary.Cell>
              )}

              <Table.Summary.Cell index={11}>
                {viewType == "hold" ? TagAwaitingHold(fieldSum?.industry, fieldSum?.holdIndustry) : viewType != "hold" && fieldSum?.industry || ""}
                {/* {fieldSum?.industry || "" || ""} */}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={12}>
                {fieldSum?.oldBillingQty || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={13}>
                {fieldSum?.oldInstallation || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={14}>
                {fieldSum?.oldCollection || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={15}>
                {fieldSum?.oldOpeningStock || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={16}>
                {fieldSum?.openingStockDays || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={17}>
                {fieldSum?.closingOutStandingMonth_3 || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={18}>
                {fieldSum?.closingOutStandingMonth_2 || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={19}>
                {fieldSum?.closingOutStandingMonth_1 || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={20}>
                {fieldSum?.outStandingDays || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={21}>
                {fieldSum?.bgLacsCurrentYear || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={22}>
                {viewType == "hold" ? TagAwaitingHold(fieldSum?.delayPlan, fieldSum?.holdDelayPlan) : viewType != "hold" && fieldSum?.delayPlan || ""}

              </Table.Summary.Cell>
              <Table.Summary.Cell index={23}>
                {viewType == "hold" ? TagAwaitingHold(fieldSum?.billing, fieldSum?.holdBilling) : viewType != "hold" && fieldSum?.billing || ""}

              </Table.Summary.Cell>
              <Table.Summary.Cell index={24}>
                {viewType == "hold" ? TagAwaitingHold(fieldSum?.installation, fieldSum?.holdInstallation) : viewType != "hold" && fieldSum?.installation || ""}

              </Table.Summary.Cell>
              <Table.Summary.Cell index={25}>
                {viewType == "hold" ? TagAwaitingHold(fieldSum?.collection, fieldSum?.holdCollection) : viewType != "hold" && fieldSum?.collection || ""}

              </Table.Summary.Cell>
              {/* holdClosingStock */}
              <Table.Summary.Cell index={26}>  {viewType == "hold" ? TagAwaitingHold(fieldSum?.closingStock, fieldSum?.holdClosingStock) : viewType != "hold" && fieldSum?.closingStock || ""}</Table.Summary.Cell>
              <Table.Summary.Cell index={27}>  {viewType == "hold" ? TagAwaitingHold(fieldSum?.closingOutStanding, fieldSum?.holdClosingOutstanding) : viewType != "hold" && fieldSum?.closingOutStanding || ""}</Table.Summary.Cell>
              <Table.Summary.Cell index={28}>
                {viewType == "hold" && fieldSum?.holdT1 ?
                  TagAwaitingHold(fieldSum?.t1, fieldSum?.holdT1)
                  : viewType != "hold" && fieldSum.t1 ? fieldSum.t1 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={29}>
                {viewType == "hold" && fieldSum?.holdT2 ? TagAwaitingHold(fieldSum?.t2, fieldSum?.holdT2) : viewType != "hold" && fieldSum.t2 ? fieldSum.t2 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={30}>
                {viewType == "hold" && fieldSum?.holdT2t1 ? TagAwaitingHold(fieldSum?.t2_T1, fieldSum?.holdT2t1) : viewType != "hold" && fieldSum.t2_T1 ? fieldSum.t2_T1 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={31}>
                {viewType == "hold" && fieldSum?.holdT3 ? TagAwaitingHold(fieldSum?.t3, fieldSum?.holdT3) : viewType != "hold" && fieldSum.t3 ? fieldSum.t3 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={32}>
                {viewType == "hold" && fieldSum?.holdT3t2 ? TagAwaitingHold(fieldSum?.t3_T2, fieldSum?.holdT3t2) : viewType != "hold" && fieldSum.t3_T2 ? fieldSum.t3_T2 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={33}>
                {viewType == "hold" && fieldSum?.holdT4 ? TagAwaitingHold(fieldSum?.t4, fieldSum?.holdT4) : viewType != "hold" && fieldSum.t4 ? fieldSum.t4 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={34}>
                {viewType == "hold" && fieldSum?.holdT4t3 ? TagAwaitingHold(fieldSum?.t4_T3, fieldSum?.holdT4t3) : viewType != "hold" && fieldSum.t4_T3 ? fieldSum.t4_T3 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={35}>
                {viewType == "hold" && fieldSum?.holdT5 ? TagAwaitingHold(fieldSum?.t5, fieldSum?.holdT5) : viewType != "hold" && fieldSum.t5 ? fieldSum.t5 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={36}>
                {viewType == "hold" && fieldSum?.holdT5t4 ? TagAwaitingHold(fieldSum?.t5_T4, fieldSum?.holdT5t4) : viewType != "hold" && fieldSum.t5_T4 ? fieldSum.t5_T4 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={37}>
                {viewType == "hold" && fieldSum?.holdT6 ? TagAwaitingHold(fieldSum?.t6, fieldSum?.holdT6) : viewType != "hold" && fieldSum.t6 ? fieldSum.t6 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={38}>
                {viewType == "hold" && fieldSum?.holdT6t5 ? TagAwaitingHold(fieldSum?.t6_T5, fieldSum?.holdT6t5) : viewType != "hold" && fieldSum.t6_T5 ? fieldSum.t6_T5 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={39}>
                {viewType == "hold" && fieldSum?.holdP1 ? TagAwaitingHold(Math.round(fieldSum.p1 / fieldSum.t1), Math.round(fieldSum.holdP1 / fieldSum.holdT1)) : viewType != "hold" && fieldSum.t1 && fieldSum.p1
                  ? Math.round(fieldSum.p1 / fieldSum.t1)
                  : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={40}>
                {viewType == "hold" && fieldSum?.holdP2 ? TagAwaitingHold(Math.round(fieldSum.p2 / fieldSum.t2), Math.round(fieldSum.holdP2 / fieldSum.holdT2)) : viewType != "hold" && fieldSum.t2 && fieldSum.p2
                  ? Math.round(fieldSum.p2 / fieldSum.t2)
                  : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={41}>
                {viewType == "hold" && fieldSum?.holdP3 ? TagAwaitingHold(Math.round(fieldSum.p3 / fieldSum.t3), Math.round(fieldSum.holdP3 / fieldSum.holdT3)) : viewType != "hold" && fieldSum.t3 && fieldSum.p3
                  ? Math.round(fieldSum.p3 / fieldSum.t3)
                  : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={42}>
                {viewType == "hold" && fieldSum?.holdP4 ? TagAwaitingHold(Math.round(fieldSum.p4 / fieldSum.t4), Math.round(fieldSum.holdP4 / fieldSum.holdT4)) : viewType != "hold" && fieldSum.t4 && fieldSum.p4
                  ? Math.round(fieldSum.p4 / fieldSum.t4)
                  : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={43}>
                {viewType == "hold" && fieldSum?.holdP5 ? TagAwaitingHold(Math.round(fieldSum.p5 / fieldSum.t5), Math.round(fieldSum.holdP5 / fieldSum.holdT5)) : viewType != "hold" && fieldSum.t5 && fieldSum.p5
                  ? Math.round(fieldSum.p5 / fieldSum.t5)
                  : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={44}>

                {viewType == "hold" && fieldSum?.holdP6 ? TagAwaitingHold(Math.round(fieldSum.p6 / fieldSum.t6), Math.round(fieldSum.holdP6 / fieldSum.holdT6)) :
                  viewType != "hold" && fieldSum.t6 && fieldSum.p6
                    ? Math.round(fieldSum.p6 / fieldSum.t6)
                    : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={45}>
                {viewType == "hold" && fieldSum?.holdP1 ? TagAwaitingHold(fieldSum.p1, fieldSum?.holdP1) : viewType != "hold" && fieldSum.p1 ? fieldSum.p1 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={46}>
                {viewType == "hold" && fieldSum?.holdP2 ? TagAwaitingHold(fieldSum.p2, fieldSum?.holdP2) : viewType != "hold" && fieldSum.p2 ? fieldSum.p2 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={47}>
                {viewType == "hold" && fieldSum?.holdP3 ? TagAwaitingHold(fieldSum.p3, fieldSum?.holdP3) : viewType != "hold" && fieldSum.p3 ? fieldSum.p3 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={48}>
                {viewType == "hold" && fieldSum?.holdP4 ? TagAwaitingHold(fieldSum.p4, fieldSum?.holdP4) : viewType != "hold" && fieldSum.p4 ? fieldSum.p4 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={49}>
                {viewType == "hold" && fieldSum?.holdP5 ? TagAwaitingHold(fieldSum.p5, fieldSum?.holdP5) : viewType != "hold" && fieldSum.p5 ? fieldSum.p5 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={50}>
                {viewType == "hold" && fieldSum?.holdP6 ? TagAwaitingHold(fieldSum.p6, fieldSum?.holdP6) : viewType != "hold" && fieldSum.p6 ? fieldSum.p6 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={51}>
                {viewType == "hold" ? TagAwaitingHold(fieldSum.volume, fieldSum?.holdVolume) : fieldSum.volume && viewType != "hold" ? fieldSum.volume : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={52}>
                {viewType === "hold" ? (
                  fieldSum.volume && fieldSum.holdVolume ? (
                    TagAwaitingHold(Math.round(fieldSum.amount / fieldSum.volume), Math.round(fieldSum.holdAmount / fieldSum.holdVolume)))
                    : (""))
                  : viewType !== "hold" ? (
                    fieldSum.volume ? (
                      Math.round(fieldSum.amount / fieldSum.volume)
                    ) : ("")) : ("")}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={53}>
                {viewType == "hold" ? TagAwaitingHold(fieldSum.amount, fieldSum?.holdAmount) : fieldSum.amount && viewType != "hold" ? fieldSum.amount : ""}
                {/* {fieldSum.amount ? fieldSum.amount : ""} */}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={54}></Table.Summary.Cell>

              <Table.Summary.Cell index={55}></Table.Summary.Cell>
              <Table.Summary.Cell index={56}></Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        columns={columns}
        pagination={false}
        onChange={handleChange}
        dataSource={dataSource}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, selectedRow) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSchemeIdNo(selectedRow);
            setSelectedRows(selectedRow.map((item) => item.schemeId));
          },
        }}
        footer={() =>
          viewType == "hold" ? <div className="pl-2"><LegendHold /></div> : <></>
        }
        scroll={{
          x: 7000,
          y: 100,
        }}
      />
    </>
  );
};
export default AwaitingApprovalListCollectionDetails;
