export const parseFiveDigitNumber=(input)=> {
  // Convert input to a string in case it's not already
  if(input){
  const strInput = input?.toString()?.trim();

  // Extract the first 5 characters
  const trimmedInput = strInput.length > 5 ? strInput.slice(0, 5) : strInput;

  // Convert to an integer
  const num = parseInt(trimmedInput, 10);

  // Check if the result is a valid number
  if (!isNaN(num)) {
      return num;
  } else {
      return null; // or handle the error as needed
  }
}else{
  return null
}
}
export const collectionSchemeUpload = (props) => {
  let {
    parseData,
    formikData,
    collectionLock,
    calArr,
    previousYearSameMonthString,
    
    selectedSecondMonthString,
    selectedFirstMonthString,
  } = props;

 switch(collectionLock){
  case "t6":
  return formikData?.map((y, index) => {
    if (parseData.find((x) => x["DEALER CODE"] === y?.code)) {

      //TOP SLAB
      let z = parseData.find((x) => x["DEALER CODE"] === y?.code);
     let dropdownscheme= z?.["TOP SLAB"]?.toUpperCase()=="T6"?["t1","t2","t3","t4","t5","t6"]:
        z?.["TOP SLAB"]?.toUpperCase()=="T5"?["t1","t2","t3","t4","t5"]:
        z?.["TOP SLAB"]?.toUpperCase()=="T4"?["t1","t2","t3","t4"]:
        z?.["TOP SLAB"]?.toUpperCase()=="T3"?["t1","t2","t3"]:
        z?.["TOP SLAB"]?.toUpperCase()=="T2"?["t1","t2"]:
        z?.["TOP SLAB"]?.toUpperCase()=="T1"?["t1"]:[]

       let paget1=dropdownscheme.includes("t1"||"t2"||"t3"||"t4"||"t5"||"t6") 
       let paget2=dropdownscheme.includes("t2"||"t3"||"t4"||"t5"||"t6") 
       let paget3=dropdownscheme.includes("t3"||"t4"||"t5"||"t6") 
       let paget4=dropdownscheme.includes("t4"||"t5"||"t6") 
       let paget5=dropdownscheme.includes("t5"||"t6") 
       let paget6=dropdownscheme.includes("t6") 

        let getT6Include=y?.dropdownscheme.includes("t6")
        let getT5Include=y?.dropdownscheme.includes("t5")
        let getT4Include=y?.dropdownscheme.includes("t4")
        let getT3Include=y?.dropdownscheme.includes("t3")
        let getT2Include=y?.dropdownscheme.includes("t2")
        let getT1Include=y?.dropdownscheme.includes("t1")
        calArr.push(index);
        let r1 = paget1?parseFiveDigitNumber(z?.["PAYOUT RATE"]): getT1Include?parseFiveDigitNumber(z?.["PAYOUT RATE"]) : 0; //120
        let r2 = paget2?parseFiveDigitNumber(z?.["__EMPTY_14"]):getT2Include?parseFiveDigitNumber(z?.["__EMPTY_14"]) :0; //130
        let r3 = paget3?parseFiveDigitNumber(z?.["__EMPTY_15"]):getT3Include?parseFiveDigitNumber(z?.["__EMPTY_15"]) :0; //140
        let r4 = paget4?parseFiveDigitNumber(z?.["__EMPTY_16"]):getT4Include?parseFiveDigitNumber(z?.["__EMPTY_16"]) : 0; //150
        let r5 = paget5?parseFiveDigitNumber(z?.["__EMPTY_17"]):getT5Include?parseFiveDigitNumber(z?.["__EMPTY_17"]) : 0; //160
        let r6 = paget6?parseFiveDigitNumber(z?.["__EMPTY_18"]):getT6Include?parseFiveDigitNumber(z?.["__EMPTY_18"]) : 0; //170
  
        let t1 = paget1?parseInt(z?.["BOOKS COLLECTION TARGETS (NO. OF TRS.)"]):getT1Include?parseInt(z?.["BOOKS COLLECTION TARGETS (NO. OF TRS.)"]) : 0; //60
        let t2 = paget2?parseInt(z?.["__EMPTY_9"]) :getT2Include?parseInt(z?.["__EMPTY_9"]) : 0; //70
        let t3 = paget3?parseInt(z?.["__EMPTY_10"]) :getT3Include?parseInt(z?.["__EMPTY_10"]) : 0; //80
        let t4 = paget4?parseInt(z?.["__EMPTY_11"]) :getT4Include?parseInt(z?.["__EMPTY_11"]) : 0; //90
        let t5 = paget5?parseInt(z?.["__EMPTY_12"]) :getT5Include?parseInt(z?.["__EMPTY_12"]) : 0; //100
        let t6 = paget6?parseInt(z?.["__EMPTY_13"])  :getT6Include?parseInt(z?.["__EMPTY_13"]) : 0; //110
      return {
        ...y,
        industry: parseInt(z?.__EMPTY) || 0, //10
        dropdownscheme:dropdownscheme,
        installation: parseInt(z?.["__EMPTY_5"]) || 0, //50
        collection: parseInt(z?.__EMPTY_6) || 0, //60

        industry: parseInt(z?.[`${previousYearSameMonthString}`]) || 0, //10
        billing:
          parseInt(
            z?.[
            `${selectedFirstMonthString} -${selectedSecondMonthString} NO'S`
            ]
          ) || 0, //20
        installation: parseInt(z?.["__EMPTY_7"]) || 0, //30
        collection: parseInt(z?.["__EMPTY_8"]) || 0, //40

        delayPlan: parseInt(z?.["Installation Plan"]) || 0, //50
        BooksCollectionTargetT1: dropdownscheme.includes("t1"||"t2"||"t3"||"t4"||"t5"||"t6") ? t1 : 0,
        BooksCollectionTargetT2: dropdownscheme.includes("t2"||"t3"||"t4"||"t5"||"t6") ? t2 : 0,//70
        BooksCollectionTargetT3: dropdownscheme.includes("t3"||"t4"||"t5"||"t6") ? t3 : 0, //80
        BooksCollectionTargetT4: dropdownscheme.includes("t4"||"t5"||"t6") ? t4 : 0, //90
        BooksCollectionTargetT5: dropdownscheme.includes("t5"||"t6") ? t5 :  0, //100
        BooksCollectionTargetT6: dropdownscheme.includes("t6") ? t6 : 0, //110
        PayoutRatep1: dropdownscheme.includes("t1"||"t2"||"t3"||"t4"||"t5"||"t6") ? r1 : 0, //120
        PayoutRatep2: dropdownscheme.includes("t2"||"t3"||"t4"||"t5"||"t6") ? r2 : 0, //130
        PayoutRatep3: dropdownscheme.includes("t3"||"t4"||"t5"||"t6") ? r3 : 0, //140
        PayoutRatep4: dropdownscheme.includes("t4"||"t5"||"t6") ? r4 : 0, //150
        PayoutRatep5: dropdownscheme.includes("t5"||"t6") ? r5 : 0, //160
        PayoutRatep6: dropdownscheme.includes("t6") ? r6 : 0, //170
        reason: z?.["REMARKS"], //test
        TEPvol: parseInt(z?.["Expected Volume"]) || 0, //130
      };
    } else {
      return y;
    }
  });

  case "t5":
    return formikData?.map((y, index) => {
      if (parseData.find((x) => x["DEALER CODE"] === y?.code)) {
        let z = parseData.find((x) => x["DEALER CODE"] === y?.code);
      let getT5Include=y?.dropdownscheme.includes("t5")
      let getT4Include=y?.dropdownscheme.includes("t4")
      let getT3Include=y?.dropdownscheme.includes("t3")
      let getT2Include=y?.dropdownscheme.includes("t2")
      let getT1Include=y?.dropdownscheme.includes("t1")
        calArr.push(index);
        let r1 = getT1Include?parseFiveDigitNumber(z?.["PAYOUT RATE"]): 0; //120
        let r2 = getT2Include?parseFiveDigitNumber(z?.["__EMPTY_13"]) : 0; //130
        let r3 = getT3Include?parseFiveDigitNumber(z?.["__EMPTY_14"]) : 0; //140
        let r4 = getT4Include?parseFiveDigitNumber(z?.["__EMPTY_15"]) : 0; //150
        let r5 = getT5Include?parseFiveDigitNumber(z?.["__EMPTY_16"]) : 0; //160
        let r6 =  0; //170
  
        let t1 = getT1Include?parseInt(z?.["BOOKS COLLECTION TARGETS (NO. OF TRS.)"]) : 0; //60
        let t2 = getT2Include?parseInt(z?.["__EMPTY_9"]) : 0; //70
        let t3 = getT3Include?parseInt(z?.["__EMPTY_10"]): 0; //80
        let t4 = getT4Include?parseInt(z?.["__EMPTY_11"]): 0; //90
        let t5 = getT5Include?parseInt(z?.["__EMPTY_12"]): 0; //100
        let t6 = 0; //110
        let dropdownscheme= z?.["TOP SLAB"]?.toUpperCase()=="T5"?["t1","t2","t3","t4","t5"]:
        z?.["TOP SLAB"]?.toUpperCase()=="T4"?["t1","t2","t3","t4"]:
        z?.["TOP SLAB"]?.toUpperCase()=="T3"?["t1","t2","t3"]:
        z?.["TOP SLAB"]?.toUpperCase()=="T2"?["t1","t2"]:
        z?.["TOP SLAB"]?.toUpperCase()=="T1"?["t1"]:[]
        return {
          ...y,
          industry: parseInt(z?.__EMPTY) || 0, //10          
          installation: parseInt(z?.["__EMPTY_5"]) || 0, //50
          collection: parseInt(z?.__EMPTY_6) || 0, //60
          dropdownscheme:dropdownscheme,
          industry: parseInt(z?.[`${previousYearSameMonthString}`]) || 0, //10
          billing:
            parseInt(
              z?.[
              `${selectedFirstMonthString} -${selectedSecondMonthString} NO'S`
              ]
            ) || 0, //20
          installation: parseInt(z?.["__EMPTY_7"]) || 0, //30
          collection: parseInt(z?.["__EMPTY_8"]) || 0, //40
  
          delayPlan: parseInt(z?.["Installation Plan"]) || 0, //50
          BooksCollectionTargetT1: dropdownscheme.includes("t1"||"t2"||"t3"||"t4"||"t5") ? t1 : 0,
          BooksCollectionTargetT2: dropdownscheme.includes("t2"||"t3"||"t4"||"t5") ? t2 : 0,//70
          BooksCollectionTargetT3: dropdownscheme.includes("t3"||"t4"||"t5") ? t3 : 0, //80
          BooksCollectionTargetT4: dropdownscheme.includes("t4"||"t5") ? t4 : 0, //90
          BooksCollectionTargetT5: dropdownscheme.includes("t5") ? t5 :  0, //100
          BooksCollectionTargetT6:  0, //110
          PayoutRatep1: dropdownscheme.includes("t1"||"t2"||"t3"||"t4"||"t5") ? r1 : 0, //120
          PayoutRatep2: dropdownscheme.includes("t2"||"t3"||"t4"||"t5") ? r2 : 0, //130
          PayoutRatep3: dropdownscheme.includes("t3"||"t4"||"t5") ? r3 : 0, //140
          PayoutRatep4: dropdownscheme.includes("t4"||"t5") ? r4 : 0, //150
          PayoutRatep5: dropdownscheme.includes("t5") ? r5 : 0, //160
          PayoutRatep6:  0, //170
          reason: z?.["REMARKS"], //test
          TEPvol: parseInt(z?.["Expected Volume"]) || 0, //130
        };
      } else {
        return y;
      }
    });

case "t4":
  return formikData?.map((y, index) => {
    if (parseData.find((x) => x["DEALER CODE"] === y?.code)) {
      let z = parseData.find((x) => x["DEALER CODE"] === y?.code);
      let getT4Include=y?.dropdownscheme.includes("t4")
      let getT3Include=y?.dropdownscheme.includes("t3")
      let getT2Include=y?.dropdownscheme.includes("t2")
      let getT1Include=y?.dropdownscheme.includes("t1")
      calArr.push(index);
      let r1 = getT1Include?parseFiveDigitNumber(z?.["PAYOUT RATE"]): 0; //120
      let r2 = getT2Include?parseFiveDigitNumber(z?.["__EMPTY_12"])  :0; //130
      let r3 = getT3Include?parseFiveDigitNumber(z?.["__EMPTY_13"])  :0; //140
      let r4 = getT4Include?parseFiveDigitNumber(z?.["__EMPTY_14"]) : 0; //150
      let r5 =  0; //160
      let r6 =  0; //170

      let t1 =  getT1Include?parseInt(z?.["BOOKS COLLECTION TARGETS (NO. OF TRS.)"]) : 0; //60
      let t2 =  getT2Include?parseInt(z?.["__EMPTY_9"]) :0; //70
      let t3 =  getT3Include?parseInt(z?.["__EMPTY_10"]): 0; //80
      let t4 =  getT4Include?parseInt(z?.["__EMPTY_11"]): 0; //90
      let t5 =  0; //100
      let t6 =  0; //110
      let dropdownscheme=z?.["TOP SLAB"]?.toUpperCase()=="T4"?["t1","t2","t3","t4"]:
      z?.["TOP SLAB"]?.toUpperCase()=="T3"?["t1","t2","t3"]:
      z?.["TOP SLAB"]?.toUpperCase()=="T2"?["t1","t2"]:
      z?.["TOP SLAB"]?.toUpperCase()=="T1"?["t1"]:[]
      return {
        ...y,
        industry: parseInt(z?.__EMPTY) || 0, //10
        dropdownscheme:dropdownscheme,
        collection: parseInt(z?.__EMPTY_6) || 0, //60

        industry: parseInt(z?.[`${previousYearSameMonthString}`]) || 0, //10
        billing:
          parseInt(
            z?.[
            `${selectedFirstMonthString} -${selectedSecondMonthString} NO'S`
            ]
          ) || 0, //20
        installation: parseInt(z?.["__EMPTY_7"]) || 0, //30
        collection: parseInt(z?.["__EMPTY_8"]) || 0, //40

        delayPlan: parseInt(z?.["Installation Plan"]) || 0, //50
        BooksCollectionTargetT1: dropdownscheme.includes("t1"||"t2"||"t3"||"t4") ? t1 : 0,
        BooksCollectionTargetT2: dropdownscheme.includes("t2"||"t3"||"t4") ? t2 : 0,//70
        BooksCollectionTargetT3: dropdownscheme.includes("t3"||"t4") ? t3 : 0, //80
        BooksCollectionTargetT4: dropdownscheme.includes("t4") ? t4 : 0, //90
        BooksCollectionTargetT5: 0, //100
        BooksCollectionTargetT6:  0, //110
        PayoutRatep1: dropdownscheme.includes("t1"||"t2"||"t3"||"t4") ? r1 : 0, //120
        PayoutRatep2: dropdownscheme.includes("t2"||"t3"||"t4") ? r2 : 0, //130
        PayoutRatep3: dropdownscheme.includes("t3"||"t4") ? r3 : 0, //140
        PayoutRatep4: dropdownscheme.includes("t4") ? r4 : 0, //150
        PayoutRatep5:  0, //160
        PayoutRatep6:  0, //170
        reason: z?.["REMARKS"], //test
        TEPvol: parseInt(z?.["Expected Volume"]) || 0, //130
      };
    } else {
      return y;
    }
  });

  
  case "t3":
    return formikData?.map((y, index) => {
      if (parseData.find((x) => x["DEALER CODE"] === y?.code)) {
        let z = parseData.find((x) => x["DEALER CODE"] === y?.code);
      let getT3Include=y?.dropdownscheme.includes("t3")
      let getT2Include=y?.dropdownscheme.includes("t2")
      let getT1Include=y?.dropdownscheme.includes("t1")
        calArr.push(index);
        let r1 = getT1Include?parseFiveDigitNumber(z?.["PAYOUT RATE"]): 0; //120
        let r2 = getT2Include?parseFiveDigitNumber(z?.["__EMPTY_11"]) :0; //130
        let r3 = getT3Include?parseFiveDigitNumber(z?.["__EMPTY_12"]) :0; //140
        let r4 =  0; //150
        let r5 =  0; //160
        let r6 =  0; //170
  
        let t1 = parseInt(z?.["BOOKS COLLECTION TARGETS (NO. OF TRS.)"]) || 0; //60
        let t2 = parseInt(z?.["__EMPTY_9"]) || 0; //70
        let t3 = parseInt(z?.["__EMPTY_10"]) || 0; //80
        let t4 =  0; //90
        let t5 =  0; //100
        let t6 =  0; //110
        let dropdownscheme=z?.["TOP SLAB"]?.toUpperCase()=="T3"?["t1","t2","t3"]:
        z?.["TOP SLAB"]?.toUpperCase()=="T2"?["t1","t2"]:
        z?.["TOP SLAB"]?.toUpperCase()=="T1"?["t1"]:[]
        return {
          ...y,
          industry: parseInt(z?.__EMPTY) || 0, //10          
          installation: parseInt(z?.["__EMPTY_5"]) || 0, //50
          collection: parseInt(z?.__EMPTY_6) || 0, //60
  
          industry: parseInt(z?.[`${previousYearSameMonthString}`]) || 0, //10
          billing:
            parseInt(
              z?.[
              `${selectedFirstMonthString} -${selectedSecondMonthString} NO'S`
              ]
            ) || 0, //20
          installation: parseInt(z?.["__EMPTY_7"]) || 0, //30
          collection: parseInt(z?.["__EMPTY_8"]) || 0, //40
  
          delayPlan: parseInt(z?.["Installation Plan"]) || 0, //50
          BooksCollectionTargetT1: dropdownscheme.includes("t1"||"t2"||"t3") ? t1 : 0,
          BooksCollectionTargetT2: dropdownscheme.includes("t2"||"t3") ? t2 : 0,//70
          BooksCollectionTargetT3: dropdownscheme.includes("t3") ? t3 : 0, //80
          BooksCollectionTargetT4: 0, //90
          BooksCollectionTargetT5: 0, //100
          BooksCollectionTargetT6:  0, //110
          PayoutRatep1: dropdownscheme.includes("t1"||"t2"||"t3") ? r1 : 0, //120
          PayoutRatep2: dropdownscheme.includes("t2"||"t3") ? r2 : 0, //130
          PayoutRatep3: dropdownscheme.includes("t3") ? r3 : 0, //140
          PayoutRatep4:  0, //150
          PayoutRatep5:  0, //160
          PayoutRatep6:  0, //170
          dropdownscheme:dropdownscheme,
          reason: z?.["REMARKS"], //test
          TEPvol: parseInt(z?.["Expected Volume"]) || 0, //130
        };
      } else {
        return y;
      }
    });
    default:
      return
 }


 
}
