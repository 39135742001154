import { Button, Table, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import {
  columnWidth,
  inputColumnWidth,
  columnWidth150,
  smallColumnWidth,
  amountCheckIN,
  checkDisableText,
  TableSummaryInput,
  DummyTableCollectionSummary,
  columnWidth120,
  columnWidth220,
  DisableShow,
} from "../widthTable.jsx";

//t1,modifiedT1,historyT1

//status:1?"t1:modifiedT1"
//if hold and approved historyT1:t1
//status 2&&historyT1 t1:modifiedT1
//if rejected t1:historyT1

export const validationStatus = (data) => {
  return data?.status == 1 ? true : false;
};
export const validationEdit  = (data) => {
  return (data?.status == 2 && data?.isEditonHold == true)? true : false;
};
export const validationStatus3 = (data) => {
  return ((data?.schemeTypeId == 1 || data?.schemeTypeId == 2) &&
    !data?.historyT1) ||
    (data?.schemeTypeId == 3 && !data?.historyT1) ||
    ((data?.schemeTypeId == 4 ||
      data?.schemeTypeId == 5 ||
      data?.schemeTypeId == 8) &&
      !data?.installationTarget)
    ? true
    : false;
};
export const validationStatus1 = (data) => (data?.status == 2 ? true : false);
export const validationStatus2 = (data) => (data?.status == 5 ? true : false);
export const TagUnholdData = (x, y, data) => {
  return data ? (
    <DisableShow />
  ) : (
    <div className="d-flex justify-content-center">
      <Tag color="red">{x || 0}</Tag>
      <Tag color={x != y ? "green" : "red"}>{y || 0}</Tag>
    </div>
  );
};

const HoldTableCollectionView = (props) => {
  const {
    showCreatedBy,
    showRemark,
    showCreatedDate,
    disableSort,
    showHoldComment,
  } = props;

  const getCreatedName = () =>
    showCreatedBy
      ? [
          {
            title: (
              <div>
                CREATED <br /> BY
              </div>
            ),
            dataIndex: "createdBy",
            ...columnWidth150,
            sorter: disableSort ? false : true,
            ellipsis: false,
            tableTooltip: (record) => record.createdBy,
            defaultSortOrder: "ascend",
            align: "center",
            key: "createdBy",
            // shouldCellUpdate: (record, prevRecord) =>
            //   record.createdBy !== prevRecord.createdBy,
            render: (data) => <Tooltip title={data}>{data}</Tooltip>,
          },
        ]
      : [];

  const getRemarks = () =>
    showHoldComment
      ? [
          {
            title: "",
            children: [
              {
                title: "REMARKS",
                align: "center",
                dataIndex: "reason",
                ellipsis: {
                  showTitle: false,
                },
                // shouldCellUpdate: (record, prevRecord) =>
                //   record.reason !== prevRecord.reason,
                // render: (data) => <Tooltip title={data}>{data}</Tooltip>,
                render: (_, data) => {
                  const simple = data?.reason;
                  const modified = data?.modifiedReason;
                  const history = data?.historyReason;
                  return validationEdit(data) ? (
                    <div>{history}</div>
                  ) :<div>{simple}</div>
                },
                ...columnWidth,
              },
              {
                title: "MODIFIED REMARKS",
                dataIndex: "modifiedRemarks",
                key: "modifiedRemarks",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                shouldCellUpdate: (record, prevRecord) =>
                  record.modifiedRemarks !== prevRecord.modifiedRemarks,
                render: (data) => <Tooltip title={data}>{data || ""}</Tooltip>,
                ...columnWidth,
              },
              {
                title: "HOLD REASON",
                dataIndex: "modifiedReason",
                key: "modifiedReason",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                // shouldCellUpdate: (record, prevRecord) =>
                //   record.modifiedReason !== prevRecord.modifiedReason,
                render: (data) => <Tooltip title={data}>{data || ""}</Tooltip>,
                ...columnWidth,
              },
            ],
          },
        ]
      : showRemark
      ? [
          {
            title: "REMARKS",
            align: "center",
            dataIndex: "reason",
            ellipsis: {
              showTitle: false,
            },
            // shouldCellUpdate: (record, prevRecord) =>
            //   record.reason !== prevRecord.reason,
            render: (data) => <Tooltip title={data}>{data}</Tooltip>,
            ...columnWidth,
          },
        ]
      : [];

  const getCreatedDate = () =>
    showCreatedDate
      ? [
          {
            title: (
              <div>
                Created <br />
                Date
              </div>
            ),
            dataIndex: "createdDate",
            ...columnWidth150,
            sorter: disableSort ? false : true,
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "createdDate",
            // shouldCellUpdate: (record, prevRecord) =>
            //   record.createdDate !== prevRecord.createdDate,
            render: (data) => <Tooltip title={data}>{data}</Tooltip>,
          },
        ]
      : [];

  return [
    {
      title: (
        <div>
          SCHEME <br /> NO
        </div>
      ),
      dataIndex: "schemeNo",
      fixed: "left",
      sorter: disableSort ? false : true,
      ...columnWidth,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "descend",
      key: "SchemeNo",
      shouldCellUpdate: (record, prevRecord) =>
        record.schemeNo !== prevRecord.schemeNo,
      render: (item, record) => {
        let color = " ";
        if (record?.isEditonHold == false) {
          color = "#9999ff";
        } else {
          switch (record.status) {
            case 0:
              color = "#FCE838"; //inProgress
              break;
            case 1:
              color = "#FCE838"; //inProgress
              break;
            case 2:
              color = "#51DE4E"; //Approved
              break;
            case 3:
              color = "#9999ff"; //Withdraw
              break;
            case 4:
              color = "#FF3232"; //Cancel
              break;
            case 5:
              color = "#FF3232"; //Reject
              break;
            case 7:
              color = "#bcb38d"; //Deviation
              break;
            case 8:
              color = "rgb(255, 0, 255)"; //Auto Reject
              break;
            case 9:
              color = "#9999ff"; //hold
              break;
            default:
              color = "#FCE838";
              break;
          }
        }

        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item}
          </Tag>
        );
      },
    },
    {
      title: (
        <div className="pl-4">
          DEALER <br /> CODE
        </div>
      ),
      dataIndex: "dealerCode",
      fixed: "left",
      sorter: disableSort ? false : true,
      ...columnWidth150,
      align: "center",
      defaultSortOrder: "descend",
      key: "DealerCode",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: (
        <div className="pl-4">
          DEALER <br /> LOCATION
        </div>
      ),
      dataIndex: "dealerLocation",
      ...columnWidth150,
      fixed: "left",
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "descend",
      key: "DealerLocation",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      // shouldCellUpdate: (record, prevRecord) =>
      //   record.DealerLocation !== prevRecord.DealerLocation,
    },
    {
      title: (
        <div className="pl-4">
          DEALER <br /> NAME
        </div>
      ),
      dataIndex: "dealerName",
      ...columnWidth150,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "DealerName",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      // shouldCellUpdate: (record, prevRecord) =>
      //   record.DealerName !== prevRecord.DealerName,
    },
    {
      title: "STATE",
      dataIndex: "stateName",
      ...columnWidth150,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "StateName",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      // shouldCellUpdate: (record, prevRecord) =>
      //   record.stateName !== prevRecord.stateName,
    },
    ...getCreatedDate(),
    {
      title: (
        <div>
          TERRITORY <br /> MANAGER
        </div>
      ),
      dataIndex: "territoryManager",
      ...columnWidth150,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "TerritoryManager",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      // shouldCellUpdate: (record, prevRecord) =>
      //   record.territoryManager !== prevRecord.territoryManager,
    },
    {
      title: (
        <div>
          TOP <br /> SLAB
        </div>
      ),
      dataIndex: "topTarget",
      ...columnWidth150,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "topTarget",
      shouldCellUpdate: (record, prevRecord) =>
        record.topTarget !== prevRecord.topTarget,
      render: (_, data) => {
        const simple =
          data?.topTarget
            ?.split(",")
            [data?.topTarget?.split(",")?.length - 1].toUpperCase() ||
          "t1".toUpperCase();
        const modified =
          data?.modifiedTopTarget
            ?.split(",")
            [data?.modifiedTopTarget?.split(",")?.length - 1].toUpperCase() ||
          "t1".toUpperCase();
        const history =
          data?.historyTopTarget
            ?.split(",")
            [data?.historyTopTarget?.split(",")?.length - 1].toUpperCase() ||
          "t1".toUpperCase();
        return validationStatus(data) ? (
          TagUnholdData(simple, modified)
        ) : validationStatus3(data) ? (
          TagUnholdData(simple, modified)
        ) : validationStatus1(data) ? (
          TagUnholdData(history, simple)
        ) : validationStatus2(data) ? (
          TagUnholdData(simple, history)
        ) : (
          <div>{simple}</div>
        );
      },
    },
    ...getCreatedName(),
    {
      title: (
        <div>
          START <br /> MONTH
        </div>
      ),
      dataIndex: "startMonth",
      key: "startMonth",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      ...columnWidth150,
      shouldCellUpdate: (record, prevRecord) =>
        record.startMonth !== prevRecord.startMonth,
      render: (data) => dayjs(data).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      key: "endMonth",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.endMonth !== prevRecord.endMonth,
      ...columnWidth150,
      render: (data) => dayjs(data).format("MMM'YY"),
    },
    {
      title: "INDUSTRY",
      dataIndex: "industry",
      key: "industry",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.industry !== prevRecord.industry,
      ...smallColumnWidth,
      // render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      render: (_, data) => {
        const simple = data?.industry;
        const modified = data?.modifiedIndustry;
        const history = data?.historyIndustry;
        return validationStatus(data) ? (
          TagUnholdData(simple, modified)
        ) : validationStatus3(data) ? (
          TagUnholdData(simple, modified)
        ) : validationStatus1(data) ? (
          TagUnholdData(history, simple)
        ) : validationStatus2(data) ? (
          TagUnholdData(simple, history)
        ) : (
          <div>{simple}</div>
        );
      },
    },
    {
      title: "B",
      dataIndex: "oldBillingQty",
      key: "oldBillingQty",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.oldBillingQty !== prevRecord.oldBillingQty,
      ...inputColumnWidth,
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: "I",
      dataIndex: "oldInstallation",
      key: "oldInstallation",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.oldInstallation !== prevRecord.oldInstallation,
      ...inputColumnWidth,
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: "C",
      dataIndex: "oldCollection",
      key: "oldCollection",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.oldCollection !== prevRecord.oldCollection,
      ...inputColumnWidth,
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: ` OPENING STOCK`,
      dataIndex: "stock",

      children: [
        {
          title: "Nos",
          dataIndex: "oldOpeningStock",
          key: "oldOpeningStock",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          ...columnWidth150,
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.oldOpeningStock !== prevRecord.oldOpeningStock,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
        {
          title: "Days",
          dataIndex: "openingStockDays",
          key: "openingStockDays",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.openingStockDays !== prevRecord.openingStockDays,
          ...columnWidth150,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
      ],
    },
    {
      title: "CLOSING OS. (IN LACS)",
      dataIndex: "stock",

      textAlign: "center",

      children: [
        {
          title: (
            <span>
              Last{" "}
              <span className="ordinal-title">
                3<sup className="ordinal-suffix">rd</sup>
              </span>{" "}
              Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_3",
          key: "closingOutStandingMonth_3",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.closingOutStandingMonth_3 !==
          //   prevRecord.closingOutStandingMonth_3,
          ...columnWidth150,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
        {
          title: (
            <span>
              Last{" "}
              <span className="ordinal-title">
                2<sup className="ordinal-suffix">nd</sup>
              </span>{" "}
              Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_2",
          key: "closingOutStandingMonth_2",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          ...columnWidth150,
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.closingOutStandingMonth_2 !==
          //   prevRecord.closingOutStandingMonth_2,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
        {
          title: (
            <span>
              Last  Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_1",
          key: "closingOutStandingMonth_1",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.closingOutStandingMonth_1 !==
          //   prevRecord.closingOutStandingMonth_1,
          ...columnWidth150,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
        // {
        //   title: (
        //     <span>
        //       Last{" "}
        //       <span className="ordinal-title">
        //         1<sup className="ordinal-suffix">st</sup>
        //       </span>{" "}
        //       Month Days
        //     </span>
        //   ),
        //   dataIndex: "outStandingDays",
        //   key: "outStandingDays",
        //   align: "center",
        //   ellipsis: {
        //     showTitle: false,
        //   },
        //   // shouldCellUpdate: (record, prevRecord) =>
        //   //   record.outStandingDays !== prevRecord.outStandingDays,
        //   ...columnWidth150,
        //   render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        // },
      ],
    },
    {
      title: "Closing OS Days",
      dataIndex: "outStandingDays",
      children: [
        {
          title: "OS Last Month",
          dataIndex: "outStandingDays",
          key: "outStandingDays",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          ...columnWidth150,
          render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        },
      ],
    },
    {
      title: "BG",
      dataIndex: "bg",
      children: [
        {
          title: "Lacs",
          dataIndex: "bGLacsCurrentYear",
          key: "bGLacsCurrentYear",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.bGLacsCurrentYear !== prevRecord.bGLacsCurrentYear,
          ...columnWidth150,
          render: (data) => <Tooltip title={data}>{data || 0}</Tooltip>,
        },
      ],
    },
    {
      title: "INSTALLATION PLAN",
      children: [
        {
          title: "NOS",
          dataIndex: "delayPlan",
          key: "delayPlan",
          ...columnWidth150,
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.delayPlan !== prevRecord.delayPlan,
          // render: (data) => amountCheckIN(data),
          render: (_, data) => {
            const simple = data?.delayPlan;
            const modified = data?.modifiedDelayPlan;
            const history = data?.historyDelayPlan;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history)
            ) : (
              <div>{simple}</div>
            );
          },
        },
      ],
    },
    {
      title: <div>BILLING DETAILS</div>,
      children: [
        {
          title: "B",
          dataIndex: "billing",
          key: "billing",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.billing !== prevRecord.billing,
          // render: (data) => amountCheckIN(data),
          render: (_, data) => {
            const simple = data?.billing;
            const modified = data?.modifiedBilling;
            const history = data?.historyBilling;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "I",
          dataIndex: "installation",
          key: "installation",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.installation !== prevRecord.installation,
          render: (_, data) => {
            const simple = data?.installation;
            const modified = data?.modifiedInstallation;
            const history = data?.historyInstallation;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "C",
          dataIndex: "collection",
          key: "collection",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.collection !== prevRecord.collection,
          render: (_, data) => {
            const simple = data?.collection;
            const modified = data?.modifiedCollection;
            const history = data?.historyCollection;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
      ],
    },
    {
      title: "Closing Stock",
      dataIndex: "closingStock",
      key: "closingStock",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.closingStock !== prevRecord.closingStock,
      render: (_, data) => {
        const simple = data?.closingStock;
        const modified = data?.modifiedclosingStock;
        const history = data?.historyClosingStock;
        return validationStatus(data) ? (
          TagUnholdData(simple, modified)
        ) : validationStatus3(data) ? (
          TagUnholdData(simple, modified)
        ) : validationStatus1(data) ? (
          TagUnholdData(history, simple)
        ) : validationStatus2(data) ? (
          TagUnholdData(simple, history)
        ) : (
          <div>{simple}</div>
        );
      },
      ...columnWidth150,
    },

    {
      title: "Closing OutStanding (In Lacs)",
      dataIndex: "closingOutStanding",
      key: "closingOutStanding",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      shouldCellUpdate: (record, prevRecord) =>
        record.closingOutStanding !== prevRecord.closingOutStanding,
      render: (_, data) => {
        const simple = data?.closingOutStanding;
        const modified = data?.modifiedclosingOutStanding;
        const history = data?.historyClosingOutstanding;
        return validationStatus(data) ? (
          TagUnholdData(simple, modified)
        ) : validationStatus3(data) ? (
          TagUnholdData(simple, modified)
        ) : validationStatus1(data) ? (
          TagUnholdData(history, simple)
        ) : validationStatus2(data) ? (
          TagUnholdData(simple, history)
        ) : (
          <div>{simple}</div>
        );
      },
      ...columnWidth220,
    },
    {
      title: "BOOKS COLLECTION TARGETS (NO. OF TRS.)",
      children: [
        {
          title: "T1",
          dataIndex: "t1",
          key: "t1",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.t1 !== prevRecord.t1,
          render: (_, data) => {
            const simple = data?.t1;
            const modified = data?.modifiedT1;
            const history = data?.historyT1;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t1")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t1")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t1")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },

          ...columnWidth150,
        },
        {
          title: "T2",
          dataIndex: "t2",
          key: "t2",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.t2 !== prevRecord.t2,
          //   render: (value, data) =>
          //     checkDisableText(
          //       value,
          //       data?.topTarget?.split(",")?.includes("t2")
          //     ),
          render: (_, data) => {
            const simple = data?.t2;
            const modified = data?.modifiedT2;
            const history = data?.historyT2;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t2")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t2")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t2")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "T2-T1",
          dataIndex: "t2_T1",
          key: "t2_T1",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.t2_T1 !== prevRecord.t2_T1,

          render: (_, data) => {
            const simple = data?.t2_T1;
            const modified = data?.modifiedT2_T1;
            const history = data?.historyT2_T1;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t2")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t2")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t2")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },

        {
          title: "T3",
          dataIndex: "t3",
          key: "t3",
          align: "center",
          // shouldCellUpdate: (record, prevRecord) => record.t3 !== prevRecord.t3,
          render: (_, data) => {
            const simple = data?.t3;
            const modified = data?.modifiedT3;
            const history = data?.historyT3;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t3")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t3")
              ? false
              : true;
            const topSlabDetailsHistory = data?.HistoryTopTarget?.split(
              ","
            )?.includes("t3")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "T3-T2",
          dataIndex: "t3_T2",
          key: "t3_T2",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.t3_T2 !== prevRecord.t3_T2,
          render: (_, data) => {
            const simple = data?.t3_T2;
            const modified = data?.modifiedT3_T2;
            const history = data?.historyT3_T2;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t3")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t3")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t3")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "T4",
          dataIndex: "t4",
          key: "t4",
          align: "center",
          // shouldCellUpdate: (record, prevRecord) => record.t4 !== prevRecord.t4,
          render: (_, data) => {
            const simple = data?.t4;
            const modified = data?.modifiedT4;
            const history = data?.historyT4;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t4")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t4")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t4")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "T4-T3",
          dataIndex: "t4_T3",
          key: "t4_T3",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.t4_T3 !== prevRecord.t4_T3,
          render: (_, data) => {
            const simple = data?.t4_T3;
            const modified = data?.modifiedT4_T3;
            const history = data?.historyT4_T3;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t4")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t4")
              ? false
              : true;
            const topSlabDetailsHistory = data?.HistoryTopTarget?.split(
              ","
            )?.includes("t4")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "T5",
          dataIndex: "t5",
          key: "t5",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.t5 !== prevRecord.t5,
          render: (_, data) => {
            const simple = data?.t5;
            const modified = data?.modifiedT5;
            const history = data?.historyT5;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t5")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t5")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t5")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "T5-T4",
          dataIndex: "t5_T4",
          key: "t5_T4",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.t5_T4 !== prevRecord.t5_T4,
          render: (_, data) => {
            const simple = data?.t5_T4;
            const modified = data?.modifiedT5_T4;
            const history = data?.historyT5_T4;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t5")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t5")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t5")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },

        {
          title: "T6",
          dataIndex: "t6",
          key: "t6",
          align: "center",
          // shouldCellUpdate: (record, prevRecord) => record.t6 !== prevRecord.t6,
          render: (_, data) => {
            const simple = data?.t6;
            const modified = data?.modifiedT6;
            const history = data?.historyT6;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t6")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t6")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t6")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },

          ...columnWidth150,
        },
        {
          title: "T6-T5",
          dataIndex: "t6_T5",
          key: "t6_T5",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.t6_T5 !== prevRecord.t6_T5,
          render: (_, data) => {
            const simple = data?.t6_T5;
            const modified = data?.modifiedT6_T5;
            const history = data?.historyT6_T5;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t6")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t6")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t6")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
      ],
    },
    {
      title: "PAYOUT RATE",
      children: [
        {
          title: "R1",
          dataIndex: "r1",
          key: "r1",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.r1 !== prevRecord.r1,
          render: (_, data) => {
            const simple = data?.r1;
            const modified = data?.modifiedR1;
            const history = data?.historyR1;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t1")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t1")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t1")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "R2",
          dataIndex: "r2",
          key: "r2",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.r2 !== prevRecord.r2,
          render: (_, data) => {
            const simple = data?.r2;
            const modified = data?.modifiedR2;
            const history = data?.historyR2;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t2")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t2")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t2")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "R3",
          dataIndex: "r3",
          key: "r3",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.r3 !== prevRecord.r3,
          render: (_, data) => {
            const simple = data?.r3;
            const modified = data?.modifiedR3;
            const history = data?.historyR3;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t3")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t3")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t3")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "R4",
          dataIndex: "r4",
          key: "r4",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.r4 !== prevRecord.r4,
          render: (_, data) => {
            const simple = data?.r4;
            const modified = data?.modifiedR4;
            const history = data?.historyR4;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t4")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t4")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t4")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "R5",
          dataIndex: "r5",
          key: "r5",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.r5 !== prevRecord.r5,
          render: (_, data) => {
            const simple = data?.r5;
            const modified = data?.modifiedR5;
            const history = data?.historyR5;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t5")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t5")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t5")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "R6",
          dataIndex: "r6",
          key: "r6",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.r6 !== prevRecord.r6,
          render: (_, data) => {
            const simple = data?.r6;
            const modified = data?.modifiedR6;
            const history = data?.historyR6;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t6")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t6")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t6")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
      ],
    },

    {
      title: "PAYOUT RATE",
      children: [
        {
          title: "P1",
          dataIndex: "p1",
          key: "p1",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.p1 !== prevRecord.p1,
          render: (_, data) => {
            const simple = data?.p1;
            const modified = data?.modifiedP1;
            const history = data?.historyP1;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t1")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t1")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t1")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "P2",
          dataIndex: "p2",
          key: "p2",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.p2 !== prevRecord.p2,
          render: (_, data) => {
            const simple = data?.p2;
            const modified = data?.modifiedP2;
            const history = data?.historyP2;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t2")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t2")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t2")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "P3",
          dataIndex: "p3",
          key: "p3",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.p3 !== prevRecord.p3,
          render: (_, data) => {
            const simple = data?.p3;
            const modified = data?.modifiedP3;
            const history = data?.historyP3;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t3")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t3")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t3")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "P4",
          dataIndex: "p4",
          key: "p4",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.p4 !== prevRecord.p4,
          render: (_, data) => {
            const simple = data?.p4;
            const modified = data?.modifiedP4;
            const history = data?.historyP4;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t4")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t4")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t4")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "P5",
          dataIndex: "p5",
          key: "p5",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.p5 !== prevRecord.p5,
          render: (_, data) => {
            const simple = data?.p5;
            const modified = data?.modifiedP5;
            const history = data?.historyP5;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t5")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t5")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t5")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
        {
          title: "P6",
          dataIndex: "p6",
          key: "p6",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) => record.p6 !== prevRecord.p6,
          render: (_, data) => {
            const simple = data?.p6;
            const modified = data?.modifiedP6;
            const history = data?.historyP6;
            const topSlabDetails = data?.topTarget?.split(",")?.includes("t6")
              ? false
              : true;
            const topSlabDetailsModified = data?.modifiedTopTarget
              ?.split(",")
              ?.includes("t6")
              ? false
              : true;
            const topSlabDetailsHistory = data?.historyTopTarget
              ?.split(",")
              ?.includes("t6")
              ? false
              : true;
            return validationStatus(data) ? (
              TagUnholdData(simple, modified, topSlabDetailsModified)
            ) : validationStatus3(data) ? (
              TagUnholdData(simple, modified, topSlabDetails)
            ) : validationStatus1(data) ? (
              TagUnholdData(history, simple, topSlabDetails)
            ) : validationStatus2(data) ? (
              TagUnholdData(simple, history, topSlabDetailsHistory)
            ) : (
              <div>{simple}</div>
            );
          },
          ...columnWidth150,
        },
      ],
    },
    ...getRemarks(),
    {
      title: "TOTAL EXPECTED PAYOUT",
      children: [
        {
          title: "Expected Volume",
          dataIndex: "volume",
          key: "volume",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.volume !== prevRecord.volume,
          render: (_, data) =>
            validationStatus(data) ? (
              TagUnholdData(data?.volume, data?.modifiedVolume)
            ) : validationStatus3(data) ? (
              TagUnholdData(data?.volume, data?.modifiedVolume)
            ) : validationStatus1(data) ? (
              TagUnholdData(data?.historyVolume, data?.volume)
            ) : validationStatus2(data) ? (
              TagUnholdData(data?.volume, data?.historyVolume)
            ) : (
              <div>{data?.volume}</div>
            ),
          ...columnWidth150,
        },
        {
          title: "PER TR.",
          dataIndex: "perTr",
          key: "perTr",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.perTr !== prevRecord.perTr,
          render: (_, data) =>
            validationStatus(data) ? (
              TagUnholdData(data?.perTr, data?.modifiedPerTr)
            ) : validationStatus3(data) ? (
              TagUnholdData(data?.perTr, data?.modifiedPerTr)
            ) : validationStatus1(data) ? (
              TagUnholdData(data?.historyPerTr, data?.perTr)
            ) : validationStatus2(data) ? (
              TagUnholdData(data?.perTr, data?.historyPerTr)
            ) : (
              <div>{data?.perTr}</div>
            ),
          ...columnWidth150,
        },
        {
          title: "AMOUNT",
          dataIndex: "amount",
          key: "amount",
          align: "center",
          ellipsis: {
            showTitle: false,
          },
          // shouldCellUpdate: (record, prevRecord) =>
          //   record.amount !== prevRecord.amount,
          render: (_, data) =>
            validationStatus(data) ? (
              TagUnholdData(data?.amount, data?.modifiedAmount)
            ) : validationStatus3(data) ? (
              TagUnholdData(data?.amount, data?.modifiedAmount)
            ) : validationStatus1(data) ? (
              TagUnholdData(data?.historyAmount, data?.amount)
            ) : validationStatus2(data) ? (
              TagUnholdData(data?.amount, data?.historyAmount)
            ) : (
              <div>{data?.amount}</div>
            ),
          ...columnWidth150,
        },
      ],
    },
  ];
};

export default HoldTableCollectionView;
