import { Button, Spin, Table, Tag, message } from "antd";
import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosAPI from "../../../services/axiosApi";
import "./schemeStatus.css";

import ActionButton from "../../../components/actionButton/actionButton";

import { ReactComponent as LeftArrow } from "../../../assets/vector/leftArrow.svg";
import dayjs from "dayjs";
import { Pagination } from "antd";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import { ArrowBackRounded } from "@mui/icons-material";
import FullScreenLoader from "../../../components/loader/FullScreenLoader";
//import type { ColumnsType } from 'antd/es/table'

const With_DeliveryPlan = 1;
const Without_Delivery_Plan = 2;
const SLAB_BASED = 3;
const SINGLE_TARGET = 8;
const SINGLE_TARGET_WITH_UPPER_CAP = 4;
const UNCONDITIONAL_STATEMENT = 5;

const SchemeStatusTable = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [arrDataResult, setArrDataSource] = useState([])
  const selectedSchemeType = searchParams.get('schemeType');
  const [dataSource, setDataSource] = useState([]);
  const [userData, setUserData] = useState({});
  const [startHeadMonth, setStartHeadMonth] = useState();
  const [endHeadMonth, setEndHeadMonth] = useState();
  const [showFirstMonth, setshowFirstMonth] = useState()
  const [spinner, setSpinner] = useState(false);
  const [count, setCount] = useState(0);
  const [isLoading,setIsLoading]=useState(false)
  // const [selectedSchemeType, setSelectedSchemeType] = useState(1);

  // const { schemeKey, startMonth, endMonth, modalTitle } = location.state


  const userInfo = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userInfo.userRole;

  const modalName = useSelector(
    (state) => state.schemeSubmitted.modalName.schemeState
  );
  const schemeStatusData = useSelector((state) => state.schemeStatus.data);

  const schemeData = useSelector((state) => state.schemeStatus);

  const datePicker = useSelector(
    (state) => state.monthPicker
  );


  const InitSummaryModalValue = {
    pageSize: 150,
    pageNumber: 1,
  };
  const handlePaginationChange = (pageNumber, pageSize) => {
    setModalFormData({ ...modalFormData, pageNumber, pageSize });
    location.state.payload.pageNumber = pageNumber
    location.state.payload.pageSize = pageSize
  };

  const [modalFormData, setModalFormData] = useState(InitSummaryModalValue);

  useEffect(() => {
    if (location?.state) {

      const userData = decryptData(sessionStorage.getItem("userInfo"));
      const userRole = userData.userRole;
      const userId = userData.userId;
   
      if (userRole == "AMGR") {
        if (location?.state?.schemeState == "Schemes Submitted") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/SummaryDetailsSubmitted`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
        if (location?.state?.schemeState == "Schemes Approved") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/ApprovedSummaryDetails`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
        if (location?.state?.schemeState == "Schemes Pending") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/PendingSummaryDetails`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
        if (location?.state?.schemeState == "Schemes Rejected") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/SummaryDetailsRejected`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
      }
      if (userRole == "SADM") {
        if (location?.state?.schemeState == "Schemes Submitted") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/SummaryDetailsSubmitted`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
        if (location?.state?.schemeState == "Schemes Approved") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/ApprovedSummaryDetails`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
        if (location?.state?.schemeState == "Schemes Pending") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/PendingSummaryDetails`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
        if (location?.state?.schemeState == "Schemes Rejected") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/SummaryDetailsRejected`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
      }
      if (userRole == "ZADM") {
        if (location?.state?.schemeState == "Schemes Submitted") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/SummaryDetailsSubmitted`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
        if (location?.state?.schemeState == "Schemes Approved") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/ApprovedSummaryDetails`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
        if (location?.state?.schemeState == "Schemes Pending") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/PendingSummaryDetails`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
        if (location?.state?.schemeState == "Schemes Rejected") {
          setIsLoading(true)
          axiosAPI
            .post(`Summary/SummaryDetailsRejected`, location?.state?.payload)
            .then((result) => {
              let response = result.data.data.data;
              let totalCount = result.data.data.totalCountScheme;
              setCount(totalCount);
              setArrDataSource(response || [])
              setIsLoading(false)
            });
        }
      }
    }
  }, [location, modalFormData.pageNumber, modalFormData.pageSize])

  const columns = [
    {
      title: "Scheme No.",
      dataIndex: "scheme",
      width: "145px",
      fixed: "left",
      render: (item) => {
        let color = " ";
        const dataItem=item?.holdStatus==9?item?.holdStatus:item.status
        switch (dataItem) {
          case 0:
            color = "#FCE838";
            break;
            // #Inprogress
          case 1:
            color = "#FCE838";
            break;
            // #Approved
          case 2:
            color = "#51DE4E";
            break;
              // #Withdraw
          case 3:
            color = "#FFAE00";
            break;
              // #Cancel
          case 4:
            color = "#FF3232";
            break;
             // #Reject
          case 5:
            color = "#FF3232";
            break;
             //Deviation
          case 7:
              color = "#bcb38d";
              break;
             // #AutoRejected;
         case 8:
              color = "rgb(255, 0, 255)";
              break;
              case 9:
                color = "#9999ff"; //hold
                break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: "Dealer Code",
      dataIndex: "code",
      fixed: "left",
      width: "140px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerCode",

    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      fixed: "left",
      width: "150px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerName"
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "150px",
    
      onFilter: (value, record) => record.Location.indexOf(value) === 0,
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
    },
    {
      title: "State",
      dataIndex: "state",
      width: "130px",
     
      sorter: true,
      defaultSortOrder: "ascend",
      key: "StateName",
    },
    {
      title: "Territory Manager",
      dataIndex: "Manager",
      sorter: true,
      defaultSortOrder: "ascend",
      width: "150px",
      key:"TerritoryManager",
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      width: "130px",
      sorter: true,
    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      width: "160px",
      sorter: true,
    },
    {
      title: "TOP SLAB",
      width: "150px",
      render: (x, y, index) => y?.scheme?.topTarget?.toUpperCase(),
    },

    {
      title: "Industry",
      dataIndex: "industry",
      width: "100px",
    },
    {
      title: "B",
      dataIndex: "oldBillingQty",
      width: "100px",
    },
    {
      title: "I",
      dataIndex: "oldInstallation",
      width: "100px",
    },
    {
      title: "C",
      dataIndex: "oldCollection",
      width: "110px",
    },

    // {
    //   title: "Slab Achived Last Year",
    //   dataIndex: "target",
    // },
    {
      title: " Opening Stock",
      dataIndex: "stock",
      children: [
        {
          title: "NOS",
          width: "120px",

          dataIndex: "oldOpeningStock",
        },
        {
          title: "Days",
          width: "120px",

          dataIndex: "openingStockDays",
        },
      ],
    },

    {
      title: "Closing Os. (In Lacs)",
      dataIndex: "stock",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name.length - b.name.length,
      children: [

        {
title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
          dataIndex: "lastThirdMonth",
          defaultSortOrder: "descend",
          width: "150px",

          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          title: (
            <span>
              Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
            </span>
          ),
          dataIndex: "lastTwoMonth",
          defaultSortOrder: "descend",
          width: "150px",

          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          title: (
            <span>
              Last Month
            </span>
          ),
          dataIndex: "lastOneMonth",
          defaultSortOrder: "descend",
          width: "150px",

          sorter: (a, b) => a.name.length - b.name.length,
        },
        // {
        //   title: "Lacs",
        //   dataIndex: "lac",
        //   defaultSortOrder: "descend",
        //   width: "190px",

        //   sorter: (a, b) => a.name.length - b.name.length,
        // },
        // {
        //   title: (
        //     <span>
        //       Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
        //     </span>
        //   ),
        //   dataIndex: "outStandingDays",
        //   defaultSortOrder: "descend",
        //   width: "100px",

        //   sorter: (a, b) => a.name.length - b.name.length,
        // },
      ],
    },
    {
      title: "Closing OS Days",
      dataIndex: "outStandingDays",
      children: [
        {
          title: "OS Last Month",
          dataIndex: "outStandingDays",
          width: "100px",
          sorter: (a, b) => a.name.length - b.name.length,
        },
      ],
    },
    {
      title: "BG",
      dataIndex: "bg",
      children: [
        {
          title: "Lacs",
          width: "120px",
          dataIndex: "bgLacsCurrentYear",
        },
      ],
    },
    {
      title: "Installation Plan",
      dataIndex: "IP",
      children: [
        {
          title: "NOS",
          dataIndex: "delayPlan",
          width: "100px",

        },
      ],
    },
    {
      title: "Billing Details",
      dataIndex: "plan",
      children: [
        {
          title: "B",
          dataIndex: "b",
          width: "100px",

        },
        {
          title: "I",
          dataIndex: "i",
          width: "100px",

        },
        {
          title: "C",
          dataIndex: "c",
          width: "100px",

        },
      ],
    },
    {
      title: (
        <div>
          Closing <br /> Stock
        </div>
      ),
      dataIndex: "closingStock",
      width: "80px",
    },
    {
      title: (
        <div>
          Closing <br /> OutStanding (In Lacs)
        </div>
      ),
      dataIndex: "closingOutStanding",
      width: "110px",
    },
    {
      title: "Books Collection Target (No. of Ts.)",
      dataIndex: "BooksCollectionTarget",
      children: [
        {
          title: "T1",
          dataIndex: "BooksCollectionTargetT1",
          width: "100px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "T2",
          dataIndex: "BooksCollectionTargetT2",
          width: "100px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "T2-T1",
          dataIndex: "BooksCollectionTargetT2T1",
          width: "100px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "T3",
          dataIndex: "BooksCollectionTargetT3",
          width: "100px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "T3-T2",
          dataIndex: "BooksCollectionTargetT3T2",
          width: "100px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "T4",
          dataIndex: "BooksCollectionTargetT4",
          defaultSortOrder: "descend",
          width: "100px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "T4-T3",
          dataIndex: "BooksCollectionTargetT4T3",
          defaultSortOrder: "descend",
          width: "100px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T5",
          dataIndex: "BooksCollectionTargetT5",
          defaultSortOrder: "descend",
          width: "100px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "T5-T4",
          dataIndex: "BooksCollectionTargetT5T4",
          defaultSortOrder: "descend",
          width: "100px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "T6",
          dataIndex: "BooksCollectionTargetT6",
          defaultSortOrder: "descend",
          width: "100px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "T6-T5",
          dataIndex: "BooksCollectionTargetT6T5",
          defaultSortOrder: "descend",
          width: "100px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
      ],
    },
    {
      title: "Payout Rate",
      dataIndex: "pr",

      children: [
        {
          title: "R1",
          dataIndex: "PayoutRateR1",
          width: "120px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "R2",
          dataIndex: "PayoutRateR2",
          width: "120px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "R3",
          dataIndex: "PayoutRateR3",
          width: "120px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "R4",
          dataIndex: "PayoutRateR4",
          defaultSortOrder: "descend",
          width: "120px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "R5",
          dataIndex: "PayoutRateR5",
          defaultSortOrder: "descend",
          width: "120px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "R6",
          dataIndex: "PayoutRateR6",
          defaultSortOrder: "descend",
          width: "120px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
      ],
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "180px",

    },
    {
      title: "Payout",
      dataIndex: "payout",

      children: [
        {
          title: "P1",
          dataIndex: "payoutP1",
          width: "120px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "P2",
          dataIndex: "payoutP2",
          width: "120px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "P3",
          dataIndex: "payoutP3",
          width: "120px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "P4",
          dataIndex: "payoutP4",
          width: "120px",
          defaultSortOrder: "descend",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }

        },
        {
          title: "P5",
          dataIndex: "payoutP5",
          defaultSortOrder: "descend",
          width: "120px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
        {
          title: "P6",
          dataIndex: "payoutP6",
          defaultSortOrder: "descend",
          width: "120px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{x}</div>
            )
          }
        },
      ],
    },
    {
      title: "Total Expected Payout",
      dataIndex: "TEP",
      children: [
        {
          title: "Expected Volume",
          dataIndex: "TEPvol",
          width: "150px",

        },
        {
          title: "Per Tr.",
          dataIndex: "TEPpertr",
          width: "150px",

        },
        {
          title: "Amount",
          dataIndex: "TEPamount",
          width: "150px",

        },
      ],
    },
    // {
    //   title: "",
    //   dataIndex: "",
    //   children: [
    //     {
    //       title: "Last 1 Month Slab Achived",
    //       dataIndex: "LMSA",
    //     },
    //     {
    //       title: "Last 1 Col QTY",
    //       dataIndex: "LCQ",
    //     },
    //     {
    //       title: "Last 2 Month Slab Achived",
    //       dataIndex: "LMSA1",
    //     },
    //     {
    //       title: "Last 1 Col QTY",
    //       dataIndex: "LCQ1",
    //     },
    //   ],
    // },
  ];

  const slabBasedColumns = [
    {
      title: "Scheme No.",
      dataIndex: "scheme",
      width: "160px",
      fixed: "left",
      defaultSortOrder: "descend",
      render: (item) => {
        let color = " ";
        switch (item.status) {
          case 0:
            color = "#FCE838";
            break;
            // #Inprogress
          case 1:
            color = "#FCE838";
            break;
            // #Approved
          case 2:
            color = "#51DE4E";
            break;
              // #Withdraw
          case 3:
            color = "#FFAE00";
            break;
              // #Cancel
          case 4:
            color = "#FF3232";
            break;
             // #Reject
          case 5:
            color = "#FF3232";
            break;
             // #AutoRejected;
         case 8:
              color = "rgb(255, 0, 255)";
              break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: "Dealer Code",
      dataIndex: "code",
      // sorter: true,
      fixed: 'left',
      width: "160px",
      sorter: true,
      key: "DealerCode",
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      // sorter: true,
      fixed: 'left',
      width: "160px",
      sorter: true,
      key: "DealerName"
    },
    {
      title: "State",
      dataIndex: "state",
      // sorter: true,
      width: '130px',
      sorter: true,
      key: "StateName",
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "190px",
      sorter: true,
      key: "DealerLocation",
      onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },

    {
      title: "Territory Manager",
      dataIndex: "Manager",
      // sorter: true,
      width: '190px',
      sorter: true,
      key: "TerritoryManager",
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      // sorter: true,
      width: '160px'

    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      // sorter: true,
      width: '160px'

    },
    {
      title: "TOP SLAB",
      dataIndex: "topTarget",
      width: '190px',
      sorter: true,
      render:(x,y)=>y?.scheme?.topTarget?.toUpperCase()
  },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      // sorter: true,
      width: '250px'

    },
    {
      title: "Targets",
      dataIndex: "Target",
      // width: '180px',
      children: [
        {
          title: "T1",
          dataIndex: "t1",
          width: '90px',
        },
        {
          title: "T2",
          dataIndex: "t2",
          width: '90px',
        },
        {
          title: "T3",
          dataIndex: "t3",
          width: '90px',
      }
      ]
    },
    {
      title: "inc. Per Tractor",
      dataIndex: "IPT",
      // width: '180px',
      children: [
        {
          title: "R1",
          dataIndex: "r1",
          width: '90px',
        },
        {
          title: "R2",
          dataIndex: "r2",
          width: '90px',
        },
        {
          title: "R3",
          dataIndex: "r3",
          width: '90px',
      }
      ]
    },
    {
      title: "Payout",
      dataIndex: "Payout",
      // width: '180px',
      children: [
        {
          title: "P1",
          dataIndex: "p1",
          width: '90px',
        },
        {
          title: "P2",
          dataIndex: "p2",
          width: '90px',
        },
        {
          title: "P3",
          dataIndex: "p3",
          width: '90px',
      }

      ]
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: '120px'

    }
  ];

  const singleTargetWithUprCapcolumns = [
    {
      title: "Scheme No.",
      dataIndex: "scheme",
      width: "160px",
      fixed: "left",
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.name.length - b.name.length,
      render: (item) => {
        let color = " ";
        switch (item.status) {
          case 0:
            color = "#FCE838";
            break;
          case 1:
            color = "#FCE838";
            break;
          case 2:
            color = "#51DE4E";
            break;
          case 3:
            color = "#FFAE00";
            break;
          case 4:
            color = "#FF3232";
            break;
          case 5:
            color = "#FF3232";
            break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: "Dealer Code",
      dataIndex: "code",
      //   sorter: true,
      fixed: 'left',
      width: "160px",
      sorter: true,
      key: "DealerCode",
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      //   sorter: true,
      fixed: 'left',
      width: "160px",
      sorter: true,
      key: "DealerName"
    },
    {
      title: "State",
      dataIndex: "state",
      //   sorter: true,
      width: '130px',
      sorter: true,
      key: "StateName",
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "190px",
      sorter: true,
      key: "DealerLocation",
      filters: [
        {
          text: "Amritsar",
          value: "Amritsar",
        },
        {
          text: "Zirakpur",
          value: "Zirakpur",
        },
      ],
      onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },

    {
      title: "Territory Manager",
      dataIndex: "Manager",
      //   sorter: true,
      width: '190px',
      sorter: true,
      key: "TerritoryManager",
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      //   sorter: true,
      width: '130px'

    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      //   sorter: true,
      width: '130px'

    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      //   sorter: true,
      width: '250px'

    },
    {
      title: "Installation Target",
      dataIndex: "Target",
      width: '190px'

    },
    {
      title: "Upper Cap",
      dataIndex: "UC",
      width: '120px'

    },
    {
      title: "inc. Per Tractor",
      dataIndex: "IPT",
      width: '190px'

    },
    {
      title: "Payout",
      dataIndex: "Payout",
      width: '120px'

    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: '120px'

    }
  ];

  const unconditionalInstallationColumns = [
    {
      title: "Scheme No.",
      dataIndex: "scheme",
      width: "160px",
      fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name.length - b.name.length,
      render: (item) => {
        let color = " ";
        switch (item.status) {
          case 0:
            color = "#FCE838";
            break;
          case 1:
            color = "#FCE838";
            break;
          case 2:
            color = "#51DE4E";
            break;
          case 3:
            color = "#FFAE00";
            break;
          case 4:
            color = "#FF3232";
            break;
          case 5:
            color = "#FF3232";
            break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: "Dealer Code",
      dataIndex: "code",
      //   sorter: true,
      fixed: "left",
      width: "160px",
      sorter: true,
      key: "DealerCode",
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      //   sorter: true,
      fixed: "left",
      width: "160px",
      sorter: true,
      key: "DealerName"
    },
    {
      title: "State",
      dataIndex: "state",
      //   sorter: true,
      width: "130px",
      sorter: true,
      key: "StateName",
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "190px",
      onFilter: (value, record) => record.Location.indexOf(value) === 0,
      sorter: true,
      key: "DealerLocation",
    },

    {
      title: "Territory Manager",
      dataIndex: "Manager",
      //   sorter: true,
      width: "190px",
      sorter: true,
      key: "TerritoryManager",
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      //   sorter: true,
      width: "130px",
    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      //   sorter: true,
      width: "130px",
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      //   sorter: true,
      width: "250px",
    },
    {
      title: "Installation Expected",
      dataIndex: "Target",
      width: "200px",
    },
    {
      title: "inc. Per Tractor",
      dataIndex: "IPT",
      width: "190px",
    },
    {
      title: "Payout",
      dataIndex: "Payout",
      width: "190px",
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "120px",
    },
  ];

  const singleTargetColumns = [
    {
      title: "Scheme No.",
      dataIndex: "scheme",
      width: "160px",
      fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name.length - b.name.length,
      render: (item) => {
        let color = " ";
        switch (item.status) {
          case 0:
            color = "#FCE838";
            break;
          case 1:
            color = "#FCE838";
            break;
          case 2:
            color = "#51DE4E";
            break;
          case 3:
            color = "#FFAE00";
            break;
          case 4:
            color = "#FF3232";
            break;
          case 5:
            color = "#FF3232";
            break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: "Dealer Code",
      dataIndex: "code",
      //   sorter: true,
      fixed: "left",
      width: "160px",
      sorter: true,
      key: "DealerCode",
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      //   sorter: true,
      fixed: "left",
      width: "160px",
      sorter: true,
      key: "DealerName"
    },
    {
      title: "State",
      dataIndex: "state",
      //   sorter: true,
      width: "130px",
      sorter: true,
      key: "StateName",
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "190px",
      sorter: true,
      key: "DealerLocation",
      onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },

    {
      title: "Territory Manager",
      dataIndex: "Manager",
      //   sorter: true,
      width: "190px",
      sorter: true,
      key: "TerritoryManager",
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      //   sorter: true,
      width: "130px",
    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      //   sorter: true,
      width: "130px",
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      //   sorter: true,
      width: "250px",
    },
    {
      title: "Installation Target",
      dataIndex: "Target",
      width: "190px",
    },

    {
      title: "inc. Per Tractor",
      dataIndex: "IPT",
      width: "190px",
    },
    {
      title: "Payout",
      dataIndex: "singleTrgPayout",
      width: "120px",
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: '120px'

    }
  ]



  const getStartMonthName = (startMonthX, startYearX) => {
    const date = new Date();
    date.setMonth(startMonthX - 1);
    date.setYear(startYearX);
    setshowFirstMonth(date.toLocaleString('en-US', { month: 'long', year: '2-digit' }));
  }
  function getEndMonthName(endMonthX, endYearX) {
    const date = new Date();
    date.setMonth(endMonthX - 1);
    date.setYear(endYearX);
    return setEndHeadMonth(date.toLocaleString('en-US', { month: 'long', year: '2-digit' }));
  }

  // useEffect(() => {
  //   setDataSource(modalFormData);
  //   navigate(
  //     `/collection/schemeStatus?schemeType=${modalFormData.scheme_Type.key}`, {
  //     state: {
  //       location
  //     }
  //   }
  //   );
  // }, [location])


  useEffect(() => {
    const startMonthX = datePicker.startMonth;
    const endMonthX = datePicker.endMonth;
    const startYearX = datePicker.startYr;
    const endYearX = datePicker.endYr;
    getStartMonthName(startMonthX, startYearX);
    getEndMonthName(endMonthX, endYearX);
  })


  useEffect(() => {
   
   
    const fetchData = async ()=>{
      try{

        const data = [];
        Array?.isArray(arrDataResult) && arrDataResult
          ?.filter((item) => item.schemeNo != null)
          .map((item, index) => {
            for (let i = 0; i < 1; i++) {
              let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
              setStartHeadMonth(formattedStartDate);
              let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
              setEndHeadMonth(formattedEndDate);
              data.push({
                // status: item.status,
                // key: item.DealerCode,
                scheme: item,
                code: item.dealerCode,
                name: item.dealerName,
                Location: item.dealerLocation,
                state: item.stateName,
                Manager: item.territoryManager,
                StartMonth: formattedStartDate,
                EndMonth: formattedEndDate,
                industry: item.Industry,
                b: item.billing,
                i: item.installation,
                c: item.collection,
                oldBillingQty: item.oldBillingQty,
                oldCollection: item.oldCollection,
                oldInstallation: item.oldInstallation,
                // target: item.SlabAchievedLastYear,
                // nos: item.Feb22OpeningStock.Nos,
                // days: item.Feb22OpeningStock.Days,
                // nov21: item.Nov21,
                // dec21: item.Dec21,
                // lac: item.Jan22ClosingOs.Lacs,
                delayPlan: item.delayPlan,
                bgLacsCurrentYear: item.bGLacsCurrentYear,
                lastThirdMonth: item.closingOutStandingMonth_3,
                lastTwoMonth: item.closingOutStandingMonth_2,
                lastOneMonth: item.closingOutStandingMonth_1,
                outStandingDays: item.outStandingDays,
                openingStockDays: item.openingStockDays,
                oldOpeningStock: item.oldOpeningStock,
                reason: item.reason,
                BooksCollectionTargetT1: item.t1,
                t1: item.t1,
                t2: item.t2,
                t3:item.t3,
                BooksCollectionTargetT2: item.t2,
                BooksCollectionTargetT2T1: item.t2_T1,
                BooksCollectionTargetT3: item.t3,
                BooksCollectionTargetT3T2: item.t3_T2,
                BooksCollectionTargetT4:item.t4,
                BooksCollectionTargetT4T3:item.t4_T3,
                BooksCollectionTargetT5:item.t5,
                BooksCollectionTargetT5T4:item.t5_T4,
                BooksCollectionTargetT6:item.t6,
                BooksCollectionTargetT6T5:item.t6_T5,
                PayoutRateR1: item.r1,
                PayoutRateR2: item.r2,
                PayoutRateR3: item.r3,
                PayoutRateR4: item.r4,
                PayoutRateR5: item.r5,
                PayoutRateR6: item.r6,
                r2: item.r2,
                r1: item.r1,
                r3 :item.r3,
                p1: item.p1,
                p2: item.p2,
                p3: item.p3,
                singleTrgPayout: item.p1,
                Payout: item.p1,
                UC: item.upperCap,
    
                // installationTarget
                Target: item.installationTarget,
                IPT: item.incPerTarget,
                payoutP1: item.p1,
                payoutP2: item.p2,
                payoutP3: item.p3,
                payoutP4: item.p4,
                payoutP5: item.p5,
                payoutP6: item.p6,
                TEPvol: item.volume,
                TEPpertr: item.perTr,
                TEPamount: item.amount,
                // LMSA: item.LastOneMonthSlabAchieved,
                // LCQ: item.LastOneMonthColQty,
                // LMSA1: item.LastTwoMonthSlabAchieved,
                // LCQ1: item.LastTwoMonthColQty
                oldBillingQty: item.oldBillingQty,
                oldInstallation: item.oldInstallation,
                oldCollection: item.oldCollection,
                oldOpeningStock: item.oldOpeningStock,
                openingStockDays: item.openingStockDays,
                outStandingDays: item.outStandingDays,
                closingOutStandingMonth_1: item.closingOutStandingMonth_1,
                closingOutStandingMonth_2: item.closingOutStandingMonth_2,
                closingOutStandingMonth_3: item.closingOutStandingMonth_3,
                industry: item.industry,
                actual_Installation_Last_Year_Month: item.actual_Installation_Last_Year_Month,
                closingStock: item.closingStock,
                closingOutStanding: item.closingOutStanding
                // billing: parseInt(item.billing),
                // installation: parseInt(item.installation),
                // collection: parseInt(item.collection),
              });
            }
            setDataSource(data);
          });
      }catch (error) {
        message.error('Failed to fetch data');
      } finally {
        setSpinner(false);
      }
    };
   fetchData();
    
  }, [location, arrDataResult]);

  const handleBack = () => {
    navigate(-1);
  };


  const getColumns = schemeType => {

    if ([1, 2].includes(schemeType)) {
      return columns;
    }
    if (schemeType === 3) {
      return slabBasedColumns;
    }
    if (schemeType === 4) {
      return singleTargetWithUprCapcolumns;
    }
    if (schemeType === 5) {
      return unconditionalInstallationColumns;
    }
    if (schemeType === 8) {
      return singleTargetColumns;
    }
  }
  const scroll = selectedSchemeType == 1 || selectedSchemeType == 2 ? {
    x: 4000,
    y: 800
  } : {
    x: 2000,
    y: 300
  }
  return (
    <div className="create_scheme_wrap collection-schme-box">

      <div className="Scheme_Status_Heading">
        <div className="arrow_heading">
          {/* <LeftArrow style={{ cursor: "pointer",color:"black" }} onClick={handleBack} /> */}
          <Button type="primary" icon={<ArrowBackRounded />} size="middle" onClick={handleBack} />
          <span className="left-arrow-icon2">
            {modalName} - {" "}
            {startHeadMonth === undefined
              ? "No Record Found !"
              : showFirstMonth + " to " + endHeadMonth}
          </span>

        </div>
        {["AMGR"].includes(userData?.userRole) ? (
          ""
        ) : (
          null
        )}
      </div>
      {/* {["SADM"].includes(userRole) &&
        modalName == 'Schemes Approved' &&
        <ActionButton />} */}

      <Table
        style={{
          cursor: "default",
        }}
        loading={spinner ? (<Spin size="large" />) : false}
        summary={(pageData) => {
          const fieldSum = {
            b: 0,
            i: 0,
            c: 0,
            totalR1: 0,
            totalR2: 0,
            totalR3: 0,
            totalR4: 0,
            totalR5: 0,
            totalR6: 0,
            t1: 0,
            t2: 0,
            t3: 0,
            t4:0,
            t5:0,
            t6:0,
            T2_T1: 0,
            T3_T2: 0,
            T4_T3: 0,
            T5_T4: 0,
            T6_T5: 0,
            totalP1: 0,
            totalP2: 0,
            totalP3: 0,
            totalP4: 0,
            totalP5: 0,
            totalP6: 0,
            vol: 0,
            perTr: 0,
            totalAmount: 0,
            actualCollection: 0,
            actualBillingAmount: 0,
            actualBillingOty: 0,
            billingFactor: 0,
            collectionQuantity: 0,
            T1:0,
            T2:0,
            T3:0,
            R1:0,
            R2:0,
            R3:0,
            Targets:0,
            Payout1:0,
            InstTargets:0,
            upperCap:0,
            Payout2:0,
          };

          pageData.forEach(
            ({
              payoutP1,
              payoutP2,
              payoutP3,
              payoutP4,
              payoutP5,
              payoutP6,
              TEPamount,
              BooksCollectionTargetT1,
              BooksCollectionTargetT2,
              BooksCollectionTargetT3,
              BooksCollectionTargetT4,
              BooksCollectionTargetT5,
              BooksCollectionTargetT6,
              BooksCollectionTargetT2T1,
              BooksCollectionTargetT3T2,
              BooksCollectionTargetT4T3,
              BooksCollectionTargetT5T4,
              BooksCollectionTargetT6T5,
              TEPvol,
              actualCollection,
              actualBillingAmount,
              actualBillingOty,
              actualBillingQuantity,
              billingFactor,
              collectionQuantity,
              TEPpertr,
              Target,
              IPT,
              UC
            }) => {
              fieldSum.totalP1 += payoutP1 ? parseInt(payoutP1) : 0;
              fieldSum.totalP2 += payoutP2 ? parseInt(payoutP2) : 0;
              fieldSum.totalP3 += payoutP3 ? parseInt(payoutP3) : 0;
              fieldSum.totalP4 += payoutP4 ? parseInt(payoutP4) : 0;
              fieldSum.totalP5 += payoutP5 ? parseInt(payoutP5) : 0;
              fieldSum.totalP6 += payoutP6 ? parseInt(payoutP6) : 0;
              fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
              fieldSum.t1 += BooksCollectionTargetT1
                ? parseInt(BooksCollectionTargetT1)
                : 0;
              fieldSum.t2 += BooksCollectionTargetT2
                ? parseInt(BooksCollectionTargetT2)
                : 0;
              fieldSum.t3 += BooksCollectionTargetT3
                ? parseInt(BooksCollectionTargetT3)
                : 0;
              fieldSum.t4 += BooksCollectionTargetT4
                ? parseInt(BooksCollectionTargetT4)
                  : 0;
              fieldSum.t5 += BooksCollectionTargetT5
                ? parseInt(BooksCollectionTargetT5)
                  : 0;
              fieldSum.t6 += BooksCollectionTargetT6
                ? parseInt(BooksCollectionTargetT6)
                  : 0;

              fieldSum.T2_T1 += BooksCollectionTargetT2T1
                ? parseInt(BooksCollectionTargetT2T1)
                : 0;
              fieldSum.T3_T2 += BooksCollectionTargetT3T2
                ? parseInt(BooksCollectionTargetT3T2)
                : 0;
                fieldSum.T4_T3 += BooksCollectionTargetT4T3
                ? parseInt(BooksCollectionTargetT4T3)
                : 0;
              
                fieldSum.T5_T4 += BooksCollectionTargetT5T4
                ? parseInt(BooksCollectionTargetT5T4)
                : 0;
                fieldSum.T6_T5 += BooksCollectionTargetT6T5
                ? parseInt(BooksCollectionTargetT6T5)
                : 0;
                fieldSum.totalR1 = parseInt(fieldSum.totalP1 / fieldSum.t1);
                fieldSum.totalR2 = parseInt(fieldSum.totalP2 / fieldSum.t2);
                fieldSum.totalR3 = parseInt(fieldSum.totalP3 / fieldSum.t3);
                fieldSum.totalR4 = parseInt(fieldSum.totalP4 / fieldSum.t4);
                fieldSum.totalR5 = parseInt(fieldSum.totalP5 / fieldSum.t5);
                fieldSum.totalR6 = parseInt(fieldSum.totalP6 / fieldSum.t6);

              fieldSum.vol += TEPvol ? parseInt(TEPvol) : 0;
              fieldSum.perTr = parseInt(fieldSum.totalAmount / fieldSum.vol);
              fieldSum.actualCollection += actualCollection
                ? parseInt(actualCollection)
                : 0;
              fieldSum.actualBillingAmount += actualBillingAmount
                ? parseInt(actualBillingAmount)
                : 0;
              fieldSum.actualBillingOty += actualBillingQuantity
                ? parseInt(actualBillingQuantity)
                : 0;
              fieldSum.billingFactor += billingFactor
                ? parseInt(billingFactor)
                : 0;
              fieldSum.collectionQuantity += collectionQuantity
                ? parseInt(collectionQuantity)
                : 0;
                fieldSum.T1 +=BooksCollectionTargetT1
                ? parseInt(BooksCollectionTargetT1)
                : 0;
                fieldSum.Targets += Target ? parseInt(Target): 0;
                fieldSum.InstTargets += IPT
                ? parseInt(IPT)
                  : 0;

                fieldSum.Payout1 = parseInt(fieldSum.Targets * fieldSum.InstTargets);
                fieldSum.upperCap  += UC ? parseInt(UC): 0;
                fieldSum.Payout2 = parseInt(fieldSum.upperCap * fieldSum.InstTargets)
                

            }
          );
          return (
            <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
              <Table.Summary.Cell fixed index={0}>Total</Table.Summary.Cell>
            
              {  (selectedSchemeType ==  With_DeliveryPlan ||  selectedSchemeType ==  Without_Delivery_Plan)  && 
              <>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}></Table.Summary.Cell>
              <Table.Summary.Cell index={10}></Table.Summary.Cell>
              <Table.Summary.Cell index={11}></Table.Summary.Cell>
              <Table.Summary.Cell index={12}></Table.Summary.Cell>
              <Table.Summary.Cell index={13}></Table.Summary.Cell>
              <Table.Summary.Cell index={14}></Table.Summary.Cell>
              <Table.Summary.Cell index={15}></Table.Summary.Cell>
              <Table.Summary.Cell index={16}></Table.Summary.Cell>
              <Table.Summary.Cell index={17}></Table.Summary.Cell>
              <Table.Summary.Cell index={18}></Table.Summary.Cell>
              <Table.Summary.Cell index={19}></Table.Summary.Cell>
              <Table.Summary.Cell index={20}></Table.Summary.Cell>
              <Table.Summary.Cell index={21}></Table.Summary.Cell>
              <Table.Summary.Cell index={22}></Table.Summary.Cell>
              <Table.Summary.Cell index={23}></Table.Summary.Cell>
              <Table.Summary.Cell index={24}></Table.Summary.Cell>
              <Table.Summary.Cell index={25}></Table.Summary.Cell>
              <Table.Summary.Cell index={26}>
                {fieldSum.t1 ? fieldSum.t1 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={27}>
                {fieldSum.t2 ? fieldSum.t2 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={28}>
                {fieldSum.T2_T1 ? fieldSum.T2_T1 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={29}>
                {fieldSum.t3 ? fieldSum.t3 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={30}>
                {fieldSum.T3_T2 ? fieldSum.T3_T2 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={31}>  { fieldSum.t4 ? fieldSum.t4 : ""} </Table.Summary.Cell>
              <Table.Summary.Cell index={32}> { fieldSum.T4_T3 ? fieldSum.T4_T3:"" }</Table.Summary.Cell>
              <Table.Summary.Cell index={33}> {fieldSum.t5 ? fieldSum.t5 : "" }</Table.Summary.Cell>
              <Table.Summary.Cell index={34}> { fieldSum.T5_T4 ? fieldSum.T5_T4:"" }</Table.Summary.Cell>
              <Table.Summary.Cell index={35}> {fieldSum.t6 ? fieldSum.t6 : "" }</Table.Summary.Cell>
              <Table.Summary.Cell index={36}> { fieldSum.T6_T5 ? fieldSum.T6_T5:"" }</Table.Summary.Cell>
              <Table.Summary.Cell index={37}>
                {fieldSum.totalR1 ? fieldSum.totalR1 :""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={38}>
              {fieldSum.totalR2 ? fieldSum.totalR2 :"" }
              </Table.Summary.Cell>
              <Table.Summary.Cell index={39}>
              {fieldSum.totalR3 ? fieldSum.totalR3 :"" }
              </Table.Summary.Cell>
              <Table.Summary.Cell index={40}>
              {fieldSum.totalR4 ? fieldSum.totalR4 :"" }
              </Table.Summary.Cell>
              <Table.Summary.Cell index={41}>
              {fieldSum.totalR5 ? fieldSum.totalR5 :"" }
              </Table.Summary.Cell>
              <Table.Summary.Cell index={42}>
              {fieldSum.totalR6 ? fieldSum.totalR6 :"" }
              </Table.Summary.Cell>
              <Table.Summary.Cell index={22}></Table.Summary.Cell>
              <Table.Summary.Cell index={22}> {  fieldSum.totalP1 ?  fieldSum.totalP1 : ""} </Table.Summary.Cell>
              <Table.Summary.Cell index={22}> {  fieldSum.totalP2 ?  fieldSum.totalP2 : ""}</Table.Summary.Cell>
              <Table.Summary.Cell index={22}> {  fieldSum.totalP3 ?  fieldSum.totalP3 : ""}</Table.Summary.Cell>
              <Table.Summary.Cell index={22}> {  fieldSum.totalP4 ?  fieldSum.totalP4 : ""}</Table.Summary.Cell>
              <Table.Summary.Cell index={22}> {  fieldSum.totalP5 ?  fieldSum.totalP5 : ""}</Table.Summary.Cell>
              <Table.Summary.Cell index={22}> {  fieldSum.totalP6 ?  fieldSum.totalP6 : ""}</Table.Summary.Cell>

              <Table.Summary.Cell index={35}>
                {fieldSum.vol ? fieldSum.vol : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={36}>
                {fieldSum.perTr ? fieldSum.perTr : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={37}>
                {fieldSum.totalAmount ? fieldSum.totalAmount : ""}
              </Table.Summary.Cell>
              </>
        }


              {
                  selectedSchemeType == SLAB_BASED &&
                  <>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}></Table.Summary.Cell>
             
            
                    <Table.Summary.Cell index={24}>{fieldSum.t1 ?fieldSum.t1 :0}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{fieldSum.t2 ? fieldSum.t2 :0}</Table.Summary.Cell>
                    <Table.Summary.Cell index={26}>{fieldSum.t3 ? fieldSum.t3 : 0}</Table.Summary.Cell>
                    <Table.Summary.Cell index={27}>{fieldSum.totalR1 ? fieldSum.totalR1 :0}</Table.Summary.Cell>
                    <Table.Summary.Cell index={28}>{fieldSum.totalR2 ? fieldSum.totalR2 :0}</Table.Summary.Cell>
                    <Table.Summary.Cell index={29}>{fieldSum.totalR3 ? fieldSum.totalR3 :0}</Table.Summary.Cell>
                    <Table.Summary.Cell index={30}>{fieldSum.totalP1 ?  fieldSum.totalP1 : 0} </Table.Summary.Cell>
                    <Table.Summary.Cell index={31}>{fieldSum.totalP2 ?  fieldSum.totalP2 : 0} </Table.Summary.Cell>
                    <Table.Summary.Cell index={32}>{fieldSum.totalP3 ?  fieldSum.totalP3 : 0} </Table.Summary.Cell>
                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                  </>
                }
                 {
                  selectedSchemeType ==   SINGLE_TARGET && 
                  <>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              
             
            
                    <Table.Summary.Cell index={24}>{fieldSum.Targets}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{parseInt(fieldSum.Payout1 / fieldSum.Targets)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={26}>{fieldSum.Payout1}</Table.Summary.Cell>
                    <Table.Summary.Cell index={27}>{}</Table.Summary.Cell>
                    
                  </>
                }


{
                  selectedSchemeType ==   SINGLE_TARGET_WITH_UPPER_CAP && 
                  <>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              
             
            
                    <Table.Summary.Cell index={24}>{fieldSum.Targets}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{ fieldSum.upperCap}</Table.Summary.Cell>
                    <Table.Summary.Cell index={26}>{ parseInt(fieldSum.Payout2 / fieldSum.InstTargets)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={27}>{fieldSum.Payout2}</Table.Summary.Cell>
                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                    
                  </>
                }
                {
                  selectedSchemeType ==   UNCONDITIONAL_STATEMENT && 
                  <>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              
             
            
                    <Table.Summary.Cell index={24}>{fieldSum.Targets}</Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>{ parseInt(fieldSum.Payout1 / fieldSum.Targets)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={26}>{ fieldSum.Payout1}</Table.Summary.Cell>
                    <Table.Summary.Cell index={27}>{}</Table.Summary.Cell>
                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                    
                  </>
                }
            </Table.Summary.Row>
          );
        }}
        columns={getColumns(+selectedSchemeType)}
        dataSource={dataSource}
        bordered
        scroll={scroll}
        pagination={false}
      />
      <Pagination defaultCurrent={1}
        showSizeChanger
        className="paginationDesign"
        current={modalFormData.pageNumber}
        onChange={handlePaginationChange}
        total={count || 0}
        pageSize={modalFormData.pageSize}
        pageSizeOptions={['150', '250', '350']}
      />
       {isLoading && <FullScreenLoader/>}
    </div>
  );
};
export default SchemeStatusTable;
