import ToastifyShow from "../../components/ToastifyShow";
import axiosAPI from "../../services/axiosApi"
import { decryptData } from "../../utils/localStorageEncodeDecode";
export const schemeGeneratePost = async (data) => {
    try {

      const response=axiosAPI.post(`SchemeGenerate/SchemeGenerate`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const schemeListApprove = async (data) => {
    try {

      const response=axiosAPI.post(`SchemeGenerate/GetSchemeListforApprove`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const schemeDraftPost = async (data) => {
    try {

      const response=axiosAPI.post(`SchemeGenerate/GetDraftedScheme`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  //SchemeGenerate/GetSchemeGenerate


  export const schemeGenerateApi = async (data) => {
    try {

      const {userRole,userId,userName} = decryptData(sessionStorage.getItem("userInfo"));
let url=(userRole=="SADM"||userRole=="ZADM")?"GetAllAMsSchemeDataForAwaitingApporaval":userRole=="NSH"?"GetAllAwaitingApporavalSchemeForNSH":"GetSchemeGenerate"
      const response=axiosAPI.post(`SchemeGenerate/${url}`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const getHoldSchemes = async (data) => {
    try {

      const response=axiosAPI.post(`HoldScheme/GetHoldSchemes`,data)
        .then((data) => {

          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  //UpdateScheme/ApprovalScheme


  export const updateApprovalScheme = async (data) => {
    try {

      const response=axiosAPI.post(`UpdateScheme/ApprovalScheme`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };


  //For Approve Click of Hold Schemes on Awaiting Screen
  export const updateApprovedHoldScheme = async (data) => {
    try {
      const response=axiosAPI.post(`HoldScheme/ApprovedHoldScheme`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

    //For Reject Click of Hold Schemes on Awaiting Screen
  export const updateRejectedHoldScheme = async (data) => {
    try {
      const response=axiosAPI.post(`HoldScheme/RejectHoldScheme`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  //UpdateScheme/UpdateSchemeGenerate


  export const updateSchemeGenerateApi = async (data) => {
    try {

      const response=axiosAPI.post(`UpdateScheme/UpdateSchemeGenerate`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
//SchemeGenerate/GetAllAMsSchemeDataForAwaitingApporavalCount

export const GetAllSchemeAwaitingCountData = async (data) => {
  try {
    const response=axiosAPI.post(`SchemeGenerate/GetAllAMsSchemeDataForAwaitingApporavalCount`,data)
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
          ToastifyShow("Something went wrong","error")
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};

export const GetHoldSchemesCountData = async (data) => {
  try {
    const response=axiosAPI.post(`SchemeGenerate/GetAllAMsHoldSchemeDataForAwaitingApporavalCount`,data)
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
          ToastifyShow("Something went wrong","error")
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};