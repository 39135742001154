import React from "react";
import { Divider, Card } from "antd";
import EditScheme from "../createSchemes/editScheme";
import SlabBasedEditScheme from "../createSchemes/editSchemes/slabBasedEdit";
import SingleTargetEditScheme from "../createSchemes/editSchemes/singleTargetEdit";
import SingleTargetWithUpperCapEdit from "../createSchemes/editSchemes/singleTargetWithUpperCapEdit";
import UnconditionalInstallationEdit from "../createSchemes/editSchemes/unconditionalInstallationEdit";
import DraftInstallationEdit from "./draftInstallationEdit";

const InstallationDraftEditScheme = () => {

  return (
    <div className="create_scheme_wrap approval-schme-row-cdo">
      <DraftInstallationEdit />
      {/* {schemeTypeId.includes(3) && <SlabBasedEditScheme type = "edit" editType ="draft"/>}
      {schemeTypeId.includes(8)  && <SingleTargetEditScheme type = "edit" editType ="draft"/>}
      {schemeTypeId.includes(4)  && <SingleTargetWithUpperCapEdit type = "edit" editType ="draft"/>}
      {schemeTypeId.includes(5)  && <UnconditionalInstallationEdit type = "edit" editType ="draft"/>} */}
    </div>
  );
};
export default InstallationDraftEditScheme;