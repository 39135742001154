import React, { useState, useEffect } from "react";
import ActionButton from "../../../components/actionButton/actionButton";
import dayjs, { Dayjs } from "dayjs";
import CollectionTable from "../../../components/table/CollectionTables";
import SlabBased from "../../../components/table/SlabBased";
import SingleTarget from "../../../components/table/SingleTarget";
import SingleTargetWithUpperCap from "../../../components/table/SingleTargetWithUpperCap";
import UnconditionalInstallation from "../../../components/table/UnconditionalInstallation";
import Status from "../../../components/StatusButton/Status";
import ToastifyShow from "../../../components/ToastifyShow";
import moment from "moment";
import XLSX from 'xlsx-js-style';
import { formatNumberDecimal } from "../../../utils/formatter";
const  StateHeadTable = (props) => {
  const [selectedSchemeType, setSelectedSchemeType] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const fetchGeneratedScheme = (demoParams) => {
    setSelectedSchemeType(demoParams.schemeType);
    props?.sendDataToParent(demoParams.schemeType);
    props?.SetTotalCountShow(true)
  };
  const captalizeObjKeys = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
    );

  const handleDownload=()=>{

    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };
  if(selectedSchemeType == 5){
      const data = dataSource
        .map((rec) => rec.scheme)
        .map((item) => {
          let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
          let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
          return captalizeObjKeys({
            SCHEME_NO: item.schemeNo,
            DEALER_CODE: item.dealerCode,
            DEALER_NAME: item.dealerName,
            STATE: item.stateName,
            DEALER_LOCATION: item.dealerLocation,
            TERRITORY_MANAGER: item.territoryManager,
            START_MONTH:
              formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
              END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
              ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month,
              INSTALLATION_EXPECTED: item.installationTarget,
              INC_PER_TRACTOR: item.incPerTarget,
              PAYOUT: item.p1,
              REMARKS: item.reason,
              HOLDREMARKS:item.holdReason?item.holdReason:"",

          });
        });
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error");
        return false;
      }
      let tab_text = `<table class="custom-table">
      <thead>
      <tr>
          <th >SCHEME NO</th>
          <th >DEALER CODE</th>
          <th >DEALER NAME</th>
          <th >STATE</th>
          <th >DEALER LOCATION</th>
          <th >TERRITORY MANAGER</th>
          <th >START MONTH</th>
          <th >END MONTH</th>
          <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th >INSTALLATION EXPECTED</th>
          <th >INC. PER TRACTOR</th>
          <th >PAYOUT</th>
          <th >REMARKS</th>
            <th > HOLD REMARKS</th>
          </tr>
      </thead>`;
  
        data?.forEach((x) => {
          tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
    <td>${x?.DEALER_CODE}</td>
    <td>${x?.DEALER_NAME}</td>
    <td>${x?.STATE}</td>
    <td>${x?.DEALER_LOCATION}</td>
    <td>${x?.TERRITORY_MANAGER}</td>
    <td>${x?.START_MONTH}</td>
    <td>${x?.END_MONTH}</td>
    <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
    <td>${x?.INSTALLATION_EXPECTED}</td>

    <td>${x?.INC_PER_TRACTOR}</td>
    <td>${x?.PAYOUT}</td>
    <td>${x?.REMARKS}</td>
     <td>${x?.HOLDREMARKS}</td>
    </tr>`;
        });

        let totals = {
          installationTarget: 0,
          incPerTarget: 0,
          p1:0,
          reason:"",
          holdReason:"",
         
      };
  
  dataSource.forEach((item) => {
          for (let key in totals) {
              totals[key] += parseInt(item.scheme[key]) || 0 ;
          }
      });
      totals.incPerTarget =  totals.installationTarget !== 0 ? parseInt(totals.p1 / totals.installationTarget):0;

      let totalsRow = "<tr style='background-color: #f2f2f2;' ><td colspan='9' >Total</td>";
      for (let key in totals) {
        if(key != 'reason' && key != 'holdReason'){
          if (key.startsWith('incPer') ) {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
   
     
        totalsRow += "</tr>";
        tab_text += totalsRow;
        tab_text += "</table>";

    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    document.body.removeChild(elt);

    
    const allColumns = data.reduce((columns, item) => {
      Object.keys(item).forEach(key => {
        if (!columns.includes(key)) {

          columns.push(key);
        }
      });
      return columns;
    }, []);




    function getAlphabetEquivalent(number) {
      if (number < 1 || number > 26) { return "Invalid input"; }
      return String.fromCharCode(number + 64);
    }
    let excelArr = []
    for (let index = 0; index < allColumns.length; index++) {
      let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
      excelArr.push(ele);

    }

    Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
      excelArr
        .includes(cell)).forEach(cell => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });



    // generate file
    XLSX.writeFile(wb, `Unconditional_Installation_${new Date().toJSON().slice(0, 10)}.xlsx`);
      // const url = window.URL.createObjectURL(
      //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
      // );
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", );
      // document.body.appendChild(link);
      // link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
    }
    else if(selectedSchemeType == 4){
      const data = dataSource
      .map((rec) => rec.scheme)
      .map((item) => {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        return captalizeObjKeys({
          SCHEME_NO: item.schemeNo,
          DEALER_CODE: item.dealerCode,
          DEALER_NAME: item.dealerName,
          STATE: item.stateName,
          DEALER_LOCATION: item.dealerLocation,
          TERRITORY_MANAGER: item.territoryManager,
          START_MONTH:
            formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
            END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
            ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month,
            INSTALLATION_TARGET: item.installationTarget,
            UPPER_CAP: item.upperCap,
            INC_PER_TRACTOR: item.incPerTarget,
            PAYOUT: item.p1,
            REMARKS: item.reason,
            HOLDREMARKS:item.holdReason?item.holdReason:"",
        });
      });
    if (data?.length == 0) {
      ToastifyShow("Something went wrong", "error");
      return false;
    }
    let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th>UPPER CAP</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        <th >HOLD REMARKS</th>

        </tr>
    </thead>`;

      data?.forEach((x) => {
        tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
  <td>${x?.DEALER_CODE}</td>
  <td>${x?.DEALER_NAME}</td>
  <td>${x?.STATE}</td>
  <td>${x?.DEALER_LOCATION}</td>
  <td>${x?.TERRITORY_MANAGER}</td>
  <td>${x?.START_MONTH}</td>
  <td>${x?.END_MONTH}</td>
  <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${x?.INSTALLATION_TARGET}</td>
  <td>${x?.UPPER_CAP}</td>
  <td>${x?.INC_PER_TRACTOR}</td>
  <td>${x?.PAYOUT}</td>
  <td>${x?.REMARKS}</td>
  <td>${x?.HOLDREMARKS}</td>

  </tr>`;
      });

      let totals = {
        installationTarget: 0,
        upperCap: 0,
        incPerTarget:0,
        p1: 0,
        reason:"",
        holdReason:""
       
    };

    dataSource.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item.scheme[key]) || 0 ;
        }
    });
    totals.incPerTarget =  totals.installationTarget !== 0 ? parseInt(totals.p1 / totals.installationTarget):0;

    let totalsRow = "<tr><td colspan='9' >Total</td>";
     for (let key in totals) {
      if(key != 'reason' && key != 'holdReason'){
        if (key.startsWith('incPer') ) {
          totalsRow += `<td>${totals[key]}</td>`;
      } else {
          totalsRow += `<td>${totals[key]}</td>`;
      }
      }else{
       totalsRow  += `<td></td>`;
      } 
    }
 
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);

      // Add style to Total row color
      const allColumns = data.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

  
      // generate file
      XLSX.writeFile(wb, `Installation_Single_Target_With_Upper_Cap${new Date().toJSON().slice(0, 10)}.xlsx`);
      // const url = window.URL.createObjectURL(
    //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
    // );
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", `Installation_Single_Target_With_Upper_Cap-${new Date().toJSON().slice(0, 10)}.xlsx`);
    // document.body.appendChild(link);
    // link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
    }
    else if(selectedSchemeType == 8){
      const data = dataSource
      .map((rec) => rec.scheme)
      .map((item) => {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        return captalizeObjKeys({
          SCHEME_NO: item.schemeNo,
          DEALER_CODE: item.dealerCode,
          DEALER_NAME: item.dealerName,
          STATE: item.stateName,
          DEALER_LOCATION: item.dealerLocation,
          TERRITORY_MANAGER: item.territoryManager,
          START_MONTH:
            formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
            END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
            ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month,
            INSTALLATION_TARGET: item.installationTarget,
            INC_PER_TRACTOR: item.incPerTarget,
            PAYOUT: item.p1,
            REMARKS: item.reason,
            HOLDREMARKS:item.holdReason?item.holdReason:"" ,
        });
      });
    if (data?.length == 0) {
      ToastifyShow("Something went wrong", "error");
      return false;
    }
    let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        <th > HOLD REMARKS</th>

        </tr>
    </thead>`;

      data?.forEach((x) => {
        tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
  <td>${x?.DEALER_CODE}</td>
  <td>${x?.DEALER_NAME}</td>
  <td>${x?.STATE}</td>
  <td>${x?.DEALER_LOCATION}</td>
  <td>${x?.TERRITORY_MANAGER}</td>
  <td>${x?.START_MONTH}</td>
  <td>${x?.END_MONTH}</td>
  <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${x?.INSTALLATION_TARGET}</td>
  <td>${x?.INC_PER_TRACTOR}</td>
  <td>${x?.PAYOUT}</td>
  <td>${x?.REMARKS}</td>
  <td>${x?.HOLDREMARKS}</td>

  </tr>`;
      });

      let totals = {
        installationTarget: 0,
        incPerTarget: 0,
        p1: 0,
        reason:"",
        holdReason:""
       
    };


    dataSource.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item.scheme[key]) || 0 ;
        }
    });
    totals.incPerTarget =  totals.installationTarget !== 0 ? parseInt(totals.p1 / totals.installationTarget):0;

      let totalsRow = "<tr><td colspan='9' >Total</td>";
      for (let key in totals) {
        if(key != 'reason' && key !='holdReason'){
          if (key.startsWith('incPer') ) {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
   
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);

       // Add style to total row color
       const allColumns = data.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });
  
      // generate file
      XLSX.writeFile(wb, `Download_Installation_Single_Target_${new Date().toJSON().slice(0, 10)}.xlsx`);      // const url = window.URL.createObjectURL(
    //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
    // );
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download",`Download_Installation_Single_Target-${new Date().toJSON().slice(0, 10)}.xlsx`);
    // document.body.appendChild(link);
    }
    else if(selectedSchemeType == 3){
      const data = dataSource.map(rec => rec.scheme).map(item=> {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        return captalizeObjKeys({
          Scheme_No: item.schemeNo,
          Dealer_Code: item.dealerCode,
          Dealer_Name: item.dealerName,
          State: item.stateName,
          Dealer_Location: item.dealerLocation,
          Territory_Manager: item.territoryManager,
          Start_Month:formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
          End_Month:formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
          Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
          Target_T1: item.t1,
          Target_T2: item.t2,
          Target_T3: item.t3,
          Inc_Per_Tractor_R1: item.r1,
          Inc_Per_Tractor_R2: item.r2,
          Inc_Per_Tractor_R3: item.r3,
          PAYOUT_P1: item.p1,
          PAYOUT_P2: item.p2,
          PAYOUT_P3: item.p3,
          Remarks: item.reason,
          TOP_TARGET:item.topTarget,
          HOLDREMARKS:item.holdReason?item.holdReason:"",
        })
      })
      if(data?.length==0){
      ToastifyShow("Something went wrong","error")
      return false
      }

      let tab_text = `<table>
      <thead>
      <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">TOP SLAB</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th colspan="3">TARGETS</th>
          <th colspan="3">INC. PER TRACTOR</th>
          <th colspan="3">PAYOUT</th>
          <th rowspan="2">REMARKS</th>
          <th rowspan="2"> HOLD REMARKS</th>

          </tr>
      <tr>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>P1</th>
         <th>P2</th>
         <th>P3</th>
        </tr>
      </thead>`;
     
      data?.forEach((header) => {
        tab_text += `<tr ><td>${header?.SCHEME_NO}</td>
  <td>${header?.DEALER_CODE}</td>
  <td>${header?.DEALER_NAME}</td>
  <td>${header?.STATE}</td>
  <td>${header?.DEALER_LOCATION}</td>
  <td>${header?.TERRITORY_MANAGER}</td>
  <td>${header?.TOP_TARGET?.toUpperCase()||""}</td>
  <td>${header?.START_MONTH}</td>
  <td>${header?.END_MONTH}</td>
  <td>${header?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${header?.TARGET_T1}</td>
  <td>${header?.TARGET_T2}</td>
  <td>${header?.TARGET_T3}</td>
  <td>${header?.INC_PER_TRACTOR_R1}</td>
  <td>${header?.INC_PER_TRACTOR_R2}</td>
  <td>${header?.INC_PER_TRACTOR_R3}</td>
  <td>${header?.PAYOUT_P1}</td>
  <td>${header?.PAYOUT_P2}</td>
  <td>${header?.PAYOUT_P3}</td>
  <td>${header?.REMARKS}</td>
   <td>${header?.HOLDREMARKS }</td>
  </tr>`;
      });

      let totals = {
        t1: 0,
        t2: 0,
        t3:0,
        r1: 0,
        r2: 0,
        r3: 0,
        p1: 0,
        p2: 0,
        p3: 0,
        reason:"",
        holdReason:""
       
    };

    dataSource.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item.scheme[key]) || 0 ;
        }
    });
    totals.r1 = totals.t1 !== 0 ? parseInt(totals.p1 / totals.t1) : 0;
    totals.r2 = totals.t2 !== 0 ? parseInt(totals.p2 / totals.t2) : 0;
    totals.r3 = totals.t3 !== 0 ? parseInt(totals.p3 / totals.t3) : 0;
  

      let totalsRow = "<tr><td colspan='10' >Total</td>";
      for (let key in totals) {
     
        if(key != 'reason' && key != 'holdReason'){
          if (key.startsWith('r')) {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
   
      totalsRow += "</tr>";
      tab_text += totalsRow;

      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      // Add style to Roral row
      const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(dataSource.length + 3).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });
  
      // generate file
      XLSX.writeFile(wb, `Slab_based_scheme_${new Date().toJSON().slice(0, 10)}.xlsx`);
    // const url = window.URL.createObjectURL(
    // new Blob([tab_text], { type: "application/vnd.ms-excel" })
    // );
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", `Slab_based_scheme.xlsx`);
    // document.body.appendChild(link);
    // link.click(); 
    }
    else if([1, 2].includes(selectedSchemeType)){
      if(dataSource?.length==0||!dataSource){
      ToastifyShow("Something went wrong","error")
      return false
      }

      let tab_text = `<table>
    <thead>
    <tr>
        <th rowspan="2">SCHEME NO</th>
        <th rowspan="2">DEALER CODE</th>
        <th rowspan="2">DEALER LOCATION</th>
        <th rowspan="2">DEALER NAME</th>
        <th rowspan="2">STATE</th>
        <th rowspan="2">TERRITORY MANAGER</th>
        <th rowspan="2">TOP SLAB</th>
        <th rowspan="2">START MONTH</th>
        <th rowspan="2">END MONTH</th>
        <th rowspan="2">Industry</th>
        <th rowspan="2">B</th>
        <th rowspan="2">I</th>
        <th rowspan="2">C</th>
     <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS.(IN LACS)</th>
        <th>BG</th>
        <th>INSTALLATION PLAN</th>
        <th colspan="3">BILLING DETAILS</th>
        <th rowspan="2">CLOSING STOCK</th>
        <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
        <th colspan="11">BOOKING COLLECTION TARGETS(NO. OF TRS)</th>
        <th colspan="6">PAYOUT RATE</th>
        <th rowspan="2">REMARKS</th>
         <th rowspan="2"> HOLD REMARKS</th>
        <th colspan="6">PAYOUT</th>
        <th colspan="3">TOTAL EXPECTED PAYOUT</th>
        </tr>
    <tr>
       <th>NOS</th>
       <th>Days</th>
       <th>LAST 3rd MONTH</th>
       <th>LAST 2nd MONTH</th>
       <th>LAST 1st MONTH</th>
              <th>LAST 1st MONTH DAYS</th>
       <th>LACS</th>
       <th>NOS</th>
       <th>B</th>
       <th>I</th>
       <th>C</th>
       <th>T1</th>
       <th>T2</th>
       <th>T2-T1</th>
       <th>T3</th>
       <th>T3-T2</th>
       <th>T4</th>
       <th>T4-T3</th>
       <th>T5</th>
       <th>T5-T4</th>
       <th>T6</th>
       <th>T6-T5</th>
       <th>R1</th>
       <th>R2</th>
       <th>R3</th>
       <th>R4</th>
       <th>R5</th>
       <th>R6</th>
       <th>P1</th>
       <th>P2</th>
       <th>P3</th> 
       <th>P4</th>
       <th>P5</th>
       <th>P6</th>  
       <th>Expected Volume</th>
       <th>PER TR.</th>
       <th>AMOUNT</th>  
      </tr>
    </thead>`;

      dataSource?.forEach((x) => {
        tab_text += `<tr ><td>${x?.scheme?.schemeNo}</td>
  <td>${x?.scheme?.dealerCode}</td>
  <td>${x?.scheme?.dealerLocation}</td>
  <td>${x?.scheme?.dealerName}</td>
  <td>${x?.scheme?.stateName}</td>
  <td>${x?.scheme?.territoryManager}</td>
  <td>${x?.scheme?.topTarget?.toUpperCase()||""}</td>
  <td>${moment(x?.scheme?.startMonth).format("MMM-YY")||""}</td>
  <td>${moment(x?.scheme?.endMonth).format("MMM-YY")||""}</td>
  <td>${x?.scheme?.industry}</td>
  <td>${x?.scheme?.oldBillingQty}</td>
  <td>${x?.scheme?.oldInstallation}</td>
  <td>${x?.scheme?.oldCollection||0}</td>
  <td>${x?.scheme?.oldOpeningStock}</td>
  <td>${x?.scheme?.openingStockDays}</td>
  <td>${formatNumberDecimal(x?.scheme?.closingOutStandingMonth_1)}</td>
  <td>${formatNumberDecimal(x?.scheme?.closingOutStandingMonth_2)}</td>
  <td>${formatNumberDecimal(x?.scheme?.closingOutStandingMonth_3)}</td>
  <td>${x?.scheme?.outStandingDays}</td>
  <td>${x?.scheme?.bGLacsCurrentYear||0}</td>
  <td>${x?.scheme?.delayPlan}</td>
  <td>${x?.scheme?.billing}</td>
  <td>${x?.scheme?.installation}</td>
  <td>${x?.scheme?.collection}</td>
  <td>${x?.scheme?.closingStock}</td>
  <td>${x?.scheme?.closingOutStanding}</td>
  <td>${x?.scheme?.t1}</td>
  <td>${x?.scheme?.t2}</td>
  <td>${ x?.scheme?.t2_T1 && x?.scheme?.topTarget?.includes("t2")?(x?.scheme?.t2_T1) :0}</td>
  <td>${x?.scheme?.t3}</td>
  <td>${x?.scheme?.t3_T2 && x?.scheme?.topTarget?.includes("t3")?(x?.scheme?.t3_T2) :0}</td>
  <td>${x?.scheme?.t4}</td>
  <td>${ x?.scheme?.t4_T3 && x?.scheme?.topTarget?.includes("t4") ? (x?.scheme?.t4_T3)  :0}</td>
  <td>${x?.scheme?.t5}</td>
  <td>${x?.scheme?.t5_T4 && x?.scheme?.topTarget?.includes("t5") ? (x?.scheme?.t5_T4)  :0}</td>
  <td>${x?.scheme?.t6}</td>
  <td>${x?.scheme?.t6_T5 && x?.scheme?.topTarget?.includes("t6") ? (x?.scheme?.t6_T5)  :0}</td>
  <td>${x?.scheme?.r1}</td>
  <td>${x?.scheme?.r2}</td>
  <td>${x?.scheme?.r3}</td>
  <td>${x?.scheme?.r4}</td>
  <td>${x?.scheme?.r5}</td>
  <td>${x?.scheme?.r6}</td>
  <td>${x?.scheme?.reason}</td>
  <td>${x?.scheme?.holdReason?x?.scheme?.holdReason:""}</td>
  <td>${x?.scheme?.p1}</td>
  <td>${x?.scheme?.p2}</td>
  <td>${x?.scheme?.p3}</td>
  <td>${x?.scheme?.p4}</td>
  <td>${x?.scheme?.p5}</td>
  <td>${x?.scheme?.p6}</td>
  <td>${x?.scheme?.volume}</td>
  <td>${x?.scheme?.perTr}</td>
  <td>${x?.scheme?.amount}</td>
  </tr>`;
      });


      let totals = {
        delayPlan: 0,
        billing: 0,
        installation:0,
        collection: 0,
        closingStock:0,
        closingOutStanding:0,
        t1: 0,
        t2: 0,
        t2_T1: 0,
        t3: 0,
        t3_T2: 0,
        t4: 0,
        t4_T3: 0,
        t5: 0,
        t5_T4: 0,
        t6: 0,
        t6_T5: 0,
        r1: 0,
        r2: 0,
        r3: 0,
        r4: 0,
        r5: 0,
        r6: 0,
        reason:"",
        holdReason:"",
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        volume: 0,
        perTr: 0,
        amount: 0,
       
    };

    dataSource.forEach((item) => {
      for (let key in totals) {
        if (key === "t2_T1" && item.scheme.topTarget.includes("t2")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t3_T2" && item.scheme.topTarget.includes("t3")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t4_T3" && item.scheme.topTarget.includes("t4")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t5_T4" && item.scheme.topTarget.includes("t5")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t6_T5" && item.scheme.topTarget.includes("t6")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (!["t2_T1", "t3_T2", "t4_T3", "t5_T4", "t6_T5"].includes(key)) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        }
      }
    });

   
  totals.r1 = totals.t1 !== 0 ? parseInt(totals.p1 / totals.t1) : 0;
  totals.r2 = totals.t2 !== 0 ? parseInt(totals.p2 / totals.t2) : 0;
  totals.r3 = totals.t3 !== 0 ? parseInt(totals.p3 / totals.t3) : 0;
  totals.r4 = totals.t4 !== 0 ? parseInt(totals.p4 / totals.t4) : 0;
  totals.r5 = totals.t5 !== 0 ? parseInt(totals.p5 / totals.t5) : 0;
  totals.r6 = totals.t6 !== 0 ? parseInt(totals.p6 / totals.t6) : 0; 
  totals.perTr =  totals.volume !== 0 ? Math.round(totals.amount / totals.volume):0;
  

      let totalsRow = "<tr><td colspan='20' >Total</td>";
      for (let key in totals) {
        if(key != 'reason' && key != 'closingStock' && key !='closingOutStanding' && key != 'holdReason'){
          if (key.startsWith('r') ) {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
   
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);

      // Add style to total row
      const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {
            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {

        if (number < 1) {
          return "Invalid input";
      } else if (number <= 26) {
          return String.fromCharCode(number + 64);
      } else {
          let firstChar = String.fromCharCode(((number - 1) / 26) + 64);
          let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
          return firstChar + secondChar;
      }
  
      }

      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(dataSource.length + 3).toString()}`
        excelArr.push(ele);

      }
      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });
      let nameFile=selectedSchemeType==1?"With_DeliveryPlan.xlsx":"Without_Delivery_Plan.xlsx"
      // generate file
      XLSX.writeFile(wb, nameFile);
    
    }
  }
  const getSchemeType = (schemeType) => {
    if (schemeType == 1) {
      return "Collection - With Delivery Plan";
    }
    if (schemeType == 2) {
      return "Collection - Without Delivery Plan";
    }
    if (schemeType == 3) {
      return "Installation - Slab Based";
    }
    if (schemeType == 8) {
      return "Installation - Single Target";
    }
    if (schemeType == 4) {
      return "Installation - Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Installation - Unconditional Installation";
    }
  };
  return (
    <div
      id="table"
      className="create_scheme_wrap collection-schme-box onemoth-date-selector "
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ fontSize: "18px", padding: "20px" }}>
            {getSchemeType(selectedSchemeType)}
          </h2>
          <div className="status-btn-col-box">
            <button className="action_button c-white mx-1" disabled={dataSource?.length == 0} onClick={handleDownload}>Download</button>
            <Status
              selectedStatus={selectedStatus}
              handleStatusDropdown={(statusId) => setSelectedStatus(statusId)}
            ></Status>

            <ActionButton onGetData={fetchGeneratedScheme} />
          </div>
        </div>
      </div>
      {[1, 2].includes(selectedSchemeType) && (
        <>
          <CollectionTable
            selectedStatus={selectedStatus}
            onSetCardData={props.onSetCardData}
            selectedSchemeType={selectedSchemeType}
            setDataSource={setDataSource}
            dataSource={dataSource}
            fyear={props?.fyear}
          />


        </>
      )}
      {selectedSchemeType == 3 && (
        <SlabBased
          onSetCardData={props.onSetCardData}
          selectedSchemeType={selectedSchemeType}
          setDataSource={setDataSource}
          dataSource={dataSource}
        />
      )}
      {selectedSchemeType == 8 && (
        <SingleTarget
          onSetCardData={props.onSetCardData}
          selectedSchemeType={selectedSchemeType}
          setDataSource={setDataSource}
          dataSource={dataSource}
        />
      )}
      {selectedSchemeType == 4 && (
        <SingleTargetWithUpperCap
          onSetCardData={props.onSetCardData}
          selectedSchemeType={selectedSchemeType}
          setDataSource={setDataSource}
          dataSource={dataSource}
        />
      )}
      {selectedSchemeType == 5 && (
        <UnconditionalInstallation
          onSetCardData={props.onSetCardData}
          selectedSchemeType={selectedSchemeType}
          setDataSource={setDataSource}
          dataSource={dataSource}
        />
      )}

      {/* <div>
        <Row
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
          }}
        >
          <div
            className="All_scheme_status"
            style={{ marginRight: "148px", marginTop: "36px" }}
          >
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "green" }}
              ></div>
              Approved
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "red" }}
              ></div>
              Rejected
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "yellow" }}
              ></div>
              Work in Progress
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "rgb(255, 0, 255)" }}
              ></div>
              Auto Rejected
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "#BCB38D" }}
              ></div>
              Devation
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "#9999ff" }}
              ></div>
              Withdrawn
            </div>
          </div>

        </Row>
      </div> */}
    </div>
  );
};
export default StateHeadTable;
