import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import "../../createSchemes/createScheme.css";
import { Button, Table, Col, Row, Form, message, Select, Tag, Modal, Space, Tooltip, Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import axiosAPI from "../../../../services/axiosApi";
import { useFormik } from "formik";
import { schemeList } from "../../../../redux/collection/SchemeList";
import { getCurrentFiscalYear } from "../../../../utils/dateFormatter";
import moment from "moment";
import { ArrowBack, ArrowBackRounded } from "@mui/icons-material";
import { getUpdateModifiedAwaitingDataDetails, resetSaveModification } from "../../../../redux/modification/modificationSlice";
import SMDialog from "../../../../components/modal/alertModal/CommonModal";
import ValidationErrorsModal from "../../../../components/modal/alertModal/ValidationErrorsModal";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
import { getAwaitingLockSchemeAsync, getLockedZonalHeadApiAsync } from "../../../../redux/stateData/stateDataSlice";
import ValidationDifferentSchemeError from "../../../../components/modal/alertModal/ValidationDifferentSchemeError";
import { postUpdateHoldScheme } from "../../../../redux/hold/holdDataSlice";
import { useNavigate } from "react-router-dom";
import ToastifyShow from "../../../../components/ToastifyShow";
import { isValidContentType, isValidFileHeader } from "../../../../config";
import XLSX from "xlsx-js-style";
import { parseFiveDigitNumber } from "../../../../components/download/collectionSchemeUpload";
import { ModificationCollectionSchemeDownload } from "../ModificationCollectionSchemeDownload";


const EditModifiedAwaitingCollection = ({ backBtn, schemeDetails, selectedLabel }) => {
  const dispatch = useDispatch();
  const { userId, userName, userRole } = decryptData(sessionStorage.getItem("userInfo"));
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [schemeLockedStatus, setSchemeLockedStatus] = useState([])
  const [submitData, setSubmitData] = useState({})
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [checkCalculate, setCheckCalculate] = useState(false);
  const [checkCalculateArr, setCheckCalculateArr] = useState([]);
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });
  const [codeError, setCodeError] = useState([]);
  const [openErrorDialog, setOpenErrorDialog] = useState({
    open: false,
    errorsFields: {},
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {

    if (submitData?.[0]?.holdStatus == 9) {
      dispatch(postUpdateHoldScheme(submitData)).then(async (data) => {
        if (data.payload?.errorMessage == "Hold Scheme Updated successfully!!") {
          message.success({
            content: "Record Updated Successfully",
            className: "custom-message",
          });
          await backBtn(false)
        }
      });
      setIsModalOpen(false);
    } else {
      dispatch(getUpdateModifiedAwaitingDataDetails(submitData))
        .then(async (result) => {
          if (result.payload?.errorMessage == "Data Saved successfully!!") {
            message.success({
              content: "Record Updated Successfully",
              className: "custom-message",
            });
            await backBtn(false)
            await dispatch(resetSaveModification())
          }
        })
      setIsModalOpen(false);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const formik = useFormik({
    initialValues: [],
  });

  useEffect(() => {
    if (userRole == "ZADM" || userRole == "NSH") {
      dispatch(getLockedZonalHeadApiAsync({ "fyear": "FY24", "userid": userId })).then((x) => {

        if (x?.payload?.data?.[0]) {
          setSchemeLockedStatus(x?.payload?.data)
        }
      })
    }
    if (userRole == "NSH") {
      axiosAPI
        .post(`Dashboard/GetSchemeStatus`)
        .then((result) => {
          setSchemeLockedStatus(result.data.data)
        })

    }
  }, [])

  const topTargetArr = (x) => [
    {
      name: "T1",
      value: "t1",

    },
    {
      name: "T2",
      value: "t2",

    },
    {
      name: "T3",
      value: "t3",

    },
    {
      name: "T4",
      value: "t4",
      disabled: !x?.t4 ? true : false,
    },
    {
      name: "T5",
      value: "t5",
      disabled: !x?.t5 ? true : false,
    },
    {
      name: "T6",
      value: "t6",
      disabled: !x?.t6 ? true : false,
    },
  ];
  const topTargetArr1 = (x) => [
    {
      name: "T1",
      value: "t1",

    },
    {
      name: "T2",
      value: "t2",

    },
    {
      name: "T3",
      value: "t3",

    },
    {
      name: "T4",
      value: "t4",
      disabled: !x?.t4 ? true : false,
    },
    {
      name: "T5",
      value: "t5",
      disabled: !x?.t5 ? true : false,
    },
    {
      name: "T6",
      value: "t6",
      disabled: !x?.t6 ? true : false,
    },
  ]
  const {loadingState } = useSelector((state) => state.modifyCollection);

  const showErrorDialog = (errFields) => {
    setOpenErrorDialog((openErrorDialog) => ({
      open: true,
      errorsFields: errFields ? errFields : {},
    }));
  };

  useEffect(() => {
    if (schemeDetails) {
      let data1 = schemeDetails.map((x) => ({
        ...x,
        dealerCode: x.dealerCode,
        dealerId: x.dealerId,
        dealerName: x.dealerName,
        dealerLocation: x.dealerLocation,
        stateName: x.stateName,
        territoryManager: x.territoryManager || "",
        startMonth: moment(x.startMonth).format("MMM YY"),
        endMonth: moment(x.endMonth).format("MMM YY"),
        reason: x.reason,
        billing: x.billing ? +x.billing : 0,
        installation: x.installation ? +x.installation : 0,
        collection: Math.round(x.collection ? +x.collection : 0),
        delayPlan: x.delayPlan,
        schemeTypeId: x.schemeTypeId,
        bookCollectionId: x.bookCollectionId,
        payoutId: x.payoutId,
        schemeId: x.schemeId,
        schemeNo: x.schemeNo,
        schemePlanId: x.schemePlanId,
        openingStockDays: x.openingStockDays,
        outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
        t1: +x.t1,
        t2: +x.t2,
        t3: +x.t3,
        t4: +x.t4,
        t5: +x.t5,
        t6: +x.t6,
        t2_T1: x.t2_T1,
        t3_T2: x.t3_T2,
        t4_T3: x.t4_T3,
        t5_T4: x.t5_T4,
        t6_T5: x.t6_T5,
        p1: x.p1,
        p2: x.p2,
        p3: x.p3,
        p4: x.p4,
        p5: x.p5,
        p6: x.p6,
        r1: +x.r1,
        r2: +x.r2,
        r3: +x.r3,
        r4: +x.r4,
        r5: +x.r5,
        r6: +x.r6,
        volume: x.volume,
        perTr: Math.round(x.perTr),
        amount: x.amount,
        industry: x.industry ? parseInt(x.industry) : 0,
        oldBillingQty: x.oldBillingQty,
        oldInstallation: x.oldInstallation,
        oldCollection: Math.round(x.oldCollection),
        oldOpeningStock: x.oldOpeningStock,
        closingOutStandingMonth_1: x.closingOutStandingMonth_1,
        closingOutStandingMonth_2: x.closingOutStandingMonth_2,
        closingOutStandingMonth_3: x.closingOutStandingMonth_3,
        bgLacsCurrentYear: x.bgLacsCurrentYear,
        stateId: x?.stateId,
        dropdownscheme: selectedLabel === 'Hold Schemes' ? x?.holdTopTarget?.split(",") : x?.modifiedTopTarget?.split(",") || [],
        TopTarget: x?.dropdownscheme?.toString() || "",
        topTargetArrState: (Array?.isArray(schemeLockedStatus)) ? schemeLockedStatus.filter((y) => y?.stateId == x?.stateId)?.[0] || [{
          t1: false,
          t2: false,
          t3: false,
          t4: false,
          t5: false,
          t6: false
        }] : [{
          t1: false,
          t2: false,
          t3: false,
          t4: false,
          t5: false,
          t6: false
        }],
        // topTargetArrState: schemeLockedStatus?.filter((y) => y?.stateId == x?.stateId)?.[0] || {},
        closingStock: x.closingStock,
        closingOutStanding: x.closingOutStanding,
        billingFactorCOSx: x.billingFactorCOS
      }));

      formik?.setValues(data1);
    }
  }, [schemeDetails, schemeLockedStatus]);

  const handleErrorModal = (firstItem, secondItem, opt = false) => {
    setOpenErrorModal(opt ? opt : !openErrorModal);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };
  const calculateFormula = (index) => {
    let y = formik?.values[index];
    let {

      modifiedT1,
      modifiedT2,
      modifiedT3,
      modifiedR1,
      modifiedR2,
      modifiexdR3,
      modifiedVolume,
      modifiedAmount,
      dropdownscheme,
      billingFactorCOS,
      oldOpeningStock,
      closingOutStandingMonth_1
    } = formik?.values[index];




    let billing = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdBilling : formik?.values?.[index]?.modifiedBilling;
    let collection = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdCollection : formik?.values?.[index]?.modifiedCollection;
    let installation = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdInstallation : formik?.values?.[index]?.modifiedInstallation;

    let t1 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT1 : formik?.values?.[index]?.modifiedT1;
    let t2 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT2 : formik?.values?.[index]?.modifiedT2;
    let t3 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT3 : formik?.values?.[index]?.modifiedT3;
    let t4 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT4 : formik?.values?.[index]?.modifiedT4;
    let t5 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT5 : formik?.values?.[index]?.modifiedT5;
    let t6 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT6 : formik?.values?.[index]?.modifiedT6;
    let r1 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR1 : formik?.values?.[index]?.modifiedR1;
    let r2 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR2 : formik?.values?.[index]?.modifiedR2;
    let r3 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR3 : formik?.values?.[index]?.modifiedR3;
    let r4 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR4 : formik?.values?.[index]?.modifiedR4;
    let r5 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR5 : formik?.values?.[index]?.modifiedR5;
    let r6 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR6 : formik?.values?.[index]?.modifiedR6;
    let p1 = formik?.values?.[index]?.modifiedP1;
    let volume = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdVolume : formik?.values?.[index]?.modifiedVolume
    let amount = formik?.values?.[index]?.modifiedAmount;
    const TEPvol1 = volume ? parseInt(volume) : 0;

    const handleDropDownSchemeCheckIndex = () => {
      let { t1, t2, t3, t4, t5, t6 } = dropdownscheme?.reduce((a, v) => ({ ...a, [v]: v }), {});
      return t1 && !t2 && !t3 && !t4 && !t5 && !t6
        ? "t1"
        : t1 && t2 && !t3 && !t4 && !t5 && !t6
          ? "t2"
          : t1 && t2 && t3 && !t4 && !t5 && !t6
            ? "t3"
            : t1 && t2 && t3 && t4 && !t5 && !t6
              ? "t4"
              : t1 && t2 && t3 && t4 && t5 && !t6
                ? "t5"
                : t1 && t2 && t3 && t4 && t5 && t6
                  ? "t6"
                  : "";
    };
    let validationSoftTarget = handleDropDownSchemeCheckIndex();


    let calculateTepAMount1 = () => {
      switch (validationSoftTarget) {
        case "t1":
          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : parseInt(TEPvol1)
              ? parseInt(TEPvol1) * parseInt(r1) : 0
        case "t2":
          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : (parseInt(TEPvol1) >= parseInt(t1)) && (parseInt(TEPvol1) < parseInt(t2))
              ? parseInt(TEPvol1) * parseInt(r1)
              : parseInt(TEPvol1)
                ? parseInt(TEPvol1) * parseInt(r2) : 0
        case "t3":
          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : (parseInt(TEPvol1) >= parseInt(t1)) && (parseInt(TEPvol1) < parseInt(t2))
              ? parseInt(TEPvol1) * parseInt(r1)
              : (parseInt(TEPvol1) >= parseInt(t2)) && (parseInt(TEPvol1) < parseInt(t3))
                ? parseInt(TEPvol1) * parseInt(r2)
                : parseInt(TEPvol1)
                  ? parseInt(TEPvol1) * parseInt(r3) : 0
        case "t4":
          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : (parseInt(TEPvol1) >= parseInt(t1)) && (parseInt(TEPvol1) < parseInt(t2))
              ? parseInt(TEPvol1) * parseInt(r1)
              : (parseInt(TEPvol1) >= parseInt(t2)) && (parseInt(TEPvol1) < parseInt(t3))
                ? parseInt(TEPvol1) * parseInt(r2)
                : (parseInt(TEPvol1) >= parseInt(t3)) && (parseInt(TEPvol1) < parseInt(t4))
                  ? parseInt(TEPvol1) * parseInt(r3)
                  : parseInt(TEPvol1)
                    ? parseInt(TEPvol1) * parseInt(r4) : 0

        case "t5":
          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : (parseInt(TEPvol1) >= parseInt(t1)) && (parseInt(TEPvol1) < parseInt(t2))
              ? parseInt(TEPvol1) * parseInt(r1)
              : (parseInt(TEPvol1) >= parseInt(t2)) && (parseInt(TEPvol1) < parseInt(t3))
                ? parseInt(TEPvol1) * parseInt(r2)
                : (parseInt(TEPvol1) >= parseInt(t3)) && (parseInt(TEPvol1) < parseInt(t4))
                  ? parseInt(TEPvol1) * parseInt(r3)
                  : (parseInt(TEPvol1) >= parseInt(t4)) && (parseInt(TEPvol1) < parseInt(t5))
                    ? parseInt(TEPvol1) * parseInt(r4)
                    : parseInt(TEPvol1)
                      ? parseInt(TEPvol1) * parseInt(r5) : 0;


        case "t6":

          return parseInt(TEPvol1) < parseInt(t1)
            ? 0
            : (parseInt(TEPvol1) >= parseInt(t1)) && (parseInt(TEPvol1) < parseInt(t2))
              ? parseInt(TEPvol1) * parseInt(r1)
              : (parseInt(TEPvol1) >= parseInt(t2)) && (parseInt(TEPvol1) < parseInt(t3))
                ? parseInt(TEPvol1) * parseInt(r2)
                : (parseInt(TEPvol1) >= parseInt(t3)) && (parseInt(TEPvol1) < parseInt(t4))
                  ? parseInt(TEPvol1) * parseInt(r3)
                  : (parseInt(TEPvol1) >= parseInt(t4)) && (parseInt(TEPvol1) < parseInt(t5))
                    ? parseInt(TEPvol1) * parseInt(r4)
                    : (parseInt(TEPvol1) >= parseInt(t5)) && (parseInt(TEPvol1) < parseInt(t6))
                      ? parseInt(TEPvol1) * parseInt(r5)
                      : parseInt(TEPvol1)
                        ? parseInt(TEPvol1) * parseInt(r6)
                        : 0;
      }
    }
    let calculateTepAMount = calculateTepAMount1()
    let TEPpertr1Calculate = (calculateTepAMount / volume)


    //Calculate ClosingStock
    const calculateClosingStock = () => {
      return (oldOpeningStock + parseInt(billing)) - installation;
    };
    const modifiedclosingStock = calculateClosingStock();

    //Calculate Closing OutStanding
    const calculateClosingOutStanding = () => {
      const closingOutstanding = ((parseFloat(closingOutStandingMonth_1 * 100000)) 
        + (parseInt(billing) * parseFloat(billingFactorCOS)))
        - (parseInt(collection) * parseFloat(billingFactorCOS));

      return closingOutstanding / 100000;
    };

    const modifiedclosingOutStanding = calculateClosingOutStanding().toFixed(2);

    let slabAchieve;

    if (validationSoftTarget == "t4") {

      slabAchieve = ((TEPvol1 >= t1) && (TEPvol1 < t2))
        ? "T1"
        : (TEPvol1 < t1) || (TEPvol1 == 0)
          ? 0
          : TEPvol1 >= t2 &&
            TEPvol1 < t3
            ? "T2" :
            TEPvol1 >= t3 &&
              TEPvol1 < t4
              ? "T3"
              : "T4";
    } else if (validationSoftTarget == "t5") {
      slabAchieve = ((TEPvol1 >= t1) && (TEPvol1 < t2))
        ? "T1"
        : (TEPvol1 < t1) || (TEPvol1 == 0)
          ? 0
          : TEPvol1 >= t2 &&
            TEPvol1 < t3
            ? "T2" :
            TEPvol1 >= t3 &&
              TEPvol1 < t4
              ? "T3" :
              TEPvol1 >= t4 &&
                TEPvol1 < t5
                ? "T4"
                : "T5";

    } else if (validationSoftTarget == "t6") {
      slabAchieve = ((TEPvol1 >= t1) && (TEPvol1 < t2))
        ? "T1"
        : (TEPvol1 < t1) || (TEPvol1 == 0)
          ? 0
          : TEPvol1 >= t2 &&
            TEPvol1 < t3
            ? "T2" :
            TEPvol1 >= t3 &&
              TEPvol1 < t4
              ? "T3" :
              TEPvol1 >= t4 &&
                TEPvol1 < t5
                ? "T4" :
                TEPvol1 >= t5 &&
                  TEPvol1 < t6
                  ? "T5"
                  : "T6";
    } else {
      slabAchieve = ((TEPvol1 >= t1) && (TEPvol1 < t2))
        ? "T1"
        : TEPvol1 < t1 || TEPvol1 == 0
          ? 0
          : TEPvol1 >= t2 &&
            TEPvol1 < t3
            ? "T2"
            : "T3";
    }

    formik?.setFieldValue(index, {
      ...y,
      modifiedT2_T1: (+((t2 && t1) && (
        parseInt(t2 || 0) -
        parseInt(t1 || 0))) || 0
      ),

      holdT2_T1: (+((t2 && t1) && (
        parseInt(t2 || 0) -
        parseInt(t1 || 0))) || 0
      ),
      modifiedT3_T2: (+((t3 && t2) && (
        parseInt(t3 || 0) -
        parseInt(t2 || 0)) || 0
      )
      ),
      holdT3_T2: (+((t3 && t2) && (
        parseInt(t3 || 0) -
        parseInt(t2 || 0)) || 0
      )
      ),
      modifiedT4_T3: (+((t4 && t3) &&
        (parseInt(t4 || 0) -
          parseInt(t3 || 0))) || 0
      ),
      holdT4_T3: (+((t4 && t3) &&
        (parseInt(t4 || 0) -
          parseInt(t3 || 0))) || 0
      ),
      modifiedT5_T4: (+((t5 && t4) &&
        (parseInt(t5 || 0) -
          parseInt(t4 || 0))) || 0
      ),
      holdT5_T4: (+((t5 && t4) &&
        (parseInt(t5 || 0) -
          parseInt(t4 || 0))) || 0
      ),
      modifiedT6_T5: (+((t6 && t5) &&
        (parseInt(t6 || 0) -
          parseInt(t5 || 0))) || 0
      ),
      holdT6_T5: (+((t6 && t5) &&
        (parseInt(t6 || 0) -
          parseInt(t5 || 0))) || 0
      ),
      modifiedP1:
        t1 && r1
          ? parseInt(t1 || 0) * parseInt(r1 || 0)
          : 0,
      holdP1:
        t1 && r1
          ? parseInt(t1 || 0) * parseInt(r1 || 0)
          : 0,
      modifiedP2:
        t2 && r2
          ? parseInt(t2 || 0) * parseInt(r2 || 0)
          : 0,
      holdP2:
        t2 && r2
          ? parseInt(t2 || 0) * parseInt(r2 || 0)
          : 0,
      modifiedP3:
        t3 && r3
          ? parseInt(t3 || 0) * parseInt(r3 || 0)
          : "",
      holdP3:
        t3 && r3
          ? parseInt(t3 || 0) * parseInt(r3 || 0)
          : "",
      modifiedP4:
        t4 && r4
          ? parseInt(t4 || 0) * parseInt(r4 || 0)
          : "",
      holdP4:
        t4 && r4
          ? parseInt(t4 || 0) * parseInt(r4 || 0)
          : "",
      modifiedP5:
        t5 && r5
          ? parseInt(t5 || 0) * parseInt(r5 || 0)
          : "",
      holdP5:
        t5 && r5
          ? parseInt(t5 || 0) * parseInt(r5 || 0)
          : "",
      modifiedP6:
        t6 && r6
          ? parseInt(t6 || 0) * parseInt(r6 || 0)
          : "",
      holdP6:
        t6 && r6
          ? parseInt(t6 || 0) * parseInt(r6 || 0)
          : "",

      modifiedPerTr: Math.round(TEPpertr1Calculate),
      holdPerTr: Math.round(TEPpertr1Calculate),
      modifiedVol: volume,
      modifiedAmount: Math.round(calculateTepAMount),
      holdAmount: Math.round(calculateTepAMount),
      modifiedclosingStock: modifiedclosingStock,
      holdclosingStock: modifiedclosingStock,
      modifiedclosingOutStanding: modifiedclosingOutStanding,
      holdclosingOutStanding: modifiedclosingOutStanding,
    });


    if (
      t1 != null &&
      t2 != null &&
      +t2 <= +t1 &&
      (validationSoftTarget == "t2" || validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )

    ) {
      handleErrorModal("T2", "T1", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   t2: "",
      // });
    }

    if (
      t2 != null &&
      t3 != null &&
      +t3 <= +t2 &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T3", "T2", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   t3: "",
      // });
    }

    if (
      t3 != null &&
      t4 != null &&
      +t4 <= +t3 &&
      (validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T4", "T3", true);
      return false
    }

    if (
      t4 != null &&
      t5 != null &&
      +t5 <= +t4 &&
      (validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T5", "T4", true);
      return false
    }
    if (
      t5 != null &&
      t6 != null &&
      +t6 <= +t5 &&
      (validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T6", "T5", true);
      return false
    }
    if (
      r2 != null &&
      r1 != null &&
      +r2 <= +r1 &&
      (validationSoftTarget == "t2" || validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R2", "R1", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r2: "",
      // });
    }

    if (
      r3 != null &&
      r2 != null &&
      +r3 <= +r2
      &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R3", "R2", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r3: "",
      // });
    }


    if (
      r4 != null &&
      r3 != null &&
      +r4 <= +r3
      &&
      (
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R4", "R3", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r3: "",
      // });
    }

    if (
      r5 != null &&
      r4 != null &&
      +r5 <= +r4
      &&
      (validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R5", "R4", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r3: "",
      // });
    }

    if (
      r6 != null &&
      r5 != null &&
      +r6 <= +r5
      &&
      (validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R6", "R5", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r3: "",
      // });
    }
    // const ModifiedTEPvol1 = modifiedVolume ? parseInt(modifiedVolume) : 0;

    // let modifiedcalculateTepAMount = (ModifiedTEPvol1 >= modifiedT1 &&
    //   ModifiedTEPvol1 < modifiedT2) ? (
    //   parseInt(ModifiedTEPvol1) * parseInt(modifiedR1)
    // ) : (ModifiedTEPvol1 < modifiedT1 || modifiedVolume == 0) ? 0 : ModifiedTEPvol1 >= modifiedT2 && ModifiedTEPvol1 < modifiedT3 ?
    //   parseInt(ModifiedTEPvol1) * parseInt(modifiedR2) :
    //   parseInt(ModifiedTEPvol1) * parseInt(modifiedR3)

    // amount == "NaN" && (modifiedcalculateTepAMount = modifiedVolume)
    // let modifiedTEPpertr1Calculate = ModifiedTEPvol1 ? Math.round(
    //   parseInt(modifiedcalculateTepAMount) / parseInt(ModifiedTEPvol1)
    // ) : ((parseInt(modifiedcalculateTepAMount) / parseInt(ModifiedTEPvol1)) == "NaN") ? modifiedVolume : 0

    // formik?.setFieldValue(index, {
    //   ...y,
    //   "modifiedT2_T1": +(
    //     parseInt(modifiedT2 || 0) -
    //     parseInt(modifiedT1 || 0)
    //   ),
    //   "modifiedT3_T2": +(
    //     parseInt(modifiedT3 || 0) -
    //     parseInt(modifiedT2 || 0)
    //   ),
    //   modifiedP1:
    //     (parseInt(modifiedT1 || 1) *
    //       parseInt(modifiedR1 || 1)) || "",
    //   modifiedP2:
    //     (parseInt(modifiedT2 || 1) *
    //       parseInt(modifiedR2 || 1)) || "",
    //   modifiedP3:
    //     (parseInt(modifiedT3 || 1) *
    //       parseInt(modifiedR3 || 1)) || "",
    //   modifiedR1: modifiedR1,
    //   modifiedR2: modifiedR2,
    //   modifiedR3: modifiedR3,
    //   modifiedPerTr: Math.round(modifiedTEPpertr1Calculate),
    //   modifiedAmount: Math.round(modifiedcalculateTepAMount),
    // });
    // if (
    //   modifiedR2 != null &&
    //   modifiedR1 != null &&
    //   +modifiedR2 <= +modifiedR1
    // ) {
    //   handleErrorModal("R2", "R1", true);
    // }

    // if (
    //   modifiedR3 != null &&
    //   modifiedR2 != null &&
    //   +modifiedR3 <= +modifiedR2
    // ) {
    //   handleErrorModal("R3", "R2", true);
    // }

    // if (
    //   modifiedT1 != null &&
    //   modifiedT2 != null &&
    //   parseInt(modifiedT2) <= parseInt(modifiedT1)
    // ) {
    //   handleErrorModal("T2", "T1", true);
    // }

    // if (
    //   modifiedT2 != null &&
    //   modifiedT3 != null &&
    //   +modifiedT3 <= +modifiedT2
    // ) {
    //   handleErrorModal("T3", "T2", true);
    // }
  };

  const getStateHead = () => userRole != "SADM" ? [
    {
      title: (
        <div>
          State <br /> Head
        </div>
      ),
      dataIndex: "stateHeadName",
      width: "180px",
    },
  ] : []


  const getDropdown = () =>
    [
      {
        title: "Top Slab",
        width: "150px",
        render: (x, y, index) => {


          const handleChange = (value) => {
            let dropdownValues = formik?.values?.[index]?.dropdownscheme;
            let checkValue1 = value?.includes("t1");
            let checkValue2 = value?.includes("t2");
            let checkValue3 = value?.includes("t3");
            let checkValue4 = value?.includes("t4");
            let checkValue5 = value?.includes("t5");
            let checkValue6 = value?.includes("t6");
            const handleDropDownScheme = () => {
              return checkValue6 ? ['t1', 't2', 't3', 't4', 't5', 't6']
                : checkValue5 ? ['t1', 't2', 't3', 't4', 't5'] :
                  checkValue4 ? ['t1', 't2', 't3', 't4']
                    : checkValue3 ? ['t1', 't2', 't3']
                      : checkValue2 ? ['t1', 't2']
                        : checkValue1 ? ['t1'] : [];
            };
            const handleDropdownSchemes = handleDropDownScheme()

            formik.setFieldValue(index, {
              ...x,
              dropdownscheme: handleDropDownScheme() || null,
              ...(handleDropdownSchemes.includes("t6")
                ? {
                } :
                handleDropdownSchemes.includes("t5") ? {
                  ...(selectedLabel === 'Hold Schemes' ? { holdR6: '' } : { modifiedR6: '' }),
                  ...(selectedLabel === 'Hold Schemes' ? { holdT6: '' } : { modifiedT4: '' }),
                  ...(selectedLabel === 'Hold Schemes' ? { holdT6T5: '' } : { modifiedT6T5: '' })
                } :
                  handleDropdownSchemes.includes("t4") ? {
                    ...(selectedLabel === 'Hold Schemes' ? { holdR6: "" } : { modifiedR6: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdR5: "" } : { modifiedR5: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT6: "" } : { modifiedT4: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT5: "" } : { modifiedT5: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT5T4: '' } : { modifiedT5T4: '' })
                  } : handleDropdownSchemes.includes("t3") ? {
                    ...(selectedLabel === 'Hold Schemes' ? { holdR4: '' } : { modifiedR4: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdR5: '' } : { modifiedR5: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdR6: '' } : { modifiedR6: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT6: '' } : { modifiedT6: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT5: '' } : { modifiedT5: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT4: '' } : { modifiedT4: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT5T4: '' } : { modifiedT5T4: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT4T3: '' } : { modifiedT4T3: '' })
                  } : handleDropdownSchemes.includes("t2") ? {
                    ...(selectedLabel === 'Hold Schemes' ? { holdR3: '' } : { modifiedR3: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdR4: '' } : { modifiedR4: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdR5: '' } : { modifiedR5: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdR6: '' } : { modifiedR6: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT6: '' } : { modifiedT6: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT5: '' } : { modifiedT5: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT4: '' } : { modifiedT4: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT3: '' } : { modifiedT3: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT5T4: '' } : { modifiedT5T4: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT4T3: '' } : { modifiedT4T3: '' })
                  } : handleDropdownSchemes.includes("t1") && {
                    ...(selectedLabel === 'Hold Schemes' ? { holdR2: '' } : { modifiedR2: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdR3: '' } : { modifiedR3: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdR4: '' } : { modifiedR4: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdR5: '' } : { modifiedR5: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdR6: '' } : { modifiedR6: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT6: '' } : { modifiedT6: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT5: '' } : { modifiedT5: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT4: '' } : { modifiedT4: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT3: '' } : { modifiedT3: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT5T4: '' } : { modifiedT5T4: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT4T3: '' } : { modifiedT4T3: '' }),
                    ...(selectedLabel === 'Hold Schemes' ? { holdT3T2: '' } : { modifiedT3T2: '' })
                  }),
            });
          };
          let arr1 = userRole == "ZADM" || userRole == "NSH" || userRole == "SADM" ? topTargetArr1(Array?.isArray(y?.topTargetArrState) ? y?.topTargetArrState[0] : y?.topTargetArrState)?.filter((x) => !x?.disabled) : topTargetArr
            ?.filter((x) => !x?.disabled)
          return (
            <Select
              onChange={handleChange}
              value={y?.dropdownscheme}
              mode="multiple"
              autoClearSearchValue="multiple"
              className="w-100"
              allowClear
              onDeselect={false}
              showArrow={false}
              removeIcon={false}
            >
              {arr1
                ?.filter((x) => !x?.disabled)
                ?.map((x) => (
                  <Select.Option value={x?.value}>
                    {x?.name}
                  </Select.Option>
                ))}
            </Select>
          );
        },
      },
    ]

  const getTargetT1 = () => [
    {
      title: "T1",
      dataIndex: "t1",
      editable: true,
      width: "100px",
      render: (x, y, index) => (
        <div className="d-flex">
          {/* <Tag className="my-1 input1 w-70" >{y?.t1}</Tag> */}
          <input
            style={{ width: "90%", padding: "5px" }}
            name={formik?.values?.[index]?.modifiedT1}
            value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT1 : formik?.values?.[index]?.modifiedT1}
            onBlur={() => {
              calculateFormula(index);
            }}

            onChange={(e) => {
              let value = e.target?.value.replace(/[^\d]/g, "");
              formik.setFieldValue(index, {
                ...y,
                modifiedT1: value,
                holdT1: value
              });
            }}
            disabled={
              !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                ? true
                : false
            }
            className={
              !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                ? "input-disabled"
                : "my-1 input1 w-70"
            }
            placeholder={
              !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                ? ""
                : "Type here"
            }

          />
        </div>
      ),

    },
  ]
  const getTargetT2 = () => [
    {
      title: "T2",
      dataIndex: "t2",
      editable: true,
      width: "100px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.modifiedT2}
          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT2 : formik?.values?.[index]?.modifiedT2}
          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target?.value.replace(/[^\d]/g, "");
            formik.setFieldValue(index, {
              ...y,
              modifiedT2: value,
              holdT2: value
            });
          }}
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t2")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t2")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
          placeholder={
            !formik?.values?.[index]?.dropdownscheme?.includes("t2")
              ? ""
              : "Type here"
          }

          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
    {
      title: "T2-T1",
      dataIndex: selectedLabel === 'Hold Schemes' ? "holdT2_T1" : "modifiedT2_T1",
      width: "100px",
      render: (x, y, index) => {
        return (formik?.values?.[index]?.dropdownscheme?.includes("t2") && formik?.values?.[index]?.dropdownscheme?.includes("t1") ? <div>{x}</div> :
          <div
            style={{ height: "26px" }}
            className={"input-disabled1"
            }>&nbsp;</div>
        )
      }
    },
  ];
  const getTargetT3 = () => [
    {
      title: "T3",
      dataIndex: "t3",
      editable: true,
      width: "100px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.modifiedT3}
          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT3 : formik?.values?.[index]?.modifiedT3 || ""}
          onBlur={() => {
            calculateFormula(index);
          }}

          onChange={(e) => {
            let value = e.target?.value.replace(/[^\d]/g, "");
            formik.setFieldValue(index, {
              ...y,
              modifiedT3: value,
              holdT3: value
            });
          }}
          placeholder={
            !formik?.values?.[index]?.dropdownscheme?.includes("t3")
              ? ""
              : "Type here"
          }
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t3")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t3")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
    {
      title: "T3-T2",
      dataIndex: selectedLabel === 'Hold Schemes' ? "holdT3_T2" : "modifiedT3_T2",
      width: "100px",
      render: (x, y, index) => {
        return ((formik?.values?.[index]?.dropdownscheme?.includes("t3") && formik?.values?.[index]?.dropdownscheme?.includes("t2")) ? <div>{x}</div> :
          <div
            style={{ height: "26px" }}
            className={"input-disabled1"
            }>&nbsp;</div>
        )
      }
    },
  ];
  const getTargetT4 = () => [
    {
      title: "T4",
      dataIndex: "t4",
      editable: true,
      width: "100px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.modifiedT4}

          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT4 : formik?.values?.[index]?.modifiedT4 || ""}
          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target?.value.replace(/[^\d]/g, "");
            formik.setFieldValue(index, {
              ...y,
              modifiedT4: value,
              holdT4: value
            });
          }}
          placeholder={
            !formik?.values?.[index]?.dropdownscheme?.includes("t4")
              ? ""
              : "Type here"
          }
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t4")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t4")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
    {
      title: "T4-T3",
      dataIndex: selectedLabel === 'Hold Schemes' ? "holdT4_T3" : "modifiedT4_T3",
      width: "100px",
      render: (x, y, index) => {
        return ((formik?.values?.[index]?.dropdownscheme?.includes("t4") && formik?.values?.[index]?.dropdownscheme?.includes("t3")) ? <div>{x}</div> :
          <div
            style={{ height: "26px" }}
            className={"input-disabled1"
            }>&nbsp;</div>
        )
      }
    },
  ];
  const getTargetT5 = () => [
    {
      title: "T5",
      dataIndex: "t5",
      editable: true,
      width: "100px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.modifiedT5}
          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT5 : formik?.values?.[index]?.modifiedT5 || ""}

          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target?.value.replace(/[^\d]/g, "");
            formik.setFieldValue(index, {
              ...y,
              modifiedT5: value,
              holdT5: value
            });
          }}
          placeholder={
            !formik?.values?.[index]?.dropdownscheme?.includes("t5")
              ? ""
              : "Type here"
          }
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t5")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t5")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
    {
      title: "T5-T4",
      dataIndex: selectedLabel === 'Hold Schemes' ? "holdT5_T4" : "modifiedT5_T4",
      width: "100px",
      render: (x, y, index) => {
        return ((formik?.values?.[index]?.dropdownscheme?.includes("t5") && formik?.values?.[index]?.dropdownscheme?.includes("t4")) ? <div>{x}</div> :
          <div
            style={{ height: "26px" }}
            className={"input-disabled1"
            }>&nbsp;</div>
        )
      }
    },
  ]
  const getTargetT6 = () => [
    {
      title: "T6",
      dataIndex: "t6",
      editable: true,
      width: "100px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.modifiedT6}
          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT6 : formik?.values?.[index]?.modifiedT6 || ""}

          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target?.value.replace(/[^\d]/g, "");
            formik.setFieldValue(index, {
              ...y,
              modifiedT6: value,
              holdT6: value
            });
          }}
          placeholder={
            !formik?.values?.[index]?.dropdownscheme?.includes("t6")
              ? ""
              : "Type here"
          }
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t6")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t6")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
    {
      title: "T6-T5",
      dataIndex: selectedLabel === 'Hold Schemes' ? "holdT6_T5" : "modifiedT6_T5",
      width: "100px",
      render: (x, y, index) => {
        return ((formik?.values?.[index]?.dropdownscheme?.includes("t6") && formik?.values?.[index]?.dropdownscheme?.includes("t5")) ? <div>{x}</div> :
          <div
            style={{ height: "26px" }}
            className={"input-disabled1"
            }>&nbsp;</div>
        )
      }
    },
  ]
  const getPayoutRateR1 = () => [
    {
      title: "R1",
      dataIndex: "r1",
      editable: false,
      width: "120px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.modifiedR1}
          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR1 : formik?.values?.[index]?.modifiedR1 || ""}
          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
            if (value.length > 5) {
              value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
            }
            formik.setFieldValue(index, {
              ...y,
              modifiedR1: value,
              holdR1: value
            });
          }}
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t1")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t1")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
          placeholder={
            !formik?.values?.[index]?.dropdownscheme?.includes("t1")
              ? ""
              : "Type here"
          }

          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
  ]
  const getPayoutRateR2 = () => [
    {
      title: "R2",
      dataIndex: "r2",
      editable: false,
      width: "120px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.modifiedR2}
          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR2 : formik?.values?.[index]?.modifiedR2 || ""}
          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
            if (value.length > 5) {
              value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
            }
            formik.setFieldValue(index, {
              ...y,
              modifiedR2: value,
              holdR2: value
            });
          }}
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t2")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t2")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
          placeholder={
            !formik?.values?.[index]?.dropdownscheme?.includes("t2")
              ? ""
              : "Type here"
          }

          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
  ]
  const getPayoutRateR3 = () => [
    {
      title: "R3",
      dataIndex: "r3",
      editable: true,
      width: "120px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.modifiedR3}
          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR3 : formik?.values?.[index]?.modifiedR3 || ""}
          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
            if (value.length > 5) {
              value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
            }
            formik.setFieldValue(index, {
              ...y,
              modifiedR3: value,
              holdR3: value
            });
          }}
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t3")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t3")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
          placeholder={
            !formik?.values?.[index]?.dropdownscheme?.includes("t3")
              ? ""
              : "Type here"
          }

          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
  ]
  const getPayoutRateR4 = () => [
    {
      title: "R4",
      dataIndex: "r4",
      editable: true,
      width: "120px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.modifiedR4}
          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR4 : formik?.values?.[index]?.modifiedR4 || ""}
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t4")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t4")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
            if (value.length > 5) {
              value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
            }
            formik.setFieldValue(index, {
              ...y,
              modifiedR4: value,
              holdR4: value
            });
          }}
          placeholder={
            !formik?.values?.[index]?.dropdownscheme?.includes("t4")
              ? ""
              : "Type here"
          }

          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
  ]
  const getPayoutRateR5 = () => [
    {
      title: "R5",
      dataIndex: "r5",
      editable: true,
      width: "120px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.modifiedR5}
          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR5 : formik?.values?.[index]?.modifiedR5 || ""}
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t5")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t5")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
            if (value.length > 5) {
              value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
            }
            formik.setFieldValue(index, {
              ...y,
              modifiedR5: value,
              holdR5: value
            });
          }}
          placeholder={
            !formik?.values?.[index]?.dropdownscheme?.includes("t5")
              ? ""
              : "Type here"
          }

          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
  ]
  const getPayoutRateR6 = () => [
    {
      title: "R6",
      dataIndex: "r6",
      editable: true,
      width: "120px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.modifiedR6}
          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR6 : formik?.values?.[index]?.modifiedR6 || ""}
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t6")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t6")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
            if (value.length > 5) {
              value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
            }
            formik.setFieldValue(index, {
              ...y,
              modifiedR6: value,
              holdR6: value
            });
          }}
          placeholder={
            !formik?.values?.[index]?.dropdownscheme?.includes("t6")
              ? ""
              : "Type here"
          }

          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
  ]
  const getPayoutP1 = () => [
    {
      title: "P1",
      dataIndex: selectedLabel === 'Hold Schemes' ? "holdP1" : "modifiedP1",
      width: "120px",
      render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t1") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
    },
  ]
  const getPayoutP2 = () => [
    {
      title: "P2",
      dataIndex: selectedLabel === 'Hold Schemes' ? "holdP2" : "modifiedP2",
      width: "120px",
      render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t2") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
    },
  ];
  const getPayoutP3 = () => [
    {
      title: "P3",
      dataIndex: selectedLabel === 'Hold Schemes' ? "holdP3" : "modifiedP3",
      width: "120px",
      render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t3") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
    },
  ]
  const getPayoutP4 = () => [
    {
      title: "P4",
      dataIndex: selectedLabel === 'Hold Schemes' ? "holdP4" : "modifiedP4",
      width: "120px",
      render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t4") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
    },
  ]
  const getPayoutP5 = () => [
    {
      title: "P5",
      dataIndex: selectedLabel === 'Hold Schemes' ? "holdP5" : "modifiedP5",
      width: "120px",
      render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t5") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
    },
  ]
  const getPayoutP6 = () => [
    {
      title: "P6",
      dataIndex: selectedLabel === 'Hold Schemes' ? "holdP6" : "modifiedP6",
      width: "120px",
      render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t6") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>

    },
  ]

  const defaultColumns = useMemo(
    () => [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        fixed: "left",
        width: "120px",
        sorter: true,
        key: "DealerCode",
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        fixed: "left",
        width: "180px",
        sorter: true,
        key: "DealerName"
        //  defaultSortOrder: "descend",
        //  sorter: (a, b) => a.name && b.name && a.name.length - b.name.length,
      },
      {
        title: "Dealer Location",
        dataIndex: "dealerLocation",
        width: "120px",
        fixed: "left",
        sorter: true,
        key: "DealerLocation",
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: "120px",
        sorter: true,
        key: "StateName",
      }, ...getStateHead(),
      {
        title: "Territory Manager",
        dataIndex: "territoryManager",
        width: "100px",
        sorter: true,
        key: "TerritoryManager",
      }, ...getDropdown(),
      {
        title: "Start Month",
        dataIndex: "startMonth",
        width: "120px",
      },
      {
        title: "End Month",
        dataIndex: "endMonth",
        width: "120px",
      },
      {
        title: "Industry",
        dataIndex: "industry",
        width: "150px",
        render: (x, y, index) => (
          <div className="d-flex">
            <input
              style={{ backgroundColor: y?.industry != y?.modifiedIndustry ? "#ffcfcf" : "", padding: "5px" }}
              name={formik?.values?.[index]?.modifiedIndustry}
              placeholder="Type here"
              value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdIndustry : formik?.values?.[index]?.modifiedIndustry}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  modifiedIndustry: value,
                  holdIndustry: value
                });
              }}
              className="my-1 input1 w-70"
            />
          </div>
        ),
      },
      {
        title: "B",
        dataIndex: "oldBillingQty",
        width: "70px",
      },
      {
        title: "I",
        dataIndex: "oldInstallation",
        width: "70px",
      },
      {
        title: "C",
        dataIndex: "oldCollection",
        width: "70px",
      },
      {
        title: "Opening Stock",
        dataIndex: "stock",
        editable: false,
        children: [
          {
            title: "Nos",
            dataIndex: "oldOpeningStock",
            width: "100px",
          },
          {
            title: "Days",
            dataIndex: "openingStockDays",
            width: "300px",

            // render: (x, y, index) => (
            //   <div className="d-flex">
            //   <Tag className="my-1 input1 w-70" >{y.openingStockDays}</Tag>
            //   <input
            //      style={{backgroundColor:y?.openingStockDays != y?.modifiedOpeningStockDays?"#ffcfcf":"",padding: "5px" }}
            //     name={formik?.values?.[index]?.modifiedOpeningStockDays}
            //     placeholder="Type here"
            //     value={formik?.values?.[index]?.modifiedOpeningStockDays}
            //     onBlur={() => {
            //       calculateFormula(index);
            //     }}
            //     onChange={(e) => {
            //       let value = e.target?.value.replace(/[^\d]/g, "");
            //       formik.setFieldValue(index, {
            //         ...y,
            //         modifiedOpeningStockDays: value,
            //       });
            //     }}
            //     className="my-1 input1 w-70"
            //   />
            //   </div>
            // ),

          },
        ],
      },

      {
        title: "Closing Os. (In Lacs)",
        dataIndex: "stock",
        editable: true,
        children: [
          {
  title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_3",
            width: "100px",
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span>Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_2",
            width: "100px",
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_1",
            width: "100px",
          },

          {
            title: (
              <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
              </span>
            ),
            dataIndex: "outStandingDays",
            width: "100px",
            //  editable: true,
          },
        ],
      },
      {
        title: "BG",
        dataIndex: "bg",
        children: [
          {
            title: "Lacs",
            dataIndex: "bGLacsCurrentYear",
            width: "100px",
          },
        ],
      },

      {
        title: "BILLING DETAILS",
        dataIndex: "plan",
        children: [
          {
            title: "B",
            dataIndex: "billing",
            width: "200px",
            render: (x, y, index) => (
              <div className="d-flex">

                <input
                  style={{ backgroundColor: y?.billing != y?.modifiedBilling ? "#ffcfcf" : "", padding: "5px" }}
                  name={formik?.values?.[index]?.modifiedBilling}
                  placeholder="Type here"
                  value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdBilling : formik?.values?.[index]?.modifiedBilling}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      modifiedBilling: value,
                      holdBilling: value
                    });
                  }}
                  className="my-1 input1 w-70"

                />
              </div>
            ),
          },
          {
            title: "I",
            dataIndex: "installation",
            editable: true,

            render: (x, y, index) => (
              <div className="d-flex">

                <input
                  style={{ backgroundColor: y?.installation != y?.modifiedInstallation ? "#ffcfcf" : "", padding: "5px" }}
                  name={formik?.values?.[index]?.modifiedInstallation}
                  placeholder="Type here"
                  value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdInstallation : formik?.values?.[index]?.modifiedInstallation}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      modifiedInstallation: value,
                      holdInstallation: value
                    });
                  }}
                  className="my-1 input1 w-70"
                />
              </div>
            ),

            width: "200px",
          },
          {
            title: "C",
            dataIndex: "collection",
            editable: true,

            render: (x, y, index) => (
              <div className="d-flex">

                <input
                  style={{ backgroundColor: y?.collection != y?.modifiedCollection ? "#ffcfcf" : "", padding: "5px" }}
                  name={formik?.values?.[index]?.modifiedCollection}
                  placeholder="Type here"
                  value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdCollection : formik?.values?.[index]?.modifiedCollection}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      modifiedCollection: value,
                      holdCollection: value
                    });
                  }}
                  className="my-1 input1 w-70"
                />
              </div>
            ),

            width: "200px",
          },
        ],
      },
      {
        title: "Installation plan",
        dataIndex: "instaPlan",
        editable: true,
        width: "300px",
        children: [
          {
            title: "Nos",
            dataIndex: "delayPlan",
            width: "300px",

            render: (x, y, index) => (
              <div className="d-flex">

                <input
                  style={{ backgroundColor: y?.delayPlan != y?.modifiedDelayPlan ? "#ffcfcf" : "", padding: "5px" }}
                  name={formik?.values?.[index]?.modifiedDelayPlan}
                  placeholder="Type here"
                  value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdDelayPlan : formik?.values?.[index]?.modifiedDelayPlan}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      modifiedDelayPlan: value,
                      holdDelayPlan: value,

                    });
                  }}
                  className="my-1 input1 w-70"
                />
              </div>
            ),

            editable: true,
          },
        ],
      },

      {
        title: (
          <div>
            Closing <br /> Stock
          </div>
        ),
        dataIndex: selectedLabel === 'Hold Schemes' ? "holdclosingStock" : "modifiedclosingStock",
        width: "80px",
      },
      {
        title: (
          <div>
            Closing <br /> OutStanding
          </div>
        ),
        dataIndex: selectedLabel === 'Hold Schemes' ? "holdclosingOutStanding" : "modifiedclosingOutStanding",
        width: "110px",
      },
      {
        title: "Books Collection Targets (No. of Trs.)",
        dataIndex: "BooksCollectionTarget",
        editable: true,
        children: [
          ...getTargetT1(),
          ...getTargetT2(),
          ...getTargetT3(),
          ...getTargetT4(),
          ...getTargetT5(),
          ...getTargetT6(),
        ],
      },
      {
        title: "Payout Rate",
        dataIndex: "pr",
        editable: true,

        children: [
          ...getPayoutRateR1(),
          ...getPayoutRateR2(),
          ...getPayoutRateR3(),
          ...getPayoutRateR4(),
          ...getPayoutRateR5(),
          ...getPayoutRateR6(),
        ],
      },
      {
        title: "Payout",
        dataIndex: "payout",
        editable: true,

        children: [
          ...getPayoutP1(),
          ...getPayoutP2(),
          ...getPayoutP3(),
          ...getPayoutP4(),
          ...getPayoutP5(),
          ...getPayoutP6(),
        ]
      },
      {
        title: "Remarks",
        dataIndex: "reason",

        render: (x, y, index) => (
          <div className="d-flex">

            <input
              // style={{ backgroundColor: y?.reason != y?.modifiedReason ? "#ffcfcf" : "", padding: "5px" }}
              name={formik?.values?.[index]?.modifiedReason}
              placeholder="Type here"
              value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdRemarks : formik?.values?.[index]?.modifiedReason}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value;
                const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
                formik.setFieldValue(index, {
                  ...y,
                  modifiedReason: sanitizedValue,
                  holdRemarks: sanitizedValue
                });
              }}
              className="my-1 input1 w-70"
            />
          </div>),
        width: "300px",
      },
      {
        title: "Total Expected Payout",
        dataIndex: "TEP",
        editable: true,
        children: [
          {
            title: "Expected Volume",
            dataIndex: "volume",
            render: (x, y, index) => (
              <div className="d-flex">

                <input
                  // style={{ backgroundColor: y?.volume != y?.modifiedVolume ? "#ffcfcf" : "", padding: "5px" }}
                  name={formik?.values?.[index]?.modifiedVolume}
                  placeholder="Type here"
                  value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdVolume : formik?.values?.[index]?.modifiedVolume}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      modifiedVolume: value,
                      holdVolume: value
                    });
                  }}
                  className="my-1 input1 w-70"
                />
              </div>),
            width: "300px",
          },
          {
            title: "Per Tr.",
            dataIndex: "perTr",
            width: "250px",
            render: (x, y, index) => (

              <div className="d-flex">
                {/* <Tag className="w-70 input1" >{y?.perTr}</Tag> */}
                <Tag className="w-70 input1" >{
                  selectedLabel === 'Hold Schemes' ? y.holdPerTr : y?.modifiedPerTr}</Tag>
              </div>
            )
          },
          {
            title: "Amount",
            dataIndex: "amount",
            width: "250px",
            render: (x, y, index) => (
              <div className="d-flex">
                {/* <Tag className="w-70 input1" >{y?.amount}</Tag> */}
                <Tag className="w-70 input1" >{selectedLabel === 'Hold Schemes' ? y.holdAmount : y?.modifiedAmount}</Tag>
              </div>
            )
          },
        ],
      },
    ],
    [formik?.values, codeError]
  );



  const handleSubmit = (e) => {

    let extraValidation = {};
    formik?.values?.map((x, index) => {
      let topTargetDetails = x?.dropdownscheme;
      let errors = [];
      if (topTargetDetails?.length == 0) {
        errors.push("Please select top target");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdIndustry : !x.modifiedIndustry) {
        errors.push("Please enter industry");
      }
      // if (!x.openingStockDays) {
      //   errors.push("Please enter open stock days");
      // }
      // if (!x.modifiedOutStandingDays) {
      //   errors.push("Please enter outstanding days");
      // }
      if (selectedLabel === 'Hold Schemes' ? !x.holdBilling : !x.modifiedBilling) {
        errors.push("Please enter billing");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdInstallation : !x.modifiedInstallation) {
        errors.push("Please enter installation");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdCollection : !x.modifiedCollection) {
        errors.push("Please enter collection");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdDelayPlan && x?.schemeTypeId == 1 : !x.modifiedDelayPlan && x?.schemeTypeId == 1) {
        errors.push("Please enter Installtion Plan Nos");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdVolume : !x.modifiedVolume || x.modifiedVolume === "0") {

        errors.push("Please enter volume");
      }
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdT6 : x?.modifiedT6 || 0) <=
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdT5 : x?.modifiedT5 || 0)
      ) {
        errors.push("T6 should be greater than T5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdT5 : x?.modifiedT5 || 0) <=
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdT4 : x?.modifiedT4 || 0)
      ) {
        errors.push("T5 should be greater than T4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdT4 : x?.modifiedT4 || 0) <=
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdT3 : x?.modifiedT3 || 0)
      ) {
        errors.push("T4 should be greater than T3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdT3 : x?.modifiedT3 || 0) <=
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdT2 : x?.modifiedT2 || 0)
      ) {
        errors.push("T3 should be greater than T2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdT2 : x?.modifiedT2 || 0) <=
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdT1 : x?.modifiedT1 || 0)
      ) {
        errors.push("T2 should be greater than T1");
      }
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdR6 : x?.modifiedR6 || 0) <= parseInt(selectedLabel === 'Hold Schemes' ? !x.holdR5 : x?.modifiedR5 || 0)
      ) {
        errors.push("R6 should be greater than R5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdR5 : x?.modifiedR5 || 0) <= parseInt(selectedLabel === 'Hold Schemes' ? !x.holdR4 : x?.modifiedR4 || 0)
      ) {
        errors.push("R5 should be greater than R4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdR4 : x?.modifiedR4 || 0) <= parseInt(selectedLabel === 'Hold Schemes' ? !x.holdR3 : x?.modifiedR3 || 0)
      ) {
        errors.push("R4 should be greater than R3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdR3 : x?.modifiedR3 || 0) <= parseInt(selectedLabel === 'Hold Schemes' ? !x.holdR2 : x?.modifiedR2 || 0)
      ) {
        errors.push("R3 should be greater than R2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(selectedLabel === 'Hold Schemes' ? !x.holdR2 : x?.modifiedR2 || 0) <= parseInt(selectedLabel === 'Hold Schemes' ? !x.holdR1 : x?.modifiedR1 || 0)
      ) {
        errors.push("R2 should be greater than R1");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdR1 : !x.modifiedR1) {
        errors.push("Please enter R1");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdR2 && topTargetDetails?.includes("t2") : !x.modifiedR2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter R2");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdR3 && topTargetDetails?.includes("t3") : !x.modifiedR3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter R3");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdR4 && topTargetDetails?.includes("t4") : !x.modifiedR4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter R4");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdR5 && topTargetDetails?.includes("t5") : !x.modifiedR5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter R5");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdR6 && topTargetDetails?.includes("t6") : !x.modifiedR6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter R6");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdT1 : !x.modifiedT1) {
        errors.push("Please enter T1");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdT2 && topTargetDetails?.includes("t2") : !x.modifiedT2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter T2");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdT3 && topTargetDetails?.includes("t3") : !x.modifiedT3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter T3");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdT4 && topTargetDetails?.includes("t4") : !x.modifiedT4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter T4");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdT5 && topTargetDetails?.includes("t5") : !x.modifiedT5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter T5");
      }
      if (selectedLabel === 'Hold Schemes' ? !x.holdT6 && topTargetDetails?.includes("t6") : !x.modifiedT6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter T6");
      }
      if (errors.length > 0) {
        extraValidation[x?.dealerCode] = errors;
      }
    });


    if (formik?.values?.[0]?.holdStatus == 9) {
      let data = formik?.values?.map((x) => {
        return {
          dealerCode: x.dealerCode,
          dealerId: x.dealerId,
          dealerName: x.dealerName,
          dealerLocation: x.dealerLocation,
          stateName: x.stateName,
          territoryManager: x.territoryManager || "",
          startMonth: x.startMonth, //moment(x.startMonth).format("MMM YY"),
          endMonth: x.endMonth, //moment(x.endMonth).format("MMM YY"),
          // reason: x.reason,
          billing: x.billing ? +x.billing : 0,
          installation: x.installation ? +x.installation : 0,
          collection: Math.round(x.collection ? +x.collection : 0),
          delayPlan: x.delayPlan || 0,
          schemeTypeId: x.schemeTypeId,
          bookCollectionId: x.bookCollectionId,
          payoutId: x.payoutId,
          schemeId: x.schemeId,
          schemeNo: x.schemeNo,
          schemePlanId: x.schemePlanId,
          openingStockDays: x.openingStockDays,
          outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
          T1: +x.holdT1 || 0,
          T2: +x.holdT2 || 0,
          T3: +x.holdT3 || 0,
          T4: +x.holdT4 || 0,
          T5: +x.holdT5 || 0,
          T6: +x.holdT6 || 0,
          T2t1: x.holdT2_T1 || 0,
          T3t2: x.holdT3_T2 || 0,
          T4t3: x.holdT4_T3 || 0,
          T5t4: x.holdT5_T4 || 0,
          T6t5: x.holdT6_T5 || 0,
          P1: x.holdP1 || 0,
          P2: x.holdP2 || 0,
          P3: x.holdP3 || 0,
          P4: x.holdP4 || 0,
          P5: x.holdP5 || 0,
          P6: x.holdP6 || 0,
          R1: +x.holdR1 || 0,
          R2: +x.holdR2 || 0,
          R3: +x.holdR3 || 0,
          R4: +x.holdR4 || 0,
          R5: +x.holdR5 || 0,
          R6: +x.holdR6 || 0,
          Volume: x.holdVolume || 0,
          TopTarget: x?.dropdownscheme?.toString(),
          Industry: x?.holdIndustry || 0,
          DelayPlan: x?.holdDelayPlan || 0,
          Billing: x.holdBilling ? +x.holdBilling : 0,
          Installation: x.holdInstallation ? +x.holdInstallation : 0,
          Collection: Math.round(x.holdCollection ? +x.holdCollection : 0),
          PerTr: Math.round(x.holdPerTr) || 0,
          Amount: x.holdAmount,
          HoldReason: x?.holdRemarks,
          closingStock: x?.closingStock,
          closingOutStanding: x?.closingOutStanding,
          status: 1,
          userid: +userId,
          username: userName,
          userrole: userRole,
          UpperCap: x?.holdUpperCap,
          InsPerTarget: x?.holdInsPerTarget,
          InsTarget: x?.InsTarget,
          LastOneMonthSlabAchieved: x?.holdLastOneMonthSlabAchieved,
          LastMonthColQty: x?.holdLastMonthColQty,
          holdLastTwoMonthSlabAchieved: x?.holdLastTwoMonthSlabAchieved,
          LastTwoMonthColQty: x?.LastTwoMonthColQty,
          holdStatus: x?.holdStatus,
          IsModified: true
        }
      });
      setSubmitData(data)
    } else {
      let data = formik?.values?.map((x) => {
        return {
          modificationId: x.modifiedId,
          dealerCode: x.dealerCode,
          dealerId: x.dealerId,
          dealerName: x.dealerName,
          dealerLocation: x.dealerLocation,
          stateName: x.stateName,
          territoryManager: x.territoryManager || "",

          startMonth: moment(x.startMonth).format("MMM YY"),
          endMonth: moment(x.endMonth).format("MMM YY"),
          reason: x.modifiedReason,
          status: 1,
          // state: x.stateId,
          billing: x.modifiedBilling ? +x.modifiedBilling : 0,
          installation: x.modifiedInstallation ? +x.modifiedInstallation : 0,
          collection: Math.round(x.modifiedCollection ? +x.modifiedCollection : 0),
          delayPlan: parseInt(x.modifiedDelayPlan),
          // isSAP: true,
          // isActive: true,
          // schemePlanStartMonth: startDate,
          // schemePlanEndMonth: endDate,

          schemeTypeId: x.schemeTypeId,
          bookCollectionId: x.bookCollectionId,
          payoutId: x.payoutId,
          schemeId: x.schemeId,
          schemeNo: x.schemeNo,
          schemePlanId: x.schemePlanId,
          openingStockDays: x.openingStockDays,
          outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
          t1: +x.modifiedT1 || 0,
          t2: +x.modifiedT2 || 0,
          t3: +x.modifiedT3 || 0,
          t4: +x.modifiedT4 || 0,
          t5: +x.modifiedT5 || 0,
          t6: +x.modifiedT6 || 0,

          t2_T1: x.modifiedT2_T1 || 0,
          T2t1: x.holdT2_T1 || 0,
          t3_T2: x.modifiedT3_T2 || 0,
          T3t2: x.holdT3_T2 || 0,
          t4_T3: x.modifiedT4_T3 || 0,
          T4t3: x.holdT4_T3 || 0,
          t5_T4: x.modifiedT5_T4 || 0,
          T5t4: x.holdT5_T4 || 0,
          t6_T5: x.modifiedT6_T5 || 0,
          T6t5: x.holdT6_T5 || 0,
          p1: x.modifiedP1 || 0,
          p2: x.modifiedP2 || 0,
          p3: x.modifiedP3 || 0,
          p4: x.modifiedP4 || 0,
          p5: x.modifiedP5 || 0,
          p6: x.modifiedP6 || 0,
          r1: +x.modifiedR1 || 0,
          r2: +x.modifiedR2 || 0,
          r3: +x.modifiedR3 || 0,
          r4: +x.modifiedR4 || 0,
          r5: +x.modifiedR5 || 0,
          r6: +x.modifiedR6 || 0,
          TopTarget: x?.dropdownscheme?.toString(),

          volume: x.modifiedVol,
          perTr: Math.round(x.modifiedPerTr),
          amount: x.modifiedAmount,
          // ...(y != "draft" && { Manager: y.Manager }),
          // fyear: currentFiscalYear,
          userid: +userId,
          username: userName,
          userrole: userRole,
          industry: x.modifiedIndustry ? parseInt(x.modifiedIndustry) : 0,
          oldBillingQty: x.oldBillingQty,
          oldInstallation: x.oldInstallation,
          oldCollection: Math.round(x.oldCollection),
          oldOpeningStock: x.oldOpeningStock,
          closingOutStandingMonth_1: x.closingOutStandingMonth_1,
          closingOutStandingMonth_2: x.closingOutStandingMonth_2,
          closingOutStandingMonth_3: x.closingOutStandingMonth_3,
          bgLacsCurrentYear: x.bgLacsCurrentYear,
          closingStock: selectedLabel === 'Hold Schemes' ? x.holdclosingStock : x.modifiedclosingStock,
          closingOutStanding: selectedLabel === 'Hold Schemes' ? x.holdclosingOutStanding : x.modifiedclosingOutStanding,
          HoldReason: x.modifiedReason,
          holdStatus: x?.holdStatus,
          IsModified: selectedLabel == "Hold Schemes" ? true : false
        }
      });
      setSubmitData(data)
    }


    setCodeError(Object?.keys(extraValidation || {}));

    if (Object?.keys?.(extraValidation)?.length != 0) {

      showErrorDialog(extraValidation);
    } else if (Object?.keys?.(extraValidation)?.length == 0) {

      showModal()


    }

  };
  const handleFileUpload = (e) => {
    const maxFileSize = 5 * 1024 * 1024; // Set maximum file size to 5MB
    const files = e.target.files;

    // Check if any file exceeds the maximum file size limit
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxFileSize) {
        ToastifyShow(`File ${files[i].name} is too large. Maximum file size is 5MB.`, "error");
        return;
      }
  
      // Check file headers for XLSX files
      if (!isValidFileHeader(files[i])) {
        ToastifyShow(`Invalid file header for ${files[i].name}.`, "error");
        return;
      }
  
      // Check content type for XLSX files
      if (!isValidContentType(files[i])) {
        ToastifyShow(`Invalid content type for ${files[i].name}.`, "error");
        return;
      }
  
    }

    if (files[0].name.split('.')?.[1] !== 'xlsx') {
      ToastifyShow("Please upload an XLSX file only.", "error");
      return;
    }
  
    const reader = new FileReader();
    reader.readAsBinaryString(files[0]);
  
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  
    reader.onload = async(e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      let calArr = [];
      // schemeLockedStatus?.t1 || userRole == "ZADM"
      let dataItem= formik?.values?.map((y, index) => {
        if (parsedData.find((x) => x["DEALER CODE"] === y?.dealerCode)) {
    
          //TOP SLAB
          let z = parsedData.find((x) => x["DEALER CODE"] === y?.dealerCode);
          let dropdownscheme= z?.["TOP SLAB"]?.toUpperCase()=="T6"&&y?.topTargetArrState?.t6?["t1","t2","t3","t4","t5","t6"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T5"&&y?.topTargetArrState?.t5?["t1","t2","t3","t4","t5"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T4"&&y?.topTargetArrState?.t4?["t1","t2","t3","t4"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T3"?["t1","t2","t3"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T2"?["t1","t2"]:
            z?.["TOP SLAB"]?.toUpperCase()=="T1"?["t1"]:["t1","t2"]

           let paget1=dropdownscheme.includes("t1"||"t2"||"t3"||"t4"||"t5"||"t6") 
           let paget2=dropdownscheme.includes("t2"||"t3"||"t4"||"t5"||"t6") 
           let paget3=dropdownscheme.includes("t3"||"t4"||"t5"||"t6") 
           let paget4=dropdownscheme.includes("t4"||"t5"||"t6") 
           let paget5=dropdownscheme.includes("t5"||"t6") 
           let paget6=dropdownscheme.includes("t6") 

            let updatedR1 = paget1?parseFiveDigitNumber(z?.["PAYOUT RATE"]) : 0; //1200
            let updatedR2 = paget2?parseFiveDigitNumber(z?.["__EMPTY_11"]):0; //1300
            let updatedR3 = paget3?parseFiveDigitNumber(z?.["__EMPTY_12"]) :0; //1400
            let updatedR4 = paget4?parseFiveDigitNumber(z?.["__EMPTY_13"]) : 0; //1500
            let updatedR5 = paget5?parseFiveDigitNumber(z?.["__EMPTY_14"]) : 0; //1600
            let updatedR6 = paget6?parseFiveDigitNumber(z?.["__EMPTY_15"]) : 0; //1700
      
            let updateT1 = paget1?parseInt(z?.["BOOKS COLLECTION TARGETS (NO. OF TRS.)"]): 0; //60
            let updateT2 = paget2?parseInt(z?.["__EMPTY_6"]) : 0; //700
            let updateT3 = paget3?parseInt(z?.["__EMPTY_7"]) : 0; //800
            let updateT4 = paget4?parseInt(z?.["__EMPTY_8"]) : 0; //900
            let updateT5 = paget5?parseInt(z?.["__EMPTY_9"]) : 0; //1000
            let updateT6 = paget6?parseInt(z?.["__EMPTY_10"])  : 0; //1100
            const keyPrefixes = selectedLabel === 'Hold Schemes' ? 'hold' : 'modified';
            const keyPrefixes1 = selectedLabel === 'Hold Schemes' ? 'holdReason' : 'modifiedRemarks';

            calArr.push(index);
          return {
            ...y,
            dropdownscheme:dropdownscheme,
            [`holdIndustry`]:parseInt(z?.INDUSTRY) || 0, //100
            [`holdBilling`]:parseInt(z?.["BILLING DETAILS"]) || 0, //200
            [`holdInstallation`]:parseInt(z?.__EMPTY_4) || 0, //300
            [`holdCollection`]:parseInt(z?.__EMPTY_5) || 0,
            [`holdDelayPlan` ]: parseInt(z?.[`Installation Plan`]) || 0, //500
            [`holdT1`]: updateT1 || 0, //600
            [`holdT2`]: updateT2||0,//700
            [`holdT3`]: updateT3||0,//800
            [`holdT4`]: updateT4||0,//900
            [`holdT5`]: updateT5||0,//1000
            [`holdT6`]: updateT6||0,//1100
            [`holdR1`]: updatedR1||0, //1200
            [`holdR2`]: updatedR2||0, //1300
            [`holdR3`]: updatedR3||0, //1400
            [`holdR4`]: updatedR4||0, //1500
            [`holdR5`]: updatedR5||0, //1600
            [`holdR6`]: updatedR6||0, //1700
            [`holdRemarks`]:z?.REMARKS || "", //1700,
            [`holdVolume`]: parseInt(z?.[`Expected Volume`]) || 0,
            [`modifiedIndustry`]:parseInt(z?.INDUSTRY) || 0, //100
            [`modifiedBilling`]:parseInt(z?.["BILLING DETAILS"]) || 0, //200
            [`modifiedInstallation`]:parseInt(z?.__EMPTY_4) || 0, //300
            [`modifiedCollection`]:parseInt(z?.__EMPTY_5) || 0,
            [`modifiedDelayPlan` ]: parseInt(z?.[`Installation Plan`]) || 0, //500
            [`modifiedT1`]: updateT1 || 0, //600
            [`modifiedT2`]: updateT2||0,//700
            [`modifiedT3`]: updateT3||0,//800
            [`modifiedT4`]: updateT4||0,//900
            [`modifiedT5`]: updateT5||0,//1000
            [`modifiedT6`]: updateT6||0,//1100
            [`modifiedR1`]: updatedR1||0, //1200
            [`modifiedR2`]: updatedR2||0, //1300
            [`modifiedR3`]: updatedR3||0, //1400
            [`modifiedR4`]: updatedR4||0, //1500
            [`modifiedR5`]: updatedR5||0, //1600
            [`modifiedR6`]: updatedR6||0, //1700
            [`modifiedVolume`]: parseInt(z?.[`Expected Volume`]) || 0,
            [`modifiedReason`]:z?.REMARKS || "", //1700,
          };
        } else {
          return y;
        }
      });
      await setCheckCalculate(true);
      await setCheckCalculateArr(calArr);
      await formik?.setValues(dataItem);
      ToastifyShow("File Uploaded successfully","success")
    };
  };


  
  const handleSampleFile = () => {

    const headerStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
        diagonal: { diagonalUp: true, diagonalDown: true },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    const headerStyle1 = {
   
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
        diagonal: { diagonalUp: true, diagonalDown: true },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
 if (formik?.values?.length == 0) {
        ToastifyShow("Something went wrong", "error")
        return false
      }

      let tab_text =ModificationCollectionSchemeDownload({
        collectionLock:"t6"
      })
      formik?.values?.length != 0 &&
      formik?.values.forEach((header) => {
        tab_text += `<tr ><td>${header?.dealerCode}</td>
  <td>${header?.dealerLocation}</td>
  <td>${header?.dealerName || ""}</td>
  <td>${header?.stateName}</td>
  <td>${header?.territoryManager}</td>
  <td>${header?.startMonth|| ""}</td>
  <td>${header?.endMonth || ""}</td>
  <td>${header?.dropdownscheme?.includes("t6")
            ? "T6"
            : header?.dropdownscheme?.includes("t5")
              ? "T5"
              : header?.dropdownscheme?.includes("t4")
                ? "T4"
                : header?.dropdownscheme?.includes("t3")
                  ? "T3"
                  : header?.dropdownscheme?.includes("t2")
                    ? "T2"
                    : header?.dropdownscheme?.includes("t1")
                      ? "T1"
                      : ""
          }
  </td>
  <td>${selectedLabel === 'Hold Schemes' ? header?.holdIndustry : (header?.modifiedIndustry||"")}</td>
  <td>${header?.oldBillingQty}</td>
  <td>${header?.oldInstallation}</td>
  <td>${header?.oldCollection}</td>
  <td>${header?.oldOpeningStock || 0}</td>
  <td>${header?.openingStockDays || 0}</td>
    <td>${header?.closingOutStandingMonth_3}</td>
    <td>${header?.closingOutStandingMonth_2}</td>
    <td>${header?.closingOutStandingMonth_1}</td>
    <td>${header?.outStandingDays || 0}</td>
    <td>${header?.bGLacsCurrentYear}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdBilling : (header?.modifiedBilling||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdInstallation : (header?.modifiedInstallation||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdCollection : (header?.modifiedCollection||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdDelayPlan : (header?.modifiedDelayPlan||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdT1 : (header?.modifiedT1||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdT2 : (header?.modifiedT2||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdT3 : (header?.modifiedT3||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdT4 : (header?.modifiedT4||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdT5 : (header?.modifiedT5||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdT6 : (header?.modifiedT6||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdR1 : (header?.modifiedR1||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdR2 : (header?.modifiedR2||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdR3 : (header?.modifiedR3||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdR4 : (header?.modifiedR4||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdR5 : (header?.modifiedR5||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdR6 : (header?.modifiedR6||"")}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdRemarks : header?.modifiedReason}</td>
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdVolume : header?.modifiedVolume}</td>
    
  </tr>`;
      });

      tab_text += "</table>";

    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);

    // Apply styles to the table using a class


    // generate workbook with XLSXStyle
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    



      function xSeparateKeys(obj) {
        return Object.keys(obj).filter(key => 
          ((key.startsWith('X') && key !== 'X2'&&key!=='X1')|| 
          (key.startsWith('Z') && key !== 'Z2') ||
           (key.startsWith('Y') && key !== 'Y2') ||
           (key.startsWith('ADD') && key !== 'ADD1' && key !== 'ADD2')||
           (key.startsWith('AA') && key !== 'AA2')||
           (key.startsWith('AB') && key !== 'AB2')||
           (key.startsWith('AC') && key !== 'AC2')||
           (key.startsWith('AD') && key !== 'AD2'&&key!=='AD1')||
           (key.startsWith('AE') && key !== 'AE2')||
           (key.startsWith('AF') && key !== 'AF2')||
           (key.startsWith('AG') && key !== 'AG2')||
           (key.startsWith('AH') && key !== 'AH2')||
           (key.startsWith('AI') && key !== 'AI2')||
           (key.startsWith('AJ') && key !== 'AJ1')||
           (key.startsWith('AK') && key !== 'AK1')||
           (key.startsWith('H') && key !== 'H1')||
           (key.startsWith('W')&&key!=='W1'&&key!=='W2')||
           (key.startsWith('T')&&key!=='T1'&&key!=='T2')||
           (key.startsWith('U')&&key!=='U1'&&key!=='U2')||
           (key.startsWith('V')&&key!=='V1'&&key!=='V2')||
           (key.startsWith('I') && key !== 'I1')
          ))
      }
      const xKeys= xSeparateKeys(wb?.Sheets?.Sheet1);


    Object?.keys(wb?.Sheets?.Sheet1 || {})
      ?.filter(
        (x) =>
          x != "!rows" &&
          x != "!cols" &&
          x != "!merges" &&
          x != "!ref" &&
          x != "!fullref" &&
          !xKeys?.includes(x)
      )
      ?.forEach((x) => {
        wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle;
      });

      Object?.keys(wb?.Sheets?.Sheet1 || {})
      ?.filter(
        (x) =>xKeys?.includes(x)
      )
      ?.forEach((x) => {
        wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle1;
      });
    // Dynamically set column widths

    wb.Sheets[wb.SheetNames[0]]["!cols"] = [
      { wch: 15 },
      { wch: 20 },
      { wch: 30 },
      { wch: 10 },
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    // Dynamically set row heights
    wb.Sheets[wb.SheetNames[0]]["!rows"] = [
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },

      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      // Add more row heights as needed
    ];

    // remove element
    document.body.removeChild(elt);

    XLSX.writeFile(
      wb,
      (formik?.values?.[0]?.type == "1" || formik?.values?.[0]?.type == "")
        ? "With_Delivery_Plan.xlsx"
        : "Without_Delivery_Plan.xlsx"
    );
    }
  useEffect(() => {
    if (checkCalculate) {
      checkCalculateArr?.forEach((x) => calculateFormula(x));
      setCheckCalculate(false);
    }
  }, [checkCalculate]);
  const handleRemoveFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };
  const menu = (
    <Menu>
      <Menu.Item>
        {selectedFiles?.length == 0 && (
          <div className="custom-file-input">
            <input type="file" accept=".xlsx" onChange={handleFileUpload} />
            <label htmlFor="fileInput">Choose File</label>
          </div>
        )}
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <Button onClick={() => handleRemoveFile(index)} className="mx-1">
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </Menu.Item>
      <Menu.Item onClick={handleSampleFile}>Download Sample File</Menu.Item>
    </Menu>
  );
  return (
    <div className="create_scheme_wrap general-bscheme">
           <div className="d-flex justify-content-between">
      <Button type="primary" icon={<ArrowBackRounded />} size="middle" onClick={() => {
        backBtn(false)
      }} />
           <Space
              style={{
                padding: "0",
                paddingTop: "14px",
              }}
              direction="vertical"
            >
              <Tooltip title={"Import/Export to Excel"}>
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button className="h-2">Import/Export to Excel</Button>
              </Dropdown>
              </Tooltip> 
            </Space>
            </div>
      <div className="table_scheme_wrap generate-finace-report">
        <Form>
          <Table
            className="finance"
            loading={loadingState == "pending" ? true : false}
            columns={defaultColumns}
            dataSource={formik?.values}
            bordered
            rowClassName={(record, index) => codeError?.includes(record?.dealerCode) ? "data-row active-row" : ""}
            pagination={false}
            scroll={{
              x: 1000,
              y: 300,
            }}
            size="middle"
          />
        </Form>
        <div className="pt-3 d-flex f-reverse">
          <Button
            type="default"
            shape="round"
            style={{
              margin: "10px",
              background: "#FCE838",
              fontWeight: "700",
              textTransform: "uppercase",
              border: "none",
              padding: "20px",
            }}
            size="large"
            onClick={handleSubmit}
          >
            Update & Submit
          </Button>
        </div>
      </div>

      <Modal open={isModalOpen} onOk={() => {

        handleOk()
      }}         maskClosable={false}
      destroyOnClose={true} onCancel={handleCancel}>
        <h2>Are you sure you want to submit!</h2>
      </Modal>

      {/* <ValidationErrorsModal
        open={openErrorDialog.open}
        onClose={() => {
          setOpenErrorDialog({ open: false, errorsFields: [] });
        }}
        errorsFields={openErrorDialog?.errorsFields}
      /> */}

      <ValidationDifferentSchemeError

        open={openErrorDialog.open}
        onClose={() => {
          setOpenErrorDialog({ open: false, errorsFields: {} });
        }}
        errorsFields={openErrorDialog?.errorsFields}

      />
      <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>
        <p>
          {errorItem.firstItem} should be greater then {errorItem.secondItem}
        </p>
      </SMDialog>
    </div>
  );
};
export default EditModifiedAwaitingCollection;
