const TableColorDetails=()=>{
    return(
        <div className="All_scheme_status1">
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "green" }}
          ></div>
          Approved
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "red" }}
          ></div>
          Rejected
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "yellow" }}
          ></div>
          Work in Progress
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "rgb(255, 0, 255)" }}
          ></div>
          Auto Rejected
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "#BCB38D" }}
          ></div>
          Deviation
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "#9999ff" }}
          ></div>
          Hold
        </div>
      </div>
    )
}

export default TableColorDetails