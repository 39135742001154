import React from "react";
import { Divider, Card } from "antd";
import CreateSchemeTable from "../createSchemes/createSchemeTable";

const CollectionApprovalEditScheme = () => {
  return (
    <div className="create_scheme_wrap approval-schme-row-cdo">
      <CreateSchemeTable type="edit" editType="schemeProgress" />
    </div>
  );
};
export default CollectionApprovalEditScheme;