import "./404.css";
export default function PageNotFound() {
  return (
    <div>
      <div className="text-center">
        <img src={require("./404.jpg")} alt="#" className="error-screen"/>
        <div className="fw-bolder fs-1">404 ERROR!</div>
        <div className="fw-bold fs-1 error-color">SORRY! PAGE NOT FOUND.</div>
      </div>
    </div>
  );
}
