import CryptoJS from "crypto-js";
const key1="QJDwfFL3sj4BMN941"
export const xUseValue =  "YKWw4CHccO2S2giYhuIif2hS699ucneJ";
export const velocityX =  "VE8Rzir2JPayk302";
const encryptedBytesVector = CryptoJS.enc.Utf8.parse(velocityX);
const encryptionKeyBytes = CryptoJS.enc.Utf8.parse(xUseValue);

export const encryptPayload = (config) => {
  
    if (config) {
      const encryptedPayload = CryptoJS.AES.encrypt(
        JSON.stringify(config),
        encryptionKeyBytes,
        {
          iv: encryptedBytesVector,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
      config = encryptedPayload;

    }

    return config;
};