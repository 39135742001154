import { MoreOutlined } from "@ant-design/icons";
import { Collapse, Space, Pagination, Dropdown, Button, message } from "antd";
import DraftSchemeTable from "./DraftSchemeTable";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "../../../assets/vector/edit.svg";
import "./draftScheme.css";
import CollectionFilter from "../../../components/installation/collectionFilter";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import { useState } from "react";

const { Panel } = Collapse;

const DraftCollapseTab = ({ getSelectedRowKeys, fetchGeneratedScheme }) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [selectedSchemeType, setSelectedSchemeType] = useState(1)


  
  const navigate = useNavigate();
  const handleEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation()
    const selectedRow = JSON.parse(sessionStorage.getItem('rowData'))
    const recordExist = selectedRow ;


if (recordExist!= null) {
  navigate("/collection/draftSchemes/edit",{state:selectedRow});
} else {
  message.warning("Please select the checkbox !")
  // messageApi.open({
  //   type: 'warning',
  //   content: 'Please select the checkbox !',
  //   duration: 2
  // });
  // alert("please select checkbox");
}

  };

  const onGetData = (demoParams) => {
    // 
    setSelectedSchemeType(demoParams.schemeType)
  };

  const items = [
    {
      label: (
        <Button className="link" type="link" onClick={(e) => handleEditClick(e)}>
          Edit
        </Button>
      ),
      key: "1",
      icon: <Edit />,
    },
  ];
  const collapseProps = {
    items,
    trigger: ["click"],
  };
  
  const defaultActiveKey = ["1", "2"];

  const genExtra = () => (
    <Dropdown menu={collapseProps}>
      <MoreOutlined />
    </Dropdown>
  );

  return (
    <>
      <Space
        direction="vertical"
        size="large"
        style={{
          display: "flex",
        }}
      >
        <Collapse className="all_scheme_draft" defaultActiveKey={defaultActiveKey}>
          <Panel
            size="large"
            header="Incentive Collection "
            key="1"
            extra={genExtra()}
          >
             <CollectionFilter onGetData={onGetData}  />
            <DraftSchemeTable
            selectedSchemeType={selectedSchemeType}
              getSelectedRowKeys={getSelectedRowKeys}
              fetchGeneratedScheme={fetchGeneratedScheme}
            />
          </Panel>
        </Collapse>
        {/* <Pagination
          defaultCurrent={1}
          total={30}
          className="draft-scheme-pagination"
        /> */}
      </Space>
    </>
  );
};
export default DraftCollapseTab;
