import React, { Table, Tag, Space, Row, Col, Button } from "antd";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  generatedScheme,
} from "../../../redux/installation/AllGeneratedScheme";
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
import omit from 'lodash/omit'
import MergeModal from "../../../components/modal/alertModal/ConfirmModal";
import axiosAPI from '../../../services/axiosApi';
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const currentFiscalYear = getCurrentFiscalYear()
//import type { ColumnsType } from 'antd/es/table'
const InitAllSchemeData = {
  currentYear: dayjs().year(),
  // collectionType: 'Collection',
  schemeType: '',
  // dealerCode: [],
  month: 2,
  monthEnd: 3,
  sortingOrder: "ascend",
  sortingType: "DealerCode"
}
const InstallationAllSchemeTable = ({ payload, getSelectionCheck, selectedStatus }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [schemeIdNo, setSchemeIdNo] = useState([])
  const [disable, setDisable] = useState(false)
  const [enable, setEnable] = useState(false)
  const listData = useSelector((state) => state.generatedScheme.data);
  const [collectionData, setCollectionData] = useState(InitAllSchemeData);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openMergeModal, setOpenMergeModal] = useState(false)
  const [openUnMergeModal, setOpenUnMergeModal] = useState(false)

  const handleSingleEdit = () => {
    navigate("/installation/allSchemes/edit");
  };

  const columns = [
    {
      title: 'Scheme No.',
      dataIndex: 'scheme',
      width: "180px",
      fixed: 'left',
      defaultSortOrder: "ascend",
      //    sorter: (a, b) => a.name.length - b.name.length,
      sorter: true,
      key: "SchemeNo",

      render: (item) => {
        let color = " "
        const dataItem=item?.holdStatus==9?item?.holdStatus:item.status
        switch (dataItem) {
          case 0:
            color = "#FCE838"; //inProgress
            break;
          case 1:
            color = "#FCE838"; //inProgress
            break;
          case 2:
            color = "#51DE4E"; //Approved
            break;
          case 3:
            color = "#9999ff"; //Withdraw
            break;
          case 4:
            color = "#FF3232"; //Cancel
            break;
          case 5:
            color = "#FF3232"; //Reject
            break;
          case 7:
            color = "#bcb38d"; //Deviation
            break;
          case 8:
            color = "#FFAE00"; //Auto Reject
            break;
            case 9:
              color = "#9999ff"; //hold
              break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: 'black', fontSize: '13px' }}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        )
      },

    },
    {
      title: "Dealer Code",
      dataIndex: "code",
      width: "180px",
      fixed: "left",
      sorter: true,
      key: "DealerCode",
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      width: '130px',
      fixed: "left",
      sorter: true,
      key: "DealerName",
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "150px",
      fixed: "left",
      sorter: true,
      key: "DealerLocation",
      defaultSortOrder: "ascend",
      //  sorter: (a, b) => a.name.length - b.name.length,
      // filters: [
      //   {
      //     text: "Amritsar",
      //     value: "Amritsar",
      //   },
      //   {
      //     text: "Zirakpur",
      //     value: "Zirakpur",
      //   },
      // ],
      // onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },
    {
      title: "State",
      dataIndex: "state",
      width: '150px',
      sorter: true,
      key: "StateName",
      defaultSortOrder: "ascend",
      //  sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Territory Manager",
      dataIndex: "Manager",
      // width: '30px',
      sorter: true,
      key: "TerritoryManager",
      defaultSortOrder: "ascend",
      //   sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.name.length - b.name.length,
      // width: '30px',
      // sorter: true,
    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,
      // width: '30px',
      // sorter: true,
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      defaultSortOrder: "descend",
      //    sorter: (a, b) => a.name.length - b.name.length,
      // width: '50px',
      // sorter: true,

    },
    {
      title: "Targets",
      dataIndex: "Targets",
      defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,
      // width: '60px',
      children: [
        {
          title: "T1",
          dataIndex: "t1",
          defaultSortOrder: "descend",
          //  sorter: (a, b) => a.name.length - b.name.length,
          // width: '30px',
        },
        {
          title: "T2",
          dataIndex: "t2",
          defaultSortOrder: "descend",
          //   sorter: (a, b) => a.name.length - b.name.length,
          // width: '30px',
        }
      ]
    },

    {
      title: "inc. Per Tractor",
      dataIndex: "IPT",
      defaultSortOrder: "descend",
      //   sorter: (a, b) => a.name.length - b.name.length,
      // width: '60px',
      children: [
        {
          title: "R1",
          dataIndex: "r1",
          defaultSortOrder: "descend",
          //   sorter: (a, b) => a.name.length - b.name.length,
          // width: '30px',
        },
        {
          title: "R2",
          dataIndex: "r2",
          defaultSortOrder: "descend",
          //  sorter: (a, b) => a.name.length - b.name.length,
          // width: '30px',
        }
      ]
    },
    {
      title: "Payout",
      dataIndex: "Payout",
      defaultSortOrder: "descend",
      //   sorter: (a, b) => a.name.length - b.name.length,
      // width: '60px',
      children: [
        {
          title: "P1",
          dataIndex: "p1",
          defaultSortOrder: "descend",
          //   sorter: (a, b) => a.name.length - b.name.length,
          // width: '30px',
        },
        {
          title: "P2",
          dataIndex: "p2",
          defaultSortOrder: "descend",
          //  sorter: (a, b) => a.name.length - b.name.length,
          // width: '30px',
        }
      ]
    },
    {
      title: 'Inc Per Target',
      dataIndex: 'incPerTarget',
      defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,

    },
    {
      title: "Installation Target",
      dataIndex: "Target",
      defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,
      // width: "30px",
    },
    {
      title: "Upper Cap",
      dataIndex: "UC",
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.name.length - b.name.length,
      // width: '30px'

    },
    {
      title: "Remarks",
      dataIndex: "reason",
      editable: true,
      defaultSortOrder: "descend",
      //  sorter: (a, b) => a.name.length - b.name.length,
      // width: '50px'

    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: '100px',

      render: (_, record) => (
        <Space size="small">
          <a onClick={handleSingleEdit}>Edit</a>
        </Space>
      ),
    },
  ];

  // fetchSchemeList(userId);
  const fetchGeneratedScheme = async (demoParams) => {
    let result = await axiosAPI.post('SchemeGenerate/GetSchemeGenerate', demoParams);
    // const result = await res.json();
    // dispatch(schemeList(res.data[0]))
    dispatch(generatedScheme(result.data.data.length > 0 && result.data.data.filter((item) => item.schemeNo != null)));
    if (result.data.data.length === 0) setDataSource([]);
  };

  useEffect(() => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const demoParams = {
      fyear: currentFiscalYear,
      schemeType: 7,
      // state: 1,
      // userid: 2,
      status: selectedStatus,
      userid: userId,
      // month: 2,
      // endMonth: 2
      ...payload
    };
    if (selectedStatus === "") { delete demoParams.status }
    fetchGeneratedScheme(demoParams);
  }, [payload, selectedStatus]);



  useEffect(() => {
    const data = [];

    listData?.filter((item) => item.schemeNo != null).map((item, index) => {

      for (let i = 0; i < 1; i++) {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        data.push({
          status: item.status,
          key: item.schemeId,
          scheme: item,
          code: item.dealerCode,
          name: item.dealerName,
          Location: item.dealerLocation,
          state: item.stateName,
          Manager: item.territoryManager,
          StartMonth: formattedStartDate,
          EndMonth: formattedEndDate,
          UC: item?.upperCap,
          Target: item?.installationTarget,
          p1: item?.p1,
          p2: item?.p2,
          r1: item?.r1,
          r2: item?.r2,
          t1: item?.t1,
          t2: item?.t2,
          incPerTarget: item?.incPerTarget,
          reason: item.reason,
          // industry: item.Feb21.Industry,
          // b: item.Feb21.B,
          // i: item.Feb21.I,
          // c: item.Feb21.C,
          // target: item.SlabAchievedLastYear,
          // nos: item.Feb22OpeningStock.Nos,
          // days: item.Feb22OpeningStock.Days,
          // nov21: item.Nov21,
          // dec21: item.Dec21,
          // lac: item.Jan22ClosingOs.Lacs,
          // BooksCollectionTargetT1: item.BooksCollectionTargets.T1,
          // BooksCollectionTargetT2: item.BooksCollectionTargets.T2,
          // BooksCollectionTargetT2T1: item.BooksCollectionTargets.T2T1,
          // BooksCollectionTargetT3: item.BooksCollectionTargets.T3,
          // BooksCollectionTargetT3T2: item.BooksCollectionTargets.T3T2,
          // PayoutRatep1: item.PayoutRate.R1,
          // PayoutRatep2: item.PayoutRate.R2,
          // PayoutRatep3: item.PayoutRate.R3,
          // payoutR1: item.Payout.P1,
          // payoutR2: item.Payout.P3,
          // payoutR3: item.Payout.P1,
          // TEPvol: item.TotalExpectedPayout.Vol,
          // TEPpertr: item.TotalExpectedPayout.PerTr,
          // TEPamount: item.TotalExpectedPayout.Amount,
          // LMSA: item.LastOneMonthSlabAchieved,
          // LCQ: item.LastOneMonthColQty,
          // LMSA1: item.LastTwoMonthSlabAchieved,
          // LCQ1: item.LastTwoMonthColQty

        })
      }
      setDataSource(data)
    })
  }, [listData])


  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userData.userRole;
  const userId = userData.userId;
  const userName = userData.userName;
  const handleUnMergeModal = () => {

    setOpenUnMergeModal(!openUnMergeModal)
  }
  useEffect(() => {
    const value = schemeIdNo.map(item => item.scheme.isMerge)
    const status = schemeIdNo.some(item => item.scheme.status === 3)

    setDisable(status ? status : value.includes(true) && !value.includes(false) ? true : value.includes(true))
    setEnable(status ? status : !value.includes(true) && value.includes(false) ? true : value.includes(false))
  }, [schemeIdNo])

  const handleOk = () => {
    // for first api
    const inactivePayload = schemeIdNo.map(item => ({
      "schemeId": item.scheme.schemeId,
      userName,
      "schemeNo": item.scheme.schemeNo
    }))


    // omitting keys
    const payload2 = schemeIdNo.map(item => item.scheme)
      .map(item => omit(item, ['schemeId', 'schemeNo', 'bookCollectionId', 'payoutId', 'expectedPayoutId']))
      .map(item => ({
        ...item,
        schemeTypeId: item.schemeTypeId.toString(),
        stateId: item.stateId.toString(),
        dealerId: item.dealerId.toString(),
      }))

    // combined two objects to one
    const mergePayload = payload2.reduce((acc, cur) => {
      for (const [key, value] of Object.entries(cur)) {
        if (typeof value === 'string') {
          acc[key] = `${acc[key]},${value}`;
        } else {
          acc[key] += value;
        }
      }
      return acc;
    }
    )
    // need to confirm currentfyear
    const currentFyear = currentFiscalYear
    const createdDate = new Date();
    const endMonth = new Date()
    const startMonth = new Date()
    const schemePlanEndMonth = new Date()
    const schemePlanStartMonth = new Date()

    const p = {
      ...mergePayload,
      fyear: currentFyear,
      createdDate,
      startMonth,
      endMonth,
      schemePlanStartMonth: mergePayload?.startMonth,
      schemePlanEndMonth: mergePayload?.endMonth,
      schemeTypeId: +mergePayload.schemeTypeId.split(',')[0],
      state: +mergePayload.stateId.split(',')[0],
      dealerId: +mergePayload.dealerId.split(',')[0],
      userid: userId,
      username: userName
    }

    const updatePayload = omit(p, ['stateId'])



    axiosAPI.post('UpdateScheme/UpdateInActiveScheme', inactivePayload).then((result) => {
      axiosAPI.post('SchemeGenerate/MergeSchemeGenerate', [updatePayload])
        .then((result) => {
          window.location.reload();
        });
    });
    setLoading(false)
    setOpenMergeModal(false)
  }

  const handleMerge = (e, allData) => {
    setOpenMergeModal(!openMergeModal)
  }
  const handleUnMerge = (e, allData) => {
    const payload = schemeIdNo.map(item => ({
      "schemeId": item.scheme.schemeId,
      userName,
      "schemeNo": item.scheme.schemeNo
    }))

    const payload2 = schemeIdNo.map(item => ({
      "schemeId": item.scheme.schemeId,
      userId,
      userName
    }))

    axiosAPI.post('UpdateScheme/UnMergeSchemeGenerate', payload2[0]).then((result) => {
      axiosAPI.post('UpdateScheme/UpdateInActiveScheme', payload)
        .then((result) => {
          // hit get scheme generate api
          // fetchGeneratedScheme(demoParams);
        });
    });
    setLoading(false)
    setOpenUnMergeModal(false)
  }
  const tableHeader = (d) => {

    return (
      <Row className="table_sub_buttons">

        <Col>
          {/* <Button
            disabled={disable}
            icon={<MergeIcon />}
            type="text"
            onClick={(e) => handleMerge(e, d)}
          >
            Merge
          </Button> */}
        </Col>
        <Col>
          {/* <Button
            disabled={enable}
            icon={<UnmergeIcon />}
            type="text"
            onClick={(e) => handleUnMergeModal(e, d)}
          >
            Unmerge
          </Button> */}
        </Col>
      </Row>
    );
  };
  return (
    <div >
      <Table
        // title={tableHeader}
        className="all_schemeTable_pagination"
        style={{
          cursor: "default",
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, selectedRow) => {

            setSelectedRowKeys(newSelectedRowKeys);
            setSchemeIdNo(selectedRow)
            // getSelectionCheck(newSelectedRowKeys, dataSource);
            sessionStorage.setItem('rowData', JSON.stringify(selectedRow))
          },
        }}

        columns={columns}
        dataSource={dataSource}
        bordered
        scroll={{
          x: 5500,
          y: 800,
        }}
      />
      <MergeModal
        open={openMergeModal}
        handleCancel={handleMerge}
        handleOk={handleOk}
        confirmLoading={loading}
        modalText={'Do you want to merge?'}
      />
      <MergeModal
        open={openUnMergeModal}
        handleCancel={handleUnMergeModal}
        handleOk={handleUnMerge}
        confirmLoading={loading}
        modalText={'Do you want to Unmerge?'}
      />

    </div>
  );
};
export default InstallationAllSchemeTable;

