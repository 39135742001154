import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosAPI from "../../services/axiosApi";
import { encryptData } from "../../utils/localStorageEncodeDecode";
import persistStore from "redux-persist/es/persistStore";

//Login User Action
export const loginUser = createAsyncThunk(
  "Login/Logins1",
  async (params, thunkAPI) => {
    try {
      const { data } = await axiosAPI.post(`Login/Logins`, params);
      if (data?.message == "Wrong Credentials") {
        throw data;
      }
      if (data?.response == 47) {
        throw data?.lockOutEnabled=="true"?"disabled for 5 min":data?.message
      } else {
        return data;
      }
    } catch (error) {
      if (error) {
        localStorage.clear();
      }
      const message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.message) ||
        error?.message ||
        error ||
        error?.string();
      return thunkAPI.rejectWithValue(error?.lockOutEnabled?"disabled for 5 min":message);
    }
  }
);
const clearStore=()=>{
  // setTimeout(()=>{
    sessionStorage.clear();
    window.location.reload();
  // },800)
}
//Login User Action
export const logoutUser = createAsyncThunk(
  "Login/logout",
  async (params, { rejectWithValue }) => {
    try {
      const details = await axiosAPI.post(`Login/logout`, {}).then((data)=>{
        clearStore();
      }).catch((err)=>{

        clearStore();
      }).finally(()=>{
        clearStore();
      })


      return details;
    } catch (e) {
      localStorage.clear();
      // localStorage.clear()
      return rejectWithValue(e);
    }
  }
);
const initialState = {
  isAuthenticated: false,
  userInfo: {
    userRole: "",
    userId: null,
    userName: "",
  },
  loading: "idle",
  loadingLogin: "idle",
  error: false,
};

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loadingLogin = "pending";
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loadingLogin = "succeeded";
      if (action.payload.response === 0 || action.payload.response === 18) {
        throw new Error(action.payload.message);
      }
      state.isAuthenticated = true;

      sessionStorage.setItem("userInfo", encryptData(action.payload.data));
      state.userInfo.userRole = action.payload.data.userRole;
      state.userInfo.userId = action.payload.data.userId;
      state.userInfo.UserName = action.payload.data.userName;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loadingLogin = "failed";
      state.error =
        typeof action.payload == "string"
          ? action.payload
          : action.payload.error.message;
    });
    builder.addCase(logoutUser.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.isAuthenticated = false;
      // window.location.reload();
      state.userInfo = {
        userRole: "",
        userId: null,
        userName: "",
      };
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload.error.message;
    });
  },
});

export const { logout } = authSlice.actions;

export const selectUser = (state) => state.user.userInfo;

export default authSlice.reducer;
