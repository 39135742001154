import omit from 'lodash/omit'
import dayjs from 'dayjs'
import axiosAPI from '../../services/axiosApi';
import { getCurrentFiscalYear } from '../dateFormatter'


const currentFiscalYear = getCurrentFiscalYear()
const mergeScheme = async (schemeIdNo, userName, userId) => {

  // let initialValue = 0
  let startMonth = ''
  let endMonth = ''
  let amount
  for (let i = 0; i <= schemeIdNo.length - 1; i++) {
    for (let j = i + 1; j <= schemeIdNo.length - 1; j++) {
      if (schemeIdNo[i].StartMonth === schemeIdNo[j].StartMonth && schemeIdNo[i].EndMonth === schemeIdNo[j].EndMonth) {
        const monthStart = schemeIdNo[i].StartMonth.split("'")[0]
        const startYear = schemeIdNo[i].StartMonth.split("'")[1]
        const monthEnd = schemeIdNo[i].EndMonth.split("'")[0]
        const endYear = schemeIdNo[i].EndMonth.split("'")[1]
        startMonth = dayjs(`${monthStart} 20${startYear}`).format()
        endMonth = dayjs(`${monthEnd} 20${endYear}`).format()

      }
    }
  }
  if (startMonth === '') {
    return {
      error: "Scheme can't be merged due to different start  and end month"
    }
    // return message.warning("Scheme can't be merged due to different start  and end month")
  }

  const isSchemeNo = schemeIdNo.map(idNo => idNo.scheme.schemeNo)
  const keyToReduce = ['BooksCollectionTargetT1', 'BooksCollectionTargetT2', 'BooksCollectionTargetT3']

  const r1 = schemeIdNo.reduce((minValue, currentObject) => {
    return Math.min(minValue, currentObject.scheme.r1);
  }, schemeIdNo[0].scheme.r1)
  const r2 = schemeIdNo.reduce((minValue, currentObject) => {
    return Math.min(minValue, currentObject.scheme.r2);
  }, schemeIdNo[0].scheme.r2)
  const r3 = schemeIdNo.reduce((minValue, currentObject) => {
    return Math.min(minValue, currentObject.scheme.r3);
  }, schemeIdNo[0].scheme.r3)
 


  const totalr1t1 = schemeIdNo.reduce((acc, curr) => acc + (curr.scheme.t1 * curr.scheme.r1), 0)
  const totalr2t2 = schemeIdNo.reduce((acc, curr) => acc + (curr.scheme.t2 * curr.scheme.r2), 0)
  const totalr3t3 = schemeIdNo.reduce((acc, curr) => acc + (curr.scheme.t3 * curr.scheme.r3), 0)


  const totalTarget = schemeIdNo.reduce((acc, curr) => {

    keyToReduce.forEach(key => {
      if (acc[key]) {
        acc[key] += curr[key]
      }
      else {
        acc[key] = curr[key]
      }
    })
    return acc
  }, [])
  const totalt1 = totalTarget.BooksCollectionTargetT1
  const totalt2 = totalTarget.BooksCollectionTargetT2
  const totalt3 = totalTarget.BooksCollectionTargetT3




  const p1 = Math.round(totalt1 * r1)
  const p2 = Math.round(totalt2 * r2)
  const p3 = Math.round(totalt3 * r3)


  const inactivePayload = schemeIdNo.map(item => ({
    "schemeId": item.scheme.schemeId,
    userName,
    "schemeNo": item.scheme.schemeNo
  }))

  // omitting keys
  const payload2 = schemeIdNo.map(item => item.scheme)
    .map(item => omit(item, ['schemeId', 'schemeNo', 'bookCollectionId', 'payoutId', 'expectedPayoutId', 'deviationAmount']))
    .map(item => ({
      ...item,
      schemeTypeId: item.schemeTypeId.toString(),
      stateId: item.stateId.toString(),
      dealerId: item.dealerId.toString(),
    }))

  // combined two objects to one
  const mergePayload = payload2.reduce((acc, cur) => {
    for (const [key, value] of Object.entries(cur)) {
      if(key =="schemePlanStartMonth"||key =="schemePlanEndMonth"){
        acc[key] = `${acc[key]}`;
      }
      else if (typeof value === 'string') {
        acc[key] = `${acc[key]},${value}`;
      }
      else {
        acc[key] += value;
      }
    }
    return acc;
  })
  if (mergePayload.volume >= totalt1 &&
    mergePayload.volume < totalt2) {
    amount = mergePayload.volume * r1
  }
  else if (mergePayload.volume >= totalt2 &&
    mergePayload.volume < totalt3) {
    amount = mergePayload.volume * r2
  }
  else {
    amount = mergePayload.volume * r3
  }

  const perTr = Math.round(amount/mergePayload.volume)
  // need to confirm currentfyear
  const currentFyear = currentFiscalYear
  const createdDate = new Date();
  // const endMonth = new Date()
  // const startMonth = new Date()
  const schemePlanEndMonth = new Date()
  const schemePlanStartMonth = new Date()


  const p = {
    ...mergePayload,
    fyear: currentFyear,
    createdDate,
    startMonth,
    endMonth,
    p1: p1 ? p1 : 0,
    p2: p2 ? p2 : 0,
    p3: p3 ? p3 : 0,
    r1: r1 ? r1 : 0,
    r2: r2 ? r2 : 0,
    r3: r3 ? r3 : 0,
    // schemePlanStartMonth:mergePayload?.startMonth,
    // schemePlanEndMonth:mergePayload?.endMonth,
    schemeTypeId: +mergePayload.schemeTypeId.split(',')[0],
    state: +mergePayload.stateId.split(',')[0],
    status: isSchemeNo.includes(null) ? 0 : 1,
    dealerId: +mergePayload.dealerId.split(',')[0],
    amount: amount,
    userid: userId,
    username: userName,
    perTr: perTr
  }

  // const updatePayload = omit(p, ['stateId'])
  // setLoading(true)
  await axiosAPI
    .post(`UpdateScheme/UpdateInActiveScheme`, inactivePayload)
    .then((result) => {
      const oldScheme = result.data.data
      const schemePayload = {
        OldScheme: oldScheme,
        ...p,
      }
      axiosAPI
        .post(`SchemeGenerate/MergeSchemeGenerate`, [schemePayload])
      .then((result) => window.location.reload())
      // window.location.reload();)
    })

}

export default mergeScheme
