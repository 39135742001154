import { useState, useEffect, useCallback, useRef } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Collapse, Space, Pagination, Dropdown, Button, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import StatusC from "../../../components/statusBox/Status";
import AllSchemeTable from "./AllSchemeTable";
import { add, fetchgenerateScheme } from "../../../redux/collection/PostScheme";
import { useDispatch, useSelector } from "react-redux";
import { generatedScheme } from "../../../redux/collection/AllGeneratedScheme";
import Status from "../../../components/allSchemeStatus/allSchemeStatus";
import axiosAPI from "../../../services/axiosApi";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import { Download } from "@mui/icons-material";
import CollectionFilter from "../../../components/installation/collectionFilter";
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const { Panel } = Collapse;

const currentFiscalYear = getCurrentFiscalYear();

const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: 1,
  dealerCode: [],
  startDate: "",
  endDate: "",
};

const CollapseTab = ({ payload, startMonth,dealerDetails }) => {
  const navigate = useNavigate();
  const [enable, setEnable] = useState(false);


  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedSchemeType, setSelectedSchemeType] = useState(1);
  const childref = useRef();

  const handleEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/collection/editScheme");
  };
  const actionValues = [
    {
      key: "1",
      label: (
        <Button
          className="link"
          type="link"
          onClick={(e) => handleEditClick(e)}
        >
          Edit
        </Button>
      ),
    },
  ];

  const [items, setItems] = useState(
    actionValues.filter((item) => item.key !== "1")
  );
  let keys = [];

  const getSelectionCheck = (selectedRowKeys, dataSource) => {
    if (selectedRowKeys.length <= 1) {
      setItems(items.filter((item) => item.key !== "1"));
    } else {
      setItems(actionValues);
    }

    keys = selectedRowKeys;
  };

  const collapseProps = {
    items,
    trigger: ["click"],
  };
  const defaultActiveKey = ["1", "2"];

  const genExtra = () => (
    <a onClick={(e) => e.preventDefault()}>
      <Dropdown menu={collapseProps}>
        <MoreOutlined className="more_option" style={{ height: "20px" }} />
      </Dropdown>
    </a>

    // <MoreOutlined

    // onClick={(event) => {
    //   // If you don't want click extra trigger collapse, you can prevent this:
    //   event.stopPropagation();
    // }}
    // />
  );

  const fetchGeneratedScheme = (demoParams) => {
    setSelectedSchemeType(demoParams.schemeType);
  };
  return (
    <>
      <Space
        className="collapse-items collapse-box-cell-table"
        direction="vertical"
        size="large"
      >
        <Collapse
          className="all_scheme_collapse"
          defaultActiveKey={defaultActiveKey}
        >
          <Panel
            className="panel_heading"
            size="large"
            header="Incentive Collection "
            key="1"
            // extra={genExtra()}
          >
 
            {/* <div className="d-flex" id="tophead"> */}
            <div className="allSchmeStatus d-flex justify-content-between">
              <div>
              <Status
                selectedStatus={selectedStatus}
                handleStatusDropdown={(statusId) => setSelectedStatus(statusId)}
              />
              </div>
              <div>
              <CollectionFilter onGetData={fetchGeneratedScheme} />
           
              <Button className="mx-1" onClick={() => childref.current.click()}>
                Download
              </Button>
          

              </div>
              </div>
            {/* </div> */}
            {/* {selectedSchemeType == 1  || selectedSchemeType == 2 &&  */}
            <AllSchemeTable
              selectedStatus={selectedStatus}
              selectedSchemeType={selectedSchemeType}
              payload={payload}
              startMonth={startMonth}
              getSelectionCheck={getSelectionCheck}
              reference={childref}
              setEnable={setEnable}
              enable={enable}
              dealerDetails={dealerDetails}
            />
            {/* } */}
            {/* <StatusC/> */}
          </Panel>
        </Collapse>
        {/* <Collapse className='all_scheme_collapse' >
            <Panel className='panel_heading' size = "large" header="Incentive Collection FY 2023 - April" key="1" extra={genExtra()}>
                <AllSchemeTable/>
            </Panel>
        </Collapse>
        <Collapse className='all_scheme_collapse' >
            <Panel className='panel_heading' size = "large" header="Incentive Collection FY 2023 - May" key="1" extra={genExtra()}>
                <AllSchemeTable/>
            </Panel>
        </Collapse>
        <Collapse className='all_scheme_collapse' >
            <Panel className='panel_heading' size = "large" header="Incentive Collection FY 2023 - June" key="1" extra={genExtra()}>
                <AllSchemeTable/>
            </Panel>
        </Collapse>
        <Collapse className='all_scheme_collapse' >
            <Panel className='panel_heading' size = "large" header="Incentive Collection FY 2023 - July" key="1" extra={genExtra()}>
                <AllSchemeTable/>
            </Panel>
        </Collapse> */}
        {/* <Pagination
          className="All_Scheme_pagination"
          defaultCurrent={1}
          total={30}
        /> */}
      </Space>
    </>
  );
};
export default CollapseTab;
