/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
// import { Table, Divider, Col, Row, Form, Switch } from "antd";
import axiosAPI from "../../../services/axiosApi";
import {
  Space,
  Table,
  Divider,
  Col,
  Row,
  Form,
  Checkbox,
  Switch,
  Button,
  Modal,
  message,
} from "antd";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import { ReactComponent as HideIcon } from "../../../assets/vector/hide.svg";
import { ReactComponent as UnHideIcon } from "../../../assets/vector/unHide.svg";
import { postSchemeListApi } from "../../../redux/stateData/stateDataSlice";
import { useDispatch, useSelector } from "react-redux";
const currentFiscalYear = getCurrentFiscalYear();

const Schemes = () => {


  const [showHideBtn,setShowHideBtn]=useState(true)
  const [dataSource, setDataSource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { loadingSchemeList } = useSelector((state) => state.stateData);
  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userId = userData.userId;
  const username = userData.userName;
  const dispatch=useDispatch()
  const displayMessage = (type, content) => {
    messageApi.open({
      type,
      content,
    });
  };

  // const handleStatus = (record) => {
  //   const { stateId, scheme1, scheme2, scheme3, t4, t5, t6, stateName } = record;
  //   const payload = [
  //     {
  //       userid: userId,
  //       stateId,
  //       scheme1,
  //       scheme2,
  //       scheme3,
  //       t4,
  //       t5,
  //       t6,
  //       username,
  //       stateName,
  //     },
  //   ];
  //   axiosAPI
  //     .post(`Dashboard/SchemeStateRequestList`, payload)
  //     .then((result) => {
  //       fetchSchemeStatus();
  //     });
  // };

  // const handleCheckboxChange = (e, stateName) => {
  //   const { name, checked } = e.target;
  //   const selectedIndex = [...dataSource].findIndex(
  //     (item) => item.stateName == stateName
  //   );
  //   const updateData = [...dataSource];
  //   updateData[selectedIndex][name] = !updateData[selectedIndex][name];
  //   setDataSource(updateData);
  // };

  const mapSchemeLabels = (schemeType) => {
    let label = "";
    switch (schemeType) {
      case "scheme1":
        label = "With delivery plan";
        break;
      case "scheme2":
        label = "Without delivery plan";
        break;
      case "scheme3":
        label = "Slab Based";
        break;
      case "scheme4":
        label = "Single Target with Upper Cap";
        break;
      case "scheme5":
        label = "Unconditional Installation Scheme";
        break;
      case "scheme6":
        label = "Single Target";
        break;
      case "t3I":
        label = "T3";
        break;
      case "t4":
        label = "T4";
        break;
      case "t5":
        label = "T5";
        break;
      case "t6":
        label = "T6";
        break;
      default:
        label = "with delivery plan";
        break;
    }
    return label;
  };

  const updateScheme = async (selectedScheme) => {
    try {

      const postData = [
        {
          userid: selectedScheme?.userid,
          username: selectedScheme?.username,
          stateId: selectedScheme?.stateId,
          stateName: selectedScheme?.stateName,
          scheme1: selectedScheme?.collection?.scheme1,
          scheme2: selectedScheme?.collection?.scheme2,
          scheme3: selectedScheme?.installation?.scheme3,
          scheme4: selectedScheme?.installation?.scheme4,
          scheme5: selectedScheme?.installation?.scheme5,
          scheme6: selectedScheme?.installation?.scheme6,
          t3I: selectedScheme?.installation?.t3I,
          t4: selectedScheme?.collection?.t4,
          t5: selectedScheme?.collection?.t4 ? selectedScheme?.collection?.t5 : false,
          t6: selectedScheme?.collection?.t4 ? selectedScheme?.collection?.t6 : false,
        },
      ];

      const result = await axiosAPI.post(
        `Dashboard/SchemeStateRequestList`,
        postData
      );
      success();
    } catch (error) {
    }
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Successfully Updated",
    });
  };
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  const handleOk = () => {
    success();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // let [toggleCheck, setToggleCheck] = useState(true)


  const handleLockUnlock = async (checked, schemeType, record, type) => {
    if (schemeType == "t5") {
      if (record.collection.t4 == true) {
        const schemeData = [...dataSource];

        const selectedIndex = schemeData.findIndex(
          (item) => item.stateId === record.stateId
        );
        // setToggleCheck(false)


        schemeData[selectedIndex] = {
          ...schemeData[selectedIndex],
          [type]: {
            ...schemeData[selectedIndex][type],
            [schemeType]: !checked,
          },
        };
        if (schemeType == "t5" && record.collection.t5 == true) {
          schemeData[selectedIndex] = {
            ...schemeData[selectedIndex],
            [type]: {
              ...schemeData[selectedIndex][type],
              ["t6"]: !checked,
            },
          };
        }
        setDataSource(schemeData);

        await updateScheme(schemeData[selectedIndex]);
      }
      else {
        displayMessage('warning', 'Firstly you have to unlock T4.');
      }
    }
    else if (schemeType == "t6") {
      if (record.collection.t4 == true && record.collection.t5 == true) {
        const schemeData = [...dataSource];

        const selectedIndex = schemeData.findIndex(
          (item) => item.stateId === record.stateId
        );
        // setToggleCheck(false)


        schemeData[selectedIndex] = {
          ...schemeData[selectedIndex],
          [type]: {
            ...schemeData[selectedIndex][type],
            [schemeType]: !checked,
          },
        };
        setDataSource(schemeData);
        await updateScheme(schemeData[selectedIndex]);
      }
      else {
        displayMessage('warning', 'Firstly you have to unlock T5.');
      }
    }
    else {
      const schemeData = [...dataSource];

      const selectedIndex = schemeData.findIndex(
        (item) => item.stateId === record.stateId
      );
      // setToggleCheck(false)


      schemeData[selectedIndex] = {
        ...schemeData[selectedIndex],
        [type]: {
          ...schemeData[selectedIndex][type],
          [schemeType]: !checked,
        },
      };
      if (schemeType == "t4" && record.collection.t4 == true) {
        schemeData[selectedIndex] = {
          ...schemeData[selectedIndex],
          [type]: {
            ...schemeData[selectedIndex][type],
            ["t5"]: !checked,
          },
        };
        schemeData[selectedIndex] = {
          ...schemeData[selectedIndex],
          [type]: {
            ...schemeData[selectedIndex][type],
            ["t6"]: !checked,
          },
        };
      }
      setDataSource(schemeData);
      await updateScheme(schemeData[selectedIndex]);
    }

  };

  
  const handleAllStates = (checked, type, schemeType) => {
    const allStateData = dataSource?.filter(dataView => dataView?.userId == "All States");
    if(type=="collection"){
      if (schemeType=="t5"&&!allStateData?.[0]?.collection?.t4) {
        return  displayMessage('warning', 'Firstly you have to unlock T4');
       }   
      if (schemeType=="t6"&&(!allStateData?.[0]?.collection?.t4||!allStateData?.[0]?.collection?.t5)) {
        return  displayMessage('warning', 'Firstly you have to unlock T5');
       }
  }

    const updatedData = dataSource.map(item => {
      if (item[type] && item[type][schemeType] !== undefined) {

        return {
          ...item, 
          [type]: {
            ...item[type], 
            ...schemeType==="t6"&&checked?{
              "t6": !checked,
              "t5": allStateData?.[0]?.collection?.t5||false,
              "t4": allStateData?.[0]?.collection?.t4||false, 
            }:schemeType==="t5"&&checked?{
              "t6": false,
              "t5": !checked,
              "t4": allStateData?.[0]?.collection?.t4||false, 
            }:schemeType==="t4"&&checked?{
              "t6": false,
              "t5": false,
              "t4": !checked, 
            }:{
            [schemeType]: !checked 
            }
          }
        }
      }
      return item;
    })
 
    setDataSource(updatedData);
    const filteredData = updatedData?.filter(dataView => dataView?.userId !== "All States");
  
    const mappedData = filteredData?.map(dataArr => {
      const { userid, username, stateId, stateName, collection, installation } = dataArr;
      return {
        userid,
        username,
        stateId,
        stateName,
        scheme1: collection?.scheme1,
        scheme2: collection?.scheme2,
        scheme3: installation?.scheme3,
        scheme4: installation?.scheme4,
        scheme5: installation?.scheme5,
        scheme6: installation?.scheme6,
        t3I: installation?.t3I,
        t4: collection?.t4||false,
        t5: collection?.t5 || false,
        t6: collection?.t6 ||false,
      };
    });
    
    dispatch(postSchemeListApi(mappedData));
  };

const columns = useMemo(() => [
  {
    title: "State",
    dataIndex: "stateName",
    width: "170px",
  },
  {
    title: "Collection",
    dataIndex: "collection",
    render: (_, record) => {
      if (!Object.keys(record?.collection).length) {
        return null;
      }
      return (
        <div>
          {Object.keys(record?.collection).map((schemeType,index) => (
            <div className="scheme-switch-row" style={{ margin: "10px" }} key={index}>
              <span>{mapSchemeLabels(schemeType)}</span>
              <Switch
                checkedChildren="Lock"
                unCheckedChildren="Unlock"
                checked={!record?.collection?.[schemeType]}
                onChange={(checked) =>
                  record?.stateName === "All States"
                    ? handleAllStates(checked, "collection", schemeType)
                    : handleLockUnlock(checked, schemeType, record, "collection")
                }
              />
            </div>
          ))}
        </div>
      );
    },
  },
  {
    title: "Installation",
    dataIndex: "installation",
    render: (x, record) => {
      if (!Object.keys(record?.installation).length) {
        return null;
      }
      return (
        <div>
          {Object.keys(record?.installation).map((schemeType,index) => (
            <div className="scheme-switch-row" style={{ margin: "10px" }} key={index}>
              <span>{mapSchemeLabels(schemeType)}</span>
              <Switch
                checkedChildren="Lock"
                unCheckedChildren="Unlock"
                checked={!record?.installation?.[schemeType]}
                onChange={(checked) =>
                  record?.stateName === "All States"
                    ? handleAllStates(checked, "installation", schemeType)
                    : handleLockUnlock(checked, schemeType, record, "installation")
                }
              />
            </div>
          ))}{" "}
        </div>
      );
    },
  },
], [handleAllStates, handleLockUnlock]);

  const fetchSchemeStatus = () => {
    const payload = {
      fyear: currentFiscalYear,
      schemeType: 0,
      state: 0,
      userid: userId,
      month: 4,
      monthEnd: 4,
    };
    axiosAPI
      .post(`Dashboard/GetSchemeStatus`, payload)
      .then((result) => {
        if (!result?.data?.data) {
          return false;
        }
        const filteredData = result?.data?.data.map((state) => {
          return {
            userId: state.userId,
            stateId: state.stateId,
            collection: {
              scheme1: state.scheme1,
              scheme2: state.scheme2,
              t4: state.t4,
              t5: state.t5,
              t6: state.t6,
            },
            installation: {
              scheme3: state.scheme3,
              scheme4: state.scheme4,
              scheme5: state.scheme5,
              scheme6: state.scheme6,
              t3I: state.t3I
            },
            username: state.username,
            stateName: state.stateName,
          };
        });

        const allStates=[{
          userId: 'All States',
          stateId: 'All States',
          collection: {
            scheme1: filteredData?.filter((data)=>data?.collection?.scheme1)?.length==filteredData?.length?true:filteredData?.filter((data)=>data?.installation?.scheme1)?.length!=0?null:false,
            scheme2: filteredData?.filter((data)=>data?.collection?.scheme2)?.length==filteredData?.length?true:filteredData?.filter((data)=>data?.installation?.scheme2)?.length!=0?null:false,
            t4: filteredData?.filter((data)=>data?.collection?.t4)?.length==filteredData?.length?true:filteredData?.filter((data)=>data?.installation?.t4)?.length!=0?null:false,
            t5: filteredData?.filter((data)=>data?.collection?.t5)?.length==filteredData?.length?true:filteredData?.filter((data)=>data?.installation?.t5)?.length!=0?null:false,
            t6: filteredData?.filter((data)=>data?.collection?.t6)?.length==filteredData?.length?true:filteredData?.filter((data)=>data?.installation?.t6)?.length!=0?null:false,
          },
          installation: {
            scheme3: filteredData?.filter((data)=>data?.installation?.scheme3)?.length==filteredData?.length?true:filteredData?.filter((data)=>data?.installation?.scheme3)?.length!=0?null:false,
            scheme4: filteredData?.filter((data)=>data?.installation?.scheme4)?.length==filteredData?.length?true:filteredData?.filter((data)=>data?.installation?.scheme4)?.length!=0?null:false,
            scheme5: filteredData?.filter((data)=>data?.installation?.scheme5)?.length==filteredData?.length?true:filteredData?.filter((data)=>data?.installation?.scheme5)?.length!=0?null:false,
            scheme6: filteredData?.filter((data)=>data?.installation?.scheme6)?.length==filteredData?.length?true:filteredData?.filter((data)=>data?.installation?.scheme6)?.length!=0?null:false,
            t3I: filteredData?.filter((data)=>data?.installation?.scheme6)?.length==filteredData?.length?true:filteredData?.filter((data)=>data?.installation?.scheme6)?.length!=0?null:false,
          },
          username: "All States",
          stateName: "All States",
        }]
        setDataSource(filteredData);
      })
      .catch((error) => console.log("ERROR in fetchSchemeStatus : ", error));
  };

  useEffect(() => {
    fetchSchemeStatus();
  }, []);


  return (
    <>
      <div className="table_scheme_wrap onemoth-date-selector ">
        <Divider />
        <grid>
          <Row className="flex">
            <Col flex="auto">
              <h2

                style={{ fontSize: "18px", padding: "10px" }}
              >
                Schemes
              </h2>
            </Col>
            <Col>
          <Switch
                checked={showHideBtn}
                onChange={() => setShowHideBtn(!showHideBtn)}
                checkedChildren="Hide"
                unCheckedChildren="Unhide"
              />
              </Col>
     </Row>
        </grid>

       {showHideBtn&& <Form>
          <Table
            rowClassName={() => "editable-row"}
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={false}
            scroll={{
              x: 1000,
              y: 200,
            }}
            loading={loadingSchemeList=="pending"?true:false}
          />
        </Form>}
        <div>
        </div>
      </div>
      {contextHolder}
      <Modal
        title="Are you sure you want to save"
        open={isModalOpen}
        onOk={handleOk}        maskClosable={false}
        destroyOnClose={true}
        onCancel={handleCancel}
      ></Modal>
    </>
  );
};
export default Schemes;
