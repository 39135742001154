import { object, string, number } from 'yup';

export const formSchema = object({
  volume: number().min(1).required(),
  // perTr: number().min(0).required(),
  // amount: string().required(),
  billing: number().min(1).required(),
  collection: number().min(1).required(),
  r1: number().min(1).required(),
  r2: number().min(1).required(),
  r3: number().min(1).required(),
  t1: number().min(1).required(),
  t2: number().min(1).required(),
  t3: number().min(1).required(),
  industry: number().min(1).required(),
  delayPlan: number().min(1).required(),
  // perTr: number().required().positive().integer(),
  // website: string().url().nullable(),
});

export const singleTargetSchema = object({
  // p1: number().min(0).required(),
  incPerTarget: number().min(1).required(),
  installationTarget: number().min(1).required(),
});
export const singleTargetWithUpperCapSchema = object({
  incPerTarget: number().min(1).required(),
  installationTarget: number().min(1).required(),
  upperCap: number().min(1).required(),
});

export const slabBasedSchema = object({
  t1: number().min(1).required(),
  t2: number().min(1).required(),
  r1: number().min(1).required(),
  r2: number().min(1).required(),
});