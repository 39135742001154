import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { trackCollectionDealersList, trackInstallationDealersList } from "./trackCollectionService";

export const getTrackCollectionDealersListApi = createAsyncThunk(
  "getTrackCollectionDealersListApi",
  async (data, thunkAPI) => {
    try {
      const response = await trackCollectionDealersList(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTrackInstallationDealersListApi = createAsyncThunk(
  "getTrackInstallationDealersListApi",
  async (data, thunkAPI) => {
    try {
      const response = await trackInstallationDealersList(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const trackCollectionSlice = createSlice({
  name: "trackCollectionSlice",
  initialState: {
    dealersList:{},
    loadingScheme: "idle",
    trackInstallationList: {}
  },
  reducers: {
    // resetState: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getTrackCollectionDealersListApi.pending, (state) => {
      state.loadingScheme = "pending";
    });
    builder.addCase(getTrackCollectionDealersListApi.fulfilled, (state, action) => {
      state.loadingScheme = "succeeded";
      state.dealersList = action.payload;
      state.error = false;
    });
    builder.addCase(getTrackCollectionDealersListApi.rejected, (state) => {
      state.dealersList = {};
      state.loadingScheme = "succeeded";
      state.error = true;
    });
    //schemeListApproveApi
    builder.addCase(getTrackInstallationDealersListApi.pending, (state) => {
      state.loadingScheme = "pending";
    });
    builder.addCase(getTrackInstallationDealersListApi.fulfilled, (state, action) => {
      state.loadingScheme = "succeeded";
      state.trackInstallationList = action.payload;
      state.error = false;
    });
    builder.addCase(getTrackInstallationDealersListApi.rejected, (state) => {
      state.trackInstallationList = {};
      state.loadingScheme = "succeeded";
      state.error = true;
    });
  },
});
export const { reset } = trackCollectionSlice.actions;

export default trackCollectionSlice.reducer;
