import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCollectionDealersList, trackGetAreaManager, trackGetStateHeadbyAMId, trackGetZonalHeadbyAMId,trackZonalHeadbySHId } from "./schemaTrackService";

export const trackGetAreaManagerApi = createAsyncThunk(
  "trackGetAreaManagerApi",
  async (data, thunkAPI) => {
    try {
      const response = await trackGetAreaManager(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const trackGetStateHeadbyAMIdApi = createAsyncThunk(
  "trackGetStateHeadbyAMIdApi",
  async (data, thunkAPI) => {
    try {
      const response = await trackGetStateHeadbyAMId(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const trackGetZonalHeadbyAMIdApi = createAsyncThunk(
  "trackGetZonalHeadbyAMIdApi",
  async (data, thunkAPI) => {
    try {
      const response = await trackGetZonalHeadbyAMId(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const trackZonalHeadbySHIdApi = createAsyncThunk(
  "trackZonalHeadbySHIdApi",
  async (data, thunkAPI) => {
    try {
      const response = await trackZonalHeadbySHId(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCollectionDealersListApi = createAsyncThunk(
  "getCollectionDealersListApi",
  async (data, thunkAPI) => {
    try {
      const response = await getCollectionDealersList(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const schemeTrackSlice = createSlice({
  name: "schemeTrackSlice",
  initialState: {
    getAreaManager: {},
    getStateHead:{},
    getZonalHead:{},
    getZonalHeadSH:{},
    getCollectionList:{},
    loadingTrack: "idle",
  },
  reducers: {
     reset: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(trackGetAreaManagerApi.pending, (state) => {
      state.loadingTrack = "pending";
    });
    builder.addCase(trackGetAreaManagerApi.fulfilled, (state, action) => {
      state.loadingTrack = "succeeded";
      state.getAreaManager = action.payload;
      state.error = false;
    });
    builder.addCase(trackGetAreaManagerApi.rejected, (state) => {
      state.getAreaManager = {};
      state.loadingTrack = "succeeded";
      state.error = true;
    });
    builder.addCase(trackGetStateHeadbyAMIdApi.pending, (state) => {
      state.loadingTrack = "pending";
    });
    builder.addCase(trackGetStateHeadbyAMIdApi.fulfilled, (state, action) => {
      state.loadingTrack = "succeeded";
      state.getStateHead = action.payload;
      state.error = false;
    });
    builder.addCase(trackGetStateHeadbyAMIdApi.rejected, (state) => {
      state.getStateHead = {};
      state.loadingTrack = "succeeded";
      state.error = true;
    });
    builder.addCase(trackGetZonalHeadbyAMIdApi.pending, (state) => {
      state.loadingTrack = "pending";
    });
    builder.addCase(trackGetZonalHeadbyAMIdApi.fulfilled, (state, action) => {
      state.loadingTrack = "succeeded";
      state.getZonalHead = action.payload;
      state.error = false;
    });
    builder.addCase(trackGetZonalHeadbyAMIdApi.rejected, (state) => {
      state.getZonalHead = {};
      state.loadingTrack = "succeeded";
      state.error = true;
    });
    builder.addCase(trackZonalHeadbySHIdApi.pending, (state) => {
      state.loadingTrack = "pending";
    });
    builder.addCase(trackZonalHeadbySHIdApi.fulfilled, (state, action) => {
      state.loadingTrack = "succeeded";
      state.getZonalHeadSH = action.payload;
      state.error = false;
    });
    builder.addCase(trackZonalHeadbySHIdApi.rejected, (state) => {
      state.getZonalHeadSH = {};
      state.loadingTrack = "succeeded";
      state.error = true;
    });
    builder.addCase(getCollectionDealersListApi.pending, (state) => {
      state.loadingTrack = "pending";
    });
    builder.addCase(getCollectionDealersListApi.fulfilled, (state, action) => {
      state.loadingTrack = "succeeded";
      state.getCollectionList = action.payload;
      state.error = false;
    });
    builder.addCase(getCollectionDealersListApi.rejected, (state) => {
      state.getCollectionList = {};
      state.loadingTrack = "succeeded";
      state.error = true;
    });
    
  },
});
export const { reset } = schemeTrackSlice.actions;

export default schemeTrackSlice.reducer;
