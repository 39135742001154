import React from 'react'
import { Modal } from 'antd';

const MailPreviewModal = ({children,open,onCancel,onOk}) => {
  return (
    <div>
     <Modal 
        className='mailModal'
        bodyStyle={{ overflow: 'auto', maxHeight: 'calc(100vh - 280px)'}}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        closable={false}
        okText="Send"
        cancelText="Cancel"
        maskClosable={false}
        destroyOnClose={true}
        // okButtonProps={{ style: { display: "none" } }}
        >
            
        {/* <Icon className='custom-icon' component={SmileIcon}/> */}
        {children}
       
      </Modal>
      
    </div>
  )
}

export default MailPreviewModal
