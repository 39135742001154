import React, { useEffect, useState } from "react";
import { Button, Row, Col, message } from "antd";
import "./button.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
// import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown, Space, DatePicker, Select, Tooltip } from "antd";
import axiosAPI from "../../services/axiosApi";
import { getCurrentFiscalYear } from "../../utils/dateFormatter";
import { getLockedSchemeTypeAsync } from "../../redux/stateData/stateDataSlice";
import moment from "moment";
import { decryptData } from "../../utils/localStorageEncodeDecode";

const { RangePicker } = DatePicker;

const ThemeButton = ({
  notDisableStart,
  disableStartYear,
  title,
  onSchemeChange,
  onSelectSchemeType,
  handleStartChange,
  handleEndChange,
  onSelectDealer,
  collectionData,
  listData,
  showStartDateEndDateMonth,
  onGetDealerDropdownData,
  showDealer = true,
  showSchemeType = true,
  showStartMonth = false,
  showEndMonth = true,
  disableStartMonthShow=false,
  disableDateRestriction = false,
  currentMonthRestriction = false,
  handleDateRange = () => { },
  lastMonth = false,
  pageType
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [stateHeadData, setStateHeadData] = useState([]);
  const [areaMngrData, setAreaMngrData] = useState([]);
  const [selectedSH, setSelectedSH] = useState("");
  const [selectedAM, setSelectedAM] = useState("");
  const [showIcon, setShowIcon] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [startMonth, setStartMonth] = useState(dayjs());
  const [areaDropdown, setAreaDropdown] = useState([]);
  const [lock, setLock] = useState(false);
  const { currentYear, schemeType, dealerCode } = collectionData;

  const { userRole, userId } = decryptData(sessionStorage.getItem("userInfo")) || {};
  const { lockedStates } = useSelector((state) => state.stateData);
  const currentFiscalYear = getCurrentFiscalYear();
  const [isScheme1Lock, setisScheme1Lock] = useState(false);
  const [isScheme2Lock, setisScheme2Lock] = useState(false);
  const [isScheme3Lock, setisScheme3Lock] = useState(false);
  const [isScheme4Lock, setisScheme4Lock] = useState(false);
  const [isScheme5Lock, setisScheme5Lock] = useState(false);
  const [isScheme6Lock, setisScheme6Lock] = useState(false);

  const getAmDropdownData = (userId) => {
    axiosAPI.post(`SchemeGenerate/GetDropdownAM`, { userId }).then((result) => {
      setAreaDropdown(result?.data?.data);

      setAreaMngrData(
        result.data.data.map((item) => {
          return {
            key: item.id,
            label: (
              <span onClick={() => getLockedScheme(item.id)}>{item.aM}</span>
            ),
          };
        })
      );
    });
  };

  useEffect(() => {
    if (userRole == "ZADM") {
      axiosAPI
        .post(`SchemeGenerate/GetDropdownStateHead`, { userId })
        .then((result) => {
          setStateHeadData(
            result.data.data.map((item) => {
              return {
                key: item.id,
                label: (
                  <span
                    onClick={() => {
                      setSelectedSH(item.stateHeadName);
                      getAmDropdownData(item.id);
                    }}
                  >
                    {item.stateHeadName}
                  </span>
                ),
              };
            })
          );
        });
    }
    if (userRole == "SADM") {
      getAmDropdownData(userId);
    }
    if (userRole != "ZADM") {
      getLockedScheme();
      dispatch(getLockedSchemeTypeAsync(""))
    }
  }, []);

  const getLockedScheme = async (amId = "") => {
    try {
      // Assuming dispatch returns a Promise (async operation)
      const apiResponse = await dispatch(getLockedSchemeTypeAsync(amId));
      // Update state variables based on the API response
      setisScheme1Lock(apiResponse.payload[0].scheme1);
      setisScheme2Lock(apiResponse.payload[0].scheme2);
      setisScheme3Lock(apiResponse.payload[0].scheme3);
      setisScheme4Lock(apiResponse.payload[0].scheme4);
      setisScheme5Lock(apiResponse.payload[0].scheme5);
      setisScheme6Lock(apiResponse.payload[0].scheme6);
      // Log the updated state variables for debugging
    } catch (error) {
      // Handle any errors that occur during the API call or state updates
      console.error("Error fetching locked scheme:", error);
    }
  };

  // // const {lockedStates, setlockedStates(} = useState() ;
  //   useEffect(() =>{
  //
  //     // get locked staes
  //     const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {};
  //     const accessToken = userInfo?.token;
  //     try{
  //     const response=axiosAPI.get(`dashboard/DecodeTokenNEW?token=`+accessToken)
  //         .then((data) => {
  //           return data?.data;
  //         })
  //         .catch((error) => {
  //             // ToastifyShow("Something went wrong","error")
  //           return error;
  //         });
  //       return response;

  //     } catch (err) {
  //       throw err;
  //     }
  //   },
  //    [] );

  // useEffect (() => {
  // // onSelectSchemeType(value)
  // const apiresponse = dispatch(getLockedSchemeTypeAsync());
  // setisScheme1Lock(apiresponse.scheme1);
  // setisScheme2Lock(apiresponse.scheme2);
  // setisScheme3Lock(apiresponse.scheme3);
  // setisScheme4Lock(apiresponse.scheme4);
  // setisScheme5Lock(apiresponse.scheme5);
  // setisScheme6Lock(apiresponse.scheme6);
  // }, []);

  const items = [
    {
      key: "1",
      label: <a>Select Year</a>,
    },
    {
      key: "2",
      label: <a>2020-2021</a>,
      disabled: false,
    },
    {
      key: "3",
      label: <a>2021-2022</a>,
      disabled: false,
    },
  ];
  const subtype = [
    {
      key: "1",
      label: <a onClick={() => handleSchemeDropdown(3)}>Slab Based</a>,
      disabled: !isScheme3Lock,
    },
    {
      key: "2",
      label: <a onClick={() => handleSchemeDropdown(8)}>Single Target</a>,
      disabled: !isScheme6Lock,
    },
    {
      key: "3",
      label: (
        <a onClick={() => handleSchemeDropdown(4)}>
          Single Target With Upper Cap
        </a>
      ),
      disabled: !isScheme4Lock,
    },
    {
      key: "4",
      label: (
        <a onClick={() => handleSchemeDropdown(5)}>
          Unconditional Installation
        </a>
      ),
      disabled: !isScheme5Lock,
    },
  ];
  const newSubType = subtype.filter((item) => !item.disabled);

  const subColType = [
    {
      key: "1",
      label: <a onClick={() => handleSchemeDropdown(1)}>With Delivery Plan</a>,
      disabled: !isScheme1Lock, // No curly braces around isScheme1Lock
    },
    {
      key: "2",
      label: (
        <a onClick={() => handleSchemeDropdown(2)}>Without Delivery Plan</a>
      ),
      disabled: !isScheme2Lock,
    },
  ];
  const newSubColType = subColType.filter((item) => !item.disabled);

  const dealerType = listData?.map((item) => {
    return {
      value: item.code,
      label: item.code,
      disabled: item.isExist ? true : false,
      openingStock: item.oldOpeningStock,
      closingOs: item.closingOutStandingMonth_1,
    };
  });
  const DropdownMenu =
    location.pathname === "/installation/createNewScheme" ? subtype : items;
  const handleSchemeDropdown = async (value) => {
    onSelectSchemeType(value);
    // getLockedScheme();
    // if(!isScheme1Lock && value === 1 ){
    //   // message.warning("Scheme selected is locked!!")
    // }
    // if(isScheme2Lock && value === 2 ){
    //   message.warning("Scheme selected is locked!!")
    // }
    // if(isScheme3Lock && value === 3){
    //   message.warning("Scheme selected is locked!!")
    // }
    // if(isScheme4Lock && value === 4){
    //   message.warning("Scheme selected is locked!!")
    // }
    // if(isScheme5Lock && value === 5 ){
    //   message.warning("Scheme selected is locked!!")
    // }
    // if(isScheme6Lock && value === 6 ){
    //   message.warning("Scheme selected is locked!!")
    // }

    // const postData = {
    //   userid: userId,
    //   schemeTypeId:value
    // };
    // try {

    //   await axiosAPI.post(`Dashboard/SchemeListLock?userid=${userId}&schemeTypeId=${value}`).then((result) => {

    //     if(result.data.data === 1 ){
    //       setLock(true)
    //       message.warning("Scheme selected is locked!!")
    //     } else{
    //       setLock(false)
    //     }
    //     // dispatch(schemeList(result.data.data));
    //   });
    // } catch (error) {

    // }
  };

  const handleDisable = () => {
    if (collectionData.dealerCode.length === 0) {
      const disableDealer = listData?.map((item) => item.isExist);
      if (disableDealer.includes(1)) {
        message.warning("Scheme for  disabled dealer already exist");
      }
      return;
    }
  };
  const handleIcon = () => {
    const disableDealer = listData?.map((item) => item.isExist);
    if (disableDealer.includes(1)) {
      setShowIcon(true);
    }
  };

  const getSchemeType = (schemeType) => {
    if (schemeType == 1) {
      return "With Delivery Plan";
    }
    if (schemeType == 2) {
      return "Without Delivery Plan";
    }
    if (schemeType == 3) {
      return "Slab Based";
    }
    if (schemeType == 8) {
      return "Single Target";
    }
    if (schemeType == 4) {
      return "Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Unconditional Installation";
    }
  };
  //Show Remaining Months of Financial Year 
  const disabledDate = (current,selected) => {

  //   if(!selected){
  //     const today = new Date();
  //     const currentYear = today.getFullYear();
  //     const currentMonth = today.getMonth();

  //     current = current instanceof Date ? current : new Date(current);

  //     // Disable if the month is not the current month of the current year
  //     if (current.getFullYear() !== currentYear || current.getMonth() !== currentMonth) {
  //         return true;
  //     }
      
  //     return false;
  // }else{
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const financialYearStartMonth = 3; // April (0-based index)
    const financialYearEndMonth = 2;   // March (0-based index)
    const financialYearStart = new Date(currentYear, financialYearStartMonth, 1);
    const financialYearEnd = new Date(currentYear + 1, financialYearEndMonth + 1, 0);

    current = current instanceof Date ? current : new Date(current);
    
    // Disable months before the financial year start
    if (currentYear === financialYearStart.getFullYear() && currentMonth < financialYearStartMonth) {
        return true;
    }
    
    // Disable months after the financial year end
    if (currentYear === financialYearEnd.getFullYear() && currentMonth > financialYearEndMonth) {
        return true;
    }
    
    // Disable months outside of the financial year
    if (current < financialYearStart || current > financialYearEnd) {
        return true;
    }
    
    // Disable months that have already passed from the current month
    if (current < today) {
        return true;
    }
    
    return false;
  // }
};

const disableStartMonth = (current) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  current = current instanceof Date ? current : new Date(current);

  // Disable if the month is not the current month of the current year
  if (current.getFullYear() !== currentYear || current.getMonth() !== currentMonth) {
      return true;
  }
  
  return false;
};


  //Show Current and Previous Month
  // const disabledDate = (current) => {
  //   const today = new Date();
  //   const currentYear = today.getFullYear();
  //   const currentMonth = today.getMonth();
  //   const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1);
  //   const lastDayOfCurrentMonth = new Date(currentYear, currentMonth + 1, 0);
  //   const lastDayOfPreviousMonth = new Date(currentYear, currentMonth, 0);

  //   const currentDate = current instanceof Date ? current : new Date(current);
  //   const currentYearOfDate = currentDate.getFullYear();
  //   const currentMonthOfDate = currentDate.getMonth();
  //   if (currentYearOfDate < currentYear || (currentYearOfDate === currentYear && currentMonthOfDate < currentMonth - 1 )) {
  //       return true;
  //   }

  //   return current < lastDayOfPreviousMonth || current > lastDayOfCurrentMonth;
  // };
  //Show Current Month amd last two Previous Months
  // const disabledDate = (current) => {
  //   const today = new Date();
  //   const currentYear = today.getFullYear();
  //   const currentMonth = today.getMonth();
  //   const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1);
  //   const lastDayOfCurrentMonth = new Date(currentYear, currentMonth + 1, 0);
  //   const lastDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 0);
  //   const lastDayOfTwoMonthsAgo = new Date(currentYear, currentMonth - 2, 0);

  //   const currentDate = current instanceof Date ? current : new Date(current);
  //   const currentYearOfDate = currentDate.getFullYear();
  //   const currentMonthOfDate = currentDate.getMonth();

  //   if (currentYearOfDate < currentYear ||
  //     (currentYearOfDate === currentYear && currentMonthOfDate < currentMonth - 2)) {
  //     return true;
  //   }

  //   return current < lastDayOfTwoMonthsAgo || current > lastDayOfCurrentMonth;
  // };



  const handlePanelChange = (value) => {
    setSelectedMonth(value);
  };

  /**
   * Handle Area Manager Dropdown click
   *
   * @param {Object} event
   */
  const handleAMClick = (event) => {
    const selectedAm = areaDropdown.find(
      (value) => value.id === parseInt(event.key)
    );
    setSelectedAM(selectedAm.aM);
    onGetDealerDropdownData(parseInt(event?.key), "amID");
  };
  const handleRemoveIcon = () => {
    setShowIcon(false);
  };

  const previousYear = (current) => {
    const today = dayjs().year()-1;
    const currentYear = today;
    const startFinancialYear = new Date(currentYear, 3, 1);
    const endFinancialYear = new Date(currentYear + 1, 2, 31)
    const currentDate = current instanceof Date ? current : new Date(current);
    return currentDate < startFinancialYear
  };
  const allow =
    location.pathname != "/dashboard" && ["ZADM", "SADM"].includes(userRole);
  return (
    <>
      <div className="Button">
        <Row className="button_inner_sec">
          {/* <Col>
            <Button
              className="upload_btn"
              type="default"
              shape="circle"
              icon={<UploadOutlined />}
            />
          </Col> */}
          {/* <Col>
            <Button
              style={{ background: "white", width: "80px" }}
              disabled>{currentYear}</Button>
          </Col> */}

          {/* This might be unnecessary: shashi */}
          {/* <Col>
            {location.pathname === "/installation/createNewScheme" ? (
              <Button
                style={{ background: "white" }}
                disabled>
                <Space>installation </Space>
              </Button>
            ) : (
              <Button
                style={{ background: "white" }}
                disabled>
                <Space> collection </Space>{" "}
              </Button>
            )}
          </Col> */}

          <Col>
            <div className="date">
   
              {showStartMonth && (
                         <Tooltip title={"Start Month"}> 
                <DatePicker
                  mode="month"
                  onChange={(date, dateString) => handleStartChange(date)}
                  className="start"
                  placeholder="Start month"
                  picker="month"
                  disabledDate={(current) =>
                    pageType=="Finance"?previousYear(current):
                    disableStartYear ? disableStartYear :
                      notDisableStart
                        ? null
                        : disableStartMonthShow?disableStartMonth(current, selectedMonth):disabledDate(current, selectedMonth)
                  }
                  onPanelChange={handlePanelChange}
                // value ={startMonth}
                />
                </Tooltip>
              )}
    
              {showEndMonth && (
             <Tooltip title={"Start Month         End Month"}> 
                <RangePicker
                  mode={["month", "month"]}
                  picker="month"
                  onChange={(date, dateString) =>
                    handleDateRange(date, dateString)
                  }
                  defaultValue={[dayjs(dayjs(), 'YYYY-MM-DD'), dayjs( dayjs(), 'YYYY-MM-DD')]}
                  disabledDate={(current) =>
                    disabledDate(current, selectedMonth)
                  }
                  value={[
                    dayjs(collectionData?.startDate|| dayjs(), "YYYY-MM-DD"),
                    dayjs(collectionData?.endDate|| dayjs(), "YYYY-MM-DD"),
                  ]}
                  disabled={[true, false]}
                  // disabled={[!showStartMonth, !showEndMonth]}
                  onPanelChange={handlePanelChange}
                />
                </Tooltip>
              )}
                 {showStartDateEndDateMonth && (
             <Tooltip title={"Start Month         End Month"}> 

             
                <RangePicker
                  mode={["month", "month"]}
                  picker="month"
                  onChange={(date, dateString) =>
                    handleDateRange(date, dateString)
                  }
                  defaultValue={[dayjs(dayjs(), 'YYYY-MM-DD'), dayjs( dayjs(), 'YYYY-MM-DD')]}
                  disabledDate={(current) =>
                    disabledDate(current, selectedMonth)
                  }
                  value={[
                    dayjs(collectionData?.startDate|| dayjs(), "YYYY-MM-DD"),
                    dayjs(collectionData?.endDate|| dayjs(), "YYYY-MM-DD"),
                  ]}
                  // disabled={[!showStartMonth, !showEndMonth]}
                  onPanelChange={handlePanelChange}
                />
                </Tooltip>
              )}
            </div>
          </Col>

          {location.pathname != "/dashboard" && userRole == "ZADM" && (
            <Col>
            <Tooltip title={"Select State Head"}> 
              <Dropdown menu={{ items: stateHeadData }}>
                <Button>
                  <Space>
                    {selectedSH == "" ? "Select State Head" : selectedSH}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              </Tooltip>
            </Col>
          )}

          {allow && (
            <Col>
             <Tooltip title={"Select Area Manager"}> 
              <Dropdown menu={{ items: areaMngrData, onClick: handleAMClick }}>
                <Button>
                  <Space>
                    {selectedAM == "" ? "Select Area Manager" : selectedAM}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              </Tooltip>
            </Col>
          )}

          {showSchemeType && (
            <Col>
             <Tooltip title={"Scheme Sub Type"}> 
              {location.pathname === "/installation/createNewScheme" ? (
                <Dropdown menu={{ items: newSubType }}>
                  <Button>
                    <Space>
                      {schemeType == ""
                        ? "Scheme Sub Type"
                        : getSchemeType(schemeType)}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              ) : (
                <Dropdown menu={{ items: newSubColType }}>
                  <Button>
                    <Space>
                      {schemeType == ""
                        ? "Scheme Sub Type"
                        : getSchemeType(schemeType)}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              )}
              </Tooltip>
            </Col>
          )}

          {showDealer && (
            <Col>
            <Tooltip title={"Select Dealers"}> 
              <Space
                style={{
                  width: "90%",
                }}
                direction="vertical"
              >
                <Select
                  className="select_dealer"
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Dealers"
                  maxTagCount="responsive"
                  defaultValue={dealerCode}
                  // value={getDealerCode(dealerCode)}
                  value={dealerCode}
                  onChange={(val, item) => {
                    onSelectDealer(val, item, dealerType);
                  }}
                  // onFocus={handleDisable}
                  onClick={() => handleIcon()}
                  // onBlur={()=>handleRemoveIcon()}
                  options={[
                    { label: "All Dealers", value: "allDealer" },
                    ...dealerType,
                  ]}
                  disabled={lock}
                />
              </Space>
              </Tooltip>
            </Col>
          )}
          {showIcon && (
            <Tooltip
              title="Dealers with existing scheme are disabled"
              placement="top"
              className="infoIcon"
            >
              <InfoCircleOutlined
                className="infoCircleIcon"
                style={{
                  width: "30px",
                  height: "32px",
                  fontSize: "28px",
                  fill: "#FF0000",
                }}
              />
            </Tooltip>
          )}
        </Row>
      </div>
    </>
  );
};
export default ThemeButton;
