import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getDealerListByStateIdApi,getMergeDealerListApi, getUnmergeDealerListByStateIdApi,getUnmergeDealerListApi,getAMListByStateIdApi} from "./mergeUnmergeService";

export const getDealerListByStateIdAsync = createAsyncThunk(
    "getDelearList",
    async (data, thunkAPI) => {
      try {
        const response = await getDealerListByStateIdApi(data);
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.string();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const getAMListByStateIdAsync = createAsyncThunk(
    "getAmList",
    async (data, thunkAPI) => {
      try {
        const response = await getAMListByStateIdApi(data);
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.string();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  
  export const getDealerMergeAsync = createAsyncThunk(
    "getDealerMergeList",
    async (data, thunkAPI) => {
      try {
        const response = await getMergeDealerListApi(data);
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.string();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getUmMergeDealerListByStateIdAsync = createAsyncThunk(
    "getUnMergeDelearList",
    async (data, thunkAPI) => {
      try {
        const response = await getUnmergeDealerListByStateIdApi(data);
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.string();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const getDealerUnMergeAsync = createAsyncThunk(
    "getDealerUnMergeList",
    async (data, thunkAPI) => {
      try {
        const response = await getUnmergeDealerListApi(data);
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.string();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  const mergeUnmergeSlice = createSlice({
    name: "mergeUnmergeSlice",
    initialState: {
      dealerList: {},
      mergeData: {},
      unMergeData: {},
      dealerUnMergeList: {},
      amList:{},
      message:null
    },
    reducers: {
      resetDealer: (state, action) => {
        state.dealerList = {}
      },
      resetUnMergeData: (state, action) => {
        state.unMergeData = {}
      },
      resetModifiedData:(state, action) => {
        state.mergeData = {}
      },
      resetDealerUnMergeList:(state,action)=>{
        state.editDashboard= {}
      }
    },
    extraReducers: (builder) => {
      // Dealer dealerList
      builder.addCase(getDealerListByStateIdAsync.pending, (state) => {
        state.loadingState1 = "pending";
      });
      builder.addCase(getDealerListByStateIdAsync.fulfilled, (state, { payload }) => {
        state.dealerList = payload;
        state.loadingState1 = "succeeded";
        state.error = true;
      });
      builder.addCase(getDealerListByStateIdAsync.rejected, (state) => {
        state.stateWiseData = {};
        state.loadingState1 = "succeeded";
        state.error = true;
      });
  
      //  Merge Data
      builder.addCase(getDealerMergeAsync.pending, (state) => {
        state.loadingState1 = "pending";
      });
      builder.addCase(getDealerMergeAsync.fulfilled, (state, { payload }) => {
        state.mergeData = payload;
        state.loadingState1 = "succeeded";
        state.error = true;
      });
      builder.addCase(getDealerMergeAsync.rejected, (state) => {
        state.stateWiseData = {};
        state.loadingState1 = "succeeded";
        state.error = true;
      });
  
  //  UnMerge Data
      builder.addCase(getUmMergeDealerListByStateIdAsync.pending, (state) => {
        state.loadingState = "pending";
      });
      builder.addCase(getUmMergeDealerListByStateIdAsync.fulfilled, (state, { payload }) => {
        state.unMergeData = payload;
        state.loadingState = "succeeded";
        state.error = true;
      });
      builder.addCase(getUmMergeDealerListByStateIdAsync.rejected, (state) => {
        state.stateWiseData = {};
        state.loadingState = "succeeded";
        state.error = true;
      });
  
      // Accept Modified  
      builder.addCase(getDealerUnMergeAsync.pending, (state) => {
        state.loadingState = "pending";
      });
      builder.addCase(getDealerUnMergeAsync.fulfilled, (state, { payload }) => {
        state.dealerUnMergeList = payload;
        state.loadingState = "succeeded";
        state.error = true;
      });
      builder.addCase(getDealerUnMergeAsync.rejected, (state) => {
        state.stateWiseData = {};
        state.loadingState = "succeeded";
        state.error = true;
      });


      builder.addCase(getAMListByStateIdAsync.pending, (state) => {
        state.loadingState = "pending";
      });
      builder.addCase(getAMListByStateIdAsync.fulfilled, (state, { payload }) => {
        state.amList = payload;
        state.loadingState = "succeeded";
        state.error = true;
      });
      builder.addCase(getAMListByStateIdAsync.rejected, (state) => {
        state.stateWiseData = {};
        state.loadingState = "succeeded";
        state.error = true;
      });
    
    },
  });
  export const { resetDealer,resetUnMergeData,resetModifiedData,resetDealerUnMergeList } = mergeUnmergeSlice.actions;

export default mergeUnmergeSlice.reducer;