import { Button, Card, Col, Row, Slider } from "antd";
import { useMemo, useState } from "react";
import "./awaiting.css";
import { useSelector } from "react-redux";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
const HeaderBox = ({ title, statusFilter, setStatusFilter,  selectedLabel }) => {
  const { schemeAwaitingCount } = useSelector((state) => state.schemagenerate);
  const { schemeHoldCount } = useSelector((state) => state.schemagenerate);
  const { awaitingLockSchemes } = useSelector((state) => state.stateData);
  const { holdSchemeAwaiting } = useSelector(
    (state) => state.schemagenerate
  );
  const { userRole } = decryptData(sessionStorage.getItem("userInfo"));
  let dashboardDetails = useMemo(
    () => [
      userRole === "SADM" &&
      awaitingLockSchemes &&
      awaitingLockSchemes?.[0]?.scheme1 && {
        name: "with delivery plan",
        type: "1",
        schemetype: "1",
        bgColor: "#FEF5F8",
        CardColor: "#E70033",
        value:  selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType1: schemeAwaitingCount?.data?.schemeType1 || 0,
      },
      userRole === "SADM" &&
      awaitingLockSchemes &&
      awaitingLockSchemes?.[0]?.scheme2 && {
        name: "without delivery plan",
        type: "2",
        schemetype: "1",
        bgColor: "#F4FCF9",
        CardColor: "#45CB85",
        value: selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType2: schemeAwaitingCount?.data?.schemeType2 || 0,
      },
      userRole === "SADM" &&
      awaitingLockSchemes &&
      awaitingLockSchemes?.[0]?.scheme6 && {
        name: "single target",
        type: "8",
        schemetype: "2",
        bgColor: "#F2F9FF",
        CardColor: "#00B0F0",
        value: selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType8 : schemeAwaitingCount?.data?.schemeType8 || 0,
      },
      userRole === "SADM" &&
      awaitingLockSchemes &&
      awaitingLockSchemes?.[0]?.scheme4 && {
        name: "single target with upper cap",
        type: "4",
        schemetype: "2",
        bgColor: "#F6F5FB",
        CardColor: "#4B38B3",
        value:  selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType4 : schemeAwaitingCount?.data?.schemeType4 || 0,
      },
      userRole === "SADM" &&
      awaitingLockSchemes &&
      awaitingLockSchemes?.[0]?.scheme3 && {
        name: "slab based",
        type: "3",
        schemetype: "2",
        bgColor: "#FFFBF3",
        CardColor: "#FFBE0B",
        value: selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType3 : schemeAwaitingCount?.data?.schemeType3 || 0,
      },
      userRole === "SADM" &&
      awaitingLockSchemes &&
      awaitingLockSchemes?.[0]?.scheme5 && {
        name: "Unconditional Installation",
        type: "5",
        schemetype: "2",
        bgColor: "#E8F6F3",
        CardColor: "#58D68D ",
        value: selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType5 : schemeAwaitingCount?.data?.schemeType5 || 0,
      },
    ],
    [awaitingLockSchemes, schemeAwaitingCount?.data, selectedLabel]
  );
  let dashboardDetails1 = useMemo(
    () => [
      {
        name: "with delivery plan",
        type: "1",
        schemetype: "1",
        bgColor: "#FEF5F8",
        CardColor: "#E70033",
        value:  selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType1 : schemeAwaitingCount?.data?.schemeType1 || 0
      },
      {
        name: "without delivery plan",
        type: "2",
        schemetype: "1",
        bgColor: "#F4FCF9",
        CardColor: "#45CB85",
        value:  selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType2 : schemeAwaitingCount?.data?.schemeType2 || 0,
      },
      {
        name: "single target",
        type: "8",
        schemetype: "2",
        bgColor: "#F2F9FF",
        CardColor: "#00B0F0",
        value:  selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType8 : schemeAwaitingCount?.data?.schemeType8 || 0,
      },
      {
        name: "single target with upper cap",
        type: "4",
        schemetype: "2",
        bgColor: "#F6F5FB",
        CardColor: "#4B38B3",
        value:  selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType4 : schemeAwaitingCount?.data?.schemeType4 || 0,
      },
      {
        name: "slab based",
        type: "3",
        schemetype: "2",
        bgColor: "#FFFBF3",
        CardColor: "#FFBE0B",
        value:  selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType3 : schemeAwaitingCount?.data?.schemeType3 || 0,
      },
      {
        name: "Unconditional Installation",
        type: "5",
        schemetype: "2",
        bgColor: "#FFFBF3",
        CardColor: "#FFBE0B",
        value: selectedLabel == "Hold Schemes" ? schemeHoldCount?.data?.schemeType5 : schemeAwaitingCount?.data?.schemeType5 || 0,
      },
    ],
    [schemeAwaitingCount?.data, selectedLabel]
  );
  let arrDashboard = (userRole == "SADM"
    ? dashboardDetails
    : dashboardDetails1) || [
      {
        name: "-",
        type: "0",
        schemetype: "1",
        bgColor: "#FEF5F8",
        CardColor: "#E70033",
        value: 0,
      },
    ];


  return (
    <>
      <div className="approval_heading1 w-100">{title && title}</div>
      <div className="w-100 d-flex flex-wrap ">
        <ul className="cards-list ">
          {arrDashboard
            ?.filter((x) => x)
            ?.map((x, index) => (
              <li
                key={index}
                className="card"
                style={{ backgroundColor: x?.bgColor }}
                ref={(element) => {
                  if (element) {
                    element.style.setProperty("border-color", x?.CardColor, "important");
                    if (statusFilter === x?.type) {
                      element.style.setProperty("background", x?.bgColor, "important");
                    } else {
                      element.style.setProperty("background", x?.bgColor);
                    }
                  }
                }}

                onClick={() => setStatusFilter(x?.type)}
              >
                <div className="p-2 card-content">
                  <div>
                    <div
                      className="fs-2"
                      style={{
                        color: x?.CardColor,
                      }}
                    >
                      <label>{x?.value}</label>
                    </div>
                    <div className="pt-1 c-black1 fs-10">
                      {x?.schemetype == 1 ? "Collection" : "Installation"}
                      <br />
                    </div>
                  </div>

                  <div
                    className="text-header-bottom"
                    ref={(element) =>
                      element &&
                      element.style.setProperty("color", x?.color, "important")
                    }
                  >
                    {x?.name}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default HeaderBox;
