import axios from "axios";
import ToastifyShow from "../../components/ToastifyShow";
import axiosAPI from "../../services/axiosApi"
import { decryptData } from "../../utils/localStorageEncodeDecode";
export const getStateList = async (data) => {
    try {

      const response=axiosAPI.post(`SchemeGenerate/GetState`)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const updateSchemeListApi = async (data) => {
    try {

      const response=axiosAPI.post(`Dashboard/SchemeStateRequestList`,data)
        .then((data) => {
          ToastifyShow("Schemes updated successfully","success")
          return data?.data;
        })
        .catch((error) => {
          ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
  export const getStateWiseData = async (data) => {
    try {

      const response=axiosAPI.post(`Dashboard/StateWiseReports`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
  export const dealerTrackCounter = async (data) => {
    try {

      const response=axios.get(`https://swarajcdms.com/MobileAPI/API/CDMS/BillingData/DealerTractorCountMonthly`,data, {
        headers: { 'Content-Type': 'application/xml' }
      })
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const GetStatesbyZonalHeadId = async (data) => {
    try {
      const response=axiosAPI.post(`SchemeGenerate/GetStatesbyZonalHeadId`, data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const GetFinancialInputApi = async (data) => {
    try {
      const response=axiosAPI.post(`FinanceDashboard/GetStatesByMonthForFinanceInput`, data)
        .then((data) => {
          
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const GetYTDValueForFinanceInputApi = async (data) => {
    try {

      const response=axiosAPI.post(`FinanceDashboard/GetYTDValueForFinanceInput`, data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const getLockedSchemeTypeApi = async (amId) => {
    try {
      const getUrl = () => amId != '' ? `dashboard/DecodeTokenNEW?Nameid=`+amId : `dashboard/DecodeTokenNEW`  
      const {Token} = sessionStorage.getItem('userInfo') ? decryptData(sessionStorage.getItem('userInfo')) : {};
      const response = await axiosAPI.get(getUrl())
        .then((data) => {
         
          return data?.data;
          // if(data.data.scheme1 === true && value === 1 ){
          //         setLock(true)
          //         message.warning("Scheme selected is locked!!")
          // }
        })
        .catch((error) => {
          ToastifyShow("Something went wrong", "error")
          return error;
        });
      return response;
  
    } catch (err) {
      throw err;
    }
  };


  export const getLockedZonalHeadApi= async (data) => {
    try {

      const response = await axiosAPI.post("SchemeGenerate/GetStatesTopSlabbyZonalHeadId",data)
        .then((data) => {
         
          return data?.data;
          // if(data.data.scheme1 === true && value === 1 ){
          //         setLock(true)
          //         message.warning("Scheme selected is locked!!")
          // }
        })
        .catch((error) => {
          ToastifyShow("Something went wrong", "error")
          return error;
        });
      return response;
  
    } catch (err) {
      throw err;
    }
  };
// curl --location 'https://localhost:44391/api/Dashboard/GetTopTargetByStateId?EncryptedText=Y51fOosehNDe19GD%2FWdhwA%3D%3D' \
// --header 'Accept: application/json, text/plain, */*' \
// --header 'Accept-Language: en-GB,en-US;q=0.9,en;q=0.8' \
// --header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IlJBSkVFViBTQVJBU1dBVCIsInJvbGUiOiJTQURNIiwibmFtZWlkIjoiNTEiLCJuYmYiOjE3MTAxMzMxMDYsImV4cCI6MTcxMDEzMzQwNiwiaWF0IjoxNzEwMTMzMTA2fQ.ctH1_nXJKR4Zoaswp__CE9G3q5pZljKmPfVCzCuUyoA' \
// --header 'Connection: keep-alive' \
// --header 'Content-Type: application/json' \
// --header 'Origin: http://localhost:3000' \
// --header 'Referer: http://localhost:3000/' \
// --header 'Sec-Fetch-Dest: empty' \
// --header 'Sec-Fetch-Mode: cors' \
// --header 'Sec-Fetch-Site: cross-site' \
// --header 'User-Agent: Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36' \
// --header 'sec-ch-ua: "Google Chrome";v="117", "Not;A=Brand";v="8", "Chromium";v="117"' \
// --header 'sec-ch-ua-mobile: ?0' \
// --header 'sec-ch-ua-platform: "Linux"' \
// --data 'JUxEi5OHMZQWha+ETLskzg=='

  ////Dashborad/GetTopTargetByStateId?stateid=

  export const getLockedSchemeStateApi = async (data) => {
 
    try {
      const response = await axiosAPI.post(`Dashboard/GetTopTargetByStateId?EncryptedText=${data?.data?.replaceAll('+', '-')?.replaceAll('_','/')}`,{
      })
        .then((data) => {
        
          return data?.data;
        })
        .catch((error) => {
          ToastifyShow("Something went wrong", "error")
          return error;
        });
      return response;
  
    } catch (err) {
      throw err;
    }
  };


  export const getAwaitingLockScheme = async (amId) => {
    try {
      const getUrl = () => `dashboard/DecodeTokenNEW`  
      const response = await axiosAPI.get(getUrl())
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
          ToastifyShow("Something went wrong", "error")
          return error;
        });
      return response;
  
    } catch (err) {
      throw err;
    }
  };

  export const GetDropdownStateHead = async (data) => {
    try {
      const response=axiosAPI.post(`SchemeGenerate/GetDropdownStateHead`, data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const GetDropdownAreaManager = async (data) => {
    try {
      const response=axiosAPI.post(`SchemeGenerate/GetDropdownAM`, data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const GetDropdownZonalHead = async (data) => {
    try {
      const response=axiosAPI.post(`SchemeGenerate/GetzonalHead`, data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };