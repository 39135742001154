import React, { Table, Tag, Space, message, Button, Tooltip, Pagination } from "antd";
import { useState, useEffect } from "react";

import ActionButton from "../../../components/actionButton/actionButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { approvalScheme } from "../../../redux/collection/ApprovalScheme";
import dayjs from "dayjs";
import SMDialog from "../../../components/modal/alertModal/CommonModal";
import StatusModal from "../../../components/modal/alertModal/StatusModal";
import axiosAPI from "../../../services/axiosApi";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const currentFiscalYear = getCurrentFiscalYear();
const InitCollectionData = {
  currentYear: currentFiscalYear,
  // collectionType: 'Collection',
  pageSize: "150",
  pageNumber: 1,
};
const ApprovalTable = ({ payload, amgrId, stateHeadId, zonalHeadId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1)
  const [listItem, setlistItem] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedSchemeType, setSelectedSchemeType] = useState(1);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [schemeIdNo, setSchemeIdNo] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const [status, setStatus] = useState();
  let columns = [];

  const handleSingleEdit = (record) => {
    const selectedRow = JSON.parse(sessionStorage.getItem("rowData"));
    const recordExist =
      selectedRow && selectedRow.find((item) => item.key === record.key);

    if (recordExist) {
      selectedSchemeType == 1 || selectedSchemeType == 2
        ? navigate("/schemeProgress/edit")
        : navigate("/schemeProgress/edit");
    } else {
      messageApi.open({
        type: "warning",
        content: "Please select the checkbox !",
        duration: 2,
      });
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setCollectionData({ ...collectionData, "sortingOrder": sorter?.order, "sortingType": sorter?.columnKey })
  }

  const commonColumns = [
    {
      title: (
        <div>
          Scheme <br /> No
        </div>
      ),
      dataIndex: "scheme",
      width: "160px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "SchemeNo",
      // sorter: (a, b) => a.name && b.name && a.name.length - b.name.length,
      fixed: "left",
      render: (item) => {
        let color = " ";
        switch (item.status) {
          case 1:
            color = "#ffff00";
            break;
          case 2:
            color = "#51DE4E";
            break;
          case 5:
            color = "#FF3232";
            break;
          default:
            color = "#ffff00";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "code",
      width: "160px",
      sorter: true,
      fixed: "left",
      key: "DealerCode",
      defaultSortOrder: "ascend",
    },
    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),
      fixed: "left",
      dataIndex: "Location",
      sorter: true,
      width: "190px",
      key: "DealerLocation",
      defaultSortOrder: "ascend",
      // onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "name",
      sorter: true,
      width: "160px",
      key: "DealerName",
      defaultSortOrder: "ascend",
      // fixed: "left",
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: true,
      width: "130px",
      key: "StateName",
      defaultSortOrder: "ascend",
      // fixed: "left",
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "Manager",
      sorter: true,
      defaultSortOrder: "ascend",
      width: "190px",
      key: "TerritoryManager",
    },
    {
      title: "TOP SLAB",
      width: "150px",
      render: (x, y, index) => y?.topTarget?.toUpperCase(),
    },
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "StartMonth",
      // sorter: true,
      width: "80px",
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "EndMonth",
      // sorter: true,
      width: "80px",
    },
  ];
  if (selectedSchemeType == 1 || selectedSchemeType == 2) {
    columns = [
      {
        title: "Industry",
        dataIndex: "industry",
        width: "90px",
      },
      {
        title: "B",
        dataIndex: "oldBillingQty",
        width: "50px",
      },
      {
        title: "I",
        dataIndex: "oldInstallation",
        width: "50px",
      },
      {
        title: "C",
        dataIndex: "oldCollection",
        width: "50px",
      },

      // {
      //   title: "Slab Achived Last Year",
      //   dataIndex: "target",
      // },
      {
        title: (
          <div>
            Opening <br /> Stock
          </div>
        ),
        dataIndex: "stock",
        children: [
          {
            title: "NOS",
            width: "80px",
            dataIndex: "oldOpeningStock",
          },
          {
            title: "Days",
            dataIndex: "openingStockDays",
            width: "100px",
          },
        ],
      },
      {
        title: "Closing Os. (In Lacs)",
        dataIndex: "stock",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.name.length - b.name.length,
        children: [
          {
  title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
            dataIndex: "lastThirdMonth",
            defaultSortOrder: "descend",
            width: "80px",

            sorter: (a, b) => a.name.length - b.name.length,
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
              </span>
            ),
            dataIndex: "lastTwoMonth",
            defaultSortOrder: "descend",
            width: "80px",

            sorter: (a, b) => a.name.length - b.name.length,
          },
          {
            title: (
              <span>
                Last Month
              </span>
            ),
            dataIndex: "lastOneMonth",
            defaultSortOrder: "descend",
            width: "80px",

            sorter: (a, b) => a.name.length - b.name.length,
          },
          // {
          //   title: "Lacs",
          //   dataIndex: "lac",
          //   defaultSortOrder: "descend",
          //   sorter: (a, b) => a.name.length - b.name.length,
          // },
          // {
          //   title: (
          //     <span>
          //       Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
          //     </span>
          //   ),
          //   dataIndex: "outStandingDays",
          //   defaultSortOrder: "descend",
          //   width: "100px",

          //   sorter: (a, b) => a.name.length - b.name.length,
          // },
        ],
      },
      {
        title: "Closing OS Days",
        dataIndex: "outStandingDays",
        children: [
          {
            title: "OS Last Month",
            dataIndex: "outStandingDays",
            defaultSortOrder: "descend",
            width: "100px",
            sorter: (a, b) => a.name.length - b.name.length,
          },
        ],
      },
      {
        title: "BG",
        dataIndex: "bg",
        children: [
          {
            title: "Lacs",
            width: "120px",

            dataIndex: "bgLacsCurrentYear",
          },
        ],
      },
      {
        title: (
          <div>
            Installation <br /> Plan
          </div>
        ),
        dataIndex: "IP",
        children: [
          {
            title: "NOS",
            dataIndex: "delayPlan",
            width: "200px",
          },
        ],
      },
      {
        title: (
          <div>
            Billing <br /> Details
          </div>
        ),
        dataIndex: "plan",
        children: [
          {
            title: "B",
            dataIndex: "b",
            width: "100px",
          },
          {
            title: "I",
            dataIndex: "i",
            width: "100px",
          },
          {
            title: "C",
            dataIndex: "c",
            width: "100px",
          },
        ],
      },
      {
        title: (
          <div>
            Closing <br /> Stock
          </div>
        ),
        dataIndex: "closingStock",
        width: "80px",
      },
      {
        title: (
          <div>
            Closing <br /> OutStanding (In Lacs)
          </div>
        ),
        dataIndex: "closingOutStanding",
        width: "110px",
      },
      {
        title: "Books Collection Target (No. of Ts.)",
        dataIndex: "BooksCollectionTarget",
        children: [
          {
            title: "T11",
            dataIndex: "BooksCollectionTargetT1",
            width: "100px",
          },
          {
            title: "T2",
            dataIndex: "BooksCollectionTargetT2",
            width: "100px",
          },
          {
            title: "T2-T1",
            dataIndex: "BooksCollectionTargetT2T1",
            width: "100px",
          },
          {
            title: "T3",
            dataIndex: "BooksCollectionTargetT3",
            width: "100px",
          },
          {
            title: "T3-T2",
            dataIndex: "BooksCollectionTargetT3T2",
            width: "100px",
          },
          {
            title: "T4",
            dataIndex: "BooksCollectionTargetT4",
            width: "100px",
          },
          {
            title: "T4-T3",
            dataIndex: "BooksCollectionTargetT4T3",
            width: "100px",
          },
          {
            title: "T4",
            dataIndex: "BooksCollectionTargetT4",
            width: "100px",
          },
          {
            title: "T4-T3",
            dataIndex: "BooksCollectionTargetT4T3",
            width: "100px",
          },
          {
            title: "T5",
            dataIndex: "BooksCollectionTargetT5",
            width: "100px",
          },
          {
            title: "T5-T4",
            dataIndex: "BooksCollectionTargetT5T4",
            width: "100px",
          },
          {
            title: "T6",
            dataIndex: "BooksCollectionTargetT6",
            width: "100px",
          },
          {
            title: "T6-T5",
            dataIndex: "BooksCollectionTargetT6T5",
            width: "100px",
          },
        ],
      },
      {
        title: (
          <div>
            Payout <br /> Rate
          </div>
        ),
        dataIndex: "pr",

        children: [
          {
            title: "R1",
            dataIndex: "PayoutRatep1",
            width: "120px",
          },
          {
            title: "R2",
            dataIndex: "PayoutRatep2",
            width: "120px",
          },
          {
            title: "R3",
            dataIndex: "PayoutRatep3",
            width: "120px",
          },
          {
            title: "R4",
            dataIndex: "PayoutRatep4",
            width: "120px",
          },
          {
            title: "R5",
            dataIndex: "PayoutRatep5",
            width: "120px",
          },
          {
            title: "R6",
            dataIndex: "PayoutRatep6",
            width: "120px",
          },
        ],
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        width: "180px",
      },
      {
        title: "Payout",
        dataIndex: "payout",

        children: [
          {
            title: "P1",
            dataIndex: "payoutR1",
            width: "120px",
          },
          {
            title: "P2",
            dataIndex: "payoutR2",
            width: "120px",
          },
          {
            title: "P3",
            dataIndex: "payoutR3",
            width: "120px",
          },
          {
            title: "P4",
            dataIndex: "payoutR4",
            width: "120px",
          },
          {
            title: "P5",
            dataIndex: "payoutR5",
            width: "120px",
          },
          {
            title: "P6",
            dataIndex: "payoutR6",
            width: "120px",
          },
        ],
      },
      {
        title: "Total Expected Payout",
        dataIndex: "TEP",
        children: [
          {
            title: "Expected Volume",
            dataIndex: "TEPvol",
            width: "150px",
          },
          {
            title: "Per Tr.",
            dataIndex: "TEPpertr",
            width: "150px",
          },
          {
            title: "Amount",
            dataIndex: "TEPamount",
            width: "150px",
          },
        ],
      },
      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: "180px",

        render: (_, record) =>
          record.scheme.status == 1 ? (
            <Space size="small">
              <Tooltip title="Edit" placement="top" className="infoIcon">
                <EditOutlined
                  // type="primary"
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"} // Disable the button based on the condition
                  onClick={() => handleSingleEdit(record)}
                  style={{ fontSize: "20px" }}
                />
              </Tooltip>
              <Tooltip title="Accept" placement="top" className="infoIcon">
                <CheckOutlined
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
                  style={{ color: "#51DE4E", fontSize: "20px" }}
                  onClick={handleAcceptModal}
                />
              </Tooltip>
              <Tooltip title="Reject" placement="top" className="infoIcon">
                <CloseOutlined
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
                  style={{ color: "#FF3232", fontSize: "20px" }}
                  onClick={handleRejectModal}
                />
              </Tooltip>
            </Space>
          ) : null,
      },
    ];
  } else if (selectedSchemeType == 3) {
    columns = [
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        // sorter: true,
        width: "200px",
      },
      {
        title: "Targets",
        dataIndex: "Target",
        // width: '180px',
        children: [
          {
            title: "T1",
            dataIndex: "t1",
            width: "90px",
          },
          {
            title: "T2",
            dataIndex: "t2",
            width: "90px",
          },
          {
            title: "T3",
            dataIndex: "t3",
            width: "90px",
          },
        ],
      },
      {
        title: "inc. Per Tractor",
        dataIndex: "IPT",
        // width: '180px',
        children: [
          {
            title: "R1",
            dataIndex: "r1",
            width: "90px",
          },
          {
            title: "R2",
            dataIndex: "r2",
            width: "90px",
          },
          {
            title: "R3",
            dataIndex: "r3",
            width: "90px",
          },
        ],
      },
      {
        title: "Payout",
        dataIndex: "Payout",
        // width: '180px',
        children: [
          {
            title: "P1",
            dataIndex: "p1",
            width: "90px",
          },
          {
            title: "P2",
            dataIndex: "p2",
            width: "90px",
          },
          {
            title: "P3",
            dataIndex: "p3",
            width: "90px",
          },
        ],
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        width: "120px",
      },
      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: "100px",

        render: (_, record) =>
          record.scheme.status == 1 ? (
            <Space size="small">
              <Tooltip title="Edit" placement="top" className="infoIcon">
                <EditOutlined
                  // type="primary"
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"} // Disable the button based on the condition
                  onClick={() => handleSingleEdit(record)}
                  style={{ fontSize: "20px" }}
                />
              </Tooltip>
              <Tooltip title="Accept" placement="top" className="infoIcon">
                <CheckOutlined
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
                  style={{ color: "#51DE4E", fontSize: "20px" }}
                  onClick={handleAcceptModal}
                />
              </Tooltip>
              <Tooltip title="Reject" placement="top" className="infoIcon">
                <CloseOutlined
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
                  style={{ color: "#FF3232", fontSize: "20px" }}
                  onClick={handleRejectModal}
                />
              </Tooltip>
            </Space>
          ) : null,
      },
    ];
  } else if (selectedSchemeType == 4) {
    columns = [
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        //   sorter: true,
        width: "250px",
      },
      {
        title: "Installation Target",
        dataIndex: "Target",
        width: "190px",
      },
      {
        title: "Upper Cap",
        dataIndex: "UC",
        width: "120px",
      },
      {
        title: "inc. Per Tractor",
        dataIndex: "incPerTarget",
        width: "190px",
      },
      {
        title: "Payout",
        dataIndex: "p1",
        width: "120px",
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        width: "120px",
      },
      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: "100px",

        render: (_, record) =>
          record.scheme.status == 1 ? (
            <Space size="small">
              <Tooltip title="Edit" placement="top" className="infoIcon">
                <EditOutlined
                  // type="primary"
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"} // Disable the button based on the condition
                  onClick={() => handleSingleEdit(record)}
                  style={{ fontSize: "20px" }}
                />
              </Tooltip>
              <Tooltip title="Accept" placement="top" className="infoIcon">
                <CheckOutlined
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
                  style={{ color: "#51DE4E", fontSize: "20px" }}
                  onClick={handleAcceptModal}
                />
              </Tooltip>
              <Tooltip title="Reject" placement="top" className="infoIcon">
                <CloseOutlined
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
                  style={{ color: "#FF3232", fontSize: "20px" }}
                  onClick={handleRejectModal}
                />
              </Tooltip>
            </Space>
          ) : null,
      },
    ];
  } else if (selectedSchemeType == 8) {
    columns = [
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        //   sorter: true,
        width: "250px",
      },
      {
        title: "Installation Target",
        dataIndex: "Target",
        width: "190px",
      },

      {
        title: "inc. Per Tractor",
        dataIndex: "incPerTarget",
        width: "190px",
      },
      {
        title: "Payout",
        dataIndex: "p1",
        width: "120px",
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        width: "120px",
      },
      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: "100px",

        render: (_, record) =>
          record.scheme.status == 1 ? (
            <Space size="small">
              <Tooltip title="Edit" placement="top" className="infoIcon">
                <EditOutlined
                  // type="primary"
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"} // Disable the button based on the condition
                  onClick={() => handleSingleEdit(record)}
                  style={{ fontSize: "20px" }}
                />
              </Tooltip>
              <Tooltip title="Accept" placement="top" className="infoIcon">
                <CheckOutlined
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
                  style={{ color: "#51DE4E", fontSize: "20px" }}
                  onClick={handleAcceptModal}
                />
              </Tooltip>
              <Tooltip title="Reject" placement="top" className="infoIcon">
                <CloseOutlined
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
                  disabled={schemeIdNo && schemeIdNo.length > 1}
                  style={{ color: "#FF3232", fontSize: "20px" }}
                  onClick={handleRejectModal}
                />
              </Tooltip>
            </Space>
          ) : null,
      },
    ];
  } else if (selectedSchemeType == 5) {
    columns = [
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        //   sorter: true,
        width: "250px",
      },
      {
        title: "Installation Expected",
        dataIndex: "Target",
        width: "200px",
      },
      {
        title: "inc. Per Tractor",
        dataIndex: "incPerTarget",
        width: "190px",
      },
      {
        title: "Payout",
        dataIndex: "p1",
        width: "120px",
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        width: "120px",
      },
      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: "100px",

        render: (_, record) =>
          record.scheme.status == 1 ? (
            <Space size="small">
              <Tooltip title="Edit" placement="top" className="infoIcon">
                <EditOutlined
                  // type="primary"
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"} // Disable the button based on the condition
                  onClick={() => handleSingleEdit(record)}
                  style={{ fontSize: "20px" }}
                />
              </Tooltip>
              <Tooltip title="Accept" placement="top" className="infoIcon">
                <CheckOutlined
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
                  style={{ color: "#51DE4E", fontSize: "20px" }}
                  onClick={handleAcceptModal}
                />
              </Tooltip>
              <Tooltip title="Reject" placement="top" className="infoIcon">
                <CloseOutlined
                  className={schemeIdNo.length > 1 ? "disabled" : "enabled"}
                  style={{ color: "#FF3232", fontSize: "20px" }}
                  onClick={handleRejectModal}
                />
              </Tooltip>
            </Space>
          ) : null,
      },
    ];
  }
  const defaultColumns = [...commonColumns, ...columns];

  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userData.userRole;

  const handleSelectModal = () => {
    setOpenSelectModal(!openSelectModal);
  };
  const handleAcceptModal = () => {
    if (selectedRowKeys.length > 0) {
      setOpenAcceptModal(!openAcceptModal);
    } else {
      messageApi.open({
        type: "warning",
        content: "Please select the checkbox !",
        duration: 2,
      });
    }
  };

  const handleRejectModal = () => {
    if (selectedRowKeys.length > 0) {
      setOpenRejectModal(!openRejectModal);
    } else {
      messageApi.open({
        type: "warning",
        content: "Please select the checkbox !",
        duration: 2,
      });
    }
  };
  const handleStatus = (status) => {
    setIsLoading(true);
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const finalData = [];

    selectedRowKeys.forEach((item) => {
      for (let dataItem of dataSource) {
        if (dataItem.key === item) {
          const payload = {
            schemeId: dataItem.scheme.schemeId,
            status: status,
            remark: " ",
            username: userName,
            userid: userId,
          };
          finalData.push(payload);
        }
      }
    });
    const demoParams = {
      ...payload,
      fyear: currentFiscalYear,
      schemeType: selectedSchemeType,
      pageSize: collectionData?.pageSize || 150,
      pageNumber: collectionData?.pageNumber || 1,
      state: 0,
      userid:
        userRole == "ZADM"
          ? stateHeadId
          : userRole == "NSH" || userRole == "PMIS"
            ? zonalHeadId
            : amgrId,
      status: 1,
      sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
      sortingType: collectionData.sortingType || "DealerCode"
    };

    const fetchAwaitingApprovalSchemes = async () => {
      if (status === 2) {
        handleAcceptModal();
      } else if (status === 5) {
        handleRejectModal();
      }
      setIsLoading(false);
      let result = await axiosAPI
        .post(`UpdateScheme/ApprovalScheme`, finalData)
        .then((x) => {
          if (x?.data?.errorMessage == "Scheme Status updated successfully!!") {
            // Display the success message after the modal action
            const successCallback = () => {
              message.success({
                content: "Scheme Generated Successfully",
                className: "custom-message",
              });

              let fetchData = async () => {


                let result = await axiosAPI.post(
                  `SchemeGenerate/GetSchemeGenerate`,
                  demoParams
                );

                setlistItem(result.data.data);

                const data = await dispatch(
                  approvalScheme(
                    result.data.data.length > 0 &&
                    result.data.data.filter((item) => item.schemeNo != null)
                  )
                );

                if (result.data.data.length === 0) setDataSource([]);

                setSelectedSchemeType(demoParams.schemeType);

                // Move this line here
                dispatch(approvalScheme(result.data.data));
              };

              fetchData();
            };

            // Show the modal and provide the successCallback
            StatusModal(successCallback);
          } else {
            message.error({
              content: "Something Went Wrong",
              className: "custom-message",
            });
          }
        });
      dispatch(approvalScheme(result?.data?.data));

      getListing();
    };

    fetchAwaitingApprovalSchemes();
  };

  const fetchAwaitingApprovalScheme = async (demoParams) => {
    let result = await axiosAPI.post(
      `SchemeGenerate/GetSchemeGenerate`,
      demoParams
    );

    setlistItem(result.data.data);

    dispatch(
      approvalScheme(
        result.data.data && result.data.data.length > 0 &&
        result.data.data.filter((item) => item.schemeNo != null)
      )
    );

    if (result.data.data && result.data.data.length === 0) setDataSource([]);

    setSelectedSchemeType(demoParams.schemeType);
  };
  useEffect(() => {
    let getData = async () => {
      const demoParams = {
        ...payload,
        fyear: currentFiscalYear,
        schemeType: selectedSchemeType,
        pageSize: collectionData?.pageSize || 150,
        pageNumber: collectionData?.pageNumber || 1,
        state: 0,
        userid:
          userRole == "ZADM"
            ? stateHeadId
            : userRole == "NSH" || userRole == "PMIS"
              ? zonalHeadId
              : amgrId,
        status: 1,
        sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
        sortingType: collectionData.sortingType || "DealerCode"
      };
      let result = await axiosAPI.post(
        `SchemeGenerate/GetSchemeGenerate`,
        demoParams
      );

      setlistItem(result.data.data);

      dispatch(
        approvalScheme(
          result.data.data && result.data.data.length > 0 &&
          result.data.data.filter((item) => item.schemeNo != null)
        )
      );

      if (result.data.data && result.data.data.length === 0) setDataSource([]);

      setSelectedSchemeType(demoParams.schemeType);
    }
    getData()
  }, [collectionData])
  const getListing = () => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const demoParams = {
      ...payload,
      fyear: currentFiscalYear,
      schemeType: selectedSchemeType,
      state: 0,
      pageSize: collectionData?.pageSize || 150,
      pageNumber: collectionData?.pageNumber || 1,
      userid:
        userRole == "ZADM"
          ? stateHeadId
          : userRole == "NSH" || userRole == "PMIS"
            ? zonalHeadId
            : amgrId,
      status: 1,
      sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
      sortingType: collectionData.sortingType || "DealerCode"
    };
    fetchAwaitingApprovalScheme(demoParams);
  };

  useEffect(() => {
    getListing();
  }, [payload, amgrId, stateHeadId, zonalHeadId]);

  // Api Calling
  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    const data = [];
    listItem?.data?.filter((item) => item.schemeNo != null)
      .map((item, index) => {
        for (let i = 0; i < 1; i++) {
          let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
          let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
          data.push({
            // status: item.status,
            key: item.schemeNo,
            scheme: item,
            code: item.dealerCode,
            name: item.dealerName,
            Location: item.dealerLocation,
            state: item.stateName,
            Manager: item.territoryManager,
            TopTarget: item.topTarget,
            StartMonth: formattedStartDate,
            EndMonth: formattedEndDate,
            industry: item.industry,
            b: item.billing,
            i: item.installation,
            c: Math.round(item.collection),
            delayPlan: item.delayPlan,

            target: item.SlabAchievedLastYear,
            oldBillingQty: item.oldBillingQty,
            oldCollection: Math.round(item.oldCollection),
            oldInstallation: item.oldInstallation,
            bgLacsCurrentYear: item.bgLacsCurrentYear,
            lastThirdMonth: item.closingOutStandingMonth_3,
            lastTwoMonth: item.closingOutStandingMonth_2,
            lastOneMonth: item.closingOutStandingMonth_1,
            outStandingDays: item.outStandingDays,
            openingStockDays: item.openingStockDays,
            oldOpeningStock: item.oldOpeningStock,
            t1: item.t1,
            t2: item.t2,
            r1: item.r1,
            r2: item.r2,

            BooksCollectionTargetT1: item.t1,
            BooksCollectionTargetT2: item.t2,
            BooksCollectionTargetT2T1: item.t2_T1,
            BooksCollectionTargetT3: item.t3,
            BooksCollectionTargetT3T2: item.t3_T2,
            PayoutRatep1: item.r1,
            PayoutRatep2: item.r2,
            PayoutRatep3: item.r3,
            p1: item.p1,
            p2: item.p2,
            p3: item.p3,
            incPerTarget: item.incPerTarget,
            Target: item.installationTarget,
            UC: item.upperCap,
            payoutR2: item.p3,
            payoutR3: item.p1,
            payoutR1: item.p2,
            TEPvol: item.volume,
            TEPpertr: item.perTr,
            TEPamount: item.amount,
            // LMSA: item.lastOneMonthSlabAchieved,
            // LCQ: item.lastMonthColQty,
            // LMSA1: item.lastTwoMonthSlabAchieved,
            // LCQ1: item.lastTwoMonthColQty,
            reason: item.reason,
            closingStock: item.closingStock,
            closingOutStanding: item.closingOutStanding
          });
        }

        setDataSource(data);
      });
  }, [listItem]);
  const scroll =
    selectedSchemeType == 1 || selectedSchemeType == 2
      ? {
        x: 4200,
        y: 1000,
      }
      : {
        x: 2000,
        y: 300,
      };

  const renderFooter = () => {
    return (
      <div className="approval-count">
        <b>Scheme Progress Count : {dataSource.length}</b>
      </div>
    );
  };
  const evaluateTotalRow = (fieldSum) => {
    if (selectedSchemeType == 1 || selectedSchemeType == 2) {
      return (
        <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}></Table.Summary.Cell>
          <Table.Summary.Cell index={11}></Table.Summary.Cell>
          <Table.Summary.Cell index={12}></Table.Summary.Cell>
          <Table.Summary.Cell index={13}></Table.Summary.Cell>
          <Table.Summary.Cell index={14}></Table.Summary.Cell>
          <Table.Summary.Cell index={15}></Table.Summary.Cell>
          <Table.Summary.Cell index={16}></Table.Summary.Cell>
          <Table.Summary.Cell index={17}></Table.Summary.Cell>
          <Table.Summary.Cell index={18}></Table.Summary.Cell>
          <Table.Summary.Cell index={19}></Table.Summary.Cell>
          <Table.Summary.Cell index={20}></Table.Summary.Cell>
          <Table.Summary.Cell index={21}></Table.Summary.Cell>
          <Table.Summary.Cell index={22}></Table.Summary.Cell>
          <Table.Summary.Cell index={23}></Table.Summary.Cell>
          <Table.Summary.Cell index={24}>
            {fieldSum.t1 ? fieldSum.t1 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={25}>
            {fieldSum.t2 ? fieldSum.t2 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={26}>
            {fieldSum.T2_T1 ? fieldSum.T2_T1 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={27}>
            {fieldSum.t3 ? fieldSum.t3 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={28}>
            {fieldSum.T3_T2 ? fieldSum.T3_T2 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={29}>
            {fieldSum.t4 ? fieldSum.t4 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={30}>
            {fieldSum.T4_T3 ? fieldSum.T4_T3 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={31}>
            {fieldSum.t5 ? fieldSum.t5 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={32}>
            {fieldSum.T5_T4 ? fieldSum.T5_T4 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={33}>
            {fieldSum.t6 ? fieldSum.t6 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={34}>
            {fieldSum.T6_T5 ? fieldSum.T6_T5 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={35}></Table.Summary.Cell>
          <Table.Summary.Cell index={36}> </Table.Summary.Cell>
          <Table.Summary.Cell index={37}></Table.Summary.Cell>
          <Table.Summary.Cell index={38}></Table.Summary.Cell>
          <Table.Summary.Cell index={39}>
            {fieldSum.totalR1 ? fieldSum.totalR1 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={40}>
            {fieldSum.totalR2 ? fieldSum.totalR2 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={41}>
            {fieldSum.totalR3 ? fieldSum.totalR3 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={42}>
            {fieldSum.totalR4 ? fieldSum.totalR4 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={43}>
            {fieldSum.totalR5 ? fieldSum.totalR5 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={44}>
            {fieldSum.totalR6 ? fieldSum.totalR6 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={45}>
            {fieldSum.vol ? fieldSum.vol : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={46}>
            {fieldSum.perTr ? fieldSum.perTr : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={47}>
            {fieldSum.totalAmount ? fieldSum.totalAmount : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={50}></Table.Summary.Cell>
        </Table.Summary.Row>
      );
    } else if (selectedSchemeType === 3) {
      return (
        <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.T1 ? fieldSum.T1 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.T2 ? fieldSum.T2 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            {fieldSum.T3 ? fieldSum.T3 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={13}>
            {fieldSum.R1 ? fieldSum.R1 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={14}>
            {fieldSum.R2 ? fieldSum.R2 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={15}>
            {fieldSum.R3 ? fieldSum.R3 : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={16}>
            {fieldSum.P1Slab ? fieldSum.P1Slab : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={17}>
            {fieldSum.P2Slab ? fieldSum.P2Slab : ""}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    } else if (selectedSchemeType === 8) {
      return (
        <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.target ? fieldSum.target : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.incPerTarget ? fieldSum.incPerTarget : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            {fieldSum.P1 ? fieldSum.P1 : ""}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    } else if (selectedSchemeType === 4) {
      return (
        <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.target ? fieldSum.target : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.upperCap ? fieldSum.upperCap : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            {fieldSum.incPerTarget ? fieldSum.incPerTarget : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={13}>
            {fieldSum.P1 ? fieldSum.P1 : ""}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    } else if (selectedSchemeType === 5) {
      return (
        <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.installationExpected ? fieldSum.installationExpected : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.incPerTarget ? fieldSum.incPerTarget : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            {fieldSum.P1 ? fieldSum.P1 : ""}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    }
  };

  return (
    <>
      <div>
        <ActionButton
          status={status}
          handleStatus={(val) => handleAcceptModal(val)}
          onGetData={(item) =>
            fetchAwaitingApprovalScheme({
              ...payload,
              schemeType: item.schemeType,
              state: 0,
              pageSize: 150,
              pageNumber: 1,
              userid:
                userRole == "ZADM"
                  ? stateHeadId
                  : userRole == "NSH" || userRole == "PMIS"
                    ? zonalHeadId
                    : amgrId,
              status: 1,
            })
          }
        />

        <Table
          style={{
            cursor: "default",
            marginTop: "9px",
          }}
          summary={(pageData) => {

            const fieldSum = {
              b: 0,
              i: 0,
              c: 0,
              totalR1: 0,
              totalR2: 0,
              totalR3: 0,
              totalR4: 0,
              totalR5: 0,
              totalR6: 0,
              t1: 0,
              t2: 0,
              t3: 0,
              t4: 0,
              t5: 0,
              t6: 0,
              T2_T1: 0,
              T3_T2: 0,
              T4_T3: 0,
              T5_T4: 0,
              T6_T5: 0,
              vol: 0,
              perTr: 0,
              totalAmount: 0,
              T1: 0,
              T2: 0,
              T3: 0,
              R1: 0,
              R2: 0,
              R3: 0,
              P1: 0,
              P2: 0,
              P3: 0,
              P1Slab: 0,
              P2Slab: 0,
              P1Single: 0,
              P1UC: 0,
              P1U: 0,
              target: 0,
              incPerTarget: 0,
              payout: 0,
              installationExpected: 0,
              upperCap: 0,
            };
            pageData.forEach(
              ({
                payoutR1,
                payoutR2,
                payoutR3,
                payoutR4,
                payoutR5,
                payoutR6,
                TEPamount,
                BooksCollectionTargetT1,
                BooksCollectionTargetT2,
                BooksCollectionTargetT3,
                BooksCollectionTargetT4,
                BooksCollectionTargetT5,
                BooksCollectionTargetT6,
                BooksCollectionTargetT2T1,
                BooksCollectionTargetT3T2,
                BooksCollectionTargetT4T3,
                BooksCollectionTargetT5T4,
                BooksCollectionTargetT6T5,
                TEPvol,
                t1,
                t2,
                t3,
                r1,
                r2,
                r3,
                p1,
                p2,
                p3,
                Target,
                UC,
                incPerTarget,
                Payout,
                TEPpertr,
              }) => {
                fieldSum.totalR1 += payoutR1 ? parseInt(payoutR1) : 0;
                fieldSum.totalR2 += payoutR2 ? parseInt(payoutR2) : 0;
                fieldSum.totalR3 += payoutR3 ? parseInt(payoutR3) : 0;
                fieldSum.totalR4 += payoutR4 ? parseInt(payoutR4) : 0;
                fieldSum.totalR5 += payoutR5 ? parseInt(payoutR5) : 0;
                fieldSum.totalR6 += payoutR6 ? parseInt(payoutR6) : 0;
                fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
                fieldSum.t1 += BooksCollectionTargetT1
                  ? parseInt(BooksCollectionTargetT1)
                  : 0;
                fieldSum.t2 += BooksCollectionTargetT2
                  ? parseInt(BooksCollectionTargetT2)
                  : 0;
                fieldSum.t3 += BooksCollectionTargetT3
                  ? parseInt(BooksCollectionTargetT3)
                  : 0;
                  fieldSum.t4 += BooksCollectionTargetT4
                  ? parseInt(BooksCollectionTargetT4)
                  : 0;
                fieldSum.t5 += BooksCollectionTargetT5
                  ? parseInt(BooksCollectionTargetT5)
                  : 0;
                fieldSum.t6 += BooksCollectionTargetT6
                  ? parseInt(BooksCollectionTargetT6)
                  : 0;
                fieldSum.T2_T1 += BooksCollectionTargetT2T1
                  ? parseInt(BooksCollectionTargetT2T1)
                  : 0;
                fieldSum.T3_T2 += BooksCollectionTargetT3T2
                  ? parseInt(BooksCollectionTargetT3T2)
                  : 0;
                  fieldSum.T4_T3 += BooksCollectionTargetT4T3
                  ? parseInt(BooksCollectionTargetT4T3)
                  : 0;
                fieldSum.T5_T4 += BooksCollectionTargetT5T4
                  ? parseInt(BooksCollectionTargetT5T4)
                  : 0;
                  fieldSum.T6_T5 += BooksCollectionTargetT6T5
                  ? parseInt(BooksCollectionTargetT6T5)
                  : 0;
               
                fieldSum.vol += TEPvol ? parseInt(TEPvol) : 0;
                fieldSum.perTr = parseInt(fieldSum.totalAmount / fieldSum.vol);
                fieldSum.T1 += t1 ? parseInt(t1) : 0;
                fieldSum.T2 += t2 ? parseInt(t2) : 0;
                fieldSum.T3 += t3 ? parseInt(t3) : 0;
                fieldSum.R1 = r1 ? parseInt(p1) / parseInt(t1) : 0;
                fieldSum.R2 = r2 ? parseInt(p2) / parseInt(t2) : 0;
                fieldSum.R3 = r3 ? parseInt(p3) / parseInt(t3) : 0;
                // fieldSum.R1 += r1 ? parseInt(r1) : 0;
                // fieldSum.R2 += r2 ? parseInt(r2) : 0;
                fieldSum.P1 += p1 ? parseInt(p1) : 0;
                fieldSum.P2 += p2 ? parseInt(p2) : 0;
                fieldSum.P3 += p3 ? parseInt(p3) : 0;
                fieldSum.P1Slab += p1 ? parseInt(p1) : 0;
                fieldSum.P2Slab += p2 ? parseInt(p2) : 0;
                fieldSum.target += Target ? parseInt(Target) : 0;
                fieldSum.incPerTarget = incPerTarget ? Math.round(fieldSum.P1 / fieldSum.target) : 0;
                fieldSum.P1Single = p1
                  ? parseInt(fieldSum.incPerTarget * fieldSum.target)
                  : 0;
                fieldSum.upperCap += UC ? parseInt(UC) : 0;
                fieldSum.P1UC = p1
                  ? parseInt(fieldSum.upperCap * fieldSum.incPerTarget)
                  : 0;

                fieldSum.payout += Payout ? parseInt(Payout) : 0;
                fieldSum.installationExpected += Target ? parseInt(Target) : 0;
                fieldSum.P1U = p1
                  ? parseInt(
                    fieldSum.incPerTarget * fieldSum.installationExpected
                  )
                  : 0;
              }
            );
            return evaluateTotalRow(fieldSum);
          }}

          columns={defaultColumns}
          dataSource={dataSource}
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys, selectedRow) => {
              setSelectedRowKeys(newSelectedRowKeys);
              setSchemeIdNo(selectedRow);
              sessionStorage.setItem("rowData", JSON.stringify(selectedRow));
            },
            getCheckboxProps: (record) => ({
              disabled: record.status === 1 || record.status === 2,
              // Column configuration not to be checked
              name: record.name,
            }),
          }}
          pagination={false}
          onChange={handleChange}
          bordered
          scroll={scroll}
          footer={renderFooter}
        // footer={() => <b>Scheme Progress Count : {dataSource.length}</b>}
        />

        <Pagination defaultCurrent={1}
          className='paginationDesign'
          showSizeChanger
          current={collectionData.pageNumber}
          onChange={(pageNumber, pageSize) =>
            setCollectionData({ ...collectionData, pageNumber, pageSize })
          }
          // total={listAreaMngrData.data?.submittedCount}
          total={totalPage}
          pageSize={collectionData.pageSize}
          pageSizeOptions={['150', '250', '350']}
        />
        <SMDialog open={openSelectModal} onCancel={handleSelectModal}>
          <p className="custom-warning">Warning !</p>
          <p>Please Select The Dealer</p>
        </SMDialog>
        {/* <SMDialog open={openStatusModal} onOk={handleStatus} onCancel={handleStatusModal} >
        <p className="custom-warning">Success</p>
        <p>Are you sure you want to accept </p>
      </SMDialog> */}

        <StatusModal
          open={openAcceptModal}
          onOk={() => {
            handleStatus(2);
            message.success({
              content: "Scheme Approved Successfully",
              className: "custom-message",
            });
          }}
          onCancel={handleAcceptModal}
        >
          <p>Are you sure you want to accept </p>
        </StatusModal>
        <StatusModal
          open={openRejectModal}
          onOk={() => {
            handleStatus(5);
            message.success({
              content: "Scheme Reject Successfully",
              className: "custom-message",
            });
          }}
          onCancel={handleRejectModal}
        >
          <p>Are you sure you want to Reject</p>
        </StatusModal>
      </div>
      {contextHolder}
    </>
  );
};
export default ApprovalTable;
