import React from 'react';
import { Alert, Space } from 'antd';
import { ReactComponent as AlertIcon } from "../../../assets/vector/alert.svg";
import { fontSize, padding } from '@mui/system';
import './index.css';
import dayjs from 'dayjs';


const TurnAroundTime = ({schemeExpireDate}) =>(

    <Space className="alertBox"
        direction="vertical"
    >
     <Alert className='innerAlert'
            action={
            <AlertIcon style={{width:'23px', height:'21px'}}/>
            }
            message={`Scheme creation window expires in ${schemeExpireDate} days`}
            type="error"
        />
    </Space>
);
export default TurnAroundTime;