import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSchemeIdDetailsApi,
  getSchemesForWithdrawn,
  getWithdrawnSchemesList,
  postSaveWithdrawanScheme,
} from "../../../redux/withdrawal/withdrawalSlice";
import { Button, Input, Modal, Pagination, Select, Table } from "antd";
import { SearchOutlined } from "@mui/icons-material";
import { encryptPayload } from "../../../services/encryption";
import tableData from "../../collection/track/Installation/tableData";
import tableColumn from "./tableColumn";

const Withdrawal = () => {
  const {
    schemesForWithdrawn,
    saveWithdrawanScheme,
    withdrawnSchemesList,
    loadingScheme,
    loadingSchemeDetails
  } = useSelector((state) => state.withdrawData);
  const dispatch = useDispatch();
  const [schemeData, setSchemeData] = useState({});
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [schemeDataResponse, setSchemeDataResponse] = useState([]);
const [paginationDetails,setPaginationDetails]=useState({
  pageNumber:1,
  pageSize:150
})
  let getData = () => {
    dispatch(getSchemesForWithdrawn());
    dispatch(getWithdrawnSchemesList({
      PageNumber: paginationDetails.pageNumber,
      PageSize: paginationDetails.pageSize,
    }));
  };
  useEffect(() => {
    getData();
  }, []);
  let selectArray = Array?.isArray(schemesForWithdrawn?.data)
    ? schemesForWithdrawn?.data?.map((x) => ({
        value: x?.schemeID,
        label: x?.schemeNo,
      }))
    : [];
  const handleChange = (x) => {
    let filteredData = selectArray?.filter((y) => y?.value == x);
    setSchemeData(filteredData);
    showModal();
  };

  const columns = [
    {
      title: <div>Scheme ID</div>,
      dataIndex: "schemeID",
    },
    {
      title: <div>Scheme No</div>,
      dataIndex: "schemeNo",
    },
  ];
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {};
  const handleDone = async () => {
    await setOpen(false);
    await dispatch(
      postSaveWithdrawanScheme({
        SchemeId: schemeData?.[0]?.value,
        SchemeNo: schemeData?.[0]?.label,
      })
    );
    await dispatch(getSchemesForWithdrawn());
    await dispatch(getWithdrawnSchemesList({
      PageNumber: paginationDetails.pageNumber,
      PageSize: paginationDetails.pageSize,
    }));
    await setSchemeDataResponse([]);
    await setSchemeData([]);
  };
  useEffect(() => {
    if (schemeData?.[0]?.label) {
      dispatch(
        getSchemeIdDetailsApi(encryptPayload(schemeData?.[0]?.value))
      ).then((x) => {
     
        
          setSchemeDataResponse(x?.payload?.data||[]);
     
      }).catch((data)=>{
       
        setSchemeDataResponse([]);
      })
    }
  }, [schemeData]);
  const handleAction = (data) => {
    setConfirmationModal(true);
  };
  const handleWithdraw = () => {
    handleDone();
    setConfirmationModal(false);
    setOpen(false);
  };

  return (
    <div>
      <div className="d-flex w-50 align-items-center">
        <Select
          showSearch
          style={{
            width: 300,
          }}
          placeholder="Search Withdraw"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          value={schemeData}
          onChange={handleChange}
          options={selectArray}
        />
      </div>

      <div className="pt-3">
        <Table
          className="header bg-white pb-2"
          showSorterTooltip={false}
          pagination={false}
          loading={loadingScheme == "pending" ? true : false}
          // rowClassName={() => "editable-row"}
          columns={columns}
          dataSource={withdrawnSchemesList?.data || []}
          // scroll={{
          //   x: 1000,
          //   y: 400,
          // }}
          bordered
          size="middle"
        />
      </div>
      <div className="d-flex flex-column">

        <div>
          <Pagination
            defaultCurrent={1}
            className="paginationDesign"
            // style={{ marginTop: "-43px", position:"absolute", right: "14px" }}
            showSizeChanger
            current={paginationDetails.pageNumber}
            onChange={(pageNumber, pageSize) =>
              setPaginationDetails({ ...paginationDetails, pageNumber, pageSize })
            }
            total={100}
            pageSize={paginationDetails.pageSize}
            pageSizeOptions={['150', '250', '350']}
          />
        </div>
      </div>
      <Modal
        title="Confirmation"
        open={open}
        centered
        maskClosable={false}
        destroyOnClose={true}
        onCancel={() => {
          setOpen(false);
          setSchemeData([]);
          setConfirmationModal(false);
          setSchemeDataResponse([]);
        }}
        // okText="Done"
        width={confirmationModal ? "40%" : "90%"}
        footer={
          !confirmationModal ? null : (
            <>
              <Button type="primary" onClick={handleWithdraw}>
                Withdraw
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setSchemeDataResponse([]);
                  setSchemeData([]);
                  setConfirmationModal(false);
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </>
          )
        }
        height={confirmationModal ? "40%" : "90%"}
        // style={{ top: 0, left: 0 }}
        // bodyStyle={{ height: 'calc(100vh - 54px)' }}
        cancelText="Cancel"
      >
        {confirmationModal ? (
          <>
            <p>Do you want to withdraw the {schemeData?.[0]?.label} Scheme</p>
          </>
        ) : (
          <>
            <p>
              This {schemeData?.[0]?.label} scheme Id related to below scheme
            </p>
           {!schemeDataResponse?.[0]?.schemeTypeId?<p className="text-danger p-2">No Data Found</p>:<Table
              // loading={loadingScheme ? true : false}
              columns={tableColumn(
                schemeDataResponse?.[0]?.schemeTypeId,
                handleAction
              )}  loading={loadingSchemeDetails == "pending" ? true : false}
              onChange={handleChange}
              pagination={false}
              dataSource={schemeDataResponse}
              size="small"
              scroll={{ x: "max-content" }}
            />}
          </>
        )}
      </Modal>
    </div>
  );
};

export default Withdrawal;
