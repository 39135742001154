import { Tag, Space,  Button } from "antd";
import dayjs from "dayjs";
import { STATE_WISE_DASHBOARD_LABEL } from "../../../config";
import { EyeOutlined } from "@ant-design/icons";
import { TextLocalString } from "../../../components/ToastifyShow";


const tableData = (dashboardType,userRole,page, onHandleSort, onSendEmail) => {
  let columnsList = [
    {
      title: (
        <div> 
          {/* onClick={() => onHandleSort('schemeNo')} */}
          Scheme <br /> No
        </div>
      ),
      dataIndex: "schemeNo",
      width: "120px",
      fixed: "left",
      sorter: true,
      // sortDirections:['ascend', 'descend'],
      defaultSortOrder: "ascend",
      render: (_,item) => {
        //schemeTypeId
        let color = " ";
        const dataItem=item?.holdStatus==9?item?.holdStatus:item.status
        switch (dataItem) {
          case 0:
            color = "#FCE838"; //inProgress
            break;
          case 1:
            color = "#FCE838"; //inProgress
            break;
          case 2:
            color = "#51DE4E"; //Approved
            break;
          case 3:
            color = "#9999ff"; //Withdraw
            break;
          case 4:
            color = "#FF3232"; //Cancel
            break;
          case 5:
            color = "#FF3232"; //Reject
            break;
          case 7:
            color = "#bcb38d"; //Deviation
            break;
          case 8:
            color = "rgb(255, 0, 255)"; //Auto Reject
            break;
            case 9:
              color = "#9999ff"; //hold
              break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item?.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: (
        <div onClick={() => onHandleSort('dealerCode')}>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "dealerCode",
      width: "100px",
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"DealerCode",
    },
    {
      title: (
        <div onClick={() => onHandleSort('dealerLocation')}>
          Dealer <br /> Location
        </div>
      ),
      dataIndex: "dealerLocation",
      fixed: "left",
      width: "100px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
    },
    {
      title: (
        <div onClick={() => onHandleSort('dealerName')}>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "dealerName",
      width: "160px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerName",
    },
    {
      title: "State",
      dataIndex: "stateName",
      width: "100px",
      sorter: true,
      key: "StateName",
      defaultSortOrder: "ascend",
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "territoryManager",
      width: "120px",
      sorter: true,
      key: "TerritoryManager",
      defaultSortOrder: "ascend",
    }, {
      title: (
        <div>
          Top <br />SLAB
        </div>
      ),
      dataIndex: "topTarget",
      width: "120px",
      sorter: true,
      key: "topTarget",
      defaultSortOrder: "ascend",
      render:(x)=>x?.toUpperCase()
    },
    {
      title: (
        <div>
          Created By
        </div>
      ),
      dataIndex: "createdBy",
      width: "100px",
    },
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "startMonth",
      width: "140px",
      render: (_, item) => dayjs(item.startMonth).format("MMM'YY")
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      width: "140px",
      render: (_, item) => dayjs(item.endMonth).format("MMM'YY")
    },
    {
      title: "Industry",
      dataIndex: "industry",
      width: "60px",
    },
    {
      title: "B",
      dataIndex: "oldBillingQty",
      width: "60px",
      render:(x)=> TextLocalString(x)

    },
    {
      title: "I",
      dataIndex: "oldInstallation",
      width: "60px",
      render:(x)=> TextLocalString(x)
      // render: (_, item) => item.oldInstallation || 0
    },
    {
      title: "C",
      dataIndex: "oldCollection",
      width: "60px",
      render:(x)=> TextLocalString(x)
      // render: (_, item) => item.oldCollection || 0
    },
    {
      title: (
        <div>
          Opening <br /> Stock
        </div>
      ),
      dataIndex: "stock",
      width: "100px",
      children: [
        {
          title: "Nos",
          dataIndex: "oldOpeningStock",
          width: "100px",
        },
        {
          title: "Days",
          dataIndex: "openingStockDays",
          width: "100px",
        },
      ],
    },
    {
      title: "Closing Os. (In Lacs)",
      dataIndex: "stock",
      children: [
        {
title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
          dataIndex: "closingOutStandingMonth_3",
          width: "100px",
        },
        {
          title: (
            <span>
              Last <span>2<sup>nd</sup></span> Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_2",
          width: "100px",         render:(x)=> TextLocalString(x)
        },
        {
          title: (
            <span>
              Last Month
            </span>
          ),
          dataIndex: "closingOutStandingMonth_1",
          width: "100px",   
                render:(x)=> TextLocalString(x)
        },
        // {
        //   title: (
        //     <span>
        //       Last <span>1<sup>st</sup></span> Month Days
        //     </span>
        //   ),
        //   dataIndex: "outStandingDays",
        //   width: "100px",
        //   render:(x)=> TextLocalString(x)
        // },
      ],
    },
    {
      title: "Closing OS Days",
      dataIndex: "outStandingDays",
      children: [
        {
          title: "OS Last Month",
          dataIndex: "outStandingDays",
          width: "100px",
          render:(x)=> TextLocalString(x)
        },
      ],
    },
    {
      title: "BG",
      dataIndex: "bg",
      children: [
        {
          title: "Lacs",
          dataIndex: "bgLacsCurrentYear",
          width: "100px",
          render:(x,y)=>x||y?.bGLacsCurrentYear||0
        },
      ],
    },
    {
      title: (
        <div>
          Installation <br /> Plan
        </div>
      ),
      dataIndex: "IP",
      children: [
        {
          title: "Nos",
          dataIndex: "delayPlan",
          width: "100px",
          render:(x)=> TextLocalString(x)
        },
      ],
    },
    {
      title: (
        <div>
          Billing <br /> Details
        </div>
      ),
      dataIndex: "plan",
      children: [
        {
          title: "B",
          width: "100px",
          dataIndex: "billing",
          render:(x)=> TextLocalString(x)
          // render: (_, item) => item.billing || 0
        },
        {
          title: "I",
          width: "100px",
          dataIndex: "installation",
          render:(x)=> TextLocalString(x)
          // render: (_, item) => item.installation || 0
        },
        {
          title: "C",
          width: "100px",
          dataIndex: "collection",
          render:(x)=> TextLocalString(x)
          // render: (_, item) => item.collection || 0
        },
      ],
    },
    {
      title: (
        <div>
          Closing <br /> Stock
        </div>
      ),
      dataIndex: "closingStock",
      width: "80px",         render:(x)=> TextLocalString(x)
    },
    {
      title: (
        <div>
          Closing <br /> OutStanding (In Lacs)
        </div>
      ),
      dataIndex: "closingOutStanding",
      width: "110px",         render:(x)=> TextLocalString(x)
    },
    {
      title: "Books Collection Targets (No. of Trs.)",
      dataIndex: "BooksCollectionTarget",
      children: [
        {
          title: "T1",
          dataIndex: "t1",
          width: "100px",
            render:(x,y)=>{
              return(
                !y?.topTarget?.split(",")?.includes("t1")?
                <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
              )
            }
        },
        {
          title: "T2",
          dataIndex: "t2",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "T2-T1",
          dataIndex: "t2_T1",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "T3",
          dataIndex: "t3",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "T3-T2",
          dataIndex: "t3_T2",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "T4",
          dataIndex: "t4",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "T4-T3",
          dataIndex: "t4_T3",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "T5",
          dataIndex: "t5",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "T5-T4",
          dataIndex: "t5_T4",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "T6",
          dataIndex: "t6",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "T6-T5",
          dataIndex: "t6_T5",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
      ],
    },
    {
      title: (
        <div>
          Payout <br /> Rate
        </div>
      ),
      dataIndex: "pr",
      children: [
        {
          title: "R1",
          dataIndex: "r1",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t1")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "R2",
          dataIndex: "r2",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "R3",
          dataIndex: "r3",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "R4",
          dataIndex: "r4",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "R5",
          dataIndex: "r5",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "R6",
          dataIndex: "r6",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
      ],
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "100px",
    },
    {
      title: "Hold Remarks",
      dataIndex: "holdReason",
      width: "120px",
    },
    {
      title: "Payout",
      dataIndex: "payout",
      children: [
        {
          title: "P1",
          dataIndex: "p1",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t1")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "P2",
          dataIndex: "p2",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t2")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "P3",
          dataIndex: "p3",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t3")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "P4",
          dataIndex: "p4",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t4")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "P5",
          dataIndex: "p5",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t5")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
        {
          title: "P6",
          dataIndex: "p6",
          width: "100px",
          render:(x,y)=>{
            return(
              !y?.topTarget?.split(",")?.includes("t6")?
              <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
            )
          }
        },
      ],
    },
    {
      title: "Total Expected Payout",
      dataIndex: "TEP",
      children: [
        {
          title: "Expected Volume",
          dataIndex: "volume",
          width: "100px",         render:(x)=> TextLocalString(x)
        },
        {
          title: "Per Tr.",
          dataIndex: "perTr",
          width: "100px",         render:(x)=> TextLocalString(x)
        },
        {
          title: "Amount",
          dataIndex: "amount",
          width: "100px",
          render:(x)=> TextLocalString(x)
        },
      ],
    },
  ];

  return userRole == 'SADM' 
  ? [...columnsList, {
      title: "Actions",
      dataIndex: "actions",
      width: "80px",
      fixed: "right",
      render: (_, item) => (
        item.isCommunication ?
        <Space size="small">
          
          <Button
          icon={<EyeOutlined />}
            onClick={() => onSendEmail([item])}
            >Preview</Button>
        </Space>
        :
        null
      )
    }]
  : columnsList
};

export default tableData;
