import { EyeOutlined, FileAddOutlined } from "@ant-design/icons";
import { Tag, Space, Button } from "antd";
import dayjs from "dayjs";
import AddBoxIcon from '@mui/icons-material/AddBox';
const SLAB_BASED = 3;
const SINGLE_TARGET = 8;
const SINGLE_TARGET_WITH_UPPER_CAP = 4;
const UNCONDITIONAL_STATEMENT = 5;

const tableColumn = (selectedSchemeType, handleAction) => {
  const getCols = (schemeType) => {
    if (schemeType == SLAB_BASED) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          width: "160px",
        },
        {
          title: "Targets",
          dataIndex: "TEP",
          width: "360px",
          children: [
            {
              title: "T1",
              dataIndex: "t1",
              width: "140px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t1") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }

            },
            {
              title: "T2",
              dataIndex: "t2",
              width: "140px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t2") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "T3",
              dataIndex: "t3",
              width: "140px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t3") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
          ],
        },
        {
          title: "Inc. per Tractor",
          dataIndex: "TEP",
          width: "360px",
          children: [
            {
              title: "r1",
              dataIndex: "r1",
              width: "140px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t1") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "r2",
              dataIndex: "r2",
              width: "140px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t2") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "r3",
              dataIndex: "r3",
              width: "140px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t3") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
          ],
        },
        {
          title: "Payout",
          dataIndex: "TEP",
          width: "360px",
          children: [
            {
              title: "p1",
              dataIndex: "p1",
              width: "140px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t1") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "p2",
              dataIndex: "p2",
              width: "140px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t2") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
            {
              title: "p3",
              dataIndex: "p3",
              width: "140px",
              render: (x, y) => {
                return (
                  !y?.topTarget?.split(",")?.includes("t3") ?
                    <div className="h-24 input-disabled"></div> : <div >{x}</div>
                )
              }
            },
          ],
        },
      ]
    } else if (schemeType == SINGLE_TARGET) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          width: "160px",
        },
        {
          title: "INSTALLATION TARGET",
          dataIndex: "installationTarget",
          width: "145px",
        },
        {
          title: "INC. PER TRACTOR",
          dataIndex: "incPerTarget",
          width: "145px",
        },
        {
          title: "PAYOUT",
          dataIndex: "p1",
          width: "145px",
        },
      ]
    } else if (schemeType == SINGLE_TARGET_WITH_UPPER_CAP) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          width: "160px",
        },
        {
          title: "INSTALLATION TARGET",
          dataIndex: "installationTarget",
          width: "145px",
        },
        {
          title: "UPPER CAP",
          dataIndex: "upperCap",
          width: "145px",
        },
        {
          title: "INC. PER TRACTOR",
          dataIndex: "incPerTarget",
          width: "145px",
        },
        {
          title: "PAYOUT",
          dataIndex: "p1",
          width: "145px",
        },
      ]
    } else if (schemeType == UNCONDITIONAL_STATEMENT) {
      return [
        {
          title: "Actual Installation Last Year Month",
          dataIndex: "actual_Installation_Last_Year_Month",
          width: "160px",
        },
        {
          title: "INSTALLATION EXPECTED",
          dataIndex: "installationTarget",
          width: "145px",
        },
        {
          title: "INC. PER TRACTOR",
          dataIndex: "incPerTarget",
          width: "145px",
        },
        {
          title: "PAYOUT",
          dataIndex: "p1",
          width: "145px",
        },
      ]
    } else {
      return [{
        title: "Industry",
        dataIndex: "industry",
        width: "100px",
      },
      {
        title: "B",
        dataIndex: "oldBillingQty",
        width: "100px",
      },
      {
        title: "I",
        dataIndex: "oldInstallation"
        , width: "100px",
      },
      {
        title: "C",
        dataIndex: "oldCollection",
        width: "100px",
      },
      // {
      //   title: "Slab Achieved Last Year",
      //   dataIndex: "target",
      // },
      {
        title: (
          <div>
            Opening <br /> Stock
          </div>
        ),
        dataIndex: "stock",
        children: [
          {
            title: "Nos",
            dataIndex: "oldOpeningStock",
            width: "100px",
          },
          {
            title: "Days",
            dataIndex: "openingStockDays",
            width: "100px",
          },
        ],
      },
      {
        title: "Closing Os. (In Lacs)",
        dataIndex: "stock",
        children: [
          {
  title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_3",
            width: "150px",
          },
          {
          title: (
              <span>
                Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_2",
            width: "150px",
          },
          {
            title: (
              <span>
                Last Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_1",
            width: "150px",
          },
          // {
          //   title: (
          //     <span>
          //       Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
          //     </span>
          //   ),
          //   dataIndex: "outStandingDays",
          //   width: "100px",
          // },
        ],
      },
      {
        title: "Closing OS Days",
        dataIndex: "outStandingDays",
        children: [
          {
            title: "OS Last Month",
            dataIndex: "outStandingDays",
            width: "100px",
          },
        ],
      },
      {
        title: "BG",
        dataIndex: "bg",
        children: [
          {
            title: "Lacs",
            dataIndex: "bGLacsCurrentYear",
            width: "100px",
          },
        ],
      },
      {
        title: (
          <div>
            Installation <br /> Plan
          </div>
        ),
        dataIndex: "IP",
        children: [
          {
            title: "Nos",
            dataIndex: "delayPlan",
            width: "150px",
          },
        ],
      },
      {
        title: (
          <div>
            Billing <br /> Details
          </div>
        ),
        dataIndex: "plan",
        children: [
          {
            title: "B",
            dataIndex: "billing",
            width: "150px",
          },
          {
            title: "I",
            dataIndex: "installation",
            width: "150px",
          },
          {
            title: "C",
            dataIndex: "collection",
            width: "150px",
          },
        ],
      },
      {
        title: "Closing Stock",
        dataIndex: "closingStock",
        render: (x, y) => {
          return (
            <div >{x}</div>
          )
        }, width: "150px"
      },
      {
        title: "Closing OutStanding (In Lacs)",
        dataIndex: "closingOutStanding",
        render: (x, y) => {
          return (
            <div >{x}</div>
          )
        },
        width: "150px"
      },
      {
        title: "Books Collection Targets (No. of Trs.)",
        dataIndex: "BooksCollectionTarget",
        children: [
          {
            title: "T1",
            dataIndex: "t1",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t1") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px",
          },
          {
            title: "T2",
            dataIndex: "t2",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t2") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px",
          },
          {
            title: "T2-T1",
            dataIndex: "t2_T1",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t2") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px",
          },
          {
            title: "T3",
            dataIndex: "t3",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t3") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px",
          },
          {
            title: "T3-T2",
            dataIndex: "t3_T2",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t3") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px",
          },
          {
            title: "T4",
            dataIndex: "t4",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t4") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px",
          },
          {
            title: "T4-T3",
            dataIndex: "t4_T3",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t4") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "T5",
            dataIndex: "t5",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t5") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "T5-T4",
            dataIndex: "t5_T4",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t5") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "T6",
            dataIndex: "t6",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t6") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "T6-T5",
            dataIndex: "t6_T5",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t6") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
        ],
      },
      {
        title: (
          <div>
            Payout <br /> Rate
          </div>
        ),
        dataIndex: "pr",

        children: [
          {
            title: "R1",
            dataIndex: "r1",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t1") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "R2",
            dataIndex: "r2",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t2") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "R3",
            dataIndex: "r3",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t3") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "R4",
            dataIndex: "r4",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t4") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "R5",
            dataIndex: "r5",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t5") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "R6",
            dataIndex: "r6",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t6") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
        ],
      }, {
        title: "Payout",
        dataIndex: "payout",

        children: [
          {
            title: "P1",
            dataIndex: "p1",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t1") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "P2",
            dataIndex: "p2",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t2") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "P3",
            dataIndex: "p3",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t3") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "P4",
            dataIndex: "p4",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t4") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "P5",
            dataIndex: "p5",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t5") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
          {
            title: "P6",
            dataIndex: "p6",
            render: (x, y) => {
              return (
                !y?.topTarget?.split(",")?.includes("t6") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "150px"
          },
        ],
      },
      {
        title: "Total Expected Payout",
        dataIndex: "TEP",
        children: [
          {
            title: "Expected Volume",
            dataIndex: "volume",
            width: "150px",
          },
          {
            title: "Per Tr.",
            dataIndex: "perTr",
            width: "150px",

          },
          {
            title: "Amount",
            dataIndex: "amount",
            width: "150px",
          },
        ]
      }]
    }

  }

  let TopSlabShow = (x) =>
    (x == 1 || x == 2 || x == 3) ? [{
      title: (
        <div>
          TOP SLAB
        </div>
      ),
      dataIndex: "topTarget",
      width: "180px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "topTarget",
      render: (x) => x?.toUpperCase() || ""
    }] : []
  let columnsList = [
    {
      title: (
        <div>
          {/* onClick={() => onHandleSort('schemeNo')} */}
          Scheme <br /> No
        </div>
      ),
      width: "140px",
      fixed: "left",
      dataIndex: "schemeNo"
    },
    {
      title: (
        <div >
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "dealerCode",
      width: "140px",
      fixed: "left",
      key: "DealerCode",
    },
    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),
      dataIndex: "dealerLocation",
      fixed: "left",
      width: "150px",
      key: "DealerLocation",
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "dealerName",
      width: "160px",
      key: "DealerName"
    },
    {
      title: "State",
      dataIndex: "stateName",
      width: "100px",
      key: "StateName",
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "territoryManager",
      width: "180px",
      key: "TerritoryManager",
    },
    ...TopSlabShow(selectedSchemeType),
    // {
    //   title: (
    //     <div>
    //       Created By
    //     </div>
    //   ),
    //   dataIndex: "createdBy",
    //   width: "180px",
    // },
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "startMonth",
      width: "140px",
      render: (_, item) => dayjs(item.startMonth).format("MMM'YY")
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      width: "140px",
      render: (_, item) => dayjs(item.endMonth).format("MMM'YY")
    },
    ...getCols(selectedSchemeType),
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "160px",
    },
  ];

  return [...columnsList, {
    title: "Actions",
    dataIndex: "actions",
    width: "150px",
    fixed: "right",
    render: (_, item) => (
      <Button
        className="text-danger1"
        icon={<FileAddOutlined size={30} />}
        onClick={() => handleAction(item)}
      >Withdraw</Button>
    )
  }]
};

export default tableColumn;
