export const ModificationInstallationDownload = ({ schemeType }) => {
  let tab_text =
    schemeType == "3" || schemeType == ""
      ? `<table class="custom-table">
    <thead >
    <tr >
      <th colspan="9" rowspan="1" >-</th>
      <th colspan="3" rowspan="1">Targets</th>
      <th colspan="3" rowspan="1">INC.PER TRACTOR</th>
      <th  rowspan="1">-</th>
      </tr>
    <tr>
    <th >DEALER CODE</th>
    <th >DEALER NAME</th>
    <th >STATE</th>
    <th >DEALER LOCATION</th>
    <th >TERRITORY MANAGER</th>
    <th>TOP SLAB</th>
    <th >START MONTH</th>
    <th >END MONTH</th>
    <th>ACTUAL INSTALLATION LAST YEAR MONTH</th>
     <th>T1</th>
     <th>T2</th>
     <th>T3</th>
     <th>R1</th>
     <th>R2</th>
     <th>R3</th>
     <th>REMARKS</th>
    </tr>
    </thead>`
      : schemeType == "8"
      ? `<table class="custom-table">
    <thead >
    <tr >
      <th>DEALER CODE</th>
      <th>DEALER NAME</th>
      <th>STATE</th>
      <th>DEALER LOCATION</th>
      <th>TERRITORY MANAGER</th>
      <th>START MONTH</th>
      <th>END MONTH</th>
      <th>ACTUAL INSTALLATION LAST YEAR MONTH</th>
      <th>INSTALLATION TARGET</th>
      <th>INC. PER TRACTOR	</th>
      <th>REMARKS</th>
      </tr>
    </thead>`
      : schemeType == "4"
      ? `<table class="custom-table">
    <thead >
    <tr >
    <th>DEALER CODE</th>
    <th>DEALER NAME</th>
    <th>STATE</th>
    <th>DEALER LOCATION</th>
    <th>TERRITORY MANAGER</th>
    <th>START MONTH</th>
    <th>END MONTH</th>
    <th>ACTUAL INSTALLATION LAST YEAR MONTH</th>
    <th>INSTALLATION TARGET</th>
    <th>UPPER CAP</th>
    <th>INC. PER TRACTOR	</th>
    <th>REMARKS</th>
    </tr>
    </thead>`
      : `<table class="custom-table">
    <thead >
    <tr >
    <th>DEALER CODE</th>
    <th>DEALER NAME</th>
    <th>STATE</th>
    <th>DEALER LOCATION</th>
    <th>TERRITORY MANAGER</th>
    <th>START MONTH</th>
    <th>END MONTH</th>
    <th>ACTUAL INSTALLATION LAST YEAR MONTH</th>
    <th>INSTALLATION EXPECTED</th>
    <th>INC. PER TRACTOR	</th>
    <th>REMARKS</th>
    </tr>
    </thead>`;

  return tab_text;
};
