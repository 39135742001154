import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Button ,Row, Col} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import InstallationDraftCollapseTab from "./InstallationDraftSchemeCollapse";
import { Link } from 'react-router-dom';
import axiosAPI from "../../../services/axiosApi";
import { draftScheme } from "../../../redux/collection/AllDraftScheme";
// import SchemeHeader from "./draftSchemeHeader";
import './InstallationDraftScheme.css'


const InstallationDraftSchemes = () => {


useEffect(()=>{
sessionStorage.removeItem("rowData")
},[])

    return (
      <div className='create_scheme_wrap collection-schme-box'>
        <Divider />
        <div className='draft-scheme-header'>
          <span className='draft-scheme-header-title'>Draft Schemes</span>
          <Link to='/installation/createNewScheme'>
          <Button className="create-btn btn-big" icon={<PlusOutlined />}>Create New Scheme</Button></ Link>
        </div>
        <InstallationDraftCollapseTab   
      />
      </div>
    )
}
export default InstallationDraftSchemes;
