import {
  Button,
  Table,
  Row,
  Col,
  Tag,
  DatePicker,
  Dropdown,
  Input,
  Select,
  Space,
  Tooltip
} from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import axiosAPI from "../../../services/axiosApi";
import { getStateListApi } from "../../../redux/stateData/stateDataSlice";
import ModificationCollectionTable from "./ModificationCollectionTable";
import Status from "../../../components/allSchemeStatus/allSchemeStatus";
import "./ModificationInstallation";
import { getTrackCollectionDealersListApi } from "../../../redux/trackCollection/trackCollectionSlice";
import {
  trackGetAreaManagerApi,
  trackGetStateHeadbyAMIdApi,
  trackGetZonalHeadbyAMIdApi,
  trackZonalHeadbySHIdApi,
} from "../../../redux/schemetrack/schemaTrackSlice";
import { DownOutlined } from "@ant-design/icons";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import { decryptData } from "../../../utils/localStorageEncodeDecode";



const { RangePicker } = DatePicker;

const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: 0,
  dealerCode: [],
  startMonth: 0,
  endMonth: 0,
  currentMonth: 0,
  selectedState: "",
  dealerLocation: "",
  schemeNumber: "",
  dealerCode: "",
  pageSize: 150,
  pageNumber: 1,
};

const AwaitingLogsModification = ({
  props,
  onChangeFilter,
  showEnforcedMonth = false,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;

  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [startMonth, setStartMonth] = useState(dayjs());
  const [endMonth, setEndMonth] = useState(dayjs());
  const [enforcedMonth, setEnforcedMonth] = useState();
  const [stateListArr, setStateListArr] = useState([]);
  const [areaManagerArr, setAreaManagerArr] = useState([]);
  const { stateList, loadingState } = useSelector((state) => state.stateData);
  const {
    getAreaManager,
    getStateHead,
    getZonalHead,
    getZonalHeadSH,
    loadingTrack,
  } = useSelector((state) => state.schemeTrack);
  const [selectedStateId, setSelectedStateId] = useState(0);
  const [selectedSchemeType, setSelectedSchemeType] = useState(0);
  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );
  const [selectedStatus, setSelectedStatus] = useState("");

  const [stateHeadData, setStateHeadData] = useState([])
  const [zonalHeadData, setZonalHeadData] = useState([])
  const [areaMngrData, setAreaMngrData] = useState([])
  const [selectedSH, setSelectedSH] = useState('')
  const [selectedSHID, setSelectedSHID] = useState(0)
  const [selectedAMID, setSelectedAMID] = useState(0)
  const [selectedZHID, setSelectedZHID] = useState(0)

  const [selectedZH, setSelectedZH] = useState('')
  const [selectedAM, setSelectedAM] = useState('')
  const [areaDropdown, setAreaDropdown] = useState([])

  const [monthlyFilters, setMonthlyFilters] = useState({
    monthStart: new Date().getMonth() + 1,
    monthEnd: new Date().getMonth() + 1,
    enforcedMonth: 0,
  });


  const subColType = [
    {
      key: "0",
      label: (
        <a onClick={() => setCollectionData({...collectionData, schemeType: 0})}>Scheme Sub Type
        </a>
      ),
    },
    {
      key: "1",
      label: (
        <a
          onClick={() =>
            setCollectionData({ ...collectionData, schemeType: 1 })
          }
        >
          With Delivery Plan
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() =>
            setCollectionData({ ...collectionData, schemeType: 2 })
          }
        >
          Without Delivery Plan
        </a>
      ),
      disabled: false,
    },
    {
      key: "3",
      label: (
        <a
          onClick={() =>
            setCollectionData({ ...collectionData, schemeType: 8 })
          }
        >
          Single Target 
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          onClick={() =>
            setCollectionData({ ...collectionData, schemeType: 4 })
          }
        >
          Single Target With Upper Cap
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a
          onClick={() =>
            setCollectionData({ ...collectionData, schemeType: 3 })
          }
        >
          Slab Based 
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a
          onClick={() =>
            setCollectionData({ ...collectionData, schemeType: 5})
          }
        >
         Unconditional Installation
        </a>
      ),
    },
  ];

  const getSchemeType = (schemeType) => {
    if (schemeType == 1) {
      return "With Delivery Plan";
    }
    if (schemeType == 2) {
      return "Without Delivery Plan";
    }
    if (schemeType == 3) {
      return "Slab Based";
    }
    if (schemeType == 8) {
      return "Single Target";
    }
    if (schemeType == 4) {
      return "Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Unconditional Installation";
    }
  };

  useEffect(() => {
    dispatch(getStateListApi());
  }, []);

  const { dealersList } = useSelector((state) => state.trackCollection);

  const getZhId = () => {
    if(userRole == "ZADM"){
      return userId
    }
    if (userRole == "SADM") {
      return getZonalHeadSH?.data?.userId
    }

    if(userRole == 'AMGR' && getZonalHead?.data?.userId) {
      return getZonalHead?.data?.userId
    }
    if(['PMIS', 'Finance', 'COSM', 'NSH'].includes(userRole)) {
      return selectedZHID;
    }
  }

  const getShId = () => {
    if(userRole == "ZADM"){
      return selectedSHID
    }
    if (userRole == "SADM") {
      return userId
    }
    if(userRole == 'AMGR' && getStateHead?.data?.userId) {
      return getStateHead?.data?.userId
    }
    if(['PMIS', 'Finance', 'COSM', 'NSH'].includes(userRole)) {
      return selectedSHID;
    }
  }

  const getAmId = () => {
    if(userRole == "ZADM"){
      return selectedAMID
    }
    if (userRole == "SADM") {
      return selectedAMID;
    }
    if (userRole == "AMGR") {
      return userId
    }
    if(['PMIS', 'Finance', 'COSM', 'NSH'].includes(userRole)) {
      return selectedAMID
    }
  }

  useEffect(() => {
    const payload = 
    {
      "startMonth": collectionData?.startMonth ? new Date(collectionData?.startMonth)?.getMonth() + 1 : collectionData?.startMonth,
      "endMonth": collectionData?.endMonth ? new Date(collectionData?.endMonth)?.getMonth() + 1 : collectionData?.endMonth,
      "userid": userId, // 18
      "status": selectedStatus|| 0,
      "stateId": selectedStateId,
      "stateHeadId": getShId(),
      "areaManagerId": getAmId(),
      "zonalHeadId": getZhId(),
      "runningMonth": collectionData?.currentMonth,
      "userRole": userRole,
      "fyear": "FY24",
      "schemeType": collectionData?.schemeType,
      "state": selectedStateId,
      dealerLocation: collectionData?.dealerLocation,
      schemeNumber: collectionData?.schemeNumber,
      dealerCode: collectionData?.dealerCode,
      "pageSize": collectionData?.pageSize,
      "pageNumber": collectionData?.pageNumber,
      sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
      sortingType: collectionData.sortingType || "DealerCode"
    }
    dispatch(getTrackCollectionDealersListApi(payload))
  },[selectedStateId,selectedStatus, monthlyFilters, collectionData, selectedSHID, selectedAMID, getZonalHeadSH, getStateHead, getZonalHead, selectedZHID])

  useEffect(() => {
    if (userRole != "AMGR") {
      dispatch(trackGetAreaManagerApi({ userid: userId }));
    }
    if (userRole !== "SADM") {
      // dispatch(trackGetStateHeadbyAMIdApi({"userid": userId}))
    }
    if (userRole == "SADM") {
      dispatch(trackZonalHeadbySHIdApi({ UserId: userId }));
    }
    if (userRole == "ZADM") {
      dispatch(trackGetZonalHeadbyAMIdApi({ userid: userId }));
      dispatch(trackZonalHeadbySHIdApi({ userid: userId }));
    }
    if (userRole == "AMGR") {
      dispatch(trackGetZonalHeadbyAMIdApi({ userid: userId }));
      dispatch(trackGetStateHeadbyAMIdApi({ userid: userId }));
      // dispatch(trackZonalHeadbySHIdApi({"userid": userId}))
    }
  }, [selectedStateId, selectedStatus, enforcedMonth]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };
  const hasSelected = selectedRowKeys.length > 0;
  const handleInputChange = (key, date, dateString) => {
    switch (key) {
      case "monthStart":
        setStartMonth(date);
        break;
      case "monthEnd":
        setEndMonth(date);
        break;
      case "enforcedMonth":
        setEnforcedMonth(date);
        break;
    }
    const month = date ? new Date(date).getMonth() + 1 : 0;
    // onChangeFilter(key, month);
    setCollectionData({ ...collectionData, currentMonth: month });
    setMonthlyFilters((monthlyFilters) => ({
      ...monthlyFilters,
      [key]: month,
    }));
  };

  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      setStateListArr(stateList?.data);
    } else {
      setStateListArr([]);
    }
  }, [stateList]);

  const handleStateSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));
  };

  const disabledDate = (current, selectedMonth) => {
    // lock
    if (false) return true;
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const futureYear = currentYear + 1;

    //disableDateRestriction
    if (false) {
      return null;
    }

    return (
      current &&
      (current.year() < currentYear ||
        (current.year() === currentYear && current.month() < currentMonth) ||
        current.year() > futureYear ||
        (current.year() === futureYear && current.month() > 2))
    );
  };

  const handlePanelChange = (value) => {
    setSelectedMonth(value);
  };

  const currentFiscalYear = getCurrentFiscalYear();

  const getShDropdownData = (userId) => {
    axiosAPI.post(
      `SchemeGenerate/GetDropdownStateHead`,
      { userId }
    )
    .then((result) => {
      setStateHeadData([
        {
          key: '0',
          label: (
            <span onClick={() => setSelectedSHID(0)}>All State Heads</span>)
        },
        ...result.data.data?.map(item => {
        return {
          key: item.id,
          label: (
            <span onClick={() => { setSelectedSH(item.stateHeadName); setSelectedSHID(item.id); getAmDropdownData(item.id) }}>{item.stateHeadName}</span>)
        }
      })])
    });
  }

  useEffect(() => {
    if (userRole == "ZADM") {
      getShDropdownData(userId);
    }
    if (userRole == "SADM") {
      getAmDropdownData(userId);
    }
  }, []);

  useEffect(() => {
      axiosAPI.post(
          `SchemeGenerate/GetzonalHead`)
        .then((result) => {
          setZonalHeadData([
            {
              key: '0',
              label: (
                <span onClick={() => {setSelectedZH(" All Zonal Head");setSelectedZHID(0)}}>All Zonal Head</span>)
            },
            ...result.data.data?.map(item => {
            return {
              key: item.id,
              label: (
                <span onClick={() => { setSelectedZH(item.zonalHeadName); setSelectedZHID(item.id); getShDropdownData(item.id) }}>{item.zonalHeadName}</span>)
            }
          })])
        });
    
    if (userRole == "SADM") {
      getStateByStateHeadId(userId)
    }
  }, []);

  const getDealerDropdownData = (userId, type) => {
    const monthStart = collectionData?.startDate
      ? dayjs(collectionData?.startDate).month() + 1
      : dayjs().month() + 1;
    const monthEnd = collectionData?.endDate
      ? dayjs(collectionData?.endDate).month() + 1
      : dayjs().month() + 1;

    if (type === "amID") {
      setCollectionData((collectionData) => ({
        ...collectionData,
        amID: userId,
      }));
    }
    const postData = {
      fyear: currentFiscalYear,
      month: monthStart,
      monthEnd: monthEnd,
      userId,
      year: dayjs().year(),
    };
    // axiosAPI.post(`SchemeGenerate/GetSchemeList`, postData).then((result) => {
    //   const data = formatData(result.data.data);
    //   // setDealer(result.data.data)

    //   dispatch(schemeList(data));
    // });
  };

  const handleAMClick = (event) => {
    const selectedAm = areaDropdown.find(
      (value) => value.id === parseInt(event.key)
    );
    setSelectedAM(selectedAm?.am);
    getDealerDropdownData(parseInt(event.key), "amID");
  };

  const getAmDropdownData = (userId) => {
    axiosAPI.post(`SchemeGenerate/GetDropdownAM`, { userId })
      .then((result) => {
        setAreaDropdown(result?.data?.data);
        setAreaMngrData([
          {
            key: '0',
            label: (
              <span onClick={() =>{setSelectedAM("All Area Managers"); setSelectedSHID(0)}}>All Area Managers</span>)
          },
          ...result.data.data.map(item => {
          return {
            key: item.id,
            label: (
              <span onClick={() => setSelectedAMID(item.id)}>{item.am}</span>)
          }
        })])
      });
  }

  const getZH = () => {
    if (userRole == "ZADM") {
      return userName;
    }
    if (userRole == "SADM" && getZonalHeadSH?.data?.userName) {
      return getZonalHeadSH?.data?.userName;
    }
    return selectedZH == 'All Zonal Head' ? 'All Zonal Head' : selectedZH 
  }

  const getSH = () => {
    // if(userRole == 'ZADM') {
    //   return userName;
    // };
    if (userRole == "SADM" && userName) {
      return userName;
    }
    if(userRole == 'ZADM') {
      return selectedSH == '' ? 'All State Heads' : selectedSH
    };
    if(userRole == 'AMGR' && getStateHead?.data?.userName) {
      return getStateHead?.data?.userName
    }
    return selectedSH == '' ? 'All State Heads' : selectedSH
  }

  const getAM = () => {
    if(userRole == 'ZADM') {
      return selectedAM == '' ? 'All Area Managers' : selectedAM
    };
    if(userRole == 'SADM') {
      return selectedAM == '' ? 'All Area Managers' : selectedAM
    }
    if(userRole == 'AMGR' && userName) {
      return userName
    }    
    return selectedAM == 'All Area Managers' ? 'All Area Managers' : selectedAM
  }

  const getStateByStateHeadId = (SHID) => {
    const payload = {
      fyear: currentFiscalYear,
      userid: SHID ? SHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatebyStateHeadId`, payload)
      .then((result) => {
        const data = result?.data?.data;
        setStateListArr([{
          stateID: data ? data?.stateID : 0,
          stateName: data ? data?.stateName : "stateName",
        }]);
      });
  }

  const getStateByZonalHeadId = (ZHID) => {
    const payload = {
      fyear: currentFiscalYear,
      userid: ZHID ? ZHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatesbyZonalHeadId`, payload)
      .then((result) => {
        const data = result.data.data;
        setStateListArr(data);
      });
  }

  useEffect(() => {
    if(userRole == 'SADM') {
      getStateByStateHeadId(userId)
      return;
    }
    if(selectedSHID){
      getStateByStateHeadId(selectedSHID)
    }
    if(userRole == 'ZADM') {
      getStateByZonalHeadId(userId)
      return;
    }
  },[selectedSHID])

  return (
    <div
      id="table"
      className="trackcollection create_scheme_wrap onemoth-date-selector view-schela"
    >
       <div>
        <div
          style={{marginTop:"18px"}}
            id="space"
            className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
          >
            <div
              className="allSchmeStatus select_state heightauto"
              style={{ color: "red" }}
            >
              <Status
                className=""
                selectedStatus={selectedStatus}
                handleStatusDropdown={(statusId) => setSelectedStatus(statusId)}
              />
            </div>

            <Col>
            <Tooltip title={"Select Scheme"}>
                <Dropdown menu={{ items: subColType }}>
                  <Button>
                    <Space> 
                      {collectionData?.schemeType == '' ? 'Scheme Sub Type' : getSchemeType(collectionData?.schemeType)}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
                </Tooltip>
            </Col>
            <div className="marginauto">
            <Tooltip title={"Running Month"}>
              <DatePicker
                // onChange={(date) => handleInputChange('enforcedMonth', date)}
                onChange ={(date, formatDate) => {
                  if(formatDate == ''){
                    setCollectionData({
                      ...collectionData,
                      currentMonth: 0
                    })
                    return;  
                  }
                  setCollectionData({
                    ...collectionData,
                    currentMonth: new Date(formatDate).getMonth() + 1
                  })
                }}
                className="start"
                placeholder="Running Month"
                picker="month"
                value={enforcedMonth}
                allowClear
                disabled={collectionData.startMonth != '' || collectionData.endMonth != '' }
              />
</Tooltip>
            </div>
            <div className="month ">
            <Tooltip title={"Start Month      End Month"}>
                <RangePicker
                  mode={["month", "month"]}
                  picker="month"
                  onChange ={(date, formatDate) => {
                    setCollectionData((collectionData) => ({
                      ...collectionData,
                      startMonth: date && date[0] ? date[0].format() : 0,
                      endMonth: date && date[1] ? date[1].format() : 0,
                    }));
                  }}
                  //disabledDate={(current) => disabledDate(current, selectedMonth)}
                  // disabled={[!showStartMonth, !showEndMonth]}
                  onPanelChange={handlePanelChange}
                  disabled={collectionData.currentMonth > 0}
                />
                </Tooltip>
              </div>
              <Col>
              <Tooltip title={"Zonal Head"}>
                <Dropdown
                  menu={{ items: zonalHeadData }}
                  disabled={["ZADM", "SADM", "AMGR"].includes(userRole)}
                >
                  <Button>
                    <Space>
                      {getZH()}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
                </Tooltip>
              </Col>
              <div className="positionicons">
                
              </div>
        </div>
        <div
          style={{marginTop:"0"}}
            id="space"
            className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
          >
              {true && (
                <Col>
                              <Tooltip title={"State Head"}>
                  <Dropdown
                    menu={{ items: stateHeadData }}
                    disabled={["SADM", "AMGR"].includes(userRole)}
                  >
                    <Button>
                      <Space>
                        {getSH()}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown></Tooltip>
                </Col>
              )}
              {true && <Col>
                <Tooltip title={"Area Manager"}>
                <Dropdown menu={{ items: areaMngrData, onClick: handleAMClick }} disabled={['AMGR'].includes(userRole)}>
                  <Button>
                    <Space>
                      {getAM()}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
                </Tooltip>
              </Col>}
              <Tooltip title={"State Select"}>
              <Select
                showSearch
                className="select_state heightauto"
                placeholder="Select State"
                optionFilterProp="children"
                onSelect={handleStateSelect}
                defaultValue={0}
              >
                <Option key={0} value={0}>All States</Option>
                {stateListArr?.map((option) => (
                  <Option key={option.stateID} value={option.stateName}>
                    {option.stateName}
                  </Option>
                ))}
              </Select>
              </Tooltip>
              <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
                <Input placeholder="Scheme No." value={collectionData.schemeNumber} onChange={e => setCollectionData({...collectionData, schemeNumber: e.target.value})} />
              </div>
              <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
                <Input placeholder="Dealer Code" value={collectionData.dealerCode} onChange={e => setCollectionData({...collectionData, dealerCode: e.target.value})} />
              </div>
              <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
                <Input placeholder="Dealer Location" value={collectionData.dealerLocation} onChange={e => setCollectionData({...collectionData, dealerLocation: e.target.value})}/>
              </div>
             
          </div>
      </div>

      {/*  */}
      <div className="fullwidths">
        <div
          class="recent-boxes fullwidths"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <h2 style={{ fontSize: "18px", padding: "20px" }}>
            Recent {getSchemeType(selectedSchemeType)}
          </h2> */}
        </div>
        <span>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <ModificationCollectionTable
        selectedStatus={selectedStatus}
        selectedSchemeType={selectedSchemeType}
        monthlyFilters={monthlyFilters}
        selectedStateId={selectedStateId}
        loading={loadingState == "pending" || loadingTrack == "pending"}
        handleChange={(pagination, filters, sorter) => {
          setCollectionData({ ...collectionData, "sortingOrder": sorter?.order, "sortingType": sorter?.columnKey })
        }}
      />

      <div className="All_scheme_status">
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "green" }}
          ></div>
          Approved
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "red" }}
          ></div>
          Rejected
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "yellow" }}
          ></div>
          Work in Progress
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "rgb(255, 0, 255)" }}
          ></div>
          Auto Rejected
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "#BCB38D" }}
          ></div>
          Deviation
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "#9999ff" }}
          ></div>
          Hold
        </div>
      </div>

    
    </div>
  );
};
export default AwaitingLogsModification;
