import { Checkbox, Row, Col } from "antd";

const onChange = (checkedValues) => {
};



const Checkboxs = ({ dealer }) => {
  const options = dealer ? dealer.map(item => ({
    label: item.dealerName,
    value: item.admDealerId
  })) : [];

  return (
    <Checkbox.Group className="checkbox-group" options={options} onChange={onChange} />
  )
};
export default Checkboxs;
