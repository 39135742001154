import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const getGeneratedScheme = createSlice({
  name: "generatedScheme",
  initialState: { 
      data: [],
  },
  reducers: {
    generatedScheme: (state, action)=>{
        state.data = action.payload;
    }
  }

});
export const { generatedScheme } = getGeneratedScheme.actions;
export const getAllGeneratedScheme = (state)=> state.generatedScheme.data;
export default getGeneratedScheme.reducer;