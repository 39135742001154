import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Button, Tooltip } from "antd";
import "./allSchemeStatus.css";

const Status = ({ handleStatusDropdown,selectedStatus, isDisable=false }) => {
  const items = [
    {
      label: <a onClick={() => handleStatusDropdown(0)}>All Status</a>,

      key: "0",
    },
    {
      label: <a onClick={() => handleStatusDropdown(2)}>Approved</a>,

      key: "1",
    },

    {
      label: <a onClick={() => handleStatusDropdown(5)}>Reject</a>,

      key: "2",
    },

    {
      label: <a onClick={() => handleStatusDropdown(1)}>Work In progress</a>,

      key: "3",
    },

    {
      label: <a onClick={() => handleStatusDropdown(9)}>Hold</a>,

      key: "9",
    },
    {
      label: <a onClick={() => handleStatusDropdown(7)}>Deviation</a>,
      key: "6",
    },
    {
      label: <a onClick={() => handleStatusDropdown(8)}>Auto Rejected</a>,
      key: "7",
    }

  ];
  const getStatusType = (selectedStatus) => {
    if (selectedStatus == 0) {
      return "All Status";
    }
    if (selectedStatus == 2) {
      return "Approved";
    }
    if (selectedStatus == 5) {
      return "Reject";
    }
    if (selectedStatus == 9) {
      return "Hold";
    }
    if (selectedStatus == 1) {
      return "Work in Progress";
    }
    if (selectedStatus == 7) {
      return "Deviation";
    }
    if (selectedStatus == 8) {
      return "Auto Rejected";
    }

  };

  return (
    <div className="rejected-box">
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        disabled={isDisable}
      >
        <Space>
        <Tooltip title={"Status"}>
          <Button>
            <span style={{ color: "black" }}>
             {selectedStatus == " " ? 'Status' : getStatusType(selectedStatus)}
             </span>
            <DownOutlined style={{ color: "black" }} />
          </Button>
          </Tooltip>
        </Space>
      </Dropdown>
    </div>
  );
};
export default Status;
