
import React, { useEffect, useState } from 'react';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Space, DatePicker, Input, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import "./InstallationAllScheme.css"
import dayjs from 'dayjs';
import { CREATE_SCHEME_ROLES } from "../../../config";
import { decryptData } from '../../../utils/localStorageEncodeDecode';


const items = [
  {
    label: "2023",
    key: "1",
  },
  {
    label: "2024",
    key: "2",
  },
];
const menuProps = {
  items,
  selectable: true,
  // defaultSelectedKeys: ["2"],
};
const InstallationAllSchemeHeader = ({ onChangeFilter, showEnforcedMonth, startMonth, setStartMonth, setDealerDetails, dealerDetails, showFilter }) => {

  const [endMonth, setEndMonth] = useState(dayjs());
  const [enforcedMonth, setEnforcedMonth] = useState();
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const userData = sessionStorage.getItem('userInfo') ? decryptData(sessionStorage.getItem('userInfo')) : {};
    setUserInfo(userData);
  }, []);

  const handleInputChange = (key, date) => {
    switch (key) {
      case 'month':
        setStartMonth(date);
        setEnforcedMonth(null);
        break;
      case 'monthEnd':
        setEndMonth(date);
        setEnforcedMonth(null);
        break;
      case 'enforcedMonth':
        setEnforcedMonth(date);
        setStartMonth(null);
        setEndMonth(null);
        break;
      default:
        break;
    };
    const month = date ? new Date(date).getMonth() + 1 : 0;
    onChangeFilter(key, month);
  };

  return (
    <>
      <div className="All_scheme_inner">

        <Space className="all_Scheme_header">

          {/* <Dropdown disabled menu={menuProps}>
            <Button>
              <Space>
                2024
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown> */}

          {showEnforcedMonth ? (
            <Tooltip title={"Running Month"}>
              <DatePicker
                onChange={(date) => handleInputChange('enforcedMonth', date)}
                className="start"
                placeholder="Running Month"
                picker="month"
                value={enforcedMonth}
                allowClear
              />
            </Tooltip>
          ) : null}

          <div className="month">
            <div>
              <Tooltip title={"Start Month"}>
                <DatePicker
                  onChange={(date) => handleInputChange('month', date)}
                  className="start"
                  placeholder="Start Month"
                  picker="month"
                  value={startMonth}
                //     disabledDate={(current) => {
                //   const endDate = dayjs(endMonth).format("YYYY-MM-DD");
                //   return current && current > dayjs(endDate, "YYYY-MM-DD");
                // }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip title={"End Month"}>
                <DatePicker
                  onChange={(date) => handleInputChange('monthEnd', date)}
                  className="end"
                  placeholder="End Month"
                  picker="month"
                  value={endMonth}
                  disabledDate={(current) => {
                    const startDate = dayjs(startMonth).format("YYYY-MM-DD");
                    return current && current < dayjs(startDate, "YYYY-MM-DD");
                  }}
                />
              </Tooltip>
            </div>
          </div>
          {showFilter && <div className="d-flex my-2">
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search mx-2">
            <Tooltip title={"Dealer Code"}>
              <Input
                placeholder="Dealer Code"
                value={dealerDetails?.code}
                className="h-42 "
                onChange={(e) => {
                  setDealerDetails({
                    ...dealerDetails,
                    code: e.target.value,
                  });
                }}
              />
                </Tooltip>
            </div>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search mx-2">
            <Tooltip title={"Dealer Location"}> 
              <Input
                placeholder="Dealer Location"
                value={dealerDetails?.location}
                className="h-42 "
                onChange={(e) => {
                  setDealerDetails({
                    ...dealerDetails,
                    location: e.target.value,
                  });
                }}
              />
               </Tooltip>
            </div>
          </div>}
          {CREATE_SCHEME_ROLES.includes(userInfo?.userRole) ? (
            <div className='allscheme_btn_newscheme'>
              <Link to='/installation/createNewScheme'>
                <Button className="create btn-big" icon={<PlusOutlined style={{ color: 'white' }} />}><span style={{ color: '#fff' }}>Create New Scheme</span></Button>
              </Link>
            </div>
          ) : null}

        </Space>
      </div>
    </>
  );
};

export default InstallationAllSchemeHeader;
