import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../utils/dateFormatter";


const getMonthPicker = createSlice({
  name: "monthPicker",
  initialState: {
      startMonth: 0,
      endMonth:0,
      startYr: 0,
      endYr: 0,
      financialYear:getCurrentFiscalYear1(new Date())
  },
  reducers: {
    monthPicker: (state, action)=>{
        state.startMonth = action.payload.start_Month;
        state.endMonth = action.payload.end_Month;
        state.startYr = action.payload.start_yr;
        state.endYr = action.payload.end_yr;
        state.financialYear=action.payload.financialYear
    }
  }

});
export const { monthPicker } = getMonthPicker.actions;
export const getMonthPickerData = (state)=> state.monthPicker.data;
export default getMonthPicker.reducer;
