import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./draftScheme.css";
import { Divider, Button ,Row, Col} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import SMDialog from "../../../components/modal/alertModal/CommonModal";
import DraftCollapseTab from "./DraftCollapseTab";
import { draftScheme } from "../../../redux/collection/AllDraftScheme";
import { Link } from "react-router-dom";
import axiosAPI from "../../../services/axiosApi";

const CollectionDraftSchemes = () => {
  const dispatch = useDispatch();
  const [keys, setKeys] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const listData = useSelector((state) => state.draftScheme.data);

  const getSelectedRowKeys = (newSelectedRowKeys) => {
    setKeys(newSelectedRowKeys);
  };

  const fetchGeneratedScheme = async (demoParams) => {
    let result = await axiosAPI.post(`SchemeGenerate/GetDraftedScheme`,demoParams);
    dispatch(draftScheme(result.data.data));
  };
useEffect(()=>{
sessionStorage.removeItem("rowData")
},[])

  return (
    <div className="create_scheme_wrap collection-schme-box">
      <Divider />
      <div className="draft-scheme-header">
        <span className="draft-scheme-header-title">Draft Schemes</span>

        <div>
         
          <Link to="/collection/createNewScheme">
            <Button className="create-btn btn-big" icon={<PlusOutlined />}>
              Create New Scheme
            </Button>
          </Link>
        </div>
      </div>
      <DraftCollapseTab
        getSelectedRowKeys={getSelectedRowKeys}
        fetchGeneratedScheme={fetchGeneratedScheme}
      />
  
      <SMDialog
        open={openSuccessModal}
        onCancel={() => setOpenSuccessModal(!openSuccessModal)}
      >
        <p className="custom-warning">Success</p>
        <p>Scheme Successfully Generated</p>
      </SMDialog>
    </div>
  );
};
export default CollectionDraftSchemes;
