import React ,{useState}from 'react'
import AwaitingLogHeader from './AwaitingLogHeader';
import AwaitingLogTab from './AwaitingLogTab';
import { Divider } from "antd";
import dayjs from 'dayjs';
import "./awaitingLog.css";
import { getCurrentFiscalYear } from '../../../utils/dateFormatter';
import { decryptData } from '../../../utils/localStorageEncodeDecode';

const currentFiscalYear = getCurrentFiscalYear();

const initPayloadData = {
    fyear: currentFiscalYear,
    schemeType: 1,
    // state: 1,
    // userid: 0,
    month: dayjs().month() + 1,
    monthEnd: dayjs().month() + 1,
  };
const CollectionApprovalLogSchemes = () => {
    const userInfo = decryptData(sessionStorage.getItem("userInfo"));
    const userRole = userInfo.userRole;
    const [startMonth, setStartMonth] = useState(dayjs());
    const [payload, setPayload] = useState(initPayloadData);
    return (
      <>
        <div className="create_scheme_wrap collection-schme-box">
          <Divider />

          {/* <div className="approval_heading">Approved Schemes Log</div> */}
          <AwaitingLogHeader
            payload={payload}
            onChangeFilter={(key, val) => setPayload({ ...payload, [key]: val })}
            showEnforcedMonth={true}
            startMonth={startMonth} setStartMonth={setStartMonth}
          />
          <AwaitingLogTab payload={payload}  startMonth={startMonth}/>
        </div>
      </>
    );
}

export default CollectionApprovalLogSchemes;
