import {
  MoreOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Collapse, Space, Dropdown } from "antd";
import ApprovalTable from "./ApprovalTable";
import { useEffect, useState } from "react";
import axiosAPI from '../../../services/axiosApi';
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const { Panel } = Collapse;

const items = [
  {
    label: "Edit",
    key: "1",
    icon: <EditOutlined />,
  },
  {
    label: "Approve",
    key: "2",
    icon: <CheckOutlined />,
  },
  {
    label: "Reject",
    key: "3",
    icon: <CloseOutlined />,
  },
  {
    label: "Download",
    key: "3",
    icon: <DownloadOutlined />,
  },
];

const collapseProps = {
  items,
  trigger: ["click"],
};

const ApprovalSchemesTab = ({ payload }) => {
  const [stateAmgr, setStateAmgr] = useState()
  const [stateHeadlist, setStateHeadlist] = useState()
  const [zonalHeadList,setZonalHeadList]= useState();
  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userData.userRole;
  const userId = userData.userId;

  const getAmDropdownData = () => {
    if(userRole == 'SADM'){
      axiosAPI
      .post(`SchemeGenerate/GetDropdownAM`, { userId })
      .then((result) => {
        setStateAmgr(result.data.data)
      });
    }
    if(userRole == 'ZADM'){
      axiosAPI
      .post(`SchemeGenerate/GetDropdownStateHead`, {userId})
      .then((result) => {
        setStateHeadlist(result.data.data)
      });
    }
    if(userRole == 'NSH' || userRole == 'PMIS'){
      axiosAPI
      .post(`SchemeGenerate/GetzonalHead`)
      .then((result) => {
        setZonalHeadList(result.data.data)
      });
    }
  }

  useEffect(() => {
    getAmDropdownData()
  }, [])

  return (
    <>
      <Space
        className="collapse-items approval-scheme"
        direction="vertical"
        size="large"
      >
        {userRole == 'SADM' && stateAmgr?.map((item,key)=>(
          <Collapse>
          <Panel size="large" header={item.am} key={key}>
            <ApprovalTable payload={payload} amgrId={item.id}/>
          </Panel>
        </Collapse>
        ))}
         {userRole == 'ZADM' && stateHeadlist?.map((item,key)=>(
          <Collapse >
          <Panel size="large" header={item.stateHeadName} key={key}>
            <ApprovalTable payload={payload} stateHeadId={item.id}/>
          </Panel>
        </Collapse>
        ))}
        {(userRole == 'NSH' || userRole == 'PMIS') && zonalHeadList?.map((item,key)=>(
          <Collapse >
          <Panel size="large" header={item.zonalHeadName} key={key}>
            <ApprovalTable payload={payload} zonalHeadId={item.id}/>
         
          </Panel>
        </Collapse>
        ))}

      </Space>
    </>
  );
};
export default ApprovalSchemesTab;
