import CryptoJS from "crypto-js";
const dataDcrypt = "6d090796-ecdf-11ea-adc1-0242ac112345";
export const encryptData = (data, salt = dataDcrypt) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

export const decryptData = (ciphertext, salt = dataDcrypt) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, salt);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    let token = JSON.parse(decryptedString) || null;
    return token;
  } catch (err) {
    return null;
  }
};
