import React from "react";
import SlabBasedEditScheme from "./editSchemes/slabBasedEdit";
import SingleTargetEditScheme from "./editSchemes/singleTargetEdit";
import SingleTargetWithUpperCapEdit from "./editSchemes/singleTargetWithUpperCapEdit";
import UnconditionalInstallationEdit from "./editSchemes/unconditionalInstallationEdit";

const InstallationEdit = () => {
    const selectedRow = JSON.parse(sessionStorage.getItem('rowData'))
    const schemeTypeId = selectedRow?.map(schemeKey => schemeKey.scheme.schemeTypeId)

    return(
        <div className="create_scheme_wrap">
           {schemeTypeId.includes(3) && <SlabBasedEditScheme type = "edit" />}
           {schemeTypeId.includes(8)  && <SingleTargetEditScheme type = "edit"/>}
           {schemeTypeId.includes(4)  && <SingleTargetWithUpperCapEdit type = "edit" />}
           {schemeTypeId.includes(5)  && <UnconditionalInstallationEdit type = "edit" />}
            {/* <EditScheme type="edit"/> */}
        </div>
    )
}
export default InstallationEdit;
