import React, { Table, Tag, Row, Pagination, Col, Button } from "antd";
import { useState, useEffect } from "react";

import moment from "moment";
import XLSX from 'xlsx-js-style';
import ActionButton from "../../../components/actionButton/actionButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { approvalScheme } from "../../../redux/collection/ApprovalScheme";
import dayjs from "dayjs";
import axiosAPI from "../../../services/axiosApi";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../../utils/dateFormatter";
import { ReactComponent as Download } from "../../../assets/vector/download.svg";
import ToastifyShow from "../../../components/ToastifyShow";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import { formatNumberDecimal } from "../../../utils/formatter";




const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => { },
};


const AwaitingLogTable = ({ payload, amgrId, stateHeadId, zonalHeadId,startMonth}) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [listItem, setlistItem] = useState([]);
  const [selectedSchemeType, setSelectedSchemeType] = useState(1);
  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const currentFiscalYear = getCurrentFiscalYear1(startMonth||new Date());
  const InitCollectionData = {
    currentYear: currentFiscalYear,
    pageSize: "150",
    pageNumber: 1,
    sortingOrder: "ascend",
    sortingType: "DealerCode"
  };
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const [totalCountScheme, settotalCountScheme] = useState(1)
  const userRole = userData.userRole;
  let columns = []
  
  const handleChange = (pagination, filters, sorter) => {
    setCollectionData({ ...collectionData, "sortingOrder": sorter?.order, "sortingType": sorter?.columnKey })
  }

  const topTargetShow=(x)=>
(x==1||x==2||x==3)?    [{
      title: (
        <div>
          TOP SLAB
        </div>
      ),
      dataIndex: "topTarget",
      width: "180px",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"topTarget",
    }]:[]
  const commonColumns = [
    {
      title: <div>
        Scheme <br /> No
      </div>,
      dataIndex: "scheme",
      width: "160px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "SchemeNo",
      //  sorter: (a, b) => a.name && b.name && a.name.length - b.name.length,
      fixed: "left",
      render: (item) => {
        let color = " ";
        switch (item.status) {

          case 1:
            color = "#ffff00";
            break;
          case 2:
            color = "#51DE4E";
            break;
          case 5:
            color = "#FF3232";
            break;
          default:
            color = "#ffff00";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: <div>
        Dealer <br /> Code
      </div>,
      dataIndex: "code",
      width: "160px",
      sorter: true,
      defaultSortOrder: "ascend",
      fixed: "left",
      key: "DealerCode",
    },
    {
      title: <div>
        Dealer <br /> Name
      </div>,
      dataIndex: "name",
      width: "160px",
      key: "DealerName",
      sorter: true,
      defaultSortOrder: "ascend",
      fixed: "left",
    },
    {
      title: <div>
        Dealer <br /> Location
      </div>,
      fixed: "left",
      dataIndex: "Location",
      width: "190px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
      // onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },

    {
      title: "State",
      dataIndex: "state",
      sorter: true,
      defaultSortOrder: "ascend",
      width: "130px",
      key: "StateName",
    },
    {
      title: <div>
        Territory <br /> Manager
      </div>,
      dataIndex: "Manager",
      width: "190px",
      sorter: true,
      key: "TerritoryManager",
    },    ...topTargetShow(selectedSchemeType),
    {
      title: <div>
        Start <br /> Month
      </div>,
      dataIndex: "StartMonth",

      width: "160px",

      // sorter: true,
    },
    {
      title: <div>
        End <br /> Month
      </div>,
      dataIndex: "EndMonth",
      width: "160px",

      // sorter: true,
    },
  ]
  if (selectedSchemeType == 1 || selectedSchemeType == 2) {
    columns = [


      {
        title: "Industry",
        dataIndex: "industry",
        width: "110px",

      },
      {
        title: "B",
        dataIndex: "oldBillingQty",
        width: "110px",
        render: (x) => x || 0,
      },
      {
        title: "I",
        dataIndex: "oldInstallation",
        width: "110px",
        render: (x) => x || 0,
      },
      {
        title: "C",
        dataIndex: "oldCollection",
        width: "110px",
        render: (x) => x || 0,
      },


      // {
      //   title: "Slab Achived Last Year",
      //   dataIndex: "target",
      // },
      {
        title: <div>
          Opening <br /> Stock
        </div>,
        dataIndex: "stock",
        children: [
          {
            title: "NOS",
            dataIndex: "oldOpeningStock",
            width: "120px",

          },
          {
            title: "Days",
            dataIndex: "openingStockDays",
            width: "120px",

          },
        ],
      },
      {
        title: "Closing Os. (In Lacs)",
        dataIndex: "stock",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.name.length - b.name.length,
        children: [

          {
  title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
            dataIndex: "lastThirdMonth",
            defaultSortOrder: "descend",
            width: "190px",

            sorter: (a, b) => a.name.length - b.name.length,
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month Days
              </span>
            ),
            dataIndex: "lastTwoMonth",
            defaultSortOrder: "descend",
            width: "190px",

            sorter: (a, b) => a.name.length - b.name.length,
          },
          {
            title: (
              <span>
                Last Month
              </span>
            ),
            dataIndex: "lastOneMonth",
            defaultSortOrder: "descend",
            width: "190px",

            sorter: (a, b) => a.name.length - b.name.length,
          },
          // {
          //   title: "Lacs",
          //   dataIndex: "lac",
          //   defaultSortOrder: "descend",
          //   width : "190px",

          //   sorter: (a, b) => a.name.length - b.name.length,
          // },
          // {
          //   title: (
          //     <span>
          //       Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
          //     </span>
          //   ),
          //   dataIndex: "outStandingDays",
          //   defaultSortOrder: "descend",
          //   width: "110px",

          //   sorter: (a, b) => a.name.length - b.name.length,
          // },
        ],
      },
      {
        title: "Closing OS Days",
        dataIndex: "outStandingDays",
        children: [
          {
            title: "OS Last Month",
            dataIndex: "outStandingDays",
            defaultSortOrder: "descend",
            width: "110px",
            sorter: (a, b) => a.name.length - b.name.length,
          },
        ],
      },
      {
        title: "BG",
        dataIndex: "bg",
        children: [
          {
            title: "Lacs",
            dataIndex: "bgLacsCurrentYear",
            width: "120px",
            render:(x,y)=>x||y?.BG_Lacs

          },
        ],
      },
      {
        title: <div>Installation<br />Plan</div>,
        dataIndex: "IP",
        children: [
          {
            title: "NOS",
            dataIndex: "delayPlan",
            width: "200px",

          },
        ],
      },
      {
        title: <div>Billing<br />Details</div>,
        dataIndex: "plan",
        children: [
          {
            title: "B",
            dataIndex: "b",
            width: "100px",
            render: (x) => x || 0,

          },
          {
            title: "I",
            dataIndex: "i",
            width: "100px",
            render: (x) => x || 0,
          },
          {
            title: "C",
            dataIndex: "c",
            width: "100px",
            render: (x) => x || 0,
          },
        ],
      },
      {
        title: (
          <div>
            Closing <br /> Stock
          </div>
        ),
        dataIndex: "closingStock",
        width: "80px",
      },
      {
        title: (
          <div>
            Closing <br /> OutStanding (In Lacs)
          </div>
        ),
        dataIndex: "closingOutStanding",
        width: "110px",
      },
      {
        title: "Books Collection Target (No. of Ts.)",
        dataIndex: "BooksCollectionTarget",
        children: [
          {
            title: "T1",
            dataIndex: "BooksCollectionTargetT1",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "100px",

          },
          {
            title: "T2",
            dataIndex: "BooksCollectionTargetT2",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "100px",

          },
          {
            title: "T2-T1",
            dataIndex: "BooksCollectionTargetT2T1",
            width: "100px",

          },
          {
            title: "T3",
            dataIndex: "BooksCollectionTargetT3",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "100px",

          },
          {
            title: "T3-T2",
            dataIndex: "BooksCollectionTargetT3T2",
            width: "100px",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },

          },
          {
            title: "T4",
            dataIndex: "BooksCollectionTargetT4",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "100px",
          },
          {
            title: "T4-T3",
            dataIndex: "BooksCollectionTargetT4T3",
            width: "100px",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
          },
          {
            title: "T5",
            dataIndex: "BooksCollectionTargetT5",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "100px",
          },
          {
            title: "T5-T4",
            dataIndex: "BooksCollectionTargetT5T4",
            width: "100px",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
          },
          {
            title: "T6",
            dataIndex: "BooksCollectionTargetT6",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "100px",
          },
          {
            title: "T6-T5",
            dataIndex: "BooksCollectionTargetT6T5",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "100px",
          },
        ],
      },
      {
        title: <div>Payout <br /> Rate</div>,
        dataIndex: "pr",

        children: [
          {
            title: "R1",
            dataIndex: "PayoutRatep1",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
          {
            title: "R2",
            dataIndex: "PayoutRatep2",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
          {
            title: "R3",
            dataIndex: "PayoutRatep3",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
          {
            title: "R4",
            dataIndex: "PayoutRatep4",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
          {
            title: "R5",
            dataIndex: "PayoutRatep5",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
          {
            title: "R6",
            dataIndex: "PayoutRatep6",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
        ],
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        width: "180px",

      },
      {
        title: "Payout",
        dataIndex: "payout",

        children: [
          {
            title: "P1",
            dataIndex: "payoutR1",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
          {
            title: "P2",
            dataIndex: "payoutR2",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
          {
            title: "P3",
            dataIndex: "payoutR3",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
          {
            title: "P4",
            dataIndex: "payoutR4",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
          {
            title: "P5",
            dataIndex: "payoutR5",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
          {
            title: "P6",
            dataIndex: "payoutR6",
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
            width: "120px",
          },
        ],
      },
      {
        title: "Total Expected Payout",
        dataIndex: "TEP",
        children: [
          {
            title: "Expected Volume",
            dataIndex: "TEPvol",
            width: "150px",

          },
          {
            title: "Per Tr.",
            dataIndex: "TEPpertr",
            width: "150px",

          },
          {
            title: "Amount",
            dataIndex: "TEPamount",
            width: "150px",

          },
        ],
      },

    ];

  }
  else if (selectedSchemeType == 3) {
    columns = [
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        // sorter: true,
        width: '250px'

      },
      {
        title: "Targets",
        dataIndex: "Target",
        // width: '180px',
        children: [
          {
            title: "T1",
            dataIndex: "t1",
            width: '90px',
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
          },
          {
            title: "T2",
            dataIndex: "t2",
            width: '90px',
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
          },
          {
            title: "T3",
            dataIndex: "t3",
            width: '90px',
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
          }
        ]
      },
      {
        title: "inc. Per Tractor",
        dataIndex: "IPT",
        // width: '180px',
        children: [
          {
            title: "R1",
            dataIndex: "r1",
            width: '90px',
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
          },
          {
            title: "R2",
            dataIndex: "r2",
            width: '90px',
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
          },
          {
            title: "R3",
            dataIndex: "r3",
            width: '90px',
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
          }
        ]
      },
      {
        title: "Payout",
        dataIndex: "Payout",
        // width: '180px',
        children: [
          {
            title: "P1",
            dataIndex: "p1",
            width: '90px',
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
          },
          {
            title: "P2",
            dataIndex: "p2",
            width: '90px',
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
          },
          {
            title: "P3",
            dataIndex: "p3",
            width: '90px',
            render: (x, y) => {
              return (
                !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                  <div className="h-24 input-disabled"></div> : <div >{x}</div>
              )
            },
          }
        ]
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        width: '120px'

      },

    ]
  }
  else if (selectedSchemeType == 4) {
    columns = [
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        //   sorter: true,
        width: '250px'

      },
      {
        title: "Installation Target",
        dataIndex: "Target",
        width: '190px'


      },
      {
        title: "Upper Cap",
        dataIndex: "upperCap",
        width: '120px'

      },
      {
        title: "inc. Per Tractor",
        dataIndex: "incPerTarget",
        width: '190px'

      },
      {
        title: "Payout",
        dataIndex: "p1",
        width: '120px'

      },
      {
        title: "Remarks",
        dataIndex: "reason",
        width: '120px'

      },

    ]
  }
  else if (selectedSchemeType == 8) {
    columns = [
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        //   sorter: true,
        width: "250px",
      },
      {
        title: "Installation Target",
        dataIndex: "Target",
        width: "190px",
      },

      {
        title: "inc. Per Tractor",
        dataIndex: "incPerTarget",
        width: "190px",
      },
      {
        title: "Payout",
        dataIndex: "p1",
        width: "120px",
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        width: '120px'

      },

    ]
  }
  else if (selectedSchemeType == 5) {
    columns = [
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        //   sorter: true,
        width: "250px",
      },
      {
        title: "Installation Expected",
        dataIndex: "Target",
        width: "200px",
      },
      {
        title: "inc. Per Tractor",
        dataIndex: "incPerTarget",
        width: "190px",
      },
      {
        title: "Payout",
        dataIndex: "p1",
        width: "120px",
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        width: "120px",
      },
    ]
  }
  const defaultColumns = [...commonColumns, ...columns]
  const fetchAwaitingApprovalScheme = async (demoParams) => {
    let result = await axiosAPI.post(`SchemeGenerate/GetApprovedSchemes`, demoParams);
    let resultData = result?.data?.data?.data || []
    setlistItem(resultData);

    dispatch(approvalScheme(resultData.length > 0 && resultData.filter((item) => item.schemeNo != null)));

    if (resultData.length === 0) setDataSource([]);

    setSelectedSchemeType(demoParams.schemeType);
  };

  const getListing = () => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const demoParams = {
      ...payload,
      fyear: currentFiscalYear,
      schemeType: selectedSchemeType,
      state: 0,
      userid: userRole == "ZADM" ? stateHeadId : userRole == "NSH" || userRole == 'PMIS' ? zonalHeadId : amgrId,
      pageSize: collectionData.pageSize,
      pageNumber: collectionData.pageNumber,
      sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
      sortingType: collectionData.sortingType || "DealerCode"
    };
    fetchAwaitingApprovalScheme(demoParams);
  };


  const scroll = selectedSchemeType == 1 || selectedSchemeType == 2 ? {
    x: 4000,
    y: 800
  } : {
    x: 2000,
    y: 300
  }
  useEffect(() => {
    getListing();
  }, [payload, amgrId, stateHeadId, zonalHeadId]);

  // Api Calling
  useEffect(() => {
    getListing();
  }, []);
  useEffect(() => {
    const data = [];
    listItem
      ?.filter((item) => item.schemeNo != null)
      .map((item, index) => {
        for (let i = 0; i < 1; i++) {
          let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
          let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
          data.push({
            // status: item.status,
            key: item.schemeNo,
            scheme: item,
            code: item.dealerCode,
            name: item.dealerName,
            Location: item.dealerLocation,
            state: item.stateName,
            Manager: item.territoryManager,
            topTarget: item.topTarget,
            StartMonth: formattedStartDate,
            EndMonth: formattedEndDate,
            industry: item.industry,
            b: item.billing,
            i: item.installation,
            c: Math.round(item.collection),
            target: item.SlabAchievedLastYear,
            oldBillingQty: item.oldBillingQty,
            oldCollection: Math.round(item.oldCollection),
            oldInstallation: item.oldInstallation,
            delayPlan: item.delayPlan,
            BG_Lacs: item.bgLacsCurrentYear||item.bGLacsCurrentYear||0,
            lastThirdMonth:item.closingOutStandingMonth_3 ,
            lastTwoMonth: item.closingOutStandingMonth_2,
            lastOneMonth:item.closingOutStandingMonth_1,
            outStandingDays: item.outStandingDays,
            openingStockDays: item.openingStockDays,
            oldOpeningStock: item.oldOpeningStock,
            BooksCollectionTargetT1: item.t1,
            BooksCollectionTargetT2: item.t2,
            BooksCollectionTargetT2T1: item.t2_T1,
            BooksCollectionTargetT3: item.t3,
            BooksCollectionTargetT3T2: item.t3_T2,
            BooksCollectionTargetT4: item.t4,
            BooksCollectionTargetT4T3: item.t4_T3,
            BooksCollectionTargetT5: item.t5,
            BooksCollectionTargetT5T4: item.t5_T4,
            BooksCollectionTargetT6: item.t6,
            BooksCollectionTargetT6T5: item.t6_T5,
            PayoutRatep1: item.r1,
            PayoutRatep2: item.r2,
            PayoutRatep3: item.r3,
            PayoutRatep4: item.r4,
            PayoutRatep5: item.r5,
            PayoutRatep6: item.r6,
            payoutR1: item.p1,
            payoutR2: item.p2,
            payoutR3: item.p3,
            payoutR4: item.p4,
            payoutR5: item.p5,
            payoutR6: item.p6,
            TEPvol: item.volume,
            TEPpertr: item.perTr,
            TEPamount: item.amount,
            upperCap: item.upperCap,
            t1: item.t1,
            t2: item.t2,
            t3: item.t3,
            r1: item.r1,
            r2: item.r2,
            r3: item.r3,
            p1: item.p1,
            p2: item.p2,
            p3: item.p3,
            incPerTarget: item.incPerTarget,
            Target: item.installationTarget,
            actual_Installation_Last_Year_Month:item?.actual_Installation_Last_Year_Month,
            // LMSA: item.lastOneMonthSlabAchieved,
            // LCQ: item.lastMonthColQty,
            // LMSA1: item.lastTwoMonthSlabAchieved,
            // LCQ1: item.lastTwoMonthColQty,
            reason: item.reason,
            closingStock: item.closingStock,
            closingOutStanding: item.closingOutStanding
          });
        }
        setDataSource(data);
      });
  }, [listItem]);

  const evaluateTotalRow = (fieldSum) => {
    if (selectedSchemeType == 1 || selectedSchemeType == 2) {
      return (
        <Table.Summary.Row style={{ fontWeight: '500', fontSize: '18px' }}>
          <Table.Summary.Cell fixed index={0}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}></Table.Summary.Cell>
          <Table.Summary.Cell index={11}></Table.Summary.Cell>
          <Table.Summary.Cell index={12}></Table.Summary.Cell>
          <Table.Summary.Cell index={13}></Table.Summary.Cell>
          <Table.Summary.Cell index={14}></Table.Summary.Cell>
          <Table.Summary.Cell index={15}></Table.Summary.Cell>
          <Table.Summary.Cell index={16}></Table.Summary.Cell>
          <Table.Summary.Cell index={17}></Table.Summary.Cell>
          <Table.Summary.Cell index={18}></Table.Summary.Cell>
          <Table.Summary.Cell index={19}></Table.Summary.Cell>
          <Table.Summary.Cell index={20}></Table.Summary.Cell>
          <Table.Summary.Cell index={21}></Table.Summary.Cell>
          <Table.Summary.Cell index={22}></Table.Summary.Cell>
          <Table.Summary.Cell index={22}></Table.Summary.Cell>
          <Table.Summary.Cell index={23}></Table.Summary.Cell>
          <Table.Summary.Cell index={24}></Table.Summary.Cell>
          <Table.Summary.Cell index={25}>
            {fieldSum.t1 ? fieldSum.t1 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={26}>
            {fieldSum.t2 ? fieldSum.t2 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={27}>
            {fieldSum.T2_T1 ? fieldSum.T2_T1 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={28}>
            {fieldSum.t3 ? fieldSum.t3 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={29}>
            {fieldSum.T3_T2 ? fieldSum.T3_T2 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={30}>
            {fieldSum.t4 ? fieldSum.t4 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={31}>
            {fieldSum.T4_T3 ? fieldSum.T4_T3 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={32}>
            {fieldSum.t5 ? fieldSum.t5 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={33}>
            {fieldSum.T5_T4 ? fieldSum.T5_T4 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={34}>
            {fieldSum.t6 ? fieldSum.t6 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={35}>
            {fieldSum.T6_T5 ? fieldSum.T6_T5 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={36}>{fieldSum.t1 && fieldSum.totalR1 ? Math.round(fieldSum.totalR1 / fieldSum.t1) : ""}</Table.Summary.Cell>
          <Table.Summary.Cell index={37}>{fieldSum.t2 && fieldSum.totalR2 ? Math.round(fieldSum.totalR2 / fieldSum.t2) : ""}</Table.Summary.Cell>
          <Table.Summary.Cell index={38}>{fieldSum.t3 && fieldSum.totalR3 ? Math.round(fieldSum.totalR3 / fieldSum.t3) : ""}</Table.Summary.Cell>
          <Table.Summary.Cell index={39}>{fieldSum.t4 && fieldSum.totalR4 ? Math.round(fieldSum.totalR4 / fieldSum.t4) : ""}</Table.Summary.Cell>
          <Table.Summary.Cell index={40}>{fieldSum.t5 && fieldSum.totalR5 ? Math.round(fieldSum.totalR5 / fieldSum.t5) : ""}</Table.Summary.Cell>
          <Table.Summary.Cell index={41}>{fieldSum.t6 && fieldSum.totalR6 ? Math.round(fieldSum.totalR6 / fieldSum.t6) : ""}</Table.Summary.Cell>
          <Table.Summary.Cell index={42}></Table.Summary.Cell>
          <Table.Summary.Cell index={43}>
            {fieldSum.totalR1 ? fieldSum.totalR1 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={44}>
            {fieldSum.totalR2 ? fieldSum.totalR2 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={45}>
            {fieldSum.totalR3 ? fieldSum.totalR3 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={46}>
            {fieldSum.totalR4 ? fieldSum.totalR4 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={47}>
            {fieldSum.totalR5 ? fieldSum.totalR5 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={48}>
            {fieldSum.totalR6 ? fieldSum.totalR6 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={49}>
            {fieldSum.vol ? fieldSum.vol : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={50}>
            {Math.round(fieldSum.totalAmount / fieldSum.vol)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={51}>
            {fieldSum.totalAmount}

          </Table.Summary.Cell>
          <Table.Summary.Cell index={52}>

          </Table.Summary.Cell>
        </Table.Summary.Row>
      )
    }
     //Slab Based
    else if (selectedSchemeType === 3) {
      return (
        <Table.Summary.Row style={{ fontWeight: '600', fontSize: '18px' }}>
          <Table.Summary.Cell fixed index={0}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.T1 ? fieldSum.T1 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.T2 ? fieldSum.T2 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            {fieldSum.T3 ? fieldSum.T3 : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={13}>
            {fieldSum.T1 && fieldSum.P1Slab ? Math.round(fieldSum.P1Slab / fieldSum.T1) : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={14}>
            {fieldSum.T2 && fieldSum.P2Slab ? Math.round(fieldSum.P2Slab / fieldSum.T2) : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={15}>
            {fieldSum.T3 && fieldSum.P3Slab ? Math.round(fieldSum.P3Slab / fieldSum.T3) : ""}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={16}>
            {fieldSum.P1Slab ? fieldSum.P1Slab : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={17}>
            {fieldSum.P2Slab ? fieldSum.P2Slab : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={18}>
            {fieldSum.P3Slab ? fieldSum.P3Slab : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={19}></Table.Summary.Cell>
          <Table.Summary.Cell index={20}></Table.Summary.Cell>
        </Table.Summary.Row>
      )
    }
    //Single Target
    else if (selectedSchemeType === 8) {
      return (
        <Table.Summary.Row style={{ fontWeight: '600', fontSize: '18px' }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            {fieldSum.target ? fieldSum.target : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.incPerTarget ? fieldSum.incPerTarget : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.P1Slab ? fieldSum.P1Slab : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}></Table.Summary.Cell>
        </Table.Summary.Row>
      )
    }
    else if (selectedSchemeType === 4) {
      return (
        <Table.Summary.Row style={{ fontWeight: '600', fontSize: '18px' }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            {fieldSum.target ? fieldSum.target : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.upperCap ? fieldSum.upperCap : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.incPerTarget ? fieldSum.incPerTarget : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            {fieldSum.P1UC ? fieldSum.P1UC : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={13}></Table.Summary.Cell>
        </Table.Summary.Row>
      )
    }
     //Unconditional Installation
    else if (selectedSchemeType === 5) {
      return (
        <Table.Summary.Row style={{ fontWeight: '600', fontSize: '18px' }}>
          <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            {fieldSum.installationExpected ? fieldSum.installationExpected : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {fieldSum.incPerTarget ? fieldSum.incPerTarget : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {fieldSum.P1Slab ? fieldSum.P1Slab : ''}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}></Table.Summary.Cell>
        </Table.Summary.Row>
      )
    }
  };

  const getDownloadCols = item => {
    let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
    let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
    let createDate = dayjs(item.createdDate).utc('z').local().tz(dayjs.tz.guess()).format("MMM D, YYYY");
    if ([1, 2].includes(selectedSchemeType)) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Location: item.dealerLocation,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Industry: item.industry,
        B: item.oldBillingQty ? item.oldBillingQty : 0,
        I: Math.round(item.oldCollection),
        C: item.oldInstallation ? item.oldInstallation : 0,
        Opening_Stock_Nos: item.oldOpeningStock,
        Opening_Stock_Days: item.openingStockDays,
        Last_Third_Month: item.closingOutStandingMonth_1,
        Last_Two_Month: item.closingOutStandingMonth_2,
        Last_One_Month: item.closingOutStandingMonth_3,
        Days: item.outStandingDays,
        BG_Lacs: item.bgLacsCurrentYear||item.bGLacsCurrentYear||0,
        Installation_Plan_Nos: item.delayPlan,
        Billing_Details_B: item.billing,
        Billing_Details_i: item.installation,
        Billing_Details_c: Math.round(item.collection),
        Books_Collection_Target_T1: item.t1,
        Books_Collection_Target_T2: item.t2,
        Books_Collection_Target_T2T1: item.t2_T1,
        Books_Collection_Target_T3: item.t3,
        Books_Collection_Target_T3T2: item.t3_T2,
        Payout_Rate_P1: item.r1,
        Payout_Rate_P2: item.r2,
        Payout_Rate_P3: item.r3,
        Remarks: item.reason,
        Payout_R1: item.p1,
        Payout_R2: item.p2,
        Payout_R3: item.p3,
        TEP_Vol: item.volume,
        TEP_PerTr: item.perTr,
        TEP_Amount: item.amount,
      }
    }
    // Slab based scheme
    if (selectedSchemeType == 3) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Target_T1: item.t1,
        Target_T2: item.t2,
        Inc_Per_Tractor_R1: item.r1,
        Inc_Per_Tractor_R2: item.r2,
        Payout_P1: item.p1,
        Payout_P2: item.p2,
        Remarks: item.reason
      }
    }
    // Single Target Scheme
    if (selectedSchemeType == 8) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: item.installationTarget,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason
      }
    }
    // Single Target With Upper Cap Scheme
    if (selectedSchemeType == 4) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: item.installationTarget,
        Upper_Cap: item.upperCap,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason
      }
    }
    // Unconditional Scheme
    if (selectedSchemeType == 5) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Expected: item.installationTarget,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason
      }
    }
  }

  const captalizeObjKeys = obj => Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
  );

  const handleDownload = () => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };
    
    if (selectedSchemeType == 5) {
      const data = dataSource
        .map((rec) => rec.scheme)
        .map((item) => {
          let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
          let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
          return captalizeObjKeys({
            SCHEME_NO: item.schemeNo,
            DEALER_CODE: item.dealerCode,
            DEALER_NAME: item.dealerName,
            STATE: item.stateName,
            DEALER_LOCATION: item.dealerLocation,
            TERRITORY_MANAGER: item.territoryManager,
            START_MONTH:
              formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
            END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
            ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month,
            INSTALLATION_EXPECTED: item.installationTarget,
            INC_PER_TRACTOR: item.incPerTarget,
            PAYOUT: item.p1,
            REMARKS: item.reason,


          });
        });
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error");
        return false;
      }
      let tab_text = `<table class="custom-table">
      <thead>
      <tr>
          <th >SCHEME NO</th>
          <th >DEALER CODE</th>
          <th >DEALER NAME</th>
          <th >STATE</th>
          <th >DEALER LOCATION</th>
          <th >TERRITORY MANAGER</th>
          <th >START MONTH</th>
          <th >END MONTH</th>
          <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th >INSTALLATION EXPECTED</th>
          <th >INC. PER TRACTOR</th>
          <th >PAYOUT</th>
          <th >REMARKS</th>
          <th ></th>
          </tr>
      </thead>`;

      data?.forEach((x) => {
        tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
    <td>${x?.DEALER_CODE}</td>
    <td>${x?.DEALER_NAME}</td>
    <td>${x?.STATE}</td>
    <td>${x?.DEALER_LOCATION}</td>
    <td>${x?.TERRITORY_MANAGER}</td>
    <td>${x?.START_MONTH}</td>
    <td>${x?.END_MONTH}</td>
    <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
    <td>${x?.INSTALLATION_EXPECTED}</td>
    <td>${x?.INC_PER_TRACTOR}</td>
    <td>${x?.PAYOUT}</td>
    <td>${x?.REMARKS}</td>
    </tr>`;
      });

      let totals = {
        installationTarget: 0,
        incPerTarget: 0,
        p1:0,
        reason:""
       
    };

dataSource.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item.scheme[key]) || 0 ;
        }
    });

    totals.incPerTarget =  totals.installationTarget !== 0 ? parseInt(totals.p1 / totals.installationTarget):0;

    let totalsRow = "<tr style='background-color: #f2f2f2;' ><td colspan='9' >Total</td>";
    for (let key in totals) {
      if(key != 'reason'){
        if (key.startsWith('incPer') ) {
          totalsRow += `<td>${totals[key]}</td>`;
      } else {
          totalsRow += `<td>${totals[key]}</td>`;
      }
      }else{
       totalsRow  += `<td></td>`;
      } 
    }
 
   
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";


     
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      const allColumns = data.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);




      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });


      // generate file
      XLSX.writeFile(wb, `Unconditional_Installation_${new Date().toJSON().slice(0, 10)}.xlsx`);
      // const url = window.URL.createObjectURL(
      //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
      // );
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", );
      // document.body.appendChild(link);
      // link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
    }
    else if (selectedSchemeType == 4) {
      const data = dataSource
        .map((rec) => rec.scheme)
        .map((item) => {
          let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
          let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
          return captalizeObjKeys({
            SCHEME_NO: item.schemeNo,
            DEALER_CODE: item.dealerCode,
            DEALER_NAME: item.dealerName,
            STATE: item.stateName,
            DEALER_LOCATION: item.dealerLocation,
            TERRITORY_MANAGER: item.territoryManager,
            START_MONTH:
              formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
            END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
            ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month,
            INSTALLATION_TARGET: item.installationTarget,
            UPPER_CAP: item.upperCap,
            INC_PER_TRACTOR: item.incPerTarget,
            PAYOUT: item.p1,
            REMARKS: item.reason,
          });
        });
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error");
        return false;
      }
      let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th>UPPER CAP</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        </tr>
    </thead>`;

      data?.forEach((x) => {
        tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
  <td>${x?.DEALER_CODE}</td>
  <td>${x?.DEALER_NAME}</td>
  <td>${x?.STATE}</td>
  <td>${x?.DEALER_LOCATION}</td>
  <td>${x?.TERRITORY_MANAGER}</td>
  <td>${x?.START_MONTH}</td>
  <td>${x?.END_MONTH}</td>
  <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${x?.INSTALLATION_TARGET}</td>
  <td>${x?.UPPER_CAP}</td>
  <td>${x?.INC_PER_TRACTOR}</td>
  <td>${x?.PAYOUT}</td>
  <td>${x?.REMARKS}</td>
  </tr>`;
      });

      
      
      let totals = {
        installationTarget: 0,
        upperCap: 0,
        incPerTarget:0,
        p1: 0,
        reason:""
       
    };

    dataSource.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item.scheme[key]) || 0 ;
        }
    });

    totals.incPerTarget =  totals.installationTarget !== 0 ? parseInt(totals.p1 / totals.installationTarget):0;

      let totalsRow = "<tr><td colspan='9' >Total</td>";
       for (let key in totals) {
        if(key != 'reason'){
          if (key.startsWith('incPer') ) {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
   
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      // Add style to Total row color
      const allColumns = data.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });


      // generate file
      XLSX.writeFile(wb, `Installation_Single_Target_With_Upper_Cap${new Date().toJSON().slice(0, 10)}.xlsx`);
      // const url = window.URL.createObjectURL(
      //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
      // );
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", `Installation_Single_Target_With_Upper_Cap-${new Date().toJSON().slice(0, 10)}.xlsx`);
      // document.body.appendChild(link);
      // link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
    }
    else if (selectedSchemeType == 8) {
      const data = dataSource
        .map((rec) => rec.scheme)
        .map((item) => {
          let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
          let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
          return captalizeObjKeys({
            SCHEME_NO: item.schemeNo,
            DEALER_CODE: item.dealerCode,
            DEALER_NAME: item.dealerName,
            STATE: item.stateName,
            DEALER_LOCATION: item.dealerLocation,
            TERRITORY_MANAGER: item.territoryManager,
            START_MONTH:
              formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
            END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
            ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month,
            INSTALLATION_TARGET: item.installationTarget,
            INC_PER_TRACTOR: item.incPerTarget,
            PAYOUT: item.p1,
            REMARKS: item.reason,
          });
        });
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error");
        return false;
      }
      let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        </tr>
    </thead>`;

      data?.forEach((x) => {
        tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
  <td>${x?.DEALER_CODE}</td>
  <td>${x?.DEALER_NAME}</td>
  <td>${x?.STATE}</td>
  <td>${x?.DEALER_LOCATION}</td>
  <td>${x?.TERRITORY_MANAGER}</td>
  <td>${x?.START_MONTH}</td>
  <td>${x?.END_MONTH}</td>
  <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${x?.INSTALLATION_TARGET}</td>
  <td>${x?.INC_PER_TRACTOR}</td>
  <td>${x?.PAYOUT}</td>
  <td>${x?.REMARKS}</td>
  </tr>`;
      });

      
      let totals = {
        installationTarget: 0,
        incPerTarget: 0,
        p1: 0,
        reason:""
       
    };

    dataSource.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item.scheme[key]) || 0 ;
        }
    });

    totals.incPerTarget =  totals.installationTarget !== 0 ? parseInt(totals.p1 / totals.installationTarget):0;

    let totalsRow = "<tr><td colspan='9' >Total</td>";
    for (let key in totals) {
      if(key != 'reason'){
        if (key.startsWith('incPer') ) {
          totalsRow += `<td>${totals[key]}</td>`;
      } else {
          totalsRow += `<td>${totals[key]}</td>`;
      }
      }else{
       totalsRow  += `<td></td>`;
      } 
    }
   
      totalsRow += "</tr>";
      tab_text += totalsRow;

      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      // Add style to total row color
      const allColumns = data.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });


      // generate file
      XLSX.writeFile(wb, `Download_Installation_Single_Target_${new Date().toJSON().slice(0, 10)}.xlsx`);      // const url = window.URL.createObjectURL(
      //   new Blob([tab_text], { type: "application/vnd.ms-excel" })
      // );
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download",`Download_Installation_Single_Target-${new Date().toJSON().slice(0, 10)}.xlsx`);
      // document.body.appendChild(link);
    }
    else if (selectedSchemeType == 3) {
      const data = dataSource.map(rec => rec.scheme).map(item => {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        return captalizeObjKeys({
          Scheme_No: item.schemeNo,
          Dealer_Code: item.dealerCode,
          Dealer_Name: item.dealerName,
          State: item.stateName,
          Dealer_Location: item.dealerLocation,
          Territory_Manager: item.territoryManager,
          Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
          End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
          Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
          Target_T1: item.t1,
          Target_T2: item.t2,
          Target_T3: item.t3,
          Inc_Per_Tractor_R1: item.r1,
          Inc_Per_Tractor_R2: item.r2,
          Inc_Per_Tractor_R3: item.r3,
          PAYOUT_P1: item.p1,
          PAYOUT_P2: item.p2,
          PAYOUT_P3: item.p3,
          Remarks: item.reason,
          TOP_TARGET: item.topTarget
        })
      })
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error")
        return false
      }

      let tab_text = `<table>
      <thead>
      <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">TOP SLAB</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th colspan="3">TARGETS</th>
          <th colspan="3">INC. PER TRACTOR</th>
          <th colspan="3">PAYOUT</th>
          <th rowspan="2">REMARKS</th>
          </tr>
      <tr>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>P1</th>
         <th>P2</th>
         <th>P3</th>
        </tr>
      </thead>`;
      
      data?.forEach((header) => {
        tab_text += `<tr ><td>${header?.SCHEME_NO}</td>
  <td>${header?.DEALER_CODE}</td>
  <td>${header?.DEALER_NAME}</td>
  <td>${header?.STATE}</td>
  <td>${header?.DEALER_LOCATION}</td>
  <td>${header?.TERRITORY_MANAGER}</td>
  <td>${header?.TOP_TARGET?.toUpperCase() || ""}</td>
  <td>${header?.START_MONTH}</td>
  <td>${header?.END_MONTH}</td>
  <td>${header?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${header?.TARGET_T1}</td>
  <td>${header?.TARGET_T2}</td>
  <td>${header?.TARGET_T3}</td>
  <td>${header?.INC_PER_TRACTOR_R1}</td>
  <td>${header?.INC_PER_TRACTOR_R2}</td>
  <td>${header?.INC_PER_TRACTOR_R3}</td>
  <td>${header?.PAYOUT_P1}</td>
  <td>${header?.PAYOUT_P2}</td>
  <td>${header?.PAYOUT_P3}</td>
  <td>${header?.REMARKS}</td>
  </tr>`;
      });

      let totals = {
        t1: 0,
        t2: 0,
        t3:0,
        r1: 0,
        r2: 0,
        r3: 0,
        p1: 0,
        p2: 0,
        p3: 0,
        reason:""
       
    };

    dataSource.forEach((item) => {
        for (let key in totals) {
            totals[key] += parseInt(item.scheme[key]) || 0 ;
        }
    });

    totals.r1 = totals.t1 !== 0 ? parseInt(totals.p1 / totals.t1) : 0;
    totals.r2 = totals.t2 !== 0 ? parseInt(totals.p2 / totals.t2) : 0;
    totals.r3 = totals.t3 !== 0 ? parseInt(totals.p3 / totals.t3) : 0;
  
        let totalsRow = "<tr><td colspan='10' >Total</td>";
        for (let key in totals) {
        if(key != 'reason'){
          if (key.startsWith('r')) {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
      totalsRow += "</tr>";
      tab_text += totalsRow;

      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
       // Add style to Roral row
       const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(dataSource.length + 3).toString()}`
        excelArr.push(ele);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

      // generate file
      XLSX.writeFile(wb, `Slab_based_scheme_${new Date().toJSON().slice(0, 10)}.xlsx`);
      // const url = window.URL.createObjectURL(
      // new Blob([tab_text], { type: "application/vnd.ms-excel" })
      // );
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", `Slab_based_scheme.xlsx`);
      // document.body.appendChild(link);
      // link.click(); 
    } else if ([1, 2].includes(selectedSchemeType)) {
      if (dataSource?.length == 0 || !dataSource) {
        ToastifyShow("Something went wrong", "error")
        return false
      }

      let tab_text = `<table>
    <thead>
    <tr>
        <th rowspan="2">SCHEME NO</th>
        <th rowspan="2">DEALER CODE</th>
        <th rowspan="2">DEALER LOCATION</th>
        <th rowspan="2">DEALER NAME</th>
        <th rowspan="2">STATE</th>
        <th rowspan="2">TERRITORY MANAGER</th>
        <th rowspan="2">TOP SLAB</th>
        <th rowspan="2">START MONTH</th>
        <th rowspan="2">END MONTH</th>
        <th rowspan="2">Industry</th>
        <th rowspan="2">B</th>
        <th rowspan="2">I</th>
        <th rowspan="2">C</th>
        <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS.(IN LACS)</th>
        <th>BG</th>
        <th>INSTALLATION PLAN</th>
        <th colspan="3">BILLING DETAILS</th>
        <th rowspan="2">CLOSING STOCK</th>
        <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
        <th colspan="11">BOOKING COLLECTION TARGETS(NO. OF TRS)</th>
        <th colspan="6">PAYOUT RATE</th>
        <th rowspan="2">REMARKS</th>
        <th colspan="6">PAYOUT</th>
        <th colspan="3">TOTAL EXPECTED PAYOUT</th>
        </tr>
    <tr>
       <th>NOS</th>
       <th>Days</th>
       <th>LAST 3rd MONTH</th>
       <th>LAST 2nd MONTH</th>
       <th>LAST 1st MONTH</th>
       
       <th>LAST 1st MONTH DAYS</th>
       <th>LACS</th>
       <th>NOS</th>
       <th>B</th>
       <th>I</th>
       <th>C</th>
       <th>T1</th>
       <th>T2</th>
       <th>T2-T1</th>
       <th>T3</th>
       <th>T3-T2</th>
       <th>T4</th>
       <th>T4-T3</th>
       <th>T5</th>
       <th>T5-T4</th>
       <th>T6</th>
       <th>T6-T5</th>
       <th>R1</th>
       <th>R2</th>
       <th>R3</th>
       <th>R4</th>
       <th>R5</th>
       <th>R6</th>
       <th>P1</th>
       <th>P2</th>
       <th>P3</th> 
       <th>P4</th>
       <th>P5</th>
       <th>P6</th>  
       <th>Expected Volume</th>
       <th>PER TR.</th>
       <th>AMOUNT</th>  
      </tr>
    </thead>`;

      dataSource?.forEach((x) => {

        tab_text += `<tr ><td>${x?.scheme?.schemeNo}</td>
  <td>${x?.scheme?.dealerCode}</td>
  <td>${x?.scheme?.dealerLocation}</td>
  <td>${x?.scheme?.dealerName}</td>
  <td>${x?.scheme?.stateName}</td>
  <td>${x?.scheme?.territoryManager}</td>
  <td>${x?.scheme?.topTarget?.toUpperCase() || ""}</td>
  <td>${moment(x?.scheme?.startMonth).format("MMM-YY") || ""}</td>
  <td>${moment(x?.scheme?.endMonth).format("MMM-YY") || ""}</td>
  <td>${x?.scheme?.industry}</td>
  <td>${x?.scheme?.oldBillingQty}</td>
  <td>${x?.scheme?.oldInstallation}</td>
  <td>${x?.scheme?.oldCollection || 0}</td>
  <td>${x?.scheme?.oldOpeningStock}</td>
  <td>${x?.scheme?.openingStockDays}</td>
  <td>${formatNumberDecimal(x?.scheme?.closingOutStandingMonth_1)}</td>
  <td>${formatNumberDecimal(x?.scheme?.closingOutStandingMonth_2)}</td>
  <td>${formatNumberDecimal(x?.scheme?.closingOutStandingMonth_3)}</td>
  <td>${x?.scheme?.outStandingDays}</td>
  <td>${x?.scheme?.bGLacsCurrentYear || 0}</td>
  <td>${x?.scheme?.delayPlan}</td>
  <td>${x?.scheme?.billing}</td>
  <td>${x?.scheme?.installation}</td>
  <td>${x?.scheme?.collection}</td>
  <td>${x?.scheme?.closingStock}</td>
  <td>${x?.scheme?.closingOutStanding}</td>
 <td>${x?.scheme?.t1}</td>
  <td>${x?.scheme?.t2}</td>
  <td>${ x?.scheme?.t2_T1 && x?.scheme?.topTarget?.includes("t2")?(x?.scheme?.t2_T1) :0}</td>
  <td>${x?.scheme?.t3}</td>
  <td>${x?.scheme?.t3_T2 && x?.scheme?.topTarget?.includes("t3")?(x?.scheme?.t3_T2) :0}</td>
  <td>${x?.scheme?.t4}</td>
  <td>${ x?.scheme?.t4_T3 && x?.scheme?.topTarget?.includes("t4") ? (x?.scheme?.t4_T3)  :0}</td>
  <td>${x?.scheme?.t5}</td>
  <td>${x?.scheme?.t5_T4 && x?.scheme?.topTarget?.includes("t5") ? (x?.scheme?.t5_T4)  :0}</td>
  <td>${x?.scheme?.t6}</td>
  <td>${x?.scheme?.t6_T5 && x?.scheme?.topTarget?.includes("t6") ? (x?.scheme?.t6_T5)  :0}</td>
  <td>${x?.scheme?.r1}</td>
  <td>${x?.scheme?.r2}</td>
  <td>${x?.scheme?.r3}</td>
  <td>${x?.scheme?.r4}</td>
  <td>${x?.scheme?.r5}</td>
  <td>${x?.scheme?.r6}</td>
  <td>${x?.scheme?.reason}</td>
  <td>${x?.scheme?.p1}</td>
  <td>${x?.scheme?.p2}</td>
  <td>${x?.scheme?.p3}</td>
  <td>${x?.scheme?.p4}</td>
  <td>${x?.scheme?.p5}</td>
  <td>${x?.scheme?.p6}</td>
  <td>${x?.scheme?.volume}</td>
  <td>${x?.scheme?.perTr}</td>
  <td>${x?.scheme?.amount}</td>
  </tr>`;
      });

      let totals = {
        delayPlan: 0,
        billing: 0,
        installation:0,
        collection: 0,
        closingStock:0,
        closingOutStanding:0,
        t1: 0,
        t2: 0,
        t2_T1: 0,
        t3: 0,
        t3_T2: 0,
        t4: 0,
        t4_T3: 0,
        t5: 0,
        t5_T4: 0,
        t6: 0,
        t6_T5: 0,
        r1: 0,
        r2: 0,
        r3: 0,
        r4: 0,
        r5: 0,
        r6: 0,
        reason:"",
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        volume: 0,
        perTr: 0,
        amount: 0,
       
    };

    dataSource.forEach((item) => {
      for (let key in totals) {
        if (key === "t2_T1" && item.scheme.topTarget.includes("t2")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t3_T2" && item.scheme.topTarget.includes("t3")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t4_T3" && item.scheme.topTarget.includes("t4")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t5_T4" && item.scheme.topTarget.includes("t5")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (key === "t6_T5" && item.scheme.topTarget.includes("t6")) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        } else if (!["t2_T1", "t3_T2", "t4_T3", "t5_T4", "t6_T5"].includes(key)) {
          totals[key] += parseInt(item.scheme[key]) || 0;
        }
      }
    });

    totals.r1 = totals.t1 !== 0 ? parseInt(totals.p1 / totals.t1) : 0;
  totals.r2 = totals.t2 !== 0 ? parseInt(totals.p2 / totals.t2) : 0;
  totals.r3 = totals.t3 !== 0 ? parseInt(totals.p3 / totals.t3) : 0;
  totals.r4 = totals.t4 !== 0 ? parseInt(totals.p4 / totals.t4) : 0;
  totals.r5 = totals.t5 !== 0 ? parseInt(totals.p5 / totals.t5) : 0;
  totals.r6 = totals.t6 !== 0 ? parseInt(totals.p6 / totals.t6) : 0;
  totals.perTr =  totals.volume !== 0 ? parseInt(totals.amount / totals.volume):0;
    

   
      let totalsRow = "<tr><td colspan='20' >Total</td>";
      for (let key in totals) {
        if(key != 'reason' && key != 'closingStock' && key !='closingOutStanding'){
          if (key.startsWith('r') || key === 'perTr') {
            totalsRow += `<td>${totals[key]}</td>`;
        } else {
            totalsRow += `<td>${totals[key]}</td>`;
        }
        }else{
         totalsRow  += `<td></td>`;
        } 
      }
   
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);

       // Add style to total row color
       const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {
            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {

        if (number < 1) {
          return "Invalid input";
      } else if (number <= 26) {
          return String.fromCharCode(number + 64);
      } else {
          let firstChar = String.fromCharCode(((number - 1) / 26) + 64);
          let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
          return firstChar + secondChar;
      }
  
      }

      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(dataSource.length + 3).toString()}`
        excelArr.push(ele);

      }
      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });
      let nameFile = selectedSchemeType == 1 ? "With_DeliveryPlan.xlsx" : "Without_Delivery_Plan.xlsx"
      // generate file
      XLSX.writeFile(wb, nameFile);

    }
  }

  const tableHeader = (d) => {
    return (
      <Row className="table_sub_buttons">
        <Col>
          <Button
            disabled={dataSource?.length == 0}
            icon={<Download />}
            type="text"
            onClick={handleDownload}
          >
            Download
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <ActionButton
        onGetData={(item) => {
          fetchAwaitingApprovalScheme({
            ...payload,
            schemeType: item.schemeType,
            state: 0,
            pageSize: collectionData.pageSize,
            pageNumber: collectionData.pageNumber,
            userid: userRole == "ZADM" ? stateHeadId : userRole == "NSH" || userRole == 'PMIS' ? zonalHeadId : amgrId,
            sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
            sortingType: collectionData.sortingType || "DealerCode"
          })
          setSelectedSchemeType(item.schemeType)
        }
        }
      />
      <Table
        title={tableHeader}
        style={{
          cursor: "default",
          marginTop: "9px",
        }}
        summary={(pageData) => {

          const fieldSum = {
            b: 0,
            i: 0,
            c: 0,
            totalR1: 0,
            totalR2: 0,
            totalR3: 0,
            totalR4: 0,
            totalR5: 0,
            totalR6: 0,
            t1: 0,
            t2: 0,
            t3: 0,
            t4: 0,
            t5: 0,
            t6: 0,
            T2_T1: 0,
            T3_T2: 0,
            T4_T3: 0,
            T5_T4: 0,
            T6_T5: 0,
            vol: 0,
            perTr: 0,
            totalAmount: 0,
            T1: 0,
            T2: 0,
            T3: 0,
            R1: 0,
            R2: 0,
            R3: 0,
            P1: 0,
            P1UC: 0,
            P1Slab: 0,
            P2Slab: 0,
            P3Slab: 0,
            P1U: 0,
            P2: 0,
            target: 0,
            incPerTarget: 0,
            payout: 0,
            installationExpected: 0,
            upperCap: 0,

          }
          pageData.forEach(({
            payoutR1,
            payoutR2,
            payoutR3,
            payoutR4,
            payoutR5,
            payoutR6,
            TEPamount,
            BooksCollectionTargetT1,
            BooksCollectionTargetT2,
            BooksCollectionTargetT3,
            BooksCollectionTargetT4,
            BooksCollectionTargetT5,
            BooksCollectionTargetT6,
            BooksCollectionTargetT2T1,
            BooksCollectionTargetT3T2,
            BooksCollectionTargetT4T3,
            BooksCollectionTargetT5T4,
            BooksCollectionTargetT6T5,
            TEPvol,
            t1,
            t2,
            t3,
            r1,
            r2,
            r3,
            p1,
            p2,
            p3,
            Target,
            upperCap,
            incPerTarget,
            Payout,
            TEPpertr,
            scheme
          }) => {
            fieldSum.totalR1+= (payoutR1 &&scheme?.topTarget?.includes("t1")) ? parseInt(payoutR1||0) : 0;
         //   fieldSum.totalR1 += payoutR1 ? parseInt(payoutR1) : 0;
            fieldSum.totalR2 += payoutR2 ? parseInt(payoutR2) : 0;
            fieldSum.totalR3 += payoutR3 ? parseInt(payoutR3) : 0;
            fieldSum.totalR4 += payoutR4 ? parseInt(payoutR4) : 0;
            fieldSum.totalR5 += payoutR5 ? parseInt(payoutR5) : 0;
            fieldSum.totalR6 += payoutR6 ? parseInt(payoutR6) : 0;
            fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
            fieldSum.t1 += (BooksCollectionTargetT1 && scheme?.topTarget?.includes("t1")) ? parseInt(BooksCollectionTargetT1 || 0) : 0;
            fieldSum.t2 += (BooksCollectionTargetT2 && scheme?.topTarget?.includes("t2")) ? parseInt(BooksCollectionTargetT2 || 0) : 0;
            fieldSum.t3 += (BooksCollectionTargetT3 && scheme?.topTarget?.includes("t3")) ? parseInt(BooksCollectionTargetT3 || 0) : 0;
            fieldSum.t4 += (BooksCollectionTargetT4 && scheme?.topTarget?.includes("t4")) ? parseInt(BooksCollectionTargetT4 || 0) : 0;
            fieldSum.t5 += (BooksCollectionTargetT5 && scheme?.topTarget?.includes("t5")) ? parseInt(BooksCollectionTargetT5 || 0) : 0;
            fieldSum.t6 += (BooksCollectionTargetT6 && scheme?.topTarget?.includes("t6")) ? parseInt(BooksCollectionTargetT6 || 0) : 0;
            fieldSum.T2_T1 += (BooksCollectionTargetT2T1 && scheme?.topTarget?.includes("t2")) ? parseInt(BooksCollectionTargetT2T1 || 0) : 0;
            fieldSum.T3_T2 += (BooksCollectionTargetT3T2 && scheme?.topTarget?.includes("t3")) ? parseInt(BooksCollectionTargetT3T2 || 0) : 0;
            fieldSum.T4_T3 += (BooksCollectionTargetT4T3 && scheme?.topTarget?.includes("t4")) ? parseInt(BooksCollectionTargetT4T3 || 0) : 0;
            fieldSum.T5_T4 += (BooksCollectionTargetT5T4 && scheme?.topTarget?.includes("t5")) ? parseInt(BooksCollectionTargetT5T4 || 0) : 0;
            fieldSum.T6_T5 += (BooksCollectionTargetT6T5 && scheme?.topTarget?.includes("t6")) ? parseInt(BooksCollectionTargetT6T5 || 0) : 0;
            fieldSum.vol += TEPvol ? parseInt(TEPvol) : 0;
            fieldSum.perTr = parseInt(fieldSum.totalAmount / fieldSum.vol)
            fieldSum.T1 += t1 ? parseInt(t1) : 0;
            fieldSum.T2 += t2 ? parseInt(t2) : 0;
            fieldSum.T3 += t3 ? parseInt(t3) : 0;

            // fieldSum.R1 += r1 ? parseInt(r1) : 0;
            // fieldSum.R2 += r2 ? parseInt(r2) : 0;
            fieldSum.P1 = p1 ? parseInt(fieldSum.target * fieldSum.incPerTarget) : 0;

            fieldSum.P1Slab += p1 ? parseInt(p1) : 0;
            fieldSum.P2Slab += p2 ? parseInt(p2) : 0;
            fieldSum.P3Slab += p3 ? parseInt(p3) : 0;
            fieldSum.P2 += p2 ? parseInt(p2) : 0;
            fieldSum.P3 += p3 ? parseInt(p3) : 0;
            fieldSum.R1 = r1 ? Math.round(fieldSum.P1Slab / fieldSum.T1) : 0;
            fieldSum.R2 = r2 ? Math.round(fieldSum.P2Slab / fieldSum.T2) : 0;
            fieldSum.R3 = r3 ? Math.round(fieldSum.P3Slab / fieldSum.T3) : 0;
            fieldSum.target += Target ? parseInt(Target) : 0;
            fieldSum.incPerTarget = incPerTarget ? Math.round(fieldSum.P1Slab / fieldSum.target) : 0;
            fieldSum.upperCap += upperCap ? parseInt(upperCap) : 0;
            fieldSum.P1UC = p1 ? parseInt(p1) : 0;
            fieldSum.payout += Payout ? parseInt(Payout) : 0;
            fieldSum.installationExpected += Target ? parseInt(Target) : 0;
            fieldSum.P1U = p1 ? parseInt(fieldSum.incPerTarget * fieldSum.installationExpected) : 0

          });
          return evaluateTotalRow(fieldSum)
        }}

        columns={defaultColumns}
        onChange={handleChange}
        pagination={false}
        dataSource={dataSource}
        // rowSelection={{
        //   selectedRowKeys,
        //   onChange: (newSelectedRowKeys,selectedRow) => {
        //     setSelectedRowKeys(newSelectedRowKeys);
        //     sessionStorage.setItem('rowData', JSON.stringify(selectedRow))
        //   },
        //   getCheckboxProps: (record) => ({
        //     disabled: record.status === 1 || record.status === 2,
        //     // Column configuration not to be checked
        //     name: record.name,
        //   }),
        // }}
        bordered
        scroll={scroll}
      />
      <Pagination
        defaultCurrent={1}
        className='paginationDesign'
        current={collectionData.pageNumber}
        onChange={(pageNumber, pageSize) =>
          setCollectionData({ ...collectionData, pageNumber, pageSize })
        }
        total={totalCountScheme}
        showSizeChanger
        pageSize={collectionData.pageSize}
          pageSizeOptions={['150', '250', '350']}
      />
    </div>
  );
};
export default AwaitingLogTable;
