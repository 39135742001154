import React from "react";
import CommonModal from "./CommonModal";
const ValidationDifferentSchemeError = (props) => {
  let { open, onClose, errorsFields, SchemeID } = props;

  return (
    <CommonModal open={open} onCancel={() => onClose()}>
      <p className="custom-warning">Warning !</p>
      {SchemeID ? (
        <p>Please fill the required fields in Dealer Code {SchemeID}</p>
      ) : (
        <p>Please fill the following required fields.</p>
      )}

      <p className="error-list-p overflow-auto m-h400">
        {Object?.entries(errorsFields || {})?.map((x, index) => (
          <div key={index}>
            <div className="px-1 text-danger">{x[0]}</div>
            <ul key={index}>
              {x[1]?.length != 0 && x[1]?.map((y) => <li>{y}</li>)}
            </ul>
          </div>
        ))}
      </p>
    </CommonModal>
  );
};

export default ValidationDifferentSchemeError;
