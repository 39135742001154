import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const getSchemeList = createSlice({
  name: "schemeList",
  initialState: { 
      data: [],
  },
  reducers: {
    installationSchemeList: (state, action)=>{
        state.data = action.payload;
    }
  }

});
export const { installationSchemeList } = getSchemeList.actions;
export const getAllSchemeList = (state)=> state.schemeList.data;
export default getSchemeList.reducer;