import { createSlice } from "@reduxjs/toolkit";

const getApprovalScheme = createSlice({
  name: "approvalScheme",
  initialState: {
    data: [],
  },
  reducers: {
    approvalScheme: (state, action) => {
      state.data = action.payload;
    },
  },
});
export const { approvalScheme } = getApprovalScheme.actions;
export const getAllApprovalScheme = (state) => state.approvalScheme.data;
export default getApprovalScheme.reducer;
