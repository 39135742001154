import React from 'react';
import { Modal } from 'antd';
import { ReactComponent as SmileIcon } from "../../../assets/vector/smile.svg";
import Icon from "@ant-design/icons";
import "./index.css";

const SMDialog = ({children,open,onCancel}) => {

  return (
   <>
    <Modal 
        className='alertModal'
        open={open}
        onCancel={onCancel}
        closable={false}
        cancelText="Ok"
        okButtonProps={{ style: { display: "none" } }}
        maskClosable={false}
        destroyOnClose={true}
        >
        {/* <Icon className='custom-icon' component={SmileIcon}/> */}
        {children}
       
      </Modal>
   </>
  )

}


export default SMDialog;
