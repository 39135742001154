import { Row, Tabs,Col } from "antd";
import React, { useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import './modal.css';

const { TabPane } = Tabs;

const collectionSchemeData = [
   {
      id: 1,
      startMonth: 'January',
      endMonth: 'January'
   },
   {
      id: 2,
      startMonth: 'February',
      endMonth: 'February'
   },
   {
      id: 3,
      startMonth: 'March',
      endMonth: 'March'
   },
   {
      id: 4,
      startMonth: 'April',
      endMonth: 'April'
   }
]

const installationSchemeData = [
   {
      id: 1,
      startMonth: 'January',
      endMonth: 'January'
   },
   {
      id: 2,
      startMonth: 'February',
      endMonth: 'February'
   },
   {
      id: 3,
      startMonth: 'March',
      endMonth: 'March'
   },
   {
      id: 4,
      startMonth: 'April',
      endMonth: 'April'
   }
]

const MenuTabs = ({modalTitle}) => {

   const [schemeKey, setSchemeKey] = useState("6")

   const onChange = (key) => {
      setSchemeKey(key)
   };

   return (
  <Tabs
    className="custom-tabs"
    centered
    defaultActiveKey="6"
    onChange={onChange}
    key="16"
  >
    <TabPane tab="Collection schemes (8)" key="6">
    {collectionSchemeData.map(item => {
      return(
            <>
            <Row>
            <Col span={12} className ="textLeft ">
               <Link className="link" to='/collection/schemeStatus'  state={{ schemeKey: schemeKey, startMonth: item.startMonth, endMonth: item.endMonth, modalTitle:modalTitle }}> {item.startMonth} to {item.endMonth} (1)</Link>
            </Col>
            <Col  span={12}  className ="textRight">
               <RightOutlined/>
            </Col>
         </Row>
         <br/>
         </>
      )
    })}
    </TabPane>

    <TabPane tab="Installation schemes(10)" key="7">
    {installationSchemeData.map(data => (
      <div>
      <Row >
      <Col span={12} className ="textLeft ">
         <Link className="link" to='/collection/schemeStatus'  state={{ schemeKey: schemeKey, startMonth: data.startMonth, endMonth: data.endMonth, modalTitle:modalTitle }}> {data.startMonth} to {data.endMonth} (1)</Link>
      </Col>
      <Col  span={12}  className ="textRight">
         <RightOutlined/>
      </Col>
    </Row>
      <br />
      </div>
    ))}
    </TabPane>
  </Tabs>
   )
};

export default MenuTabs;
