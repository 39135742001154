import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


//fetchSchemeList  User Action
// export const fetchSchemeList = createAsyncThunk("fetchSchemeList", async (params) => {
//     let res = await fetch(`${baseUrl}/api/SchemeGenerate/GetSchemeList`,{
//       method: "Post",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({"userid": params}),
//     })
//   const result = await res.json();
//   });

const getSchemeList = createSlice({
  name: "schemeList",
  initialState: { 
      data: [],
  },
  reducers: {
    schemeList: (state, action)=>{
        state.data = action.payload;
    }
  }

});
export const { schemeList } = getSchemeList.actions;
export const getAllSchemeList = (state)=> state.schemeList.data;
export default getSchemeList.reducer;