import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";


//fetchSchemeList  User Action
// export const fetchSchemeList = createAsyncThunk("fetchSchemeList", async (params) => {
//     let res = await fetch(`${baseUrl}/api/SchemeGenerate/GetSchemeList`,{
//       method: "Post",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({"userid": params}),
//     })
//   const result = await res.json();
//   });

const getAllScheme = createSlice({
  name: "allScheme",
  initialState: { 
      currentYear: dayjs().year(),
      schemeType: '',
      month: 2,
      // collectionType: 'Collection',
      // dealerCode: [],
  },
  reducers: {
    allScheme: (state, action)=>{
        state.data = action.payload;
    }
  }

});
export const { allScheme } = getAllScheme.actions;
export const getAllSchemedata = (state)=> state.allScheme.data;
export default getAllScheme.reducer;