import React, { Children } from "react";
import { Modal, Button } from "antd";
import MenuTabs from "./MenuTabs";
import Dropdowns from './Dropdowns'
import './modal.css';

const SummaryModal = ({ openModal, handleModal, modalTitle, getMonthlyData, onSave, children}) => {

  return (
    <>
      <Modal
        className="SummaryModal"
        title={modalTitle}
        centered
        open={openModal}
        onCancel={handleModal}
        closable={false}
        onOk={onSave}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          
          <Button key="cancel" style={{ width: '100px' }} onClick={handleModal}>
            Cancel
          </Button>,
          <Button key="ok" style={{ background: 'darkgreen', width: '100px' }} onClick={onSave}>
          OK
        </Button>,
        ]}
      //  cancelButtonProps={{style:{ width: '100px'}}}
      //  okButtonProps={{ style: { background: '#FCE838', width: '100px' } }}
      >
        {children}
      </Modal>
    </>
  );
};

export default SummaryModal;
