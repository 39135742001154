import React from 'react'
import { Button, Modal } from 'antd';

const ConfirmModal = ({handleOk,handleCancel,modalText,confirmLoading,open}) => {
    return(
        <Modal
        className='merge-box-red'
        title="Confirm Action"
        open={open}
        onOk={handleCancel}
        confirmLoading={confirmLoading}
        onCancel={handleOk}
         cancelText="Yes"
        okText="No"
        maskClosable={false}
        destroyOnClose={true}
      >
        <p>{modalText}</p>
      </Modal>
    )
}
export default ConfirmModal