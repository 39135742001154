import ToastifyShow from "../../components/ToastifyShow";
import axiosAPI from "../../services/axiosApi";

export const getDealerListByStateIdApi = async (data) => {
  try {
    const response = axiosAPI.post(`controller/DealersListByStateId`,data)
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
        ToastifyShow("Something went wrong", "error")
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};
export const getMergeDealerListApi = async (data) => {
  try {
    const response = axiosAPI.post(`controller/dealersMergedScheme`,data)
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
        ToastifyShow("Something went wrong", "error")
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};
export const getUnmergeDealerListByStateIdApi = async (data) => {
  try {
    const response = axiosAPI.post(`controller/UnMergedDataByStateId`,data)
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
        ToastifyShow("Something went wrong", "error")
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};
export const getUnmergeDealerListApi = async (data) => {
  try {
    const response = axiosAPI.post(`controller/UnMergeDealers`,data)
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
        ToastifyShow("Something went wrong", "error")
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};
export const getAMListByStateIdApi = async (data) => {
  try {
    const response = axiosAPI.post(`controller/AMListByStateId`,data)
      .then((data) => {
        return data?.data;
      })
      .catch((error) => {
        ToastifyShow("Something went wrong", "error")
        return error;
      });
    return response;
  } catch (err) {
    throw err;
  }
};