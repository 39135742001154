import React,{useState} from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Button } from "antd";

const Status = ({ handleStatusDropdown, selectedStatus,selectedSchemeType }) => {
  // const [itemName, setItemName] = useState("Status");
  const items = [
    {
      label: <a onClick={() => handleStatusDropdown(0)}>All Scheme</a>,

      key: "1",
    },
    {
      label: <a onClick={() => handleStatusDropdown(2)}>Approved</a>,

      key: "2",
    },
    {
      label: <a onClick={() => handleStatusDropdown(5)}>Reject</a>,

      key: "3",
    },
    {
      label: <a onClick={() => handleStatusDropdown(1)}>Work in Progress</a>,

      key: "4",
    },
    {
      label: <a onClick={() => handleStatusDropdown(9)}>Hold</a>,
      key: "9",
    },
    {
      label: <a onClick={() => handleStatusDropdown(7)}>Deviation</a>,
      key: "6",
    },
    {
      label: <a onClick={() => handleStatusDropdown(8)}>Auto Rejected</a>,
      key: "7",
    }
  ]

  const getStatusType = (selectedStatus) => {
    if (selectedStatus == 0) {
      return "All Scheme";
    }
    if (selectedStatus == 2) {
      return "Approved";
    }
    if (selectedStatus == 5) {
      return "Reject";
    }
    if (selectedStatus == 9) {
      return "Hold";
    }
    if (selectedStatus == 1) {
      return "Work in Progress";
    }
    if (selectedStatus == 7 ) {
      return "Deviation";
    }
    if (selectedStatus == 8 ) {
      return "Auto Reject";
    }

  };
  return (
    <div>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
      >
        <Space>
          <Button className="yearPicker status-btn-box">
          {selectedStatus == " " ? 'Status' : getStatusType(selectedStatus)}
            <DownOutlined />
          </Button>
        </Space>
      </Dropdown>
    </div>
  );
};

export default Status;
