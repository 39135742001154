import ToastifyShow from "../../components/ToastifyShow";
import axiosAPI from "../../services/axiosApi"
export const getNHSDashboardDetails = async (data) => {
    try {

      const response=axiosAPI.post(`Dashboard/GetNSHDashboardDetail`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  //API for OneMonthSchemes - Installation
  export const getNHSDashboardDetailsForInstallation = async (data) => {
    try {
      const response=axiosAPI.post(`Dashboard/GetNSHDashboardForInstallationScheme`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
  export const getNHSDashboardDetailsForZadm = async (data) => {
    try {

      const response=axiosAPI.post(`Dashboard/GetNSHDashboardDetailForZonalHead`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

    //API for OneMonthSchemes - Installation For ZADM
  export const getNHSDashboardDetailsForInstallationForZadm = async (data) => {
    try {

      const response=axiosAPI.post(`Dashboard/GetNSHDashboardInstallationSchemeForZonalHead`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const getNHSDashboardMultiMonthDetails = async (data) => {
    try {

      const response=axiosAPI.post(`Dashboard/GetNSHDashboardDetailForMultiMonth`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  // Api for MultipleMonthSchemes Collection
  export const getNHSDashboardDetailsForCollection = async (data) => {
    try {
      const response=axiosAPI.post(`Dashboard/GetNSHDashboardDetailForMultiMonth`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  //Dashboard/GetCOSMStateWiseReport
  export const getDashboardCOSMStateWiseReport = async (data) => {
    try {

      const response=axiosAPI.post(`Dashboard/GetCOSMStateWiseReport`,data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
    //Dashboard/GetCOSMStateWiseReportForMultiMonth
    export const getDashboardCOSMMultipleStateWiseReport = async (data) => {
      try {
  
        const response=axiosAPI.post(`Dashboard/GetCOSMStateWiseReportForMultiMonth`,data)
          .then((data) => {
            return data?.data;
          })
          .catch((error) => {
              ToastifyShow("Something went wrong","error")
            return error;
          });
        return response;
      } catch (err) {
        throw err;
      }
    };

    //Dashboard/GetCOSMStateWiseReport
    export const getDashboardDetails = async (data) => {
      try {

        const response=axiosAPI.post(`Dashboard/DashboardDetail`,data)
          .then((data) => {
            return data?.data;
          })
          .catch((error) => {
              ToastifyShow("Something went wrong","error")
            return error;
          });
        return response;
      } catch (err) {
        throw err;
      }
    };

  