import React, { useEffect, useState } from "react";
import { DatePicker, Tooltip } from "antd";
import moment from "moment";
import { ReactComponent as DownArrow } from "../../assets/vector/downArrow.svg";
import "./financialYear.css";
import dayjs from "dayjs";


const FinancialYearButton = ({handleDate}) => {
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const financialYearStartMonth = 3; 
  const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  const financialYear = financialYearStartYear + 1;
  const [dateValue, setDateValue] = useState(financialYear);
    useEffect(() => {
      setDateValue(financialYear);
    }, [financialYear]);
const [dateShow,setDateShow]=useState(financialYear)
  const downIcon = <DownArrow />;

  const onChange = (selectedDate) => {
    setDateValue(selectedDate.year());
    // handleDate(dateShow?.year())
  };
  const formatDate = (date) => {
    return `FY-${dayjs(date).year()}`;
  };

  useEffect(()=>{
    handleDate(dateValue)
  },[dateValue])
  const disabledDate = (current) => {
    const today = new Date();
    const currentYear = today.getFullYear()+1;
    const lastYear = currentYear - 5;
  //const [dateShow,setDateShow]=useState((dayjs() && dayjs().month() < 3)?dayjs():dayjs())
    // Disable dates outside the range of April of last year to March of the current year
    return (
      current &&
      (current.year() < lastYear ||
        (current.year() === lastYear && current.month() < 3) ||
        current.year() > currentYear ||
        (current.year() === currentYear && current.month() > 2))
    );
  };
  return (
    <div className="calender">
                 <Tooltip title={"Financial Year"}>
      <DatePicker
      
        //  defaultValue={dayjs('2024-08-20', dateFormat)}
        picker="year"
        bordered={true}
        className="yearPicker"
        suffixIcon={downIcon}
        disabledDate={disabledDate}
       // value={dayjs(dateShow)}
        onChange={onChange}
        allowClear={false}
        format={formatDate}
        placeholder="Financial Year"
      /></Tooltip>
    </div>
  );
};

export default FinancialYearButton;
